import React from "react";
// import { ContentStyled } from "./style";
import { ContentStyled } from "containers/Terms/style";
//@ts-ignore
import { useTranslation } from "react-i18next";
import { Divider, Typography } from "antd";
import privacyPolicy from "./disclaimerTexts";
import { getI18n } from "react-i18next";
const Content = () => {
  const { t } = useTranslation();
  const current = new Date();
  const date = `${current.getDate()}/${
    current.getMonth() + 1
  }/${current.getFullYear()}`;
  const { language: selectedlang, changeLanguage } = getI18n();

  const data = privacyPolicy[selectedlang];
  return (
    <ContentStyled>
      <Typography.Title level={4}>
        {t("remain.disclaimer-for-camera")}
      </Typography.Title>

      <Typography.Text style={{ display: "block", marginBottom: "1rem" }}>
        {t("remain.disclaimer-content-first")}
      </Typography.Text>

      <Typography.Text>{t("remain.disclaimer-content-second")}</Typography.Text>
      <Divider />
      {data?.map((term, index) => {
        return (
          <div style={{ marginBottom: "30px" }}>
            <Typography.Title level={5} className="title">
              {term?.title}
            </Typography.Title>
            <div className="description">
              {term?.desc?.map((description) => {
                return (
                  <Typography.Text
                    style={{ display: "block", marginBottom: "8px" }}
                  >
                    {description}
                  </Typography.Text>
                );
              })}
            </div>
          </div>
        );
      })}
    </ContentStyled>
  );
};

export default Content;
