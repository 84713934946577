import { Col, Divider, Row } from "antd";
import React, { useEffect } from "react";
import * as S from "./index.styles";
import Location from "./Location";
import CityDescriptions from "./Description";
import useAPI from "hooks/useApi";
import { useParams } from "react-router-dom";
import dublinBanner from "../../../assets/dublinBanner.jpg";
import CustomEditor from "../../../components/Editor";
import city from "shared/front/apis/city.api";
import Leaflet from "./Leaflet";
import Typography from "antd/es/typography/Typography";
import { BaseTypography } from "components/common/BaseTypography";
import { useTranslation } from "react-i18next";

const SingleCity = () => {
  const params = useParams();
  const { t } = useTranslation();
  const { id } = params;
  const [makeRequest, { data: singleCityData, loading }] = useAPI();
  const getCityData = async (id: any) => {
    // try {
    //   await makeRequest({
    //     method: "get",
    //     url: `common/getCity/${id}`,
    //   });
    // } catch (error) {}
    try {
      await makeRequest(city.getCityForSuperAdmin(id));
    } catch (error) {}
  };

  useEffect(() => {
    getCityData(id);
  }, []);

  const cityDetails = singleCityData?.cityDetails;
  const stikerData = singleCityData?.stickerDetails;

  return (
    <S.CityWrapper>
      <Row justify={"center"}>
        {cityDetails?.bannerImage && cityDetails?.logo && (
          <Col span={24} style={{ position: "relative" }}>
            <S.CityImage
              src={cityDetails.bannerImage}
              alt={cityDetails.bannerImage}
            />
            <S.CityLogoImage src={cityDetails.logo} alt={cityDetails.logo} />
          </Col>
        )}

        <Col span={24} style={{ marginTop: "100px" }}>
          <S.HeaderWrapper>
            <S.Header>
              {/* <S.Author>{cityDetails?.title} GDPR compliance.</S.Author> */}
              <S.Author>{cityDetails?.title}</S.Author>
            </S.Header>
            <S.Description>{cityDetails?.description}</S.Description>
          </S.HeaderWrapper>
          <Row gutter={30}>
            <Col
              xs={24}
              lg={
                Array.isArray(cityDetails?.QRDetails) &&
                cityDetails?.QRDetails.length > 0
                  ? 12
                  : 24
              }
            >
              <S.InfoContainerSStyled>
                {/* <S.InfoHeader>{t("dashboard.city-info")}</S.InfoHeader> */}
                {/* <Divider style={{ margin: "5px 0px 10px" }} /> */}
                <CityDescriptions singleCityData={singleCityData} />
              </S.InfoContainerSStyled>
            </Col>
            {Array.isArray(cityDetails?.QRDetails) &&
              cityDetails?.QRDetails.length > 0 && (
                <Col xs={24} lg={12}>
                  <S.InfoContainerStyled>
                    <Leaflet QRCodeDetails={cityDetails?.QRDetails} />
                  </S.InfoContainerStyled>
                </Col>
              )}
          </Row>
          <Divider />
          <CustomEditor editorState={cityDetails?.descEng} isReadOnly />
        </Col>
        {/* <Col span={12} style={{ marginTop: "30px" }}>
          <Leaflet />
        </Col> */}
      </Row>
    </S.CityWrapper>
  );
};

export default SingleCity;
