import styled from "styled-components";
import { BaseTypography } from "../BaseTypography";
import { FONT_SIZE } from "styles/themes/constants";

export const ErrorText = styled(BaseTypography)<ErrorTextProps>`
  color: ${(props) =>
    props.color === "errorColor" ? `var(--red)` : `var(--success)`};
  font-size: ${(props) =>
    props.size === "small" ? `${FONT_SIZE.xxs}` : `${FONT_SIZE.xs}`};
`;

interface ErrorTextProps {
  size?: "large" | "small";
  color?: string;
}
