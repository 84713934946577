import dynamicQRImg from "../../Features/images/qr.png";
import stickerImg from "../../Features/images/sticker.png";
import publicPageImg from "../../Features/images/city-public-page.png";

export default [
  {
    img: dynamicQRImg,
    title: "QR-Code für Land und Stadt",
    description:
      "Moderne Städte erfordern moderne Lösungen. Mit Kamera-Compliance bringen wir die DSGVO-Konformität in öffentliche Überwachungssysteme, schützen personenbezogene Daten und gewährleisten vollständige Transparenz im Prozess.",
  },
  {
    img: stickerImg,
    title: "Aufkleber mit Feld",
    description:
      "Aufkleber sind großartig! Platziere deine langweiligen QR-Codes in bunten Aufklebern, um ihnen attraktive Looks zu verleihen.",
  },
  {
    img: publicPageImg,
    title: "Öffentliche Seite für Städte",
    description:
      "Passen Sie Ihre öffentlichen Seiten nach Ihren Bedürfnissen an... Stadtadministratoren können sie anpassen und je nach Stadt unterschiedliche Sprachen hinzufügen.",
  },
];
