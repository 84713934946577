import { Typography } from "antd";
import { ConnectContainer } from "./style";
import { connectData } from "./connectData";
//@ts-ignore
import { useTranslation } from "react-i18next";

const Connect = () => {
  const { t } = useTranslation();
  return (
    <>
      <ConnectContainer>
        <Typography.Title className="title" level={3}>
          {t("homePage.connect-title")}
        </Typography.Title>
        <div className="Wrapper">
          {connectData?.map((connect) => {
            return (
              <div key={connect.id}>
                <img src={connect.img} className="img" />
              </div>
            );
          })}
        </div>
      </ConnectContainer>
    </>
  );
};

export default Connect;
