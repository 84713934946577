import { Avatar } from "antd";
import React from "react";
import { styled } from "styled-components";
import { FONT_SIZE, FONT_WEIGHT, media } from "styles/themes/constants";

const LogoTitle = ({ logo, style, CState, title }: any) => {
  return (
    <LogoTitleWrapper>
      {CState?.logo && <Avatar size={64} src={logo} style={style} />}
      <PageTitle CState={CState}>{title}</PageTitle>
    </LogoTitleWrapper>
  );
};

export default LogoTitle;
const LogoTitleWrapper = styled.div`
  padding: 0rem 0px;
  display: flex;
  align-items: center;
  margin: 10px 0px;
  @media only screen and ${media.md} {
    margin: 20px 0px;
  }
`;

const PageTitle = styled.div<{ CState: any }>`
  font-size: ${FONT_SIZE.lg};
  font-weight: ${FONT_WEIGHT.bold};
  color: ${(props) => props?.CState?.fontColor};
  line-height: 1.5625rem;
  text-transform: capitalize;
  font-family: "Open Sans", Arial, sans-serif;
  margin-left: 5px;

  @media only screen and ${media.md} {
    font-size: ${FONT_SIZE.xxl};
  }
`;
