import { Pagination } from "antd";
import React from "react";
import { styled } from "styled-components";
import { FONT_SIZE } from "styles/themes/constants";
import { FlexEndText } from "utils/styles";
import { useTranslation } from "react-i18next";
export const BasePagination = ({ pagination, handlePagination }) => {
  const { t } = useTranslation();
  return (
    <PaginationWrapper>
      <Pagination
        current={pagination.page + 1}
        showSizeChanger
        pageSize={pagination.limit}
        total={pagination?.count}
        showTotal={(total, range): any => {
          return `${range[0]}-${
            pagination.page + 1 === 1
              ? pagination?.totalVisibleData || range[1]
              : range[1]
          } ${t("pagination.of")} ${total} ${t("pagination.items")}`;
        }}
        onChange={(page, pageSize) => {
          handlePagination(page - 1, pageSize);
        }}
      />
    </PaginationWrapper>
  );
};

const PaginationWrapper = styled.div`
  margin: 12px 0px;
  ${FlexEndText}
  .ant-table-tbody .ant-table-cell {
    font-size: ${FONT_SIZE.xxs};
    cursor: pointer;
  }
  .ant-pagination-item.ant-pagination-item-active {
    border-radius: 6px;
    background: var(--primary);
    & a {
      color: #fff !important;
    }
  }
  .ant-pagination-item {
    border-radius: 6px;
    & a {
      color: var(--primary);
    }
  }
`;
