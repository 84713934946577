import styled from "styled-components";
import React from "react";
import ScreenContainer from "./ScreenContainer";
import ScreenContent from "./ScreenContent";
import { ReactChildrenType } from "../../utils/interfaces";

const Screen: React.FC<ReactChildrenType> = ({ children }) => {
  return (
    <ScreenContainer>
      <ScreenContent>{children}</ScreenContent>
    </ScreenContainer>
  );
};

export default Screen;
