import { BaseTypography } from "components/common/BaseTypography";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { FONT_SIZE, media } from "styles/themes/constants";
import { devices } from "utils/theme";

export const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 24px;
  & .profile-avatar {
    cursor: pointer;
  }
`;
export const DashboardText = styled(BaseTypography)`
  color: var(--black);
  margin-right: 12px;
  cursor: pointer;
`;

// export const AdminLink = styled(Link)`
//   text-decoration: none;
//   cursor: pointer;
//   margin-right: 2rem;
//   font-size: ${FONT_SIZE.xxs};

//   color: var(--black) !important;

//   @media (max-width: 500px) {
//     font-size: ${FONT_SIZE.xs};
//     margin-right: 1rem;
//   }
// `;

export const AdminLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  margin-right: 1rem;
  font-size: ${FONT_SIZE.xxs};
  color: var(--black) !important;
  display: none;

  @media (${devices.mobileSM}) {
    display: block;
  }

  @media only screen and ${media.md} {
    font-size: ${FONT_SIZE.xs};
    margin-right: 2rem;
  }
`;

export const LanguageText = styled(BaseTypography.Text)`
  margin-right: 1rem;
  cursor: pointer;
  font-size: ${FONT_SIZE.xxs};
  color: var(--black) !important;
  @media only screen and ${media.md} {
    font-size: ${FONT_SIZE.xs};
    margin-right: 2rem;
  }
`;

export const NotificationProfileWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
`;
