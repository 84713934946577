import { Col, Divider, Row } from "antd";
import React, { useEffect, useState } from "react";
import LeftPart from "./LeftPart";
// import Template from "../Templates/Template1";
// import BaseButton from "components/common/BaseButton";
import publicPage from "shared/front/apis/public-page.api";
import toastr from "utils/toastHandler";
import useAPI from "hooks/useApi";
import { useParams } from "react-router-dom";
// import request from "utils/request";
// import languageMapWithCountries from "utils/languagemaps";
// import { useTranslation } from "react-i18next";
// import QRDetailPublic from "containers/QrDetailPublicView";
import QRPublicPage from "containers/QRPublicPage";

const data = {
  URL: "https://rentisity-stage.s3.eu-west-1.amazonaws.com/328b79fa-69ab-4808-832d-1581ae8790e7.svg",
  address: "Helsinki, Finland",
  admin: "64dde3b971a618f402078c15",
  batch: "AB-51",
  banner:
    "https://rentisity-stage.s3.eu-west-1.amazonaws.com/701eb312-f6ec-417a-8af0-9034e8f343a9",
  camera: [
    {
      location: {
        type: "Point",
        coordinates: [85.34917447275537, 27.671340942382812],
      },
      name: "Test Camera Name",
      address: "Test address, Helsinki, Finland",
      images: [
        {
          _id: "64de19eeb4a2fcc4c8500a64",
          QRCode: "64de198fb4a2fcc4c85009b4",
          description: "InterSection 1",
          label: "Intersection with wide range of coverage",
          URL: "https://kari-shr.notion.site/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2Fe2e6b4f7-6642-464d-90d0-c20ad43960e0%2FIntersection_1.png?table=block&id=37c9c121-1d57-4814-97c5-ef5cb7eb00d3&spaceId=69e0d783-f2e1-46e7-ad84-1ff7b3754666&width=1930&userId=&cache=v2",
          // ... other properties inside the "images" array
        },
        {
          _id: "64de19eeb4a2fcc4c8500a64",
          QRCode: "64de198fb4a2fcc4c85009b4",
          description: "InterSection 2",
          label: "Intersection with wide range of coverage",
          URL: "https://kari-shr.notion.site/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2Fe2e6b4f7-6642-464d-90d0-c20ad43960e0%2FIntersection_1.png?table=block&id=37c9c121-1d57-4814-97c5-ef5cb7eb00d3&spaceId=69e0d783-f2e1-46e7-ad84-1ff7b3754666&width=1930&userId=&cache=v2",
          // ... other properties inside the "images" array
        },
        {
          _id: "64de19eeb4a2fcc4c8500a64",
          QRCode: "64de198fb4a2fcc4c85009b4",
          description: "InterSection 3",
          label: "Intersection with wide range of coverage",
          URL: "https://kari-shr.notion.site/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2Fe2e6b4f7-6642-464d-90d0-c20ad43960e0%2FIntersection_1.png?table=block&id=37c9c121-1d57-4814-97c5-ef5cb7eb00d3&spaceId=69e0d783-f2e1-46e7-ad84-1ff7b3754666&width=1930&userId=&cache=v2",
          // ... other properties inside the "images" array
        },
        {
          _id: "64de19eeb4a2fcc4c8500a64",
          QRCode: "64de198fb4a2fcc4c85009b4",
          description: "InterSection 4",
          label: "Intersection with wide range of coverage",
          URL: "https://kari-shr.notion.site/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2Fe2e6b4f7-6642-464d-90d0-c20ad43960e0%2FIntersection_1.png?table=block&id=37c9c121-1d57-4814-97c5-ef5cb7eb00d3&spaceId=69e0d783-f2e1-46e7-ad84-1ff7b3754666&width=1930&userId=&cache=v2",
          // ... other properties inside the "images" array
        },
      ],
      // ... other properties inside the "camera" array
    },
  ],
  city: {
    _id: "64dde39571a618f402078c09",
    superAdmin: "64d5fb525bcd4ead1ec63461",
    // ... other properties inside the "city" object
  },
  cityName: "City Camera Compliance",
  count: 0,
  createdAt: "2023-08-17T12:58:55.829Z",
  frontendURL: "https://cameracompliance.com/qr/64de198fb4a2fcc4c85009b4",
  images: [
    {
      _id: "64de19eeb4a2fcc4c8500a64",
      QRCode: "64de198fb4a2fcc4c85009b4",
      description: "InterSection 1",
      label: "intersection with wide range of coverage",
      URL: "https://kari-shr.notion.site/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2Fe2e6b4f7-6642-464d-90d0-c20ad43960e0%2FIntersection_1.png?table=block&id=37c9c121-1d57-4814-97c5-ef5cb7eb00d3&spaceId=69e0d783-f2e1-46e7-ad84-1ff7b3754666&width=1930&userId=&cache=v2",
      // ... other properties inside the "images" array
    },
    {
      _id: "64de19eeb4a2fcc4c8500a64",
      QRCode: "64de198fb4a2fcc4c85009b4",
      description: "InterSection 1",
      label: "intersection with wide range of coverage",
      URL: "https://kari-shr.notion.site/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2Fe2e6b4f7-6642-464d-90d0-c20ad43960e0%2FIntersection_1.png?table=block&id=37c9c121-1d57-4814-97c5-ef5cb7eb00d3&spaceId=69e0d783-f2e1-46e7-ad84-1ff7b3754666&width=1930&userId=&cache=v2",
      // ... other properties inside the "images" array
    },
    {
      _id: "64de19eeb4a2fcc4c8500a64",
      QRCode: "64de198fb4a2fcc4c85009b4",
      description: "InterSection 1",
      label: "intersection with wide range of coverage",
      URL: "https://kari-shr.notion.site/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2Fe2e6b4f7-6642-464d-90d0-c20ad43960e0%2FIntersection_1.png?table=block&id=37c9c121-1d57-4814-97c5-ef5cb7eb00d3&spaceId=69e0d783-f2e1-46e7-ad84-1ff7b3754666&width=1930&userId=&cache=v2",
      // ... other properties inside the "images" array
    },
    {
      _id: "64de19eeb4a2fcc4c8500a64",
      QRCode: "64de198fb4a2fcc4c85009b4",
      description: "InterSection 1",
      label: "intersection with wide range of coverage",
      URL: "https://kari-shr.notion.site/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2Fe2e6b4f7-6642-464d-90d0-c20ad43960e0%2FIntersection_1.png?table=block&id=37c9c121-1d57-4814-97c5-ef5cb7eb00d3&spaceId=69e0d783-f2e1-46e7-ad84-1ff7b3754666&width=1930&userId=&cache=v2",
      // ... other properties inside the "images" array
    },
  ],
  footerLogo: [],
  isArchive: false,
  isAssigned: false,
  isCompleted: false,
  isDownloaded: true,
  isVerified: false,
  latitude: 60.192059,
  location: {
    type: "Point",
    coordinates: [24.945831, 60.192059],
  },
  longitude: 24.945831,
  operator: null,
  status: "Active",
  sticker: {
    _id: "64dde55371a618f402078d14",
    admin: "64dde3b971a618f402078c15",
    city: "64dde39571a618f402078c09",
  },
  stickerURL:
    "https://rentisity-stage.s3.eu-west-1.amazonaws.com/c1001748-9dd9-4854-ae1c-e95a47548014.svg",
  updatedAt: "2023-08-17T13:00:30.464Z",
  __v: 0,
  _id: "64de198fb4a2fcc4c85009b4",
};

type CustomizationType = {
  customize?: any;
  closeModal?: any;
  // data?: any;
};

const CustomizationTemplates = ({
  customize,
  closeModal,
}: CustomizationType) => {
  // const { t } = useTranslation();
  const { id } = useParams();

  const [colorState, setColorState] = useState({
    bannerBackgroundColor: "#F9F0F0",
    backgroundColor: "#7B94B6",
    footerColor: "#0D1D34",
    fontColor: "#000000",
    footerFontColor: "#000000",
    logo: false,
    objectFit: "contain",
    isCameraDetails: true,
  });
  const [showImage, setShowImage] = useState(false);
  const [bannerSize, setBannerSize] = useState("contain");
  // const [availableLanguages, setAvailableLanguages] = useState([]);
  // const [selectedDetails, setSelectedDetails] = useState<{
  //   desc?: string;
  //   lang?: string;
  //   title?: string;
  // }>({});
  // const [selectedLang, setSelectedLang] = useState("");
  // const [
  //   makeCustomizeRequest,
  //   { loading: customizeLoading, data: customizeData },
  // ] = useAPI();
  const [makeGetRequest, { loading: publicPageLoading, data: publicPageInfo }] =
    useAPI();

  // const cityDetailsArray = publicPageInfo?.details || [];
  // const footerLogo = publicPageInfo?.footerLogo || [];

  useEffect(() => {
    if (!!customize) {
      setColorState(customize);
    }
  }, [customize]);

  const getPublicPageByID = async (id) => {
    try {
      await makeGetRequest(publicPage.getPublicPageById(id));
    } catch (error) {
      toastr.error(error);
    }
  };

  // const handleDetailsSelection = (lang) => {
  //   if (Array.isArray(cityDetailsArray) && cityDetailsArray.length > 0) {
  //     const selected = cityDetailsArray.find((x) => x.lang === lang);
  //     setSelectedDetails(selected);
  //   }
  // };

  useEffect(() => {
    getPublicPageByID(id);
  }, []);

  // useEffect(() => {
  //   const availLang = publicPageInfo?.details?.map((val) => {
  //     return {
  //       langCode: val.lang,
  //       language: languageMapWithCountries[val.lang]?.languageFullName,
  //       flag: (
  //         <div style={{ fontFamily: "Twemoji Country Flags" }}>
  //           {languageMapWithCountries[val.lang]?.flag}
  //         </div>
  //       ),
  //     };
  //   });
  //   if (availLang?.length > 0) {
  //     handleDetailsSelection(availLang[0]?.langCode);
  //     setSelectedLang(availLang[0]?.langCode);
  //     setAvailableLanguages(availLang);
  //   }
  // }, [publicPageInfo]);
  return (
    <Row gutter={[14, 14]}>
      <Col
        span={6}
        style={{
          overflowY: "scroll",
          maxHeight: "calc(100vh - 100px)",
        }}
      >
        <LeftPart
          colorState={colorState}
          setColorState={setColorState}
          showImage={showImage}
          setShowImage={setShowImage}
          id={id}
          closeModal={closeModal}
        />
      </Col>
      <Col>
        <Divider
          type="vertical"
          style={{
            height: "100%",
            border: "0.8px solid",
            background: "var(--border)",
          }}
        />
      </Col>

      <Col span={16}>
        <>
          <div
            style={{
              overflowY: "scroll",
              maxHeight: "calc(100vh - 100px)",
            }}
          >
            {/* <Template
              publicPageInfo={publicPageInfo}
              availableLanguages={availableLanguages}
              selectedDetails={selectedDetails}
              QrDetailsData={data}
              colorState={colorState}
              showImage={showImage}
            /> */}
            <QRPublicPage
              loading={publicPageLoading}
              publicView={publicPageInfo}
              qrDetails={data}
              colorState={colorState}
              showImages={showImage}
              cameras={data?.camera}
            />
          </div>
        </>
      </Col>
    </Row>
  );
};

export default CustomizationTemplates;
//test
