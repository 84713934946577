import dynamicQRImg from "../../Features/images/qr.png";
import stickerImg from "../../Features/images/sticker.png";
import publicPageImg from "../../Features/images/city-public-page.png";

export default [
  {
    img: dynamicQRImg,
    title: "Pays et ville en code QR",
    description:
      "Les villes modernes nécessitent des solutions modernes. Avec la Conformité de Caméra, nous apportons la conformité au RGPD aux systèmes de surveillance publique, protégeant les données personnelles et garantissant une transparence totale dans le processus.",
  },
  {
    img: stickerImg,
    title: "Autocollant avec champ",
    description:
      "Les autocollants sont géniaux ! Placez vos codes QR ennuyeux dans des autocollants colorés pour leur donner un aspect attrayant.",
  },
  {
    img: publicPageImg,
    title: "Page publique pour les villes",
    description:
      "Personnalisez vos pages publiques selon vos besoins... Les administrateurs de la ville peuvent les personnaliser et ajouter différentes langues en fonction de la ville.",
  },
];
