export default [
  {
    title: "1. Informațiile pe care le colectăm:",
    desc: [
      {
        title: "1.1",
        desc: "Informații personale: Când interacționați cu proiectul nostru de conformitate cu camerele, putem colecta informații personale precum numele dvs., adresa de email și informațiile de contact.",
      },
      {
        title: "1.2",
        desc: "Informații despre dispozitiv: Putem colecta informații despre dispozitivul pe care îl utilizați pentru a accesa serviciile noastre, inclusiv tipul de dispozitiv, sistemul de operare, tipul de browser și adresa IP.",
      },
      {
        title: "1.3",
        desc: "Informații despre locație: Cu consimțământul dvs. explicit, putem colecta informații despre locația dvs. de pe dispozitiv pentru a vă furniza informații precise de conformitate legate de orașul și zona în care vă aflați.",
      },
      {
        title: "1.4",
        desc: "Informații despre scanarea camerei: Când scanați un cod QR de pe un stâlp cu o cameră, putem colecta date legate de locația camerei, data și ora scanării și informațiile de conformitate obținute.",
      },
    ],
  },
  {
    title: "2. Modul în care utilizăm informațiile dvs.:",
    desc: [
      {
        title: "2.1",
        desc: "Utilizăm informațiile colectate pentru a vă furniza informații precise de conformitate legate de zona orașului în care ați scanat codul QR.",
      },
      {
        title: "2.2",
        desc: "Putem utiliza informațiile dvs. personale pentru a vă trimite actualizări, notificări și informații importante legate de proiectul de conformitate a camerelor.",
      },
      {
        title: "2.3",
        desc: "Analizăm datele agregate și anonimizate în scopuri statistice, ceea ce ne poate ajuta să îmbunătățim serviciile noastre.",
      },
    ],
  },

  {
    title: "3. Partajarea informațiilor:",
    desc: [
      {
        title: "3.1",
        desc: "Nu partajăm informațiile dvs. personale cu terțe părți în scopuri de marketing fără consimțământul dvs. explicit.",
      },
      {
        title: "3.2",
        desc: "Putem partaja informațiile dvs. cu furnizorii noștri de servicii care ne ajută să livrăm serviciile noastre. Acești furnizori de servicii au obligația de a menține confidențialitatea informațiilor dvs.",
      },
      {
        title: "3.3",
        desc: "Putem dezvălui informațiile dvs. dacă este necesar conform legii sau pentru a proteja drepturile noastre, intimitatea, siguranța sau proprietatea.",
      },
    ],
  },

  {
    title: "4. Securitatea datelor:",
    desc: [
      {
        title: "4.1",
        desc: "Implementăm măsuri de securitate pentru a proteja informațiile dvs. personale împotriva accesului neautorizat, modificării, dezvăluirii sau distrugerii.",
      },
      {
        title: "4.2",
        desc: "În ciuda eforturilor noastre, nicio transmisie sau stocare online nu este complet sigură. Prin urmare, nu putem garanta securitatea absolută a informațiilor dvs.",
      },
    ],
  },

  {
    title: "5. Alegerile dvs.:",
    desc: [
      {
        title: "5.1",
        desc: "Puteți renunța să primiți comunicări de marketing de la noi urmând instrucțiunile de dezabonare furnizate în comunicare.",
      },
      {
        title: "5.2",
        desc: "Puteți controla colectarea informațiilor despre locație ajustând setările dispozitivului dvs.",
      },
    ],
  },

  {
    title: "6. Confidențialitatea copiilor:",
    desc: [
      {
        title: "6.1",
        desc: "Serviciile noastre nu sunt destinate persoanelor sub vârsta de [age]. Nu colectăm intenționat informații personale de la copii.",
      },
    ],
  },

  {
    title: "7. Modificări ale acestei Politici de confidențialitate:",
    desc: [
      {
        title: "7.1",
        desc: "Putem actualiza această Politică de confidențialitate din când în când pentru a reflecta modificările în practicile noastre sau din motive operaționale, legale sau reglementare.",
      },
      {
        title: "7.2",
        desc: "Vom furniza o notificare evidentă a oricăror modificări semnificative ale acestei Politici de confidențialitate.",
      },
    ],
  },
  {
    title: "8. Contactați-ne:",
    desc: [
      {
        desc: "Dacă aveți întrebări, preocupări sau solicitări cu privire la această Politică de confidențialitate sau la utilizarea informațiilor dvs. personale, vă rugăm să ne contactați la [contact email].",
      },
    ],
  },
];
