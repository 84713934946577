import React, { useState } from "react";
import { useResponsive } from "hooks/useResponsive";
import * as S from "./TopCitiesTable.styles";
import { useTranslation } from "react-i18next";
import { ColumnsType } from "antd/es/table";
import { BloodTestResult } from "constants/bloodTestResults";
import { ItalicLink } from "utils/styles";
import TopCitiesTableRowCard from "../TopCitiesTableRowCard";
import NoCityImage from "../../../../assets/nobanner.png";

interface BloodScreeningTableProps {
  activeItem: BloodTestResult;
  setActiveItem: (item: BloodTestResult) => void;
  topCity: any;
}

export const TopCitiesTable: React.FC<BloodScreeningTableProps> = ({
  activeItem,
  setActiveItem,
  topCity,
}) => {
  const { t } = useTranslation();

  const { isLargeMobile, isTablet, isDesktop } = useResponsive();
  const [dataSource] = useState<BloodTestResult[]>(topCity);
  const columns: ColumnsType<BloodTestResult> = [
    {
      title: t("dashboard.city-logo"),
      dataIndex: "logo",
      render: (logo: string) => {
        return (
          <>
            {!!logo ? (
              <img
                src={logo}
                alt={logo}
                width={40}
                height={40}
                style={{ objectFit: "cover" }}
              />
            ) : (
              <img
                src={NoCityImage}
                alt="notfound"
                width={40}
                height={40}
                style={{ objectFit: "cover" }}
              />
            )}
          </>
        );
      },
    },
    {
      title: t("dashboard.city-name"),
      dataIndex: "title",
      render: (test: string, { key }) => (
        <S.Text $isActive={activeItem.key === key}>{test}</S.Text>
      ),
    },
    {
      title: t("dashboard.accepted-qr"),
      dataIndex: "acceptedQR",
      align: "right" as "right",
      render: (result: number, { key }) => (
        <S.Text $isActive={activeItem.key === key}>{result}</S.Text>
      ),
    },
  ];

  return (
    <S.Container>
      {isLargeMobile ? (
        <S.Table
          size={"small"}
          pagination={false}
          columns={columns}
          dataSource={dataSource}
          scroll={{ y: isDesktop ? 300 : isTablet ? 620 : 400 }}
          // onRow={(record: any) => {
          //   return {
          //     onClick: () => setActiveItem(record),
          //   };
          // }}
        />
      ) : (
        <S.CardsWrapper>
          {topCity?.map((city) => (
            <TopCitiesTableRowCard
              logo={city.logo}
              title={city.title}
              acceptedQr={city.acceptedQR}
            />
          ))}
        </S.CardsWrapper>
      )}
      <ItalicLink to={`city`}>{t("dashboard.view-more")}</ItalicLink>
    </S.Container>
  );
};
