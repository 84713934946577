export default [
  {
    title: "1. Acceptance of Terms:",
    desc: [
      {
        title: "1.1",
        desc: "By using our camera compliance project, you acknowledge that you have read, understood, and agreed to these Terms and Conditions.",
      },
    ],
  },
  {
    title: "2. Privacy Policy:",
    desc: [
      {
        title: "2.1",
        desc: "Your use of our services is also governed by our Privacy Policy. By using our services, you consent to the collection, use, and sharing of your personal information as described in the Privacy Policy.",
      },
    ],
  },

  {
    title: "3. Use of Services:",
    desc: [
      {
        title: "3.1",
        desc: "Your use of our services is also governed by our Privacy Policy. By using our services, you consent to the collection, use, and sharing of your personal information as described in the Privacy Policy.",
      },
      {
        title: "3.2",
        desc: "You must comply with all applicable laws and regulations while using our services.",
      },
    ],
  },

  {
    title: "4. Intellectual Property:",
    desc: [
      {
        title: "4.1",
        desc: " All content, materials, and intellectual property associated with our camera compliance project are owned by us or our licensors. You may not reproduce, distribute, modify, or create derivative works based on our content without our explicit permission.",
      },
    ],
  },

  {
    title: "5. User Conduct:",
    desc: [
      {
        title: "5.1",
        desc: "You are responsible for the accuracy and legality of the information you provide while using our services.",
      },
      {
        title: "5.2",
        desc: "You must not engage in any conduct that could disrupt or harm our services, other users, or our reputation.",
      },
    ],
  },

  {
    title: "6. Limitation of Liability:",
    desc: [
      {
        title: "6.1",
        desc: " Our camera compliance project is provided “as is” and “as available.” We do not guarantee the accuracy, reliability, or availability of the services.",
      },
      {
        title: "6.2",
        desc: "We are not liable for any damages, losses, or liabilities arising from your use of our services or any information obtained through our services.” We do not guarantee the accuracy, reliability, or availability of the services.",
      },
    ],
  },

  {
    title: "7. Indemnification:",
    desc: [
      {
        title: "7.1",
        desc: "You agree to indemnify and hold us harmless from any claims, losses, liabilities, and expenses (including legal fees) arising from your use of our services or your violation of these Terms and Conditions.",
      },
    ],
  },
  {
    title: "8. Modifications and Termination:",
    desc: [
      {
        title: "8.1",
        desc: " We reserve the right to modify, suspend, or terminate our camera compliance project at any time without prior notice.",
      },
      {
        title: "8.2",
        desc: " We may also modify these Terms and Conditions from time to time. Your continued use of our services after such modifications constitutes your acceptance of the updated terms.",
      },
    ],
  },
  {
    title: "9. Governing Law:",
    desc: [
      {
        title: "9.1",
        desc: "These Terms and Conditions are governed by and construed in accordance with the laws of [Jurisdiction]. Any disputes arising out of or in connection with these terms shall be subject to the exclusive jurisdiction of the courts in [Jurisdiction].",
      },
    ],
  },
  {
    title: "10. Contact Us:",
    desc: [
      {
        desc: "If you have any questions, concerns, or inquiries regarding these Terms and Conditions or the use of our services, please contact us at [contact email]. By using our camera compliance project, you agree to be bound by these Terms and Conditions. If you do not agree with any part of these terms, please do not use our services.",
      },
    ],
  },
];
