import { Typography } from "antd";
import { Button } from "components/common/BaseButton/index.styles";
import {
  ContactUsText,
  ExploreBtnWrapper,
  HeroStyled,
  HeroContainer,
} from "./style";
//@ts-ignore
import { ReactComponent as RIGHT_ARROW_SVG } from "../../assets/arrow-right.svg";
//@ts-ignore
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import heroBGImage from "../../assets/homepage_hero.svg";
import heroBGGif from "../../assets/homepage_hero.gif";

type Props = {};

const Hero = (props: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <HeroContainer>
      <HeroStyled>
        <div className="left-contents">
          <Typography.Text className="camera-compliance">
            {/* {t("dashboard.camera-compliance")} */}
          </Typography.Text>
          <Typography.Text
            style={{
              fontSize: 16,
            }}
          >
            Camera Compliance Ltd
          </Typography.Text>
          <Typography.Title className="title">
            Revolutionizing Urban Surveillance with GDPR Compliance
            {/* {t("homePage.hero-title")} */}
            {/* Finland GDPR compliance */}
          </Typography.Title>
          <Typography.Paragraph className="description">
            {/* {t("homePage.description")} */}
            At Camera Compliance Ltd, we specialize in integrating cutting-edge
            technology with legal compliance to create safer, smarter, and more
            responsible urban environments. Our products are designed to bring
            cities and municipalities into full alignment with the General Data
            Protection Regulation (GDPR), focusing on the responsible use of
            surveillance cameras in public spaces.
          </Typography.Paragraph>
          <Button
            type="primary"
            onClick={() => {
              navigate("/contact");
            }}
          >
            <ExploreBtnWrapper>
              <ContactUsText>{t("buttons.contactUs")}</ContactUsText>
              <RIGHT_ARROW_SVG />
            </ExploreBtnWrapper>
          </Button>
        </div>
        <div className="right-contents">
          <img src={heroBGGif} alt="hero" />
        </div>
      </HeroStyled>
    </HeroContainer>
  );
};

export default Hero;
