import styled from "styled-components";
import { BaseLayout } from "../../../components/common/BaseLayout";
import { BaseMenu } from "../../../components/common/BaseMenu";
import {
  BREAKPOINTS,
  FONT_SIZE,
  media,
} from "../../../styles/themes/constants";
import { number } from "yup";

export const LayoutMaster = styled(BaseLayout)`
  min-height: 100vh;
  height: auto;
`;

export const LayoutMain = styled(BaseLayout)`
  margin-top: 70px;
`;
export const OverlayStyled = styled.div<{
  $iscollapsed: boolean;
  color?: string;
}>`
  position: fixed;
  background: ${({ color }) => color ?? "rgba(0, 0, 0, 0.3)"};
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 9999;
  display: ${({ $iscollapsed }) => ($iscollapsed ? "none" : "block")};
  @media (min-width: ${BREAKPOINTS.lg}px) {
    display: none;
  }
`;

export const Menu = styled(BaseMenu)<LayoutMainProps>`
  position: fixed;
  top: 60px;
  left: 0px;
  width: ${(props) => props.width}px;
  .ant-menu-item {
    margin: 10px 0px;
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    position: relative;
    top: 0px;
  }
`;

export const LogoWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 13px 0px;
  margin-bottom: 3px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  padding-left: 20px;
  @media (max-width: 500px) {
    padding: 12px 0px;
    padding-left: 20px;
  }

  & .logoLabel {
    flex: 1;
    color: black;
    font-size: 0.8rem;
    margin: 0px;
    line-height: 16px;
  }
  & svg.svg-logo {
    height: 30px;
    width: 30px;
    margin-right: 10px;
  }
`;

export const SidebarWrapperStyled = styled.div<{ $isCollapsed: boolean }>`
  & .large-screen-sidebar {
    display: block;
    @media (max-width: ${BREAKPOINTS.lg}px) {
      display: none;
    }
  }
  & .small-screen-sidebar {
    display: none;
    transform: ${({ $isCollapsed }) =>
      $isCollapsed ? "translateX(-100%)" : "translateX(0%)"};
    transition: 0.3s;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);

    @media (max-width: ${BREAKPOINTS.lg}px) {
      display: block;
      position: fixed;
      top: 0px;
      left: 0px;
      height: 100vh;
      background: #fff;
      z-index: 99999;
      width: 250px;

      & .icon-wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 10px;
        & .hamburger-icon {
          margin-right: 5px;
          font-size: 2rem;

          @media ${media.sm} {
            font-size: 2rem;
            & .icon {
              height: 30px;
              width: 30px;
            }
          }
        }
      }
    }
  }
`;
export const SiderWrapperStyled = styled.div`
  .ant-menu-item-selected {
    background-color: var(--primary) !important;
    color: #fff;
  }
`;

interface LayoutMainProps {
  width: number;
}
