import BaseButton from "components/common/BaseButton";
import { BaseTypography } from "components/common/BaseTypography";
import { useEffect, useState } from "react";
import { fullConvertTimestamp } from "utils/utils";
import AddCameraModal from "./AddCameraModal";
import { CameraInfoStyled } from "./styled";
import { useTranslation } from "react-i18next";
import { BaseCard } from "components/common/BaseCard";
import { styled } from "styled-components";
import ConfirmationMessagePopup from "components/ConfirmationMessagePopup/ConfirmationMessagePopup";
import request from "utils/request";
import { Button, Checkbox, Divider, Spin } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import useAPI from "hooks/useApi";
import { toast } from "react-toastify";
import { FaBox, FaCheckCircle } from "react-icons/fa";
import QR from "shared/front/apis/qr.api";
import { GrCheckbox } from "react-icons/gr";

const CameraInfo = ({
  info,
  qrId,
  refetch,
  groupDataById,
  qrDetails,
  isPublicPage,
}: any) => {
  const cameraData = groupDataById?.camera;
  const selectedGroupCamera = qrDetails?.selectedGroupCamera;
  const selectedPublicCameras = qrDetails?.selectedPublicCamera;
  const { t } = useTranslation();
  const [makeRequest, { loading: assignCameraLoading }] = useAPI();

  const [isVisible, setIsVisible] = useState(false);
  const [deleteCamera, { loading: deleteLoading }] = useAPI();
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState({
    status: false,
    id: null,
  });
  const [selectedCamera, setSelectedCamera] = useState([]);
  const [selectedPublicCamera, setSelectedPublicCamera] = useState([]);
  const [loadingCameraId, setLoadingCameraId] = useState([]);
  const [loadingPublicCameraId, setLoadingPublicCameraId] = useState([]);
  const [deletedCameraId, setDeletedCameraId] = useState([]);

  const navigate = useNavigate();

  const cameraInfoToShow = {
    Address: info?.address || "N/A",
    Longitude: info?.longitude,
    Latitude: info?.latitude,
    "Created By": info?.isCreateAutomatic ? "System" : "Admin",
    "Created Date": fullConvertTimestamp(info?.createdAt),
  };

  const assignCameraToPublicOrGroup = async (updatedCameraState) => {
    await makeRequest(QR.assignCameraToGroup(qrId, updatedCameraState));
  };

  const handleUnlinkCamera = async (cId) => {
    await deleteCamera({
      method: "post",
      url: `QR/UnLinkCameraToQRCodeById/${qrId}`,
      data: { cameraId: cId },
    });
    refetch();
    toast(t("remain.camera-unlinked-successfully"));
  };
  const handleUnlink = (id) => {
    setIsDeleteModalVisible({ status: true, id });
  };
  const handleAddCamera = () => {
    setIsVisible(true);
  };
  const onCloseAddCamera = () => {
    setIsVisible(false);
    refetch();
  };
  const closeDeleteModal = () => {
    setIsDeleteModalVisible({ status: false, id: null });
  };
  useEffect(() => {
    if (selectedPublicCameras) {
      setSelectedPublicCamera(selectedPublicCameras);
    }
  }, [selectedPublicCameras]);

  useEffect(() => {
    if (selectedGroupCamera) {
      setSelectedCamera(selectedGroupCamera);
    }
  }, [selectedGroupCamera]);
  return (
    <CameraInfoStyled>
      <AddCameraModal
        isVisible={isVisible}
        onClose={onCloseAddCamera}
        qrId={qrId}
        alreadyAddCamerasId={
          Array.isArray(info) &&
          info?.reduce((acc, val) => {
            acc.push(val?._id);
            return acc;
          }, [])
        }
      />
      <ConfirmationMessagePopup
        isVisible={isDeleteModalVisible.status}
        onAction={async () => {
          await handleUnlinkCamera(isDeleteModalVisible.id);
          closeDeleteModal();
        }}
        onClose={closeDeleteModal}
        actionIsDanger={true}
        actionLabel={t("remain.unlink-camera")}
        title={t("remain.are-you-sure-message")}
      ></ConfirmationMessagePopup>

      {isPublicPage ? (
        <div className="camera-wrapper">
          <BaseTypography.Title level={4} style={{ marginBottom: "-10px" }}>
            {/* {t("new.camera")} */}
            {t("new.public-page-camera")}
          </BaseTypography.Title>
          <div className="no-camera-wrapper">
            <BaseTypography.Text>
              {t("new.camera-is-associated-with-this-qr")}
            </BaseTypography.Text>
            <BaseButton onClick={handleAddCamera}>
              {t("new.link-camera-button")}
            </BaseButton>
          </div>

          <Checkbox
            checked={
              info?.length > 0 &&
              selectedPublicCamera?.length > 0 &&
              info?.every((item) => selectedPublicCamera?.includes(item?._id))
            }
            onChange={() => {
              if (
                info?.every((item) => selectedPublicCamera?.includes(item?._id))
              ) {
                setSelectedPublicCamera([]);
                assignCameraToPublicOrGroup({
                  selectedGroupCameraIds: selectedCamera,
                  selectedPublicCameraIds: [],
                });
              } else {
                const updatedCameraState = info?.map((item) => item?._id);
                setSelectedPublicCamera(updatedCameraState);
                assignCameraToPublicOrGroup({
                  selectedGroupCameraIds: updatedCameraState,
                  selectedPublicCameraIds: selectedPublicCamera,
                });
              }
            }}
            style={{ marginBottom: "6px" }}
          >
            {t("group.select-all")}
          </Checkbox>

          <GridContainer>
            {Array.isArray(info) &&
              info?.map((item) => {
                return (
                  <BaseCardStyled
                    style={{ position: "relative" }}
                    isArchive={item?.isArchive}
                  >
                    <div
                      onClick={async () => {
                        const updatedCameraState =
                          selectedPublicCamera?.includes(item._id)
                            ? selectedPublicCamera.filter(
                                (id) => id !== item._id
                              )
                            : [...selectedPublicCamera, item._id];
                        setSelectedPublicCamera(updatedCameraState);
                        setLoadingPublicCameraId((prev) => [
                          ...prev,
                          item?._id,
                        ]);
                        await assignCameraToPublicOrGroup({
                          selectedPublicCameraIds: updatedCameraState,
                          selectedGroupCameraIds: selectedCamera,
                        });
                        setLoadingPublicCameraId((prev) =>
                          prev.filter((id) => id !== item?._id)
                        );
                      }}
                    >
                      <div className="camera-info-row">
                        <div className="camera-info-row-title">
                          {t("dashboard.name")}
                        </div>
                        <div className="camera-info-row-value">
                          {item?.name || "N/A"}
                        </div>
                      </div>
                      <div className="camera-info-row">
                        <div className="camera-info-row-title">
                          {t("dashboard.address")}
                        </div>
                        <div className="camera-info-row-value">
                          {item?.address}
                        </div>
                      </div>
                      <div className="camera-info-row">
                        <div className="camera-info-row-title">
                          {t("dashboard.longitude")}
                        </div>
                        <div className="camera-info-row-value">
                          {item?.longitude}
                        </div>
                      </div>
                      <div className="camera-info-row">
                        <div className="camera-info-row-title">
                          {t("remain.created-by")}
                        </div>
                        <div className="camera-info-row-value">
                          {item?.isCreateAutomatic ? "System" : "Admin"}
                        </div>
                      </div>
                      <div className="camera-info-row">
                        <div className="camera-info-row-title">
                          {t("dashboard.image")}
                        </div>
                        <div className="camera-info-row-value">
                          {item?.images?.length ?? 0}
                        </div>
                      </div>
                      <div className="camera-info-row">
                        <div className="camera-info-row-title">
                          {t("dashboard.created-date")}
                        </div>
                        <div className="camera-info-row-value">
                          {fullConvertTimestamp(item?.createdAt)}
                        </div>
                      </div>

                      <div
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "5px",
                        }}
                      >
                        {assignCameraLoading &&
                        loadingPublicCameraId.includes(item?._id) ? (
                          <Spin />
                        ) : selectedPublicCamera?.includes(item?._id) ? (
                          <FaCheckCircle color="#167a8b" size={18} />
                        ) : (
                          <div
                            style={{
                              width: "24px",
                              height: "24px",
                              border: "1px solid black",
                              borderRadius: "3px",
                            }}
                          ></div>
                        )}
                      </div>
                    </div>

                    <div className="view-camera-wrapper">
                      <Button
                        style={{ marginRight: "5px" }}
                        // onClick={() => handleUnlinkCamera(item?._id)}
                        onClick={() => handleUnlink(item?._id)}
                      >
                        {t("remain.unlink-camera")}
                      </Button>
                      <Button
                        onClick={() =>
                          navigate(`/dashboard/camera/${item?._id}`)
                        }
                      >
                        {t("remain.view-camera")}
                      </Button>
                    </div>
                  </BaseCardStyled>
                );
              })}
          </GridContainer>
        </div>
      ) : (
        <div className="camera-wrapper">
          <BaseTypography.Title level={4} style={{ marginBottom: "-10px" }}>
            {/* {t("new.camera")} */}
            {t("remain.group")} {t("remain.camera")}
          </BaseTypography.Title>
          <div className="no-camera-wrapper">
            <BaseTypography.Text>
              {/* {t("new.camera-is-associated-with-this-qr")} */}
              {t("remain.click-on-camera-to-show-public-page")}
            </BaseTypography.Text>
          </div>
          <Checkbox
            checked={cameraData?.every((item) =>
              selectedCamera?.includes(item?._id)
            )}
            onChange={() => {
              if (
                cameraData?.every((item) => selectedCamera?.includes(item?._id))
              ) {
                setSelectedCamera([]);
                assignCameraToPublicOrGroup({
                  selectedGroupCameraIds: [],
                  selectedPublicCameraIds: selectedPublicCamera,
                });
              } else {
                const updatedCameraState = cameraData?.map((item) => item?._id);
                setSelectedCamera(updatedCameraState);
                assignCameraToPublicOrGroup({
                  selectedGroupCameraIds: updatedCameraState,
                  selectedPublicCameraIds: selectedPublicCamera,
                });
              }
            }}
            style={{ marginBottom: "6px" }}
          >
            {t("group.select-all")}
          </Checkbox>
          <QRStickerImageContainerStyled>
            <GridContainer>
              {cameraData?.map((item, index) => {
                return (
                  <BaseCardStyled isArchive={item?.isArchive}>
                    <CameraWrapper
                      onClick={async () => {
                        const updatedCameraState = selectedCamera.includes(
                          item._id
                        )
                          ? selectedCamera.filter((id) => id !== item._id)
                          : [...selectedCamera, item._id];
                        setSelectedCamera(updatedCameraState);
                        setLoadingCameraId((prev) => [...prev, item?._id]);

                        await assignCameraToPublicOrGroup({
                          selectedGroupCameraIds: updatedCameraState,
                          selectedPublicCameraIds: selectedPublicCamera,
                        });
                        setLoadingCameraId((prev) =>
                          prev.filter((id) => id !== item?._id)
                        );
                      }}
                    >
                      <div>
                        <div className="camera-info-row">
                          <div className="camera-info-row-title">
                            {t("dashboard.name")}
                          </div>
                          <div className="camera-info-row-value">
                            {item?.name || "N/A"}
                          </div>
                        </div>
                        <div className="camera-info-row">
                          <div className="camera-info-row-title">
                            {t("dashboard.address")}
                          </div>
                          <div className="camera-info-row-value">
                            {item?.address}
                          </div>
                        </div>
                        <div className="camera-info-row">
                          <div className="camera-info-row-title">
                            {t("dashboard.blocked")}
                          </div>

                          <div className="camera-info-row-value">
                            {item?.isArchive ? "Yes" : "No"}
                          </div>
                        </div>
                        <div className="camera-info-row">
                          <div className="camera-info-row-title">
                            {t("dashboard.longitude")}
                          </div>
                          <div className="camera-info-row-value">
                            {item?.longitude}
                          </div>
                        </div>
                        <div className="camera-info-row">
                          <div className="camera-info-row-title">
                            {t("remain.created-by")}
                          </div>
                          <div className="camera-info-row-value">
                            {item?.isCreateAutomatic ? "System" : "Admin"}
                          </div>
                        </div>
                        <div className="camera-info-row">
                          <div className="camera-info-row-title">
                            {t("dashboard.image")}
                          </div>
                          <div className="camera-info-row-value">
                            {item?.images?.length || 0}
                          </div>
                        </div>

                        <div className="camera-info-row">
                          <div className="camera-info-row-title">
                            {t("dashboard.created-date")}
                          </div>
                          <div className="camera-info-row-value">
                            {fullConvertTimestamp(item?.createdAt)}
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "5px",
                        }}
                      >
                        {assignCameraLoading &&
                        loadingCameraId.includes(item?._id) ? (
                          <Spin />
                        ) : selectedCamera?.includes(item?._id) ? (
                          <FaCheckCircle color="#167a8b" size={18} />
                        ) : (
                          <div
                            style={{
                              width: "24px",
                              height: "24px",
                              border: "1px solid black",
                              borderRadius: "3px",
                            }}
                          ></div>
                        )}
                      </div>
                    </CameraWrapper>

                    <div className="view-camera-wrapper">
                      <Button
                        onClick={() =>
                          navigate(`/dashboard/camera/${item?._id}`)
                        }
                      >
                        {t("remain.view-camera")}
                      </Button>
                    </div>
                  </BaseCardStyled>
                );
              })}
            </GridContainer>
          </QRStickerImageContainerStyled>
        </div>
      )}
    </CameraInfoStyled>
  );
};

export default CameraInfo;

const BaseCardStyled = styled(BaseCard)<{ isArchive: boolean }>`
  border: ${({ isArchive }) =>
    isArchive ? "2px solid var(--red)" : "2px solid #808080"};
  background: ${({ isArchive }) =>
    isArchive ? "rgba(200,10,10,0.1)" : "var(--white)"};
`;

const CameraWrapper = styled.div`
  position: relative;
  cursor: pointer;
  padding-right: 50px;
  &.selected {
    border: 3px solid var(--primary);
  }
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }

  gap: 16px;

  & .view-camera-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 10px;
  }
`;

export const QRStickerImageContainerStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 10px;
`;

const QRStickerImageWrapperStyled = styled.div`
  max-height: 120px;
  max-width: 200px;
  display: flex;
  justify-content: center;
  border: 1px solid #dbdbdb;

  overflow: hidden;
  cursor: pointer;

  &.selected {
    border: 3px solid var(--primary);
  }
  & img {
    max-height: 120px;
    object-fit: contain;
  }
`;
