import React from "react";
// import { ContentStyled } from "./style";
import { ContentStyled } from "containers/Terms/style";
//@ts-ignore
import { useTranslation } from "react-i18next";
import { Divider, Typography } from "antd";
import privacyPolicy from "./privacyTexts";
import { getI18n } from "react-i18next";
const Content = () => {
  const { t } = useTranslation();
  const current = new Date();
  const date = `${current.getDate()}/${
    current.getMonth() + 1
  }/${current.getFullYear()}`;
  const { language: selectedlang, changeLanguage } = getI18n();

  const data = privacyPolicy[selectedlang];
  return (
    <ContentStyled>
      <Typography.Title level={4}>
        {t("Privacy.privacy-title")}{" "}
      </Typography.Title>
      <Typography.Title level={5}>
        {" "}
        <span> Date: </span>
        {date}{" "}
      </Typography.Title>
      <Typography.Text>{t("Privacy.privacy-description")}</Typography.Text>
      <Divider />
      {data?.map((term, index) => {
        return (
          <div style={{ marginBottom: "30px" }}>
            <Typography.Title level={5} className="title">
              {term?.title}
            </Typography.Title>
            <div className="description">
              {term?.desc?.map((subDesc) => {
                return (
                  <div className="subDecriptionWrapper">
                    {subDesc.title && (
                      <Typography.Text
                        style={{
                          marginRight: "10px",
                          fontWeight: "bold",
                          width: "40px",
                        }}
                      >
                        {subDesc?.title}
                      </Typography.Text>
                    )}

                    <Typography.Text className="subDescription">
                      {subDesc?.desc}
                    </Typography.Text>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
      <Typography.Text style={{ fontSize: "1rem" }}>
        {t("Privacy.privacy-info")}
      </Typography.Text>
    </ContentStyled>
  );
};

export default Content;
