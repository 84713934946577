import styled from "styled-components";

export const ImageWrapper = styled.div`
  width: 60%;
  margin: 0px auto;
`;

export const Image = styled.img`
  width: 100%;
`;
