import { BaseTypography } from "components/common/BaseTypography";
import styled from "styled-components";
import { media } from "styles/themes/constants";
export const Title = styled(BaseTypography.Text)`
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 1rem;

  @media only screen and ${media.xl} {
    font-size: 1.2rem;
    margin-bottom: 1.4rem;
  }
`;
