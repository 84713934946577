import { Avatar, Button, Col, Divider, Row, Skeleton, Space } from "antd";
import { BaseAvatar } from "components/common/BaseAvatar/BaseAvatar";
import { BaseCard } from "components/common/BaseCard";
import React, { useState } from "react";

import NoAvatar from "../../../assets/NOAVATAR.png";
import { FaEdit } from "react-icons/fa";
import {
  BellOutlined,
  DollarOutlined,
  SecurityScanOutlined,
  UserOutlined,
} from "@ant-design/icons";
import ActionButton from "components/ActionWrapper/ActionButton";
import BasePhoneInput from "components/common/BasePhoneInput";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import BaseButton from "components/common/BaseButton";
import BannerImage from "containers/Dashboard/BannerImage";
import LogoImage from "containers/Dashboard/LogoImage";
import { BaseTypography } from "components/common/BaseTypography";
import { useRoot } from "RootProvider";
import { t } from "i18next";
import { dashboard } from "constants/api/dashboard";
import { useTranslation } from "react-i18next";
import EditAdminModal from "../../Admin/EditAdminModal";
import { useDispatch, useSelector } from "react-redux";
import admin from "shared/front/apis/admin.api";
import useAPI from "hooks/useApi";
import BaseInputs from "components/common/BaseInput/BaseInputs";
import * as S from "./index.styles";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
// import Modal from "components/common/Modal";
import BaseModal from "components/common/BaseModal";
import adminApi from "shared/front/apis/admin.api";
import { toast } from "react-toastify";
// Your custom CSS
const CustomCol = styled(Col)`
  display: flex;
  align-items: flex-end;
  margin-left: 20px;
`;

interface ProfileNavItem {
  id: number;
  name: string;
  icon: React.ReactNode;
  color: "primary" | "error" | "warning" | "success";
  href: string;
}

const profileNavData: ProfileNavItem[] = [
  {
    id: 1,
    name: "Sticker No.",
    icon: <SecurityScanOutlined />,
    color: "primary",
    href: "personal-info",
  },
  {
    id: 2,
    name: "Accepted QR",
    icon: <SecurityScanOutlined />,
    color: "success",
    href: "security-settings",
  },
  {
    id: 3,
    name: "Active QR",
    icon: <BellOutlined />,
    color: "error",
    href: "notifications",
  },
  {
    id: 4,
    name: "Operator No.",
    icon: <DollarOutlined />,
    color: "warning",
    href: "payments",
  },
];
const LeftProfile = ({ cityInfo, getCityInfo, loading }: any) => {
  const root = useRoot();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isEditModal, setIsEditModal] = useState(null);

  const [makeRequest, { loading: adminLoading, error }] = useAPI();

  const handleFormSubmit = async (formikValues: any) => {
    try {
      const { firstName, lastName, phone, countryCode } = formikValues;
      const resp = await makeRequest(
        admin.updateOwnProfile({
          data: {
            firstName,

            lastName,
            phone: phone.replace(countryCode, ""),

            countryCode,
          },
        })
      );
      console.log(resp);

      const userDetails = root?.auth?.userDetails;
      userDetails.firstName = firstName;
      userDetails.lastName = lastName;
      userDetails.fullName = `${firstName} ${lastName}`;
      userDetails.phone = phone.replace(countryCode, "");
      userDetails.countryCode = countryCode;

      root?.setAuth({
        ...root?.auth,
        userDetails,
      });

      if (!!resp?._id) {
        setIsEditModal(false);

        toast.success(
          `${t("toast.admin")} ${resp?.firstName} ${t(
            "toast.is-successfully-added"
          )}.`
        );
      }
    } catch (error: any) {
      toast.error(error);
    }
  };

  return (
    <>
      <BaseModal
        visible={isEditModal}
        onCancel={() => setIsEditModal(false)}
        title={`${t("remain.edit-admin")} ${t("dashboard.profile")}`}
        footer={[]}
      >
        <Formik
          initialValues={{
            firstName: root?.auth?.userDetails?.firstName,
            lastName: root?.auth?.userDetails?.lastName,
            _id: root?.auth?.userDetails?._id,
            phone: `${root?.auth?.userDetails?.countryCode}${root?.auth?.userDetails?.phone}`,
            countryCode: root?.auth?.userDetails?.countryCode,
          }}
          onSubmit={async (values, { setSubmitting }) => {
            await handleFormSubmit(values);
            await setSubmitting(false);
          }}
        >
          {({ values, handleSubmit, isSubmitting, setFieldValue, errors }) => (
            <Form onSubmit={handleSubmit}>
              <div>
                <BaseInputs
                  label={t("dashboard.first-name")}
                  name={"firstName"}
                  type={"text"}
                />
              </div>

              <div>
                <BaseInputs
                  label={t("dashboard.last-name")}
                  name={"lastName"}
                  type={"text"}
                />
              </div>

              <div>
                <BasePhoneInput
                  label={t("dashboard.phone-no")}
                  name={"phone"}
                  handleChange={(val, country, formatted) => {
                    console.log(val, country, formatted);
                    setFieldValue("phone", val);
                    setFieldValue("countryCode", country);
                  }}
                />
              </div>
              <ButtonWrapperStyled>
                <BaseButton
                  htmlType="submit"
                  disabled={isSubmitting || adminLoading}
                  loading={adminLoading || adminLoading}
                  type={"primary"}
                >
                  {t("dashboard.submit")}
                </BaseButton>
              </ButtonWrapperStyled>
            </Form>
          )}
        </Formik>
      </BaseModal>

      {loading ? (
        <S.SkeletonWrapperStyled>
          <Skeleton.Avatar shape="square" size={300}></Skeleton.Avatar>
          <Skeleton></Skeleton>
        </S.SkeletonWrapperStyled>
      ) : (
        <BaseCard style={{ marginBottom: "30px" }}>
          <div style={{ marginTop: "10px" }}>
            <Row>
              <Col span={24} style={{ marginBottom: "45px" }}>
                <div
                  style={{
                    position: "relative",
                    minHeight: "60px",
                  }}
                >
                  {cityInfo?.bannerImage && (
                    <BannerImage bannerImg={cityInfo?.bannerImage} />
                  )}

                  <S.LogoWrap>
                    <LogoImage logoImg={cityInfo?.logo} />
                  </S.LogoWrap>
                </div>
              </Col>
              <BaseTypography.Title level={3} style={{ marginBottom: "0px" }}>
                {cityInfo?.title}
              </BaseTypography.Title>
              <Divider />
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <BaseTypography.Title
                    level={4}
                    style={{ width: "100%", display: "block" }}
                  >
                    {t("dashboard.admin")}
                  </BaseTypography.Title>
                  <BaseTypography.Text
                    style={{ width: "100%", display: "block" }}
                  >
                    <BaseTypography.Text type="secondary">
                      {t("remain.name")}
                    </BaseTypography.Text>{" "}
                    &nbsp;&emsp;
                    {`${root?.auth?.userDetails?.firstName} ${root?.auth?.userDetails?.lastName}`}
                  </BaseTypography.Text>
                  <BaseTypography.Text
                    style={{ width: "100%", display: "block" }}
                  >
                    <BaseTypography.Text type="secondary">
                      {t("dashboard.email")}
                    </BaseTypography.Text>{" "}
                    &nbsp;&nbsp;&emsp;
                    {root?.auth?.userDetails?.email}
                  </BaseTypography.Text>
                  <BaseTypography.Text
                    style={{ width: "100%", display: "block" }}
                  >
                    <BaseTypography.Text type="secondary">
                      {t("contact.phone")}
                    </BaseTypography.Text>{" "}
                    &emsp;+{root?.auth?.userDetails?.countryCode}&nbsp;
                    {root?.auth?.userDetails?.phone}
                  </BaseTypography.Text>
                </div>
                <div>
                  <BaseButton
                    type="link"
                    onClick={(e) => {
                      e.stopPropagation();

                      setIsEditModal({
                        _id: root?.auth?.userDetails?._id,
                        firstName: root?.auth?.userDetails?.firstName,
                        lastName: root?.auth?.userDetails?.lastName,
                        phone: root?.auth?.userDetails?.phone,
                        countryCode: root?.auth?.userDetails?.countryCode,
                      });
                    }}
                    style={{
                      marginLeft: "15px",
                      background: "var(--primary)",
                      color: "#fff",
                    }}
                  >
                    {t("dashboard.update")} {t("dashboard.profile")}
                  </BaseButton>
                </div>
              </div>
            </Row>
          </div>
        </BaseCard>
      )}
    </>
  );
};

export default LeftProfile;
const ProfileCard = styled(BaseCard)`
  height: unset;
`;
const ButtonWrapperStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  & button {
    background: var(--primary);
    color: #fff;
  }
`;
