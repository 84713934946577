import { FooterStyled } from "./style";
import { Typography } from "antd";

//@ts-ignore
import { ReactComponent as RIGHT_ARROW_SVG } from "../../assets/arrow-right.svg";
//@ts-ignore
import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";

import { BiLogoLinkedin } from "react-icons/bi";

type Props = {};

const Footer = (props: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();

  return (
    <FooterStyled>
      <div className="reserved-wrapper">
        <div className="footer-icons">
          <a
            href="https://www.linkedin.com/company/camera-compliance/"
            target="_blank"
            rel="noreferrer"
          >
            <BiLogoLinkedin className="img" />
          </a>
        </div>

        <Typography.Text className="reserved">
          <span className="term" onClick={() => navigate("/terms")}>
            {t("footer.footer-term")}
          </span>{" "}
          -{" "}
          <span className="privacy" onClick={() => navigate("/privacy")}>
            {t("footer.footer-privacy")}
          </span>{" "}
          -{" "}
          <span
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/disclaimer")}
          >
            Disclaimer
          </span>
        </Typography.Text>
        <br />
        <Typography.Text className="reserved-copy">
          © 2023 Camera Compliance Limited | Registered in Ireland company
          number: 623650
        </Typography.Text>
      </div>
    </FooterStyled>
  );
};

export default Footer;
