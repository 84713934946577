import { styled } from "styled-components";
import footerBGImage from "../../assets/footer-image.png";
import heroBGImage from "../../assets/hero-png.png";
import { devices } from "utils/theme";
import { BaseTypography } from "components/common/BaseTypography";
import { flexRowAlignCenterJustifySpaceBetween } from "utils/styles";
import { FONT_SIZE, FONT_WEIGHT } from "styles/themes/constants";

export const HomeStyled = styled.div`
  min-height: 100vh;
  background: #fcfcfc;
  overflow-x: hidden;
`;

export const ContentStyled = styled.div`
  max-width: 1160px;
  margin: 0px auto 30px;
  margin-top: 20px;
  min-height: calc(100vh - 239px);
  align-items: flex-start;
  justify-content: space-between;
  padding: 80px 30px 0px;
  flex-direction: column-reverse;

  @media (${devices.laptop}) {
    flex-direction: row;
  }

  & .subDecriptionWrapper {
    display: flex;
  }
  & .content {
    margin-right: 50px;
    @media (${devices.laptop}) {
      width: 440px;
    }
    & .content {
      margin-bottom: 10px;
      font-size: 1rem;
      font-weight: 500;
    }
    & .title {
      font-weight: 600;
    }
    & .description {
      font-weight: 400;
      font-size: 1rem;
    }

    & .subDescription {
      font-size: 1rem;
    }
  }
  & .right-contents {
    flex: 1;
    height: 100%;
    min-height: 450px;
    width: 100%;

    background-image: url(${heroBGImage});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top right;
    position: relative;

    & svg {
      position: absolute;
      top: 120px;
      width: 137px;
      height: 137px;
    }

    & .box-with-text {
      position: absolute;
      left: 0px;
      transform: translate(calc(100% - 40px), 0px);
      border-radius: 20px;
      background: rgba(22, 122, 139, 0.63);
      color: #f5f5f5;
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      width: 157px;
      height: 147px;
      padding: 24px 15px;
      overflow: hidden;
    }
  }
`;

export const FooterStyled = styled.div`
  flex-shrink: 0;

  & .footer-contents {
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 82px 30px;

    & .title {
      color: #167a8b;
      font-size: 23px;
      font-weight: bold;
      line-height: 30px;
      margin-bottom: 24px;
      text-align: center;
    }
    & .desc {
      color: #576874;
      font-size: 1rem;
      font-weight: 400;
      margin-bottom: 10px;

      line-height: 24px;
      margin-bottom: 30px;
    }

    & button {
      background: #167a8b;
      color: #fff;
      display: flex;
      align-items: center;
      padding: 20px 12px;
      font-size: 1rem;

      & svg {
        margin-left: 10px;
        width: 23px;
        height: 22px;
      }

      &:hover {
        background: #167a8b;
        color: #fff;
      }
    }
  }

  & .reserved-wrapper {
    width: 100%;
    background: #07121d;
    text-align: center;
    padding: 40px 0px;
    font-size: 1rem;
    & .reserved {
      color: #fff;
    }
    & .reserved-copy {
      color: gray;

      & .term {
        cursor: pointer;
      }
      & .privacy {
        cursor: pointer;
      }
    }
    & .footer-icons {
      display: flex;
      text-align: center;
      justify-content: center;
      gap: 15px;
      margin-bottom: 20px;

      & .img {
        height: 45px;
        width: 45px;
        text-align: center;
        align-items: center;
        justify-content: center;
        background-color: white;
        color: black;
        padding: 10px;
        border-radius: 50%;
        cursor: pointer;
      }
    }
  }
`;

export const IconStyled = styled.span`
  color: var(--white);
`;
export const ExploreBtnWrapper = styled.div`
  ${flexRowAlignCenterJustifySpaceBetween}
`;

export const ExploreText = styled(BaseTypography)`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  color: var(--white);
`;
