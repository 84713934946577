import { BaseCard } from "components/common/BaseCard";
import React, { useCallback, useEffect, useState } from "react";
import BaseButton from "components/common/BaseButton";
import { BaseTypography } from "components/common/BaseTypography";
import BaseModal from "components/common/BaseModal";
import publicViewApi from "shared/front/apis/publicView.api";
import request from "utils/request";
import { useDispatch, useSelector } from "react-redux";
import store from "store";
import { AutoComplete, Spin } from "antd";
import { getI18n } from "react-i18next";
import { debounce } from "lodash";
import { useParams } from "react-router-dom";
import QR from "shared/front/apis/qr.api";
import publicPage from "shared/front/apis/public-page.api";
import { styled } from "styled-components";
import useAPI from "hooks/useApi";
import { FONT_SIZE, FONT_WEIGHT } from "styles/themes/constants";
import { convertTimestamp } from "utils/utils";
import { BaseTag } from "components/common/BaseTag";
import { FaCheck, FaCheckCircle } from "react-icons/fa";
import PublicViewRender from "containers/GeneratedQRDetails/PublicViewRender";
import BaseLoading from "components/common/BaseSpin";

const PublicViewInfo = ({
  isSelected,
  publicViewId,
  setPublicViewId,
  radioValue,
}: any) => {
  const dispatch = useDispatch();
  const { language: selectedlang } = getI18n();
  const [
    makeRequest,
    { loading: publicViewloading, data: publicViewDataById },
  ] = useAPI();

  const getPublicViewDataById = async (publicViewId) => {
    await makeRequest(publicPage.getPublicPageById(publicViewId));
  };

  useEffect(() => {
    if (publicViewId) {
      getPublicViewDataById(publicViewId);
    }
  }, [radioValue, publicViewId]);
  const initialParams = {
    limit: 10,
    page: 0,
  };
  const publicViews = useSelector((state: any) => state?.publicViews?.data);

  const publicViewData = useSelector((state: any) => state?.publicViewDataById);
  console.log("the publicview data", publicViewData);
  const [loading, setLoading] = useState({
    publicViewLoading: false,
  });

  const delayedFetchPublicView = useCallback(
    debounce((params) => {
      fetchAllPublicPages(params);
    }, 500),
    []
  );
  const publicViewsPagination = useSelector(
    (state: any) => state?.publicViews?.pagination
  );
  const fetchAllPublicPages = async (params) => {
    setLoading((prev) => ({ ...prev, publicViewLoading: true }));
    const actionType = publicViewsPagination?.page === 0 ? "set" : "update";
    await request(
      publicViewApi.getAllPublicViews(actionType, {
        limit: params.limit,
        page: params.page,
        title: params.title,
        lang: params.lang,
      })
    );

    setLoading((prev) => ({ ...prev, publicViewLoading: false }));
  };
  const getPublicViewData = async () => {
    dispatch(store.Actions.set("publicViews.pagination", initialParams));
    fetchAllPublicPages(initialParams);
  };

  const getSelectedLanguageTitle = (details) => {
    if ((Array.isArray(details) && details.length === 0) || !details) {
      return "N/A";
    }

    const selectedLangTitle = details.find((x) => x?.lang === selectedlang);
    if (!!selectedLangTitle?.title) {
      return selectedLangTitle?.title;
    }
    return details[0].title;
  };

  const publicViewOptions = () => {
    const options = [];
    if (loading?.publicViewLoading) {
      options.push({
        label: <Spin size="small" />,
        value: null,
        disabled: true,
      });
    }

    if (Array.isArray(publicViews) && publicViews?.length > 0) {
      return [
        ...options,
        ...publicViews.map((val) => ({
          value: getSelectedLanguageTitle(val?.details),
          label: getSelectedLanguageTitle(val?.details),
          id: val._id,
        })),
      ];
    }
    return [
      {
        value: null,
        label: "no data found",
        disaled: true,
      },
    ];
  };

  console.log("the options values", publicViewOptions());

  const onSearchPublicView = (text) => {
    delayedFetchPublicView({
      ...initialParams,
      title: text,
      lang: selectedlang,
    });
  };

  const getPublicViewPageDetailById = async (id) => {
    await request(publicPage.getPublicPageById(id));
  };

  useEffect(() => {
    if (publicViewId) {
      getPublicViewPageDetailById(publicViewId);
    }
  }, [publicViewId]);

  useEffect(() => {
    getPublicViewData();
  }, []);

  // if (publicViewloading) {
  //   return <BaseLoading />;
  // }
  const getValue = (publicViewId) => {
    const res = publicViewOptions().filter((item) => item.id === publicViewId);
    return res[0].label;
  };

  return (
    <>
      <CameraInfoStyled>
        <div className="camera-wrapper">
          <BaseTypography.Title level={4} style={{ marginBottom: "-10px" }}>
            Public Page{" "}
            {isSelected ? <FaCheckCircle color="green" size={14} /> : null}
          </BaseTypography.Title>
          <div className="no-camera-wrapper">
            <BaseTypography.Text>
              Add public page to associated with this QR.
            </BaseTypography.Text>
          </div>
          <AutoComplete
            value={publicViewId && getValue(publicViewId)}
            options={publicViewOptions()}
            style={{ width: "50%" }}
            placeholder={"Search public page"}
            onSearch={(text) => onSearchPublicView(text)}
            onSelect={(value, key) => {
              if (!!key) setPublicViewId(key.id);
            }}
            onChange={(key, value) => {
              if (!!key) {
                setPublicViewId(key.id);
              }
            }}
          />
          {!!publicViewDataById && (
            <div>
              <PublicViewRender publicViewDataById={publicViewDataById} />
            </div>
          )}
        </div>
      </CameraInfoStyled>
    </>
  );
};

export default PublicViewInfo;
const CameraInfoStyled = styled.div`
  padding: 20px;
  & .no-camera-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px 0px 20px;
  }

  & .camera-wrapper {
    & .camera-info-row {
      display: flex;
      align-items: center;
      margin-bottom: 6px;
      &-title {
        width: 120px;
        font-weight: 600;
        color: #666;
      }
      &-value {
        position: relative;
      }
    }
  }
`;

const NoLinkFound = styled.div`
  text-align: center;
  font-style: italic;
  color: var(--gray);
  font-size: ${FONT_SIZE.md};
`;

const PublicPageCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;

const LeftPartDetails = styled.div``;
const InfoWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  & .label {
    width: 90px;
    font-weight: 600;
    color: #444;
  }
  & .value {
  }
`;

export const LanguageContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Flag = styled.span`
  font-size: 8px;
  margin-right: 4px;
  font-family: "Twemoji Country Flags";
`;

export const LanguageValue = styled.span`
  font-size: ${FONT_SIZE.xxs};
  font-weight: ${FONT_WEIGHT.regular};
`;
export const LanguagesRow = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5px;
`;
