import React, { useRef, useState } from "react";
import Header from "./Header";
import { HomeStyled } from "./style";
import Hero from "./Hero";
import Footer from "./Footer";
import InfoContainer from "./InfoContainer";
import Faq from "./FAQ";
import Testimonial from "./Testimonial";
import MainHeader from "layouts/Main/MainHeader/MainHeader";
import Connect from "./Connect";
import Features from "./Features";
import ProfileHeader from "layouts/ProfileHeader";
import AboutUs from "./AboutUs";
import UniqueFeatures from "./UniqueFeatures";
import Solutions from "./Solutions";
import ChooseCamera from "./ChooseCamera";
import Section from "./Section";
import Blogs from "./blogs";

const Home = () => {
  const blogsRef = useRef(null);

  const [activeNav, setActiveNav] = useState("Home");

  const onHomePress = () => {
    setActiveNav("Home");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const onNewsPress = () => {
    setActiveNav("News");
    blogsRef?.current.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  return (
    <HomeStyled>
      <MainHeader
        onHomePress={onHomePress}
        onNewsPress={onNewsPress}
        activeNav={activeNav}
      />
      <Hero />
      <AboutUs />
      <Solutions />
      <ChooseCamera />
      <Features />
      {/* <Testimonial /> */}
      <UniqueFeatures />
      <Connect />
      <Blogs ref={blogsRef} />
      <Section />
      {/* <Faq /> */}
      <Footer />
    </HomeStyled>
  );
};

export default Home;
