import { BaseTypography } from "components/common/BaseTypography";
import { BaseInputNumber } from "components/common/BaseInputNumber";
import React from "react";
import Card from "./Card";
import { useResponsive } from "hooks/useResponsive";
import { useTranslation } from "react-i18next";
import BaseColorPicker from "components/common/BaseColorPricker";

const QROptions = ({ qrOptions, setQROptions }: any) => {
  const { t } = useTranslation();
  const { isTablet, useMediaQuery } = useResponsive();

  return (
    <Card
      noMargin
      style={{
        margin: isTablet ? "0px 0px 10px 0px" : "0px 10px 0px 0px",
        minWidth: useMediaQuery({
          query: `(max-width: ${410 - 0.02}px)`,
        })
          ? "auto"
          : "225px",
        flex: 1,
      }}
    >
      <div
        style={{
          display: "flex",
          marginTop: "5px",
          flexDirection: isTablet ? "column" : "row",
          // alignItems: isTablet ? "start" : "center",
          gap: isTablet ? "2px" : "8px",
          flexWrap: "wrap",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: isTablet ? "0px 0px 8px" : "0px 8px 0px 0px",
          }}
        >
          <BaseColorPicker
            colorPickerWrapperStyle={{
              width: "190px",
            }}
            label="QR Color"
            value={qrOptions.color.dark}
            onChange={(value) => {
              let rbgaValue = value.toHexString();

              if (value.metaColor?.roundA === 0) {
                rbgaValue = rbgaValue.slice(0, -2) + "01";
              }
              setQROptions((prev) => ({
                ...prev,
                color: {
                  ...prev.color,
                  dark: rbgaValue,
                },
              }));
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: isTablet ? "0px 0px 8px" : "0px 8px 0px 0px",
          }}
        >
          <BaseColorPicker
            colorPickerWrapperStyle={{
              width: "190px",
            }}
            label="QR Background Color"
            value={qrOptions.color.light}
            onChange={(value) => {
              let rbgaValue = value.toHexString();

              if (value.metaColor?.roundA === 0) {
                rbgaValue = rbgaValue.slice(0, -2) + "01";
              }
              setQROptions((prev) => ({
                ...prev,
                color: {
                  ...prev.color,
                  light: rbgaValue,
                },
              }));
            }}
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: isTablet ? "0px 0px 8px" : "0px 8px 0px 0px",
          }}
        >
          <BaseTypography.Text
            style={{
              marginBottom: isTablet ? "0px" : "10px",
            }}
          >
            Margin
          </BaseTypography.Text>
          <BaseInputNumber
            value={qrOptions.margin}
            min={0}
            onChange={(value) => {
              setQROptions((prev) => ({
                ...prev,
                margin: value ?? 0,
              }));
            }}
            style={{
              height: isTablet ? "auto" : "38px",
            }}
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: isTablet ? "0px 0px 8px" : "0px 8px 0px 0px",
          }}
        >
          <BaseTypography.Text
            style={{
              marginBottom: isTablet ? "0px" : "10px",
            }}
          >
            Border Radius
          </BaseTypography.Text>
          <BaseInputNumber
            style={{
              height: isTablet ? "auto" : "38px",
            }}
            value={qrOptions.borderRadius}
            min={0}
            onChange={(value) => {
              setQROptions((prev) => ({
                ...prev,
                borderRadius: value ?? 0,
              }));
            }}
          />
        </div>
      </div>
    </Card>
  );
};

export default QROptions;
