import BaseModal from "components/common/BaseModal";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import * as S from "./index.styles";
import { Col, Input, message } from "antd";
import BaseInputs from "components/common/BaseInput/BaseInputs";
import { BaseTypography } from "components/common/BaseTypography";
import BaseButton from "components/common/BaseButton";
import BaseError from "components/common/BaseError";
import useAPI from "hooks/useApi";
import admin from "shared/front/apis/admin.api";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { generatePassword } from "services/generatePassword";
import BaseUploader from "components/common/BaseUploaders";
import { CopyOutlined } from "@ant-design/icons";
import useClipboard from "hooks/useClipboard";
import { ButtonWrapper } from "containers/QrDetailPublicView/index.styles";
import BasePhoneInput from "components/common/BasePhoneInput";
//@ts-ignore
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import type { Dispatch } from "redux";
import store from "store";

const AddAdminModal = ({
  onRefresh,
  isModalVisible,
  setIsModalVisible,
  closeModal,
  openModal,
  citySelect,
}: any) => {
  const { t } = useTranslation();
  const [isCopied, copyToClipboard] = useClipboard();
  const [messageApi, contextHolder] = message.useMessage();
  const [
    makeRequest,
    { loading: addOperatorLoading, error: addOperatorErrors },
  ] = useAPI();
  const [getRequest, { loading: allCityLoading, data: allCityData }] = useAPI();
  const dispatch: Dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required(t("forms.name-valid"))
      .min(2, t("forms.name-min-valid"))
      .max(30, t("forms.name-max-valid")),
    lastName: Yup.string()
      .required(t("forms.lastname-valid"))
      .min(2, t("forms.lastname-min-valid"))
      .max(30, t("forms.lastname-max-valid")),
    email: Yup.string()
      .email(t("forms.email-invalid"))
      .required(t("forms.email-required")),
    password: Yup.string().required(t("forms.password-generate")),
    phone: Yup.string().required(t("forms.phone-number")),
    cityId: Yup.string().required(t("forms.city-name-valid")),
  });

  const handleFormSubmit = async (values: any) => {
    try {
      const {
        cityId,
        firstName,
        lastName,
        email,
        password,
        phone,
        countryCode,
      } = values;
      const resp = await makeRequest(
        admin.addAdmin({
          cityId,
          firstName,
          lastName,
          email,
          password,
          phone,
          countryCode,
        })
      );
      if (!!resp?.firstName) {
        closeModal();
        onRefresh();
        toast.success(
          `${t("toast.admin")} ${resp?.firstName} ${t(
            "toast.is-successfully-added"
          )}.`
        );
      }
    } catch (error: any) {
      toast.error(error);
    }
  };

  const handleGeneratePassword = (setFieldValue: any) => {
    const password = generatePassword();
    setFieldValue("password", `${password}`);
  };

  const handleCopyToClipboard = (value: any) => {
    copyToClipboard(value);
  };

  const onClipBoardSuccess = () => {
    messageApi.open({
      type: "success",
      content: t("forms.copied-successfully"),
      style: {
        marginTop: "60px",
      },
    });
  };

  return (
    <>
      {contextHolder}
      <BaseModal
        title={t("dashboard.add-admin")}
        open={isModalVisible}
        onCancel={closeModal}
        footer={[]}
        width={500}
      >
        <Formik
          initialValues={{
            cityId: !!citySelect ? citySelect : "",
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            phone: "",
            countryCode: "",
          }}
          onSubmit={async (values, { setSubmitting }) => {
            await handleFormSubmit(values);
            await setSubmitting(false);
            dispatch(store.Actions["set"]("cities.data", []));
            dispatch(
              store.Actions["set"]("cities.pagination", {
                search: "",
                limit: 10,
                page: 0,
                count: 0,
              })
            );
          }}
          validationSchema={validationSchema}
          validateOnBlur={false}
        >
          {({
            values,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            errors,
            touched,
          }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <S.RowWrapper align={"middle"}>
                  <Col span={24}>
                    {!!citySelect ? "" : <BaseUploader name={"cityId"} />}
                  </Col>
                  <Col span={24}>
                    <BaseInputs
                      label={t("dashboard.first-name")}
                      name={"firstName"}
                      type={"text"}
                    />
                  </Col>
                  <Col span={24}>
                    <BaseInputs
                      label={t("dashboard.last-name")}
                      name={"lastName"}
                      type={"text"}
                    />
                  </Col>
                  <Col span={24}>
                    <BaseInputs
                      label={t("dashboard.email")}
                      name={"email"}
                      type={"text"}
                    />
                  </Col>
                  <Col span={24}>
                    <BasePhoneInput
                      label={t("dashboard.phone-no")}
                      name={"phone"}
                      handleChange={(val, country) => {
                        setFieldValue("phone", val);
                        setFieldValue("countryCode", country);
                      }}
                    />
                  </Col>

                  <BaseTypography.Text>
                    {" "}
                    {t("dashboard.generate-password")}
                  </BaseTypography.Text>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <BaseButton
                      onClick={() => handleGeneratePassword(setFieldValue)}
                      style={{
                        marginRight: "10px",
                        flex: 1,
                        background: "var(--primary)",
                        color: "#fff",
                      }}
                    >
                      {t("dashboard.generate-password")}
                    </BaseButton>
                    <Input
                      name={"password"}
                      type={"text"}
                      value={values?.password}
                      style={{
                        flex: 2,
                        backgroundColor: "#f5f5f5",
                        color: "#333",
                        fontWeight: "bold",
                        fontFamily: "Courier New, monospace",
                      }}
                      onChange={(e) =>
                        setFieldValue("password", e.target.value)
                      }
                    />
                    <div style={{ width: "40px" }}>
                      {values.password && (
                        <CopyOutlined
                          style={{
                            fontSize: "20px",
                            marginLeft: "10px",
                            padding: "10px",
                          }}
                          onClick={() => {
                            handleCopyToClipboard(values?.password);
                            onClipBoardSuccess();
                          }}
                        />
                      )}
                    </div>
                  </div>

                  {errors?.password && !!touched.password && (
                    <BaseError>{errors.password}</BaseError>
                  )}
                </S.RowWrapper>

                <BaseError>{addOperatorErrors}</BaseError>
                <S.ButtonWrapperStyled>
                  <BaseButton
                    htmlType="submit"
                    loading={addOperatorLoading || addOperatorLoading}
                    disabled={addOperatorLoading}
                    type={"primary"}
                  >
                    {t("dashboard.submit")}
                  </BaseButton>
                </S.ButtonWrapperStyled>
              </Form>
            );
          }}
        </Formik>
      </BaseModal>
    </>
  );
};

export default AddAdminModal;
