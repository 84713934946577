const data = {
  success: true,
  data: {
    _id: "64b765ecedc663fa1047cc34",
    admin: {
      location: {
        type: "Point",
        coordinates: [],
      },
      _id: "64b6dbc5e0433f6cd5a506af",
      firstName: "alfa",
      lastName: "beta",
      email: "alfa@gmail.com",
      initialPassword: "3000153661",
      role: "Admin",
      onboardingStep: 1,
      isVerified: true,
      isFirstLogin: false,
      isBlocked: false,
      createdAt: "2023-07-18T18:36:53.718Z",
      updatedAt: "2023-07-18T18:37:49.821Z",
      __v: 0,
    },
    operator: {
      location: {
        type: "Point",
        coordinates: [],
      },
      _id: "64b6df12e0433f6cd5a50718",
      city: "64b6dbb6e0433f6cd5a506a4",
      firstName: "operator",
      lastName: "surname",
      email: "operator@gmail.com",
      initialPassword: "0110180223",
      role: "Operator",
      onboardingStep: 1,
      isVerified: true,
      isFirstLogin: false,
      isBlocked: false,
      createdAt: "2023-07-18T18:50:58.124Z",
      updatedAt: "2023-07-18T18:50:58.124Z",
      __v: 0,
    },
    city: {
      _id: "64b6dbb6e0433f6cd5a506a4",
      superAdmin: "64b648861103342dcc9ae7ce",
      title: "netherland",
      isUpdated: true,
      operators: ["64b6df12e0433f6cd5a50718"],
      createdAt: "2023-07-18T18:36:38.531Z",
      updatedAt: "2023-07-18T19:20:49.976Z",
      __v: 0,
      assignBy: "64b6dbc5e0433f6cd5a506af",
      admin: "64b6dbc5e0433f6cd5a506af",
      desc: "ddsfsa fsd fsd fsd fsd fds fds",
      image:
        "https://rentisity-stage.s3.eu-west-1.amazonaws.com/d84767c5-4186-41b8-a78a-362d11c61b83",
    },
    QRCode: {
      location: {
        type: "Point",
        coordinates: [],
      },
      _id: "64b765b6edc663fa1047cc0b",
      URL: "https://rentisity-stage.s3.eu-west-1.amazonaws.com/fcd31f53-c311-4077-ac1a-7d32218eab1b",
      status: "New",
      isAssigned: false,
      isCompleted: false,
      isDownloaded: false,
      count: 1,
      createdAt: "2023-07-19T04:25:26.117Z",
      updatedAt: "2023-07-19T04:25:29.250Z",
      __v: 0,
      admin: "64b6dbc5e0433f6cd5a506af",
      frontendURL: "undefined/qr/64b765b6edc663fa1047cc0b",
      city: "64b6dbb6e0433f6cd5a506a4",
      sticker: "64b6dfc0e0433f6cd5a5072c",
      stickerURL:
        "https://rentisity-stage.s3.eu-west-1.amazonaws.com/3d5e5143-567d-46b0-a9a6-f8ecf097881b",
    },
    latitude: 33.5434213789,
    longitude: 55.345233217,
    address: "Kathmandu, Narephat",
    status: "Requested",
    createdAt: "2023-07-19T04:26:20.034Z",
    updatedAt: "2023-07-19T04:26:20.034Z",
    __v: 0,
  },
};
export default data;
