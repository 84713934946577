export default [
  {
    title: "1. Information We Collect:",
    desc: [
      {
        title: "1.1",
        desc: "Personal Information: When you interact with our camera compliance project, we may collect personal information such as your name, email address, and contact information.",
      },
      {
        title: "1.2",
        desc: " Device Information: We may collect information about the device you use to access our services, including device type, operating system, browser type, and IP address.",
      },
      {
        title: "1.3",
        desc: " Location Information: With your explicit consent, we may collect location information from your device to provide you with accurate compliance information related to the city and area you are in.",
      },
      {
        title: "1.4",
        desc: "Camera Scanning Information: When you scan a QR code on a pole with a camera, we may collect data related to the camera’s location, date and time of the scan, and the compliance information retrieved.",
      },
    ],
  },
  {
    title: "2. How We Use Your Information:",
    desc: [
      {
        title: "2.1",
        desc: " We use the collected information to provide you with accurate compliance information related to the city area where you have scanned the QR code.",
      },
      {
        title: "2.2",
        desc: " We may use your personal information to send you updates, notifications, and important information related to the camera compliance project.",
      },
      {
        title: "2.3",
        desc: " We analyze aggregated and anonymized data for statistical purposes, which may help us improve our services.",
      },
    ],
  },

  {
    title: "3. Information Sharing:",
    desc: [
      {
        title: "3.1",
        desc: " We do not share your personal information with third parties for marketing purposes without your explicit consent.",
      },
      {
        title: "3.2",
        desc: "We may share your information with our service providers who assist us in delivering our services. These service providers are obligated to maintain the confidentiality of your information.",
      },
      {
        title: "3.3",
        desc: "We may disclose your information if required by law or to protect our rights, privacy, safety, or property.",
      },
    ],
  },

  {
    title: "4. Data Security:",
    desc: [
      {
        title: "4.1",
        desc: "We implement security measures to protect your personal information against unauthorized access, alteration, disclosure, or destruction.",
      },
      {
        title: "4.2",
        desc: "Despite our efforts, no online transmission or storage is completely secure. Therefore, we cannot guarantee the absolute security of your information.",
      },
    ],
  },

  {
    title: "5. Your Choices:",
    desc: [
      {
        title: "5.1",
        desc: "You can opt out of receiving marketing communications from us by following the unsubscribe instructions provided in the communication.",
      },
      {
        title: "5.2",
        desc: " You can control the collection of location information by adjusting the settings on your device.",
      },
    ],
  },

  {
    title: "6. Children’s Privacy:",
    desc: [
      {
        title: "6.1",
        desc: "Our services are not intended for individuals under the age of [age]. We do not knowingly collect personal information from children.",
      },
    ],
  },

  {
    title: "7. Changes to this Privacy Policy:",
    desc: [
      {
        title: "7.1",
        desc: " We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons.",
      },
      {
        title: "7.2",
        desc: " We will provide prominent notice of any material changes to this Privacy Policy.",
      },
    ],
  },
  {
    title: "8. Contact Us:",
    desc: [
      {
        desc: "If you have any questions, concerns, or requests regarding this Privacy Policy or the use of your personal information, please contact us at [contact email].",
      },
    ],
  },
];
