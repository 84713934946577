import React from "react";
import { FC } from "react";
import { StatisticsCard } from "./statisticsCard/StatisticsCard/StatisticsCard";
import { Col, Row } from "antd";
import {
  QrcodeOutlined,
  FileOutlined,
  TeamOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";
import { useRoot } from "RootProvider";
import { ADMIN, SUPER_ADMIN } from "constants/roles/roles";
import { styled } from "styled-components";
import { BiCamera } from "react-icons/bi";
import { useTranslation } from "react-i18next";

export type StatisticColor =
  | "primary"
  | "error"
  | "secondary"
  | "success"
  | "tairo"
  | "skyblue"
  | "lightPurple"
  | "lightBlue"
  | "lightGreen"
  | "lightYellow"
  | "lightOrange";

interface ConfigStatistic {
  id: number;
  name: string;
  title: string;
  color: StatisticColor;
  Icon: FC;
  value: number;
}

export const StatisticsCards = ({ stats }: any) => {
  const { role } = useRoot();
  const { t } = useTranslation();

  const statistics: ConfigStatistic[] = [
    {
      id: 1,
      name: t("dashboard.sticker"),
      title: "sticker",
      // color: "primary",
      color: "lightPurple",
      value: stats?.stickerCount,
      Icon: FileOutlined,
    },
    {
      id: 2,
      name: t("dashboard.accepted-qr"),
      title: "accepted",
      // color: "tairo",
      color: "lightGreen",
      value: stats?.QRCount,
      Icon: QrcodeOutlined,
    },
    {
      id: 3,
      name: t("dashboard.operator"),
      title: "operator",
      // color: "skyblue",
      color: "lightBlue",
      value: stats?.operatorCount,
      Icon: TeamOutlined,
    },
  ];
  // Add the "City" statistic only if the role is SUPER_ADMIN
  if (role === SUPER_ADMIN) {
    statistics.push({
      id: 4,
      name: t("dashboard.city"),
      title: "city",
      // color: "secondary",
      color: "lightOrange",
      value: stats?.cityCount,
      Icon: EnvironmentOutlined,
    });
  } else {
    statistics.push({
      id: 5,
      name: t("dashboard.camera"),
      title: "camera",
      // color: "secondary",
      color: "lightOrange",
      value: stats?.cameraCount,
      Icon: BiCamera,
    });
  }

  return (
    <React.Fragment>
      <RowWrapper
        gutter={[
          { xs: 8, sm: 12, md: 16, lg: 30 },
          { xs: 8, sm: 12, md: 16, lg: 30 },
        ]}
      >
        {statistics?.map((st: any, index: any) => (
          <Col
            key={st.id}
            id={st.name}
            xs={12}

            // md={index === statistics.length - 1 ? 0 : 8}
            // order={(isTablet && index + 1) || 0}
          >
            <StatisticsCard
              name={st.name}
              value={st.value}
              prevValue={st.prevValue}
              color={st.color}
              unit={st.unit}
              Icon={st.Icon}
            />
          </Col>
        ))}
      </RowWrapper>
    </React.Fragment>
  );
};
const RowWrapper = styled(Row)`
  // margin-top: 1.9rem;
`;
