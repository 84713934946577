export const COUNTRIES = [
  {
    flag: "🇬🇧",
    countryName: "United Kingdom",
    language: "en",
    languageFullName: "English",
  },
  {
    flag: "🇩🇪",
    countryName: "Germany",
    language: "de",
    languageFullName: "German",
  },
  {
    flag: "🇫🇮",
    countryName: "Finland",
    language: "fi",
    languageFullName: "Finnish",
  },
  {
    flag: "🇫🇷",
    countryName: "France",
    language: "fr",
    languageFullName: "French",
  },
  {
    flag: "🇪🇸",
    countryName: "Spain",
    language: "es",
    languageFullName: "Spanish",
  },
  {
    flag: "🇮🇹",
    countryName: "Italy",
    language: "it",
    languageFullName: "Italian",
  },
  {
    flag: "🇵🇱",
    countryName: "Poland",
    language: "pl",
    languageFullName: "Polish",
  },
  {
    flag: "🇷🇴",
    countryName: "Romania",
    language: "ro",
    languageFullName: "Romanian",
  },
  {
    flag: "🇸🇮",
    countryName: "Slovenia",
    language: "si",
    languageFullName: "Slovenian",
  },
  {
    flag: "🇸🇪",
    countryName: "Sweden",
    language: "sv",
    languageFullName: "Swedish",
  },
];

export const LANGUAGES = {
  en: COUNTRIES.map((country) => ({
    value: country.language,
    label: country.countryName,
    flag: country.flag,
    languageFullName: country.languageFullName,
  })),
  de: COUNTRIES.map((country) => ({
    value: country.language,
    label: country.countryName,
    flag: country.flag,
    languageFullName: country.languageFullName,
  })),
  COUNTRIES,
};
