import { Col, Modal, Row } from "antd";
import { BaseTypography } from "components/common/BaseTypography";
import React, { useState } from "react";
import BaseModal from "components/common/BaseModal";
import BaseInputs from "components/common/BaseInput/BaseInputs";
import { ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";
import BaseButton from "components/common/BaseButton";
import useAPI from "hooks/useApi";
import operators from "shared/front/apis/operator.api";
import { last } from "lodash";
import BaseError from "components/common/BaseError";
import { toast } from "react-toastify";
import { generateRandomNumber } from "utils/utils";
import { generate } from "@wcj/generate-password";
import { BaseCheckbox } from "components/common/BaseCheckbox/BaseCheckbox";
import { generatePassword } from "services/generatePassword";
import { styled } from "styled-components";
import BaseInputsNumbers from "components/common/BaseInputNumber/BaseInputNumbers";
import { ButtonFlexEnd } from "utils/styles";
import * as yup from "yup";
import cameraApi from "shared/front/apis/camera";
import request from "utils/request";
import store from "store";
import { useTranslation } from "react-i18next";

// const validationSchema = yup.object().shape({
//   latitude: yup
//     .string()
//     .required("Latitude is required.")
//     .matches(
//       /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)$/,
//       "Invalid latitude format. Latitude must be between -90 and 90."
//     ),

//   longitude: yup
//     .string()
//     .required("Longitude is required.")
//     .matches(
//       /^[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/,
//       "Invalid longitude format. Longitude must be between -180 and 180."
//     ),
//   name: Yup.string().required("Name is required."),
//   address: Yup.string().required("Address is required."),
//   serialNumber: Yup.string().required("SerialNumber is required."),
// });

const AddCameraModal = ({
  isModalOpen,
  setIsModalOpen,
  onRefresh,
  cameraEditData,
  cameraID,
}: IOperatorProps) => {
  const { t } = useTranslation();

  const validationSchema = yup.object().shape({
    latitude: yup
      .string()
      .required(t("forms.camera-latitude"))
      .matches(
        /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)$/,
        t("forms.latitude-format")
      ),

    longitude: yup
      .string()
      .required(t("forms.camera-longitude"))
      .matches(
        /^[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/,
        t("forms.longitude-format")
      ),
    address: Yup.string().required(t("forms.camera-address")),
    serialNumber: Yup.string(),
    name: Yup.string().required(t("remain.name-is-required")),
  });
  const [makeRequest, { loading: addOperatorLoading, error }] = useAPI();
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const handleFormSubmit = async (values: any) => {
    try {
      await makeRequest(cameraApi.addCamera(values));
      onRefresh();
    } catch (error: any) {
      toast.error(error);
    }
  };

  const handleUpdateCamera = async (updatedCameraEditData) => {
    const response = await request(
      cameraApi.updateCamera({
        data: {
          name: updatedCameraEditData?.name,
          address: updatedCameraEditData?.address,
          serialNumber: updatedCameraEditData?.serialNumber,
          longitude: updatedCameraEditData?.longitude,
          latitude: updatedCameraEditData?.latitude,
        },
        cameraId: cameraID,
      })
    );
    if (response) {
      store.store.dispatch(store.Actions.set("selectedCamera", response));
      onRefresh();
      closeModal();
    }
  };

  const handleGeneratePassword = (setFieldValue: any) => {
    const password = generatePassword();
    setFieldValue("password", `${password}`);
  };

  return (
    <>
      {isModalOpen && (
        <BaseModal
          title={
            cameraID
              ? t("dashboard.edit-camera-location")
              : t("dashboard.add-camera-location")
          }
          open={isModalOpen}
          onCancel={closeModal}
          footer={[]}
          width={500}
        >
          <Formik
            initialValues={{
              address: cameraEditData?.address || "",
              serialNumber: cameraEditData?.serialNumber || "",
              latitude: cameraEditData?.latitude || "",
              longitude: cameraEditData?.longitude || "",
              name: cameraEditData?.name || "",
            }}
            onSubmit={async (values, { setSubmitting }) => {
              if (cameraID) {
                handleUpdateCamera(values);
              } else {
                await handleFormSubmit(values);
                await setSubmitting(false);
                setIsModalOpen(false);
              }
            }}
            validationSchema={validationSchema}
            validateOnBlur={false}
          >
            {({
              values,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              errors,
            }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <RowWrapper align={"middle"}>
                    <Col span={24}>
                      <BaseInputs
                        label={t("dashboard.name")}
                        name={"name"}
                        type={"text"}
                        placeholder={t("dashboard.name")}
                      />
                    </Col>
                    <Col span={24}>
                      <BaseInputs
                        label={t("dashboard.address")}
                        name={"address"}
                        type={"text"}
                        // placeholder={t("dashboard.not-available")}
                      />
                    </Col>
                    <Col span={24}>
                      <BaseInputs
                        label={t("dashboard.serialnumber")}
                        name={"serialNumber"}
                        type={"text"}
                        // placeholder={t("dashboard.not-available")}
                      />
                    </Col>
                    <Col span={24}>
                      <BaseInputs
                        label={t("dashboard.latitude")}
                        name={"latitude"}
                        type={"text"}
                        value={values.latitude}
                      />
                    </Col>
                    <Col span={24}>
                      <BaseInputs
                        label={t("dashboard.longitude")}
                        name={"longitude"}
                        type={"text"}
                        value={values.longitude}
                      />
                    </Col>
                  </RowWrapper>

                  <ButtonFlexEnd>
                    <BaseButton
                      htmlType="submit"
                      // disabled={isSubmitting || addOperatorLoading}
                      loading={addOperatorLoading || addOperatorLoading}
                      type={"primary"}
                      style={{ background: "var(--primary)" }}
                    >
                      {cameraID ? t("dashboard.update") : t("dashboard.submit")}
                    </BaseButton>
                  </ButtonFlexEnd>
                </Form>
              );
            }}
          </Formik>
        </BaseModal>
      )}
    </>
  );
};

export default AddCameraModal;

interface IOperatorProps {
  isModalOpen: boolean;
  cameraID: string;
  cameraEditData: any;
  setIsModalOpen: (value: boolean) => void;
  onRefresh: () => void;
}

const RowWrapper = styled(Row)`
  margin: 1rem 0rem;
`;
