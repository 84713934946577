export default [
  {
    title: "Limitation of Liability",
    desc: [
      "Camera Compliance Ltd, its affiliates, partners, and employees shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages, including without limitation, financial loss, loss of data, or any other damages arising out of your use of this website or reliance on the informational content provided.",
    ],
  },
  {
    title: "No Professional Advice",
    desc: [
      "The information provided on this website does not constitute legal, technical, or other professional advice. While we strive to provide accurate and up-to-date information, we make no warranties or representations of any kind as to the website’s accuracy, currency, or completeness.",

      "For detailed advice tailored to your situation, consult with qualified professionals, including but not limited to, legal professionals experienced in data protection and GDPR compliance.",
    ],
  },
  {
    title: "Governing Law",
    desc: [
      "This waiver shall be governed by and construed in accordance with the laws of the jurisdiction in which Camera Compliance Ltd operates. By using this website, you consent to the jurisdiction of such courts for any actions, suits, or proceedings arising out of this waiver.",
    ],
  },
];
