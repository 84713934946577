import React, { useState, useEffect } from "react";
import { Card, Divider, Tabs } from "antd";
import useAPI from "hooks/useApi";
import city from "shared/front/apis/city.api";
import { BaseTypography } from "components/common/BaseTypography";
import HeaderWrapper from "components/headerwrapper";
import { getI18n, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import dashboard from "shared/front/apis/dashboard.api";
import { toast } from "react-toastify";
import ActionButton from "components/ActionWrapper/ActionButton";
import AlterActionWrapper from "components/ActionWrapper/AlterActionWrapper";
import Editor from "components/Editor/Editor";
import { COUNTRIES } from "constants/countries";
import languageMapWithCountries from "utils/languagemaps";
import { RiLockPasswordLine } from "react-icons/ri";
import { AiFillEdit } from "react-icons/ai";
import { styled } from "styled-components";
import BaseToolTip from "components/common/BaseTooltip";
import { FONT_FAMILY, FONT_SIZE, FONT_WEIGHT } from "styles/themes/constants";
import BaseModal from "components/common/BaseModal";
import LeftProfile from "./BannerLogoProfile";
import Stats from "./Stats";
import RightProfile from "./EditProfile";
import EditProfile from "./EditProfile";
import BaseLoading from "components/common/BaseSpin";
const Profile = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [enableEditProfile, setEnableEditProfile] = useState(false);
  const [makeRequest, { loading: assignCityLoading, data: myCityInfo }] =
    useAPI();
  const [getRequest, { data: cityStats, loading: cityStatsLoading }] = useAPI();

  const getCityStats = async () => {
    try {
      await getRequest(dashboard.dashboard());
    } catch (error) {
      toast.error(error);
    }
  };
  const [selectedTab, setSelectedTab] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState(null);
  const getCityInfo = async () => {
    await makeRequest(city.getMyAssignCity());
  };
  const { language: selectedlang, changeLanguage } = getI18n();

  const handleViewEditCity = () => {
    setIsModalVisible(true);
  };
  const closeModal = () => {
    setIsModalVisible(false);
  };
  useEffect(() => {
    getCityInfo();
    getCityStats();
  }, []);

  useEffect(() => {
    if (
      myCityInfo?.details &&
      Array.isArray(myCityInfo?.details) &&
      myCityInfo?.details.length > 0
    ) {
      const result = handleOnChange(selectedlang);

      if (!result && !selectedDetails?.lang) {
        setSelectedDetails(myCityInfo?.details[0]);
        setSelectedTab(myCityInfo?.details[0]?.lang);
      }
    }
  }, [myCityInfo, selectedlang]);

  const handleOnChange = (lang) => {
    if (
      myCityInfo?.details &&
      Array.isArray(myCityInfo?.details) &&
      myCityInfo?.details.length > 0
    ) {
      const selected = myCityInfo?.details.find((x) => x.lang === lang);
      if (!selected?.lang) {
        return false;
      }
      setSelectedTab(selected?.lang);
      setSelectedDetails({ ...selected });
      const countryExistInMyList = COUNTRIES.find((x) => x.language === lang);
      if (countryExistInMyList) {
        changeLanguage(selected?.lang);
      }
      return true;
    }
  };
  return (
    <>
      {assignCityLoading ? (
        <BaseLoading />
      ) : (
        <>
          <HeaderWrapper title={t("dashboard.city-profile")} />
          <AlterActionWrapper flexEnd={true}>
            <ActionButton
              actionTitle={t("dashboard.edit-city")}
              handleAction={handleViewEditCity}
            >
              <AiFillEdit size={16} style={{ marginRight: "4px" }} />
            </ActionButton>
          </AlterActionWrapper>
          <LeftProfile cityInfo={myCityInfo} reFetch={getCityInfo}/>

          {isModalVisible && (
            <BaseModal
              title={t("dashboard.city-profile")}
              open={isModalVisible}
              onCancel={closeModal}
              width={"60%"}
              footer={[]}
            >
              <EditProfile
                cityInfo={myCityInfo}
                onCancel={() => setIsModalVisible(false)}
                reFetch={getCityInfo}
              />
            </BaseModal>
          )}
        </>
      )}
    </>
  );
};

export default Profile;

const LogoGrid = styled.div`
  display: flex;
  max-width: 100%;
  flex-direction: column;
  margin: 0 auto;
  margin-bottom: 20px;
`;

const LogoImage = styled.img`
  width: 70px;
  height: 70px;
`;

const Wrapper = styled.div`
  display: flex;
  gap: 28px;
  flex-wrap: wrap;
  margin: 20px;
  align-items: center;
`;

const LogoWrapper = styled.div``;

const AssociateLogoTextStyle = styled(BaseTypography.Text)`
  font-size: ${FONT_SIZE.lg};
  font-weight: ${FONT_WEIGHT.semibold};
  margin: 20px;
`;
