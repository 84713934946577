import React, { useEffect } from "react";
import { Alert, Button } from "antd";
import { Link } from "react-router-dom";
import { styled } from "styled-components";
import useAPI from "hooks/useApi";
import { useSelector } from "react-redux";

const Container = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 20px;
  width: 100%;

  & .alert {
    padding: 12px 12px;
  }
`;

const NotGroupedCameraQR = ({}) => {
  const Count = useSelector((state: any) => state.dashboard);

  if (
    Count?.numberOfCameraNotAssociatedToGroup === 0 &&
    Count?.numberOfQRNotAssociatedToGroup === 0
  ) {
    return null;
  }
  return (
    <Container>
      {Count?.numberOfQRNotAssociatedToGroup === 0 ? null : (
        <>
          <Link
            to="/dashboard/generated-qr?status=NotGrouped"
            style={{ flex: 1 }}
          >
            <Alert
              message={`QR`}
              description={`Number of QR not grouped  -   ${Count?.numberOfQRNotAssociatedToGroup}`}
              type="warning"
              // showIcon
              className="alert"
            />
          </Link>
        </>
      )}
      {Count?.numberOfCameraNotAssociatedToGroup === 0 ? null : (
        <Link to="/dashboard/camera?status=NotGrouped" style={{ flex: 1 }}>
          <Alert
            message={`Camera`}
            description={`Number of Cameras not grouped  - ${Count?.numberOfCameraNotAssociatedToGroup}`}
            type="warning"
            // showIcon
            className="alert"
          />
        </Link>
      )}
    </Container>
  );
};
export default NotGroupedCameraQR;
