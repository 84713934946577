import { useState } from "react";
import request from "../utils/request";

interface State {
  data: any;
  loading: boolean;
  error: string;
}

const useAPI = (): [
  makeRequest: (config: any) => Promise<any>,
  state: State
] => {
  const [state, setState] = useState<State>({
    loading: false,
    error: "",
    data: null,
  });

  const makeRequest = async (config: any): Promise<any> => {
    try {
      setState({
        loading: true,
        error: "",
        data: state.data,
      });

      const res = await request(config);
      setState({
        loading: false,
        error: "",
        data: res,
      });
      return res;
    } catch (err: any) {
      setState({
        loading: false,
        error: err.toString(),
        data: state.data,
      });
      throw err;
    }
  };

  return [makeRequest, state];
};

export default useAPI;
