import { BaseTypography } from "components/common/BaseTypography";
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import { useTranslation } from "react-i18next";

type Props = {
  navigateBackTo?: string;
  text?: string;
};

const GoBack = ({ navigateBackTo, text, ...restProps }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const goBack = () => {
    if (!!navigateBackTo) {
      navigate(navigateBackTo);
      return;
    }
    navigate(-1);
  };

  return (
    <GoBackStyled onClick={goBack} {...restProps}>
      <BsArrowLeft className="icon" />
      <BaseTypography.Text className="text">
        {text || t("remain.back")}
      </BaseTypography.Text>
    </GoBackStyled>
  );
};

export default GoBack;

const GoBackStyled = styled.div`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  color: var(--gray);
  margin-bottom: 5px;

  & .icon {
    margin-right: 5px;
  }
  & .text {
    color: var(--gray);
  }
`;
