import Customization from "../../../assets/cursor-finger.svg";
import EasyToUse from "../../../assets/user-gear.svg";
import RealTime from "../../../assets/time-check.svg";

export const sectionData = [
  {
    id: 1,
    img: EasyToUse,
    title: "Customization",
    description:
      " it's the look and feel of your user interface or the content you engage with, our customization feature allows you to express your style effortlessly.",
  },
  {
    id: 2,
    img: Customization,
    title: "Easy To Use",
    description:
      " Our website boasts an intuitive and clean design, making it easy for users of all backgrounds to find their way around.",
  },
  {
    id: 3,
    img: RealTime,
    title: "Real Time Analytics",
    description:
      "Our real-time analytics system continuously processes data as it flows in, providing you with instant access to critical insights. ",
  },
];
