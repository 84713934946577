import BaseButton from "components/common/BaseButton";
import BaseFilter from "components/common/BaseFilter";
import { BaseSelects } from "components/common/BaseSelect/BaseSelect";
import { Form, Formik } from "formik";
import useAPI from "hooks/useApi";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import QR from "shared/front/apis/qr.api";
import { FlexEnd } from "utils/styles";

const QrFilter = ({ pagination, handleBatch }: any) => {
  const { t } = useTranslation();
  const [getRequest, { data: batchData, loading: batchLoading }] = useAPI();

  const [open, setOpen] = useState(false);
  const makeRequest = () => {
    getRequest(QR.getBatch({ limit: "1000", sort: "-1" }));
  };
  useEffect(() => {
    if (open) {
      makeRequest();
    }
  }, [open]);

  const options = batchData?.data?.map((item: any) => ({
    value: item,
    label: item,
  }));

  return (
    <div>
      <BaseFilter open={open} setOpen={setOpen}>
        <Formik
          initialValues={{ batchno: "" }}
          onSubmit={(values) => {
            handleBatch(values.batchno);
            setOpen(false);
          }}
        >
          {({ values, setFieldValue }) => {
            return (
              <Form>
                <BaseSelects
                  name={"batchno"}
                  showSearch
                  style={{ width: "100%" }}
                  options={options}
                  placeholder={t("dashboard.select-batch")}
                />
                <FlexEnd
                  style={{
                    marginTop: "16px",
                  }}
                >
                  <BaseButton htmlType={"submit"}>
                    {t("dashboard.submit")}
                  </BaseButton>
                </FlexEnd>
              </Form>
            );
          }}
        </Formik>
      </BaseFilter>
      {/* <AiOutlineFilter
        style={{ fontSize: "22px", marginRight: "10px" }}
        onClick={() => setOpen(true)}
      /> */}
    </div>
  );
};

export default QrFilter;
