import { Col, Modal, Row } from "antd";
import { BaseTypography } from "components/common/BaseTypography";
import React, { useState, useEffect } from "react";
import * as S from "./index.styles";
import BaseModal from "components/common/BaseModal";
import BaseInputs from "components/common/BaseInput/BaseInputs";
import { ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";
import BaseButton from "components/common/BaseButton";
import useAPI from "hooks/useApi";
import operators from "shared/front/apis/operator.api";
import { last } from "lodash";
import BaseError from "components/common/BaseError";
import { toast } from "react-toastify";
import { BaseSelect } from "components/common/BaseSelect";
import { allCitiesDropDown } from "constants/api/city";
import { useTranslation } from "react-i18next";

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("First Name is required")
    .min(2, "First Name should have a minimum of 2 characters")
    .max(30, "First Name should have a maximum of 30 characters"),
  lastName: Yup.string()
    .required("Last Name is required")
    .min(2, "Last Name should have a minimum of 2 characters")
    .max(30, "Last Name should have a maximum of 30 characters"),
  email: Yup.string().email("Invalid Email").required("Email is required"),
});

const AssignOperatorModal = ({
  isModalOpen,
  setIsModalOpen,
  onRefresh,
  operatorInfo,
}: IOperatorProps) => {
  const [
    makeRequest,
    { loading: addOperatorLoading, error: addOperatorErrors },
  ] = useAPI();
  const [getRequest, { loading, data: allCityData }] = useAPI();
  const [options, setOptions] = useState([]);
  const { t } = useTranslation();
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const getAllCities = async () => {
    try {
      const res = await getRequest({
        method: "get",
        url: allCitiesDropDown,
      });
      setOptions(res);
    } catch (error) {}
  };
  const handleFormSubmit = async (values: any) => {
    try {
      const { firstName, lastName, email } = values;
      await makeRequest(operators.addOperator({ firstName, lastName, email }));
      onRefresh();
    } catch (error: any) {
      toast.error(error);
    }
  };

  useEffect(() => {
    getAllCities();
  }, []);

  return (
    <>
      {isModalOpen && (
        <BaseModal
          title={t("dashboard.assign-operator")}
          open={isModalOpen}
          onCancel={closeModal}
          footer={[]}
          width={720}
        >
          <Formik
            initialValues={{ firstname: "", lastname: "", cityId: "" }}
            onSubmit={async (values, { setSubmitting }) => {
              await handleFormSubmit(values);
              await setSubmitting(false);
              setIsModalOpen(false);
            }}
            validationSchema={validationSchema}
            validateOnBlur={false}
          >
            {({ values, handleSubmit, isSubmitting, setFieldValue }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <S.OperatorText>
                    {t("dashboard.operator")}:{" "}
                    {operatorInfo?.firstName + " " + operatorInfo?.lastName}
                  </S.OperatorText>
                  <S.RowWrapper gutter={[28, 16]}>
                    <BaseSelect
                      label={t("dashboard.select-city")}
                      style={{
                        width: "300px",
                      }}
                      name={"cityId"}
                      allowClear
                      showSearch
                      placeholder={t("dashboard.select-a-city")}
                      options={options}
                      onSelect={(value, option) => {
                        setFieldValue("cityId", option?.id);
                      }}
                    />
                  </S.RowWrapper>

                  <BaseError>{addOperatorErrors}</BaseError>

                  <BaseButton
                    htmlType="submit"
                    disabled={isSubmitting || addOperatorLoading}
                    loading={addOperatorLoading || addOperatorLoading}
                    type={"primary"}
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    {t("dashboard.assign")}
                  </BaseButton>
                </Form>
              );
            }}
          </Formik>
        </BaseModal>
      )}
    </>
  );
};

export default AssignOperatorModal;

interface IOperatorProps {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  onRefresh: () => void;
  operatorInfo: any;
}
