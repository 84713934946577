/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { TableProps } from "antd";
import * as S from "./index.styles";

export type BaseTableProps<T> = TableProps<T>;

export const BaseTable: React.FC<BaseTableProps<any>> = (props) => {
  return (
    <S.Table
      {...props}
      rowClassName={(record, index) => (record?.isRecent ? "row-bg" : "")}
      // scroll={{ y: "calc(100vh - 380px)", x: "auto" }}
    />
  );
};
