import { UrlLink } from "utils/styles";
import * as Styles from "./index.styles";
import { convertTimestamp } from "utils/utils";
import { BaseTag } from "components/common/BaseTag";
import { Image } from "antd";

interface StickersTableRowCardProps {
  url: string;
  createdDate: string;
  status: string;
}

const StickersTableRowCard = ({
  url,
  createdDate,
  status,
}: StickersTableRowCardProps) => {
  return (
    <Styles.CardWrapper>
      <Image
        alt="sticker"
        src={url}
        style={{
          width: "80px",
          aspectRatio: "1",
          backgroundColor: "#fff",
          padding: "4px",
          objectFit: "contain",
        }}
        preview={{
          src: url,
        }}
      />
      <div>
        <UrlLink onClick={() => window.open(url)}>{url}</UrlLink>
        <div
          style={{ fontSize: "12px", fontWeight: "400", marginBottom: "6px" }}
        >
          Created on {convertTimestamp(createdDate)}
        </div>
        {status === "Active" && <BaseTag color="success">success</BaseTag>}
      </div>
    </Styles.CardWrapper>
  );
};

export default StickersTableRowCard;
