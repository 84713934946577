import { BaseTypography } from "components/common/BaseTypography";
import styled from "styled-components";
import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
  media,
} from "styles/themes/constants";
import { flexColumn, flexColumnJustifyAlignCenter } from "utils/styles";

export const Header = styled.div`
  padding: 0rem 0px;
  display: flex;
  align-items: center;
  margin: 0px 0px 10px;
`;

export const AuthorWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 21.25rem;
  position: relative;
  max-width: 42.5rem;
  box-shadow: var(--box-shadow);
  border-radius: ${BORDER_RADIUS};
  transition: 0.3s;

  [data-theme="dark"] & {
    background: var(--secondary-background-color);
  }

  &:hover {
    box-shadow: var(--box-shadow-hover);
  }
`;

export const Author = styled.div`
  font-size: ${FONT_SIZE.lg};
  font-weight: ${FONT_WEIGHT.bold};
  color: var(--text-main-color);
  line-height: 1.5625rem;
  text-transform: capitalize;
  font-family: "Open Sans", Arial, sans-serif;
`;
export const StatusMessage = styled.div<{ status: string }>`
  font-size: ${FONT_SIZE.lg};
  font-weight: ${FONT_WEIGHT.bold};
  line-height: 1.5625rem;
  text-transform: capitalize;
  font-family: "Open Sans", Arial, sans-serif;
  color: ${(props) =>
    props.status === "Accepted" ? `var(--primary)` : `var(--red)`};
`;

export const InfoWrapper = styled.div`
  padding: 1.25rem;

  @media only screen and ${media.xl} {
    padding: 1rem;
  }

  @media only screen and ${media.xxl} {
    padding: 1.85rem;
  }
`;

export const InfoHeader = styled.div`
  display: flex;
  font-size: 1.1rem;
`;

export const Title = styled.div`
  font-size: ${FONT_SIZE.xl};
  font-weight: ${FONT_WEIGHT.semibold};
  width: 80%;
  line-height: 1.375rem;

  color: var(--text-main-color);

  @media only screen and ${media.md} {
    font-size: ${FONT_SIZE.xxl};
  }
`;

export const DateTime = styled(BaseTypography.Title)`
  font-size: ${FONT_SIZE.xs};
  color: var(--text-main-color);
  color: #333;
  padding-bottom: 10px;
  line-height: 1em;
  font-weight: 500;
`;

export const Description = styled.div`
  font-size: ${FONT_SIZE.xs};
  color: rgb(102, 102, 102);
  margin: 10px 0px 30px;
  @media only screen and ${media.xxl} {
    font-size: 1rem;
  }
`;

export const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.625rem;
  padding: 0 1.25rem 1.25rem;
`;

export const AcceptReject = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  padding: 0px 0px;
  margin-top: 30px;
`;

export const ButtonWrapper = styled.div`
  // ${flexColumnJustifyAlignCenter};
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 160px;
  > * {
    margin-bottom: 1rem;
  }
`;

export const QRRequestedStyled = styled.div`
  max-width: 1200px;
  width: 100%;
  padding: 20px;
  margin: 0px auto;
  background: var(--whiteBackground);
  padding-bottom: 50px;
`;
export const InfoContainerStyled = styled.div`
  margin-bottom: 20px;
`;
export const InfoWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  & .label {
    width: 80px;
    font-weight: 600;
    color: #444;
  }
  & .value {
  }
`;

export const IconStyle = styled.div<{ status: string }>`
  font-size: 30px;
  color: ${(props) =>
    props.status === "Accepted" ? `var(--primary)` : `var(--red)`};
`;
