import React from "react";
import { styled } from "styled-components";
import store from "store";
import type { Dispatch } from "redux";
import { useDispatch } from "react-redux";

const Status = ({ pagination, handleClearFilter }) => {
  const dispatch: Dispatch = useDispatch();
  const batchName = pagination.batch;

  return (
    !!batchName && (
      <InfoStyle>
        <span>{`batch: ${batchName}`}</span>
        <span
          className="cancel-icon"
          onClick={() => {
            handleClearFilter();
          }}
        >
          X
        </span>
      </InfoStyle>
    )
  );
};

const QrFilterStatus = ({ pagination, handleClearFilter }) => {
  return (
    <>
      <Status pagination={pagination} handleClearFilter={handleClearFilter} />
    </>
  );
};

export default QrFilterStatus;

const InfoStyle = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  border-radius: 20px;
  background-color: #f2f2f2;
  padding: 3px 6px;
  /* margin: 5px; */
  text-transform: capitalize;
  cursor: pointer;
  span:first-child {
    font-size: 10px;
  }
  .cancel-icon {
    margin-left: 10px;
    cursor: pointer;
    font-weight: bold;
    font-size: 10px;
  }
`;
