import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Typography } from "antd";
// import OTPInput from "./OTPInput";
// import Error from "@shared/web/components/Error";
// import Button from "@shared/web/components/Button";
import { toast } from "react-toastify";
import BaseButton from "components/common/BaseButton";
import { BaseTypography } from "components/common/BaseTypography";
import OTPinput from "./OTPinput";
import useAPI from "hooks/useApi";
import Header from "containers/Home/Header";
import * as Auth from "./AuthLayout.styles";
import { BaseCard } from "components/common/BaseCard";
import { FlexEnd } from "utils/styles";
import BaseError from "components/common/BaseError";
import { useTranslation } from "react-i18next";

function useQuery() {
  const query = new URLSearchParams(useLocation().search);

  return {
    email: query.get("email"),
    verificationCode: query.get("verificationCode"),
  };
}

const VerifyOTP = () => {
  const [code, setCode] = useState("");
  const { state }: any = useLocation();
  console.log("the state are", code);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const [handleSubmitRequest, { loading, error }] = useAPI();

  //   useEffect(() => {
  //     if (!state.email) {
  //       // history.replace('/login');
  //       navigate("/login", { replace: true });
  //     }
  //   }, []);

  const handleSubmit = async (code) => {
    const data = await handleSubmitRequest({
      method: "post",
      url: "auth/checkOtpCode",
      data: { code, email: state.email, type: "password-reset" },
    });
    console.log("the data are", data);

    toast.success(t("remain.account-verified-successfully"));
    navigate("/auth/reset-password", {
      state: {
        code: parseInt(data),
        email: state.email,
      },
    });
  };

  return (
    <>
      <Header />
      <Auth.Wrapper>
        <Auth.BackgroundWrapper>
          <Auth.LoginWrapper>
            <BaseCard>
              <Typography.Title level={5}>
                {t("remain.verify-account")}
              </Typography.Title>
              <OTPinput
                length={6}
                loading={loading}
                defaultValue={""}
                onComplete={(code) => {
                  setCode(code);
                }}
              />
              <BaseTypography.Text>
                {t("remain.sent-verification-code")}
              </BaseTypography.Text>
              {error && <BaseError>{error}</BaseError>}

              <FlexEnd
                style={{
                  margin: "10px 0px",
                }}
              >
                <BaseButton
                  loading={loading}
                  disabled={loading}
                  onClick={() => handleSubmit(code)}
                  style={{
                    background: "var(--primary)",
                    color: "#fff",
                  }}
                >
                  {t("remain.verify-account")}
                </BaseButton>
              </FlexEnd>
            </BaseCard>
          </Auth.LoginWrapper>
        </Auth.BackgroundWrapper>
      </Auth.Wrapper>
    </>
  );
};

export default VerifyOTP;
