export default [
  {
    title: "Limitazione di Responsabilità",
    desc: [
      "Camera Compliance Ltd, le sue affiliate, i partner e i dipendenti non saranno responsabili per danni diretti, indiretti, incidentali, speciali, consequenziali o punitivi, incluso, a titolo esemplificativo ma non esaustivo, la perdita finanziaria, la perdita di dati o qualsiasi altro danno derivante dall'uso di questo sito web o dalla fiducia nel contenuto informativo fornito.",
    ],
  },
  {
    title: "Nessun Consiglio Professionale",
    desc: [
      "Le informazioni fornite su questo sito web non costituiscono consulenza legale, tecnica o professionale. Pur cercando di fornire informazioni accurate e aggiornate, non offriamo alcuna garanzia o rappresentazione di alcun tipo riguardo all'accuratezza, alla tempestività o alla completezza del sito web.",

      "Per ottenere consigli dettagliati personalizzati per la tua situazione, consulta professionisti qualificati, inclusi, ma non limitati a, professionisti legali esperti in materia di protezione dei dati e conformità al GDPR.",
    ],
  },
  {
    title: "Legge Applicabile",
    desc: [
      "Questa rinuncia sarà disciplinata ed interpretata conformemente alle leggi della giurisdizione in cui opera Camera Compliance Ltd. Utilizzando questo sito web, acconsenti alla giurisdizione di tali tribunali per qualsiasi azione, causa o procedimento derivante da questa rinuncia.",
    ],
  },
];
