import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { Col, Row, Tabs, Typography } from "antd";
import { Field, Form, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import BaseButton from "components/common/BaseButton";
import BaseInputs from "components/common/BaseInput/BaseInputs";
import BaseModal from "components/common/BaseModal";
import S3uploaderMessage from "components/common/S3presigned";
import CustomEditor from "components/Editor/Editor";
import { BaseTypography } from "components/common/BaseTypography";
import country from "shared/front/apis/country.api";
import { ButtonFlexEnd } from "utils/styles";
import { BaseSelect } from "components/common/BaseSelect";
import { useSelector } from "react-redux";
import useAPI from "hooks/useApi";
import city from "shared/front/apis/city.api";
import * as S from "./index.styles";
//@ts-ignore
import { useTranslation } from "react-i18next";

const SAdminAddCity = ({
  loader,
  isModalVisible,
  closeModal,
  handleAddCity,
}: any) => {
  const formikRef = useRef<FormikProps<any>>(null);
  // const [description, setDesription] = useState({
  //   descEng: "",
  //   descFin: "",
  // });

  const [initialContent, setInitialContent] = useState();
  const { t } = useTranslation();
  const [modalWidth, setModalWidth] = useState<string | number>("500px");
  const countries = useSelector((state: any) => state.countries.data);
  // const [getRequest, { loading }] = useAPI();

  const validateSchema = Yup.object().shape({
    title: Yup.string()
      .required(t("forms.city-name-valid"))
      .min(2, t("forms.city-chars-valid"))
      .max(50, t("forms.city-maxChars-valid")),
  });
  const handleSubmit = async (values: any) => {
    try {
      handleAddCity(values);
    } catch (error: any) {
      toast.error(error);
    }
  };

  // const handleEditorChange = (field: string, edits: string) => {
  //   formikRef.current.setFieldValue(field, edits);
  //   setDesription((prev) => ({
  //     ...prev,
  //     [field]: edits,
  //   }));
  // };

  const handleResize = () => {
    if (window.innerWidth > 500) {
      setModalWidth("30vw");
    } else {
      setModalWidth("100vw");
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize(); // Initialize modal width based on initial window size

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // For Country List Data
  // const getAllCountry = (params) => {
  //   getRequest(country.getCountry("set", [params]));
  // };

  // useEffect(() => {
  //   getAllCountry("test");
  // }, []);
  // const dataSource = (countries || []).map((item: any) => ({
  //   value: item._id,
  //   label: item.title,
  // }));

  // const items = [
  //   {
  //     key: "1",
  //     label: t("dashboard.english"),

  //     children: (
  //       <>
  //         <Col span={24} style={{ marginBottom: "20px" }}>
  //           <BaseInputs
  //             label={t("dashboard.name")}
  //             name="title"
  //             type={"text"}
  //             placeholder="City Name"
  //           />
  //         </Col>
  //         <Col span={24}>
  //           <BaseTypography style={{ marginBottom: 4 }}>
  //             {t("dashboard.information")}
  //           </BaseTypography>
  //           <CustomEditor
  //             onEditorChange={(values) => {
  //               handleEditorChange("descEng", values);
  //             }}
  //             editorState={description.descEng}
  //             placeholder="Enter your city information ..."
  //           />
  //         </Col>
  //       </>
  //     ),
  //   },
  //   {
  //     key: "2",
  //     label: t("dashboard.finnish"),
  //     children: (
  //       <>
  //         <Col span={24} style={{ marginBottom: "20px" }}>
  //           <BaseInputs
  //             label={t("dashboard.name")}
  //             name="titleFin"
  //             type={"text"}
  //             placeholder="City Name"
  //           />
  //         </Col>
  //         <Col span={24}>
  //           <BaseTypography style={{ marginBottom: 4 }}>
  //             {t("dashboard.information")}
  //           </BaseTypography>
  //           <CustomEditor
  //             onEditorChange={(values) => {
  //               handleEditorChange("descFin", values);
  //             }}
  //             editorState={description.descFin}
  //             placeholder="Enter your city information ..."
  //           />
  //         </Col>
  //       </>
  //     ),
  //   },
  // ];

  return (
    <>
      {isModalVisible && (
        <BaseModal
          title={t("dashboard.add-city")}
          open={isModalVisible}
          onCancel={closeModal}
          footer={[]}
          width={modalWidth}
          centered
        >
          <Formik
            innerRef={formikRef}
            initialValues={{
              title: "",
            }}
            onSubmit={(values, { setSubmitting }) => {
              console.log("on submit", values);
              setSubmitting(false);
              handleSubmit(values);
            }}
            validationSchema={validateSchema}
            validateOnBlur={false}
          >
            {({ values, isSubmitting, setFieldValue, errors }) => {
              return (
                <Form>
                  {/* <S.ImageUploader span={24}>
                    <Field
                      name="bannerImage"
                      label={t("dashboard.city-banner")}
                      component={S3uploaderMessage}
                      setFieldValue={setFieldValue}
                      fieldName={"bannerImage"}
                      value={values.bannerImage}
                      error={errors?.bannerImage}
                      isFullWidth
                    />
                  </S.ImageUploader>
                  <S.ImageUploader span={24}>
                    <Field
                      name="logo"
                      label={t("dashboard.city-icon")}
                      component={S3uploaderMessage}
                      setFieldValue={setFieldValue}
                      fieldName={"logo"}
                      value={values.logo}
                      error={errors?.logo}
                    />
                  </S.ImageUploader> */}
                  {/* <Col span={24} style={{ marginBottom: "20px" }}>
                    <BaseSelect
                      label={t("dashboard.select-country")}
                      style={{
                        width: "100%",
                      }}
                      name={"countryName"}
                      allowClear
                      showSearch
                      placeholder="Select a Country"
                      options={dataSource}
                      onSelect={(value, option) => {
                        setFieldValue("countryName", option?.label);
                      }}
                    />
                  </Col>
                  <Typography.Text>
                    {t("dashboard.name-language")}.
                  </Typography.Text> */}

                  <Col span={24} style={{ marginBottom: "20px" }}>
                    <BaseInputs
                      label={t("dashboard.name")}
                      name="title"
                      type={"text"}
                      placeholder={t("forms.city-placeholder")}
                    />
                  </Col>

                  {/* <Tabs
                    defaultActiveKey="1"
                    items={items}
                    onChange={(key) => console.log("key", key)}
                  /> */}

                  <ButtonFlexEnd>
                    <BaseButton
                      htmlType="submit"
                      disabled={isSubmitting || loader}
                      loading={loader}
                      type={"primary"}
                      style={{
                        marginTop: "2rem",
                        background: "var(--primary)",
                        color: "#fff",
                      }}
                    >
                      {t("dashboard.submit")}
                    </BaseButton>
                  </ButtonFlexEnd>
                </Form>
              );
            }}
          </Formik>
        </BaseModal>
      )}
    </>
  );
};

export default SAdminAddCity;
