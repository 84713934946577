export const cameraData = [
  {
    id: 1,

    title: "City and Municipal Governments",
    description: [
      "Administrators and policymakers can utilize our system to ensure that public surveillance infrastructure is fully compliant with GDPR.",
      "Helps in fostering public trust and ensuring transparency in urban surveillance practices.",
    ],
  },
  {
    id: 2,

    title: "Traffic and Urban Planning Departments",
    description: [
      "Ideal for traffic engineers and urban planners seeking to integrate smart technology for efficient traffic management and public safety, while adhering to privacy laws.",
      "Enhances the capability to analyze traffic patterns and pedestrian movement without compromising individual privacy.",
    ],
  },
  {
    id: 3,

    title: "Law Enforcement Agencies",
    description: [
      "Provides law enforcement with a tool that respects citizens’ privacy rights while utilizing camera footage for public safety and crime prevention",
      "Ensures that data handling by law enforcement aligns with GDPR standards.",
    ],
  },
  {
    id: 4,

    title: "Legal and Compliance Teams within Municipalities",
    description: [
      "Assists legal professionals in ensuring that the city’s surveillance practices are compliant with the latest data protection regulations.",
      "Aids in navigating the legal landscape of privacy and data protection.",
    ],
  },
  {
    id: 5,

    title: "Data Protection Officers (DPOs)",
    description: [
      "Essential for DPOs responsible for overseeing data protection strategy and implementation to ensure compliance with GDPR.",
      "Streamlines the process of monitoring and reporting on the compliance of surveillance systems.",
    ],
  },
  {
    id: 6,

    title: "Public Transportation Authorities",
    description: [
      "Enables these authorities to monitor public spaces while maintaining compliance with privacy regulations.",
      "Assists in enhancing the safety and efficiency of public transportation systems.",
    ],
  },
  {
    id: 7,

    title: "Educational Institutions and Research Bodies:",
    description: [
      "For those conducting research in urban development, traffic management, and public policy, our system offers a GDPR-compliant way to gather and analyze data.",
    ],
  },
  {
    id: 8,

    title: "Citizens and Community Groups",
    description: [
      "Empowers citizens by providing them with direct access to information about the surveillance they are subjected to in public spaces.",
      "Enhances community engagement and awareness regarding privacy rights and public safety measures.",
    ],
  },
  {
    id: 9,

    title: "Private Sector Partners Involved in Smart City Initiatives:",
    description: [
      "Companies collaborating with public entities on smart city projects can use the system to ensure their solutions are compliant with privacy regulations.",
    ],
  },
];
