import React, { useState } from "react";
import ReactECharts from "echarts-for-react";
import styled from "styled-components";
import { BaseCard } from "components/common/BaseCard";
import moment from "moment";
import { Dates } from "constants/Dates";
import { useTranslation } from "react-i18next";

export const ActivityCard = ({ totalWeeklyScan }) => {
  const { t } = useTranslation();
  const days = Dates.getDays();
  const result = totalWeeklyScan?.map((data: any) => {
    const datas = moment(data._id);

    const formattedDate = datas?.format("YYYY-MM-DD");
    const dayOfWeek = datas?.format("ddd");
    return { ...data, dayOfWeek };
  });
  const mapDataToDays = () => {
    const dataForDays = days?.map((day) => {
      const scanDataForDay = result?.find((data) => data.dayOfWeek === day);
      return scanDataForDay ? scanDataForDay.count : 0;
    });
    return dataForDays;
  };

  const optionsVisitByDate = {
    grid: { top: 8, right: 8, bottom: 24, left: 36 },
    xAxis: {
      type: "category",
      data: days,
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        data: mapDataToDays(),
        type: "line",
        smooth: true,
      },
    ],
    tooltip: {
      trigger: "axis",
    },
  };

  return (
    <ActivityCardStyled
      id="activity"
      title={t("dashboard.weekly-activity")}
      padding={0}
    >
      <ReactECharts option={optionsVisitByDate} />
    </ActivityCardStyled>
  );
};

const ActivityCardStyled = styled(BaseCard)`
  height: 100%;
`;
