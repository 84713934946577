import styled from "styled-components";
import { InfoCircleOutlined } from "@ant-design/icons/lib";
import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
  media,
} from "styles/themes/constants";
import { Divider } from "antd";

export const Wrapper = styled.div`
  padding: 0.5rem 0rem;
`;

export const LegendWrapper = styled.div`
  display: flex;
  padding: 0.5rem;
`;

export const LegendInfo = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  color: var(--text-superLight-color);
`;

export const LegendDescription = styled.div`
  display: flex;
`;

export const LegendColor = styled.span`
  height: 1rem;
  width: 1rem;
  min-width: 1rem;
  border-radius: 50%;
`;

export const LegendTitle = styled.div`
  line-height: 1rem;
  font-weight: ${FONT_WEIGHT.semibold};
  color: var(--text-main-color);
  display: flex;
  justify-content: space-between;
  text-transform: capitalize;

  @media only screen and ${media.xs} {
    font-size: ${FONT_SIZE.xs};
  }

  @media only screen and ${media.md} {
    font-size: ${FONT_SIZE.xs};
  }
`;

export const Values = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  color: var(--primary-color);
  text-transform: capitalize;

  @media only screen and ${media.md} {
    margin-left: 1.5rem;
  }
`;

export const DividerStyle = styled.div`
  border-bottom: 0.1px solid var(--border);
  margin: 12px 0px;
`;
export const InfoWrapperStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-auto-rows: auto;
  gap: 20px;
  width: 100%;
  margin: 0.6rem 0px;
`;

export const StatsStyled = styled.div`
  background: #e5f6f9;
  padding: 20px 30px;
  box-shadow: 0px 0px 10px 1px #dbdbdb;
  min-width: 150px;
  border-radius: 20px;

  & .label {
    font-size: 1rem;
    color: #666;
    margin-bottom: 10px;
    text-align: center;
  }

  & .value {
    font-size: 1.2rem;
    color: #167a8b;
    margin-bottom: 10px;
    text-align: center;
  }
`;
