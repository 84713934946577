const publicViewApi = {
  getAllPublicViews: (action, params): any => {
    return {
      url: "group/getMyPublicView",
      method: "get",
      params: params,
      config: {
        store: {
          action: action,
          key: "publicViews.data",
        },
      },
    };
  },
  linkPublicViewToQr: (data) => {
    return {
      url: "QR/linkPublicViewToQRCode",
      method: "post",
      data,
    };
  },
};

export default publicViewApi;
