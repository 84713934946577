import React, { useState } from "react";
import { Modal, Row } from "antd";
import auth from "shared/front/apis/auth.api";
import { toast } from "react-toastify";
import { Form, Formik } from "formik";
import BaseInputs from "components/common/BaseInput/BaseInputs";
import BaseButton from "components/common/BaseButton";
import { useTranslation } from "react-i18next";
import { handleLogout } from "utils/request";
import useAPI from "hooks/useApi";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required("New Password is required")
    .min(8, "New Password must be at least 8 characters long"),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("newPassword")], "Passwords must match"),
});
const ChangePassword = ({ visible, onClose }) => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { t } = useTranslation();
  const [makeRequest, { loading: changePasswordLoading }] = useAPI();

  const handleChangePassword = async (values: any) => {
    const { newPassword, confirmPassword } = values;
    if (newPassword === confirmPassword) {
      try {
        const res = await makeRequest(auth.changePassword(values));
        console.log("the response", res);
        toast.success("The password has been changed successfully.");
        await handleLogout();
      } catch (error: any) {
        toast.error(error);
      }
    } else {
      return toast.error("Password didn't match");
    }
  };
  const handleOk = () => {
    onClose();
  };

  const handleCancel = () => {
    setNewPassword("");
    setConfirmPassword("");
    onClose();
  };

  return (
    <Modal
      title="Change Password"
      visible={visible}
      onCancel={handleCancel}
      footer={false}
    >
      <Formik
        initialValues={{
          newPassword: "",
          confirmPassword: "",
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          await handleChangePassword(values);
          await setSubmitting(false);
        }}
      >
        {({ errors, values, isSubmitting }: any) => {
          return (
            <Form>
              <BaseInputs
                label={"Old Password"}
                name={"oldPassword"}
                type={"password"}
              />
              <BaseInputs
                label={"New Password"}
                name={"newPassword"}
                type={"password"}
              />
              <BaseInputs
                label={"Confirm Password"}
                name={"confirmPassword"}
                type={"password"}
              />
              <Row
                justify="space-between"
                style={{
                  marginBottom: 10,
                }}
              ></Row>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <BaseButton
                  htmlType="submit"
                  style={{
                    background: "var(--primary)",
                    color: "#fff",
                  }}
                  disabled={isSubmitting || changePasswordLoading}
                  loading={isSubmitting || changePasswordLoading}
                >
                  {t("dashboard.change-password")}
                </BaseButton>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default ChangePassword;
