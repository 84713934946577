import React from "react";
import Header from "containers/Home/Header";
import { HomeStyled } from "./style";
import Content from "./Content";
import Footer from "containers/Home/Footer";
// import Footer from "containers/Home/Footer";
// import Footer from "./Footer";

const Terms = () => {
  return (
    <HomeStyled>
      <Header />
      <Content />

      <Footer />
    </HomeStyled>
  );
};

export default Terms;
