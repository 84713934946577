import styled from "styled-components";
import { Table as AntdTable } from "antd";
import { FONT_SIZE, media } from "styles/themes/constants";
import { RefTable } from "antd/es/table/interface";

export const Table: RefTable = styled(AntdTable)`
  .row-bg {
    background: #eee;
  }
  .ant-table-thead .ant-table-cell {
    font-size: ${FONT_SIZE.xxs};
  }
  .ant-table-tbody .ant-table-cell {
    font-size: ${FONT_SIZE.xxs};
    cursor: pointer;
  }
  .ant-pagination-item.ant-pagination-item-active {
    border-radius: 6px;
    background: var(--primary);
    & a {
      color: #fff !important;
    }
  }
  .ant-pagination-item {
    border-radius: 6px;
    & a {
      color: var(--primary);
    }
  }
  table {
    @media only screen and (max-width: 950px) {
      overflow-x: auto;
    }
  }
`;
