import dynamicQRImg from "../../Features/images/qr.png";
import stickerImg from "../../Features/images/sticker.png";
import publicPageImg from "../../Features/images/city-public-page.png";

export default [
  {
    img: dynamicQRImg,
    title: "QR pentru țară și oraș",
    description:
      "Orașele moderne necesită soluții moderne. Cu Respectarea Camerei, aducem conformitatea GDPR în sistemele de supraveghere publică, protejând datele personale și asigurând transparența completă în proces.",
  },
  {
    img: stickerImg,
    title: "Etichetă cu câmp",
    description:
      "Etichetele sunt excelente! Plasează-ți codurile QR plictisitoare în etichete colorate pentru a le oferi un aspect atrăgător.",
  },
  {
    img: publicPageImg,
    title: "Pagină Publică pentru Orașe",
    description:
      "Personalizează paginile publice conform nevoilor tale... Administratorii orașului le pot personaliza și pot adăuga limbaje diferite în funcție de oraș.",
  },
];
