import { ButtonProps } from "antd";
import BaseButton from "components/common/BaseButton";
import { BaseTypography } from "components/common/BaseTypography";
import styled, { css } from "styled-components";
import { media } from "styles/themes/constants";
import { flexAlignCenter } from "utils/styles";

interface FullnessLineProps {
  width: number;
}

export const SkeletonWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LogoWrap = styled.div`
  position: absolute;
  /* top: 120px; */
  bottom: 0px;
  left: 22px;
  translate: 0% 50%;
  /* margin-bottom: 10px; */
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  & > button {
    display: flex;
    align-items: center;

    position: absolute;
    right: 0px;
    top: 0px;
    & .icons {
      margin-right: 5px;
    }
  }
`;

export const ImgWrapper = styled.div`
  height: 230px;
  margin-bottom: 10px;

  & .ant-avatar > img {
    object-fit: contain;
  }
`;

export const Title = styled(BaseTypography.Text)`
  font-size: 1.125rem;
  font-weight: 700;
  color: #666;
  @media only screen and ${media.xl} {
    font-size: 1.5rem;
  }
`;

export const Subtitle = styled(BaseTypography.Text)`
  margin-bottom: 2rem;

  @media only screen and ${media.xl} {
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }

  &.desc {
    border-top: 1px solid #ccc;
    padding: 10px 0px 0px;
    max-width: 700px;
    overflow-wrap: break-word;
  }
`;

export const FullnessWrapper = styled.div`
  border-radius: 50px;
  height: 1.875rem;
  margin-bottom: 0.625rem;

  background-color: rgba(var(--warning-rgb-color), 0.5);

  @media only screen and ${media.xl} {
    height: 2.5rem;
    margin-bottom: 1rem;
  }
`;

export const FullnessLine = styled.div<FullnessLineProps>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  padding-right: 0.625rem;
  border-radius: 50px;

  width: ${(props) => props.width}%;

  background: linear-gradient(
    90deg,
    var(--warning-color) 0%,
    var(--error-color) 100%
  );

  color: var(--text-secondary-color);

  @media only screen and ${media.xl} {
    font-size: 1rem;
    font-weight: 600;
    padding-right: 0.875rem;
  }
`;

export const Text = styled(BaseTypography.Text)`
  font-size: 0.75rem;
  text-align: left;

  color: var(--text-main-color);

  @media only screen and ${media.md} {
    text-align: center;
  }

  @media only screen and ${media.xl} {
    font-size: 0.875rem;
    text-align: left;
  }
`;

export const NavWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 1.5rem;
`;

export const Btn = styled(BaseButton)<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: unset !important;
  width: 100%;

  &:hover {
    background-color: rgba(var(--primary-rgb-color), 0.05);
  }

  ${(props) =>
    props.$isActive &&
    css`
      background-color: rgba(var(--primary-rgb-color), 0.05);
    `};

  & > span:first-of-type {
    padding: 0.625rem;
    border-radius: 10px;
    margin-right: 0.5rem;

    color: ${(props) => `var(--${props.color}-color)`};

    background-color: rgba(var(--primary-rgb-color), 0.05);
  }
`;

export const FlexAligncenter = styled.div`
  ${flexAlignCenter}
`;
