import React from "react";
import { CardProps } from "antd";
import * as S from "./index.styles";
import { useResponsive } from "hooks/useResponsive";

export interface BaseCardProps extends CardProps {
  className?: string;
  padding?: string | number | [number, number];
  autoHeight?: boolean;
}

export const BaseCard: React.FC<BaseCardProps> = ({
  className,
  padding,
  size,
  autoHeight = true,
  children,
  ...props
}) => {
  const { isTablet, isDesktop } = useResponsive();

  return (
    <S.Card
      size={size ? size : isTablet ? "default" : "small"}
      className={className}
      bordered={false}
      $padding={
        padding || padding === 0
          ? padding
          : (isDesktop && defaultPaddings.desktop) ||
            (isTablet && defaultPaddings.tablet) ||
            defaultPaddings.mobile
      }
      $autoHeight={autoHeight}
      {...props}
    >
      {children}
    </S.Card>
  );
};

interface DefaultPadding {
  mobile: [number, number];
  tablet: [number, number];
  desktop: [number, number];
}

const defaultPaddings: DefaultPadding = {
  mobile: [30, 16],
  tablet: [40, 30],
  desktop: [50, 60],
};
