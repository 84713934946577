import React from "react";
import * as Auth from "./AuthLayout.styles";
import * as S from "./index.styles";
import { BaseCard } from "components/common/BaseCard";
import { Form, Formik } from "formik";
import BaseInputs from "components/common/BaseInput/BaseInputs";
import { Row } from "antd";
import BaseButton from "components/common/BaseButton";
import * as Yup from "yup";
import useAPI from "hooks/useApi";
import auth from "shared/front/apis/auth.api";
import { toast } from "react-toastify";
import { Navigate, useNavigate } from "react-router-dom";
import { QR_DASHBOARD_AUTH_PATH } from "constants/routes/route";
import Header from "containers/Home/Header";
import { useRoot } from "RootProvider";
import { handleLogout } from "utils/request";
import BaseError from "components/common/BaseError";
import { useTranslation } from "react-i18next";

const validationSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required("New Password is required")
    .min(8, "New Password must be at least 8 characters long"),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("newPassword")], "Passwords must match"),
});
const ProfileChangePassword = () => {
  const { t } = useTranslation();
  const [makeRequest, { loading: changePasswordLoading }] = useAPI();

  // if (!initialPassword) {
  //   return <Navigate to="/auth/login" />;
  // }

  const handleChangePassword = async (values: any) => {
    const { newPassword, confirmPassword } = values;
    if (newPassword === confirmPassword) {
      try {
        const res = await makeRequest(auth.changePassword(values));
        console.log("the response", res);
        toast.success(t("remain.password-changed-successfully"));
        await handleLogout();
      } catch (error: any) {
        toast.error(error);
      }
    } else {
      return toast.error(t("remain.password-not-match"));
    }
  };
  return (
    <>
      <Auth.Wrapper>
        <Auth.BackgroundWrapper>
          <Auth.LoginWrapper>
            <BaseCard>
              <Auth.FormTitle>{t("dashboard.change-password")}</Auth.FormTitle>
              <S.LoginDescription>
               {t("remain.protect-you-from-misuse-password")}
              </S.LoginDescription>
              <Formik
                initialValues={{
                  newPassword: "",
                  confirmPassword: "",
                }}
                // validationSchema={validationSchema}
                onSubmit={async (values, { setSubmitting }) => {
                  await handleChangePassword(values);
                  await setSubmitting(false);
                }}
              >
                {({ errors, values, isSubmitting }: any) => {
                  return (
                    <Form>
                      <BaseInputs
                        label={t("remain.old-password")}
                        name={"oldPassword"}
                        type={"password"}
                      />
                      <BaseInputs
                        label={t("remain.new-password")}
                        name={"newPassword"}
                        type={"password"}
                      />
                      <BaseInputs
                        label={t("remain.confirmpassword")}
                        name={"confirmPassword"}
                        type={"password"}
                      />
                      <Row
                        justify="space-between"
                        style={{
                          marginBottom: 10,
                        }}
                      ></Row>
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <BaseButton
                          htmlType="submit"
                          style={{
                            background: "var(--primary)",
                            color: "#fff",
                          }}
                          disabled={isSubmitting || changePasswordLoading}
                          loading={isSubmitting || changePasswordLoading}
                        >
                          {t("remain.change-password")}
                        </BaseButton>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
              {/* <Auth.ActionsWrapper>
          <Link to="/auth/forgot-password">
            <S.ForgotPasswordText>psfgskgj</S.ForgotPasswordText>
          </Link>
        </Auth.ActionsWrapper> */}
            </BaseCard>
          </Auth.LoginWrapper>
        </Auth.BackgroundWrapper>
      </Auth.Wrapper>
    </>
  );
};

export default ProfileChangePassword;
