const QR = {
  getDetailsByQRCodeId: (id: any): any => {
    return {
      url: `QR/getActiveQRCodeDetailsById/${id}`,
      config: {
        store: {
          action: "set",
          key: "qrDetails.data",
        },
      },
    };
  },
  addOperator: (data: any): any => {
    return {
      url: "auth/registerOperator",
      method: "post",
      data,
    };
  },

  getOperatorInfo: (id: string): any => {
    return {
      url: `auth/getOperator/${id}`,
      method: "get",
    };
  },
  getAllQRCodes: (action, params): any => {
    return {
      url: "QR/getAllQRCodes",
      method: "get",
      params: params,
      config: {
        store: {
          action: action,
          key: "qr.data",
        },
      },
    };
  },
  getBatch: (params) => {
    return {
      url: "QR/getAllBatch",
      method: "get",
      params: params,
      config: {
        store: {
          action: params?.page === 0 ? "set" : "update",
          key: "qrBatch.data",
        },
      },
    };
  },
  deleteQrSticker: (qrStickerId: string) => {
    return {
      url: `QR/deleteQRCode/${qrStickerId}`,
      method: "delete",
    };
  },
  unblockQRSticker: (qrStickerId: string) => {
    return {
      url: `QR/unBlockQRCodeById/${qrStickerId}`,
      method: "post",
    };
  },
  linkCamera: (qrId: string, cameraId: string) => {
    return {
      url: `QR/linkCameraToQRCodeById/${qrId}`,
      data: { cameraId },
      method: "post",
    };
  },
  markQrToShow: (id, data) => {
    return {
      url: `QR/markQRShowFromGroupByQRCodeId/${id}`,
      method: "post",
      data,
    };
  },
  verifyBatchName: (data) => {
    return {
      url: `QR/verifyBatch`,
      data,
      method: "post",
    };
  },
  assignCameraToGroup: (id, data) => {
    return {
      url: `QR/updateCameraOnQRCodeById/${id}`,
      method: "patch",
      data,
    };
  },
};

export default QR;
