export default [
  {
    title: "1. Informazioni che raccogliamo:",
    desc: [
      {
        title: "1.1",
        desc: "Informazioni personali: Quando interagisci con il nostro progetto di conformità delle telecamere, potremmo raccogliere informazioni personali come il tuo nome, indirizzo email e informazioni di contatto.",
      },
      {
        title: "1.2",
        desc: "Informazioni sul dispositivo: Potremmo raccogliere informazioni sul dispositivo che utilizzi per accedere ai nostri servizi, inclusi il tipo di dispositivo, il sistema operativo, il tipo di browser e l'indirizzo IP.",
      },
      {
        title: "1.3",
        desc: "Informazioni sulla posizione: Con il tuo esplicito consenso, potremmo raccogliere informazioni sulla posizione dal tuo dispositivo per fornirti informazioni accurate sulla conformità relative alla città e all'area in cui ti trovi.",
      },
      {
        title: "1.4",
        desc: "Informazioni sulla scansione della telecamera: Quando scansioni un codice QR su un palo con una telecamera, potremmo raccogliere dati relativi alla posizione della telecamera, data e ora della scansione e le informazioni di conformità recuperate.",
      },
    ],
  },
  {
    title: "2. Come utilizziamo le tue informazioni:",
    desc: [
      {
        title: "2.1",
        desc: "Utilizziamo le informazioni raccolte per fornirti informazioni accurate sulla conformità relative all'area della città in cui hai scansionato il codice QR.",
      },
      {
        title: "2.2",
        desc: "Potremmo utilizzare le tue informazioni personali per inviarti aggiornamenti, notifiche e informazioni importanti relative al progetto di conformità delle telecamere.",
      },
      {
        title: "2.3",
        desc: "Analizziamo dati aggregati e anonimizzati a fini statistici, che potrebbero aiutarci a migliorare i nostri servizi.",
      },
    ],
  },

  {
    title: "3. Condivisione delle informazioni:",
    desc: [
      {
        title: "3.1",
        desc: "Non condividiamo le tue informazioni personali con terze parti per scopi di marketing senza il tuo esplicito consenso.",
      },
      {
        title: "3.2",
        desc: "Potremmo condividere le tue informazioni con i nostri fornitori di servizi che ci aiutano a fornire i nostri servizi. Questi fornitori di servizi sono obbligati a mantenere la riservatezza delle tue informazioni.",
      },
      {
        title: "3.3",
        desc: "Potremmo divulgare le tue informazioni se richiesto dalla legge o per proteggere i nostri diritti, la privacy, la sicurezza o la proprietà.",
      },
    ],
  },

  {
    title: "4. Sicurezza dei dati:",
    desc: [
      {
        title: "4.1",
        desc: "Implementiamo misure di sicurezza per proteggere le tue informazioni personali da accessi non autorizzati, alterazioni, divulgazioni o distruzioni.",
      },
      {
        title: "4.2",
        desc: "Nonostante i nostri sforzi, nessuna trasmissione o archiviazione online è completamente sicura. Pertanto, non possiamo garantire la sicurezza assoluta delle tue informazioni.",
      },
    ],
  },

  {
    title: "5. Le tue scelte:",
    desc: [
      {
        title: "5.1",
        desc: "Puoi scegliere di non ricevere comunicazioni di marketing da noi seguendo le istruzioni per l'annullamento dell'iscrizione fornite nella comunicazione.",
      },
      {
        title: "5.2",
        desc: "Puoi controllare la raccolta delle informazioni sulla posizione regolando le impostazioni sul tuo dispositivo.",
      },
    ],
  },

  {
    title: "6. Privacy dei minori:",
    desc: [
      {
        title: "6.1",
        desc: "I nostri servizi non sono destinati a individui di età inferiore a [età]. Non raccogliamo intenzionalmente informazioni personali da minori.",
      },
    ],
  },

  {
    title: "7. Modifiche a questa politica sulla privacy:",
    desc: [
      {
        title: "7.1",
        desc: "Potremmo aggiornare questa politica sulla privacy di volta in volta per riflettere modifiche nelle nostre pratiche o per altre ragioni operative, legali o regolamentari.",
      },
      {
        title: "7.2",
        desc: "Forniremo un avviso evidente di eventuali modifiche sostanziali a questa politica sulla privacy.",
      },
    ],
  },
  {
    title: "8. Contattaci:",
    desc: [
      {
        desc: "Se hai domande, preoccupazioni o richieste riguardo a questa politica sulla privacy o all'uso delle tue informazioni personali, ti preghiamo di contattarci all'indirizzo [indirizzo email di contatto].",
      },
    ],
  },
];
