import { Row, Col, Space, Switch, Radio } from "antd";
import BaseButton from "components/common/BaseButton";
import BaseColorPicker from "components/common/BaseColorPricker";
import { BaseInputNumber } from "components/common/BaseInputNumber";
import BaseSlider from "components/common/BaseSlider";
import { BaseTypography } from "components/common/BaseTypography";
import { QR_DASHBOARD_MAIN_PATH } from "constants/routes/route";
import { number } from "echarts";
import { Formik } from "formik";
import useAPI from "hooks/useApi";
import React, { useState } from "react";
import { RgbaColorPicker } from "react-colorful";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import publicPage from "shared/front/apis/public-page.api";
import { css, styled } from "styled-components";
import { idText } from "typescript";
import { FlexEnd, FlexEndLastRow } from "utils/styles";
import toastr from "utils/toastHandler";
import { object } from "yup";

const LeftPart = ({
  colorState,
  setColorState,
  showImage,
  setShowImage,
  id,
  closeModal,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [
    makeCustomizeRequest,
    { loading: customizeLoading, data: customizeData },
  ] = useAPI();
  const [makeGetRequest, { loading: publicPageLoading, data: publicPageInfo }] =
    useAPI();

  const handleCustomization = async (id) => {
    const payload = colorState;
    try {
      await makeCustomizeRequest(
        publicPage.updateCustomizationById(id, payload)
      );
      closeModal();
      toastr.success(t("remain.successfully-customized"));
    } catch (error) {
      toastr.error(error);
    }
  };
  const handleToggle = () => {
    setShowImage(!showImage);
  };
  return (
    <CustomizerWrapper>
      <Space direction="vertical" size="large" style={{ display: "flex" }}>
        {/* <Row>
          <BaseTypography.Text style={{ marginRight: "20px" }}>
            {t("remain.profile-logo")}
          </BaseTypography.Text>
          <SwitchButton
            checked={colorState?.logo}
            onChange={(e) => {
              setColorState((prev) => ({ ...prev, logo: e }));
            }}
          />
        </Row> */}
        <Row>
          <BaseTypography.Text style={{ marginRight: "20px" }}>
            {t("new.show-camera")}
          </BaseTypography.Text>
          <SwitchButton
            checked={colorState.isCameraDetails}
            onChange={() => {
              setColorState((prev) => ({
                ...prev,
                isCameraDetails: !prev.isCameraDetails,
              }));
            }}
          />
        </Row>
        <Row>
          <BaseTypography.Text style={{ marginRight: "20px" }}>
            Banner Size
          </BaseTypography.Text>

          <Radio.Group
            onChange={(e) =>
              setColorState((prev) => ({
                ...prev,
                objectFit: e.target.value,
              }))
            }
            value={colorState?.objectFit}
          >
            <Radio value={"contain"}>Fit</Radio>
            <Radio value={"cover"}>Center</Radio>
          </Radio.Group>
        </Row>
        <Row>
          <Col>
            <BaseColorPicker
              label={t("remain.banner-background-color")}
              value={colorState.bannerBackgroundColor}
              onChange={(value) => {
                const rbgaValue = value.toHexString();
                setColorState((prev) => ({
                  ...prev,
                  bannerBackgroundColor: rbgaValue,
                }));
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <BaseColorPicker
              label={t("remain.background-color")}
              value={colorState.backgroundColor}
              onChange={(value) => {
                const rbgaValue = value.toHexString();
                setColorState((prev) => ({
                  ...prev,
                  backgroundColor: rbgaValue,
                }));
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <BaseColorPicker
              label={t("remain.body-font-color")}
              value={colorState.fontColor}
              onChange={(value) => {
                const rbgaValue = value.toHexString();
                setColorState((prev) => ({
                  ...prev,
                  fontColor: rbgaValue,
                }));
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <BaseColorPicker
              label={t("remain.footer-background-color")}
              value={colorState.footerColor}
              onChange={(value) => {
                const rbgaValue = value.toHexString();
                setColorState((prev) => ({
                  ...prev,
                  footerColor: rbgaValue,
                }));
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <BaseColorPicker
              label={t("remain.footer-font-color")}
              value={colorState.footerFontColor}
              onChange={(value) => {
                const rbgaValue = value.toHexString();
                setColorState((prev) => ({
                  ...prev,
                  footerFontColor: rbgaValue,
                }));
              }}
            />
          </Col>
        </Row>
      </Space>

      <FlexEnd>
        <BaseButton
          danger
          style={{
            margin: "40px 10px",
          }}
          onClick={() => {
            closeModal();
          }}
        >
          {/* {t("remain.publish")} */}
          {t("dashboard.cancel")}
        </BaseButton>
        <BaseButton
          style={{
            margin: "40px 0px",
            background: "var(--primary)",
            color: "#fff",
          }}
          onClick={() => {
            handleCustomization(id);
          }}
        >
          {t("remain.publish")}
        </BaseButton>
      </FlexEnd>
    </CustomizerWrapper>
  );
};

export default LeftPart;
const CustomizerWrapper = styled.div`
  margin: 20px 0px;
`;

const SwitchButton = styled(Switch)``;

// test
