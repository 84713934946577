import { Button, Divider, Spin } from "antd";
import BaseButton from "components/common/BaseButton";
import { BaseTag } from "components/common/BaseTag";
import { BaseTypography } from "components/common/BaseTypography";
import { useResponsive } from "hooks/useResponsive";
import { useEffect, useState } from "react";
import { getI18n, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import groupApi from "shared/front/apis/group.api";
import request from "utils/request";
import { FlexColumnAlignCenter } from "utils/styles";
import {
  CameraContainerStyled,
  CameraStyled,
  CategoryStyled,
  PublicPageViewStyled,
  QRStyled,
  SelectedCameraContainerStyled,
  SingleCameraInfoStyled,
  ViewGroupsStyled,
} from "./style";
import { fullConvertTimestamp } from "utils/utils";
import { GoBack } from "components/GoBack";
import ViewQrSticker from "components/StickerSelection/ViewQrSticker";

type Props = {};
// groupData, isVisible, onClose, loading;
const ViewGroup = ({}: Props) => {
  const { language: selectedlang } = getI18n();
  const { t } = useTranslation();
  const { isTablet } = useResponsive();
  const { groupId } = useParams();
  const navigate = useNavigate();

  // STATE
  const [loading, setLoading] = useState(false);

  // SELECTOR
  const groupData = useSelector((state: any) => state?.groupsDetails);

  useEffect(() => {
    if (groupId) {
      getGroupDetails(groupId);
    }
  }, [groupId]);

  const getGroupDetails = async (gId) => {
    setLoading(true);
    await request(groupApi.getGroupDetail(gId));
    setLoading(false);
  };

  const getSelectedLanguageTitle = (details) => {
    if ((Array.isArray(details) && details.length === 0) || !details) {
      return "N/A";
    }

    const selectedLangTitle = details.find((x) => x?.lang === selectedlang);
    if (!!selectedLangTitle?.title) {
      return selectedLangTitle?.title;
    }
    return details[0].title;
  };
  const getSelectedLanguageDesc = (details) => {
    if ((Array.isArray(details) && details.length === 0) || !details) {
      return "N/A";
    }

    const selectedLangTitle = details.find((x) => x?.lang === selectedlang);
    if (!!selectedLangTitle?.desc) {
      return selectedLangTitle?.desc;
    }
    return details[0].desc;
  };

  return (
    <>
      <GoBack />
      <ViewGroupsStyled>
        {loading ? (
          <FlexColumnAlignCenter>
            <Spin size="large" />
            <BaseTypography.Text>Getting Groups Details</BaseTypography.Text>
          </FlexColumnAlignCenter>
        ) : (
          <>
            <BaseTypography.Title level={4}>
              {groupData?.name}
            </BaseTypography.Title>

            <Divider style={{ margin: "0px 0px 15px" }} />

            <PublicPageViewStyled>
              <div className="title-wrapper">
                <BaseTypography.Title level={5} style={{ margin: "0px" }}>
                  {t("remain.public-page")}
                </BaseTypography.Title>
                <BaseButton
                  onClick={() =>
                    navigate(
                      `/dashboard/public-page/customize-page/${groupData?.publicView?._id}`
                    )
                  }
                >
                  {t("remain.view-public-page")}
                </BaseButton>
              </div>
              <div className="page-row">
                <BaseTypography.Text className="title">
                  {t("remain.title")}
                </BaseTypography.Text>
                <BaseTypography.Text className="value">
                  {getSelectedLanguageTitle(groupData?.publicView?.details)}
                </BaseTypography.Text>
              </div>
              <div className="page-row">
                <BaseTypography.Text className="title">
                  {t("remain.status")}
                </BaseTypography.Text>
                <BaseTag
                  className="tag"
                  color={
                    groupData?.publicView?.status === "Unverified"
                      ? "var(--error)"
                      : "var(--success)"
                  }
                >
                  {groupData?.publicView?.status}
                </BaseTag>
              </div>
            </PublicPageViewStyled>

            <CategoryStyled>
              <div className="title-wrapper">
                <BaseTypography.Title level={5} style={{ margin: "0px" }}>
                  {t("remain.category")}
                </BaseTypography.Title>
              </div>
              <div className="category-value">
                <div className="cat-row">
                  <BaseTypography.Text className="title">
                    {t("remain.name")}
                  </BaseTypography.Text>
                  <BaseTypography.Text className="value">
                    {groupData?.category?.name}
                  </BaseTypography.Text>
                </div>
                {Array.isArray(groupData?.subCategory) &&
                  groupData?.subCategory?.length > 0 && (
                    <div className="cat-row">
                      <BaseTypography.Text className="title">
                        {t("remain.sub-category")}
                      </BaseTypography.Text>
                      {groupData?.subCategory.map((sub, index) => (
                        <BaseTypography.Text key={index} className="value">
                          {sub.name}
                          {index + 1 < groupData?.subCategory?.length
                            ? `, `
                            : ""}
                        </BaseTypography.Text>
                      ))}
                    </div>
                  )}
              </div>
            </CategoryStyled>
            {Array.isArray(groupData?.camera) &&
              groupData?.camera?.length > 0 && (
                <CameraStyled>
                  <div className="title-wrapper">
                    <BaseTypography.Title level={5} style={{ margin: "0px" }}>
                      {t("remain.camera")}
                    </BaseTypography.Title>
                  </div>

                  <CameraContainerStyled>
                    {groupData?.camera.map((cam, idx) => (
                      <SingleCameraInfoStyled
                        key={cam?._id}
                        className={
                          idx + 1 === groupData?.camera.length ? "last-cam" : ""
                        }
                        isBlocked={cam?.isArchive}
                      >
                        <div className="camera-row">
                          <BaseTypography.Text className="title">
                            {t("remain.name")}
                          </BaseTypography.Text>
                          <BaseTypography.Text className="value">
                            {cam?.name ?? "N/A"}
                          </BaseTypography.Text>
                        </div>
                        {cam?.address && (
                          <div className="camera-row">
                            <BaseTypography.Text className="title">
                              {t("dashboard.address")}{" "}
                            </BaseTypography.Text>
                            <BaseTypography.Text className="value">
                              {cam?.address}
                            </BaseTypography.Text>
                          </div>
                        )}
                        <div className="camera-row">
                          <BaseTypography.Text className="title">
                            {t("dashboard.longitude")}{" "}
                          </BaseTypography.Text>
                          <BaseTypography.Text className="value">
                            {cam?.longitude}
                          </BaseTypography.Text>
                        </div>
                        <div className="camera-row">
                          <BaseTypography.Text className="title">
                            {t("dashboard.latitude")}{" "}
                          </BaseTypography.Text>
                          <BaseTypography.Text className="value">
                            {cam?.latitude}
                          </BaseTypography.Text>
                        </div>
                        <div className="camera-row">
                          <BaseTypography.Text className="title">
                            {t("dashboard.blocked")}
                          </BaseTypography.Text>
                          <BaseTypography.Text className="value">
                            {cam?.isArchive ? "Yes" : "No"}
                          </BaseTypography.Text>
                        </div>
                        <div className="camera-row">
                          <BaseTypography.Text className="title">
                            {t("remain.created-by")}
                          </BaseTypography.Text>
                          <BaseTypography.Text className="value">
                            {cam?.isCreateAutomatic ? "System" : "Admin"}
                          </BaseTypography.Text>
                        </div>
                        <div className="camera-row">
                          <BaseTypography.Text className="title">
                            {t("dashboard.created-date")}
                          </BaseTypography.Text>
                          <BaseTypography.Text className="value">
                            {fullConvertTimestamp(cam?.createdAt)}
                          </BaseTypography.Text>
                        </div>
                        <div className="view-camera-wrapper">
                          <Button
                            onClick={() =>
                              navigate(`/dashboard/camera/${cam?._id}`)
                            }
                          >
                            {t("remain.view-camera")}
                          </Button>
                        </div>
                      </SingleCameraInfoStyled>
                    ))}
                  </CameraContainerStyled>
                </CameraStyled>
              )}

            {Array.isArray(groupData?.QRCode) &&
              groupData?.QRCode?.length > 0 && (
                <QRStyled>
                  <div className="title-wrapper">
                    <BaseTypography.Title level={5} style={{ margin: "0px" }}>
                      {t("dashboard.qr")}
                    </BaseTypography.Title>
                  </div>

                  <div className="qr-sticker-wrapper">
                    {groupData?.QRCode?.map((val) => (
                      <div
                        className="qr-wrapper"
                        onClick={() =>
                          navigate(`/dashboard/generated-qr/${val?._id}`)
                        }
                      >
                        <ViewQrSticker
                          disablePreview
                          frontEndURL={val?.frontendURL}
                          stickerDimensions={val?.sticker?.stickerDimension}
                          stickerURL={val?.sticker?.URL}
                          imgSize="center"
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "contain",
                          }}
                        />{" "}
                      </div>
                    ))}
                  </div>
                </QRStyled>
              )}
          </>
        )}
      </ViewGroupsStyled>
    </>
  );
};

export default ViewGroup;
