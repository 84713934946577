import React from "react";
import { SectionContainer } from "./style";
import SectionCamera from "../../../assets/camera-section.png";
import { Button, Typography } from "antd";
import { useNavigate } from "react-router-dom";
type Props = {};

const Section = (props: Props) => {
  const navigate = useNavigate();
  return (
    <SectionContainer>
      <div className="content-container">
        <img src={SectionCamera} />
        <div className="content">
          <Typography className="text">
            Who Can Use the Camera Compliance System?
          </Typography>
          <Typography
            style={{
              textAlign: "center",
              marginBottom: "15px",
              color: "#818181",
            }}
          >
            Our system is specifically designed for a diverse range of
            stakeholders who play a pivotal role in urban development, public
            safety, and data protection. Below are the key users who can
            significantly benefit from our GDPR-compliant surveillance
            solutions.
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              style={{ backgroundColor: "#167A8B", color: "white" }}
              onClick={() => navigate("/camera-compliance-details")}
            >
              Read More
            </Button>
          </div>
        </div>
      </div>
    </SectionContainer>
  );
};

export default Section;
