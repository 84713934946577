const notifications = {
  getAllNotifications: ({ params }: IParams): any => {
    return {
      url: "common/getAllNotifications",
      method: "get",
      params,
      config: {
        store: {
          action: params.page === 0 ? "set" : "update",
          key: "notifications",
        },
      },
    };
  },
  readSingleNotification: (notificationId: string): any => {
    return {
      url: `common/readNotificationById/${notificationId}`,
      method: "post",
    };
  },
  readBulkNotification: (data: Array<string>): any => {
    return {
      url: "common/bulkReadNotificationById",
      method: "post",
      data,
    };
  },
};

export default notifications;

interface IParams {
  params?: { page?: number; limit?: number };
  role?: string;
  action?: "set" | "update" | null | undefined;
  type?: "my" | "browse";
}
