import { convertTimestamp, fullConvertTimestamp } from "utils/utils";
import { BaseTag } from "components/common/BaseTag";
import * as Styles from "./index.styles";
import BaseToolTip from "components/common/BaseTooltip";

interface OperatorsTableRowCardProps {
  firstName: string;
  lastName: string;
  email: string;
  createdDate: string;
  onRowClick: (e: React.MouseEvent<HTMLElement>) => void;
}

const OperatorsTableRowCard = ({
  firstName,
  lastName,
  email,
  createdDate,
  onRowClick,
}: OperatorsTableRowCardProps) => {
  return (
    <Styles.CardWrapper onClick={onRowClick}>
      <div style={{ fontSize: "16px", fontWeight: "600", marginBottom: "6px" }}>
        {firstName} {lastName}
      </div>
      <div style={{ fontSize: "12px", fontWeight: "400" }}>{email}</div>
      <BaseToolTip title={fullConvertTimestamp(createdDate)}>
        <div style={{ fontSize: "10px", fontWeight: "400" }}>
          Created on {convertTimestamp(createdDate)}
        </div>
      </BaseToolTip>
    </Styles.CardWrapper>
  );
};

export default OperatorsTableRowCard;
