export default [
  {
    title: "Limitación de Responsabilidad",
    desc: [
      "Camera Compliance Ltd, sus afiliados, socios y empleados no serán responsables de daños directos, indirectos, incidentales, especiales, consecuentes o punitivos, incluyendo, pero sin limitarse a, pérdidas financieras, pérdida de datos u otros daños que surjan de su uso de este sitio web o de su confianza en el contenido informativo proporcionado.",
    ],
  },
  {
    title: "Sin Asesoramiento Profesional",
    desc: [
      "La información proporcionada en este sitio web no constituye asesoramiento legal, técnico u otro tipo de asesoramiento profesional. Aunque nos esforzamos por proporcionar información precisa y actualizada, no ofrecemos garantías ni representaciones de ningún tipo sobre la precisión, actualidad o integridad del sitio web.",

      "Para obtener asesoramiento detallado adaptado a su situación, consulte a profesionales calificados, incluyendo, pero no limitado a, profesionales legales con experiencia en protección de datos y cumplimiento del RGPD.",
    ],
  },
  {
    title: "Ley Aplicable",
    desc: [
      "Esta renuncia se regirá e interpretará de acuerdo con las leyes de la jurisdicción en la que Camera Compliance Ltd opera. Al utilizar este sitio web, usted acepta la jurisdicción de dichos tribunales para cualquier acción, demanda o procedimiento derivado de esta renuncia.",
    ],
  },
];
