export default [
  {
    title: "1. Tiedot, jotka keräämme:",
    desc: [
      {
        title: "1.1",
        desc: "Henkilötiedot: Kun vuorovaikutat kameran noudattamishankkeemme kanssa, voimme kerätä henkilötietoja, kuten nimesi, sähköpostiosoitteesi ja yhteystietosi.",
      },
      {
        title: "1.2",
        desc: "Laitetiedot: Voimme kerätä tietoja laitteesta, jota käytät käyttääksesi palveluitamme, mukaan lukien laitetyyppi, käyttöjärjestelmä, selaimen tyyppi ja IP-osoite.",
      },
      {
        title: "1.3",
        desc: "Sijaintitiedot: Nimenomaisella suostumuksellasi voimme kerätä laitteestasi sijaintitietoja, jotta voimme tarjota sinulle tarkkaa noudattamistietoa liittyen kaupunkiin ja alueeseen, jossa olet.",
      },
      {
        title: "1.4",
        desc: "Kameran skannaustiedot: Kun skannaat QR-koodin tolpan kameralla, voimme kerätä tietoja kameran sijainnista, skannauksen päivämäärästä ja ajasta sekä saaduista noudattamistiedoista.",
      },
    ],
  },
  {
    title: "2. Kuinka käytämme tietojasi:",
    desc: [
      {
        title: "2.1",
        desc: "Käytämme kerättyjä tietoja tarjotaksemme sinulle tarkkaa noudattamistietoa liittyen kaupungin alueeseen, jossa olet skannannut QR-koodin.",
      },
      {
        title: "2.2",
        desc: "Voimme käyttää henkilötietojasi lähettääksemme sinulle päivityksiä, ilmoituksia ja tärkeää tietoa, joka liittyy kameran noudattamishankkeeseen.",
      },
      {
        title: "2.3",
        desc: "Analysoimme yhdistettyä ja anonymisoitua dataa tilastollisiin tarkoituksiin, mikä voi auttaa meitä parantamaan palveluitamme.",
      },
    ],
  },

  {
    title: "3. Tietojen jakaminen:",
    desc: [
      {
        title: "3.1",
        desc: "Emme jaa henkilötietojasi kolmansille osapuolille markkinointitarkoituksiin ilman nimenomaista suostumustasi.",
      },
      {
        title: "3.2",
        desc: "Voimme jakaa tietojasi palveluntarjoajillemme, jotka auttavat meitä palveluidemme toimittamisessa. Nämä palveluntarjoajat ovat velvollisia pitämään tietosi luottamuksellisina.",
      },
      {
        title: "3.3",
        desc: "Voimme paljastaa tietosi, jos se on vaadittu lailla tai suojellaksemme oikeuksiamme, yksityisyyttä, turvallisuutta tai omaisuutta.",
      },
    ],
  },

  {
    title: "4. Tietoturva:",
    desc: [
      {
        title: "4.1",
        desc: "Toteutamme tietoturvatoimenpiteitä suojataksemme henkilötietojasi luvattomalta pääsyltä, muutoksilta, paljastamiselta tai tuhoutumiselta.",
      },
      {
        title: "4.2",
        desc: "Huolimatta ponnisteluistamme, mikään verkkosiirto tai tallennus ei ole täysin turvallinen. Siksi emme voi taata tietojesi ehdotonta turvallisuutta.",
      },
    ],
  },

  {
    title: "5. Valintasi:",
    desc: [
      {
        title: "5.1",
        desc: "Voit estää markkinointiviestien vastaanottamisen meiltä noudattamalla viestissä annettuja peruutusohjeita.",
      },
      {
        title: "5.2",
        desc: "Voit hallita sijaintitiedon keräämistä säätämällä asetuksia laitteellasi.",
      },
    ],
  },

  {
    title: "6. Lasten yksityisyys:",
    desc: [
      {
        title: "6.1",
        desc: "Palvelumme eivät ole tarkoitettu alle [ikä]. Emme tietoisesti kerää henkilötietoja lapsilta.",
      },
    ],
  },

  {
    title: "7. Muutokset tähän yksityisyyskäytäntöön:",
    desc: [
      {
        title: "7.1",
        desc: "Voimme päivittää tätä yksityisyyskäytäntöä aika ajoin heijastamaan muutoksia käytäntöissämme tai muista toiminnallisista, oikeudellisista tai sääntelyyn liittyvistä syistä.",
      },
      {
        title: "7.2",
        desc: "Ilmoitamme huomattavista muutoksista tähän yksityisyyskäytäntöön.",
      },
    ],
  },
  {
    title: "8. Ota yhteyttä:",
    desc: [
      {
        desc: "Jos sinulla on kysymyksiä, huolenaiheita tai pyyntöjä liittyen tähän yksityisyyskäytäntöön tai henkilötietojesi käyttöön, ota yhteyttä meihin osoitteessa [contact email].",
      },
    ],
  },
];
