import React from "react";
import { styled } from "styled-components";
import NoBannerImage from "../../../assets/nobanner.png";
import { media } from "styles/themes/constants";
import { BaseTypography } from "components/common/BaseTypography";
const LogoCityImage = styled.img`
  width: 65px;
  height: 65px;
  border-radius: 50%;
  border: 1px solid var(--border);
  object-fit: cover;
  margin-bottom: 1rem;
  background-color: var(--background);
  @media only screen and ${media.md} {
    height: 80px;
    width: 80px;
  }
  @media only screen and ${media.xxl} {
    height: 100px;
    width: 100px;
  }
`;
const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const LogoImage = ({ logoImg }: any) => {
  return (
    <LogoWrapper>
      {/* <BaseTypography>City Logo</BaseTypography> */}
      <LogoCityImage
        src={!!logoImg ? logoImg : NoBannerImage}
        alt={!!logoImg ? logoImg : NoBannerImage}
      />
    </LogoWrapper>
  );
};

export default LogoImage;
