import { BaseCard } from "components/common/BaseCard";
import React from "react";
import { getI18n } from "react-i18next";
import styled from "styled-components";
import { FONT_SIZE } from "styles/themes/constants";
import { convertTimestamp } from "utils/utils";

const PublicViewRender = ({ publicPageId, publicViewDataById }: any) => {
  console.log("the public page id", publicPageId, publicViewDataById);
  const { language: selectedlang } = getI18n();
  const getSelectedLanguageTitle = (details) => {
    if ((Array.isArray(details) && details.length === 0) || !details) {
      return "N/A";
    }

    const selectedLangTitle = details.find((x) => x?.lang === selectedlang);
    if (!!selectedLangTitle?.title) {
      return selectedLangTitle?.title;
    }
    return details[0].title;
  };
  return (
    <>
      {publicViewDataById && (
        <PublicPageCard>
          <LeftPartDetails>
            <InfoWrapperStyled>
              <div className="label">Page Title :</div>
              <div className="value">
                {getSelectedLanguageTitle(publicViewDataById?.details)}
              </div>
            </InfoWrapperStyled>
            <InfoWrapperStyled>
              <div className="label">Created At:</div>
              <div className="value">
                {convertTimestamp(publicViewDataById?.createdAt)}
              </div>
            </InfoWrapperStyled>
          </LeftPartDetails>
          <div>
            <img
              src={publicViewDataById?.banner}
              alt={"banner"}
              style={{
                height: "100px",
                objectFit: "contain",
              }}
            />
          </div>
        </PublicPageCard>
      )}
    </>
  );
};

export default PublicViewRender;

const NoLinkFound = styled.div`
  text-align: center;
  font-style: italic;
  color: var(--gray);
  font-size: ${FONT_SIZE.md};
`;

const PublicPageCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 20px;
`;

const LeftPartDetails = styled.div``;
const InfoWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  & .label {
    width: 90px;
    font-weight: 600;
    color: #444;
  }
  & .value {
  }
`;
