import { Spin } from "antd";
import BaseButton from "components/common/BaseButton";
import { BaseCard } from "components/common/BaseCard";
import { BaseTypography } from "components/common/BaseTypography";
import { LoaderWrapper } from "containers/Generated-Qr/index.styles";
import useIntersectionObserver from "hooks/useIntersectionObserver";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import operators from "shared/front/apis/operator.api";
import store from "store";
import { styled } from "styled-components";
import { media } from "styles/themes/constants";
import request from "utils/request";
import { FlexColumnAlignCenter, flexAlignCenter } from "utils/styles";
import { convertTimestamp, fullConvertTimestamp } from "utils/utils";
import { useTranslation } from "react-i18next";

type Props = {
  activity: any;
  operatorId: string;
};

const LIMIT = 10;
const OperatorActivity = ({ operatorId, activity }: Props) => {
  const { t } = useTranslation();
  const allActivity: any[] = useSelector(
    (state: any) => state?.operatorActivity.data
  );
  const pagination = useSelector(
    (state: any) => state?.operatorActivity.pagination
  );
  const [isLoading, setIsLoading] = useState(false);

  const activityLastRowRef = useRef();
  const entry = useIntersectionObserver(activityLastRowRef, {});
  let isIntersecting = undefined;

  if (entry && entry[0]) {
    isIntersecting = entry[0].isIntersecting;
  }
  useEffect(() => {
    if (allActivity.length === 0) {
      getAllActivity();
    }
  }, []);

  useEffect(() => {
    if (isIntersecting && !isLoading && allActivity.length < pagination.count) {
      getAllActivity();
    }
  }, [isIntersecting]);

  const getAllActivity = async () => {
    try {
      setIsLoading(true);
      await request(
        operators.getOperatorActivity({
          operatorId,
          params: { limit: LIMIT, page: pagination?.page },
        })
      );

      store.store.dispatch(
        store.Actions.update("operatorActivity.pagination", {
          page: pagination?.page + 1,
          limit: LIMIT,
        })
      );
    } catch (error) {
      console.log("All Activity:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleShowAllActivity = () => {
    getAllActivity();
  };

  const makeMessage = (type, qr) => {
    if (type === "QR-Update") {
      return (
        <MessageStyled>
          <a href={qr?.stickerURL} target="_blank" rel="noreferrer">
            {t("remain.sticker-qr")}
          </a>{" "}
          {t("remain.of-batch")} {qr?.batch} {t("remain.located-at")}{" "}
          {qr?.address} ({qr?.latitude}, {qr?.longitude}) {t("remain.updated")}
        </MessageStyled>
      );
    }
    return type;
  };
  return (
    <BaseCard>
      <ActivityStyled>
        <BaseTypography.Title level={3}>
          {t("remain.activity")}
        </BaseTypography.Title>
        <ActivityWrapperStyled>
          {Array.isArray(allActivity) &&
            allActivity.map((val, index) => {
              const isLastActivity = index + 1 === allActivity?.length;
              if (isLastActivity) {
                return (
                  <SingleActivityStyled ref={activityLastRowRef}>
                    <div className="wrapper">
                      <BaseTypography.Text type="secondary" className="date">
                        {convertTimestamp(val?.createdAt)}
                      </BaseTypography.Text>
                      <div className="type">{val?.type}</div>
                    </div>
                    <BaseTypography.Text>
                      {makeMessage(val?.type, val?.QRCode)}
                    </BaseTypography.Text>
                  </SingleActivityStyled>
                );
              }
              return (
                <SingleActivityStyled>
                  <div className="wrapper">
                    <BaseTypography.Text type="secondary" className="date">
                      {fullConvertTimestamp(val?.createdAt)}
                    </BaseTypography.Text>
                    <div className="type">{val?.type}</div>
                  </div>
                  <BaseTypography.Text>
                    {makeMessage(val?.type, val?.QRCode)}
                  </BaseTypography.Text>
                </SingleActivityStyled>
              );
            })}
        </ActivityWrapperStyled>
        {isLoading && (
          <FlexColumnAlignCenter>
            <Spin />
          </FlexColumnAlignCenter>
        )}
      </ActivityStyled>
    </BaseCard>
  );
};

export default OperatorActivity;

const ActivityStyled = styled.div``;

export const FlexAligncenter = styled.div`
  ${flexAlignCenter}
`;

const ActivityWrapperStyled = styled.div`
  padding: 0px 0px 30px;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
`;

const SingleActivityStyled = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  padding: 8px 5px;
  gap: 3px;
  border-bottom: 1px solid #ececec;

  @media ${media.md} {
    flex-direction: row;
    align-items: center;
    gap: 15px;
  }

  & .wrapper {
    display: flex;
    align-items: center;
    gap: 15px;
    flex-direction: row;

    & .date {
      width: auto;
    }
    & .type {
      width: 120px;
    }
  }
`;

const MessageStyled = styled.div`
  // font-size: 12px;
  & a {
    text-decoration: underline;
  }
`;
