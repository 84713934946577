import { Row } from "antd";
import styled from "styled-components";

export const RowWrapper = styled(Row)`
  margin: 1rem 0rem;
`;

export const ButtonWrapperStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  & button {
    background: var(--primary);
    color: #fff;
  }
`;

export const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
