import { generate } from "@wcj/generate-password";

export const generatePassword = () => {
  const password = generate({
    length: 10,
    special: false,
    lowerCase: false,
    upperCase: false,
  });
  return password;
};
