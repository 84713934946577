import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as S from "./index.styles";
import * as Auth from "./AuthLayout.styles";
import AuthLogo from "components/logo/AuthLogo";
import { BaseCard } from "components/common/BaseCard";
import { Field, Form, Formik } from "formik";
import { Row } from "antd";
import BaseButton from "components/common/BaseButton";
import BaseInputs from "components/common/BaseInput/BaseInputs";
import useAPI from "hooks/useApi";
import { useRoot } from "RootProvider";
import { loginURL } from "constants/api/auth";
import { handleLogin } from "utils/request";
import { toast } from "react-toastify";
import { QR_ADMIN_CHANGE_PASSWORD_PATH } from "constants/routes/route";
import { SUPER_ADMIN } from "constants/roles/roles";
import Header from "containers/Home/Header";
import { BaseTypography } from "components/common/BaseTypography";
import { useTranslation } from "react-i18next";
import MainHeader from "layouts/Main/MainHeader/MainHeader";
import * as Yup from "yup";
import sockets from "sockets";

export const LoginForm: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  // const [rememberMe, setRememberMe] = useState(false);
  const [handleSubmitRequest, { loading: loginLoading, error }] = useAPI();
  const { setAuth, role, setInitialPassword, auth, initialPassword } =
    useRoot();

  const validationSchema = Yup.object().shape({
    email: Yup.string().required(t("forms.email-required")),
    password: Yup.string().required(t("remain.password-required")),
  });

  const handleSubmit = async (values: any) => {
    try {
      const data = await handleSubmitRequest({
        method: "post",
        url: loginURL,
        data: values,
      });
      handleLogin(data);
      await setAuth(data);
      await setInitialPassword(values.password);

      if (data?.userDetails?.isFirstLogin && role !== SUPER_ADMIN) {
        navigate("/dashboard/change-password");
      } else {
        navigate("/dashboard");
      }
      sockets.connectSocket();
      // toast(t("loginToast.success-login-message"));

      const toastMessage = `${t("loginToast.you-are-logged-in-as")} ${
        data?.userDetails?.role === SUPER_ADMIN
          ? t("super-admin")
          : t("city-admin")
      }`;
      toast(toastMessage);
    } catch (error) {
      if (error && typeof error === "string") {
        toast(error);
      } else if (error?.data?.error) {
        toast(error?.data?.error);
      } else {
        toast(t("login.not-authorized"));
      }
    }
  };
  return (
    <>
      <MainHeader />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1"
      />
      <Auth.Wrapper>
        <Auth.BackgroundWrapper>
          <Auth.LoginWrapper>
            <BaseCard>
              <Auth.FormTitle>
                <div
                  style={{
                    fontWeight: 700,
                    fontSize: "1.625rem",
                    textAlign: "center",
                  }}
                >
                  {t("dashboard.login")}
                </div>
              </Auth.FormTitle>
              <S.LoginDescription>
                <BaseTypography.Text>
                  {t("dashboard.login-information")}
                </BaseTypography.Text>
              </S.LoginDescription>
              <Formik
                initialValues={{ email: "", password: "" }}
                validationSchema={validationSchema}
                onSubmit={(values: ILogin, { setSubmitting }) => {
                  handleSubmit(values);
                  setSubmitting(false);
                }}
                validateOnBlur={false}
              >
                {({ errors, isSubmitting }: any) => {
                  return (
                    <Form>
                      <BaseInputs
                        label={t("dashboard.email")}
                        name={"email"}
                        type={"text"}
                      />
                      <BaseInputs
                        label={t("dashboard.password")}
                        name={"password"}
                        type={"password"}
                      />
                      <Row
                        justify="space-between"
                        style={{
                          marginBottom: 10,
                        }}
                      ></Row>
                      <Auth.ForgetPasswordText
                        to="/auth/forgot-password"
                        style={{ marginLeft: "10px" }}
                      >
                        {t("dashboard.forget-your-password")}
                      </Auth.ForgetPasswordText>

                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <BaseButton
                          htmlType="submit"
                          type={"primary"}
                          disabled={loginLoading || isSubmitting}
                          loading={loginLoading}
                          style={{
                            background: "var(--primary)",
                            color: "#fff",
                          }}
                        >
                          {t("dashboard.login")}
                        </BaseButton>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </BaseCard>
          </Auth.LoginWrapper>
        </Auth.BackgroundWrapper>
      </Auth.Wrapper>
    </>
  );
};

interface ILogin {
  password: string;
  email: string;
}
