import { UrlLink } from "utils/styles";
import * as Styles from "./index.styles";
import { convertTimestamp } from "utils/utils";
import { Image } from "antd";
import BaseButton from "components/common/BaseButton";
import DownloadOutlined from "@ant-design/icons/DownloadOutlined";
import EyeOutlined from "@ant-design/icons/EyeOutlined";
import { BaseTag } from "components/common/BaseTag";
import { useTranslation } from "react-i18next";

interface QRGeneratorTableRowCardProps {
  url: string;
  stickerUrl: string;
  createdDate: string;
  status: string;
  onDownloadClick: (e) => void;
  onViewClick: (e) => void;
}

const QRGeneratorTableRowCard = ({
  url,
  stickerUrl,
  createdDate,
  status,
  onDownloadClick,
  onViewClick,
}: QRGeneratorTableRowCardProps) => {
  const { t } = useTranslation();
  return (
    <Styles.CardWrapper onClick={onViewClick}>
      <Image
        alt="sticker"
        src={stickerUrl}
        style={{
          width: "80px",
          aspectRatio: "1",
          backgroundColor: "#fff",
          padding: "4px",
          objectFit: "contain",
        }}
        preview={{
          src: stickerUrl,
        }}
      />
      <div style={{ display: "flex", flexDirection: "column" }}>
        <UrlLink onClick={() => window.open(url)}>{url}</UrlLink>
        <div
          style={{ fontSize: "12px", fontWeight: "400", marginBottom: "6px" }}
        >
          {t("new.created-on")}
          {convertTimestamp(createdDate)}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <BaseTag
            color="success"
            style={{ display: "flex", alignItems: "center", height: "23px" }}
          >
            {status}
          </BaseTag>
          {status === "New" && (
            <BaseButton
              onClick={onDownloadClick}
              style={{ display: "flex", alignItems: "center", height: "23px" }}
            >
              <DownloadOutlined />
            </BaseButton>
          )}
          {status !== "New" && (
            <BaseButton
              onClick={onViewClick}
              style={{ display: "flex", alignItems: "center", height: "23px" }}
            >
              <EyeOutlined />
            </BaseButton>
          )}
        </div>
      </div>
    </Styles.CardWrapper>
  );
};

export default QRGeneratorTableRowCard;
