const city = {
  addCity: (data: ICityCreateType): any => {
    return {
      url: "common/addCity",
      method: "post",
      data,
    };
  },
  getAllCities: (): any => {
    return {
      url: "common/getAllCities",
      method: "get",
    };
  },
  updateCity: (id, values: any): any => {
    return {
      url: `common/updateCity/${id}`,
      method: "patch",
      data: values,
    };
  },
  getMyAssignCity: () => {
    return {
      url: `common/getMyAssignCity`,
      method: "get",
    };
  },
  getCityForSuperAdmin: (id: any): any => {
    return {
      url: `common/getCityForSuperAdmin/${id}`,
    };
  },
  getCity: (action, params): any => {
    return {
      url: "common/getAllCity",
      method: "get",
      params: params,
      config: {
        store: {
          action: action,
          key: "cities.data",
        },
      },
    };
  },
  deleteFooterLogoById: (id) => {
    return {
      url: `group/deleteFooterLogoById/${id}`,
      method: "delete",
    };
  },
};

interface ICityCreateType {
  title: string;
}
interface ICityUpdateType {
  image: string;
  des: string;
}
interface ICityType {
  id: string;
  data: ICityUpdateType;
}

export default city;
