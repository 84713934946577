import { styled } from "styled-components";

export const CapitalText = styled.span`
  text-transform: capitalize;
`;

export const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ActionContainer = styled.div`
  text-align: right;
  & .add-admin {
    margin-right: 10px;
  }
`;
