import EyeOutlined from "@ant-design/icons/EyeOutlined";
import { ColumnsType } from "antd/es/table";
import BaseButton from "components/common/BaseButton";
import { BasePagination } from "components/common/BasePagination";
import { BaseTable } from "components/common/BaseTable";
import HeaderWrapper from "components/headerwrapper";
import { useResponsive } from "hooks/useResponsive";
import { useEffect, useState } from "react";
import { getI18n, useTranslation } from "react-i18next";
import { FaEdit } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import groupApi from "shared/front/apis/group.api";
import store from "store";
import request from "utils/request";
import { convertTimestamp } from "utils/utils";
import CreateGroup from "./CreateGroup";
import GroupsTableRowCard from "./GroupsTableRowCards";
import { GroupsStyled } from "./style";

import { BaseTypography } from "components/common/BaseTypography";
import BaseToolTip from "components/common/BaseTooltip";
const Groups = () => {
  const navigate = useNavigate();
  const { language: selectedlang } = getI18n();
  let [searchParams, setSearchParams] = useSearchParams();
  const { isLargeMobile } = useResponsive();
  const { t } = useTranslation();
  const [loading, setLoading] = useState({
    groups: false,
    groupsDetails: false,
  });
  const [createNewGroup, setCreateNewGroup] = useState(false);
  const [selectedGroupData, setSelectedGroupData] = useState(null);
  // SELECTORS
  const groups = useSelector((state: any) => state?.groups?.data);
  const groupsDetails = useSelector((state: any) => state?.groupsDetails);
  const groupsPagination = useSelector(
    (state: any) => state?.groups?.pagination
  );
  const dispatch = useDispatch();
  const columns: ColumnsType = [
    {
      title: t("dashboard.s.n."),
      dataIndex: "rowNo",
      key: "rowNo",
      rowScope: "row",
    },
    {
      title: t("group.group-name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("group.category"),
      dataIndex: "category",
      key: "category",
    },
    {
      title: t("group.public-page"),
      dataIndex: "publicView",
      key: "publicView",
      render: (text: string, record: any) => (
        <BaseTypography.Paragraph
          ellipsis={{ rows: 1 }}
          style={{ maxWidth: "250px" }}
        >
          {text}
        </BaseTypography.Paragraph>
      ),
    },
    {
      title: t("dashboard.created-date"),
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: t("dashboard.action"),
      dataIndex: "action",
      key: "action",
      align: "right" as "right",
      render: (url: string, record: any) => (
        <>
          <BaseButton
            onClick={(e) => {
              e.stopPropagation();
              navigate(record?.id);
            }}
            style={{ marginLeft: " 10px", padding: "0px" }}
            type="link"
          >
            <BaseToolTip title={t("tooltip.view-group")}>
              <EyeOutlined
                style={{
                  fontSize: "24px",
                  color: "var(--primary)",
                }}
              />
            </BaseToolTip>
          </BaseButton>
          <BaseButton
            onClick={(e) => {
              e.stopPropagation();
              setCreateNewGroup(true);
              setSelectedGroupData(record);
            }}
            style={{ marginLeft: "15px", padding: "0px" }}
            type="link"
          >
            <BaseToolTip title={t("tooltip.edit-group")}>
              <FaEdit
                style={{
                  height: "24px",
                  width: "24px",
                  color: "var(--skyblue)",
                }}
              />
            </BaseToolTip>
          </BaseButton>
        </>
      ),
    },
  ];
  const getSelectedLanguageTitle = (details) => {
    if ((Array.isArray(details) && details.length === 0) || !details) {
      return "N/A";
    }

    const selectedLangTitle = details.find((x) => x?.lang === selectedlang);
    if (!!selectedLangTitle?.title) {
      return selectedLangTitle?.title;
    }
    return details[0].title;
  };

  const groupsDataSource = groups?.map((item: any, index: any) => ({
    rowNo: groupsPagination.limit * groupsPagination.page + index + 1,
    key: item._id,
    id: item._id,
    url: item.URL,
    name: item?.name,
    publicView: getSelectedLanguageTitle(item?.publicView?.details),
    category: item.category?.name,
    createdAt: convertTimestamp(item.createdAt),
    status: item.status,
  }));

  useEffect(() => {
    const queryGroupOpen = searchParams.get("openGroupModal");
    if (queryGroupOpen) {
      handleAddGroup();
      searchParams.delete("openGroupModal");
      setSearchParams(searchParams);
    }
  }, [searchParams]);
  useEffect(() => {
    if (Array.isArray(groups) && groups?.length === 0) {
      dispatch(
        store.Actions.set("groups.pagination", {
          limit: 10,
          page: 0,
        })
      );
      getAllGroups({ limit: 10, page: 0 });
    }
  }, []);

  useEffect(() => {
    if (selectedGroupData?.id) {
      getGroupDetails(selectedGroupData?.id);
    } else {
      dispatch(store.Actions.set("groupsDetails", {}));
    }
  }, [selectedGroupData?.id, createNewGroup]);

  const getGroupDetails = async (gId) => {
    setLoading((prev) => ({ ...prev, groupsDetails: true }));
    await request(groupApi.getGroupDetail(gId));
    setLoading((prev) => ({ ...prev, groupsDetails: false }));
  };
  const onViewRow = async (record) => {
    navigate(record.id);
  };
  const handlePagination = (page, limit) => {
    const { status } = groupsPagination;
    dispatch(
      store.Actions["update"]("groups.pagination", {
        page,
        limit,
      })
    );
    getAllGroups({ page, limit, status });
  };
  const getAllGroups = async (params) => {
    try {
      setLoading((prev) => ({ ...prev, groups: true }));
      await request(groupApi.getAllGroups("set", params));
    } catch (error) {
      console.log("Fetching Groups:", error);
    } finally {
      setLoading((prev) => ({ ...prev, groups: false }));
    }
  };
  const handleAddGroup = async () => {
    setCreateNewGroup(true);
  };
  const onCloseAddGroup = async () => {
    setCreateNewGroup(false);
    dispatch(store.Actions.set("groupsDetails", {}));
    setSelectedGroupData(null);
  };

  return (
    <>
      <GroupsStyled>
        <HeaderWrapper
          title={t("group.group")}
          actionTitle={t("group.create-group")}
          handleAction={handleAddGroup}
        />
        {isLargeMobile ? (
          <BaseTable
            dataSource={groupsDataSource}
            columns={columns}
            onRow={(record, rowIndex) => {
              return {
                onClick: () => {
                  onViewRow(record);
                },
              };
            }}
            pagination={false}
            loading={loading.groups}
          />
        ) : (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {groupsDataSource?.map((group) => (
              <GroupsTableRowCard
                name={group.name}
                publicView={group.publicView}
                category={group.category}
                createdDate={group.createdAt}
                onViewClick={() => {
                  const id = group?.id;
                  navigate(`${id}`);
                }}
                onEditClick={(e) => {
                  e.stopPropagation();
                  setCreateNewGroup(true);
                  setSelectedGroupData(group);
                }}
              />
            ))}
          </div>
        )}
        <BasePagination
          pagination={groupsPagination}
          handlePagination={handlePagination}
        />
      </GroupsStyled>
      <CreateGroup
        isVisible={createNewGroup}
        onClose={onCloseAddGroup}
        dataToEdit={groupsDetails}
      />
    </>
  );
};
export default Groups;
