import styled from "styled-components";
export const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  background-color: var(--background);
  @media only screen and (max-width: 500px) {
    background-color: white;
    overflow: hidden;
  }
`;

export const BackgroundWrapper = styled.div`
  width: 100%;
  height: calc(100% - 70px);
  display: flex;
  justify-content: center;
  align-items: center;

  overflow: hidden;
`;

export const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;

  @media only screen and (max-width: 800px) {
    /* padding: 2.5rem 1.25rem; */
    width: 80%;
  }

  @media only screen and (max-width: 500px) {
    /* padding: 2.5rem 1.25rem; */
    width: 100%;
    /* height: 100%; */
    overflow: hidden;
  }
`;
