import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAPI from "./hooks/useApi";
import { verifyToken } from "./constants/api/auth";
import { readUser } from "./services/localStorage";
import { ADMIN } from "constants/roles/roles";
import { QR_DASHBOARD_MAIN_PATH } from "constants/routes/route";
import Socket from "./sockets";

export const RootContext = createContext<{
  auth: any;
  setAuth: React.Dispatch<React.SetStateAction<undefined>>;
  checkAuth: any;
  checkRole: (role: string) => boolean;
  role: string;
  initialPassword: string;
  setInitialPassword: React.Dispatch<React.SetStateAction<undefined>>;
}>({
  auth: {},
  setAuth: () => {},
  checkAuth: () => {},
  checkRole: () => false,
  role: "",
  initialPassword: "",
  setInitialPassword: () => {},
});

export const RootProvider = ({ children }: any) => {
  const navigate = useNavigate();
  const [auth, setAuth] = useState<any>();
  const [handleCheckRequest] = useAPI();
  const [isReady, setReady] = useState(false);
  const [role, setRole] = useState("");
  const [initialPassword, setInitialPassword] = useState("");

  const checkAuth = async () => {
    const user = readUser();
    if (user?.token) {
      axios.defaults.headers.Authorization = `Bearer ${user?.token}`;
      let data;
      try {
        data = await handleCheckRequest({
          method: "get",
          url: verifyToken,
        });
        // navigate(`${QR_DASHBOARD_MAIN_PATH}`);
      } catch (err) {}
      if (data?.userDetails?._id) {
        axios.defaults.headers.Authorization = `Bearer ${data?.token}`;
        setAuth({
          ...data,
          fullName: `${data.firstName} ${data.lastName}`,
        });

        await setInitialPassword(data?.userDetails?.initialPassword);
        Socket.connectSocket();

        // if (data.onboardingStep < (data?.role === "service" ? 5 : 4)) {
        //   navigate("/onboarding", { replace: true });
        // }
        // Set the role state
        // setRole(data?.userDetails?.role);
      }
    } else {
      setAuth(undefined);
    }
    setReady(true);
  };

  const checkRole = (role: string) => {
    return auth && auth?.userDetails?.role === role;
  };

  useEffect(() => {
    checkAuth();
  }, []);

  useEffect(() => {
    if (auth && auth?.userDetails?.role) {
      setRole(auth.userDetails.role);
    }
  }, [auth]);

  return (
    <RootContext.Provider
      value={{
        auth,
        setAuth,
        checkAuth,
        checkRole,
        role,
        initialPassword,
        setInitialPassword,
      }}
    >
      {isReady ? children : null}
    </RootContext.Provider>
  );
};

export const useRoot = () => useContext(RootContext);

export default RootProvider;
