import { Divider, Image } from "antd";
import { BaseTypography } from "components/common/BaseTypography";
import React from "react";
import { styled } from "styled-components";
import { FONT_SIZE, FONT_WEIGHT, media } from "styles/themes/constants";
import { devices } from "utils/theme";

const InterSection = ({ QrDetailsCameraData, cState }: any) => {
  return (
    <>
      <InterSectionWrapper cState={cState}>
        {Array.isArray(QrDetailsCameraData) &&
          QrDetailsCameraData.map((cam) => {
            if (cam?.isArchive) {
              return null;
            }
            return (
              <div className="individual-camera-details">
                <Title cState={cState}> {cam?.name}</Title>
                <AddressText cState={cState}>{cam?.address}</AddressText>
                <IntersectionImageContainerStyled>
                  {!!cam?.images?.length &&
                    cam?.images.map((img) => (
                      <div className="individual-image">
                        <div>
                          <Title level={5} cState={cState} className="label">
                            {img?.label}
                          </Title>
                          <AddressText cState={cState} className="desc">
                            {img?.description}
                          </AddressText>
                        </div>
                        <Image
                          src={img?.URL}
                          alt={img?.description}
                          className="image"
                        />
                      </div>
                    ))}
                </IntersectionImageContainerStyled>
                <Divider />
              </div>
            );
          })}
      </InterSectionWrapper>
    </>
  );
};

export default InterSection;
const InterSectionWrapper = styled.div<{ cState: any }>`
  color: ${(props) => props?.cState?.fontColor} !important;
`;
const Title = styled(BaseTypography.Title)<{
  cState: any;
}>`
  color: ${(props) => props?.cState?.fontColor} !important;
  font-size: ${FONT_SIZE.md} !important;
  @media only screen and ${media.md} {
    font-size: ${FONT_SIZE.lg} !important;
  }
`;
const AddressText = styled(BaseTypography.Text)<{ cState: any }>`
  color: ${(props) => props?.cState?.fontColor} !important;
  font-size: ${FONT_SIZE.xxs} !important;
  @media only screen and ${media.md} {
    font-size: ${FONT_SIZE.xs} !important;
  }
`;

export const IntersectionImageContainerStyled = styled.div`
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(auto-fit, 1fr);
  gap: 40px;
  justify-items: start;

  @media ${devices.mobileSM} {
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  }
  & .individual-image {
    margin: 5px 0px 30px 0px;
    background: #fffa;
    border-radius: 5px;
    padding: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    & .desc {
      margin: 10px 0px;
    }
    & .label {
      margin: 0px 0px 5px;
    }

    & .image {
      max-height: 300px;
      width: 100%;
      object-fit: cover;
    }
  }
`;
