import produce, { createDraft } from "immer";
import { SET, RESET, UPDATE, RESET_REDUX, REMOVE } from "./constants";
import { getActionKeys, hasSubKeyInAction } from "../utils/utils";
import initialState from "./initialState";

let RESET_COUNT = 0;

const handleSetAction = (action, draft) => {
  if (action?.data?.data) {
    if (hasSubKeyInAction(action.key)) {
      const [key, subkey] = getActionKeys(action.key);
      draft[key][subkey] = action.data?.data;
      if (action.data?.pagination) {
        draft[key]["pagination"]["count"] = action.data?.pagination?.count;
        draft[key]["pagination"]["totalVisibleData"] =
          action?.data?.data?.length;
      }
    } else {
      draft[action.key] = action.data?.data;
      if (action.data?.pagination) {
        draft["pagination"] = {
          ...action.data?.pagination,
          totalVisibleData: action?.data?.data?.length,
        };
      }
    }
  } else {
    if (hasSubKeyInAction(action.key)) {
      const [key, subkey] = getActionKeys(action.key);
      draft[key][subkey] = action.data;
    } else {
      draft[action.key] = action.data;
    }
  }
};

const handleUpdateAction = (action, draft) => {
  if (hasSubKeyInAction(action.key)) {
    const [key, subkey] = getActionKeys(action.key);
    if (draft[key].hasOwnProperty([subkey])) {
      if (Array.isArray(draft[key][subkey])) {
        if (action?.data?.data) {
          draft[key][subkey] = [...draft[key][subkey], ...action.data?.data];
          if (action.data?.pagination) {
            draft[key]["pagination"]["count"] = action.data?.pagination?.count;
            draft[key]["pagination"]["totalVisibleData"] =
              action.data?.pagination?.totalVisibleData + 1;
          }
        } else {
          draft[key][subkey] = [...draft[key][subkey], ...action.data];
        }
      } else if (typeof initialState[key][subkey] === "object") {
        draft[key][subkey] = { ...draft[key][subkey], ...action.data };
      } else {
        draft[key][subkey] = action.data;
      }
    }
  } else {
    if (draft.hasOwnProperty([action.key])) {
      if (Array.isArray(draft[action.key])) {
        if (action?.data?.data) {
          draft[action.key] = [...draft[action.key], ...action.data?.data];
          if (action.data?.pagination) {
            draft["pagination"] = action.data?.pagination;
          }
        } else {
          draft[action.key] = [...draft[action.key], ...action.data];
        }
      } else if (typeof initialState === "object") {
        draft[action.key] = { ...draft[action.key], ...action.data };
      } else {
        draft[action.key] = action.data;
      }
    }
  }
};

const handleResetAction = (action, draft) => {
  if (hasSubKeyInAction(action.key)) {
    const [key, subkey] = getActionKeys(action.key);
    draft[key][subkey] = initialState[key][subkey];
  } else {
    draft[action.key] = initialState[action.key];
  }
};

const handleRemoveAction = (action, draft) => {
  const prevData = draft[action.key];
  if (prevData?.data && prevData?.pagination) {
    const filterData = prevData?.data?.filter(
      (item) => item?._id !== action.id
    );
    const updateData = {
      data: filterData,
      pagination: {
        ...prevData?.pagination,
        count: prevData?.pagination?.count - 1,
        totalVisibleData: filterData?.length,
      },
    };
    draft[action.key] = updateData;
  }
};

const appReducer = (
  state = initialState,
  action: {
    key?: keyof typeof initialState;
    data?: any;
    type: string;
  }
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case RESET_REDUX:
        return initialState;
      case SET:
        // if (action?.data?.data) {
        //   draft[action.key] = action.data?.data;
        //   if (action.data?.pagination) {
        //     draft["pagination"] = action.data?.pagination;
        //   }
        // } else {
        //   draft[action.key] = action.data;
        // }
        handleSetAction(action, draft);
        break;
      case RESET:
        handleResetAction(action, draft);
        break;
      case REMOVE:
        handleRemoveAction(action, draft);
        break;

      case UPDATE: {
        // if (draft[action.key]) {
        //   try {
        //     if (typeof action.data === "function") {
        //       draft[action.key] = action.data(draft[action.key]);
        //     } else {
        //       if (Array.isArray(draft[action.key])) {
        //         if (action?.data?.data) {
        //           draft[action.key] = [
        //             ...draft[action.key],
        //             ...action.data?.data,
        //           ];
        //           if (action.data?.pagination) {
        //             draft["pagination"] = action.data?.pagination;
        //           }
        //         } else {
        //           draft[action.key] = [...draft[action.key], ...action.data];
        //         }
        //       } else if (typeof initialState === "object") {
        //         draft[action.key] = { ...draft[action.key], ...action.data };
        //       }
        //     }
        //   } catch (err) {
        //     console.log("state update failed err", err);
        //   }
        //   break;
        // }
        handleUpdateAction(action, draft);
        break;
      }
    }
  });

export default appReducer;
