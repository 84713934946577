import { ColumnsType } from "antd/es/table";
import ActionButton from "components/ActionWrapper/ActionButton";
import BaseButton from "components/common/BaseButton";
import { BaseTable } from "components/common/BaseTable";
import HeaderWrapper from "components/headerwrapper";
import { QR_DASHBOARD_MAIN_PATH } from "constants/routes/route";
import React, { useEffect, useState } from "react";
import { getI18n, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FaEdit, FaPalette } from "react-icons/fa";
import useAPI from "hooks/useApi";
import publicPage from "shared/front/apis/public-page.api";
import { useDispatch, useSelector } from "react-redux";
import type { Dispatch } from "redux";
import store from "store";
import ConfirmationMessagePopup from "components/ConfirmationMessagePopup/ConfirmationMessagePopup";
import request from "utils/request";
import { BaseTypography } from "components/common/BaseTypography";
import { BasePagination } from "components/common/BasePagination";
import { Image, Typography } from "antd";
import { useLanguage } from "hooks/useLanguage";
import { LANGUAGES } from "constants/countries";
import { BaseTag } from "components/common/BaseTag";
import * as S from "./index.styles";
import BaseTabs from "components/common/BaseTabs";
import ActionWrapper from "components/ActionWrapper/ActionWrapper";
import { useResponsive } from "hooks/useResponsive";
import PublicPageTableRowCard from "./PublicPageTableRowCard";
import EyeOutlined from "@ant-design/icons/EyeOutlined";
import { TbLock, TbLockOpen } from "react-icons/tb";
import BaseToolTip from "components/common/BaseTooltip";

const PublicPage = () => {
  const { t } = useTranslation();
  const { isLargeMobile } = useResponsive();
  const { language: selectedlang } = getI18n();

  const navigate = useNavigate();
  const { language, setLanguage } = useLanguage();
  const languageOptions = LANGUAGES[language];
  const dispatch: Dispatch = useDispatch();
  const publicPageData = useSelector((state: any) => state.publicPage.data);
  const pagination = useSelector((state: any) => state.publicPage.pagination);
  const [selectedPublicPage, setSelectedPublicPage] = useState<{
    id?: string;
    isArchive?: boolean;
  }>({});
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [imagePreviewActive, setImagePreviewActive] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [getRequest, { loading: publicViewLoading, data: publicViewData }] =
    useAPI();

  const tabItems = [
    {
      key: "Active",
      label: t("dashboard.active"),
      archive: false,
    },

    {
      key: "Blocked",
      label: t("dashboard.blocked"),
      archive: true,
    },
  ];
  const getAllPublicPageData = async (params) => {
    await getRequest(publicPage.getAllPublicPage("set", params));
  };

  const refetchOperator = () => {
    let { status, page, limit } = pagination;
    if (publicPageData?.length > 1) {
      getAllPublicPageData({
        page,
        limit,
        archive: status === "Blocked",
      });
    } else {
      if (page > 0) {
        handlePagination(page - 1, limit);
      }
    }
  };

  const closeDeleteModal = () => {
    setIsDeleteModalVisible(false);
    setSelectedPublicPage({});
  };

  const updateBlockAndunblockStatus = (actionType, id) => {
    dispatch(store.Actions.remove("publicPage", id));
    refetchOperator();
  };

  const handlePublicPageDelete = async (id) => {
    try {
      setIsLoading(true);
      const response = await request(publicPage.deletePublicPage(id));
      if (response) {
        const actionType = "block";
        updateBlockAndunblockStatus(actionType, id);
      }
      closeDeleteModal();
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleunblockPublicPage = async (id) => {
    try {
      setIsLoading(true);
      const response = await request(publicPage.unblockPublicPageById(id));
      if (response) {
        const actionType = "unblocked";
        updateBlockAndunblockStatus(actionType, id);
      }
      closeDeleteModal();
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleTabs = (status) => {
    console.log("the status", status);
    dispatch(
      store.Actions["update"]("publicPage.pagination", {
        page: 0,
        status,
        archive: status === "Blocked",
        limit: 10,
      })
    );
    getAllPublicPageData({
      page: 0,
      archive: status === "Blocked",
      limit: 10,
    });
  };

  const handlePagination = (page, limit) => {
    const { status } = pagination;
    dispatch(
      store.Actions["update"]("publicPage.pagination", {
        page,
        limit,
      })
    );
    getAllPublicPageData({ page, limit, archive: status === "Blocked" });
  };

  const getSelectedLanguageTitle = (details) => {
    if ((Array.isArray(details) && details.length === 0) || !details) {
      return "N/A";
    }

    const selectedLangTitle = details.find((x) => x?.lang === selectedlang);
    if (!!selectedLangTitle?.title) {
      return selectedLangTitle?.title;
    }
    return details[0].title;
  };

  const dataSource = publicPageData?.map((item: any, index: any) => ({
    rowNo: pagination.limit * pagination.page + index + 1,
    key: item?._id,
    id: item?._id,
    url: item?.banner,
    banner: item?.banner,
    pageTitle: getSelectedLanguageTitle(item?.details),
    languages: item?.details?.map((item) => item.lang),
    customize: item?.customization,
    isArchive: item?.isArchive,
  }));

  const columns: ColumnsType = [
    {
      title: t("dashboard.s.n."),
      dataIndex: "rowNo",
      key: "rowNo",
      rowScope: "row",
    },
    {
      title: t("dashboard.banner"),
      dataIndex: "banner",
      key: "banner",
      render: (imageURL: string) => {
        if (!imageURL) {
          return (
            <div
              style={{
                height: "60px",
                width: "100px",
                border: "1px solid #dbdbdb",
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "6px",
                background: "#ececec",
              }}
            >
              <Typography.Text
                style={{ fontSize: 12, fontWeight: 600 }}
                type="secondary"
              >
                No Banner
              </Typography.Text>
            </div>
          );
        }
        return (
          <Image
            alt={imageURL}
            src={imageURL}
            height={"50px"}
            style={{ width: "100px", objectFit: "cover" }}
            preview={{
              src: imageURL,
            }}
            onClick={(e) => {
              e.stopPropagation();
              setImagePreviewActive(true);
            }}
          />
        );
      },
    },
    {
      title: t("dashboard.page-title"),
      dataIndex: "pageTitle",
      key: "pageTitle",
      render: (text: any) => {
        return (
          <BaseTypography.Paragraph
            ellipsis={{ rows: 1 }}
            style={{ maxWidth: "250px" }}
          >
            {text}
          </BaseTypography.Paragraph>
        );
      },
    },

    {
      title: t("dashboard.language"),
      dataIndex: "languages",
      key: "languages",
      render: (text: string) => {
        const selectedLanguage = languageOptions?.filter((option) =>
          text.includes(option.value)
        );
        return (
          <S.LanguagesRow>
            {selectedLanguage?.map((option) => (
              <BaseTag
                style={{
                  width: "fit-content",
                }}
              >
                <S.LanguageContainer key={option.value}>
                  <S.Flag>{option.flag}</S.Flag>
                  <S.LanguageValue>{option.value}</S.LanguageValue>
                </S.LanguageContainer>
              </BaseTag>
            ))}
          </S.LanguagesRow>
        );
      },
    },
    // {
    //   title: t("dashboard.customize"),
    //   dataIndex: "customize",
    //   key: "customzie",
    //   render: (text: any) => {
    //     return (
    //       <>
    //         <BaseTag>
    //           {!!text ? <span>Customized</span> : <span>Not Customized</span>}
    //         </BaseTag>
    //       </>
    //     );
    //   },
    // },
    {
      title: t("dashboard.action"),
      dataIndex: "action",
      key: "action",
      align: "right" as "right",
      render: (text: any, record: any) => {
        return (
          <>
            <BaseButton
              type="link"
              style={{ marginLeft: "15px", padding: "0px" }}
              onClick={(e) => {
                e.stopPropagation();
                navigate(
                  `${QR_DASHBOARD_MAIN_PATH}/public-page/customize-page/${record.id}?openModal=true`
                );
              }}
            >
              <BaseToolTip title={t("tooltip.customize-public-page")}>
                <FaPalette
                  style={{
                    height: "24px",
                    width: "24px",
                    color: "var(--skyblue)",
                  }}
                />
              </BaseToolTip>
            </BaseButton>
            <BaseButton
              type="link"
              style={{ marginLeft: "15px", padding: "0px" }}
              onClick={(e) => {
                e.stopPropagation();
                navigate(
                  `${QR_DASHBOARD_MAIN_PATH}/public-page/edit/${record?.id}`,
                  {
                    state: {
                      isEdit: true,
                    },
                  }
                );
                dispatch(
                  store.Actions["update"]("publicPage", {
                    data: [],
                    ...pagination,
                  })
                );
              }}
            >
              <BaseToolTip title={t("tooltip.edit-public-page")}>
                <FaEdit
                  style={{
                    height: "24px",
                    width: "24px",
                    color: "var(--skyblue)",
                  }}
                />
              </BaseToolTip>
            </BaseButton>
            <BaseButton
              onClick={(e) => {
                e.stopPropagation();
                const res = publicPageData?.find((x) => x._id === record?.id);
                setSelectedPublicPage((prev) => ({
                  ...prev,
                  id: res._id,
                  isArchive: res.isArchive,
                }));
                setIsDeleteModalVisible(true);
              }}
              type="link"
              style={{ marginLeft: "15px", padding: "0px" }}
            >
              {record?.isArchive ? (
                <BaseToolTip title={t("tooltip.unblock-public-page")}>
                  <TbLockOpen
                    style={{
                      height: "24px",
                      width: "24px",
                      color: "var(--tairo)",
                    }}
                  />
                </BaseToolTip>
              ) : (
                <BaseToolTip title={t("tooltip.block-public-page")}>
                  <TbLock
                    style={{
                      height: "24px",
                      width: "24px",
                      color: "var(--orange)",
                    }}
                  />
                </BaseToolTip>
              )}
            </BaseButton>
            <BaseButton
              onClick={(e: any) => {
                e.stopPropagation();
                const id = record?.id;
                navigate(`customize-page/${id}`);
              }}
              type="link"
              style={{ marginLeft: "15px", padding: "0px" }}
            >
              <BaseToolTip title={t("tooltip.view-public-page")}>
                <EyeOutlined
                  style={{
                    fontSize: "24px",
                    color: "var(--primary)",
                  }}
                />
              </BaseToolTip>
            </BaseButton>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    const { limit } = pagination;
    getAllPublicPageData({
      page: 0,
      limit,
      archive: false,
    });
    dispatch(
      store.Actions["update"]("publicPage.pagination", {
        page: 0,
        limit,
        status: "Active",
        archive: false,
      })
    );
  }, []);

  return (
    <>
      <HeaderWrapper title={t("dashboard.public-page")} />
      <ActionWrapper>
        <BaseTabs
          items={tabItems}
          handleTabs={handleTabs}
          statusValue={pagination.status}
        />
        <ActionButton
          actionTitle={t("category.add-public-page")}
          handleAction={() => {
            navigate(`${QR_DASHBOARD_MAIN_PATH}/public-page/edit`);
            dispatch(
              store.Actions["update"]("publicPage", {
                data: [],
                ...pagination,
              })
            );
          }}
        />
      </ActionWrapper>

      {isLargeMobile ? (
        <BaseTable
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          onRow={(record) => ({
            onClick: (e: any) => {
              const id = record?.id;
              if (!imagePreviewActive) {
                navigate(`customize-page/${id}`);
              }
            },
            style: { cursor: "pointer" },
          })}
        />
      ) : (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {dataSource.map((publicPage) => (
            <PublicPageTableRowCard
              key={publicPage.key}
              pageTitle={publicPage.pageTitle}
              banner={publicPage.banner}
              languages={publicPage.languages}
              languageOptions={languageOptions}
              onCustomize={() => {
                navigate(
                  `${QR_DASHBOARD_MAIN_PATH}/public-page/customize-page/${publicPage.id}?openModal=true`
                );
              }}
              onViewClick={() => {
                const id = publicPage?.id;

                navigate(`customize-page/${id}`);
              }}
              onEditClick={() => {
                navigate(
                  `${QR_DASHBOARD_MAIN_PATH}/public-page/edit/${publicPage?.id}`,
                  {
                    state: {
                      isEdit: true,
                    },
                  }
                );
                dispatch(
                  store.Actions["update"]("publicPage", {
                    data: [],
                    ...pagination,
                  })
                );
              }}
              onDeleteClick={() => {
                const res = publicPageData?.find(
                  (x) => x._id === publicPage?.id
                );
                setSelectedPublicPage((prev) => ({ ...prev, id: res._id }));
                setIsDeleteModalVisible(true);
              }}
            />
          ))}
        </div>
      )}
      <BasePagination
        pagination={pagination}
        handlePagination={handlePagination}
      />

      <ConfirmationMessagePopup
        isVisible={isDeleteModalVisible}
        onAction={() => {
          if (selectedPublicPage?.id && selectedPublicPage?.isArchive) {
            handleunblockPublicPage(selectedPublicPage?.id);
          } else {
            handlePublicPageDelete(selectedPublicPage?.id);
          }
        }}
        actionLabel={
          selectedPublicPage?.isArchive
            ? t("remain.unblock")
            : t("remain.block")
        }
        onClose={closeDeleteModal}
        title={`${
          selectedPublicPage?.isArchive
            ? t("remain.unblock-publicPage")
            : t("remain.block-publicPage")
        }`}
        actionIsDanger={true}
        isLoading={isLoading}
      >
        <br />
      </ConfirmationMessagePopup>
    </>
  );
};

export default PublicPage;
