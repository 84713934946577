const publicPage = {
  addPublicView: (data) => {
    return {
      url: "group/addPublicView",
      method: "post",
      data,
    };
  },
  getAllPublicPage: (action, params) => {
    return {
      url: "group/getMyPublicView",
      method: "get",
      params: params,
      config: {
        store: {
          action: action,
          key: "publicPage.data",
        },
      },
    };
  },
  deletePublicPage: (id) => {
    return {
      url: `group/deletePublicViewById/${id}`,
      method: "delete",
    };
  },
  getPublicPageById: (id) => {
    return {
      url: `group/getPublicViewById/${id}`,
      method: "get",
      config: {
        store: {
          action: "set",
          key: "publicViewDataById",
        },
      },
    };
  },
  updatePublicPageById: (id, data) => {
    return {
      url: `group/updatePublicViewById/${id}`,
      method: "patch",
      data,
    };
  },
  getPublicQrCodesDetailById: (id) => {
    return {
      url: `QR/getPublicQRCodesDetailsById/${id}`,
      method: "get",
    };
  },
  updateCustomizationById: (id, data) => {
    return {
      url: `group/updatePublicViewCustomizationById/${id}`,
      method: "patch",
      data,
    };
  },
  unblockPublicPageById: (id) => {
    return {
      url: `group/unBlockPublicViewById/${id}`,
      method: "post",
    };
  },
};
export default publicPage;
