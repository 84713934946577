import BaseButton from "components/common/BaseButton";
import BaseModal from "components/common/BaseModal";
import React, { PropsWithChildren } from "react";
import { ButtonFlexEnd } from "utils/styles";
//@ts-ignore
import { useTranslation } from "react-i18next";

type Props = {
  isVisible: boolean;
  onClose: () => void;
  title: string;
  onAction: (id: string) => void;
  actionLabel: string;
  actionType?: string;
  actionIsDanger?: boolean;
  isLoading?: boolean;
};

const ConfirmationMessagePopup: React.FC<PropsWithChildren<Props>> = ({
  isVisible,
  onClose,
  title,
  children,
  onAction,
  actionLabel = "OK",
  actionType = "primary",
  actionIsDanger = false,
  isLoading = false,
}) => {
  const { t } = useTranslation();
  const handleClose = () => {
    onClose();
  };

  if (!isVisible) {
    return null;
  }

  return (
    <BaseModal
      title={title}
      open={isVisible}
      onCancel={handleClose}
      footer={[]}
      bodyStyle={{ padding: "10px" }}
    >
      <>{children}</>

      <ButtonFlexEnd>
        <BaseButton style={{ marginRight: "10px" }} onClick={handleClose}>
          {t("modal.cancel")}
        </BaseButton>
        <BaseButton
          type={actionType}
          danger={actionIsDanger}
          onClick={onAction}
          loading={isLoading}
        >
          {actionLabel}
        </BaseButton>
      </ButtonFlexEnd>
    </BaseModal>
  );
};

export default ConfirmationMessagePopup;
