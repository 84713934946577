import { styled } from "styled-components";
import { media } from "styles/themes/constants";
import {
  flexColumnJustifyAlignCenter,
  flexRow,
  flexRowAlignCenterJustifySpaceBetween,
} from "utils/styles";
import { devices } from "utils/theme";

export const GeneratedQrDetailsStyled = styled.div`
  padding: 20px 0px;
  // max-width: 1200px;
  margin: 0 auto;
`;

export const HeaderInfoStyled = styled.div`
  margin-bottom: 30px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background: #fff;
  padding: 30px;
  box-shadow: 0px 0px 1px #ccc;
  flex-direction: column;

  @media ${devices.laptop} {
    flex-direction: row;
  }

  & .contents-container {
    flex: 1;
    margin-right: 20px;
    & .info-wrapper {
      margin-right: 20px;
      display: flex;
      align-items: flex-end;
      &-contents {
        flex: 1;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;
      }

      & .info {
        display: flex;
        align-items: start;
        // flex-wrap: wrap;
        margin: 10px 0px;
        & .icons {
          height: 20px;
          width: 20px;
          margin-right: 5px;
          flex-shrink: 0;
        }
      }
    }
  }
  & .sticker-qr-container {
    ${flexRow}
    gap:15px;

    width: 100%;
    justify-content: end;
    @media ${devices.laptop} {
      width: auto;
      justify-content: start;
      flex-direction: row;
    }
    & .sticker-wrapper {
      ${flexColumnJustifyAlignCenter}
      height: 60px;

      & .sticker-qr {
        border: 1px solid #ededed;
        object-fit: contain;
        height: 60px;
        max-width: 130px;
        @media ${devices.mobileSM} {
          height: 120px;
          max-width: 200px;
        }
      }
      & .info {
        margin-top: 0.5rem;
      }
    }
    & .qr-wrapper {
      ${flexColumnJustifyAlignCenter}

      & img {
        height: 60px;
        width: 60px;
        object-fit: contain;
        border: 1px solid #ededed;
        @media ${devices.mobileSM} {
          height: 120px;
          width: 120px;
        }
      }
      & .info {
        margin-top: 0.5rem;
      }
    }
  }
`;

export const ScanInfoStyled = styled.div`
  margin-bottom: 30px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background: #fff;
  padding: 30px;
  box-shadow: 0px 0px 1px #ccc;

  & .info-wrapper {
    width: 150px;
    & .label {
      font-weight: 600;
      font-size: 1.1rem;
      color: #999;
    }
    & .value {
      font-weight: 700;
      font-size: 1rem;
      color: #167a8b;

      padding: 5px 0px 0px;
      & .sub-value {
        padding: 0px 0px 0px 10px;
        font-size: 0.9rem;
        color: #aaa;
      }
    }
  }
`;

export const StatsStyled = styled.div`
  margin-bottom: 30px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background: #fff;
  padding: 10px;
  box-shadow: 0px 0px 1px #ccc;

  & .graph-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    @media only screen and ${media.md} {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 10px;
    }

    & .bar-wrapper {
      background: #f0f9f9;
      padding: 20px;

      & .title {
        font-size: 1rem;
        color: #666;
        margin-bottom: 10px;
        text-align: center;
      }

      width: calc(100% - 5px);
      position: relative;
    }
  }
`;

export const CameraInfoStyled = styled.div`
  background: #fff;
  padding: 10px;
  box-shadow: 0px 0px 1px #ccc;
  margin-bottom: 30px;

  &.no-camera {
    background: #fff1;
    padding: 0px;
  }

  & .no-camera-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0px;
    flex-wrap: wrap;
  }

  & .camera-wrapper {
    padding: 20px;

    & .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #167a8b;
      border-color: none;
    }

    & .camera-info-row {
      display: flex;
      margin-bottom: 6px;
      // flex-wrap: wrap;
      align-items: start;
      flex-direction: column;
      @media (${devices.mobileSM}) {
        flex-direction: row;
        align-items: center;
      }
      &-title {
        width: 110px;
        font-weight: 600;
        color: #666;
      }
      &-value {
        position: relative;
      }
    }
  }
`;

export const DownloadOptionButtonStyled = styled.div<{ $showOptions: boolean }>`
  position: relative;
  & .download-btn-wrapper {
    display: flex;
    align-items: center;
    height: 32px;

    border-radius: 6px;
    overflow: hidden;
    & button.download-btn {
      border: 2px solid var(--primary);
      color: #fff;
      background: var(--primary);
      height: 100%;
      border-radius: 0px;
      border-right: 1px solid #97d2db;
      &:hover {
        color: #fff;
        background: var(--primary);
      }
    }
    & .type-selector {
      background: var(--primary);
      height: 100%;
      cursor: pointer;
      padding: 0px 5px;
      ${flexColumnJustifyAlignCenter} & .icon {
        color: #fff;
      }
    }

    & .download-option-container {
      display: ${({ $showOptions }) => ($showOptions ? "block" : "none")};
      position: absolute;
      top: 40px;
      z-index: 100;
      right: 0px;
      left: 0px;
      background: #fcfcfc;
      box-shadow: 0px 0px 10px 0px #dbdbdb;
      padding: 10px;
      & .option {
        ${flexRowAlignCenterJustifySpaceBetween}
        & button {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          min-width: 40px;
        }
      }
    }
  }
`;

export const QRImagesStyled = styled.div`
  margin-bottom: 30px;
  width: 100%;
  background: #fff;
  padding: 10px;
  box-shadow: 0px 0px 1px #ccc;
`;

export const IntersectionImageContainerStyled = styled.div`
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(auto-fit, 1fr);
  gap: 40px;
  justify-items: start;
  width: 100%;

  @media ${devices.mobileSM} {
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  }

  & .individual-image {
    margin: 5px 0px 30px 0px;
    background: #fffa;
    border: 1px solid #dbdbdb;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    & .image {
      max-height: 300px;
      width: 100%;
      object-fit: cover !important;
    }
  }
`;
