export default [
  {
    title: "1. Informacje, które zbieramy:",
    desc: [
      {
        title: "1.1",
        desc: "Dane osobowe: Gdy korzystasz z naszego projektu zgodności kamer, możemy zbierać dane osobowe, takie jak Twoje imię, adres e-mail i dane kontaktowe.",
      },
      {
        title: "1.2",
        desc: "Informacje o urządzeniu: Możemy zbierać informacje o urządzeniu, którego używasz do korzystania z naszych usług, w tym rodzaj urządzenia, system operacyjny, typ przeglądarki i adres IP.",
      },
      {
        title: "1.3",
        desc: "Informacje o lokalizacji: Za Twoją wyraźną zgodą możemy zbierać informacje o lokalizacji z Twojego urządzenia, aby dostarczyć Ci dokładne informacje o zgodności związanej z miastem i obszarem, w którym się znajdujesz.",
      },
      {
        title: "1.4",
        desc: "Informacje o skanowaniu kamery: Gdy skanujesz kod QR na słupie z kamerą, możemy zbierać dane związane z lokalizacją kamery, datą i godziną skanowania oraz pobranymi informacjami o zgodności.",
      },
    ],
  },
  {
    title: "2. Sposób wykorzystania Twoich informacji:",
    desc: [
      {
        title: "2.1",
        desc: "Wykorzystujemy zebrane informacje, aby dostarczyć Ci dokładne informacje o zgodności związane z obszarem miasta, w którym zeskanowałeś kod QR.",
      },
      {
        title: "2.2",
        desc: "Możemy wykorzystać Twoje dane osobowe, aby przesyłać Ci aktualizacje, powiadomienia i istotne informacje związane z projektem zgodności kamer.",
      },
      {
        title: "2.3",
        desc: "Analizujemy zanonimizowane dane zbiorcze w celach statystycznych, co może pomóc nam udoskonalić nasze usługi.",
      },
    ],
  },

  {
    title: "3. Udostępnianie informacji:",
    desc: [
      {
        title: "3.1",
        desc: "Nie udostępniamy Twoich danych osobowych stronom trzecim w celach marketingowych bez Twojej wyraźnej zgody.",
      },
      {
        title: "3.2",
        desc: "Możemy udostępniać Twoje informacje naszym dostawcom usług, którzy pomagają nam w dostarczaniu naszych usług. Ci dostawcy usług są zobowiązani do zachowania poufności Twoich informacji.",
      },
      {
        title: "3.3",
        desc: "Możemy ujawnić Twoje informacje, jeśli jest to wymagane przez prawo lub w celu ochrony naszych praw, prywatności, bezpieczeństwa lub mienia.",
      },
    ],
  },

  {
    title: "4. Bezpieczeństwo danych:",
    desc: [
      {
        title: "4.1",
        desc: "Wdrażamy środki bezpieczeństwa w celu ochrony Twoich danych osobowych przed nieautoryzowanym dostępem, zmianami, ujawnieniem lub zniszczeniem.",
      },
      {
        title: "4.2",
        desc: "Mimo naszych wysiłków, żadna transmisja ani przechowywanie online nie jest całkowicie bezpieczne. Dlatego nie możemy zagwarantować absolutnego bezpieczeństwa Twoich informacji.",
      },
    ],
  },

  {
    title: "5. Twoje wybory:",
    desc: [
      {
        title: "5.1",
        desc: "Możesz zrezygnować z otrzymywania od nas komunikacji marketingowych, postępując zgodnie z instrukcjami anulowania subskrypcji podanymi w komunikacji.",
      },
      {
        title: "5.2",
        desc: "Możesz kontrolować zbieranie informacji o lokalizacji, dostosowując ustawienia na swoim urządzeniu.",
      },
    ],
  },

  {
    title: "6. Prywatność dzieci:",
    desc: [
      {
        title: "6.1",
        desc: "Nasze usługi nie są przeznaczone dla osób poniżej [wiek]. Nie zbieramy celowo danych osobowych od dzieci.",
      },
    ],
  },

  {
    title: "7. Zmiany w niniejszej Polityce prywatności:",
    desc: [
      {
        title: "7.1",
        desc: "Możemy aktualizować tę Politykę prywatności w celu odzwierciedlenia zmian w naszych praktykach lub z innych operacyjnych, prawnych lub regulacyjnych powodów.",
      },
      {
        title: "7.2",
        desc: "Zapewnimy wyraźne zawiadomienie o wszelkich istotnych zmianach w tej Polityce prywatności.",
      },
    ],
  },
  {
    title: "8. Skontaktuj się z nami:",
    desc: [
      {
        desc: "Jeśli masz pytania, obawy lub prośby dotyczące tej Polityki prywatności lub wykorzystania Twoich danych osobowych, prosimy o kontakt pod adresem [adres e-mail kontaktowy].",
      },
    ],
  },
];
