import { Col, Input, Modal, Row, message } from "antd";
import { BaseTypography } from "components/common/BaseTypography";
import React, { useEffect, useState } from "react";
import * as S from "../index.styles";
import BaseModal from "components/common/BaseModal";
import BaseInputs from "components/common/BaseInput/BaseInputs";
import { Form, Formik } from "formik";
//@ts-ignore
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import BaseButton from "components/common/BaseButton";
import useAPI from "hooks/useApi";
import operators from "shared/front/apis/operator.api";
import BaseError from "components/common/BaseError";
import { toast } from "react-toastify";
import { generatePassword } from "services/generatePassword";
import useClipboard from "hooks/useClipboard";
import { CopyOutlined } from "@ant-design/icons";
import { styled } from "styled-components";
import BasePhoneInput from "components/common/BasePhoneInput";
import { FlexEnd } from "utils/styles";

const AddOperatorModal = ({
  isModalOpen,
  setIsModalOpen,
  onRefresh,
}: IOperatorProps) => {
  const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();
  const [isCopied, copyToClipboard] = useClipboard();
  const [
    makeRequest,
    { loading: addOperatorLoading, error: addOperatorErrors },
  ] = useAPI();
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const handleFormSubmit = async (values: any) => {
    try {
      const { firstName, lastName, email, password, phone, countryCode } =
        values;
      console.log(values, "values");
      await makeRequest(
        operators.addOperator({
          firstName,
          lastName,
          email,
          password,
          phone,
          countryCode,
        })
      );
      onRefresh();
    } catch (error: any) {
      toast.error(error);
    }
  };
  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required(t("forms.name-valid"))
      .min(2, t("forms.name-min-valid"))
      .max(30, t("forms.name-max-valid")),
    lastName: Yup.string()
      .required(t("forms.lastname-valid"))
      .min(2, t("forms.lastname-min-valid"))
      .max(30, t("forms.lastname-max-valid")),
    email: Yup.string()
      .email(t("forms.email-invalid"))
      .required(t("forms.email-required")),
    password: Yup.string().required(t("forms.password-generate")),
    phone: Yup.string().required(t("forms.phone-number")),
  });
  const handleGeneratePassword = (setFieldValue: any) => {
    const password = generatePassword();
    setFieldValue("password", `${password}`);
  };

  const handleCopyToClipboard = (value: any) => {
    copyToClipboard(value);
  };

  const onClipBoardSuccess = () => {
    messageApi.open({
      type: "success",
      content: t("forms.copied-successfully"),
      style: {
        marginTop: "60px",
      },
    });
  };

  return (
    <>
      {contextHolder}
      {isModalOpen && (
        <BaseModal
          title={t("dashboard.add-operator")}
          open={isModalOpen}
          onCancel={closeModal}
          footer={[]}
          width={500}
        >
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
              password: "",
              phone: "",
              countryCode: "",
            }}
            onSubmit={async (values, { setSubmitting }) => {
              await handleFormSubmit(values);
              await setSubmitting(false);
              setIsModalOpen(false);
            }}
            validationSchema={validationSchema}
            validateOnBlur={false}
          >
            {({
              values,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              errors,
            }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <S.RowWrapper align={"middle"}>
                    <Col span={24}>
                      <BaseInputs
                        label={t("dashboard.first-name")}
                        name={"firstName"}
                        type={"text"}
                      />
                    </Col>
                    <Col span={24}>
                      <BaseInputs
                        label={t("dashboard.last-name")}
                        name={"lastName"}
                        type={"text"}
                      />
                    </Col>
                    <Col span={24}>
                      <BaseInputs
                        label={t("dashboard.email")}
                        name={"email"}
                        type={"text"}
                      />
                    </Col>
                    <Col span={24}>
                      <BasePhoneInput
                        label={t("dashboard.phone-no")}
                        name={"phone"}
                        handleChange={(val, country) => {
                          console.log(val, country);
                          setFieldValue("phone", val);
                          setFieldValue("countryCode", country);
                        }}
                      />
                    </Col>

                    <BaseTypography.Text>
                      {t("dashboard.generate-password")}
                    </BaseTypography.Text>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <BaseButton
                        onClick={() => handleGeneratePassword(setFieldValue)}
                        style={{
                          marginRight: "10px",
                          flex: 1,
                          background: "var(--primary)",
                          color: "#fff",
                        }}
                      >
                        {t("dashboard.generate-password")}
                      </BaseButton>
                      {/* <input
                      type="text"
                      value={values?.password}
                      style={{
                        marginRight: "5px",
                        padding: "5px",
                        border: "1px solid #ccc",
                        borderRadius: "3px",
                        fontFamily: "Courier New, monospace",
                        fontSize: "14px",
                        backgroundColor: "#f5f5f5",
                        color: "#333",
                        fontWeight: "bold",
                      }}
                    /> */}
                      <Input
                        name={"password"}
                        type={"text"}
                        value={values?.password}
                        style={{
                          flex: 2,
                          backgroundColor: "#f5f5f5",
                          color: "#333",
                          fontWeight: "bold",
                          fontFamily: "Courier New, monospace",
                        }}
                        onChange={(e) =>
                          setFieldValue("password", e.target.value)
                        }
                      />
                      <div style={{ width: "40px" }}>
                        {values.password && (
                          <CopyOutlined
                            style={{
                              fontSize: "20px",
                              marginLeft: "10px",
                              padding: "10px",
                            }}
                            onClick={() => {
                              handleCopyToClipboard(values?.password);
                              onClipBoardSuccess();
                            }}
                          />
                        )}
                      </div>
                    </div>

                    {errors?.password && (
                      <BaseError>{errors.password}</BaseError>
                    )}
                  </S.RowWrapper>

                  <BaseError>{addOperatorErrors}</BaseError>
                  <FlexEnd>
                    <BaseButton
                      htmlType="submit"
                      disabled={isSubmitting || addOperatorLoading}
                      loading={addOperatorLoading}
                      type={"primary"}
                      style={{
                        background: "var(--primary)",
                        color: "#fff",
                        marginTop: "10px",
                      }}
                    >
                      {t("dashboard.submit")}
                    </BaseButton>
                  </FlexEnd>
                </Form>
              );
            }}
          </Formik>
        </BaseModal>
      )}
    </>
  );
};

export default AddOperatorModal;

interface IOperatorProps {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  onRefresh: () => void;
}

const CopyIcon = styled(CopyOutlined)<{ isActive: boolean }>`
  font-size: 20px;
  margin-left: 15px;
  padding: 10px;
`;
const ButtonWrapperStyled = styled.div`
  ${FlexEnd}
`;
