import { uniqBy, orderBy, flatten } from "lodash";
import { promisifyEmit } from "./helpers";

import Store from "../store";
const store = Store.store;
export function subscribers(socket: any) {
  // socket.on("getUserNotifications", (data: any) => {
  //   store.dispatch(Store.Actions.set("notifications", data));
  // });
  socket.on("notification", async (data: any) => {
    if (data.notificationType === "qr-generated") {
      store.dispatch(Store.Actions.set("qrGeneratedNotifications", data));
    }
    store.dispatch(Store.Actions.update("notifications", [data]));
    socket.emit("getUserUnreadNotificationCount");
  });

  socket.emit("getUserUnreadNotificationCount");

  socket.on("getUserUnreadNotificationCount", (data) => {
    store.dispatch(
      Store.Actions.update("unreadNotificationsCount", { count: data })
    );
  });
}
