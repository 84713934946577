import { Row } from "antd";
import styled from "styled-components";
import { BREAKPOINTS, media } from "styles/themes/constants";
import { devices } from "utils/theme";

export const HeaderWrapper = styled(Row)`
  width: 100%;
  /* background-color: var(--primary); */
  background-color: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 14px 50px 14px 35px;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: var(--headerZindex);

  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 500px) {
    padding: 14px 20px 14px 15px;
  }
  & svg.svg-logo {
    width: 50px;
    height: 50px;
  }

  & .profile-avatar {
    cursor: pointer;
  }
`;

export const LogoWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  & .logoText {
    color: #fff;
    font-size: 0.7rem;
    margin: 0px;
    line-height: 16px;
    color: black;

    @media ${media.md} {
      font-size: 0.9rem;
    }
  }
  & svg {
    height: 24px;
    width: 24px;
    margin-right: 20px;
    @media ${media.md} {
      height: 32px;
      width: 32px;
    }
  }

  & .hamburger-icon {
    display: none;
    @media (max-width: ${BREAKPOINTS.lg}px) {
      display: inline;
      & .icon {
        height: 26px;
        width: 26px;
      }
    }
  }

  & .brand-logo {
    display: none;

    @media (${devices.mobileSM}) {
      display: block;
    }
  }

  & .brand-icon {
    @media (${devices.mobileSM}) {
      display: none;
    }
  }
`;

export const NotificationProfileWrapperStyled = styled.div`
  display: flex;
  align-items: center;
`;
