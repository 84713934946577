import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as S from "./index.styles";
import { ADMIN } from "constants/roles/roles";
import { useTranslation } from "react-i18next";
import { QR_HOME_PATH } from "constants/routes/route";
import ChangePassword from "./ChangePassword"; // Import your ChangePassword component

const ProfileOverlay = ({ auth, onLinkClick }) => {
  const location = useLocation();
  const isHomePage = location.pathname === QR_HOME_PATH;
  const role = auth?.userDetails?.role;
  const naviagte = useNavigate();
  const { t } = useTranslation();
  const [changePasswordModalVisible, setChangePasswordModalVisible] =
    useState(false);

  const openChangePasswordModal = () => {
    setChangePasswordModalVisible(true);
  };

  const closeChangePasswordModal = () => {
    setChangePasswordModalVisible(false);
  };
  return (
    <>
      <S.UserInfo>
        <S.Name>
          {auth?.userDetails?.firstName} {auth?.userDetails?.lastName}
        </S.Name>

        <S.Role>{auth?.userDetails?.role}</S.Role>
      </S.UserInfo>
      {isHomePage && !!auth && (
        <>
          <S.ItemsDivider />
          <S.DashboardLink>
            <Link to="/dashboard">
              <S.Text> {t("dashboard.dashboard")}</S.Text>
            </Link>
          </S.DashboardLink>
        </>
      )}

      <S.ItemsDivider />
      <S.OverlayActions>
        {role === ADMIN && (
          <>
            <Link to="/dashboard/profile" onClick={onLinkClick}>
              <S.Text> {t("dashboard.profile")}</S.Text>
            </Link>
            <S.ItemsDivider />
          </>
        )}

        <S.Name onClick={openChangePasswordModal}>
          <S.LongText>{t("dashboard.change-password")}</S.LongText>
        </S.Name>
        {role === ADMIN && (
          <S.Name onClick={() => naviagte("/test-sticker")}>
            <S.LongText>Test {t("dashboard.sticker")}</S.LongText>
          </S.Name>
        )}

        <ChangePassword
          visible={changePasswordModalVisible}
          onClose={closeChangePasswordModal}
        />
        <Link to="/logout">
          <S.Text>{t("dashboard.log-out")}</S.Text>
        </Link>
      </S.OverlayActions>
    </>
  );
};

export default ProfileOverlay;
