import React from "react";
import { IntersectionImageContainerStyled, QRImagesStyled } from "./styled";
import { Image } from "antd";

type Props = {
  images: Array<{
    _id: string;
    URL: string;
  }>;
};

const QRImages = ({ images }: Props) => {
  return (
    <QRImagesStyled>
      <IntersectionImageContainerStyled>
        {images?.map((img) => (
          <div className="individual-image" key={img?._id}>
            <Image src={img?.URL} alt={img?._id} className="image" />
          </div>
        ))}
      </IntersectionImageContainerStyled>
    </QRImagesStyled>
  );
};

export default QRImages;
