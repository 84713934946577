import { useState, useRef, useEffect } from "react";
import { Col, Row, Input } from "antd";
import { Field, Form, Formik, FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { styled } from "styled-components";
import * as Yup from "yup";
import useAPI from "hooks/useApi";
import { useLanguage } from "hooks/useLanguage";
import city from "shared/front/apis/city.api";
import BaseInputs from "components/common/BaseInput/BaseInputs";
import S3uploaderMessage from "components/common/S3presigned";
import BaseButton from "components/common/BaseButton";
import { BaseCard } from "components/common/BaseCard";
import { ButtonFlexEnd, flexColumnJustifyAlignCenter } from "utils/styles";
import BaseLoading from "components/common/BaseSpin";
import { BaseTypography } from "components/common/BaseTypography";
import { BaseSelect } from "components/common/BaseSelect";
import CustomEditor from "components/Editor";
import { Tabs } from "antd";
import LanguageSelectionModal from "./LanguageSelectionModal";
import * as S from "./index.styles";
import CityFooterLogo from "../CityFooter";
import toastr from "utils/toastHandler";
import publicPage from "shared/front/apis/public-page.api";
import { QR_DASHBOARD_MAIN_PATH } from "constants/routes/route";

type TargetKey = React.MouseEvent | React.KeyboardEvent | string;

const validateSchema = Yup.object().shape({
  title: Yup.string()
    .required("City name is required")
    .min(2, "City name must be at least 2 characters long")
    .max(50, "City name cannot exceed 50 characters"),
  logo: Yup.mixed().required("City icon is required"),
  banner: Yup.mixed().required("City banner is required"),
});

const EditCity = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [tabs, setTabs] = useState([]);
  const [activeKey, setActiveKey] = useState("");
  const [showLanguageModal, setShowLanguageModal] = useState(false);
  const [customErrors, setCustomErrors] = useState([]);
  const formikRef = useRef<FormikProps<any>>(null);
  const [makeUpdateRequest, { loading: updatePublicPageViewLoading }] =
    useAPI();
  const [makeGetRequest, { loading: publicPageLoading, data: cityInfo }] =
    useAPI();
  const [makeAddRequest, { loading: addPublicPageLoading }] = useAPI();
  const [footerLogo, setFooterlogo] = useState([]);

  const addPublicPageView = async (data) => {
    return await makeAddRequest(publicPage.addPublicView(data));
  };

  const getPublicPageByID = async (id) => {
    if (state?.isEdit) await makeGetRequest(publicPage.getPublicPageById(id));
  };

  const updatePublicPageView = async (id, payload) => {
    await makeUpdateRequest(publicPage.updatePublicPageById(id, payload));
  };

  const handleValidation = () => {
    const errs = [];
    tabs.forEach((tab) => {
      if (!tab.title || !tab.desc) {
        errs.push(tab.lang);
      }
    });

    setCustomErrors(errs);
    return errs.length == 0;
  };

  const handleInfoValues = async (values: any) => {
    const isValid = handleValidation();
    if (!isValid) return;
    let payload = {
      title: values.title,
      banner: values.banner,
      footerLogo: (footerLogo.length && footerLogo) || [],
      customization: { isCameraDetails: true },
      details: tabs.map((tab) => ({
        lang: tab.lang,
        title: tab.title,
        desc: tab.desc,
      })),
    };

    if (!!id) {
      payload = {
        ...payload,
        customization: { ...cityInfo?.customization },
      };
    }
    if (!!id) {
      try {
        await updatePublicPageView(id, payload);
        toastr.success(t("public.toast-success-added"));
        navigate(`${QR_DASHBOARD_MAIN_PATH}/public-page`);
      } catch (error) {
        toastr.error(error);
      }
    } else {
      try {
        const res = await addPublicPageView(payload);
        console.log("the res", res?._id);
        toastr.success(t("public.toast-success-update"));
        navigate(
          `${QR_DASHBOARD_MAIN_PATH}/public-page/customize-page/${res?._id}?openModal=true`
        );
      } catch (error) {
        toastr.error(error);
      }
    }
  };

  const onChange = (key: string) => {
    setActiveKey(key);
  };

  const handleConfirmLanguage = (lang) => {
    console.log("the lang====", lang);
    const newTabs = tabs
      .map((tab) => ({ lang: tab.lang, title: tab.title, desc: tab.desc }))
      .concat({
        lang,
        title: "",
        desc: "",
      });
    setTabs(newTabs);
    setActiveKey(lang);
  };

  const remove = (targetKey: TargetKey) => {
    const targetIndex = tabs.findIndex((tab) => tab.lang === targetKey);
    let newTabs = tabs.filter((tab) => tab.lang !== targetKey);
    if (newTabs.length && targetKey === activeKey) {
      const { key } =
        newTabs[targetIndex === newTabs.length ? targetIndex - 1 : targetIndex];
      setActiveKey(key);
    }
    if (customErrors.length) {
      const newErr = customErrors.filter((err) => err != targetKey);
      setCustomErrors(newErr);
    }

    if (newTabs.length === 1) {
      newTabs = newTabs.map((tab) => ({
        lang: tab.lang,
        title: tab.title,
        desc: tab.desc,
        closable: false,
      }));
    }
    setTabs(newTabs);
  };

  const onEdit = (targetKey: TargetKey, action: "add" | "remove") => {
    if (action === "remove") {
      remove(targetKey);
    } else {
      setShowLanguageModal(true);
    }
  };

  const handleLanguageOnChange = (ind, e) => {
    setTabs((prev) => {
      return prev.map((pre, index) => {
        if (index == ind) {
          return {
            ...pre,
            title: e.target.value,
          };
        }

        return pre;
      });
    });
    handleValidation();
  };

  const handleLanguageEditorChange = (ind, val) => {
    setTabs((prev) => {
      return prev.map((pre, index) => {
        if (index == ind) {
          return {
            ...pre,
            desc: val,
          };
        }

        return pre;
      });
    });
    handleValidation();
  };

  useEffect(() => {
    if (!!id) {
      getPublicPageByID(id);
    }
  }, []);

  useEffect(() => {
    if (!publicPageLoading) {
      if (cityInfo?.footerLogo?.length > 0) {
        setFooterlogo(cityInfo?.footerLogo);
      }
      if (cityInfo?.details?.length) {
        setTabs(cityInfo.details);
        setActiveKey(cityInfo.details[0].lang);
      } else {
        setTabs([
          {
            lang: "en",
            title: "",
            desc: "",
          },
        ]);
        setActiveKey("en");
      }
      formikRef.current?.setFieldValue("banner", cityInfo?.banner);
      formikRef.current?.setFieldValue("title", cityInfo?.title);
    }
  }, [cityInfo, publicPageLoading]);
  const items = tabs.map((tab, ind) => {
    const label = `${t(`editCity.${tab.lang}`)}`;
    return {
      label,
      closable: tab.closable,
      key: tab.lang,
      children: (
        <div key={tab.lang}>
          <Col span={12} xs={24} style={{ marginBottom: "10px" }}>
            <BaseTypography.Text>{t("dashboard.name")}</BaseTypography.Text>
            <Input
              name={tab.lang}
              type={"text"}
              placeholder={t("public.title")}
              value={tab.title}
              onChange={(val) => handleLanguageOnChange(ind, val)}
            />
          </Col>
          <Col span={12} xs={24}>
            <BaseTypography.Text>
              {t("dashboard.information")}
            </BaseTypography.Text>
            <CustomEditor
              editorState={tab.desc}
              onEditorChange={(values) => {
                handleLanguageEditorChange(ind, values);
              }}
              placeholder={t("public.city-placeholder")}
            />
          </Col>
        </div>
      ),
    };
  });

  return (
    <>
      {!!publicPageLoading ? (
        <BaseLoading />
      ) : (
        <BaseCard>
          <Row
            gutter={{ xs: 10, md: 15, xl: 30 }}
            style={{ background: "#fff" }}
          >
            <Col span={24}>
              <S.Title>{t("dashboard.public-page-content")}</S.Title>
              <Formik
                innerRef={formikRef}
                initialValues={{
                  banner: "",
                  title: "",
                }}
                onSubmit={async (values, { setSubmitting }) => {
                  try {
                    await handleInfoValues(values);
                  } catch (error) {}
                }}
                validateOnBlur={false}
              >
                {({ values, setFieldValue, errors, isSubmitting }) => {
                  return (
                    <Form>
                      <Row style={{ marginBottom: "10px", paddingTop: "15px" }}>
                        <UploaderColumn span={24}>
                          <Field
                            isFullWidth
                            name="banner"
                            component={S3uploaderMessage}
                            setFieldValue={setFieldValue}
                            fieldName={"banner"}
                            value={values.banner}
                            error={errors.banner}
                            label={t("dashboard.banner")}
                            setButtonLoading={undefined}
                          />
                        </UploaderColumn>
                        <UploaderColumn
                          span={24}
                          style={{ paddingTop: "10px" }}
                        >
                          <CityFooterLogo
                            fieldName={"footerLogo"}
                            setFieldValue={setFieldValue}
                            footerLogoState={footerLogo}
                            setFooterlogo={setFooterlogo}
                          />
                        </UploaderColumn>
                      </Row>

                      <div style={{ height: "350px" }}>
                        <Tabs
                          activeKey={activeKey}
                          onChange={onChange}
                          type="editable-card"
                          onEdit={onEdit}
                          items={items}
                        />
                      </div>
                      <ButtonFlexEnd>
                        <BaseButton
                          onClick={() => {
                            navigate(`${QR_DASHBOARD_MAIN_PATH}/public-page`);
                          }}
                        >
                          {t("dashboard.cancel")}
                        </BaseButton>
                        {!!id ? (
                          <BaseButton
                            type={"primary"}
                            htmlType={"submit"}
                            loading={addPublicPageLoading}
                            disabled={addPublicPageLoading}
                            style={{
                              background: "var(--primary)",
                              color: "#fff",
                            }}
                          >
                            {t("dashboard.update")}
                          </BaseButton>
                        ) : (
                          <BaseButton
                            type={"primary"}
                            htmlType={"submit"}
                            loading={updatePublicPageViewLoading}
                            disabled={updatePublicPageViewLoading}
                            style={{
                              background: "var(--primary)",
                              color: "#fff",
                            }}
                          >
                            {t("dashboard.add")}
                          </BaseButton>
                        )}
                      </ButtonFlexEnd>
                    </Form>
                  );
                }}
              </Formik>

              {customErrors.length > 0 && (
                <Col span={12}>
                  {customErrors.map((err) => (
                    <BaseTypography.Paragraph type="danger" key={err}>
                      {t(`editCity.error-${err}`)}{" "}
                    </BaseTypography.Paragraph>
                  ))}
                </Col>
              )}
            </Col>
          </Row>
        </BaseCard>
      )}

      {showLanguageModal && (
        <LanguageSelectionModal
          showLanguageModal={showLanguageModal}
          tabItems={tabs.map((tab) => tab.lang)}
          handleCloseModal={() => setShowLanguageModal(false)}
          handleConfirmLanguage={handleConfirmLanguage}
        />
      )}
    </>
  );
};

export default EditCity;

const TextArea = styled(BaseInputs)`
  height: 200px !important;
`;
const UploaderColumn = styled(Col)`
  border: "1px solid red";
`;
