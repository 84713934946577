import React from "react";
import { StatsStyled } from "./styled";
import ReactECharts from "echarts-for-react";
import moment from "moment";
import { Dates } from "constants/Dates";
import { useTranslation } from "react-i18next";

type Props = {};

const Stats = ({ activeQrData }) => {
  const { t } = useTranslation();
  const days = Dates.getDays();
  const scanByAndroidDevice = activeQrData?.scanByAndroidDevice;
  const scanByIOSDevice = activeQrData?.scanByIOSDevice;
  const scanByOthersDevice = activeQrData?.scanByOthersDevice;
  const totalWeeklyScan = activeQrData?.totalWeeklyScan;

  const result = totalWeeklyScan?.map((data: any) => {
    const datas = moment(data._id);
    const formattedDate = datas?.format("YYYY-MM-DD");
    const dayOfWeek = datas?.format("ddd");

    return { ...data, dayOfWeek };
  });
  const mapDataToDays = () => {
    const dataForDays = days?.map((day) => {
      const scanDataForDay = result?.find((data) => data.dayOfWeek === day);
      return scanDataForDay ? scanDataForDay.count : 0;
    });
    return dataForDays;
  };

  const optionsOS = {
    grid: { top: 8, right: 8, bottom: 24, left: 36 },
    xAxis: {
      type: "category",
      data: ["OS X", "Android", "Other"],
    },
    yAxis: {
      type: "value",
    },
    series: {
      data: [
        { value: scanByIOSDevice, itemStyle: { color: "#64d684" } },
        { value: scanByAndroidDevice, itemStyle: { color: "#324ea3" } },
        { value: scanByOthersDevice, itemStyle: { color: "#167A8B" } },
      ],
      type: "bar",
      smooth: true,
    },
    tooltip: {
      trigger: "axis",
    },
  };
  const optionsVisitByDate = {
    grid: { top: 8, right: 8, bottom: 24, left: 36 },
    xAxis: {
      type: "category",
      data: days,
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        data: mapDataToDays(),
        type: "line",
        smooth: true,
      },
    ],
    tooltip: {
      trigger: "axis",
    },
  };

  return (
    <StatsStyled>
      <div className="graph-container">
        <div className="bar-wrapper">
          <div className="title">{t("dashboard.Operating-system")}</div>
          <ReactECharts option={optionsOS} />
        </div>
        <div className="bar-wrapper">
          <div className="title">{t("dashboard.weekly-visits")}</div>
          <ReactECharts option={optionsVisitByDate} />
        </div>
      </div>
    </StatsStyled>
  );
};

export default Stats;
