import React, { ReactNode, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useRoot } from "../RootProvider";
import { ReactChildrenType } from "../utils/interfaces";
import { useLocation, Outlet } from "react-router-dom";
import { useContext } from "react";
import Rbac from "./Rbac";
import { ADMIN, ALLOWED_ROLES } from "constants/roles/roles";

const RequireAuth = ({ children }: any) => {
  const { auth } = useRoot();
  const token = auth?.token;
  const role = auth?.userDetails?.role;
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth) {
      return navigate("/auth/login");
      // return <Navigate to="/" />;
    }

    if (!ALLOWED_ROLES.includes(role) && !token) {
      return navigate("/auth/login");

      // return <Navigate to="/auth/login" />;
    }
    if (role === ADMIN && auth?.userDetails?.isFirstLogin) {
      return navigate("/dashboard/change-password");

      // return <Navigate to="/dashboard/change-password" />;
    }
  }, [auth, role, token]);

  if (!auth) {
    return <Navigate to="/auth/login" />;
  }

  return <>{children ? children : <Outlet />}</>;
};

export default RequireAuth;
