import { AutoComplete, Spin } from "antd";
import BaseButton from "components/common/BaseButton";
import { BaseCard } from "components/common/BaseCard";
import BaseModal from "components/common/BaseModal";
import BaseLoading from "components/common/BaseSpin";
import { BaseTypography } from "components/common/BaseTypography";
import useAPI from "hooks/useApi";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { getI18n } from "react-i18next";
import { FaCheckCircle } from "react-icons/fa";
import { useSelector } from "react-redux";
import groupApi from "shared/front/apis/group.api";
import QR from "shared/front/apis/qr.api";
import { styled } from "styled-components";
import { FONT_SIZE } from "styles/themes/constants";
import request from "utils/request";

const GroupViewInfo = ({
  isSelected,
  groupState,
  setGroupState,
  radioValue,
}: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState({
    groupLoading: false,
  });
  const [makeRequest, { loading: groupDataLoading, data: groupDataById }] =
    useAPI();

  const groupsData = useSelector((state: any) => state?.groups?.data);

  const { language: selectedlang } = getI18n();

  const getGroupDataById = async (id) => {
    await makeRequest(groupApi.getGroupDetail(id));
  };

  useEffect(() => {
    if (groupState) {
      getGroupDataById(groupState);
    }
  }, [radioValue, groupState]);

  const initialParams = {
    limit: 10,
    page: 0,
  };

  const groupOptions = () => {
    const options = [];
    if (loading?.groupLoading) {
      options.push({
        label: <Spin size="small" />,
        value: null,
        disabled: true,
      });
    }

    if (Array.isArray(groupsData) && groupsData?.length > 0) {
      return [
        ...options,
        ...groupsData.map((val) => ({
          value: val?.name,
          label: val?.name,
          id: val._id,
        })),
      ];
    }
    return [
      {
        value: null,
        label: "no data found",
        disaled: true,
      },
    ];
  };
  const fetchAllGroup = async (params) => {
    setLoading((prev) => ({ ...prev, groupLoading: true }));

    const actionType = "set";
    // const actionType = cameraPagination?.page === 0 ? "set" : "update";
    await request(
      groupApi.getAllGroups(actionType, {
        limit: params.limit,
        page: params.page,
        search: params.search,
      })
    );
    setLoading((prev) => ({ ...prev, groupLoading: false }));
  };

  const onSearchGroup = (text) => {
    delayedFetchGroup({
      ...initialParams,
      search: text,
    });
  };
  const delayedFetchGroup = useCallback(
    debounce((params) => {
      fetchAllGroup(params);
    }, 500),
    []
  );

  useEffect(() => {
    fetchAllGroup(initialParams);
  }, []);
  const getSelectedLanguageTitle = (details) => {
    if ((Array.isArray(details) && details.length === 0) || !details) {
      return "N/A";
    }

    const selectedLangTitle = details.find((x) => x?.lang === selectedlang);
    if (!!selectedLangTitle?.title) {
      return selectedLangTitle?.title;
    }
    return details[0].title;
  };

  const getGroupValue = (id) => {
    const res = groupOptions().filter((item) => item.id === id);
    return res[0]?.label;
  };

  return (
    <>
      <CameraInfoStyled>
        <div className="camera-wrapper">
          <BaseTypography.Title level={4} style={{ marginBottom: "-10px" }}>
            Group{" "}
            {isSelected ? <FaCheckCircle color="green" size={14} /> : null}
          </BaseTypography.Title>
          <div className="no-camera-wrapper">
            <BaseTypography.Text>
              Add group to associated with this QR.
            </BaseTypography.Text>
          </div>
          <AutoComplete
            value={groupState && getGroupValue(groupState)}
            options={groupOptions()}
            style={{ width: "50%" }}
            placeholder={"Search group"}
            onSearch={(text) => onSearchGroup(text)}
            onSelect={(value, key) => {
              if (!!key) setGroupState(key.id);
            }}
            onChange={(value, key) => {
              if (!!key) setGroupState(key.id);
            }}
          />

          {groupDataById && (
            <BaseCard>
              <InfoWrapperStyled>
                <div className="label">Group Name </div>
                <div className="value">{groupDataById?.name}</div>
              </InfoWrapperStyled>
              <InfoWrapperStyled>
                <div className="label">Category </div>
                <div className="value">{groupDataById?.category?.name}</div>
              </InfoWrapperStyled>
              <InfoWrapperStyled>
                <div className="label">Public Page </div>
                <div className="value">
                  {getSelectedLanguageTitle(groupDataById?.publicView?.details)}
                </div>
              </InfoWrapperStyled>
            </BaseCard>
          )}
        </div>
      </CameraInfoStyled>
      {/* <BaseModal
        title={"Add Public Page"}
        open={isModalOpen}
        onCancel={closeModal}
        onOk={onLinkGroup}
        okText={"Link Public Page"}
      >
        <AutoComplete
          options={groupOptions()}
          style={{ width: "100%" }}
          placeholder={"Search group"}
          onSearch={(text) => onSearchGroup(text)}
          onSelect={(value, key) => {
            setGroupState(key.id);
          }}
        />
      </BaseModal> */}
    </>
  );
};

export default GroupViewInfo;
const CameraInfoStyled = styled.div`
  padding: 20px;
  &.no-camera {
    background: #fff1;
    padding: 0px;
  }

  & .no-camera-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px 0px 20px;
  }

  & .camera-wrapper {
    & .camera-info-row {
      display: flex;
      align-items: center;
      margin-bottom: 6px;
      &-title {
        width: 120px;
        font-weight: 600;
        color: #666;
      }
      &-value {
        position: relative;
      }
    }
  }
`;

const NoLinkFound = styled.div`
  text-align: center;
  font-style: italic;
  color: var(--gray);
  font-size: ${FONT_SIZE.md};
`;

const InfoWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  & .label {
    width: 110px;
    font-weight: 600;
    color: #444;
  }
  & .value {
  }
`;

const GroupWrapper = styled.div`
  margin-top: 10px;
`;
