import { Row } from "antd";
import { BaseTypography } from "components/common/BaseTypography";
import { styled } from "styled-components";

export const OperatorText = styled(BaseTypography)`
  text-transform: capitalize;
`;
export const CityText = styled(BaseTypography)`
  text-transform: capitalize;
`;
export const RowWrapper = styled(Row)`
  margin: 1rem 0rem;
`;
