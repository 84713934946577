export default [
  {
    title: "1. Acceptarea Termenilor:",
    desc: [
      {
        title: "1.1",
        desc: "Prin utilizarea proiectului nostru de conformitate cu camerele, recunoașteți că ați citit, înțeles și sunteți de acord cu acești Termeni și Condiții.",
      },
    ],
  },
  {
    title: "2. Politica de Confidențialitate:",
    desc: [
      {
        title: "2.1",
        desc: "Utilizarea serviciilor noastre este guvernată și de Politica noastră de Confidențialitate. Prin utilizarea serviciilor noastre, vă dați consimțământul pentru colectarea, utilizarea și partajarea informațiilor dvs. personale conform descrierii din Politica de Confidențialitate.",
      },
    ],
  },

  {
    title: "3. Utilizarea Serviciilor:",
    desc: [
      {
        title: "3.1",
        desc: "Utilizarea serviciilor noastre este guvernată și de Politica noastră de Confidențialitate. Prin utilizarea serviciilor noastre, vă dați consimțământul pentru colectarea, utilizarea și partajarea informațiilor dvs. personale conform descrierii din Politica de Confidențialitate.",
      },
      {
        title: "3.2",
        desc: "Trebuie să respectați toate legile și reglementările aplicabile în timpul utilizării serviciilor noastre.",
      },
    ],
  },

  {
    title: "4. Proprietate Intelectuală:",
    desc: [
      {
        title: "4.1",
        desc: "Toate conținuturile, materialele și proprietatea intelectuală asociate proiectului nostru de conformitate cu camerele ne aparțin nouă sau licențiatorilor noștri. Nu aveți voie să reproduceți, distribuiți, modificați sau creați lucrări derivate pe baza conținutului nostru fără permisiunea noastră explicită.",
      },
    ],
  },

  {
    title: "5. Conduită a Utilizatorului:",
    desc: [
      {
        title: "5.1",
        desc: "Sunteți responsabil pentru exactitatea și legalitatea informațiilor pe care le furnizați în timpul utilizării serviciilor noastre.",
      },
      {
        title: "5.2",
        desc: "Nu trebuie să vă angajați în nicio conduită care ar putea perturba sau afecta serviciile noastre, alți utilizatori sau reputația noastră.",
      },
    ],
  },

  {
    title: "6. Limitarea Răspunderii:",
    desc: [
      {
        title: "6.1",
        desc: "Proiectul nostru de conformitate cu camerele este furnizat „așa cum este” și „după cum este disponibil”. Nu garantăm exactitatea, fiabilitatea sau disponibilitatea serviciilor.",
      },
      {
        title: "6.2",
        desc: "Nu suntem răspunzători pentru niciun fel de daune, pierderi sau răspunderi rezultate din utilizarea serviciilor noastre sau a oricăror informații obținute prin intermediul serviciilor noastre. Nu garantăm exactitatea, fiabilitatea sau disponibilitatea serviciilor.",
      },
    ],
  },

  {
    title: "7. Despăgubire:",
    desc: [
      {
        title: "7.1",
        desc: "Sunteți de acord să ne despăgubiți și să ne eliberați de orice cereri, pierderi, răspunderi și cheltuieli (inclusiv taxe legale) rezultate din utilizarea serviciilor noastre sau din încălcarea acestor Termeni și Condiții.",
      },
    ],
  },
  {
    title: "8. Modificări și Încheiere:",
    desc: [
      {
        title: "8.1",
        desc: "Ne rezervăm dreptul de a modifica, suspenda sau încheia proiectul nostru de conformitate cu camerele în orice moment, fără notificare prealabilă.",
      },
      {
        title: "8.2",
        desc: "Putem, de asemenea, să modificăm acești Termeni și Condiții din când în când. Utilizarea continuă a serviciilor noastre după astfel de modificări constituie acceptarea termenilor actualizați.",
      },
    ],
  },
  {
    title: "9. Legea Aplicabilă:",
    desc: [
      {
        title: "9.1",
        desc: "Acești Termeni și Condiții sunt guvernați și interpretați în conformitate cu legile din [Jurisdicție]. Orice litigii rezultate din sau în legătură cu acești termeni vor fi supuse jurisdicției exclusive a tribunalelor din [Jurisdicție].",
      },
    ],
  },
  {
    title: "10. Contactați-ne:",
    desc: [
      {
        desc: "Dacă aveți întrebări, preocupări sau întrebări cu privire la acești Termeni și Condiții sau utilizarea serviciilor noastre, vă rugăm să ne contactați la adresa [adresa de contact]. Prin utilizarea proiectului nostru de conformitate cu camerele, sunteți de acord să fiți obligat de acești Termeni și Condiții. Dacă nu sunteți de acord cu nicio parte a acestor termeni, vă rugăm să nu utilizați serviciile noastre.",
      },
    ],
  },
];
