import * as Styles from "./index.styles";
import { BaseTag } from "components/common/BaseTag";
import BaseButton from "components/common/BaseButton";
import { FaEdit, FaEye } from "react-icons/fa";
import { TbLock, TbLockOpen } from "react-icons/tb";

interface CategoriesTableRowCardProps {
  name: string;
  createdDate: string;
  isArchive: boolean;
  onViewClick: (e: React.MouseEvent<HTMLElement>) => void;
  onLockClick: (e: React.MouseEvent<HTMLElement>) => void;
  onEditClick: (e: React.MouseEvent<HTMLElement>) => void;
}

const CategoriesTableRowCard = ({
  name,
  createdDate,
  isArchive,
  onViewClick,
  onLockClick,
  onEditClick,
}: CategoriesTableRowCardProps) => {
  return (
    <Styles.CardWrapper>
      <div>
        <div style={{ fontSize: "16px", fontWeight: "600" }}>{name}</div>
        <div
          style={{ fontSize: "12px", fontWeight: "400", marginBottom: "6px" }}
        >
          Created on {createdDate}
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <BaseTag color={isArchive ? "warning" : "success"}>
            {isArchive ? "Blocked" : "Active"}
          </BaseTag>
          <BaseButton
            onClick={onViewClick}
            type="link"
            style={{ marginLeft: " 10px", padding: "0px", height: "20px" }}
          >
            <FaEye
              style={{ height: "20px", width: "20px", color: "var(--primary)" }}
            />
          </BaseButton>
          <BaseButton
            onClick={onLockClick}
            style={{ marginLeft: " 10px", padding: "0px", height: "20px" }}
            type="link"
          >
            {isArchive ? (
              <TbLockOpen
                style={{
                  height: "20px",
                  width: "20px",
                  color: "var(--tairo)",
                }}
              />
            ) : (
              <TbLock
                style={{
                  height: "20px",
                  width: "20px",
                  color: "var(--orange)",
                }}
              />
            )}
          </BaseButton>
          {!isArchive && (
            <BaseButton
              onClick={onEditClick}
              style={{ marginLeft: " 10px", padding: "0px", height: "20px" }}
              type="link"
            >
              <FaEdit
                style={{
                  height: "20px",
                  width: "20px",
                  color: "var(--skyblue)",
                }}
              />
            </BaseButton>
          )}
        </div>
      </div>
    </Styles.CardWrapper>
  );
};

export default CategoriesTableRowCard;
