export default [
  {
    title: "1. Aceptación de Términos:",
    desc: [
      {
        title: "1.1",
        desc: "Al utilizar nuestro proyecto de cumplimiento de cámaras, reconoces que has leído, comprendido y aceptado estos Términos y Condiciones.",
      },
    ],
  },
  {
    title: "2. Política de Privacidad:",
    desc: [
      {
        title: "2.1",
        desc: "Tu uso de nuestros servicios también está regido por nuestra Política de Privacidad. Al utilizar nuestros servicios, das tu consentimiento para la recopilación, uso y compartición de tu información personal según se describe en la Política de Privacidad.",
      },
    ],
  },

  {
    title: "3. Uso de los Servicios:",
    desc: [
      {
        title: "3.1",
        desc: "Tu uso de nuestros servicios también está regido por nuestra Política de Privacidad. Al utilizar nuestros servicios, das tu consentimiento para la recopilación, uso y compartición de tu información personal según se describe en la Política de Privacidad.",
      },
      {
        title: "3.2",
        desc: "Debes cumplir con todas las leyes y regulaciones aplicables al utilizar nuestros servicios.",
      },
    ],
  },

  {
    title: "4. Propiedad Intelectual:",
    desc: [
      {
        title: "4.1",
        desc: "Todo el contenido, materiales y propiedad intelectual asociados a nuestro proyecto de cumplimiento de cámaras nos pertenecen a nosotros o a nuestros licenciantes. No puedes reproducir, distribuir, modificar ni crear trabajos derivados basados en nuestro contenido sin nuestro permiso explícito.",
      },
    ],
  },

  {
    title: "5. Conducta del Usuario:",
    desc: [
      {
        title: "5.1",
        desc: "Eres responsable de la precisión y legalidad de la información que proporcionas al utilizar nuestros servicios.",
      },
      {
        title: "5.2",
        desc: "No debes participar en ninguna conducta que pueda interrumpir o dañar nuestros servicios, otros usuarios o nuestra reputación.",
      },
    ],
  },

  {
    title: "6. Limitación de Responsabilidad:",
    desc: [
      {
        title: "6.1",
        desc: "Nuestro proyecto de cumplimiento de cámaras se proporciona 'tal cual' y 'según disponibilidad'. No garantizamos la precisión, confiabilidad o disponibilidad de los servicios.",
      },
      {
        title: "6.2",
        desc: "No somos responsables de ningún daño, pérdida o responsabilidad que surja de tu uso de nuestros servicios o de cualquier información obtenida a través de nuestros servicios. No garantizamos la precisión, confiabilidad o disponibilidad de los servicios.",
      },
    ],
  },

  {
    title: "7. Indemnización:",
    desc: [
      {
        title: "7.1",
        desc: "Aceptas indemnizarnos y mantenernos indemnes de cualquier reclamo, pérdida, responsabilidad y gasto (incluidos honorarios legales) que surja de tu uso de nuestros servicios o de tu violación de estos Términos y Condiciones.",
      },
    ],
  },
  {
    title: "8. Modificaciones y Terminación:",
    desc: [
      {
        title: "8.1",
        desc: "Nos reservamos el derecho de modificar, suspender o terminar nuestro proyecto de cumplimiento de cámaras en cualquier momento sin previo aviso.",
      },
      {
        title: "8.2",
        desc: "También podemos modificar estos Términos y Condiciones de vez en cuando. Tu uso continuado de nuestros servicios después de tales modificaciones constituye tu aceptación de los términos actualizados.",
      },
    ],
  },
  {
    title: "9. Ley Aplicable:",
    desc: [
      {
        title: "9.1",
        desc: "Estos Términos y Condiciones están regidos y se interpretan de acuerdo con las leyes de [Jurisdicción]. Cualquier disputa que surja de o en relación con estos términos estará sujeta a la jurisdicción exclusiva de los tribunales en [Jurisdicción].",
      },
    ],
  },
  {
    title: "10. Contáctanos:",
    desc: [
      {
        desc: "Si tienes preguntas, inquietudes o consultas sobre estos Términos y Condiciones o el uso de nuestros servicios, contáctanos en [correo de contacto]. Al utilizar nuestro proyecto de cumplimiento de cámaras, aceptas estar sujeto a estos Términos y Condiciones. Si no estás de acuerdo con alguna parte de estos términos, por favor, no uses nuestros servicios.",
      },
    ],
  },
];
