import { createStore } from "redux";
import reducer from "./reducer";
import * as Actions from "./actions";
import Select from "./selectors";
import { composeWithDevTools } from "redux-devtools-extension";
import initialState from "./initialState";

const store = createStore(reducer, initialState, composeWithDevTools());

export default { store, Actions, Select };
