import { Col, Divider, Modal, Row } from "antd";
import Screen from "components/Layout/Screen";
import React, { useEffect, useState } from "react";
import * as S from "./index.styles";
import Location from "containers/City/SingleCity/Location";
import useAPI from "hooks/useApi";
import { useParams, useLocation } from "react-router-dom";
import BaseButton from "components/common/BaseButton";
import { BaseTypography } from "components/common/BaseTypography";
import BaseConfirmation from "components/common/BaseConfirmation";
import { acceptQr, rejectQr } from "constants/api/qr";

import DUMMY_DATA from "./dummyData";
import { toast } from "react-toastify";
import { BaseCard } from "components/common/BaseCard";
import CheckCircleOutlined from "@ant-design/icons/CheckCircleOutlined";
import CloseCircleOutlined from "@ant-design/icons/CloseCircleOutlined";
import BaseLoading from "components/common/BaseSpin";

const QRDetails = () => {
  const { id } = useParams();
  const { pathname } = useLocation();

  const isPrivate = pathname.includes("dashboard/updated-qr");
  const [makeRequest, { data: QrDetailsData, loading: QrDetailsLoading }] =
    useAPI();
  const [acceptRequest, { loading: acceptLoading }] = useAPI();
  const [rejectRequest, { loading: rejectLoading }] = useAPI();
  const [isAcceptModal, setAcceptModal] = useState(false);
  const [isRejectModal, setRejectModal] = useState(false);
  const getCityData = async (id: any) => {
    try {
      await makeRequest({
        method: "get",
        url: `QR/getUpdateQRCodesById/${id}`,
      });
    } catch (error) {}
  };

  useEffect(() => {
    getCityData(id);
  }, []);
  const cityData = QrDetailsData?.city;
  const status = QrDetailsData?.status;
  //*******************modal**************************
  const closeAcceptModal = () => {
    setAcceptModal(false);
  };

  const closeRejectModal = () => {
    setRejectModal(false);
  };

  const handleAcceptQr = (id: any) => {
    acceptRequest({
      method: "post",
      url: acceptQr(id),
    });
  };

  const handleRejectQr = (id: any) => {
    rejectRequest({
      method: "post",
      url: rejectQr(id),
    });
  };

  const statusMessage = [
    status === "Accepted" && {
      message: "Accepted QR",
      icon: <CheckCircleOutlined />,
    },
    status === "Rejected" && {
      message: "Rejected QR",
      icon: <CloseCircleOutlined />,
    },
  ];
  return (
    <>
      {QrDetailsLoading ? (
        <BaseLoading></BaseLoading>
      ) : (
        <>
          <S.QRRequestedStyled>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                marginBottom: "10px",
              }}
            >
              <img
                style={{ objectFit: "contain" }}
                alt={QrDetailsData?.city?.bannerImage}
                src={QrDetailsData?.city?.bannerImage}
                width={"100%"}
                height={"400px"}
              />
            </div>

            <Divider />
            {isPrivate && status === "Requested" && (
              <S.AcceptReject>
                <S.ButtonWrapper>
                  <BaseButton
                    type={"primary"}
                    onClick={() => {
                      setAcceptModal(true);
                    }}
                  >
                    Accept
                  </BaseButton>
                  <BaseButton
                    type={"primary"}
                    danger
                    onClick={() => {
                      setRejectModal(true);
                    }}
                  >
                    Reject
                  </BaseButton>
                </S.ButtonWrapper>
              </S.AcceptReject>
            )}

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                <S.Header>
                  <S.Author>
                    {QrDetailsData?.city?.title} City GDPR compliance.
                  </S.Author>
                </S.Header>
                <S.Description>{DUMMY_DATA.data.city?.desc}</S.Description>
              </div>
              <BaseCard>
                {statusMessage.map((item) => {
                  return (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <S.IconStyle status={status}>{item.icon}</S.IconStyle>
                      <S.StatusMessage
                        status={status}
                        style={{ marginLeft: "8px" }}
                      >
                        {item.message}
                      </S.StatusMessage>
                    </div>
                  );
                })}
                {/* <S.Description>
              {status === "Accepted"
                ? "this is accepted"
                : status === "Rejected"
                ? "this is rejected"
                : ""}
            </S.Description> */}
              </BaseCard>
            </div>

            {!isPrivate && (
              <S.Description>
                The city uses stickers placed at different location to provide
                city information. The location of the stickers placed nearest to
                you is shown by the map. The city does takes complete
                responsibility on any information provided in respective
                Stickers.
              </S.Description>
            )}
            <Row align="top" justify="space-between">
              <Col
                xs={{ span: 24 }}
                md={{ span: 12 }}
                style={{ paddingRight: "15px" }}
              >
                {isPrivate && (
                  <S.InfoContainerStyled>
                    <S.InfoHeader>Operator</S.InfoHeader>
                    <Divider style={{ margin: "5px 0px 10px" }} />
                    <S.InfoWrapperStyled>
                      <div className="label">Name</div>
                      <div className="value">
                        :
                        {` ${QrDetailsData?.operator?.firstName} ${QrDetailsData?.operator?.lastName}`}
                      </div>
                    </S.InfoWrapperStyled>

                    <S.InfoWrapperStyled>
                      <div className="label">Email</div>
                      <div className="value">
                        : {QrDetailsData?.operator?.email}
                      </div>
                    </S.InfoWrapperStyled>
                    <S.InfoWrapperStyled>
                      <div className="label">Verified</div>
                      <div className="value">
                        : {QrDetailsData?.status === "Requested" ? "No" : "Yes"}
                      </div>
                    </S.InfoWrapperStyled>
                    <S.InfoWrapperStyled>
                      <div className="label">Blocked</div>
                      <div className="value">
                        : {QrDetailsData?.status === "Rejected" ? "Yes" : "No"}
                      </div>
                    </S.InfoWrapperStyled>
                  </S.InfoContainerStyled>
                )}
                <S.InfoContainerStyled>
                  <S.InfoHeader>Location</S.InfoHeader>
                  <Divider style={{ margin: "5px 0px 10px" }} />
                  <S.InfoWrapperStyled>
                    <div className="label">Address</div>
                    <div className="value">: {QrDetailsData?.address}</div>
                  </S.InfoWrapperStyled>
                  <S.InfoWrapperStyled>
                    <div className="label">Longitude</div>
                    <div className="value">: {QrDetailsData?.longitude}</div>
                  </S.InfoWrapperStyled>
                  <S.InfoWrapperStyled>
                    <div className="label">Latitude</div>
                    <div className="value">: {QrDetailsData?.latitude}</div>
                  </S.InfoWrapperStyled>
                </S.InfoContainerStyled>
                <S.InfoContainerStyled>
                  <S.InfoHeader>QR</S.InfoHeader>
                  <Divider style={{ margin: "5px 0px 10px" }} />
                  <S.InfoWrapperStyled>
                    <div className="label">Id</div>
                    <div className="value">: {DUMMY_DATA.data.QRCode._id}</div>
                  </S.InfoWrapperStyled>
                  <S.InfoWrapperStyled>
                    <img
                      style={{ objectFit: "contain" }}
                      alt={DUMMY_DATA.data?.QRCode?.URL}
                      src={DUMMY_DATA.data?.QRCode?.URL}
                      // src={"https://picsum.photos/1080/720"}
                      height={"100px"}
                    />
                  </S.InfoWrapperStyled>
                </S.InfoContainerStyled>
                {/* <S.InfoContainerStyled>
              <S.InfoHeader>Sticker In Use</S.InfoHeader>
              <Divider style={{ margin: "5px 0px 10px" }} />
              <img
                style={{ objectFit: "contain" }}
                alt={DUMMY_DATA.data?.QRCode?.sticker}
                // src={DUMMY_DATA.data?.QRCode?.sticker}
                src={"https://picsum.photos/1080/720"}
                width={"100%"}
                height={"400px"}
              />
            </S.InfoContainerStyled> */}
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Location
                  lat={DUMMY_DATA.data.latitude}
                  long={DUMMY_DATA.data.longitude}
                />
              </Col>
            </Row>
          </S.QRRequestedStyled>

          <BaseConfirmation
            title={"Do you want to accept the QR ?"}
            open={isAcceptModal}
            onCancel={closeAcceptModal}
            onOk={async () => {
              await handleAcceptQr(id);
              await setAcceptModal(false);
              toast.success("QR code is successfully accepted");
              getCityData(id);
            }}
            confirmLoading={acceptLoading}
          ></BaseConfirmation>
          <BaseConfirmation
            title={"Do you want to Reject the QR ?"}
            open={isRejectModal}
            onCancel={closeRejectModal}
            onOk={async () => {
              await handleRejectQr(id);
              await setRejectModal(false);
              toast.error("QR code is successfully rejected");
              getCityData(id);
            }}
            confirmLoading={rejectLoading}
          ></BaseConfirmation>
        </>
      )}
    </>
  );
};

export default QRDetails;
