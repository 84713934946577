import { Modal } from "antd";
import React from "react";

const BaseConfirmation = ({ children, ...props }: any) => {
  console.log("the icons", props.icon);
  return (
    <>
      <Modal {...props}>{children}</Modal>
    </>
  );
};

export default BaseConfirmation;
