import { COUNTRIES } from "./countries";
import { SelectMenuOption } from "./types";
import { Input, Typography } from "antd";
import { AnimatePresence, motion } from "framer-motion";
import React, { MutableRefObject, useEffect, useRef, useState } from "react";

export interface CountrySelectorProps {
  id: string;
  open: boolean;
  disabled?: boolean;
  onToggle: () => void;
  onChange: (value: SelectMenuOption["value"]) => void;
  selectedValue: SelectMenuOption;
}

export default function CountrySelector({
  id,
  open,
  disabled = false,
  onToggle,
  onChange,
  selectedValue,
}: CountrySelectorProps) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const mutableRef = ref as MutableRefObject<HTMLDivElement | null>;

    const handleClickOutside = (event) => {
      if (
        mutableRef.current &&
        !mutableRef.current.contains(event.target) &&
        open
      ) {
        onToggle();
        setQuery("");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  const [query, setQuery] = useState("");

  return (
    <div ref={ref}>
      <div className="mt-1 relative">
        <button type="button" onClick={onToggle} disabled={disabled}>
          <span className="truncate flex items-center">
            <div style={{ display: "flex" }}>
              <img
                alt={`${selectedValue.value}`}
                src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${selectedValue.value}.svg`}
                style={{ width: "100%", height: "10px" }}
              />
              {selectedValue.title}
            </div>
          </span>
        </button>

        {open && (
          <>
            <div className="sticky top-0 z-10 bg-white">
              <li className=" text-gray-900 cursor-default select-none relative py-2 px-3">
                <Input
                  type="search"
                  name="search"
                  autoComplete={"off"}
                  className="focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  placeholder={"Search a country"}
                  onChange={(e) => setQuery(e.target.value)}
                />
              </li>
              <hr />
            </div>

            <div
              className={
                "max-h-64 scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300 hover:scrollbar-thumb-gray-600 scrollbar-thumb-rounded scrollbar-thin overflow-y-scroll"
              }
            >
              {COUNTRIES.filter((country) =>
                country.title.toLowerCase().startsWith(query.toLowerCase())
              ).length === 0 ? (
                <li className="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9">
                  No countries found
                </li>
              ) : (
                COUNTRIES.filter((country) =>
                  country.title.toLowerCase().startsWith(query.toLowerCase())
                ).map((value, index) => {
                  return (
                    <li
                      key={`${id}-${index}`}
                      className="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9 flex items-center hover:bg-gray-50 transition"
                      id="listbox-option-0"
                      role="option"
                      onClick={() => {
                        onChange(value.value);
                        setQuery("");
                        onToggle();
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <img
                          alt={`${value.value}`}
                          src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${value.value}.svg`}
                          className={"inline mr-2 h-4 rounded-sm"}
                          style={{ width: "50px", height: "50px" }}
                        />

                        <div style={{ marginTop: "15px", padding: "0px 10px" }}>
                          <Typography>{value.title}</Typography>
                        </div>
                      </div>
                    </li>
                  );
                })
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
