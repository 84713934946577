import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import React, { useState } from "react";
import { BaseTypography } from "../BaseTypography";
import * as S from "./index.styles";
import { useField } from "formik";
import BaseError from "../BaseError";

const BasePhoneInput = ({
  label,
  name,
  handleChange,

  ...props
}: {
  label: string;
  name: string;

  handleChange?: (val: string, country: string, formatted: string) => void;
}) => {
  const [field, meta, helpers] = useField(name);
  const { touched, error } = meta;
  const { setValue } = helpers;

  return (
    <S.PhoneWrapper>
      <BaseTypography>{label}</BaseTypography>
      <PhoneInput
        country={"fi"}
        {...props}
        {...field}
        enableSearch={true}
        onChange={(value, country, e, formattedValue) => {
          if (handleChange) {
            //@ts-ignore
            handleChange?.(value, country?.dialCode, formattedValue);
          } else {
            setValue(formattedValue);
          }
        }}
        inputStyle={{ width: "100%" }}
      />
      {touched && error && <BaseError>{error}</BaseError>}
    </S.PhoneWrapper>
  );
};

export default BasePhoneInput;
