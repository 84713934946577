export default [
  {
    title: "1. Annahme der Bedingungen:",
    desc: [
      {
        title: "1.1",
        desc: "Durch die Nutzung unseres Projekts zur Kamerakonformität erkennen Sie an, dass Sie diese Geschäftsbedingungen gelesen, verstanden und akzeptiert haben.",
      },
    ],
  },
  {
    title: "2. Datenschutzrichtlinie:",
    desc: [
      {
        title: "2.1",
        desc: "Ihre Nutzung unserer Dienste unterliegt ebenfalls unserer Datenschutzrichtlinie. Durch die Nutzung unserer Dienste stimmen Sie der Erfassung, Nutzung und Weitergabe Ihrer persönlichen Informationen gemäß der Datenschutzrichtlinie zu.",
      },
    ],
  },

  {
    title: "3. Nutzung der Dienste:",
    desc: [
      {
        title: "3.1",
        desc: "Ihre Nutzung unserer Dienste unterliegt ebenfalls unserer Datenschutzrichtlinie. Durch die Nutzung unserer Dienste stimmen Sie der Erfassung, Nutzung und Weitergabe Ihrer persönlichen Informationen gemäß der Datenschutzrichtlinie zu.",
      },
      {
        title: "3.2",
        desc: "Sie müssen alle geltenden Gesetze und Vorschriften bei der Nutzung unserer Dienste einhalten.",
      },
    ],
  },

  {
    title: "4. Geistiges Eigentum:",
    desc: [
      {
        title: "4.1",
        desc: "Alle Inhalte, Materialien und geistigen Eigentumsrechte im Zusammenhang mit unserem Projekt zur Kamerakonformität gehören uns oder unseren Lizenzgebern. Sie dürfen unsere Inhalte nicht ohne unsere ausdrückliche Genehmigung reproduzieren, verteilen, modifizieren oder abgeleitete Werke erstellen.",
      },
    ],
  },

  {
    title: "5. Nutzerverhalten:",
    desc: [
      {
        title: "5.1",
        desc: "Sie sind für die Genauigkeit und Rechtmäßigkeit der von Ihnen während der Nutzung unserer Dienste bereitgestellten Informationen verantwortlich.",
      },
      {
        title: "5.2",
        desc: "Sie dürfen keine Handlungen ausführen, die unsere Dienste, andere Benutzer oder unseren Ruf stören oder schädigen könnten.",
      },
    ],
  },

  {
    title: "6. Haftungsbeschränkung:",
    desc: [
      {
        title: "6.1",
        desc: "Unser Projekt zur Kamerakonformität wird 'wie besehen' und 'wie verfügbar' bereitgestellt. Wir garantieren nicht für die Genauigkeit, Zuverlässigkeit oder Verfügbarkeit der Dienste.",
      },
      {
        title: "6.2",
        desc: "Wir haften nicht für Schäden, Verluste oder Haftungsansprüche, die sich aus Ihrer Nutzung unserer Dienste oder aus Informationen ergeben, die Sie über unsere Dienste erhalten. Wir garantieren nicht für die Genauigkeit, Zuverlässigkeit oder Verfügbarkeit der Dienste.",
      },
    ],
  },

  {
    title: "7. Entschädigung:",
    desc: [
      {
        title: "7.1",
        desc: "Sie erklären sich damit einverstanden, uns von jeglichen Ansprüchen, Verlusten, Haftungen und Ausgaben (einschließlich Anwaltsgebühren) freizustellen, die sich aus Ihrer Nutzung unserer Dienste oder Ihrer Verletzung dieser Geschäftsbedingungen ergeben.",
      },
    ],
  },
  {
    title: "8. Änderungen und Beendigung:",
    desc: [
      {
        title: "8.1",
        desc: "Wir behalten uns das Recht vor, unser Projekt zur Kamerakonformität jederzeit ohne vorherige Ankündigung zu ändern, auszusetzen oder zu beenden.",
      },
      {
        title: "8.2",
        desc: "Wir können auch diese Geschäftsbedingungen von Zeit zu Zeit ändern. Ihre fortgesetzte Nutzung unserer Dienste nach solchen Änderungen stellt Ihre Zustimmung zu den aktualisierten Bedingungen dar.",
      },
    ],
  },
  {
    title: "9. Anwendbares Recht:",
    desc: [
      {
        title: "9.1",
        desc: "Diese Geschäftsbedingungen unterliegen den Gesetzen von [Gerichtsstand] und werden gemäß diesen Gesetzen ausgelegt. Alle Streitigkeiten, die sich aus oder im Zusammenhang mit diesen Bedingungen ergeben, unterliegen der ausschließlichen Gerichtsbarkeit der Gerichte in [Gerichtsstand].",
      },
    ],
  },
  {
    title: "10. Kontaktieren Sie uns:",
    desc: [
      {
        desc: "Wenn Sie Fragen, Bedenken oder Anfragen zu diesen Geschäftsbedingungen oder zur Nutzung unserer Dienste haben, kontaktieren Sie uns bitte unter [Kontakt-E-Mail]. Durch die Nutzung unseres Projekts zur Kamerakonformität erklären Sie sich mit diesen Geschäftsbedingungen einverstanden. Wenn Sie mit einem Teil dieser Bedingungen nicht einverstanden sind, verwenden Sie bitte unsere Dienste nicht.",
      },
    ],
  },
];
