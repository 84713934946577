import { styled } from "styled-components";
import { FONT_FAMILY } from "styles/themes/constants";
import { devices } from "utils/theme";

export const FeaturesPageWrapper = styled.div`
  font-family: ${FONT_FAMILY.main};
  /* background-color: #111222; */
`;

export const Section = styled.div`
  background-color: "#fff";
  /* background-color: #111222; */

  /* color: white; */
`;

export const Container = styled.div`
  margin: 20px auto 0;

  @media (${devices.tablet}) {
    margin-top: 40px;
  }

  & .page-heading,
  & .role-heading {
    text-align: center;
    color: var(--primary);
  }

  & .role-heading {
    padding-top: 40px;
    border-bottom: 1px solid #16798b72;

    @media (${devices.tablet}) {
      padding-top: 20px;
    }
  }
`;
