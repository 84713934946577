import React from "react";
import * as Styles from "./index.styles";

interface TopCitiesTableRowCardProps {
  logo: string;
  title: string;
  acceptedQr: string;
}

const TopCitiesTableRowCard = ({
  logo,
  title,
  acceptedQr,
}: TopCitiesTableRowCardProps) => {
  return (
    <Styles.CardWrapper>
      <Styles.Logo src={logo} />
      <div>
        <div style={{ fontSize: "16px", fontWeight: "600" }}>{title}</div>
        <div style={{ fontSize: "12px", fontWeight: "400" }}>
          Accepted QR: {acceptedQr}
        </div>
      </div>
    </Styles.CardWrapper>
  );
};

export default TopCitiesTableRowCard;
