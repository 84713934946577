// ****************** ADDCITY **********************
export const addCity = `common/addCity`;

// ****************** GETALLCITY **********************
export const allCity = `common/getAllCity`;

// ****************** GETALLCITIES DROPDOWN **********************
export const allCitiesDropDown = `common/getAllCities`;

// ****************** GETSINGLECITY **********************
// export const getSingleCity = `common/getCity/${id}`;
