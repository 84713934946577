import React from "react";
import { Select, SelectProps } from "antd";
import { useField } from "formik";
import { BaseTypography } from "../BaseTypography";
import * as S from "./index.styles";
import BaseError from "../BaseError";

export const BaseSelects = ({ name, label, ...props }: any) => {
  const [field, meta, helpers] = useField(name);
  const { touched, error } = meta;
  const { setValue } = helpers;
  const handleChange = (value: string) => {
    setValue(value);
  };
  return (
    <S.SelectWrapper>
      {label && (
        <S.TypoWrapper>
          <BaseTypography>{label}:</BaseTypography>
        </S.TypoWrapper>
      )}

      <Select {...props} onChange={handleChange}></Select>
      {touched && error && <BaseError>{error}</BaseError>}
    </S.SelectWrapper>
  );
};

interface ISelectProps extends SelectProps {
  name?: string;
  label?: string;
}
