import { Col, Row } from "antd";
import { Form, Formik } from "formik";
import { useResponsive } from "hooks/useResponsive";
import qrcode from "qrcode";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Rnd } from "react-rnd";
import store from "store";
import styled from "styled-components";
import { parse } from "svg-parser";
import {
  TEST_QR_SVG,
  addBorderRadiusToSVG,
  convertSizesUnitToPx,
} from "utils/svgHelpers";
import { devices } from "utils/theme";
import { BaseTypography } from "../common/BaseTypography";
import ImageUpload from "../common/BaseUploaders/imageUpload";
import CalculateSize from "./CalculateSize";

export type CallbackDataType = {
  imageSize: {
    width: number;
    height: number;
    aspectRatio: number;
  };
  qrCodeSize: {
    width: number;
    height: number;
    x: number;
    y: number;
    aspectRatio: number;
  };
  qrOptions: {
    color: {
      light: string;
      dark: string;
    };
    margin: number;
    borderRadius: number;
  };
  selectedFilePreviewURL: string;
  selectedFile: File;
  selectedSVGFile: string;
  qrCodeSVG: string;
  reset: () => void;
};

type Props = {
  callbackForParent?: (data: CallbackDataType) => void;
  stickerEditData?: any;
  dataFromJSON?: any;
};

const style: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "red",
  background: `linear-gradient(to right, #808080 4px, transparent 4px) 0 0,
      linear-gradient(to right, #808080 4px, transparent 4px) 0 100%,
      linear-gradient(to left, #808080 4px, transparent 4px) 100% 0,
      linear-gradient(to left, #808080 4px, transparent 4px) 100% 100%,
      linear-gradient(to bottom, #808080 4px, transparent 4px) 0 0,
      linear-gradient(to bottom, #808080 4px, transparent 4px) 100% 0,
      linear-gradient(to top, #808080 4px, transparent 4px) 0 100%,
      linear-gradient(to top, #808080 4px, transparent 4px) 100% 100%`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "20px 20px",
  position: "absolute",
  top: "90px",
  border: "2px solid #000",
};

const INITIAL_QR_OPTIONS = {
  color: {
    light: "#FFFFFF",
    dark: "#000000",
  },
  margin: 1,
  borderRadius: 0,
};
const INITIAL_IMAGE_SIZE = {
  width: 400,
  height: 600,
  aspectRatio: 4 / 3,
};
const INITIAL_IMAGE_REF_SIZE = {
  width: 400,
  height: 600,
  aspectRatio: 4 / 3,
};
const INITIAL_QR_CODE_SIZE = {
  width: 300,
  height: 300,
  x: 10,
  y: 10,
  aspectRatio: 1 / 1,
};

const SelectSticker = ({
  stickerEditData,
  callbackForParent,
  dataFromJSON,
}: Props) => {
  // HOOKS
  const { t } = useTranslation();
  const { isTablet } = useResponsive();
  const dispatch = useDispatch();
  const stickerSelectionFromStore = useSelector(
    (state: any) => state?.stickerSelection
  );

  // STATE
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [selectedSVGFile, setSelectedSVGFile] = useState<any>(null);
  const [qrCodeSVG, setQRCodeSVG] = useState(TEST_QR_SVG);

  const [previewUrl, setPreviewUrl] = useState<any>();
  const [qrOptions, setQROptions] = useState(INITIAL_QR_OPTIONS);
  const [imageSize, setImageSize] = useState<any>(INITIAL_IMAGE_SIZE);
  const [imageRefSize, setImageRefSize] = useState(INITIAL_IMAGE_REF_SIZE);
  const [qrCodeSize, setQrCodeSize] = useState(INITIAL_QR_CODE_SIZE);

  // REF
  const bannerImageRef = useRef<HTMLImageElement>();

  useEffect(() => {
    if (stickerSelectionFromStore.status === "Active") {
      const {
        selectedFile: _selectedFile,
        qrCodeSVG: _qrCodeSVG,
        previewURL: _previewURL,
        qrOptions: _qrOptions,
        imageSize: _imageSize,
        qrCodeSize: _qrCodeSize,
        imageRefSize: _imageRefSize,
      } = stickerSelectionFromStore;
      setSelectedFile(_selectedFile);
      setQRCodeSVG(_qrCodeSVG);
      setPreviewUrl(_previewURL);
      setQROptions(_qrOptions);
      setImageSize(_imageSize);
      setQrCodeSize(_qrCodeSize);
      setImageRefSize(_imageRefSize);
    }
  }, []);

  useEffect(() => {
    if (!!callbackForParent) {
      const dataToParent = {
        imageSize,
        qrCodeSize,
        qrOptions,
        selectedFilePreviewURL: previewUrl,
        selectedFile,
        qrCodeSVG,
        selectedSVGFile,
        reset: resetAllData,
      };

      callbackForParent(dataToParent);
    }
    dispatch(
      store.Actions.set("stickerSelection", {
        status: "Active",
        selectedFile: selectedFile,
        qrCodeSVG: qrCodeSVG,
        previewURL: previewUrl,
        imageRefSize: { ...imageRefSize },
        qrOptions: {
          ...qrOptions,
        },
        imageSize: {
          ...imageSize,
        },
        qrCodeSize: {
          ...qrCodeSize,
        },
      })
    );
  }, [imageSize, qrCodeSize, qrOptions, selectedFile, previewUrl]);

  useEffect(() => {
    if (stickerEditData?.id) {
      setPreviewUrl(stickerEditData?.URL);
      setImageSize({
        width: stickerEditData?.imageSize?.width,
        height: stickerEditData?.imageSize?.height,
        aspectRatio: stickerEditData?.imageSize?.aspectRatio,
      });
      setQrCodeSize((prev) => ({
        ...prev,
        height: stickerEditData?.qrCodeSize?.height,
        width: stickerEditData?.qrCodeSize?.width,
        x: stickerEditData?.qrCodeSize?.x,
        y: stickerEditData?.qrCodeSize?.y,
      }));
      setQROptions((prev) => ({
        ...prev,
        borderRadius: stickerEditData?.qrOptions?.borderRadius || 0,
        color: {
          dark: stickerEditData?.qrOptions?.color?.dark || "#000",
          light: stickerEditData?.qrOptions?.color?.light || "#fff",
        },
        margin: stickerEditData?.qrOptions?.margin || 1,
      }));
    }
  }, [stickerEditData]);

  useEffect(() => {
    if (!!dataFromJSON?.stickerSVG) {
      setSelectedSVGFile(dataFromJSON?.stickerSVG);
      setPreviewUrl(dataFromJSON?.URL);
      setImageSize({
        width: dataFromJSON?.imageSize?.width,
        height: dataFromJSON?.imageSize?.height,
        aspectRatio: dataFromJSON?.imageSize?.aspectRatio,
      });
      setQrCodeSize((prev) => ({
        ...prev,
        height: dataFromJSON?.qrCodeSize?.height,
        width: dataFromJSON?.qrCodeSize?.width,
        x: dataFromJSON?.qrCodeSize?.x,
        y: dataFromJSON?.qrCodeSize?.y,
      }));
      setQROptions((prev) => ({
        ...prev,
        borderRadius: dataFromJSON?.qrOptions?.borderRadius || 0,
        color: {
          dark: dataFromJSON?.qrOptions?.color?.dark || "#000",
          light: dataFromJSON?.qrOptions?.color?.light || "#fff",
        },
        margin: dataFromJSON?.qrOptions?.margin || 1,
      }));
    }
  }, [dataFromJSON]);

  useEffect(() => {
    if (!!previewUrl) return;

    if (selectedFile && !stickerEditData?.id) {
      const sFile = selectedFile;

      if (
        sFile.type === "image/svg+xml" ||
        sFile.name.split(".")[1] === "svg"
      ) {
        setSVGImageHeightWidth(sFile);
      } else {
        setImageHeightWidth(sFile);
      }
    }
  }, [selectedFile, previewUrl]);

  const refDimensionY = bannerImageRef?.current?.offsetHeight;
  const refDimensionX = bannerImageRef?.current?.offsetWidth;
  useEffect(() => {
    if (bannerImageRef?.current) {
      const ht = bannerImageRef?.current?.offsetHeight;
      const wt = bannerImageRef?.current?.offsetWidth;

      setImageRefSize({
        height: ht,
        width: wt,
        aspectRatio: wt / ht,
      });
    }
  }, [selectedFile, imageSize, selectedSVGFile, refDimensionX, refDimensionY]);

  const loadQRCode = async () => {
    const options = {
      errorCorrectionLevel: "M",
      type: "svg",
      ...qrOptions,
    };
    const data = await qrcode.toString(
      "I am a testing qr for camera compliance!",
      options
    );

    const svgString = addBorderRadiusToSVG(
      data,
      String(qrOptions.borderRadius),
      qrOptions.color.light
    );
    setQRCodeSVG(svgString);
  };

  useEffect(() => {
    loadQRCode();
  }, [qrOptions, dataFromJSON?.URL]);

  const setSVGImageHeightWidth = (sFile) => {
    const bigSVGReader = new FileReader();

    bigSVGReader.onload = function (event) {
      const svgString = event.target.result;
      const parsed = parse(svgString);

      if (
        parsed &&
        parsed?.children &&
        parsed?.children[0] &&
        parsed?.children[0]?.properties
      ) {
        let img = { height: null, width: null };

        img = {
          width: Math.round(
            convertSizesUnitToPx(parsed?.children[0]?.properties?.width)
          ),
          height: Math.round(
            convertSizesUnitToPx(parsed?.children[0]?.properties?.height)
          ),
        };
        if (!img.height || !img.width) {
          const viewBox = parsed?.children[0]?.properties?.viewBox;

          let splittedVB = viewBox.split(" ");
          if (splittedVB?.length === 1) {
            splittedVB = viewBox.split(",");
          }
          img.width = Math.round(convertSizesUnitToPx(splittedVB[2]));
          img.height = Math.round(convertSizesUnitToPx(splittedVB[3]));
        }
        const aspectRatio = img.width / img.height;

        let selectSmallestSize =
          img?.width < img?.height ? img?.width : img?.height;
        const smallestSide = img?.width < img?.height ? "width" : "height";

        const SMALLEST_ALLOWED_SIZE = 300;

        if (selectSmallestSize < SMALLEST_ALLOWED_SIZE) {
          selectSmallestSize = SMALLEST_ALLOWED_SIZE;
          if (smallestSide === "height") {
            img.height = SMALLEST_ALLOWED_SIZE;
            img.width = SMALLEST_ALLOWED_SIZE * aspectRatio;
          } else {
            img.width = SMALLEST_ALLOWED_SIZE;
            img.height = SMALLEST_ALLOWED_SIZE / aspectRatio;
          }
        }

        const sizes = {
          width: img.width,
          height: img.height,
          aspectRatio,
        };
        setQrCodeSize((prev) => ({
          ...prev,
          height: Math.round(0.25 * selectSmallestSize),
          width: Math.round(0.25 * selectSmallestSize),
          x: Math.round(0.5 * img.width - 0.125 * selectSmallestSize),
          y: Math.round(0.5 * img.height - 0.125 * selectSmallestSize),
        }));
        setImageSize({
          width: Math.ceil(sizes?.width),
          height: Math.ceil(sizes?.height),
          aspectRatio: sizes?.aspectRatio,
        });
      }
    };
    bigSVGReader.readAsText(sFile);
  };

  const setImageHeightWidth = (sFile) => {
    //@ts-ignore
    const img = new Image();

    img.onload = function () {
      const sizes = {
        width: img.width,
        height: img.height,
        aspectRatio: img.width / img.height,
      };
      setQrCodeSize((prev) => ({
        ...prev,
        height: Math.round(0.2 * img.width),
        width: Math.round(0.2 * img.width),
        x: Math.round(0.1 * img.width),
        y: Math.round(0.1 * img.width),
      }));
      setImageSize({ ...sizes });
      URL.revokeObjectURL(img.src);
    };
    const objectURL = URL.createObjectURL(sFile);
    img.src = objectURL;
  };

  const factor = {
    x: imageSize.width / imageRefSize.width,
    y: imageSize.height / imageRefSize.height,
  };

  const resetAllData = () => {
    setPreviewUrl(undefined);
    setSelectedFile(undefined);
    setSelectedSVGFile(undefined);
    setQROptions(INITIAL_QR_OPTIONS);
    setImageSize(INITIAL_IMAGE_SIZE);
    setImageRefSize(INITIAL_IMAGE_REF_SIZE);
    setQrCodeSize(INITIAL_QR_CODE_SIZE);
  };

  return (
    <SelectStickerStyled>
      {!stickerEditData?.id && !dataFromJSON?.URL && (
        <Formik initialValues={{ imageSelect: "" }} onSubmit={() => {}}>
          {({ values, errors, handleSubmit }) => {
            return (
              <Form>
                <Row>
                  <Col span={24}>
                    <ImageUpload
                      setSelectedFile={setSelectedFile}
                      setPreviewUrl={setPreviewUrl}
                      previewUrl={previewUrl}
                      name={"imageSelect"}
                      selectedFile={selectedFile}
                      allowed={["image/svg+xml"]}
                    />
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      )}
      {!!previewUrl && (
        <BaseTypography>{t("dashboard.drag-and-place")}</BaseTypography>
      )}
      <Col
        span={24}
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          flexDirection: isTablet ? "row" : "column-reverse",
        }}
      >
        {!!previewUrl && (
          <>
            <CalculateSize
              imageSize={imageSize}
              rndState={qrCodeSize}
              setRndState={setQrCodeSize}
              setImageSize={setImageSize}
              qrOptions={qrOptions}
              setQROptions={setQROptions}
            />
          </>
        )}

        {!!previewUrl && (
          <div
            style={{
              flex: 1,
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: "8px",
            }}
          >
            <ImageContainer imageSize={imageSize}>
              <ImageStyled
                src={previewUrl}
                className="hello"
                ref={bannerImageRef}
              />
              <Rnd
                style={style}
                size={{
                  width: qrCodeSize.width / factor.x,
                  height: qrCodeSize.height / factor.y,
                }}
                lockAspectRatio
                position={{
                  x: qrCodeSize.x / factor.x,
                  y: qrCodeSize.y / factor.y,
                }}
                onDragStop={(e, d) => {
                  setQrCodeSize((prevState) => ({
                    ...prevState,
                    x: Math.round(d.x * factor.x),
                    y: Math.round(d.y * factor.y),
                  }));
                }}
                onResizeStop={(e, direction, ref, delta, position) => {
                  setQrCodeSize((prevState) => ({
                    ...prevState,
                    width: Math.round(parseInt(ref.style.width) * factor.x),
                    height: Math.round(parseInt(ref.style.height) * factor.y),
                  }));
                }}
                bounds={`parent`}
              >
                <div
                  style={{
                    width: qrCodeSize.width / factor.x,
                    height: qrCodeSize.height / factor.y,
                  }}
                  dangerouslySetInnerHTML={{
                    __html: qrCodeSVG,
                  }}
                />
              </Rnd>
            </ImageContainer>
          </div>
        )}
      </Col>
    </SelectStickerStyled>
  );
};

export default SelectSticker;

const SelectStickerStyled = styled.div``;

const ImageStyled = styled.img<any>`
  width: 100%;
  height: 100%;
  max-height: 400px;
  @media ${devices.tablet} {
    max-height: 500px;
  }
  @media ${devices.laptopXL} {
    max-height: 600px;
  }
`;

const ImageContainer = styled.div<ImageContainerProps>`
  height: fit-content;
  max-height: 400px;

  @media ${devices.tablet} {
    max-height: 500px;
  }

  @media ${devices.laptopXL} {
    max-height: 600px;
  }

  position: relative;
  border: 1px solid #dbdbdb;
`;

interface IimageSize {
  width: number;
  height: number;
}
interface ImageContainerProps {
  imageSize: IimageSize;
  isWidthGreaterThanHeight?: Boolean;
}
