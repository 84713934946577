import { Divider, Input, Modal, Spin, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import ConfirmationMessagePopup from "components/ConfirmationMessagePopup/ConfirmationMessagePopup";
import BaseButton from "components/common/BaseButton";
import { BasePagination } from "components/common/BasePagination";
import { BaseTable } from "components/common/BaseTable";
import { BaseTypography } from "components/common/BaseTypography";
import Error from "components/common/Error";
import HeaderWrapper from "components/headerwrapper";
import { capitalize } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaEdit, FaEye, FaTrashAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import categoryApi from "shared/front/apis/category.api";
import store from "store";
import request from "utils/request";
import { convertTimestamp } from "utils/utils";
import { CategoriesStyled, SingleSubCategoryWrapperStyled } from "./style";
import { BaseTag } from "components/common/BaseTag";
import { TbLock, TbLockOpen } from "react-icons/tb";
import BaseTabs from "components/common/BaseTabs";
import { useResponsive } from "hooks/useResponsive";
import CategoriesTableRowCard from "./CategoriesTableRowCard";

type Props = {};

const Categories = (props: Props) => {
  const { t } = useTranslation();
  const { isLargeMobile } = useResponsive();
  // STATES
  const [addingCategory, setAddingCategory] = useState(false);
  const [gettingSubCategory, setGettingSubCategory] = useState(false);
  const [enableAddCategory, setEnableAddCategory] = useState(false);
  const [enableAddSubCategory, setEnableAddSubCategory] = useState(false);
  const [enableViewCategory, setEnableViewCategory] = useState(false);
  const [validationMessage, setValidationMessage] = useState(null);
  const [categoryName, setCategoryName] = useState("");
  const [subCategoryName, setSubCategoryName] = useState("");
  const [selectedCategory, setSelectedCategory] = useState<any>({});
  const [selectedValue, setSelectedValue] = useState<{
    type: "category" | "subCategory" | null;
    value: any | null;
    action: "delete" | "update" | "select" | null;
  }>({
    type: null,
    value: null,
    action: null,
  });
  const [isLoading, setIsLoading] = useState(false);

  // SELECTORS
  const categories = useSelector((state: any) => state?.categories?.data);

  const catPagination = useSelector(
    (state: any) => state?.categories?.pagination
  );
  const subCategories = useSelector((state: any) => state?.subCategories?.data);
  const dispatch = useDispatch();
  const items = [
    {
      key: "Active",
      label: t("dashboard.active"),
      archive: false,
    },

    {
      key: "Blocked",
      label: t("dashboard.blocked"),
      archive: true,
    },
  ];

  const columns: ColumnsType = [
    {
      title: t("dashboard.s.n."),
      dataIndex: "rowNo",
      key: "rowNo",
      rowScope: "row",
    },
    {
      title: t("dashboard.name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("dashboard.created-date"),
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: t("dashboard.status"),
      dataIndex: "status",
      key: "status",
      render: (value, record: any, index) => (
        <>
          <BaseTag color={record?.isArchive ? "warning" : "success"}>
            {record?.isArchive ? "Blocked" : "Active"}
          </BaseTag>
        </>
      ),
    },
    {
      title: t("dashboard.action"),
      dataIndex: "action",
      key: "action",
      align: "right" as "right",
      render: (text: any, record: any, index: any) => (
        <>
          <BaseButton
            onClick={() => {
              onViewRow(record);
            }}
            type="link"
            style={{ marginLeft: " 10px", padding: "0px" }}
          >
            <FaEye
              style={{ height: "24px", width: "24px", color: "var(--primary)" }}
            />
          </BaseButton>
          <BaseButton
            onClick={(e) => {
              e.stopPropagation();
              setSelectedValue({
                action: "delete",
                type: "category",
                value: record,
              });
            }}
            style={{ marginLeft: " 10px", padding: "0px" }}
            type="link"
          >
            {record?.isArchive ? (
              <TbLockOpen
                style={{
                  height: "24px",
                  width: "24px",
                  color: "var(--tairo)",
                }}
              />
            ) : (
              <TbLock
                style={{
                  height: "24px",
                  width: "24px",
                  color: "var(--orange)",
                }}
              />
            )}
          </BaseButton>
          {!record?.isArchive && (
            <BaseButton
              onClick={(e) => {
                e.stopPropagation();
                setSelectedCategory(record);
                setCategoryName(record?.name);
                handleAddCategory();
              }}
              style={{ marginLeft: " 10px", padding: "0px" }}
              type="link"
            >
              <FaEdit
                style={{
                  height: "24px",
                  width: "24px",
                  color: "var(--skyblue)",
                }}
              />
            </BaseButton>
          )}
        </>
      ),
    },
  ];

  const categoryDataSource = Array.isArray(categories)
    ? categories.map((cat, index) => ({
        rowNo: catPagination?.page * catPagination?.limit + index + 1,
        id: cat?._id,
        createdAt: convertTimestamp(cat?.createdAt),
        name: cat?.name,
        isArchive: cat?.isArchive,
      }))
    : [];

  useEffect(() => {
    if (Array.isArray(categories) && categories?.length === 0) {
      dispatch(
        store.Actions.set("categories.pagination", {
          limit: 10,
          page: 0,
          status: "Active",
          archive: false,
        })
      );
      getAllCategories({ limit: 10, page: 0, archive: false });
    }
  }, []);

  useEffect(() => {
    return () => {
      dispatch(
        store.Actions["update"]("categories.pagination", {
          page: 0,
          status: "Active",
          archive: false,
          limit: 10,
        })
      );
    };
  }, []);

  const handleTabs = (status) => {
    dispatch(
      store.Actions["update"]("categories.pagination", {
        page: 0,
        status,
        archive: status === "Blocked",
        limit: 10,
      })
    );

    getAllCategories({
      page: 0,
      archive: status === "Blocked",
      limit: 10,
    });
  };

  const onViewRow = async (record) => {
    setSelectedCategory(record);
    setEnableViewCategory(true);
    await getSubCategory(record.id);
  };
  const handlePagination = (page, limit) => {
    dispatch(
      store.Actions["update"]("categories.pagination", {
        ...catPagination,
        page,
        limit,
      })
    );
    getAllCategories({ page, limit, archive: catPagination?.archive });
  };

  const getAllCategories = async (params) => {
    await request(
      categoryApi.getAllCategories("set", {
        ...params,
      })
    );
  };

  const refetchCategories = () => {
    const { page, limit, archive } = catPagination;
    if (categories?.length > 1) {
      getAllCategories({ page, limit, archive });
    } else {
      if (page > 0) {
        handlePagination(page - 1, limit);
      }
    }
  };

  const onViewCategoryClose = () => {
    setEnableViewCategory(false);
    setSelectedCategory({});
    onAddSubCategoryClose();
    setSubCategoryName("");
    handleClearSelectedValues();
  };

  const handleClearSelectedValues = () => {
    setSelectedValue({
      action: null,
      type: null,
      value: null,
    });
  };
  const onAddCategoryClose = () => {
    setEnableAddCategory(false);
    setCategoryName("");
    setSelectedCategory({});
    setValidationMessage(null);
    handleClearSelectedValues();
  };

  const onAddSubCategoryClose = () => {
    setEnableAddSubCategory(false);
    setValidationMessage(null);
    handleClearSelectedValues();
    setSubCategoryName("");
  };
  const handleAddCategory = () => {
    setEnableAddCategory(true);
  };

  const getSubCategory = async (categoryId) => {
    try {
      setGettingSubCategory(true);
      dispatch(
        store.Actions.set("subCategories.pagination", {
          limit: 50,
          page: 0,
        })
      );
      await request(
        categoryApi.getAllSubCategories(categoryId, "set", {
          limit: 50,
          page: 0,
        })
      );
    } catch (error) {
      console.log("Geeting Sub Categories :", error);
      if (typeof error === "string") {
        toast.error(error);
      }
    } finally {
      setGettingSubCategory(false);
    }
  };

  const addCategory = async (value) => {
    try {
      if (!value) {
        setValidationMessage(t("category.category-validation"));
        return;
      }
      console.log(value);
      setValidationMessage(null);
      setAddingCategory(true);
      let response = null;
      if (!!selectedCategory.id) {
        // Edit category
        response = await request(
          categoryApi.updateCategory(selectedCategory.id, { name: value })
        );
      } else {
        // Add category
        response = await request(categoryApi.addCategory({ name: value }));
      }
      if (response) {
        let updatedCategories = [];
        if (!!selectedCategory.id) {
          // Edit category
          const findCategoryIndex = categories?.findIndex(
            (category) => category._id === selectedCategory?.id
          );
          if (findCategoryIndex >= 0) {
            const updateCategories = [...categories];
            updateCategories[findCategoryIndex] = {
              ...updateCategories[findCategoryIndex],
              name: value,
            };
            updatedCategories = updateCategories;
            console.log(
              updateCategories,
              findCategoryIndex,
              updateCategories[findCategoryIndex]
            );
          }
        } else {
          // Add category
          if (catPagination?.page === 0) {
            console.log({ page: catPagination?.page });
            updatedCategories = [response, ...categories];
          } else {
            updatedCategories = [...categories];
          }
          dispatch(
            store.Actions.set("categories.pagination", {
              ...catPagination,
              count: catPagination?.count + 1,
            })
          );
        }
        dispatch(store.Actions.set("categories.data", updatedCategories));
        toast.success(
          `${value} ${t("category.category-is")} ${
            !!selectedCategory.id
              ? t("category.toast-updated")
              : t("category.toast-added")
          } ${t("category.successfully")}.`
        );
        refetchCategories();
      }
      onAddCategoryClose();
    } catch (error) {
      console.log("Adding Category: ", error);
      if (typeof error === "string") {
        toast.error(error);
      }
    } finally {
      setAddingCategory(false);
    }
  };

  const addSubCategory = async (value, categoryId) => {
    try {
      if (!value) {
        setValidationMessage(t("category.sub-category-validation"));
        return;
      }
      if (!categoryId) {
        setValidationMessage(t("category.category-select"));
        return;
      }

      setValidationMessage(null);
      setAddingCategory(true);
      let response = null;
      if (
        selectedValue?.action === "update" &&
        selectedValue?.type === "subCategory"
      ) {
        response = await request(
          categoryApi.updateSubCategory(selectedValue?.value?._id, value)
        );
      } else {
        // Add sub category
        response = await request(categoryApi.addSubCategory(categoryId, value));
      }
      if (response) {
        if (
          selectedValue?.action === "update" &&
          selectedValue?.type === "subCategory"
        ) {
          let updatedValue = subCategories.find(
            (x) => x._id === selectedValue?.value?._id
          );
          updatedValue = { ...updatedValue, name: value };
          // Add sub category
          const updatedSubCategories = [
            updatedValue,
            ...subCategories.filter((x) => x._id !== selectedValue?.value?._id),
          ];
          dispatch(
            store.Actions.set("subCategories.data", updatedSubCategories)
          );
          toast.success(`${value} sub category is updated successfully.`);
        } else {
          // Add sub category
          const updatedSubCategories = [...response, ...subCategories];
          dispatch(
            store.Actions.set("subCategories.data", updatedSubCategories)
          );
          toast.success(`${value} sub category is added successfully.`);
        }
      }
      onAddSubCategoryClose();
    } catch (error) {
      console.log("Adding Category: ", error);
      if (typeof error === "string") {
        toast.error(error);
      }
    } finally {
      setAddingCategory(false);
    }
  };

  const handleDeleteCategory = async () => {
    try {
      setIsLoading(true);
      const response = await request(
        categoryApi.deleteCategory(selectedValue?.value?.id)
      );
      if (response) {
        dispatch(store.Actions.remove("categories", selectedValue?.value?.id));
        toast.success(
          `Category ${selectedValue?.value?.name} removed successfully.`
        );
        handleClearSelectedValues();
        refetchCategories();
      }
    } catch (error) {
      console.log("Delete Category:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUnblockCategory = async () => {
    try {
      setIsLoading(true);
      const response = await request(
        categoryApi.unblockCategory(selectedValue?.value?.id)
      );
      if (response) {
        dispatch(store.Actions.remove("categories", selectedValue?.value?.id));

        toast.success(
          `Category ${selectedValue?.value?.name} unblocked successfully.`
        );
        handleClearSelectedValues();
        refetchCategories();
      }
    } catch (error) {
      console.log("Delete Category:", error);
      if (typeof error === "string") {
        toast.error(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteSubCategory = async () => {
    try {
      setIsLoading(true);
      const response = await request(
        categoryApi.deleteSubCategory(selectedValue?.value?._id)
      );
      if (response) {
        dispatch(
          store.Actions.remove("subCategories", selectedValue?.value?._id)
        );
        toast.success(
          `Sub category ${selectedValue?.value?.name} removed successfully.`
        );
        handleClearSelectedValues();
      }
    } catch (error) {
      console.log("Delete Sub Category:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <CategoriesStyled>
        <HeaderWrapper
          title={t("category.category")}
          actionTitle={t("category.add-category")}
          handleAction={handleAddCategory}
        />{" "}
        <BaseTabs
          items={items}
          handleTabs={handleTabs}
          statusValue={catPagination.status}
        />
        {isLargeMobile ? (
          <BaseTable
            dataSource={categoryDataSource}
            columns={columns}
            pagination={false}
            onRow={(record, rowIndex) => {
              return {
                onClick: () => {
                  onViewRow(record);
                },
              };
            }}
          />
        ) : (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {categoryDataSource?.map((category) => (
              <CategoriesTableRowCard
                key={category.id}
                name={category.name}
                createdDate={category.createdAt}
                isArchive={category.isArchive}
                onViewClick={(e) => {
                  e.stopPropagation();
                  onViewRow(category);
                }}
                onLockClick={(e) => {
                  e.stopPropagation();
                  setSelectedValue({
                    action: "delete",
                    type: "category",
                    value: category,
                  });
                }}
                onEditClick={(e) => {
                  e.stopPropagation();
                  setSelectedCategory(category);
                  setCategoryName(category.name);
                  handleAddCategory();
                }}
              />
            ))}
          </div>
        )}
        <BasePagination
          pagination={catPagination}
          handlePagination={handlePagination}
        />
      </CategoriesStyled>
      {/* Add Category */}
      <Modal
        open={enableAddCategory}
        onCancel={onAddCategoryClose}
        okText={
          !!selectedCategory.id
            ? t("category.edit-category")
            : t("category.add-category")
        }
        title={
          !!selectedCategory.id
            ? t("category.edit-category")
            : t("category.add-category")
        }
        footer={null}
      >
        <Typography.Text style={{ marginBottom: "5px", display: "block" }}>
          {t("category.category-name")}
        </Typography.Text>
        <Input
          placeholder={t("category.category-placeholder")}
          onChange={(e) => {
            setValidationMessage(null);
            setCategoryName(e.target.value);
          }}
          value={categoryName}
        />
        <Error message={validationMessage} />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            margin: "20px 0px",
          }}
        >
          <BaseButton onClick={() => onAddCategoryClose()}>
            {t("dashboard.cancel")}
          </BaseButton>
          <BaseButton
            type="primary"
            style={{ background: "var(--primary)", marginLeft: "8px" }}
            onClick={() => addCategory(categoryName)}
            loading={addingCategory}
          >
            {!!selectedCategory.id
              ? t("category.edit-category")
              : t("category.add-category")}
          </BaseButton>
        </div>
      </Modal>
      {/* View Category */}
      <Modal
        open={enableViewCategory}
        onCancel={onViewCategoryClose}
        footer={null}
        title={selectedCategory?.name}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography.Text>{t("category.sub-category")}</Typography.Text>
          {enableAddSubCategory ? null : (
            <BaseButton
              onClick={() => {
                setEnableAddSubCategory(true);
              }}
            >
              {t("category.add-subcategory")}
            </BaseButton>
          )}
        </div>
        <Divider style={{ margin: "6px 0px" }} />
        {enableAddSubCategory ? (
          <div>
            <Typography.Text style={{ marginBottom: "5px", display: "block" }}>
              Sub Category Name
            </Typography.Text>
            <Input
              placeholder="Sub Category"
              onChange={(e) => setSubCategoryName(e.target.value)}
              value={subCategoryName}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                margin: "20px 0px",
              }}
            >
              <BaseButton
                onClick={() => {
                  handleClearSelectedValues();
                  setSubCategoryName("");
                  setEnableAddSubCategory(false);
                }}
              >
                {t("dashboard.cancel")}
              </BaseButton>
              <BaseButton
                type="primary"
                style={{ background: "var(--primary)", marginLeft: "8px" }}
                onClick={() =>
                  addSubCategory(subCategoryName, selectedCategory?.id)
                }
                loading={addingCategory}
              >
                {selectedValue?.action === "update"
                  ? t("category.edit-sub-category")
                  : t("category.add-sub-category")}
              </BaseButton>
            </div>
          </div>
        ) : gettingSubCategory ? (
          <div style={{ color: "var(--primary)", textAlign: "center" }}>
            <Spin
              size="large"
              style={{ color: "var(--primary)", textAlign: "center" }}
            />
          </div>
        ) : Array.isArray(subCategories) && subCategories.length > 0 ? (
          subCategories.map((val, idx) => (
            <SingleSubCategoryWrapperStyled key={idx}>
              <div>
                <span>{idx + 1}.</span>{" "}
                <BaseTypography.Text>{val?.name}</BaseTypography.Text>
              </div>
              <div>
                <BaseButton
                  type="link"
                  onClick={() => {
                    setSelectedValue({
                      action: "update",
                      type: "subCategory",
                      value: val,
                    });
                    setEnableAddSubCategory(true);
                    setSubCategoryName(val?.name);
                  }}
                >
                  <FaEdit className="edit-icon icon" />
                </BaseButton>
                <BaseButton
                  type="link"
                  onClick={() =>
                    setSelectedValue({
                      action: "delete",
                      type: "subCategory",
                      value: val,
                    })
                  }
                >
                  <FaTrashAlt className="delete-icon icon" />
                </BaseButton>
              </div>
            </SingleSubCategoryWrapperStyled>
          ))
        ) : (
          <Typography.Text type="secondary">
            {t("category.no-subCategory-message")}
          </Typography.Text>
        )}
      </Modal>
      <ConfirmationMessagePopup
        isVisible={selectedValue?.action === "delete"}
        actionIsDanger
        isLoading={isLoading}
        onClose={handleClearSelectedValues}
        title={
          selectedValue?.type === "category"
            ? selectedValue?.value?.isArchive
              ? "Unblock"
              : "Block"
            : "Delete"
        }
        onAction={() => {
          if (selectedValue?.type === "category") {
            if (selectedValue?.value?.isArchive) {
              handleUnblockCategory();
            } else {
              handleDeleteCategory();
            }
          } else {
            handleDeleteSubCategory();
          }
        }}
        actionLabel={
          selectedValue?.type === "category"
            ? selectedValue?.value?.isArchive
              ? "Unblock"
              : "Block"
            : "Delete"
        }
      >
        <BaseTypography.Text>
          Are you sure you want to{" "}
          {selectedValue?.type === "category"
            ? selectedValue?.value?.isArchive
              ? "unblock"
              : "block"
            : "delete"}{" "}
          this{" "}
          {selectedValue?.type === "category" ? "category" : "sub category"}?
        </BaseTypography.Text>
        <br />
        <br />
        <BaseTypography.Text>{selectedValue?.value?.name}</BaseTypography.Text>
      </ConfirmationMessagePopup>
    </>
  );
};

export default Categories;
