import { BaseTable } from "components/common/BaseTable";
import HeaderWrapper from "components/headerwrapper";
import React, { useEffect, useState } from "react";
import * as S from "./index.styles";
import { TableProps } from "antd";
import useAPI from "hooks/useApi";
import { getAllGeneratedQr, getAllOperator } from "constants/api/qr";
import { toast } from "react-toastify";
import { BaseTag } from "components/common/BaseTag";
import BaseButton from "components/common/BaseButton";
import { convertTimestamp } from "utils/utils";
import { downloadFile } from "services/filesave";
import { useNavigate } from "react-router-dom";
import updatedQr from "shared/front/apis/updatedqr.api";
import { CapitalText, FilterWrapper, FlexEnd } from "utils/styles";
import FilterModal from "./FilterModal";
import BaseLoading from "components/common/BaseSpin";
import BaseTabs from "components/common/BaseTabs";
import EyeOutlined from "@ant-design/icons/EyeOutlined";

const QR = () => {
  const navigate = useNavigate();
  const [
    handleRequest,
    { loading: updateRequestLoading, data: generatedQRData, error },
  ] = useAPI();
  const [params, setParams] = useState({ status: "Requested" });

  const items = [
    {
      key: "Requested",
      label: `Requested`,
    },
    {
      key: "Accepted",
      label: `Accepted`,
    },
    {
      key: "Rejected",
      label: `Rejected`,
    },
  ];

  const { QRcode, city, operator } =
    generatedQRData?.data.length > 0 && generatedQRData?.data;

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (record: any) => {
        return <BaseTag color="success">{record}</BaseTag>;
      },
    },
    {
      title: "QR",
      dataIndex: "image",
      key: "image",
      render: (imageURL: string) => (
        <img alt={imageURL} src={imageURL} height={"50px"} width={"50px"} />
      ),
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: any, record: any, index: any) => {
        return <span>{convertTimestamp(text)}</span>;
      },
    },
    {
      title: "Updated By",
      dataIndex: "updatedBy",
      key: "updatedBy",
      render: (text: string) => {
        return <CapitalText>{text}</CapitalText>;
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text: any, record: any, index: any) => (
        <BaseButton
          onClick={async () => {
            navigate(`${record?.id}`);
          }}
        >
          <EyeOutlined />
        </BaseButton>
      ),
    },
  ];

  const dataSource =
    generatedQRData?.data.length > 0 &&
    generatedQRData?.data.map((item: any) => ({
      key: item._id,
      id: item._id,
      status: item?.status,
      isCompleted: item.isComplete,
      image: item.QRCode.stickerURL,
      createdAt: item.createdAt,
      action: item.stickerURL,
      updatedBy: item?.operator?.firstName + " " + item?.operator?.lastName,
    }));

  console.log("the updated QR", generatedQRData);

  const onChange: TableProps<any>["onChange"] = (
    pagination,
    filters,
    sorter,
    extra
  ) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  const makeGeneratedQr = async () => {
    try {
      await handleRequest(updatedQr.getAllUpdatedQr(params));
    } catch (error) {
      toast.error("Cannot generate the Qr");
    }
  };

  useEffect(() => {
    makeGeneratedQr();
  }, [params]);
  return (
    <>
      <S.HeaderFilterTab>
        <HeaderWrapper title={"QR"} />
        {/* <FlexEnd>
              <FilterWrapper>
                <FilterModal
                  params={params}
                  setParams={setParams}
                  options={options}
                ></FilterModal>
              </FilterWrapper>
            </FlexEnd> */}
      </S.HeaderFilterTab>
      <BaseTabs params={params} setParams={setParams} items={items} />
      {!!updateRequestLoading ? (
        <BaseLoading loadingsize={50} />
      ) : (
        <BaseTable
          dataSource={dataSource}
          columns={columns}
          onChange={onChange}
        />
      )}
    </>
  );
};

export default QR;
