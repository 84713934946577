const cameraApi = {
  getAllCamera: (action, params): any => {
    return {
      url: "common/getAllCamera",
      method: "get",
      params: params,
      config: {
        store: {
          action: action,
          key: "camera.data",
        },
      },
    };
  },
  getCameraDetails: (cameraId): any => {
    return {
      url: `common/getCamera/${cameraId}`,
      method: "get",
      config: {
        store: {
          action: "set",
          key: "cameraDetails",
        },
      },
    };
  },

  addCamera: (data: any): any => {
    return {
      url: "common/addCamera",
      method: "post",
      data,
    };
  },
  updateCamera: ({ data, cameraId }: any) => {
    return {
      url: `common/updateCamera/${cameraId}`,
      method: "patch",
      data,
    };
  },

  deleteCamera: (cameraId: string) => {
    return {
      url: `common/deleteCamera/${cameraId}`,
      method: "delete",
    };
  },

  unblockCamera: (cameraId: string) => {
    return {
      url: `common/unBlockCameraById/${cameraId}`,
      method: "post",
    };
  },

  getCity: (action, params): any => {
    return {
      url: "common/getAllCity",
      method: "get",
    };
  },
  addImages: (cameraId, data) => {
    return {
      url: `common/addCameraImageByCameraId/${cameraId}`,
      method: "post",
      data,
    };
  },
};

export default cameraApi;
