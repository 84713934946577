import styled from "styled-components";
import BackGroundImage from "../../../assets/background-image.svg";
import { devices } from "utils/theme";

export const SectionContainer = styled.div`
  background-image: url(${BackGroundImage}); 
  background-size: cover;
  background-position: left bottom;
  background-repeat: no-repeat;
  width: 100%;
 
  @media (max-width: 768px) { 
       background-image: none;
      }

  & .content-container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 0px 100px 20px 50px;

    @media (max-width: 768px) { 
      flex-direction: column; 
      align-items: center; 
      padding: 20px; 
      background-image: none;
    }
   
    }

    & .content {
      padding: 20px 10%;
      flex: 1;

      & .text {
        margin-bottom: 20px;
        text-align: center;
        font-size: 22px;
        color: #243D44;
      }
    }

    img {
      width: 30%;
      height: 400px;
      object-fit: contain;

      @media (max-width: 768px) { 
        width: 70%;
        height: auto; 
        margin-bottom: 20px; 
        /* display: none; */
      }

   
    }
  }
`;

export const SolutionContainer = styled.div`
  display: flex;
  max-width: 1160px;
  flex-direction: column;
  margin: 0 auto;
  margin-bottom: 150px;
  padding: 80px 50px;

  & .title {
    text-align: center;
    margin-bottom: 30px;
    color: #167a8b;
    font-size: 1.8rem;
    font-weight: 600;
  }

  & .main {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    gap: 20px;
    width: 100%;

    & .container {
      display: flex;

      flex-direction: column;

      align-items: center;

      padding: 20px;
      width: 100%;
      border-radius: 10px;
      border: 0.5px solid #818181;

      & .image-container {
        height: 50px;
        width: 50px;
        background-color: #aad9e1;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        margin-bottom: 10px;
      }

      & .container-title {
        color: #576874;
      }

      & .description-list {
        list-style-type: disc;
        color: #818181;
        font-size: 28px;
        padding: 0px 0px 0px 15px;
        display: block;
        gap: 10px;
      }

      & .text {
        color: #818181;
      }
      & .container-title {
        text-align: center;
      }

      & .image {
        text-align: center;
      }
    }
  }
`;
