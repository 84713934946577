export default [
  {
    title: "Limitation de Responsabilité",
    desc: [
      "Camera Compliance Ltd, ses affiliés, partenaires et employés ne seront pas responsables des dommages directs, indirects, accessoires, spéciaux, consécutifs ou punitifs, y compris, sans s'y limiter, la perte financière, la perte de données ou tout autre dommage découlant de votre utilisation de ce site Web ou de votre confiance dans le contenu informatif fourni.",
    ],
  },
  {
    title: "Pas de Conseil Professionnel",
    desc: [
      "Les informations fournies sur ce site Web ne constituent pas un conseil juridique, technique ou professionnel. Bien que nous nous efforcions de fournir des informations précises et à jour, nous ne donnons aucune garantie ni représentation d'aucune sorte quant à l'exactitude, la devise ou l'exhaustivité du site Web.",

      "Pour obtenir des conseils détaillés adaptés à votre situation, consultez des professionnels qualifiés, notamment des professionnels du droit expérimentés en matière de protection des données et de conformité au RGPD.",
    ],
  },
  {
    title: "Droit Applicable",
    desc: [
      "Cette renonciation sera régie et interprétée conformément aux lois de la juridiction dans laquelle Camera Compliance Ltd exerce ses activités. En utilisant ce site Web, vous consentez à la compétence de ces tribunaux pour toute action, poursuite ou procédure découlant de cette renonciation.",
    ],
  },
];
