export default [
  {
    title: "Haftungsbeschränkung",
    desc: [
      "Camera Compliance Ltd, seine Partner, Tochtergesellschaften und Mitarbeiter haften nicht für direkte, indirekte, zufällige, besondere, Folge- oder Strafschäden, einschließlich, aber nicht beschränkt auf finanzielle Verluste, Datenverlust oder andere Schäden, die sich aus Ihrer Nutzung dieser Website oder Ihrem Vertrauen auf den bereitgestellten Informationsinhalt ergeben.",
    ],
  },
  {
    title: "Keine Professionelle Beratung",
    desc: [
      "Die auf dieser Website bereitgestellten Informationen stellen keine rechtliche, technische oder andere professionelle Beratung dar. Obwohl wir bestrebt sind, genaue und aktuelle Informationen bereitzustellen, übernehmen wir keine Garantien oder Zusicherungen hinsichtlich der Genauigkeit, Aktualität oder Vollständigkeit der Website.",

      "Für detaillierte Beratung, die auf Ihre Situation zugeschnitten ist, konsultieren Sie qualifizierte Fachleute, einschließlich, aber nicht beschränkt auf Rechtsanwälte mit Erfahrung im Datenschutz und der Einhaltung der DSGVO.",
    ],
  },
  {
    title: "Anwendbares Recht",
    desc: [
      "Diese Haftungsbeschränkung unterliegt den Gesetzen der Gerichtsbarkeit, in der Camera Compliance Ltd tätig ist, und wird entsprechend ausgelegt. Durch die Nutzung dieser Website erklären Sie sich mit der Zuständigkeit dieser Gerichte für etwaige Klagen, Verfahren oder Rechtsstreitigkeiten im Zusammenhang mit dieser Haftungsbeschränkung einverstanden.",
    ],
  },
];
