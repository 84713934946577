import React from "react";
import styled from "styled-components";

// Styled component for the card container
const CardContainer = styled.div<any>`
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 16px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: ${({ noMargin }) => (noMargin ? "0px" : "15px 0px")};
`;

// Styled component for the card title
const CardTitle = styled.h2`
  font-size: 20px;
  margin-bottom: 8px;
`;

// Styled component for the card content
const CardContent = styled.p`
  font-size: 16px;
`;

// Reusable Card component
const Card = ({ children, noMargin = false, ...restProps }: any) => {
  return (
    <CardContainer noMargin={noMargin} {...restProps}>
      {children}
    </CardContainer>
  );
};

export default Card;
