import { Input } from "antd";
import React, { useState, useCallback, useEffect } from "react";
import { debounce } from "lodash";
import { SearchOutlined } from "@ant-design/icons";
import { styled } from "styled-components";
import { useResponsive } from "hooks/useResponsive";
//@ts-ignore
import { useTranslation } from "react-i18next";

const BaseSearch = ({ searchValue, handleSearch, style }: any) => {
  const { isTablet } = useResponsive();
  const { t } = useTranslation();
  const [value, setValue] = useState(searchValue);

  const debounceFn = useCallback(
    debounce((val) => {
      handleSearch(val);
    }, 500),
    []
  );

  const handleChange = (value) => {
    setValue(value);
    debounceFn(value);
  };

  useEffect(() => {
    if (searchValue !== value) {
      setValue(searchValue);
    }
  }, [searchValue]);

  return (
    <SearchWrapper style={style}>
      <Input
        placeholder={t("search.search")}
        onChange={(e) => handleChange(e.target.value)}
        value={value}
        allowClear
        style={{ width: isTablet ? 240 : 215 }}
      />
      {!value && <SearchIconStyled />}
    </SearchWrapper>
  );
};

export default BaseSearch;
const SearchWrapper = styled.div`
  position: relative;
  height: 32px;
`;
const SearchIconStyled = styled(SearchOutlined)`
  position: absolute;
  top: 7px;
  right: 6px;
  color: var(--lightgrey);
  font-size: 18px;
`;
