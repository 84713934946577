import axios from "axios";
import React, { useState } from "react";
import styled from "styled-components";
import { PlusOutlined } from "@ant-design/icons";
import BaseLoading from "./BaseSpin";
import { BaseTypography } from "./BaseTypography";
import BaseError from "./BaseError";
import request from "utils/request";
import S3api from "shared/front/apis/S3.api";
import Compressor from "compressorjs";

const S3uploaderMessage = ({
  setFieldValue,
  fieldName,
  error,
  label,
  value,
  isFullWidth,
  footerLogoState,
  isFormik = true,
  setFooterlogo,
  setButtonLoading,
}: any) => {
  const [imageLoading, setImageLoading] = useState(false);
  const [compressedFile, setCompressedFile] = useState(null);

  const uploadTo = async (file, presignedUrl) => {
    try {
      const response = await fetch(presignedUrl, {
        method: "PUT",
        headers: {
          "Content-Type": file.type,
        },
        body: file,
      });
      console.log("Image upload successful:", response);
    } catch (err) {
      console.log("Image upload failed:", err);
    }
  };
  const uploadToS3 = async (file) => {
    setButtonLoading && setButtonLoading(true);
    setImageLoading(true);

    try {
      const data = await request(S3api.s3PresignedUrl());

      const { url, key } = data;

      await uploadTo(file, url);
      setButtonLoading && setButtonLoading(false);
      setImageLoading(false);
      return key;
    } catch (err) {
      console.log("upload to s3", err);
      throw err;
    }
  };

  const handleChange = (e) => {
    const file = e.target.files[0];
    if (isFormik) {
      setFieldValue(fieldName, "");
    }

    uploadToS3(file)
      .then((imageUrl) => {
        if (isFormik) {
          setFieldValue(fieldName, imageUrl);
        } else {
          setFooterlogo((prev) => ({ ...prev, URL: imageUrl }));
        }
      })
      .catch((error) => console.log("error uploading", error))
      .finally(() => {
        e.target.value = "";
      });
  };
  const handleRemoveImage = () => {
    if (isFormik) {
      setFieldValue(fieldName, "");
    } else {
      setFooterlogo((prev) => ({ ...prev, URL: "" }));
    }
  };

  return (
    <>
      <BaseTypography>{label}</BaseTypography>
      <Container $isFullWidth={isFullWidth}>
        {value && isFormik && (
          <>
            <Image src={value} alt="Uploaded" $isFullWidth={isFullWidth} />
            <CloseIcon onClick={handleRemoveImage}>X</CloseIcon>
          </>
        )}
        {!isFormik && !!footerLogoState.URL && (
          <>
            <Image
              src={footerLogoState.URL}
              alt={footerLogoState.URL}
              $isFullWidth={isFullWidth}
            />
            <CloseIcon onClick={handleRemoveImage}>X</CloseIcon>
          </>
        )}
        {!value && isFormik && (
          <>
            {imageLoading ? (
              <ContentCenter>
                <BaseLoading />
              </ContentCenter>
            ) : (
              <ContentCenter>
                <PlusOutlined style={{ fontSize: "20px" }} />
              </ContentCenter>
            )}

            <FileInput
              id="file-input"
              type="file"
              onChange={handleChange}
            ></FileInput>

            {error && <BaseError size={"small"}>{error}</BaseError>}
          </>
        )}
        {!isFormik && !footerLogoState?.URL && (
          <>
            {imageLoading ? (
              <ContentCenter>
                <BaseLoading />
              </ContentCenter>
            ) : (
              <ContentCenter>
                <PlusOutlined style={{ fontSize: "20px" }} />
              </ContentCenter>
            )}

            <FileInput
              id="file-input"
              type="file"
              onChange={handleChange}
            ></FileInput>

            {error && <BaseError size={"small"}>{error}</BaseError>}
          </>
        )}
      </Container>
    </>
  );
};

export default S3uploaderMessage;
const FileInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100px;
  overflow: hidden;
  opacity: 0;
  cursor: pointer;
`;

const Image = styled.img<{ $isFullWidth: any }>`
  width: 100%;
  height: ${({ $isFullWidth }) => ($isFullWidth ? "200px" : "100px")};

  object-fit: cover;
`;
const Container = styled.div<{ $isFullWidth: any }>`
  position: relative;
  display: inline-block;
  background-color: #fafafa;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  margin: 5px;
  width: ${({ $isFullWidth }) => ($isFullWidth ? "100%" : "100px")};
`;
const ContentCenter = styled.div`
  height: 100px;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const CloseIcon = styled.div`
  background-color: red;
  border-radius: 20px;
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 15px;
  cursor: pointer;
  border: 1px solid red;
  width: 20px;
  height: 20px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const WarningText = styled(BaseTypography)`
  color: var(--red);
`;
