import React, { useState } from "react";
import { Main } from "./style";
import MainHeader from "layouts/Main/MainHeader/MainHeader";
import { Typography, Input, Modal, Button } from "antd";
import useAPI from "hooks/useApi";
import { toast } from "react-toastify";
//@ts-ignore
import { useTranslation } from "react-i18next";
import {
  BsFillTelephoneFill,
  BsFillPersonFill,
  BsArrowRight,
} from "react-icons/bs";
import { CheckCircleOutlined } from "@ant-design/icons";
import { BiSolidCity, BiMessageDetail } from "react-icons/bi";
import { AiOutlineMail } from "react-icons/ai";
import { TbCameraSelfie } from "react-icons/tb";
import Footer from "containers/Home/Footer";
import { styled } from "styled-components";
import { useNavigate } from "react-router-dom";
import { color } from "echarts";

const { TextArea } = Input;

const Contact = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    phone: "",
    email: "",
    message: "",
  });
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [createContact, { loading, error }] = useAPI();
  const [modalVisible, setModalVisible] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e, data) => {
    e.preventDefault();

    if (!data.fullName || !data.phone || !data.email || !data.message) {
      toast.error(t("contact.valid"));
      return;
    }

    try {
      setIsLoading(true);
      const response = await createContact({
        method: "POST",
        url: "common/contactUs",
        data: data,
      });

      console.log("Form submitted successfully", response.data);

      setSubmitted(true);
    } catch (error) {
      console.error("Error submitting form", error);
      toast.error(t("contact.error"));
    } finally {
      setIsLoading(false);
    }
  };

  const handleModalOk = () => {
    setModalVisible(false);
    navigate("/");
  };
  return (
    <>
      <Main>
        <div className="Container">
          <div className="Header">
            <MainHeader onHomePress={() => navigate("/")} />
          </div>
          <div className="ContactContainer">
            <div className="Contact">
              <div className="left">
                <Typography.Title level={3} style={{ color: "#167a8b" }}>
                  {t("contact.contact-us")}
                </Typography.Title>
                <Typography.Text style={{ color: "#576874" }}>
                  {t("contact.description")}
                </Typography.Text>
                <br />
                <br />
              </div>
              <div className="right">
                {submitted ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      // height: "370px",
                      textAlign: "center",
                      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                      padding: "50px",
                      borderRadius: "10px",
                      maxWidth: "1160px",
                    }}
                  >
                    <CheckCircleOutlined
                      style={{
                        fontSize: "40px",
                        color: "#52c41a",
                        marginBottom: "30px",
                      }}
                    />
                    <Typography.Title level={3} style={{ color: "gray" }}>
                      Submitted Successfully
                    </Typography.Title>
                    <Typography.Text
                      style={{
                        color: "gray",
                        textAlign: "center",
                        marginBottom: "30px",
                      }}
                    >
                      {t("contact.success-message")}
                    </Typography.Text>
                    <Button
                      key="Done"
                      // type="primary"
                      disabled={isLoading}
                      onClick={handleModalOk}
                      style={{
                        margin: "0 auto",
                        backgroundColor: "green",
                        color: "white",
                        borderRadius: "15px",
                        width: "110px",
                      }}
                    >
                      {t("contact.done")}
                    </Button>
                  </div>
                ) : (
                  <form
                    className="form-control"
                    onSubmit={(e) => handleSubmit(e, formData)}
                  >
                    <div className="input-btn-container">
                      <Input
                        placeholder={t("contact.full-name")}
                        value={formData.fullName}
                        name="fullName"
                        onChange={handleInputChange}
                        suffix={<BsFillPersonFill size={20} />}
                        className="StyledInput"
                      />
                      <Input
                        placeholder={t("contact.phone")}
                        value={formData.phone}
                        name="phone"
                        onChange={handleInputChange}
                        suffix={<BsFillTelephoneFill size={20} />}
                        className="StyledInput"
                      />
                      <Input
                        placeholder={t("contact.email")}
                        value={formData.email}
                        name="email"
                        type="email"
                        onChange={handleInputChange}
                        suffix={<AiOutlineMail size={20} />}
                        className="StyledInput"
                      />

                      <textarea
                        placeholder={t("contact.message")}
                        value={formData.message}
                        name="message"
                        onChange={handleInputChange}
                        rows={6}
                        style={{
                          border: "none",
                          outlineColor: "transparent",
                          boxShadow: "none",
                          borderBottom: "1px solid #c0c0c0",
                          fontSize: "14px",
                          padding: "10px",
                          fontFamily: "poppins",
                        }}
                        // suffix={<BiMessageDetail size={20} />}
                        // className="StyledInput"
                        className="StyledTextarea"
                      />
                      <br />
                    </div>

                    <button type="submit" className="btn">
                      {t("contact.send")}
                      <BsArrowRight
                        style={{ marginLeft: "5px", color: "white" }}
                      />
                    </button>
                  </form>
                )}
              </div>
            </div>
            <Content>
              <div>
                <Typography.Title level={5}>
                  {t("contact.give-us-a-call")}
                </Typography.Title>
                <Typography.Text type="secondary">
                  +353876436162
                </Typography.Text>
              </div>
              <div>
                <Typography.Title level={5}>
                  {t("contact.we're-On-the-map")}
                </Typography.Title>
                <Typography.Text type="secondary">Ireland</Typography.Text>
              </div>
              <div>
                <Typography.Title level={5}>
                  {t("contact.send-us-a-message")}
                </Typography.Title>
                <Typography.Text type="secondary">
                  <a href="mailto:info@cameracompliance.com">
                    info@cameracompliance.com
                  </a>
                </Typography.Text>
              </div>
            </Content>
          </div>
        </div>
        <div className="footer">
          <Footer />
        </div>
      </Main>
    </>
  );
};

export default Contact;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  margin: 0 auto;

  @media (max-width: 700px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 10px;
  }
`;
