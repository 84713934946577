export default [
  {
    title: "Vastuunrajoitus",
    desc: [
      "Camera Compliance Ltd, sen kumppanit ja työntekijät eivät ole vastuussa mistään suorista, epäsuorista, sattumanvaraisista, erityisistä, seuraamuksellisista tai rankaisevista vahingoista, mukaan lukien taloudelliset menetykset, tietojen menetykset tai muut vahingot, jotka johtuvat tämän verkkosivuston käytöstä tai siihen luottamisesta tarjottuun tiedolliseen sisältöön.",
    ],
  },
  {
    title: "Ei Ammatillista Neuvontaa",
    desc: [
      "Tämä verkkosivusto tarjoaa tietoa, joka ei muodosta oikeudellista, teknistä tai muuta ammatillista neuvontaa. Vaikka pyrimme tarjoamaan tarkkaa ja ajantasaista tietoa, emme anna mitään takuita tai esityksiä minkäänlaisesta verkkosivuston tarkkuudesta, ajantasaisuudesta tai täydellisyydestä.",
      
      "Yksityiskohtaisen neuvon saamiseksi tilanteeseesi kannattaa kääntyä pätevien ammattilaisten puoleen, mukaan lukien mutta niihin rajoittumatta tietosuojaan ja GDPR-noudattamiseen erikoistuneet lakimiehet.",
    ],
  },
  {
    title: "Sovellettava Laki",
    desc: [
      "Tätä luopumusta säädellään ja tulkitaan sen toimivaltamaan lakien mukaisesti, jossa Camera Compliance Ltd toimii. Käyttämällä tätä verkkosivustoa suostut tällaisten tuomioistuinten toimivaltaan kaikissa tämän luopumuksen perusteella syntyvissä kanteissa, oikeudenkäynneissä tai muissa menettelyissä.",
    ],
  },
];