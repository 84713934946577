import React from "react";
import { styled } from "styled-components";
import { media } from "styles/themes/constants";

const AlterActionWrapper = ({ children, flexEnd }: any) => {
  return (
    <AlterActionWrapperStyled flexEnd={flexEnd}>
      {children}
    </AlterActionWrapperStyled>
  );
};

export default AlterActionWrapper;

const AlterActionWrapperStyled = styled.div<{ flexEnd: boolean }>`
  display: flex;
  justify-content: ${(props: any) =>
    props.flexEnd ? "flex-end" : "space-between"};
  margin: 0px 0px 10px;
  & > :first-child {
    margin-right: 12px;
  }
  @media only screen and ${media.sm} {
    justify-content: flex-end;
  }
`;
