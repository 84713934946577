import styled from "styled-components";
import { devices } from "utils/theme";

export const ChooseCameraStyled = styled.div`
  max-width: 1200px;
  margin: 0px auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
  flex-direction: column-reverse;
  padding: 0px 20px;

  @media (${devices.laptop}) {
    flex-direction: row;
    background: transparent;
    margin-bottom: 100px;
  }
  position: relative;

  & .left-contents {
    flex: 1;
    width: 100%;
    margin-left: 0px;
    position: relative;
    padding: 50px 0px;
    border-radius: 30px;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;

    @media (${devices.laptop}) {
      width: 520px;
      background: transparent;
      padding: 50px 20px 0px;
      margin-left: 20px;
    }

    & .title {
      color: #333333;
      margin: 0px 0px 30px;
      font-family: Poppins;
      font-size: 30px;
      font-style: normal;
      font-weight: 600;
      line-height: 40px;
      @media (${devices.mobileSM}) {
        line-height: 50px;
      }

      position: relative;

      &::before {
        content: "";
        position: absolute;
        left: 0px;
        bottom: 0px;
        width: 68px;
        height: 6px;
        background: var(--primary);
      }
    }
    & .description {
      color: #818181;
      margin: 0px 0px 30px;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      @media (${devices.mobileSM}) {
        line-height: 36px; /* 180% */
      }
    }
    & .btn {
      background-color: #167a8b;
      color: white;
      border-radius: 5px;
    }
    & .data {
      border-radius: 4px;
      background-color: #afeffa;
      color: #818181;
      margin-bottom: 20px;
      padding: 20px;
      max-width: 500px;

      & .data-title {
        color: #1e1e1f;
        font-size: 16px;
      }
      & .data-desc {
        color: #383838;
        font-weight: 400;
      }

      & .title-container {
        display: flex;
        gap: 8px;
        align-items: center;
        margin-bottom: 10px;

        img {
          width: 20px;
          height: 20px;
          color: black;
        }
      }
    }
  }

  & .right-contents {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
    @media (${devices.mobileSM}) {
      img {
        height: 100%;
        width: 70%;
      }
    }
    @media (${devices.laptop}) {
      width: 536px;

      img {
        height: 500px;
        width: 100%;
        opacity: 1;
      }
    }
  }
`;
