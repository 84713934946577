import dynamicQRImg from "../../Features/images/qr.png";
import stickerImg from "../../Features/images/sticker.png";
import publicPageImg from "../../Features/images/city-public-page.png";

export default [
  {
    img: dynamicQRImg,
    title: "QR-maa ja kaupunki",
    description:
      "Nykykaupungit vaativat nykyaikaisia ratkaisuja. Kameran noudattamisen avulla tuomme GDPR-noudattamisen julkisiin valvontajärjestelmiin suojaten henkilötiedot ja varmistaen täydellisen läpinäkyvyyden prosessissa.",
  },
  {
    img: stickerImg,
    title: "Tarra kentällä",
    description:
      "Tarroja on mahtavaa käyttää! Laita tylsät QR-koodit värikkäisiin tarroihin, jotta ne näyttävät houkuttelevilta.",
  },
  {
    img: publicPageImg,
    title: "Julkinen sivu kaupungeille",
    description:
      "Mukauta julkiset sivut tarpeidesi mukaan... Kaupunkien ylläpitäjät voivat mukauttaa ja lisätä eri kieliä kaupungin mukaan.",
  },
];
