import React from "react";
// import { healthChartData } from "constants/healthChartData";
import { BaseCard } from "components/common/BaseCard";
import { PieChartCustomLegend } from "components/common/charts/PieChartCustomLegend";
import { useTranslation } from "react-i18next";

export const DevicesAnalytics = ({ dashboardData }: any) => {
  const { t } = useTranslation();

  const ScannedDevice: any = [
    {
      value: dashboardData?.scanByAndroidDevice,
      name: t("dashboard.scan-by-android-device"),
      description: "This are the number of Android device use for scan",
    },
    {
      value: dashboardData?.scanByIOSDevice,
      name: t("dashboard.scan-by-IOS-device"),

      description: "This are the number of IOS device use for scan",
    },
    {
      value: dashboardData?.scanByOthersDevice,
      name: t("dashboard.scan-by-Other-device"),
      description: "This are the number of Other device use for scan",
    },
  ];

  const chartData = ScannedDevice.map((item: any) => ({
    ...item,
    name: item.name,
    description: item.description,
  }));

  const legendData = chartData.map((item: any) => ({
    ...item,
    value: `${item.value}`,
  }));

  return (
    <BaseCard
      title={t("dashboard.devices-analytics")}
      padding={"0 1.25rem 1.875rem"}
    >
      <PieChartCustomLegend
        name={"QR"}
        chartData={chartData}
        legendData={legendData}
        height="300px"
      />
    </BaseCard>
  );
};
