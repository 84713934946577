import { Button, Divider } from "antd";
import { BaseTypography } from "components/common/BaseTypography";
import useClickOutside from "hooks/useClickOutside";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AiFillCaretDown } from "react-icons/ai";
import { FaDownload } from "react-icons/fa";
import { convertSvgToPng, downloadFile } from "services/filesave";
import { DownloadOptionButtonStyled } from "./styled";
import {
  downloadMergedPNG,
  downloadMergedSVG,
  downloadQRPNG,
  downloadQRSVG,
} from "utils/svgHelpers";
import { StickerDimensionsType } from "components/StickerSelection/ViewQrSticker";

type Props = {
  sticker: StickerDimensionsType;
  frontendURL: string;
  filename?: string;
  downloadFor?: "sticker" | "qr";
};

const DownloadOptionButton = ({
  sticker,
  frontendURL,
  filename,
  downloadFor = "sticker",
}: Props) => {
  const { t } = useTranslation();
  const [showOptions, setShowOptions] = useState(false);
  const closeOptions = () => {
    setShowOptions(false);
  };

  const btnWrapperRef = useClickOutside(() => {
    closeOptions();
  });

  return (
    <DownloadOptionButtonStyled ref={btnWrapperRef} $showOptions={showOptions}>
      <div className="download-btn-wrapper">
        <Button
          type="link"
          className="download-btn"
          target="_blank"
          onClick={() => {
            if (!!sticker && !!frontendURL) {
              if (downloadFor === "qr") {
                downloadQRSVG(sticker, frontendURL);
              } else {
                downloadMergedSVG(sticker, frontendURL);
              }
            }
            closeOptions();
          }}
        >
          {t("dashboard.download")}
        </Button>
        <div
          className="type-selector"
          onClick={() => {
            setShowOptions(true);
          }}
        >
          <AiFillCaretDown className="icon" />
        </div>
        <div className="download-option-container">
          <div className="option">
            <BaseTypography.Text>{t("new.as-svg")}</BaseTypography.Text>
            <Button
              target="_blank"
              onClick={() => {
                if (!!sticker && !!frontendURL) {
                  if (downloadFor === "qr") {
                    downloadQRSVG(sticker, frontendURL);
                  } else {
                    downloadMergedSVG(sticker, frontendURL);
                  }
                }
                closeOptions();
              }}
            >
              <FaDownload color="var(--primary)" />
            </Button>
          </div>
          <Divider style={{ margin: "8px 0px" }} />
          <div className="option">
            <BaseTypography.Text>{t("new.as-png")}</BaseTypography.Text>
            <Button
              target="_blank"
              onClick={() => {
                if (!!sticker && !!frontendURL) {
                  if (downloadFor === "qr") {
                    downloadQRPNG(sticker, frontendURL);
                  } else {
                    downloadMergedPNG(sticker, frontendURL);
                  }
                }

                closeOptions();
              }}
            >
              <FaDownload color="var(--primary)" />
            </Button>
          </div>
        </div>
      </div>
    </DownloadOptionButtonStyled>
  );
};

export default DownloadOptionButton;
