import React from "react";
import { CloseCircleOutlined } from "@ant-design/icons";
import { useField } from "formik";
import BaseError from "../BaseError";
import { useResponsive } from "hooks/useResponsive";
import styled from "styled-components";
import { media } from "styles/themes/constants";
import { UploadOutlined } from "@ant-design/icons";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import store from "store";

const ImageUpload = ({
  setSelectedFile,
  setPreviewUrl,
  previewUrl,
  name,
  selectedFile,
  allowed,
}: any) => {
  const [field, meta, helpers] = useField(name);
  const { t } = useTranslation();
  const { touched, error } = meta;
  const { isTablet } = useResponsive();
  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    helpers.setValue(file);

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setPreviewUrl("");
    }
    event.target.value = "";
  };

  const handleRemove = () => {
    setSelectedFile(null);
    setPreviewUrl("");
    helpers.setValue("");
    store.store.dispatch(store?.Actions?.reset("stickerSelection"));
  };

  return (
    <div
      style={{
        display: "flex",
        // alignItems: "center",
        marginBottom: "8px",
        flexDirection: isTablet ? "row" : "column",
      }}
    >
      {!selectedFile && (
        <FileUploaderWrapper>
          <div className="drop-area">
            <input
              type="file"
              onChange={handleFileChange}
              accept={allowed}
              id="fileElem"
            />
          </div>
          <label className="upload-label" htmlFor="fileElem">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <UploadOutlined style={{ fontSize: "50px" }} />
              <div className="upload-text">
                {t("remain.click-to-upload")} SVG
              </div>
              <div className="upload-small-text">
                {t("remain.upload-svg-file-format-for-sticker")}
              </div>
            </div>
          </label>
        </FileUploaderWrapper>
      )}
      {previewUrl && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            position: "relative",
            width: "fit-content",
          }}
        >
          <img
            src={previewUrl}
            alt="Preview"
            style={{
              maxWidth: "150px",
              margin: "0px 0px 0px 5px",
              height: "80px",
            }}
          />
          <CloseCircleOutlined
            onClick={handleRemove}
            style={{
              marginLeft: "10px",
              cursor: "pointer",
              position: "absolute",
              top: "0px",
              right: "0px",
              transform: "translate(50%,-50%)",
              color: "red",
              fontSize: "20px",
            }}
          />
        </div>
      )}
      {error && <BaseError>{error}</BaseError>}
    </div>
  );
};

export default ImageUpload;

const FileUploaderWrapper = styled.div`
  width: 200px;
  height: 200px;
  margin: 20px auto;
  border: 3px dotted var(--border);
  @media ${media.md} {
    width: 300px;
    height: 300px;
  }
  #fileElem {
    display: none;
  }
  .upload-label {
    padding: 10px;
    cursor: pointer;
    border-radius: 6px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f6f6f6;
    transition: 0.3s;
  }
  .upload-label:hover {
    background-color: #e9e9e9;
  }
  .upload-label:active {
    background-color: #f6f6f6;
  }
  .upload-text {
    font-size: 16px;
    user-select: none;
  }
  .upload-small-text {
    font-size: 12px;
    color: #666;
  }
`;
