const operators = {
  addOperator: (data: any): any => {
    return {
      url: "auth/registerOperator",
      method: "post",
      data,
    };
  },

  getAllOperator: (action, params): any => {
    return {
      url: "auth/getAllOperators",
      method: "get",
      params: params,
      config: {
        store: {
          action: action,
          key: "operator.data",
        },
      },
    };
  },
  getOperatorInfo: (id: string): any => {
    return {
      url: `auth/getOperator/${id}`,
      method: "get",
    };
  },
  getOperatorActivity: ({ operatorId, params }: operatorActivityArgsType) => {
    return {
      url: `auth/getOperatorActivityById/${operatorId}`,
      method: "get",
      params: params,
      config: {
        store: {
          action: params?.page === 0 ? "set" : "update",
          key: "operatorActivity.data",
        },
      },
    };
  },

  blockOperator: (operatorId: string) => {
    return {
      url: `auth/blockOperator/${operatorId}`,
      method: "post",
    };
  },
  unBlockOperator: (operatorId: string) => {
    return {
      url: `auth/unBlockOperator/${operatorId}`,
      method: "post",
    };
  },
};

export default operators;

type operatorActivityArgsType = {
  operatorId: string;
  params?: IParams;
};
interface IParams {
  limit: number;
  page: number;
}
