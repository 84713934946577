import dynamicQRImg from "../../Features/images/qr.png";
import stickerImg from "../../Features/images/sticker.png";
import publicPageImg from "../../Features/images/city-public-page.png";

export default [
  {
    img: dynamicQRImg,
    title: "Paese e città in QR Code",
    description:
      "Le città moderne richiedono soluzioni moderne. Con la Conformità della Fotocamera, portiamo la conformità GDPR ai sistemi di sorveglianza pubblica, proteggendo i dati personali e garantendo completa trasparenza nel processo.",
  },
  {
    img: stickerImg,
    title: "Adesivo con campo",
    description:
      "Gli adesivi sono fantastici! Posiziona i tuoi QR code noiosi negli adesivi colorati per dar loro un aspetto accattivante.",
  },
  {
    img: publicPageImg,
    title: "Pagina Pubblica per le Città",
    description:
      "Personalizza le tue pagine pubbliche secondo le tue esigenze... Gli amministratori della città possono personalizzarle e aggiungere diverse lingue in base alla città.",
  },
];