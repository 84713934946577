import { SET, RESET, UPDATE, RESET_REDUX, REMOVE } from "./constants";
import initialState from "./initialState";

export function set(key: any, data: any) {
  return {
    type: SET,
    data,
    key,
  };
}

export function reset(key: any) {
  return {
    type: RESET,
    data: null,
    key,
  };
}

export function update(key: any, data: any) {
  return {
    type: UPDATE,
    data,
    key,
  };
}

export function remove(key: any, id: string) {
  return {
    type: REMOVE,
    id,
    key,
  };
}

export function resetRedux() {
  return {
    type: RESET_REDUX,
  };
}
