import { useState } from "react";
import { Modal, Select } from "antd";
import { useTranslation } from "react-i18next";
import { BaseTypography } from "components/common/BaseTypography";
import { BaseSelect } from "components/common/BaseSelect";
import BaseButton from "components/common/BaseButton";
import { ButtonFlexEnd } from "utils/styles";
import { LANGUAGES } from "constants/countries";
import { useLanguage } from "hooks/useLanguage";
const { Option } = Select;

const LanguageSelectionModal = ({
  showLanguageModal,
  tabItems,
  handleCloseModal,
  handleConfirmLanguage,
}) => {
  const { t } = useTranslation();
  const { language, setLanguage } = useLanguage();
  const languageOptions = LANGUAGES[language];

  const handleSelection = (val) => {
    handleConfirmLanguage(val);
    handleCloseModal();
  };
  return (
    <Modal open={showLanguageModal} onCancel={handleCloseModal} footer={null}>
      <BaseSelect
        label={t("editCity.selectLanguage")}
        style={{
          width: "100%",
          marginTop: "10px",
        }}
        name={"cityId"}
        placeholder={t("editCity.selectLanguage")}
        children={
          <>
            {languageOptions?.map((lang) => (
              <Option
                disabled={tabItems.includes(lang.value)}
                key={lang.value}
                label={lang.label}
              >
                <span
                  style={{
                    fontFamily: "Twemoji Country Flags",
                    marginRight: "8px",
                  }}
                >
                  {lang.flag}
                </span>{" "}
                {lang.languageFullName}
              </Option>
            ))}
          </>
        }
        onSelect={handleSelection}
      />
    </Modal>
  );
};
export default LanguageSelectionModal;
