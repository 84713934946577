import { DownloadOutlined } from "@ant-design/icons";
import { Button, Divider } from "antd";
import BaseButton from "components/common/BaseButton";
import { BaseTypography } from "components/common/BaseTypography";
import useClickOutside from "hooks/useClickOutside";
import JSZip from "jszip";
import { PDFDocument } from "pdf-lib";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AiFillCaretDown } from "react-icons/ai";
import { FaDownload } from "react-icons/fa";
import { styled } from "styled-components";

import {
  FlexEnd,
  flexColumnJustifyAlignCenter,
  flexRowAlignCenterJustifySpaceBetween,
} from "utils/styles";
import { downloadMergedPNG, downloadMergedSVG } from "utils/svgHelpers";

const ImageDownloader = ({
  downloadData,
  batchCount,
}: {
  downloadData: any;
  batchCount: any;
}) => {
  const { t } = useTranslation();
  const [showOptions, setShowOptions] = useState(false);

  const closeOptions = () => {
    setShowOptions(false);
  };

  const btnWrapperRef = useClickOutside(() => {
    closeOptions();
  });

  const downloadImages = async ({
    type = "svg",
  }: {
    type: "all" | "svg" | "png" | "pdf";
  }) => {
    const zip = new JSZip();

    const downloadPromises = downloadData.map(async (url, index) => {
      try {
        if (type === "all") {
          const actualURLSVG = await downloadMergedSVG(
            url?.sticker,
            url?.frontendURL,
            true
          );
          const response = await fetch(actualURLSVG);
          const actualURLPNG = await downloadMergedPNG(
            url?.sticker,
            url?.frontendURL,
            true
          );
          const responsePNG = await fetch(actualURLPNG);
          const blob = await response.blob();
          const blobPNG = await responsePNG.blob();
          zip.file(`image_${index + 1}.svg`, blob);
          zip.file(`image_${index + 1}.png`, blobPNG);
        }
        if (type === "svg") {
          const actualURL = await downloadMergedSVG(
            url?.sticker,
            url?.frontendURL,
            true
          );
          const response = await fetch(actualURL);
          const blob = await response.blob();
          zip.file(`image_${index + 1}.svg`, blob);
        }
        if (type === "png") {
          const actualURL = await downloadMergedPNG(
            url?.sticker,
            url?.frontendURL,
            true
          );
          const responsePNG = await fetch(actualURL);
          const blobPNG = await responsePNG.blob();
          zip.file(`image_${index + 1}.png`, blobPNG);
        }
      } catch (error) {
        console.error("Error downloading image", error);
      }
    });

    await Promise.all(downloadPromises);

    const currentDate = new Date();
    const zipFileName = `images_${currentDate.toLocaleString()}.zip`;

    zip.generateAsync({ type: "blob" }).then((zipBlob) => {
      const zipLink = document.createElement("a");
      zipLink.href = URL.createObjectURL(zipBlob);
      zipLink.download = zipFileName;
      zipLink.click();
    });
  };

  const downloadPdfImages = async () => {
    try {
      const pdfDoc = await PDFDocument.create();

      const downloadPromises = downloadData.map(async (url, index) => {
        try {
          const actualURL = await downloadMergedPNG(
            url?.sticker,
            url?.frontendURL,
            true
          );
          const response = await fetch(actualURL);
          const blob = await response.blob();

          // Convert Blob to Uint8Array
          const arrayBuffer = await blob.arrayBuffer();
          const uint8Array = new Uint8Array(arrayBuffer);

          // Create a new page with the image
          const pngImage = await pdfDoc.embedPng(uint8Array);
          const page = pdfDoc.addPage([pngImage.width, pngImage.height]);
          const { width, height } = page.getSize();
          page.drawImage(pngImage, {
            x: 0,
            y: 0,
            width,
            height,
          });
        } catch (error) {
          console.error("Error downloading image", error);
        }
      });

      await Promise.all(downloadPromises);

      // Serialize the PDF to bytes
      const pdfBytes = await pdfDoc.save();

      const zip = new JSZip();
      zip.file("images.pdf", pdfBytes);

      const currentDate = new Date();
      const zipFileName = `images_${currentDate.toLocaleString()}.zip`;

      // Generate the ZIP file containing the PDF
      zip.generateAsync({ type: "blob" }).then((zipBlob) => {
        const zipLink = document.createElement("a");
        zipLink.href = URL.createObjectURL(zipBlob);
        zipLink.download = zipFileName;
        zipLink.click();
      });
    } catch (error) {
      console.error("Error creating PDF and ZIP file", error);
    }
  };

  return (
    <FlexEnd style={{ margin: "10px 0px" }}>
      <ButtonContainerStyled ref={btnWrapperRef} showOptions={showOptions}>
        <div className="download-btn-wrapper">
          <BaseButton
            style={{ border: "none", borderRadius: "0px", boxShadow: "none" }}
            onClick={() => downloadImages({ type: "svg" })}
          >
            <DownloadOutlined />
            <DownloadText>
              Download All
              <BatchCountStyle> ({batchCount})</BatchCountStyle>
            </DownloadText>
          </BaseButton>
          <div
            className="type-selector"
            onClick={() => {
              setShowOptions(true);
            }}
          >
            <AiFillCaretDown className="icon" />
          </div>
        </div>
        <div className="download-option-container">
          <div className="option">
            <BaseTypography.Text>{t("new.as-svg")}</BaseTypography.Text>
            <Button
              target="_blank"
              onClick={() => {
                downloadImages({ type: "svg" });
                closeOptions();
              }}
            >
              <FaDownload color="var(--primary)" />
            </Button>
          </div>
          <Divider style={{ margin: "8px 0px" }} />
          <div className="option">
            <BaseTypography.Text>{t("new.as-png")}</BaseTypography.Text>
            <Button
              target="_blank"
              onClick={() => {
                downloadImages({ type: "png" });
                closeOptions();
              }}
            >
              <FaDownload color="var(--primary)" />
            </Button>
          </div>
          <Divider style={{ margin: "8px 0px" }} />
          <div className="option">
            <BaseTypography.Text>{t("new.as-pdf")}</BaseTypography.Text>
            <Button
              target="_blank"
              onClick={() => {
                downloadPdfImages();
                closeOptions();
              }}
            >
              <FaDownload color="var(--primary)" />
            </Button>
          </div>
        </div>
      </ButtonContainerStyled>
    </FlexEnd>
  );
};

export default ImageDownloader;

const DownloadText = styled.span`
  font-size: 12px;
  font-weight: 500;
`;
const BatchCountStyle = styled.span`
  color: var(--primary);
  font-size: 14px;
`;

const ButtonContainerStyled = styled.div<{ showOptions?: boolean }>`
  position: relative;
  & .download-btn-wrapper {
    border-radius: 6px;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
    overflow: hidden;
    display: flex;
    align-items: center;
    height: 32px;

    & .type-selector {
      background: #fff;
      height: 100%;
      cursor: pointer;
      padding: 0px 5px;
      ${flexColumnJustifyAlignCenter} & .icon {
        color: var(--primary);
      }
    }
  }

  & .download-option-container {
    display: ${({ showOptions }) => (showOptions ? "block" : "none")};
    position: absolute;
    top: 40px;
    z-index: 100;
    right: 0px;
    left: 0px;
    background: #fcfcfc;
    box-shadow: 0px 0px 10px 0px #dbdbdb;
    padding: 10px;
    & .option {
      ${flexRowAlignCenterJustifySpaceBetween}
    }
  }
`;
