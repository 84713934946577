import { styled } from "styled-components";
import { devices } from "utils/theme";

export const FeatureWrapper = styled.div<{
  flexDirection: string;
}>`
  display: flex;
  background-color: ${(props) =>
    props.flexDirection === "row" ? "#fff" : "#efefef"};

  @media (${devices.tablet}) {
    min-height: 70vh;
  }
`;

export const Container = styled.div<{ flexDirection: string }>`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  padding: 30px;
  align-self: center;

  @media (${devices.tablet}) {
    flex-direction: ${(props) => props.flexDirection};
    padding: 50px;
  }
`;

export const Content = styled.div`
  width: 100%;
  text-align: center;

  @media (${devices.tablet}) {
    width: 40%;
    text-align: left;
  }
`;

export const Image = styled.img`
  /* width: 100%; */
  /* object-fit: contain; */
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  height: 450px;

  @media (${devices.tablet}) {
    max-width: 60%;
  }
`;

// export const FeatureWrapper = styled.div`
//   position: relative;

//   display: flex;
//   justify-content: center;
//   padding: 80px 100px;

//   & .container {
//     position: relative;
//     max-width: 1200px;

//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;

//     & .main-title {
//       font-size: 3em;
//       margin-bottom: 50px;

//       color: black;
//     }

//     & .content {
//       position: relative;
//       width: 100%;

//       display: flex;
//       justify-content: center;
//       align-items: center;

//       & .image {
//         position: relative;
//         width: 800px;
//         height: 400px;
//         overflow: hidden;

//         & .img {
//           z-index: 111;
//           position: absolute;
//           top: 0;
//           left: 0;
//           width: 100%;
//           height: 100%;
//           object-fit: cover;
//           border-radius: 10px;
//         }
//       }

//       & .information {
//         width: 700px;
//         margin-left: 50px;
//         & .info {

//           color: black;
//           align-items: center;
//           justify-content: center;
//         }
//       }

//       & .text-box {
//         z-index: 222;
//         background: rgba(255, 255, 255, 0.1);
//         backdrop-filter: blur(20px);
//         padding: 40px;
//         max-width: 400px;
//         border: 1px solid rgba(255, 255, 255, 0.1);
//         border-radius: 10px;

//         height: 400px;
//         transform: translate(-60px, 35px);

//         & .desc {
//           color: white;
//           color: black;
//           align-items: center;
//           justify-content: center;
//         }
//       }
//     }
//     & .media-icons {
//       margin-top: 100px;

//       & .a {
//         color: #fff;
//         font-size: 2em;
//         margin: 60px;
//       }
//     }
//   }
// `;
