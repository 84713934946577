import { UrlLink } from "utils/styles";
import * as Styles from "./index.styles";
import * as S from "../index.styles";
import { Image, Typography } from "antd";
import { BaseTag } from "components/common/BaseTag";
import BaseButton from "components/common/BaseButton";
import { FaEdit, FaPalette, FaTrashAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { QR_DASHBOARD_MAIN_PATH } from "constants/routes/route";

interface ILanguageOptions {
  flag: string;
  label: string;
  languageFullName: string;
  value: string;
}

interface PublicPageTableRowCardProps {
  banner: string;
  pageTitle: string;
  languages: string[];
  languageOptions: ILanguageOptions[];
  onEditClick: () => void;
  onDeleteClick: () => void;
  onViewClick: (e) => void;
  onCustomize: (e) => void;
}

const PublicPageTableRowCard = ({
  banner,
  pageTitle,
  languages,
  languageOptions,
  onEditClick,
  onDeleteClick,
  onViewClick,
  onCustomize,
}: PublicPageTableRowCardProps) => {
  const selectedLanguage = languageOptions.filter((option) =>
    languages.includes(option.value)
  );
  const navigate = useNavigate();
  return (
    <Styles.CardWrapper onClick={onViewClick}>
      {!banner ? (
        <div
          style={{
            height: "60px",
            width: "100px",
            border: "1px solid #dbdbdb",
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "6px",
            background: "#fff",
          }}
        >
          <Typography.Text
            style={{ fontSize: 12, fontWeight: 600 }}
            type="secondary"
          >
            No Banner
          </Typography.Text>
        </div>
      ) : (
        <Image
          alt="sticker"
          src={banner}
          style={{
            width: "80px",
            aspectRatio: "1",
            backgroundColor: "#fff",
            padding: "4px",
          }}
          preview={{
            src: banner,
          }}
        />
      )}
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{ fontSize: "16px", fontWeight: "600", marginBottom: "6px" }}
        >
          {pageTitle}
        </div>
        <UrlLink onClick={() => window.open(banner)}>{banner}</UrlLink>
        <div style={{ marginTop: "8px" }}>
          <S.LanguagesRow>
            {selectedLanguage.map((language) => (
              <BaseTag>
                <S.LanguageContainer key={language.value}>
                  <S.Flag>{language.flag}</S.Flag>
                  <S.LanguageValue>{language.value}</S.LanguageValue>
                </S.LanguageContainer>
              </BaseTag>
            ))}
          </S.LanguagesRow>
        </div>
        <div style={{ marginTop: "8px" }}>
          <BaseButton
            type="link"
            onClick={onEditClick}
            style={{ padding: "0px", height: "20px" }}
          >
            <FaEdit
              style={{
                height: "20px",
                width: "20px",
                color: "var(--skyblue)",
              }}
            />
          </BaseButton>
          <BaseButton
            onClick={onDeleteClick}
            type="link"
            style={{ marginLeft: "10px", padding: "0px", height: "20px" }}
          >
            <FaTrashAlt
              style={{ height: "20px", width: "20px", color: "var(--red)" }}
            />
          </BaseButton>
          <BaseButton
            type="link"
            style={{ marginLeft: "15px", padding: "0px" }}
            onClick={onCustomize}
          >
            <FaPalette
              style={{
                height: "24px",
                width: "24px",
                color: "var(--skyblue)",
              }}
            />
          </BaseButton>
        </div>
      </div>
    </Styles.CardWrapper>
  );
};

export default PublicPageTableRowCard;
