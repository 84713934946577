export default [
  {
    title: "Begränsning av Ansvar",
    desc: [
      "Camera Compliance Ltd, dess dotterbolag, partners och anställda ska inte vara ansvariga för direkta, indirekta, incidentella, särskilda, följd- eller straffskador, inklusive, utan begränsning, ekonomisk förlust, förlust av data eller andra skador som uppstår som ett resultat av din användning av denna webbplats eller förlitande på den information som tillhandahålls.",
    ],
  },
  {
    title: "Inget Professionellt Råd",
    desc: [
      "Informationen som tillhandahålls på denna webbplats utgör inte juridisk, teknisk eller annan professionell rådgivning. Även om vi strävar efter att tillhandahålla korrekt och aktuell information ger vi inga garantier eller representationer av något slag angående webbplatsens noggrannhet, aktualitet eller fullständighet.",

      "För detaljerad rådgivning anpassad till din situation bör du konsultera kvalificerade professionella, inklusive, men inte begränsat till, jurister med erfarenhet inom dataskydd och efterlevnad av GDPR.",
    ],
  },
  {
    title: "Tillämplig Lag",
    desc: [
      "Denna avståelse ska styras av och tolkas i enlighet med lagarna i den jurisdiktion där Camera Compliance Ltd verkar. Genom att använda denna webbplats samtycker du till denna jurisdiktion för eventuella handlingar, stämningar eller förfaranden som härrör från denna avståelse.",
    ],
  },
];
