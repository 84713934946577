export default [
  {
    title: "Limitarea Răspunderii",
    desc: [
      "Camera Compliance Ltd, afiliații săi, partenerii și angajații nu vor fi responsabili pentru daune directe, indirecte, accidentale, speciale, consecințiale sau punitive, inclusiv, fără limitare, pierderi financiare, pierderi de date sau orice alte daune rezultate din utilizarea acestui site web sau din încrederea acordată conținutului informativ furnizat.",
    ],
  },
  {
    title: "Fără Consultanță Profesională",
    desc: [
      "Informațiile furnizate pe acest site web nu constituie consultanță legală, tehnică sau profesională. Cu toate că ne străduim să furnizăm informații precise și actualizate, nu facem nicio garanție sau reprezentare de orice fel cu privire la exactitatea, actualitatea sau completitudinea site-ului web.",

      "Pentru a obține sfaturi detaliate adaptate situației dvs., consultați profesioniști calificați, inclusiv, dar fără a se limita la, profesioniști în domeniul juridic cu experiență în protecția datelor și conformitatea cu GDPR.",
    ],
  },
  {
    title: "Legea Aplicabilă",
    desc: [
      "Această renunțare va fi guvernată și interpretată în conformitate cu legile jurisdicției în care Camera Compliance Ltd își desfășoară activitatea. Prin utilizarea acestui site web, vă exprimați acordul cu privire la jurisdicția acestor tribunale pentru orice acțiuni, procese sau proceduri rezultate din această renunțare.",
    ],
  },
];
