import { Typography } from "antd";
import React, { ReactNode } from "react";

import * as S from "./index.styles";
import { ReactChildrenType } from "../../utils/interfaces";
import BaseButton from "../common/BaseButton";

const HeaderWrapper = ({
  title,
  actionTitle,
  handleAction,
}: IHeaderWrapper) => {
  return (
    <S.HeaderWrapper>
      <Typography.Title level={5}>{title}</Typography.Title>
      {actionTitle && (
        <BaseButton
          onClick={() => {
            handleAction && handleAction();
          }}
          style={{ background: "var(--primary)", color: "#fff" }}
        >
          {actionTitle}
        </BaseButton>
      )}
    </S.HeaderWrapper>
  );
};

export default HeaderWrapper;

interface IHeaderWrapper {
  title: string;
  handleAction?: () => void;
  actionTitle?: string;
}
