const dashboardApi = {
  dashboard: () => {
    return {
      url: "common/dashboard",
      method: "get",
      config: {
        store: {
          action: "set",
          key: "dashboard",
        },
      },
    };
  },
};
export default dashboardApi;
