import { Typography } from "antd";
import React from "react";

import { sectionData } from "./sectionData";
//@ts-ignore
import { useTranslation } from "react-i18next";
import { SolutionContainer } from "./style";

const Solutions = () => {
  const { t } = useTranslation();
  return (
    <SolutionContainer>
      <Typography.Title level={4} className="title">
        {/* {t("new.unique-features")} */}
        Our Core Solutions
      </Typography.Title>

      <div className="main">
        {sectionData?.map((section) => {
          const { img, title, description } = section;
          return (
            <div className="container" key={section.id}>
              <div className="image-container">
                <img src={img} className="image" />
              </div>
              <Typography.Title level={5} className="container-title">
                {title}
              </Typography.Title>
              <ul className="description-list">
                {description.map((desc, index) => (
                  <li key={index}>
                    <Typography.Text>-</Typography.Text>
                    <Typography className="text">{desc}</Typography>
                  </li>
                ))}
              </ul>{" "}
            </div>
          );
        })}
      </div>
    </SolutionContainer>
  );
};

export default Solutions;
