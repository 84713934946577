import { convertTimestamp } from "utils/utils";
import * as Styles from "./index.styles";
import BaseButton from "components/common/BaseButton";
import { FaEdit, FaEye, FaTrashAlt } from "react-icons/fa";
import { TbLock, TbLockOpen } from "react-icons/tb";

interface CameraTableRowCardProps {
  address: string;
  serialNumber: string;
  createdDate: string;
  isCreateAutomatic: boolean;
  onEditClick: (e) => void;
  onDeleteClick: (e) => void;
  onUnblockClick: (e) => void;
  onRowClick: (e) => void;
  isArchive: boolean;
  isUsed: boolean;
  onViewClick: (e) => void;
}

const CameraTableRowCard = ({
  address,
  serialNumber,
  createdDate,
  isCreateAutomatic,
  onEditClick,
  onDeleteClick,
  onUnblockClick,
  onRowClick,
  isArchive,
  isUsed,
  onViewClick,
}: CameraTableRowCardProps) => {
  return (
    <Styles.CardWrapper onClick={onViewClick}>
      <div>
        <div
          style={{ fontSize: "16px", fontWeight: "600", marginBottom: "6px" }}
        >
          {address}
        </div>
        <div style={{ fontSize: "14px", fontWeight: "400" }}>
          Serial number: {serialNumber}
        </div>
        <div
          style={{ fontSize: "12px", fontWeight: "400", marginBottom: "6px" }}
        >
          Created on {convertTimestamp(createdDate)}
        </div>
        <BaseButton
          onClick={onRowClick}
          style={{ padding: "0px", height: "20px", marginRight: "15px" }}
          type="link"
        >
          <FaEye
            style={{
              height: "24px",
              width: "24px",
              color: "var(--primary)",
            }}
          />
        </BaseButton>
        <BaseButton
          type="link"
          onClick={onEditClick}
          style={{ padding: "0px", height: "20px" }}
        >
          <FaEdit
            style={{ height: "20px", width: "20px", color: "var(--skyblue)" }}
          />
        </BaseButton>
        {/* {!isCreateAutomatic && (
          <BaseButton
            onClick={onDeleteClick}
            type="link"
            style={{ marginLeft: " 10px", padding: "0px", height: "20px" }}
          >
            <FaTrashAlt
              style={{ height: "20px", width: "20px", color: "var(--red)" }}
            />
          </BaseButton>
        )} */}
        {isUsed ? (
          isArchive ? (
            <BaseButton
              onClick={onUnblockClick}
              style={{ marginLeft: " 10px", padding: "0px" }}
              type="link"
            >
              <TbLockOpen
                style={{
                  height: "24px",
                  width: "24px",
                  color: "var(--tairo)",
                }}
              />
            </BaseButton>
          ) : (
            <BaseButton
              onClick={onDeleteClick}
              style={{ marginLeft: " 10px", padding: "0px" }}
              type="link"
            >
              <TbLock
                style={{
                  height: "24px",
                  width: "24px",
                  color: "var(--orange)",
                }}
              />
            </BaseButton>
          )
        ) : (
          <BaseButton
            onClick={onDeleteClick}
            style={{ marginLeft: " 10px", padding: "0px" }}
            type="link"
          >
            <FaTrashAlt
              style={{ height: "24px", width: "24px", color: "var(--red)" }}
            />
          </BaseButton>
        )}
      </div>
    </Styles.CardWrapper>
  );
};

export default CameraTableRowCard;
