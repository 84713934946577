import Header from "containers/Home/Header";
import * as Styles from "./index.styles";
import CityAdminFeatures from "./CityAdminFeatures";
import OperatorFeatures from "./OperatorFeatures";
import CustomPublicPage from "./CustomPublicPage";
import Footer from "containers/Terms/Footer";
import FeaturesHero from "./FeaturesHero";

const Features = () => {
  return (
    <Styles.FeaturesPageWrapper>
      <Header />
      <div style={{ marginTop: "61px" }}>
        <FeaturesHero />
      </div>
      <CityAdminFeatures />
      <OperatorFeatures />
      <CustomPublicPage />
      <Footer />
    </Styles.FeaturesPageWrapper>
  );
};

export default Features;
