import axios from "axios";
import { deleteUser, persistUser } from "../services/localStorage";
import Store from "../store";
import { getActionKeys, hasSubKeyInAction } from "./utils";
import { globalNavigate } from "router/AppRouter";

export const handleLogout = async () => {
  globalNavigate("/logout");
};

export const handleLogin = async (user: any) => {
  await persistUser(user);
  axios.defaults.headers.Authorization = `Bearer ${user.token}`;
};

const handleLoader = (key, data) => {
  // const [mainKey] = getActionKeys(key);
  // const loaderKey = `${mainKey}.loader`;
  if (!hasSubKeyInAction(key)) {
    Store.store.dispatch(Store.Actions["update"]("loading", { [key]: data }));
  }
};

const request = async (config: any, isDebug = false) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const redux = config?.config;
  try {
    if (redux?.store?.key) {
      handleLoader(redux?.store?.key, true);
      // Store.store.dispatch(
      //   Store.Actions["update"]("loading", { [redux.store.key]: true })
      // );
      // setTimeout(() => {
      //   Store.store.dispatch(
      //     Store.Actions["update"]("loading", { [redux.store.key]: false })
      //   );
      // }, 20000);
    }

    const res = await axios({
      ...config,
      url: API_URL + config.url,
    });

    if (res.status === 401 && res.data.message === "jwt expired") {
      handleLogout();
    }
    if (res.status >= 200 && res.status < 300) {
      const data = res?.data?.data || res?.data || res;

      // if (config?.config?.store) {
      //   // const redux = config?.config;
      //   if (redux?.store?.loading && redux.store.action === "set") {
      //     Store.store.dispatch(Store.Actions["startLoading"](redux.store.key));
      //     setTimeout(() => {
      //       Store.store.dispatch(Store.Actions["stopLoading"](redux.store.key));
      //     }, 800);
      //   }
      //   Store.store.dispatch(
      //     Store.Actions[redux.store.action](redux.store.key, data)
      //   );
      // }
      if (redux?.store?.key) {
        Store.store.dispatch(
          Store.Actions[redux.store.action](redux.store.key, data)
        );
        handleLoader(redux?.store?.key, false);
      }
      if (res.data.status === "error") {
        throw res;
      } else {
        return data;
      }
    }
  } catch (err: any) {
    console.log(err.response);

    try {
      if (err.response.data.message === "jwt expired") {
        handleLogout();
      }
      if (redux?.store?.key) {
        handleLoader(redux?.store?.key, false);
      }
    } catch (err) {}

    try {
      isDebug && console.log(err.response.data);
    } catch (err) {}
    if (err && err.response && err.response.data && err.response.data.message) {
      throw err.response.data.message;
    }
    if (err && err.response && err.response.data && err.response.data.error) {
      throw err.response.data.error;
    }
    throw err.message || err;
  }
};

export default request;
