import {
  Col,
  Divider,
  Image,
  Modal,
  Row,
  Spin,
  Alert,
  Space,
  Button,
  Typography,
} from "antd";
import { GoBack } from "components/GoBack";
import BaseButton from "components/common/BaseButton";
import BaseInputs from "components/common/BaseInput/BaseInputs";
import { BaseTypography } from "components/common/BaseTypography";
import S3uploaderMessage from "components/common/S3presigned";
import Leaflet from "containers/City/SingleCity/Leaflet";
import { Field, Form, Formik, FormikProps, FormikValues } from "formik";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import cameraApi from "shared/front/apis/camera";
import store from "store";
import request from "utils/request";
import { FlexColumnAlignCenter, FlexEnd } from "utils/styles";
import { fullConvertTimestamp } from "utils/utils";
import * as Yup from "yup";
import { ViewCameraStyled } from "./styles";
import { PlusOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { media } from "styles/themes/constants";
import { devices } from "utils/theme";

type Props = {};

const ViewCamera = (props: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [addingImage, setAddingImage] = useState(false);
  const { cameraId } = useParams();
  const [enableAddCamera, setEnableAddCamera] = useState(false);
  const formikRef = useRef<FormikProps<FormikValues>>();

  const cameraDetails = useSelector((state: any) => state.cameraDetails);
  const dispatch = useDispatch();

  const cameraDetailsToShow = [
    {
      id: 1,
      label: t("dashboard.name"),
      value: cameraDetails?.name,
    },
    {
      id: 2,
      label: t("dashboard.address"),
      value: cameraDetails?.address,
    },
    {
      id: 3,
      label: t("dashboard.longitude"),
      value: cameraDetails?.longitude,
    },
    {
      id: 4,
      label: t("dashboard.latitude"),
      value: cameraDetails?.latitude,
    },
    {
      id: 5,
      label: t("remain.created-by"),
      value: cameraDetails?.isCreateAutomatic ? "System" : "Admin",
    },
    {
      id: 5,
      label: t("dashboard.created-date"),
      value: fullConvertTimestamp(cameraDetails?.createdAt),
    },
  ];

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(t("remain.image-title-validate")),
    image: Yup.string().required(t("remain.image-url")),
    description: Yup.string().required(t("remain.image-description-validate")),
  });
  useEffect(() => {
    if (!!cameraId) {
      getCameraDetails(cameraId);
    }
  }, [cameraId]);

  const getCameraDetails = async (cId) => {
    try {
      setLoading(true);
      const response = await request(cameraApi.getCameraDetails(cId));
      return response;
    } catch (error) {
      console.log("Error Fetching Camera:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleEnableAddImages = () => setEnableAddCamera(true);
  const handleCloseAddImages = () => setEnableAddCamera(false);

  const handleAddImages = async (values) => {
    setAddingImage(true);
    try {
      const finalData = [
        {
          URL: values?.image,
          label: values?.title,
          description: values?.description,
        },
      ];

      const response = await request(cameraApi.addImages(cameraId, finalData));
      if (response) {
        dispatch(store.Actions.set("cameraDetails", response));
        toast.success(
          `${t("remain.added")} ${values?.title} ${t(
            "remain.image-successfully"
          )}`
        );
        formikRef.current.handleReset();
      }
      handleCloseAddImages();
    } catch (error) {
      console.log("Add camera image:", error);
      if (typeof error === "string") {
        toast.error(error);
      }
    } finally {
      setAddingImage(false);
    }
  };

  const renderDetails = (label, value, key) => {
    return (
      <div className="camera-row" key={key}>
        <BaseTypography.Text className="title">{label}</BaseTypography.Text>
        <BaseTypography.Text className="value">{value}</BaseTypography.Text>
      </div>
    );
  };

  return (
    <>
      <GoBack />

      {cameraDetails?.images?.length === 0 && (
        <Alert
          style={{ marginBottom: "20px" }}
          message={t("remain.no-images-added-yet")}
          type="error"
          action={
            <Space>
              <Button
                type="text"
                size="small"
                ghost
                onClick={handleEnableAddImages}
                style={{
                  background: "var(--primary)",
                  color: "white",
                  height: "30px",
                }}
              >
                {t("remain.add-image")}
              </Button>
            </Space>
          }
          closable
        />
      )}

      <ViewCameraStyled>
        <BaseTypography.Title level={4}>
          {t("remain.camera-details")}
        </BaseTypography.Title>
        <Divider style={{ margin: "0px 0px 20px" }} />
        {loading ? (
          <FlexColumnAlignCenter>
            <Spin size="large" />
            <BaseTypography.Text>
              {t("remain.getting-camera-details")}
            </BaseTypography.Text>
          </FlexColumnAlignCenter>
        ) : (
          <>
            <Row style={{ marginBottom: "50px" }}>
              <Col xs={24} lg={12}>
                {cameraDetailsToShow?.map((cam) =>
                  renderDetails(cam?.label, cam?.value, cam.id)
                )}
              </Col>
              <Col xs={24} lg={12}>
                {cameraDetails?._id && (
                  <Leaflet
                    QRCodeDetails={[
                      {
                        ...cameraDetails,
                      },
                    ]}
                  />
                )}
              </Col>
            </Row>

            <div className="add-camera-title-wrapper">
              <BaseTypography.Title level={5} style={{ margin: "0px 0px" }}>
                {t("remain.camera-images")}
              </BaseTypography.Title>
              <BaseButton
                onClick={handleEnableAddImages}
                type="primary"
                style={{ background: "var(--primary)" }}
              >
                {t("remain.add-image")}
              </BaseButton>
            </div>
            <Divider style={{ margin: "5px 0px 15px" }} />
            <div className="camera-images-container">
              {Array.isArray(cameraDetails?.images) &&
              cameraDetails?.images.length > 0 ? (
                cameraDetails?.images?.map((img, idx) => (
                  <div className="image-wrapper">
                    <div>
                      <BaseTypography.Text
                        style={{
                          display: "block",
                          width: "100%",
                          textAlign: "left",
                        }}
                      >
                        {img?.label}
                      </BaseTypography.Text>
                      <BaseTypography.Paragraph
                        type="secondary"
                        style={{
                          display: "block",
                          width: "100%",
                          textAlign: "left",
                          marginBottom: "5px",
                        }}
                      >
                        {img?.description}
                      </BaseTypography.Paragraph>
                    </div>
                    <Image
                      className="camera-image"
                      src={
                        img?.URL ||
                        `https://picsum.photos/seed/picsum/1900/1080`
                      }
                      alt={img?.URL}
                    />
                  </div>
                ))
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <BaseTypography.Text style={{ marginBottom: "10px" }}>
                    {t("remain.no-images-added-yet")}
                  </BaseTypography.Text>
                  <Container onClick={handleEnableAddImages}>
                    <ContentCenter>
                      <PlusOutlined style={{ fontSize: "20px" }} />
                    </ContentCenter>
                  </Container>
                </div>
              )}
            </div>
          </>
        )}
      </ViewCameraStyled>
      <Divider />
      <>
        {cameraDetails?.group ? (
          <>
            {" "}
            <BaseTypography.Title level={4}>
              {t("remain.group")}{" "}
            </BaseTypography.Title>
            <InfoWrapperStyled>
              <div className="label">{t("remain.group-name")}</div>
              <div className="value">{cameraDetails?.group?.name}</div>
            </InfoWrapperStyled>
            <InfoWrapperStyled>
              <div className="label">{t("remain.public-page")}</div>
              <div className="value">
                {cameraDetails?.group?.publicView?.details?.map((pub) => {
                  return <>{pub?.title}</>;
                })}
              </div>
            </InfoWrapperStyled>
            <InfoWrapperStyled>
              <div className="label">{t("remain.category")}</div>
              <div className="value">
                {cameraDetails?.group?.category?.name}
              </div>
            </InfoWrapperStyled>
            <InfoWrapperStyled>
              <div className="label">{t("remain.created")}</div>
              <div className="value">{cameraDetails?.group?.createdAt}</div>
            </InfoWrapperStyled>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                marginTop: "10px",
              }}
            >
              <Button
                onClick={() =>
                  navigate(`/dashboard/group/${cameraDetails?.group?._id}`)
                }
              >
                {t("remain.view-group")}
              </Button>
            </div>
          </>
        ) : null}
      </>
      <Modal
        open={enableAddCamera}
        onCancel={handleCloseAddImages}
        title={t("dashboard.add-image")}
        footer={null}
      >
        <Formik
          innerRef={formikRef}
          initialValues={{
            title: "",
            image: "",
            description: "",
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            handleAddImages(values);
          }}
          validateOnBlur={false}
          enableReinitialize
        >
          {({ values, errors, setFieldValue }) => (
            <Form>
              <BaseInputs
                label={t("remain.image-title")}
                name="title"
                value={values["title"]}
                type="text"
              />
              <BaseInputs
                label={t("remain.description")}
                name="description"
                value={values["description"]}
                type="textarea"
                maxLength={250}
                showCount
              />
              <Field
                isFullWidth
                name="image"
                component={S3uploaderMessage}
                setFieldValue={setFieldValue}
                fieldName={"image"}
                value={values.image}
                error={errors.image}
                label={t("remain.image")}
              />
              <FlexEnd>
                <BaseButton onClick={handleCloseAddImages}>
                  {t("group.cancel")}
                </BaseButton>
                <BaseButton
                  htmlType="submit"
                  type="primary"
                  style={{ background: "var(--primary)", marginLeft: "10px" }}
                  loading={addingImage}
                >
                  {t("remain.add-image")}
                </BaseButton>
              </FlexEnd>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default ViewCamera;

const ContentCenter = styled.div`
  height: 100px;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
`;
const Container = styled.div`
  position: relative;
  display: inline-block;
  background-color: #fafafa;
  border: 1px solid var(--border);
  border-radius: 5px;

  margin: 5px;
  cursor: pointer;
  width: 200px;
  @media only screen and ${media.md} {
    width: 500px;
  }
`;

const InfoWrapperStyled = styled.div`
  display: flex;
  margin-bottom: 8px;
  flex-wrap: wrap;
  align-items: start;
  flex-direction: column;
  @media (${devices.mobileSM}) {
    flex-direction: row;
    align-items: center;
  }

  & .label {
    width: 110px;
    font-weight: 600;
    color: #444;
  }
  & .value {
  }
`;
