import QrCode from "../../../assets/qr-code.svg";
import Notification from "../../../assets/notification-system.svg";
import RealTime from "../../../assets/real-time.svg";
import User from "../../../assets/user.svg";
import Support from "../../../assets/ongoing-support.svg";

export const sectionData = [
  {
    id: 1,
    img: QrCode,
    title: "QR Code Integration for Public Cameras",
    description: [
      "We develop and install QR code systems linked to every surveillance camera, particularly at busy intersections.",
      "These QR codes, when scanned, provide citizens with instant information about the camera’s purpose, the data being collected, and how long it will be stored, aligning with GDPR transparency requirements.",
    ],
  },
  {
    id: 2,
    img: Notification,
    title: "Notification Systems for Enhanced Transparency",
    description: [
      "Our notification systems are engineered to inform the public in real-time about surveillance activities.",
      "This includes detailing the reasons for surveillance and the rights of individuals under GDPR, thereby fostering an environment of trust and compliance.",
    ],
  },
  {
    id: 3,
    img: RealTime,
    title: "Real-Time Data Protection Compliance Monitoring",
    description: [
      "Our platform continually monitors compliance with GDPR regulations, ensuring that any data captured by cameras is handled in a legally compliant and secure manner.",
      "We provide regular updates to our clients on compliance status and potential areas for improvement.",
    ],
  },
  {
    id: 4,
    img: User,
    title: "Consultation and Compliance Strategy",
    description: [
      "Camera Compliance Ltd offers expert consultation services to help cities and municipalities develop comprehensive strategies for GDPR compliance in their surveillance systems.",
      "Our team of experts assists in legal interpretation, system design, and implementation, ensuring a smooth transition to fully compliant operations.",
    ],
  },
  {
    id: 5,
    img: Support,
    title: "Ongoing Support and Education",
    description: [
      "We believe in empowering our clients and the public. As such, we offer ongoing support and educational resources to ensure both parties understand and can effectively navigate GDPR regulations.",
    ],
  },
];
