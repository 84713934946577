import { Button, Typography, Row, Col } from "antd";
import { FeatureContainer } from "./style";
import { StraightLoader } from "./straightLoader";
import { StraightUnLoad } from "./straightLoader";
import styled from "styled-components";
//@ts-ignore
import { useTranslation } from "react-i18next";
import { devices } from "utils/theme";
import { getI18n } from "react-i18next";
import { featuresData } from "./featuresData";
import FeatureData from "./FeatureData";

import { useNavigate } from "react-router";
import { useState, useEffect } from "react";
import { FONT_SIZE } from "styles/themes/constants";

const StyledRow = styled.div`
  display: flex;
  gap: 50px;
  margin: 60px 0 40px 0;

  flex-direction: column-reverse;
  > div:nth-child(2) {
    min-height: 400px !important;
  }
  img {
    top: 0;
    width: 100%;
    height: auto;
    min-height: 340px;
    object-fit: contain;
  }

  @media (${devices.tablet}) {
    flex-direction: row;
    img {
      width: 600px;
      top: 50px;
    }
  }
`;

const Features = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex(
        (prevIndex) => (prevIndex + 1) % featuresData?.length
      );
    }, 4500);

    return () => clearInterval(intervalId);
  }, [featuresData]);
  const { language: selectedlang, changeLanguage } = getI18n();

  const data = FeatureData[selectedlang];
  return (
    <>
      <FeatureContainer>
        <div className="title">
          <Typography.Title level={3} className="header">
            {t("homePage.feature-title")}
          </Typography.Title>
          <Typography.Text
            style={{
              fontSize: FONT_SIZE.md,
            }}
          >
            {t("homePage.feature-description")}
          </Typography.Text>
        </div>
        <StyledRow>
          <Col style={{ flex: 1, maxWidth: 480, marginRight: 20 }}>
            {data?.map((feature, index) => {
              const { title, description } = feature;
              return (
                <Col key={index} style={{ marginBottom: 50, flex: 1 }}>
                  <h3>{title}</h3>
                  <p style={{ marginTop: 10, maxWidth: 400 }}>{description}</p>
                  {index === currentImageIndex ? (
                    <StraightLoader />
                  ) : (
                    <StraightUnLoad />
                  )}
                </Col>
              );
            })}
          </Col>
          <Col style={{ position: "relative", flex: 1 }}>
            {featuresData?.map((feature, index) => {
              return (
                <img
                  key={index}
                  src={feature.img}
                  alt={`Image ${index + 1}`}
                  className="img"
                  style={{
                    position: "absolute",
                    transition: "all 0.4s ease",
                    opacity: currentImageIndex === index ? 1 : 0,
                  }}
                />
              );
            })}
          </Col>
        </StyledRow>
        {/* <Button
          className="feature-btn"
          onClick={() => navigate("/features")}
          style={{
            width: "auto",
            textAlign: "center",
            margin: "0 auto",
            backgroundColor: "#167a8b",
            color: "white",
            height: "50px",

            // padding: "15px",
          }}
        >
          {t("homePage.button")}
        </Button> */}
      </FeatureContainer>
    </>
  );
};

export default Features;
