import { styled } from "styled-components";
export const Main = styled.div`
  min-height: 100vh;
  background: #fcfcfc;
  overflow-x: hidden;
  font-family: "Poppins";
  color: #576874;

  & .Container {
    min-height: calc(100vh - 169px);
    color: #576874;
    padding: 20px;
  }

  & .Header {
    max-width: 1160px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin: 0 auto;

    margin-bottom: 70px;
  }

  & .ContactContainer {
    max-width: 1160px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin: 0 auto;

    margin-bottom: 70px;

    & .Contact {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

      width: 100%;

      margin: 0 auto;
      gap: 100px;
      margin-bottom: 60px;
      /* 
      & .content {
        display: "flex";
        justify-content: "space-between";
        width: "100%";
        flex-wrap: "wrap";
        margin: "0 auto";
      } */

      & .left {
        flex: 1;
        font-size: 30px;
        line-height: 0px;
      }
      & .right {
        flex: 1;

        & .form-control {
          display: flex;
          flex-direction: column;

          & .input-btn-container {
            display: flex;
            flex-direction: column;
            gap: 20px;
          }
          & .btn {
            width: auto;
            padding: 15px;
            box-shadow: none;
            display: flex;
            height: 40px;
            align-items: center;
            align-self: flex-end;
            background-color: #167a8b;
            color: white;
            border-radius: 8px;
            border: 1px solid gray;
            cursor: pointer;
          }

          input {
            height: 30px;
            border: none;
            box-shadow: none;
          }
          input::placeholder {
            color: lightgray;
          }

          & .StyledInput {
            border: none;
            color: #b3b3b3;
            border-bottom: 1px solid #c0c0c0;
            box-shadow: none;
          }

          & .StyledTextarea::placeholder {
            /* color: #b3b3b3; */
            color: lightgray;
          }
        }
      }
    }
  }
`;
