export default [
  {
    title: "1. Información que recopilamos:",
    desc: [
      {
        title: "1.1",
        desc: "Información personal: Cuando interactúa con nuestro proyecto de cumplimiento de cámaras, podemos recopilar información personal como su nombre, dirección de correo electrónico e información de contacto.",
      },
      {
        title: "1.2",
        desc: "Información del dispositivo: Podemos recopilar información sobre el dispositivo que utiliza para acceder a nuestros servicios, incluido el tipo de dispositivo, el sistema operativo, el tipo de navegador y la dirección IP.",
      },
      {
        title: "1.3",
        desc: "Información de ubicación: Con su consentimiento explícito, podemos recopilar información de ubicación de su dispositivo para proporcionarle información precisa sobre el cumplimiento relacionado con la ciudad y la zona en la que se encuentra.",
      },
      {
        title: "1.4",
        desc: "Información de escaneo de cámaras: Cuando escanea un código QR en un poste con una cámara, podemos recopilar datos relacionados con la ubicación de la cámara, la fecha y la hora del escaneo y la información de cumplimiento recuperada.",
      },
    ],
  },
  {
    title: "2. Cómo utilizamos su información:",
    desc: [
      {
        title: "2.1",
        desc: "Utilizamos la información recopilada para proporcionarle información precisa sobre el cumplimiento relacionado con la zona de la ciudad donde ha escaneado el código QR.",
      },
      {
        title: "2.2",
        desc: "Podemos utilizar su información personal para enviarle actualizaciones, notificaciones e información importante relacionada con el proyecto de cumplimiento de cámaras.",
      },
      {
        title: "2.3",
        desc: "Analizamos datos agregados y anonimizados con fines estadísticos, lo que puede ayudarnos a mejorar nuestros servicios.",
      },
    ],
  },

  {
    title: "3. Compartir información:",
    desc: [
      {
        title: "3.1",
        desc: "No compartimos su información personal con terceros con fines de marketing sin su consentimiento explícito.",
      },
      {
        title: "3.2",
        desc: "Podemos compartir su información con nuestros proveedores de servicios que nos ayudan a brindar nuestros servicios. Estos proveedores de servicios están obligados a mantener la confidencialidad de su información.",
      },
      {
        title: "3.3",
        desc: "Podemos divulgar su información si lo exige la ley o para proteger nuestros derechos, privacidad, seguridad o propiedad.",
      },
    ],
  },

  {
    title: "4. Seguridad de los datos:",
    desc: [
      {
        title: "4.1",
        desc: "Implementamos medidas de seguridad para proteger su información personal contra el acceso no autorizado, la alteración, la divulgación o la destrucción.",
      },
      {
        title: "4.2",
        desc: "A pesar de nuestros esfuerzos, ninguna transmisión ni almacenamiento en línea es completamente seguro. Por lo tanto, no podemos garantizar la seguridad absoluta de su información.",
      },
    ],
  },

  {
    title: "5. Sus opciones:",
    desc: [
      {
        title: "5.1",
        desc: "Puede optar por no recibir comunicaciones de marketing de nuestra parte siguiendo las instrucciones de cancelación de suscripción proporcionadas en la comunicación.",
      },
      {
        title: "5.2",
        desc: "Puede controlar la recopilación de información de ubicación ajustando la configuración en su dispositivo.",
      },
    ],
  },

  {
    title: "6. Privacidad de los niños:",
    desc: [
      {
        title: "6.1",
        desc: "Nuestros servicios no están dirigidos a personas menores de [edad]. No recopilamos intencionalmente información personal de niños.",
      },
    ],
  },

  {
    title: "7. Cambios en esta política de privacidad:",
    desc: [
      {
        title: "7.1",
        desc: "Podemos actualizar esta política de privacidad de vez en cuando para reflejar cambios en nuestras prácticas u otras razones operativas, legales o regulatorias.",
      },
      {
        title: "7.2",
        desc: "Proporcionaremos un aviso destacado de cualquier cambio importante en esta política de privacidad.",
      },
    ],
  },
  {
    title: "8. Contáctenos:",
    desc: [
      {
        desc: "Si tiene alguna pregunta, inquietud o solicitud con respecto a esta política de privacidad o al uso de su información personal, contáctenos en [correo electrónico de contacto].",
      },
    ],
  },
];
