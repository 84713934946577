import axios from "axios";
import SelectSticker, {
  CallbackDataType,
} from "components/StickerSelection/SelectSticker";
import JSONUpload from "components/StickerSelection/jsonUpload";
import BaseButton from "components/common/BaseButton";
import BaseLoading from "components/common/BaseSpin";
import { addSticker } from "constants/api/sticker";
import useAPI from "hooks/useApi";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import stickers from "shared/front/apis/stickers.api";
import store from "store";
import { styled } from "styled-components";
import request from "utils/request";

export const JSON_KEY = "camera-compliance-JSON";

const Setting = ({ closeModal, refreshData, stickerEditData }: any) => {
  const { t } = useTranslation();
  const sticker = useSelector((state: any) => state.sticker.data);

  const [dataFromSelectSticker, setDataFromSelectSticker] =
    useState<CallbackDataType>(undefined);

  const [imageLoading, setImageLoading] = useState(false);

  const [dataToEdit, setDataToEdit] = useState<any>({});

  const [dataFromJSON, setDataFromJSON] = useState({
    URL: undefined,
    stickerSVG: undefined,
    imageSize: undefined,
    qrCodeSize: undefined,
    qrOptions: undefined,
  });

  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [
    handleRequest,
    { loading: generatedQRLoading, data: generatedQRData, error },
  ] = useAPI();

  useEffect(() => {
    if (stickerEditData?._id) {
      setDataToEdit({
        id: stickerEditData?._id,
        URL: stickerEditData?.URL,
        imageSize: {
          width: stickerEditData?.stickerDimension?.stickerWidth,
          height: stickerEditData?.stickerDimension?.stickerHeight,
          aspectRatio:
            stickerEditData?.stickerDimension?.stickerWidth /
            stickerEditData?.stickerDimension?.stickerWidth,
        },
        qrCodeSize: {
          height: stickerEditData?.stickerDimension?.qrHeight,
          width: stickerEditData?.stickerDimension?.qrWidth,
          x: stickerEditData?.stickerDimension?.qrLeft,
          y: stickerEditData?.stickerDimension?.qrTop,
        },
        qrOptions: stickerEditData?.stickerDimension?.qrOptions,
      });
    }
  }, [stickerEditData]);

  const uploadTo = async (file: any, presignedUrl: any) => {
    try {
      const response = await fetch(presignedUrl, {
        method: "PUT",
        headers: {
          "Content-Type": file?.type ? file?.type : "image/svg+xml",
        },
        body: file,
      });
      console.log("Image upload successful:", response);
    } catch (err) {
      console.log("Image upload failed:", err);
    }
  };

  const uploadToS3 = async (file: any) => {
    const fileExtension = "svg";
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}common/s3SignedURL?fileExtension=${fileExtension}`
      );
      console.log({ data });

      const { url, key } = data.data;
      await uploadTo(file, url);
      return key;
    } catch (err) {
      console.log("uplaod to s3");
    } finally {
    }
  };

  const handleUpdateStickerQR = async () => {
    const { imageSize, qrCodeSize, qrOptions } = dataFromSelectSticker;

    const response = await request(
      stickers.updateSticker({
        data: {
          stickerDimension: {
            stickerWidth: imageSize?.width,
            stickerHeight: imageSize?.height,
            qrWidth: qrCodeSize?.width,
            qrHeight: qrCodeSize?.height,
            qrTop: qrCodeSize?.y,
            qrLeft: qrCodeSize?.x,
            qrOptions: {
              color: {
                dark: qrOptions?.color?.dark,
                light: qrOptions?.color?.light,
              },
              margin: qrOptions?.margin,
              borderRadius: qrOptions?.borderRadius,
            },
          },
        },
        stickerId: stickerEditData?._id,
      })
    );
    if (response) {
      store.store.dispatch(
        store.Actions.set("selectedStickerToGenerateQr", response)
      );

      const updatedData = sticker.map((val) => {
        if (val?._id === response?._id) {
          return response;
        }
        return val;
      });
      store.store.dispatch(store.Actions["set"]("sticker.data", updatedData));
      toast.success(t("remain.sticker-updated-successfully"));
      closeModal();
      const queryStickerId = searchParams.get("sticker");
      if (!!queryStickerId) {
        navigate(`/dashboard/generated-qr?sticker=${queryStickerId}`);
      }
    }
    try {
    } catch (error) {
      // console.log("Updating Sticker:", error);
    }
  };

  const handleStickerQR = async () => {
    setImageLoading(true);

    const { imageSize, qrCodeSize, qrOptions, selectedFile, selectedSVGFile } =
      dataFromSelectSticker;

    let imgURL = "";
    if (!!selectedFile) {
      imgURL = await uploadToS3(selectedFile);
    }
    if (!!selectedSVGFile) {
      imgURL = await uploadToS3(selectedSVGFile);
    }

    const stylesObject = {
      stickerWidth: imageSize?.width,
      stickerHeight: imageSize?.height,
      qrWidth: qrCodeSize?.width,
      qrHeight: qrCodeSize?.height,
      qrTop: qrCodeSize?.y,
      qrLeft: qrCodeSize?.x,
      qrOptions: {
        color: {
          dark: qrOptions?.color?.dark,
          light: qrOptions?.color?.light,
        },
        margin: qrOptions?.margin,
        borderRadius: qrOptions?.borderRadius,
      },
    };

    if (!imgURL) {
      return;
    }
    try {
      await handleRequest({
        method: "post",
        url: addSticker,
        data: {
          stickerDimension: stylesObject,
          URL: imgURL,
        },
      });
      toast.success(t("toast.sticker-success-generated"));
      closeModal();
      refreshData();
    } catch (error) {
      toast.error("Cannot generate QR, Sorry for inconvenience!");
      closeModal();
    } finally {
      setImageLoading(false);
    }
  };

  function getImageDataURL(svgXml) {
    return (
      "data:image/svg+xml;base64," + btoa(unescape(encodeURIComponent(svgXml)))
    );
  }

  const getDataFromImport = (json_data) => {
    const { key } = json_data;
    if (key !== JSON_KEY) {
      toast.error(
        "Please upload JSON imported from Camera Compliance /test-sticker page"
      );
      return;
    }

    setDataFromJSON({
      URL: getImageDataURL(json_data?.stickerImage),
      stickerSVG: json_data?.stickerImage,
      imageSize: {
        width: json_data?.stickerWidth,
        height: json_data?.stickerHeight,
        aspectRatio: json_data?.stickerWidth / json_data?.stickerWidth,
      },
      qrCodeSize: {
        height: json_data?.qrHeight,
        width: json_data?.qrWidth,
        x: json_data?.qrLeftPos,
        y: json_data?.qrTopPos,
      },
      qrOptions: json_data?.qrOptions,
    });
  };

  const isEditingSticker = stickerEditData?._id;
  return (
    <>
      {generatedQRLoading && (
        <DimmedBackground>
          <BaseLoading />
        </DimmedBackground>
      )}
      <StickerSelectionContainer
        style={{
          justifyContent: dataFromSelectSticker?.selectedFilePreviewURL
            ? "start"
            : "center",
        }}
      >
        {!dataFromJSON?.URL &&
          !dataFromSelectSticker?.selectedFile &&
          !dataToEdit?.id && (
            <JSONUpload
              setSelectedFile={getDataFromImport}
              allowed={[".json"]}
            />
          )}
        <SelectSticker
          dataFromJSON={dataFromJSON}
          stickerEditData={dataToEdit}
          callbackForParent={(data) => setDataFromSelectSticker(data)}
        />
      </StickerSelectionContainer>
      {dataFromSelectSticker?.selectedFilePreviewURL && (
        <div
          style={{ display: "flex", justifyContent: "flex-end", gap: "20px" }}
        >
          {!dataToEdit?.id && (
            <BaseButton
              onClick={() => {
                dataFromSelectSticker?.reset();
                setDataFromSelectSticker(undefined);
                setDataFromJSON({
                  URL: undefined,
                  stickerSVG: undefined,
                  imageSize: undefined,
                  qrCodeSize: undefined,
                  qrOptions: undefined,
                });
              }}
              htmlType="button"
              type="primary"
              disabled={imageLoading}
              style={{ background: "var(--primary)" }}
            >
              {t("remain.back")}
            </BaseButton>
          )}
          <BaseButton
            onClick={() => {
              if (isEditingSticker) {
                handleUpdateStickerQR();
              } else {
                handleStickerQR();
              }
            }}
            htmlType="button"
            type="primary"
            loading={imageLoading}
            style={{ background: "var(--primary)" }}
          >
            {t("remain.save")}
          </BaseButton>
        </div>
      )}
    </>
  );
};

export default Setting;

const DimmedBackground = styled.div`
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StickerSelectionContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  // justify-content: center;
  gap: 20px;
  margin: 0 auto;

  @media (max-width: 860px) {
    flex-direction: column-reverse;
    gap: 0px;
  }
`;
