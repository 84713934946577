import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";
import createTheme from "./utils/theme";
import GlobalStyle from "./styles/GlobalStyle";
import Store from "./store";
import "./i18n";
import { ConfigProvider } from "antd";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={Store.store}>
      <ThemeProvider theme={createTheme(false)}>
        <ConfigProvider
          theme={{
            token: {
              fontFamily: "Poppins",
            },
          }}
        >
          <GlobalStyle />
          <App />
        </ConfigProvider>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);
