import { convertTimestamp } from "utils/utils";
import { useTranslation } from "react-i18next";

import * as S from "./index.styles";

const CityDescriptions = ({ cityDesc, cityAll, singleCityData }: any) => {
  const cityDetails = singleCityData?.cityDetails;
  const { t } = useTranslation();

  const operatorDetails = [
    {
      label: t("dashboard.added-date"),
      value: convertTimestamp(cityDesc?.createdAt),
      id: 2,
      isIcon: false,
    },

    {
      label: t("dashboard.qr-accepted"),
      value: singleCityData?.acceptedQR,
      id: 3,
      isIcon: false,
    },
    {
      label: t("dashboard.sticker"),
      value: singleCityData?.noOfStickers,
      id: 4,
      isIcon: false,
    },
    {
      label: t("dashboard.total-accepted-qr"),
      value: singleCityData?.totalAcceptedQR,
      id: 5,
      isIcon: false,
    },
  ];
  return (
    <S.Wrapper>
      <S.InfoWrapperStyled>
        {operatorDetails?.map((item) => {
          return (
            <S.StatsStyled>
              <div className="label">{item.label}</div>
              <div className="value">{item.value}</div>
            </S.StatsStyled>
          );
        })}
      </S.InfoWrapperStyled>
    </S.Wrapper>
  );
};

export default CityDescriptions;
