import { Col, Divider, Row, Typography, QRCode } from "antd";
import BaseLoading from "components/common/BaseSpin";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Leaflet from "containers/City/SingleCity/Leaflet";
import BannerImage from "./BannerImage";
import LogoTitle from "./LogoTitle";
import LanguageTabs from "./LanguageTabs";
import InterSection from "./Intersection";
import languageMapWithCountries from "utils/languagemaps";

import * as S from "./index.styles";
import { BaseTypography } from "components/common/BaseTypography";
import styled from "styled-components";
import i18next from "i18next";

interface QRPublicPageProps {
  isQRDetailsPage?: boolean;
  loading?: boolean;
  publicView?: any;
  qrDetails?: any;
  colorState?: any;
  showImages?: boolean;
  cameras?: any[];
}

const QRPublicPage = ({
  isQRDetailsPage = false,
  loading = false,
  publicView,
  qrDetails,
  colorState,
  showImages = false,
  cameras = [],
}: QRPublicPageProps) => {
  const { t } = useTranslation();
  const [availableLanguages, setAvailableLanguages] = useState([]);
  const [selectedLang, setSelectedLang] = useState("");
  const [selectedDetails, setSelectedDetails] = useState<{
    desc?: string;
    lang?: string;
    title?: string;
  }>({});

  useEffect(() => {
    if (Array.isArray(publicView?.details) && publicView?.details?.length > 0) {
      const availLang =
        publicView?.details &&
        publicView?.details?.map((detail) => {
          return {
            langCode: detail?.lang,
            language: languageMapWithCountries[detail?.lang]?.languageFullName,
            flag: (
              <div style={{ fontFamily: "Twemoji Country Flags" }}>
                {languageMapWithCountries[detail?.lang]?.flag}
              </div>
            ),
          };
        });
      handleDetailsSelection(availLang[0]?.langCode);
      setSelectedLang(availLang[0]?.langCode);
      setAvailableLanguages(availLang);
    }
  }, [publicView]);

  const handleDetailsSelection = (lang) => {
    if (Array.isArray(publicView?.details) && publicView?.details?.length > 0) {
      const selected = publicView?.details.find((x) => x?.lang === lang);
      setSelectedDetails(selected);
    }
  };

  const changeLang = async (val) => {
    await i18next.changeLanguage(val);
  };

  const onTabChange = async (val) => {
    handleDetailsSelection(val);
    setSelectedLang(val);
    changeLang(val);
  };

  const singleMarkerDetails = [];

  if (qrDetails?.latitude !== undefined && qrDetails?.longitude !== undefined) {
    singleMarkerDetails.push({
      _id: qrDetails?._id || "null",
      latitude: qrDetails?.latitude,
      longitude: qrDetails?.longitude,
    });
  }

  return (
    <>
      {loading ? (
        <BaseLoading></BaseLoading>
      ) : (
        <>
          <S.QRRequestedStyled colorState={colorState}>
            {publicView?.banner && (
              <BannerImage src={publicView?.banner} colorState={colorState} />
            )}

            <S.ContentWrapperStyled>
              {isQRDetailsPage ? (
                publicView?._id && (
                  <>
                    <LogoTitle
                      logo={qrDetails?.city?.logo}
                      title={publicView?.title}
                      CState={colorState}
                    />
                    <LanguageTabs
                      cState={colorState}
                      cityDetailsArray={publicView?.details || []}
                      availableLanguages={availableLanguages}
                      onTabChange={onTabChange}
                      selectedLang={selectedLang}
                      selectedDetails={selectedDetails}
                    />
                  </>
                )
              ) : (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <S.Description colorState={colorState}>
                        {publicView?.desc}
                      </S.Description>
                    </div>
                  </div>
                  <S.Description colorState={colorState}>
                    {publicView?.desc}
                  </S.Description>
                  <LanguageTabs
                    cState={colorState}
                    cityDetailsArray={publicView?.details || []}
                    availableLanguages={availableLanguages}
                    onTabChange={onTabChange}
                    selectedLang={selectedLang}
                    selectedDetails={selectedDetails}
                  />
                </>
              )}
              {isQRDetailsPage && !qrDetails?._id && (
                <S.Text>
                  <Typography.Text
                    style={{
                      textAlign: "center",
                      fontSize: "20px",
                      marginTop: "20px",
                    }}
                  >
                    {" "}
                    {t("remain.not-contain-any-information")}
                  </Typography.Text>
                </S.Text>
              )}
              {!!colorState?.isCameraDetails &&
                Array.isArray(cameras) &&
                cameras?.length > 0 && (
                  <InterSection
                    QrDetailsCameraData={cameras}
                    cState={colorState}
                  />
                )}

              <Row align="top" justify="space-between">
                <Col
                  xs={{ span: 24 }}
                  md={{ span: 12 }}
                  style={{ paddingRight: "15px" }}
                >
                  <S.InfoContainerStyled colorState={colorState}>
                    <S.InfoHeader>{t("remain.location")}</S.InfoHeader>
                    <Divider style={{ margin: "5px 0px 10px" }} />
                    <S.InfoWrapperStyled>
                      <div className="label">{t("dashboard.address")}</div>
                      <div className="value">: {qrDetails?.address}</div>
                    </S.InfoWrapperStyled>
                    <S.InfoWrapperStyled>
                      <div className="label">{t("dashboard.latitude")}</div>
                      <div className="value">: {qrDetails?.latitude}</div>
                    </S.InfoWrapperStyled>
                    <S.InfoWrapperStyled>
                      <div className="label">{t("dashboard.longitude")}</div>
                      <div className="value">: {qrDetails?.longitude}</div>
                    </S.InfoWrapperStyled>
                  </S.InfoContainerStyled>
                  <S.InfoContainerStyled colorState={colorState}>
                    <S.InfoHeader>{t("group.qr")}</S.InfoHeader>
                    <Divider style={{ margin: "5px 0px 10px" }} />
                    <S.InfoWrapperStyled>
                      <div className="label">{t("dashboard.id")}</div>
                      <div className="value">: {qrDetails?._id}</div>
                    </S.InfoWrapperStyled>
                    <S.InfoWrapperStyled>
                      {/* <img
                        style={{ objectFit: "contain" }}
                        src={qrDetails?.URL}
                        alt={qrDetails?.URL}
                        height={"100px"}
                      /> */}
                      <QRCode value={qrDetails?.frontendURL || "-"} />
                    </S.InfoWrapperStyled>
                  </S.InfoContainerStyled>
                </Col>
                {singleMarkerDetails?.length > 0 && (
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    {qrDetails && (
                      <Leaflet QRCodeDetails={singleMarkerDetails} />
                    )}
                  </Col>
                )}
              </Row>
              <S.IntersectionImageContainerStyled>
                {showImages ? (
                  <>
                    {qrDetails?.images?.map((img) => (
                      <div className="individual-image">
                        <TitleTypography
                          colorState={colorState}
                          level={4}
                          className="desc"
                        >
                          {img?.description}
                        </TitleTypography>
                        <BaseTypography.Text className="label">
                          {img?.label}
                        </BaseTypography.Text>
                        <img
                          src={img?.URL}
                          alt={img?.description}
                          className="image"
                        />
                      </div>
                    ))}
                  </>
                ) : (
                  <></>
                )}
              </S.IntersectionImageContainerStyled>
            </S.ContentWrapperStyled>

            <S.FooterContainer colorState={colorState}>
              <S.FooterLogo>
                <S.FooterText>
                  {qrDetails?.title} {t("dashboard.gdpr")}
                </S.FooterText>
              </S.FooterLogo>
              <S.AssociateLogo>
                <S.FooterStyled>
                  <div className="image-wrapper">
                    {publicView?.footerLogo?.length > 0 &&
                      publicView?.footerLogo?.map((item) => {
                        return (
                          <>
                            <img
                              src={item?.URL}
                              alt={item?.link}
                              onClick={() => window.open(item?.link)}
                              style={{
                                cursor: "pointer",
                                objectFit: "contain",
                              }}
                            />
                          </>
                        );
                      })}
                  </div>
                </S.FooterStyled>
                <S.FooterText>
                  {t("contact.contact-us")}: cameracompliance@example.com
                </S.FooterText>
              </S.AssociateLogo>
            </S.FooterContainer>
          </S.QRRequestedStyled>
        </>
      )}
    </>
  );
};

export default QRPublicPage;

const TitleTypography = styled(BaseTypography.Title)<{ colorState: any }>`
  color: ${(props) => props.colorState.fontColor} !important;
`;
