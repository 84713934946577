import React from "react";
import * as Styles from "./index.styles";
import BaseToolTip from "components/common/BaseTooltip";
import { convertTimestamp, fullConvertTimestamp } from "utils/utils";
import BaseButton from "components/common/BaseButton";
import UserAddOutlined from "@ant-design/icons/UserAddOutlined";

interface CitiesTableRowCardProps {
  logo: string;
  cityName: string;
  createdDate: string;
  assignedAdmin: { firstName: string; lastName: string };
  onRowClick: (e: React.MouseEvent<HTMLElement>) => void;
  onAddAdmin: (e: React.MouseEvent<HTMLElement>) => Promise<void>;
}
const CitiesTableRowCard = ({
  logo,
  cityName,
  createdDate,
  assignedAdmin,
  onRowClick,
  onAddAdmin,
}: CitiesTableRowCardProps) => {
  return (
    <Styles.CardWrapper onClick={onRowClick}>
     {logo ? <Styles.Logo src={logo} /> : <></>} 
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div>
          <div style={{ fontSize: "16px", fontWeight: "600" }}>{cityName}</div>
          <div style={{ fontSize: "12px", fontWeight: "400" }}>
            {assignedAdmin ? (
              <>
                Assigned Admin: {assignedAdmin?.firstName}{" "}
                {assignedAdmin.lastName}
              </>
            ) : (
              <>No admin assigned yet.</>
            )}
          </div>
          <BaseToolTip title={fullConvertTimestamp(createdDate)}>
            <div style={{ fontSize: "10px", fontWeight: "400" }}>
              Created on {convertTimestamp(createdDate)}
            </div>
          </BaseToolTip>
        </div>
        {!assignedAdmin && (
          <BaseToolTip title="Add Admin">
            <BaseButton onClick={onAddAdmin}>
              <UserAddOutlined />
            </BaseButton>
          </BaseToolTip>
        )}
      </div>
    </Styles.CardWrapper>
  );
};

export default CitiesTableRowCard;
