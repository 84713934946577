export default [
  {
    title: "1. Käyttöehtojen hyväksyminen:",
    desc: [
      {
        title: "1.1",
        desc: "Käyttämällä kamera compliance -projektiamme myönnät lukeneesi, ymmärtäneesi ja hyväksyneesi nämä käyttöehdot.",
      },
    ],
  },
  {
    title: "2. Tietosuojakäytäntö:",
    desc: [
      {
        title: "2.1",
        desc: "Käyttösi palveluissamme määräytyy myös tietosuojakäytäntömme mukaan. Käyttämällä palveluitamme suostut henkilötietojesi keräämiseen, käyttöön ja jakamiseen kuvatun tietosuojakäytännön mukaisesti.",
      },
    ],
  },

  {
    title: "3. Palveluiden käyttö:",
    desc: [
      {
        title: "3.1",
        desc: "Käyttösi palveluissamme määräytyy myös tietosuojakäytäntömme mukaan. Käyttämällä palveluitamme suostut henkilötietojesi keräämiseen, käyttöön ja jakamiseen kuvatun tietosuojakäytännön mukaisesti.",
      },
      {
        title: "3.2",
        desc: "Sinun on noudatettava kaikkia soveltuvia lakeja ja säädöksiä palvelujemme käytössä.",
      },
    ],
  },

  {
    title: "4. Tekijänoikeudet:",
    desc: [
      {
        title: "4.1",
        desc: "Kaikki sisältö, materiaalit ja tekijänoikeudet, jotka liittyvät kamera compliance -projektiimme, ovat omistuksessamme tai lisenssinhaltijoidemme omistuksessa. Et saa kopioida, levittää, muokata tai luoda johdannaisteoksia sisällöstämme ilman nimenomaista lupaa meiltä.",
      },
    ],
  },

  {
    title: "5. Käyttäjän toiminta:",
    desc: [
      {
        title: "5.1",
        desc: "Olet vastuussa antamasi tiedon tarkkuudesta ja laillisuudesta palveluidemme käytön aikana.",
      },
      {
        title: "5.2",
        desc: "Sinun ei tule osallistua mihinkään toimintaan, joka voi häiritä tai vahingoittaa palveluitamme, muita käyttäjiä tai mainettamme.",
      },
    ],
  },

  {
    title: "6. Vastuun rajoitus:",
    desc: [
      {
        title: "6.1",
        desc: "Kamera compliance -projektimme tarjotaan 'sellaisenaan' ja 'saatavilla sellaisenaan'. Emme takaa palveluidemme tarkkuutta, luotettavuutta tai saatavuutta.",
      },
      {
        title: "6.2",
        desc: "Emme ole vastuussa mistään vahingoista, menetyksistä tai vastuista, jotka aiheutuvat palveluidemme käytöstä tai minkä tahansa tiedon saamisesta palveluidemme kautta.",
      },
    ],
  },

  {
    title: "7. Korvausvelvollisuus:",
    desc: [
      {
        title: "7.1",
        desc: "Sitoudut korvaamaan ja pitämään meidät vahingoittumattomina mistä tahansa vaatimuksista, menetyksistä, vastuista ja kuluista (mukaan lukien oikeudelliset kulut), jotka aiheutuvat palveluidemme käytöstäsi tai näiden käyttöehtojen rikkomisesta.",
      },
    ],
  },
  {
    title: "8. Muutokset ja lopettaminen:",
    desc: [
      {
        title: "8.1",
        desc: "Pidätämme oikeuden muokata, keskeyttää tai lopettaa kamera compliance -projektimme milloin tahansa ilman ennakkoilmoitusta.",
      },
      {
        title: "8.2",
        desc: "Voimme myös muuttaa näitä käyttöehtoja aika ajoin. Jatkuva palveluidemme käyttö tällaisten muutosten jälkeen merkitsee hyväksyntääsi päivitettyjä ehtoja kohtaan.",
      },
    ],
  },
  {
    title: "9. Sovellettava laki:",
    desc: [
      {
        title: "9.1",
        desc: "Nämä käyttöehdot säätelevät ja tulkitaan [Jurisdiction] lakien mukaisesti. Kaikki näihin ehtoihin liittyvät riidat kuuluvat [Jurisdiction] tuomioistuinten yksinomaiseen toimivaltaan.",
      },
    ],
  },
  {
    title: "10. Ota yhteyttä:",
    desc: [
      {
        desc: "Jos sinulla on kysyttävää, huolenaiheita tai tiedusteluja näistä käyttöehdoista tai palveluidemme käytöstä, ota meihin yhteyttä osoitteessa [contact email]. Käyttämällä kamera compliance -projektiamme suostut sitoutumaan näihin käyttöehtoihin. Jos et hyväksy näitä ehtoja kokonaisuudessaan, älä käytä palveluitamme.",
      },
    ],
  },
];
