import BaseModal from "components/common/BaseModal";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import * as S from "./index.styles";
import { Col, Input, message } from "antd";
import BaseInputs from "components/common/BaseInput/BaseInputs";
import { BaseTypography } from "components/common/BaseTypography";
import BaseButton from "components/common/BaseButton";
import BaseError from "components/common/BaseError";
import useAPI from "hooks/useApi";
import admin from "shared/front/apis/admin.api";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { generatePassword } from "services/generatePassword";
import BaseUploader from "components/common/BaseUploaders";
import { CopyOutlined } from "@ant-design/icons";
import useClipboard from "hooks/useClipboard";
import { ButtonWrapper } from "containers/QrDetailPublicView/index.styles";
import BasePhoneInput from "components/common/BasePhoneInput";
//@ts-ignore
import { useTranslation } from "react-i18next";

const AddAdminModal = ({
  onRefresh,
  isModalVisible,
  setIsModalVisible,
  closeModal,
  openModal,
  citySelect,
  values,
}: any) => {
  const { t } = useTranslation();
  const [isCopied, copyToClipboard] = useClipboard();
  const [messageApi, contextHolder] = message.useMessage();
  const [
    makeRequest,
    { loading: addOperatorLoading, error: addOperatorErrors },
  ] = useAPI();

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required(t("forms.name-valid"))
      .min(2, t("forms.name-min-valid"))
      .max(30, t("forms.name-max-valid")),
    lastName: Yup.string()
      .required(t("forms.lastname-valid"))
      .min(2, t("forms.lastname-min-valid"))
      .max(30, t("forms.lastname-max-valid")),

    phone: Yup.string().required(t("forms.phone-number")),
  });

  const handleFormSubmit = async (formikValues: any) => {
    try {
      const { firstName, lastName, phone, countryCode } = formikValues;
      const resp = await makeRequest(
        admin.updateAdmin({
          data: {
            firstName,

            lastName,
            phone: phone.replace(countryCode, ""),
            countryCode,
          },
          id: values._id,
        })
      );
      if (!!resp) {
        closeModal();
        onRefresh();
        toast.success(
          `${t("toast.admin")} ${resp?.fullName} ${t(
            "toast.is-successfully-added"
          )}.`
        );
      }
    } catch (error: any) {
      toast.error(error);
    }
  };

  return (
    <>
      {contextHolder}
      <BaseModal
        title={t("remain.edit-admin")}
        open={isModalVisible}
        onCancel={closeModal}
        footer={[]}
        width={500}
      >
        <Formik
          initialValues={{
            firstName: values?.firstName,
            lastName: values?.lastName,
            id: values?._id,
            phone: `${values?.countryCode}${values?.phone}`,
            countryCode: values?.countryCode,
          }}
          onSubmit={async (values, { setSubmitting }) => {
            await handleFormSubmit(values);
            await setSubmitting(false);
          }}
          validationSchema={validationSchema}
          validateOnBlur={false}
        >
          {({ values, handleSubmit, isSubmitting, setFieldValue, errors }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <S.RowWrapper align={"middle"}>
                  <Col span={24}>
                    <BaseInputs
                      label={t("dashboard.first-name")}
                      name={"firstName"}
                      type={"text"}
                    />
                  </Col>
                  <Col span={24}>
                    <BaseInputs
                      label={t("dashboard.last-name")}
                      name={"lastName"}
                      type={"text"}
                    />
                  </Col>

                  <Col span={24}>
                    <BasePhoneInput
                      label={t("dashboard.phone-no")}
                      name={"phone"}
                      handleChange={(val, country, formatted) => {
                        console.log(val, country, formatted);
                        setFieldValue("phone", val);
                        setFieldValue("countryCode", country);
                      }}
                    />
                  </Col>
                </S.RowWrapper>

                <BaseError>{addOperatorErrors}</BaseError>
                <S.ButtonWrapperStyled>
                  <BaseButton
                    htmlType="submit"
                    disabled={isSubmitting || addOperatorLoading}
                    loading={addOperatorLoading || addOperatorLoading}
                    type={"primary"}
                  >
                    {t("dashboard.submit")}
                  </BaseButton>
                </S.ButtonWrapperStyled>
              </Form>
            );
          }}
        </Formik>
      </BaseModal>
    </>
  );
};

export default AddAdminModal;
