const S3api = {
  s3PresignedUrl: (): any => {
    return {
      url: "common/s3SignedURL",
      method: "get",
    };
  },
};

export default S3api;
