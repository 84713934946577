import styled from "styled-components";
import { BaseDivider } from "components/common/BaseDivider";
import { BaseTypography } from "components/common/BaseTypography";
import { FONT_SIZE, media } from "styles/themes/constants";
import { devices } from "utils/theme";

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  background-color: #f5f5f5;
`;

export const Name = styled(BaseTypography.Text)`
  font-size: ${FONT_SIZE.md};
  font-weight: 600;
  margin-bottom: 5px;
`;

export const Role = styled(BaseTypography.Text)`
  font-size: ${FONT_SIZE.xs};
  font-weight: 500;
  color: #777;
`;

export const Text = styled(BaseTypography.Text)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${FONT_SIZE.md};
  font-weight: 600;
  width: 100px;
  padding: 5px 0px;

  & > a {
    display: block;
  }

  &:hover {
    color: var(--primary);
  }

  @media only screen and ${media.md} {
    font-size: 1rem;
  }
`;

export const LongText = styled(BaseTypography.Text)`
  cursor: pointer;
  align-items: center;
  justify-content: center;
  font-size: ${FONT_SIZE.md};
  font-weight: 600;
  width: 100px;
  &:hover {
    color: var(--primary);
  }
`;

export const ItemsDivider = styled(BaseDivider)`
  margin: 0;
`;

export const OverlayActions = styled.div`
  margin: 1rem 0rem 1rem 0rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DashboardLink = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  white-space: nowrap;

  @media (${devices.mobileSM}) {
    display: none;
  }
`;
