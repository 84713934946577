import { Image } from "antd";
import { useEffect, useState } from "react";
import styled from "styled-components";
import {
  getImageDataURL,
  loadQRCode,
  mergeSVG,
  svgFileToString,
} from "utils/svgHelpers";

export type StickerDimensionsType = {
  stickerWidth: number;
  stickerHeight: number;
  qrWidth: number;
  qrHeight: number;
  qrTop: number;
  qrLeft: number;
  qrOptions: {
    color: {
      dark: string;
      light: string;
    };
    margin: number;
    borderRadius: number;
  };
};

interface Props extends React.ComponentPropsWithoutRef<"div"> {
  frontEndURL: string;
  stickerURL: string;
  stickerDimensions: StickerDimensionsType;
  imgSize?: "fit" | "center";
  disablePreview?: boolean;
  style?: React.CSSProperties;
}

const ViewQrSticker = ({
  frontEndURL,
  stickerDimensions,
  stickerURL,
  imgSize = "fit",
  disablePreview = false,
  ...rest
}: Props) => {
  const [mergedSVG, setMergedSVG] = useState(undefined);

  useEffect(() => {
    getFinalSVGString();
  }, [stickerURL]);

  const getFinalSVGString = async () => {
    const qrSVG = await loadQRCode(stickerDimensions, frontEndURL);
    const stickerSVG = await svgFileToString(stickerURL);

    if (!stickerSVG) {
      return "";
    }
    const mergedSVGString = await mergeSVG({
      stickerImage: stickerSVG,
      stickerWidth: stickerDimensions?.stickerWidth,
      stickerHeight: stickerDimensions?.stickerHeight,
      qrWidth: stickerDimensions?.qrWidth,
      qrHeight: stickerDimensions?.qrHeight,
      qrTopPos: stickerDimensions?.qrTop,
      qrLeftPos: stickerDimensions?.qrLeft,
      qrCode: qrSVG,
    });

    const imageURL = getImageDataURL(mergedSVGString);
    setMergedSVG(imageURL);

    return mergedSVGString;
  };

  return (
    <StickerWithQrContainer {...rest}>
      {mergedSVG && (
        <Image
          src={mergedSVG}
          alt={stickerURL}
          className="sticker-img"
          height={"100%"}
          width={"100%"}
          style={{ objectFit: imgSize === "center" ? "cover" : "contain" }}
          preview={
            disablePreview
              ? false
              : {
                  src: mergedSVG,
                }
          }
        />
      )}
    </StickerWithQrContainer>
  );
};

export default ViewQrSticker;

const StickerWithQrContainer = styled.div`
  display: inline-block;
  position: relative;
  height: 400px;
  width: fit-content;
  max-width: 100%;
  border: 2px solid #dbdbdb;
`;
