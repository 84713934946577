export default [
  {
    title: "1. Akceptacja Warunków:",
    desc: [
      {
        title: "1.1",
        desc: "Korzystając z naszego projektu zgodności z kamerami, potwierdzasz, że przeczytałeś, zrozumiałeś i zgadzasz się z tymi Warunkami i Warunkami.",
      },
    ],
  },
  {
    title: "2. Polityka Prywatności:",
    desc: [
      {
        title: "2.1",
        desc: "Twoje korzystanie z naszych usług podlega również naszej Polityce Prywatności. Korzystając z naszych usług, wyrażasz zgodę na zbieranie, wykorzystywanie i udostępnianie Twoich danych osobowych, zgodnie z opisem w Polityce Prywatności.",
      },
    ],
  },

  {
    title: "3. Korzystanie z Usług:",
    desc: [
      {
        title: "3.1",
        desc: "Twoje korzystanie z naszych usług podlega również naszej Polityce Prywatności. Korzystając z naszych usług, wyrażasz zgodę na zbieranie, wykorzystywanie i udostępnianie Twoich danych osobowych, zgodnie z opisem w Polityce Prywatności.",
      },
      {
        title: "3.2",
        desc: "Musisz przestrzegać wszystkich obowiązujących praw i przepisów podczas korzystania z naszych usług.",
      },
    ],
  },

  {
    title: "4. Własność Intelektualna:",
    desc: [
      {
        title: "4.1",
        desc: "Wszystkie treści, materiały i własność intelektualna związane z naszym projektem zgodności z kamerami są własnością nas lub naszych licencjodawców. Nie wolno Ci reprodukować, dystrybuować, modyfikować ani tworzyć prac pochodnych opartych na naszych treściach bez naszej wyraźnej zgody.",
      },
    ],
  },

  {
    title: "5. Zachowanie Użytkownika:",
    desc: [
      {
        title: "5.1",
        desc: "Jesteś odpowiedzialny za dokładność i legalność informacji, które dostarczasz podczas korzystania z naszych usług.",
      },
      {
        title: "5.2",
        desc: "Nie wolno Ci angażować się w żadne zachowanie, które mogłoby zakłócić lub zaszkodzić naszym usługom, innym użytkownikom lub naszej reputacji.",
      },
    ],
  },

  {
    title: "6. Ograniczenie Odpowiedzialności:",
    desc: [
      {
        title: "6.1",
        desc: "Nasz projekt zgodności z kamerami jest udostępniany „tak jak jest” i „zgodnie z dostępnością”. Nie gwarantujemy dokładności, niezawodności ani dostępności usług.",
      },
      {
        title: "6.2",
        desc: "Nie ponosimy odpowiedzialności za żadne szkody, straty ani odpowiedzialność wynikające z korzystania z naszych usług lub jakiejkolwiek informacji uzyskanej za pośrednictwem naszych usług. Nie gwarantujemy dokładności, niezawodności ani dostępności usług.",
      },
    ],
  },

  {
    title: "7. Ochrona Prawna:",
    desc: [
      {
        title: "7.1",
        desc: "Zgadzasz się zrekompensować nam i zwolnić nas od wszelkich roszczeń, strat, odpowiedzialności i wydatków (w tym opłat za usługi prawne) wynikających z korzystania z naszych usług lub naruszenia tych Warunków i Warunków.",
      },
    ],
  },
  {
    title: "8. Modyfikacje i Zakończenie:",
    desc: [
      {
        title: "8.1",
        desc: "Zastrzegamy sobie prawo do modyfikowania, zawieszania lub zakończenia naszego projektu zgodności z kamerami w dowolnym czasie, bez wcześniejszego powiadomienia.",
      },
      {
        title: "8.2",
        desc: "Możemy również modyfikować te Warunki i Warunki od czasu do czasu. Kontynuując korzystanie z naszych usług po takich modyfikacjach, wyrażasz zgodę na akceptację zaktualizowanych warunków.",
      },
    ],
  },
  {
    title: "9. Prawo Właściwe:",
    desc: [
      {
        title: "9.1",
        desc: "Te Warunki i Warunki są regulowane i interpretowane zgodnie z prawem [Jurysdykcji]. Wszelkie spory wynikające z lub w związku z tymi warunkami będą podlegać wyłącznej jurysdykcji sądów w [Jurysdykcji].",
      },
    ],
  },
  {
    title: "10. Skontaktuj się z nami:",
    desc: [
      {
        desc: "Jeśli masz pytania, obawy lub zapytania dotyczące tych Warunków i Warunków lub korzystania z naszych usług, skontaktuj się z nami pod adresem [adres e-mail kontaktowy]. Korzystając z naszego projektu zgodności z kamerami, zgadzasz się być związany tymi Warunkami i Warunkami. Jeśli nie zgadzasz się z którąkolwiek częścią tych warunków, prosimy nie korzystaj z naszych usług.",
      },
    ],
  },
];
