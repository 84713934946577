import styled from "styled-components";

export const CardWrapper = styled.div`
  gap: 20px;
  padding: 8px 20px;
  background-color: #efefef;
  margin-bottom: 1rem;
  border-radius: 8px;
  box-shadow: 0px 0px 5px 0px #a0a0a0;
`;
