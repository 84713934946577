import React from "react";
import { styled } from "styled-components";
import NoBannerImage from "../../../assets/nobanner.png";
import { media } from "styles/themes/constants";
const BannerCityImage = styled.img`
  width: 100%;
  border-radius: 10px;
  height: 150px;
  object-fit: cover;
  margin-bottom: 1rem;
  @media only screen and ${media.md} {
    height: 250px;
  }
  @media only screen and ${media.xxl} {
    height: 400px;
  }
  border: 1px solid #efefef;
`;

const BannerImage = ({ bannerImg }: any) => {
  return (
    <div>
      <BannerCityImage
        src={bannerImg}
        alt={!!bannerImg ? bannerImg : "no banner"}
      />
    </div>
  );
};

export default BannerImage;
