import { Popover } from "antd";
import React from "react";
import { AiOutlineFilter } from "react-icons/ai";
import FilterOverlay from "./FilterOverlay";
import { styled } from "styled-components";
import { useTranslation } from "react-i18next";
import BaseButton from "../BaseButton";
import { FONT_SIZE } from "styles/themes/constants";

const BaseFilter = ({ children, open, setOpen }) => {
  const { t } = useTranslation();
  return (
    <PopoverStyle
      content={
        <FilterOverlay>
          <>{children}</>
        </FilterOverlay>
      }
      title={t("dashboard.filter")}
      trigger={"click"}
      placement="leftTop"
      open={open}
      onOpenChange={(value) => setOpen(value)}
    >
      <BaseButton
        style={{
          marginRight: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <AiOutlineFilter
          style={{
            fontSize: "18px",
          }}
          onClick={() => setOpen(true)}
        />
        <FilterText>{t("dashboard.filter")}</FilterText>
      </BaseButton>
    </PopoverStyle>
  );
};

export default BaseFilter;
const PopoverStyle = styled(Popover)`
  &.ant-popover {
    z-index: 0000;
  }
`;

const FilterText = styled.span`
  font-size: ${FONT_SIZE.xs};
  margin-left: 5px;
`;
