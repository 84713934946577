import dynamicQRImg from "../../Features/images/qr.png";
import stickerImg from "../../Features/images/sticker.png";
import publicPageImg from "../../Features/images/city-public-page.png";

export default [
  {
    img: dynamicQRImg,
    title: "Kraj i miasto w kodzie QR",
    description:
      "Nowoczesne miasta wymagają nowoczesnych rozwiązań. Dzięki Zgodności Kamery wprowadzamy zgodność z RODO do publicznych systemów monitoringu, chroniąc dane osobowe i zapewniając pełną przejrzystość w procesie.",
  },
  {
    img: stickerImg,
    title: "Naklejka z polem",
    description:
      "Naklejki są świetne! Umieść swoje nudne kody QR na kolorowych naklejkach, aby nadać im atrakcyjny wygląd.",
  },
  {
    img: publicPageImg,
    title: "Strona publiczna dla miast",
    description:
      "Dostosuj swoje strony publiczne według własnych potrzeb... Administratorzy miasta mogą dostosować je i dodać różne języki zgodnie z miastem.",
  },
];
