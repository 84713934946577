import styled from "styled-components";

export const CardWrapper = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  padding: 8px 20px;
  background-color: #efefef;
  margin-bottom: 8px;
  border-radius: 8px;
`;

export const Logo = styled.img`
  width: 45px;
  aspect-ratio: 1;
`;
