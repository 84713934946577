import React from "react";
import styled from "styled-components";

const ScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default ScreenContainer;
