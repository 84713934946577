import styled from "styled-components";
export const SolutionContainer = styled.div`
  display: flex;
  max-width: 1200px;
  flex-direction: column;
  margin: 0 auto;
  margin-bottom: 70px;
  padding: 0 50px;

  & .title {
    text-align: center;
    margin-bottom: 30px;
    color: #167a8b;
    font-size: 1.8rem;
    font-weight: 600;
  }

  & .main {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    gap: 20px;
    width: 100%;

    & .container {
      display: flex;
      flex-direction: column;

      align-items: center;
      padding: 20px;
      width: 100%;
      border-radius: 10px;
      border: 1px solid #595959;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

      & .image-container {
        height: 50px;
        width: 50px;
        background-color: #39bdd4;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        margin-bottom: 10px;
      }

      & .container-title {
        color: #576874;
      }

      & .description-list {
        color: #818181;
        font-size: 28px;
        padding: 0px 0px 0px 15px;
        display: block;
        gap: 10px;
        list-style-type: none;

        li {
          display: flex;
          gap: 10px;
          margin-bottom: 14px;
        }
      }

      & .text {
        color: #363838;
        font-family: "Open Sans", Arial, sans-serif;
      }
      & .container-title {
        text-align: center;
        color: #1b1c1c;
        margin-bottom: 10px;
      }

      & .image {
        text-align: center;
        height: 24px;
      }
    }
  }
`;
