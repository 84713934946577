import { styled } from "styled-components";

export const CategoriesStyled = styled.div``;

export const SingleSubCategoryWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px 5px;
  border-radius: 6px;

  & .icon {
    height: 18px;
    width: 18px;
  }

  & .delete-icon {
    color: var(--red);
  }
  & .edit-icon {
    color: var(--skyblue);
  }

  & button {
    margin-left: 10px;
    padding: 0px;
  }

  &:hover {
    background: #f0f0f0;
  }
`;
