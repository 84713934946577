import BaseButton from "components/common/BaseButton";
import React from "react";
import { styled } from "styled-components";
import { FlexEnd, flexRow, flexRowAlignJustifyCenter } from "utils/styles";

const ActionButton = ({ actionTitle, handleAction, children }: any) => {
  return (
    <>
      {actionTitle && (
        <ButtonStyles
          onClick={() => {
            handleAction && handleAction();
          }}
          style={{
            background: "var(--primary)",
            color: "#fff",
          }}
        >
          {children}
          {actionTitle}
        </ButtonStyles>
      )}
    </>
  );
};

export default ActionButton;
const ButtonStyles = styled(BaseButton)`
  background: var(--primary);
  color: #fff;
  ${flexRowAlignJustifyCenter}
`;
