import BaseButton from "components/common/BaseButton";
import { BaseTable } from "components/common/BaseTable";
import HeaderWrapper from "components/headerwrapper";
import useAPI from "hooks/useApi";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { convertTimestamp, fullConvertTimestamp } from "utils/utils";
import * as Yup from "yup";
import SAdminAddCity from "./AddCity/SAdminAddCity";
import { CapitalText, TableImageIcon, flexRow } from "utils/styles";
import AddAdminModal from "containers/Admin/AddAdminModal";
import { styled } from "styled-components";
import EyeOutlined from "@ant-design/icons/EyeOutlined";
import UserAddOutlined from "@ant-design/icons/UserAddOutlined";
import city from "shared/front/apis/city.api";
import { useSelector } from "react-redux";
import BaseToolTip from "components/common/BaseTooltip";
import BaseSearch from "components/common/BaseSearch";
import ActionWrapper from "components/ActionWrapper/ActionWrapper";
import ActionButton from "components/ActionWrapper/ActionButton";
import { BasePagination } from "components/common/BasePagination";
import * as Styles from "./index.styles";
import { useTranslation } from "react-i18next";
import { useResponsive } from "hooks/useResponsive";
import CitiesTableRowCard from "./CitiesTableRowCard";
import { useDispatch } from "react-redux";
import type { Dispatch } from "redux";
import store from "store";
import { toast } from "react-toastify";
import AlterActionWrapper from "components/ActionWrapper/AlterActionWrapper";

const validateSchema = Yup.object().shape({
  title: Yup.string()
    .required("City name is required")
    .min(2, "username must be at least 2 characters long")
    .max(25, "username cannot exceed 25 characters"),

  desc: Yup.string()
    .required("Description is required")
    .min(2, "Desc must be at least 2 characters long")
    .max(150, "Desc cannot exceed 25 characters"),

  image: Yup.string().required("City icons are required"),
});

const City = () => {
  const { isLargeMobile } = useResponsive();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch: Dispatch = useDispatch();
  const cities = useSelector((state: any) => state.cities.data);
  const pagination = useSelector((state: any) => state.cities.pagination);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAdminModalVisible, setIsAdminModalVisible] = useState(false);
  const [cityId, setCityId] = useState(null);

  const [handleRequest, { loading: submitLoader }] = useAPI();
  const [getRequest, { loading: loader }] = useAPI();

  const openModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const openAdminModal = () => {
    setIsAdminModalVisible(true);
  };

  const closeAdminModal = () => {
    setIsAdminModalVisible(false);
  };

  const handlePagination = (page, limit) => {
    const { search } = pagination;
    dispatch(
      store.Actions["update"]("cities.pagination", {
        page,
        limit,
      })
    );
    getAllCity({ page, limit, ...(search && { search }) });
  };

  const handleSearch = (search) => {
    dispatch(store.Actions["update"]("cities.pagination", { search, page: 0 }));
    getAllCity({
      search,
      page: 0,
      limit: pagination.limit,
    });
  };

  const handleAddCity = async (values: any) => {
    try {
      const resp = await handleRequest(city.addCity(values));

      setIsModalVisible(false);
      getAllCity(pagination);

      if (!!resp?.title) {
        toast.success(`${resp?.title} is successfully added as city.`);
      }
    } catch (error: any) {
      toast.error(error);
    }
  };

  const getAllCity = (params) => {
    getRequest(city.getCity("set", params));
  };

  useEffect(() => {
    if (cities.length === 0) {
      const { page, limit } = pagination;
      getAllCity({
        page,
        limit,
      });
    }
  }, []);

  const columns = [
    {
      title: t("dashboard.s.n."),
      dataIndex: "rowNo",
      key: "rowNo",
    },
    {
      title: t("dashboard.logo"),
      dataIndex: "logo",
      key: "logo",
      render: (imageURL: string, record: any) => {
        return imageURL ? (
          <TableImageIcon alt={imageURL} src={imageURL} />
        ) : (
          <></>
        );
      },
    },
    {
      title: t("dashboard.city-name"),
      dataIndex: "city",
      key: "city",
      render: (text: any) => {
        return <CapitalText>{text}</CapitalText>;
      },
    },
    {
      title: t("dashboard.created-date"),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: any, record: any, index: any) => {
        return (
          <BaseToolTip title={fullConvertTimestamp(text)}>
            <span>{convertTimestamp(text)}</span>
          </BaseToolTip>
        );
      },
    },
    {
      title: t("dashboard.assigned-admin"),
      dataIndex: "assignedAdmin",
      key: "assignedAdmin",
      render: (data: any, record: any, index: any) => {
        return (
          <>
            {!!data ? (
              <CapitalText>
                {data?.firstName + " " + data?.lastName}
              </CapitalText>
            ) : (
              t("dashboard.not-available")
            )}
          </>
        );
      },
    },
    {
      title: t("dashboard.action"),
      dataIndex: "action",
      key: "action",
      align: "right" as "right",
      render: (text: any, record: any) => {
        return (
          <Styles.ActionContainer>
            {!record?.assignedAdmin && (
              <BaseButton
                className="add-admin"
                onClick={async (e: any) => {
                  e.stopPropagation();
                  await setCityId(record?.id);
                  openAdminModal();
                }}
              >
                <UserAddOutlined />
              </BaseButton>
            )}
            <BaseButton
              className="view-city"
              onClick={() => {
                navigate(`${record?.id}`);
              }}
            >
              <EyeOutlined />
            </BaseButton>
          </Styles.ActionContainer>
        );
      },
    },
  ];

  const dataSource = cities?.map((item: any, index: number) => ({
    rowNo: pagination.limit * pagination.page + index + 1,
    key: item._id,
    id: item._id,
    image: item.image,
    createdAt: item.createdAt,
    desc: item.desc,
    city: item.title,
    logo: item.logo,
    assignedAdmin: item?.assignBy,
  }));

  return (
    <>
      <HeaderWrapper title={t("dashboard.add-city")} />
      <AlterActionWrapper>
        <BaseSearch
          handleSearch={handleSearch}
          searchValue={pagination.search}
        />
        <ActionButton
          actionTitle={t("dashboard.add-city")}
          handleAction={openModal}
        />
      </AlterActionWrapper>
      {isModalVisible && (
        <SAdminAddCity
          loader={submitLoader}
          isModalVisible={isModalVisible}
          closeModal={closeModal}
          handleAddCity={handleAddCity}
        />
      )}

      {isAdminModalVisible && (
        <AddAdminModal
          onRefresh={getAllCity}
          isModalVisible={isAdminModalVisible}
          setIsModalVisible={setIsAdminModalVisible}
          openModal={openAdminModal}
          closeModal={closeAdminModal}
          citySelect={cityId}
        />
      )}
      {isLargeMobile ? (
        <BaseTable
          onRow={(record, rowIndex) => {
            return {
              onClick: () => {
                navigate(`${record?.id}`);
              },
            };
          }}
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          loading={loader}
        />
      ) : (
        <Styles.CardsWrapper>
          {cities?.map((city) => (
            <CitiesTableRowCard
              logo={city.logo}
              key={city.name}
              cityName={city.title}
              createdDate={city.createdAt}
              assignedAdmin={city.assignBy}
              onRowClick={(e) => {
                e.stopPropagation();
                navigate(`${city._id}`);
              }}
              onAddAdmin={async (e: any) => {
                e.stopPropagation();
                await setCityId(city?._id);
                openAdminModal();
              }}
            />
          ))}
        </Styles.CardsWrapper>
      )}

      <BasePagination
        pagination={pagination}
        handlePagination={handlePagination}
      />
    </>
  );
};

export default City;

const Column = styled.div`
  ${flexRow}
  & .add-admin {
    margin-right: 10px;
  }
`;
