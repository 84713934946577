import BaseButton from "components/common/BaseButton";
import { BaseCard } from "components/common/BaseCard";
import { BaseTypography } from "components/common/BaseTypography";
import React from "react";
import { styled } from "styled-components";
import { FONT_SIZE } from "styles/themes/constants";
//@ts-ignore
import { useTranslation } from "react-i18next";

const StepCard = ({
  title,
  buttontext,
  onClick,
  desc,
  iscompleted,
  disabled,
}) => {
  const { t } = useTranslation();
  return (
    <CardWrapper>
      <TitleText>{title}</TitleText>
      <DescriptionText>{desc}</DescriptionText>
      <div className="btn">
        {!iscompleted ? (
          <BaseButton size={"small"} onClick={onClick} disabled={disabled}>
            {buttontext}
          </BaseButton>
        ) : (
          <BaseTypography>
            {title} {t("remain.added")}
          </BaseTypography>
        )}
      </div>
    </CardWrapper>
  );
};

export default StepCard;

const CardWrapper = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  width: 300px;
  .btn {
    display: flex;
    justify-content: flex-end;
    .ant-btn {
      font-size: 12px;
      background-color: var(--primary);
      color: var(--white);
      &:hover {
        color: var(--white);
      }
    }
  }
`;

const TitleText = styled(BaseTypography)`
  font-size: ${FONT_SIZE.xs};
`;

const DescriptionText = styled(BaseTypography)`
  font-size: ${FONT_SIZE.xxs};
  color: var(--gray);
  margin: 10px 0px;
  min-height: 35px;
`;
