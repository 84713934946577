import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Pagination } from "swiper";
import { styled } from "styled-components";
import StepCard from "./StepCard";
import { useNavigate } from "react-router-dom";
import Slider from "components/Slider";

export default function Swipers({ stepsCard }) {
  const sortedSteps = stepsCard.sort((a, b) => {
    if (a.iscompleted === b.iscompleted) {
      // If iscompleted is the same, compare isDisabled
      if (a.disabled === b.disabled) {
        // If both iscompleted and disabled are the same, maintain the original order
        return 0;
      } else if (a.disabled) {
        // Place items with disabled: true before items with disabled: false
        return 1;
      } else {
        return -1;
      }
    } else if (a.iscompleted) {
      // Place items with iscompleted: true at the end
      return 1;
    } else {
      return -1;
    }
  });

  const navigate = useNavigate();
  const handleStepClick = (stepTitle) => {
    switch (stepTitle) {
      case "Sticker":
        navigate(`sticker?addSticker=true`);
        break;
      case "QR":
        navigate(`generated-qr?createQrModal=true`);
        break;
      case "Operator":
        navigate(`operator?operatorModal=true`);
        break;

      case "Camera":
        navigate(`camera?openCameraModal=true`);
        break;
      case "Public Page":
        navigate(`public-page/edit`);
        break;
      case "Group":
        navigate(`group?openGroupModal=true`);
        break;
      default:
        break;
    }
  };

  // return (
  //   <>
  //     <SwiperWrapper
  //       slidesPerView={3}
  //       spaceBetween={30}
  //       pagination={{
  //         clickable: true,
  //       }}
  //       // modules={[Pagination]}
  //       className="mySwiper"
  //     >
  //       {steps
  //         ?.filter((item) => !item.disabled)
  //         ?.map((item) => {
  //           return (
  //             <SwiperSliderWrapper>
  //               <SliderWrapper>
  //                 <StepCard
  //                   title={item.title}
  //                   buttonText={item.buttonText}
  //                   onClick={() => handleStepClick(item.title)}
  //                   desc={item.description}
  //                   disabled={item?.disabled}
  //                   current={current}
  //                   id={item.id}
  //                 />
  //               </SliderWrapper>
  //             </SwiperSliderWrapper>
  //           );
  //         })}
  //     </SwiperWrapper>
  //   </>
  // );

  return (
    <>
      <Slider wrapperHeight={150} gap={30}>
        {sortedSteps
          // ?.filter((item) => !item.disabled)
          ?.map((item) => {
            return (
              <SliderWrapper key={item.id}>
                <StepCard
                  title={item?.title}
                  buttontext={item?.buttontext}
                  onClick={() => handleStepClick(item.title)}
                  desc={item?.description}
                  disabled={item?.disabled}
                  iscompleted={item?.iscompleted}
                />
              </SliderWrapper>
            );
          })}
      </Slider>
    </>
  );
}

const SwiperSliderWrapper = styled(SwiperSlide)``;

const SliderWrapper = styled.div`
  width: 300px;
  height: 130px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 5px 10px 0px;
  border-radius: 8px;
`;

const SwiperWrapper = styled(Swiper)`
  padding: 30px 20px;
  .swiper-pagination {
    position: none !important;
    margin-top: 30px;
  }
`;
