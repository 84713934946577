const updatedQr = {
  getAllUpdatedQr: (params: any): any => {
    return {
      url: `QR/getAllUpdatedQRCodes`,
      method: "get",
      params,
    };
  },
  getDetailsByQRCodeId: (id: any): any => {
    return {
      url: `QR/getActiveQRCodeDetailsById/${id}`,
    };
  },
};

export default updatedQr;
