import { Col, Row } from "antd";
import useAPI from "hooks/useApi";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import dashboardApi from "shared/front/apis/dashboard.api";
import { ActivityCard } from "./activityCard/ActivityCard";
import { TopCityCard } from "./bloodScreeningCard/TopCityCard/TopCityCard";
import { DevicesAnalytics } from "./DevicesAnalytics/DevicesAnalytics";
import * as S from "./index.styles";
import { OperatorScreeningCard } from "./operatorStatsCard/BloodScreeningCard/BloodScreeningCard";
import { StatisticsCards } from "./statisticsCards/StatisticsCards";
import { useRoot } from "RootProvider";
import BaseLoading from "components/common/BaseSpin";
import { ADMIN, SUPER_ADMIN } from "constants/roles/roles";
import Leaflet from "containers/City/SingleCity/Leaflet";
import { styled } from "styled-components";
import OnBoarding from "./OnBoarding";
import NotGroupedCameraQR from "./notGroupedQRCamera";
import BannerImage from "./BannerImage";
import LogoImage from "./LogoImage";

const Dashboard = () => {
  const { role } = useRoot();
  const [getRequest, { loading: loader }] = useAPI();
  const dashboardData = useSelector((state: any) => state.dashboard);

  const dashboard = async () => {
    await getRequest(dashboardApi.dashboard());
  };
  const bannerImg = dashboardData?.cityDetails?.bannerImage;
  const logoImg = dashboardData?.cityDetails?.logo;
  const totalWeeklyScan = dashboardData?.TotalWeeklyScan;
  const QRCodeDetails = dashboardData?.QRCodeDetails;

  useEffect(() => {
    dashboard();
  }, []);

  return (
    <>
      {!!loader && Object.keys(dashboardData).length === 0 ? (
        <BaseLoading />
      ) : (
        Object.keys(dashboardData).length > 0 && (
          <Row>
            {role === ADMIN && !!bannerImg && (
              <Col span={24} style={{ marginBottom: "50px" }}>
                <div style={{ position: "relative" }}>
                  <BannerImage bannerImg={bannerImg} />
                  {role === ADMIN && (
                    <S.LogoWrapper>
                      {<LogoImage logoImg={logoImg} />}
                    </S.LogoWrapper>
                  )}
                </div>
              </Col>
            )}

            <S.LeftSideCol>
              {role === ADMIN && (
                <Col span={24}>
                  <OnBoarding />
                </Col>
              )}
              {role === ADMIN && <NotGroupedCameraQR />}
              <Row gutter={[30, 30]}>
                <Col xs={24} lg={16}>
                  <div style={{ marginBottom: "30px" }}>
                    <StatisticsCards stats={dashboardData} />
                  </div>
                  <div>
                    <DevicesAnalytics dashboardData={dashboardData} />
                  </div>
                </Col>
                <Col xs={24} lg={8}>
                  <S.ScreeningCardWrapper>
                    {role === SUPER_ADMIN && (
                      <div id="blood-screening">
                        <TopCityCard dashboardData={dashboardData} />
                      </div>
                    )}
                    {role === ADMIN && (
                      <div id="operator-screening">
                        <OperatorScreeningCard dashboardData={dashboardData} />
                      </div>
                    )}
                  </S.ScreeningCardWrapper>
                </Col>

                <Col id="activity" xs={24}>
                  <ActivityCard totalWeeklyScan={totalWeeklyScan} />
                </Col>
                {role === ADMIN && (
                  <Col id="Leaflet" xs={24}>
                    <Leaflet QRCodeDetails={QRCodeDetails} />
                  </Col>
                )}
              </Row>
            </S.LeftSideCol>
          </Row>
        )
      )}
    </>
  );
};

export default Dashboard;

const GroupedQRCamera = styled.div`
  margin-top: 20px;
`;
