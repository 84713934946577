import dynamicQRImg from "../../Features/images/qr.png";
import stickerImg from "../../Features/images/sticker.png";
import publicPageImg from "../../Features/images/city-public-page.png";

export default [
  {
    img: dynamicQRImg,
    title: "QR för land och stad",
    description:
      "Moderna städer kräver moderna lösningar. Med Camera Compliance inför vi GDPR-efterlevnad i offentliga övervakningssystem, skyddar personuppgifter och säkerställer fullständig öppenhet i processen.",
  },
  {
    img: stickerImg,
    title: "Nöjda med fält",
    description:
      "Klistermärken är fantastiska! Placera dina tråkiga QR-koder på färgglada klistermärken för att ge dem attraktivt utseende.",
  },
  {
    img: publicPageImg,
    title: "Offentlig sida för städer",
    description:
      "Anpassa dina offentliga sidor efter dina behov... Stadsadministratörer kan anpassa och lägga till olika språk enligt stad.",
  },
];
