import React from "react";
import { AboutUsStyled } from "./style";
import aboutUsImage from "../../assets/about-us.png";
import { Typography } from "antd";
import { useTranslation } from "react-i18next";
type Props = {};

const AboutUs = (props: Props) => {
  const { t } = useTranslation();

  return (
    <AboutUsStyled>
      <div className="left-contents">
        <img src={aboutUsImage} alt="hero" />
      </div>
      <div className="right-contents">
        <Typography.Text className="about-us">
          {/* {t("homePage.about-us")} */}
        </Typography.Text>
        <Typography.Title className="title">
          {/* {t("homePage.about-us-title")} */}
          Our Mission
          {/* Finland GDPR compliance */}
        </Typography.Title>
        <Typography.Paragraph className="description">
          {/* {t("homePage.about-us-description")} */}
          At Camera Compliance Ltd, our mission is to innovate and implement
          advanced technological solutions that empower cities and
          municipalities to uphold GDPR compliance effortlessly. We are
          dedicated to enhancing public understanding and trust in surveillance
          practices by providing accessible, transparent, and user-friendly
          tools. Our goal is to bridge the gap between public safety needs and
          individual privacy rights, ensuring that every intersection camera in
          the urban landscape respects and protects the data privacy of each
          citizen
        </Typography.Paragraph>
      </div>
    </AboutUsStyled>
  );
};

export default AboutUs;
