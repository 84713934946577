export default [
  {
    title: "1. Informations que nous recueillons :",
    desc: [
      {
        title: "1.1",
        desc: "Informations personnelles : Lorsque vous interagissez avec notre projet de conformité des caméras, nous pouvons recueillir des informations personnelles telles que votre nom, adresse e-mail et coordonnées de contact.",
      },
      {
        title: "1.2",
        desc: "Informations sur l'appareil : Nous pouvons recueillir des informations sur l'appareil que vous utilisez pour accéder à nos services, y compris le type d'appareil, le système d'exploitation, le type de navigateur et l'adresse IP.",
      },
      {
        title: "1.3",
        desc: "Informations de localisation : Avec votre consentement explicite, nous pouvons recueillir des informations de localisation depuis votre appareil pour vous fournir des informations de conformité précises liées à la ville et à la région dans laquelle vous vous trouvez.",
      },
      {
        title: "1.4",
        desc: "Informations de numérisation de la caméra : Lorsque vous numérisez un code QR sur un poteau avec une caméra, nous pouvons recueillir des données liées à l'emplacement de la caméra, à la date et à l'heure de la numérisation, ainsi qu'aux informations de conformité récupérées.",
      },
    ],
  },
  {
    title: "2. Utilisation de vos informations :",
    desc: [
      {
        title: "2.1",
        desc: "Nous utilisons les informations recueillies pour vous fournir des informations de conformité précises liées à la zone de la ville où vous avez numérisé le code QR.",
      },
      {
        title: "2.2",
        desc: "Nous pouvons utiliser vos informations personnelles pour vous envoyer des mises à jour, des notifications et des informations importantes liées au projet de conformité des caméras.",
      },
      {
        title: "2.3",
        desc: "Nous analysons des données agrégées et anonymisées à des fins statistiques, ce qui peut nous aider à améliorer nos services.",
      },
    ],
  },

  {
    title: "3. Partage des informations :",
    desc: [
      {
        title: "3.1",
        desc: "Nous ne partageons pas vos informations personnelles avec des tiers à des fins de marketing sans votre consentement explicite.",
      },
      {
        title: "3.2",
        desc: "Nous pouvons partager vos informations avec nos prestataires de services qui nous aident à fournir nos services. Ces prestataires de services sont tenus de maintenir la confidentialité de vos informations.",
      },
      {
        title: "3.3",
        desc: "Nous pouvons divulguer vos informations si la loi l'exige ou pour protéger nos droits, notre vie privée, notre sécurité ou nos biens.",
      },
    ],
  },

  {
    title: "4. Sécurité des données :",
    desc: [
      {
        title: "4.1",
        desc: "Nous mettons en œuvre des mesures de sécurité pour protéger vos informations personnelles contre tout accès non autorisé, toute altération, divulgation ou destruction.",
      },
      {
        title: "4.2",
        desc: "Malgré nos efforts, aucune transmission en ligne ou aucun stockage n'est totalement sécurisé. Par conséquent, nous ne pouvons garantir la sécurité absolue de vos informations.",
      },
    ],
  },

  {
    title: "5. Vos choix :",
    desc: [
      {
        title: "5.1",
        desc: "Vous pouvez choisir de ne pas recevoir de communications marketing de notre part en suivant les instructions de désabonnement fournies dans la communication.",
      },
      {
        title: "5.2",
        desc: "Vous pouvez contrôler la collecte d'informations de localisation en ajustant les paramètres de votre appareil.",
      },
    ],
  },

  {
    title: "6. Confidentialité des enfants :",
    desc: [
      {
        title: "6.1",
        desc: "Nos services ne sont pas destinés aux personnes de moins de [âge]. Nous ne recueillons pas sciemment d'informations personnelles auprès des enfants.",
      },
    ],
  },

  {
    title: "7. Modifications de cette politique de confidentialité :",
    desc: [
      {
        title: "7.1",
        desc: "Nous pouvons mettre à jour cette politique de confidentialité de temps à autre pour refléter les changements dans nos pratiques ou pour d'autres raisons opérationnelles, légales ou réglementaires.",
      },
      {
        title: "7.2",
        desc: "Nous fournirons un avis important de tout changement substantiel à cette politique de confidentialité.",
      },
    ],
  },
  {
    title: "8. Contactez-nous :",
    desc: [
      {
        desc: "Si vous avez des questions, des préoccupations ou des demandes concernant cette politique de confidentialité ou l'utilisation de vos informations personnelles, veuillez nous contacter à [adresse e-mail de contact].",
      },
    ],
  },
];
