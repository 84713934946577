import React from "react";
import styled from "styled-components";
import { media } from "styles/themes/constants";

const ScreenContent = styled.div`
  max-width: 1400px;
  width: 100%;
  padding: 10px;
  @media only screen and ${media.md} {
    padding: 20px;
  }
`;

export default ScreenContent;
