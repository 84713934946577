import React from "react";
import * as S from "./index.styles";

const BaseError = ({
  children,
  size,
  color = "errorColor",
}: IBaseErrorPrpos) => {
  return (
    <>
      <S.ErrorText size={size} color={color}>
        {children}
      </S.ErrorText>
    </>
  );
};

export default BaseError;

interface IBaseErrorPrpos {
  children: React.ReactNode;
  size?: "large" | "small";
  color?: string;
}
