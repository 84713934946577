import { QRCode, Typography } from "antd";
import ViewQrSticker from "components/StickerSelection/ViewQrSticker";
import { useTranslation } from "react-i18next";
import { BiLink, BiTime } from "react-icons/bi";
import { GrStatusGood } from "react-icons/gr";
import { Link, useNavigate } from "react-router-dom";
import { convertTimestamp } from "utils/utils";
import DownloadOptionButton from "./DownloadOptionButton";
import { HeaderInfoStyled } from "./styled";

const HeaderInfo = ({ activeQrData }: any) => {
  const { t } = useTranslation();
  const QrDetails: any = activeQrData?.QRDetails;
  const publicUrl = activeQrData?.QRDetails?.frontendURL;
  console.log("qr details", QrDetails);

  const desiredPart = publicUrl?.split("/qr/")[1];
  const temppath = "/qr/" + desiredPart;

  if (!QrDetails?._id) {
    return null;
  }
  return (
    <HeaderInfoStyled>
      <div className="contents-container">
        <Typography.Title level={3}>
          {t("dashboard.camera-compliance")}
        </Typography.Title>
        <div className="info-wrapper">
          <div>
            <Typography.Text className="info">
              <GrStatusGood className="icons" />
              {QrDetails?.status}
            </Typography.Text>
            <Typography.Text className="info">
              <BiTime className="icons" />
              {convertTimestamp(QrDetails?.updatedAt)}
            </Typography.Text>

            {QrDetails?.status === "Active" && (
              <>
                <Typography.Text className="info">
                  <BiLink className="icons" />
                  <div>
                    <Typography.Text style={{ display: "block" }}>
                      {t("new.view-page-as-public")}
                    </Typography.Text>

                    <Link to={`${temppath}`} target="_blank">
                      cameracompliance.com/qr/{QrDetails?._id}
                    </Link>
                  </div>
                </Typography.Text>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="sticker-qr-container">
        <div className="sticker-wrapper">
          <ViewQrSticker
            frontEndURL={QrDetails?.frontendURL}
            stickerDimensions={QrDetails?.sticker?.stickerDimension}
            stickerURL={QrDetails?.sticker?.URL}
            style={{}}
            imgSize="center"
            className="sticker-qr"
          />
          <div className="info">
            <DownloadOptionButton
              sticker={QrDetails?.sticker}
              frontendURL={QrDetails?.frontendURL}
            />
          </div>
        </div>
        <div className="qr-wrapper">
          <QRCode
            value={QrDetails?.frontendURL || "-"}
            color={
              QrDetails?.sticker?.stickerDimension?.qrOptions?.color?.dark ||
              "#000"
            }
            bgColor={
              QrDetails?.sticker?.stickerDimension?.qrOptions?.color?.light ||
              "#fff"
            }
            bordered
          />
          <div className="info">
            <DownloadOptionButton
              downloadFor="qr"
              sticker={QrDetails?.sticker}
              frontendURL={QrDetails?.frontendURL}
            />
          </div>
        </div>
      </div>
    </HeaderInfoStyled>
  );
};

export default HeaderInfo;
