import dynamicQRImg from "../../Features/images/qr.png";
import stickerImg from "../../Features/images/sticker.png";
import publicPageImg from "../../Features/images/city-public-page.png";

export default [
  {
    img: dynamicQRImg,
    title: "País y Ciudad en Código QR",
    description:
      "Las ciudades modernas requieren soluciones modernas. Con Cumplimiento de Cámara, llevamos el cumplimiento de GDPR a los sistemas de vigilancia pública, protegiendo los datos personales y asegurando total transparencia en el proceso",
  },
  {
    img: stickerImg,
    title: "Calcomanía con Campo",
    description:
      "¡Las calcomanías son geniales! Coloca tus códigos QR aburridos en coloridas calcomanías para darles un aspecto atractivo.",
  },
  {
    img: publicPageImg,
    title: "Página Pública para Ciudades",
    description:
      "Personaliza tus páginas públicas según tus necesidades... Los administradores de la ciudad pueden personalizarlas y agregar diferentes idiomas según la ciudad",
  },
];
