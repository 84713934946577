import { Col, ColorPicker, Row, Space } from "antd";
import type { Color, ColorPickerProps } from "antd/es/color-picker";
import React, { useMemo, useState } from "react";
import { BaseTypography } from "../BaseTypography";
import { styled } from "styled-components";
import useClickOutside from "hooks/useClickOutside";
import BaseButton from "../BaseButton";
import { FlexEnd, FlexEndLastRow } from "utils/styles";

const BaseColorPicker = ({ label, ...props }) => {
  const [formatRgb, setFormatRgb] = useState<ColorPickerProps["format"]>("hex");
  const [open, setOpen] = useState(false);
  const colorPickerRef = useClickOutside(() => setOpen(false));
  // const rgbString = useMemo(
  //   () =>
  //     typeof props.value === "string" ? props.value : props.value.toRgbString(),
  //   [props.value]
  // );
  return (
    <Space direction="vertical" size="small" style={{ display: "flex" }}>
      <BaseTypography.Text>{label}</BaseTypography.Text>

      <Row align={"middle"} gutter={8} onClick={(e) => e.stopPropagation()}>
        <Space size={"large"}>
          <Col ref={colorPickerRef}>
            <ColoPickerWrapper
              style={props?.colorPickerWrapperStyle}
              open={open}
              onOpenChange={() => setOpen(true)}
              panelRender={(panel) => (
                <div className="custom-panel">
                  <div>{panel}</div>
                  <div
                    style={{
                      fontSize: 5,
                      color: "rgba(0, 0, 0, 0.88)",
                      lineHeight: "20px",
                      marginTop: 8,
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                    onClick={() => setOpen(false)}
                  >
                    <BaseButton
                      style={{
                        fontSize: "10px",
                        padding: "3px 7px",
                      }}
                    >
                      OK
                    </BaseButton>
                  </div>
                </div>
              )}
              {...props}
              format={formatRgb}
              onFormatChange={setFormatRgb}
              showText
            ></ColoPickerWrapper>
          </Col>
        </Space>
      </Row>
    </Space>
  );
};

export default BaseColorPicker;
const ColoPickerWrapper = styled(ColorPicker)`
  &.ant-color-picker-trigger {
    height: 40px;
    width: 200px;
    .ant-color-picker-color-block {
      width: 100%;
      height: 100%;
    }
  }
`;
