import React, { useState, useEffect } from "react";
import { BloodScreeningTable } from "../BloodScreeningTable/BloodScreeningTable";

import * as S from "./BloodScreeningCard.styles";
import { BloodTestResult, results } from "constants/bloodTestResults";
import { useTranslation } from "react-i18next";

export const OperatorScreeningCard = ({
  isOperatorPage,
  dashboardData,
}: IOperatorScreeningCardProps) => {
  const topOperator = dashboardData?.operators;
  const { t } = useTranslation();

  // const [activeItem, setActiveItem] = useState<BloodTestResult>(
  //   dashboardData?.operators[0]
  // );
  // As by putting this from operator array 0 it cause web crash so comment down the code

  const [activeItem, setActiveItem] = useState<BloodTestResult | null>(null);
  useEffect(() => {
    if (dashboardData?.operators && dashboardData.operators.length > 0) {
      setActiveItem(dashboardData.operators[0]);
    }
  }, [dashboardData]);

  return (
    <S.BloodScreeningCard
      title={
        <S.TitleWrapper>
          <S.TableHeader> {t("dashboard.top-operators")}</S.TableHeader>
          {!!activeItem?.test && (
            <S.ActiveItem>{activeItem?.test}</S.ActiveItem>
          )}
        </S.TitleWrapper>
      }
      padding={0}
    >
      <BloodScreeningTable
        activeItem={activeItem}
        setActiveItem={setActiveItem}
        isOperatorPage={isOperatorPage}
        topOperator={topOperator}
      />
    </S.BloodScreeningCard>
  );
};

interface IOperatorScreeningCardProps {
  isOperatorPage?: boolean;
  dashboardData?: any;
}
