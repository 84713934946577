import { AutoComplete, Button, Spin } from "antd";
import BaseButton from "components/common/BaseButton";
import { BaseCard } from "components/common/BaseCard";
import BaseModal from "components/common/BaseModal";
import { BaseTypography } from "components/common/BaseTypography";
import useAPI from "hooks/useApi";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { getI18n } from "react-i18next";
import { FaCheckCircle } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import groupApi from "shared/front/apis/group.api";
import QR from "shared/front/apis/qr.api";
import { styled } from "styled-components";
import { FONT_SIZE, FONT_WEIGHT } from "styles/themes/constants";
import request from "utils/request";
import { useTranslation } from "react-i18next";
import { devices } from "utils/theme";
import { convertTimestamp } from "utils/utils";
import { RxCrossCircled } from "react-icons/rx";
import { BaseTag } from "components/common/BaseTag";
import { COUNTRIES } from "constants/countries";
import BaseToolTip from "components/common/BaseTooltip";

const GroupViewInfo = ({
  id,
  groupId,
  isSelected,
  linkQRCode,
  groupDataById,
  isBoth,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();

  const [loading, setLoading] = useState({
    groupLoading: false,
  });

  const [groupState, setGroupState] = useState({
    qrId: id,
    newGroupId: "",
    oldGroupId: groupId ? groupId : "",
  });

  const groupsData = useSelector((state: any) => state?.groups?.data);
  const navigate = useNavigate();
  const { language: selectedlang } = getI18n();

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const initialParams = {
    limit: 10,
    page: 0,
  };

  const groupOptions = () => {
    const options = [];
    if (loading?.groupLoading) {
      options.push({
        label: <Spin size="small" />,
        value: null,
        disabled: true,
      });
    }

    if (Array.isArray(groupsData) && groupsData?.length > 0) {
      return [
        ...options,
        ...groupsData.map((val) => ({
          value: val?.name,
          label: val?.name,
          id: val._id,
        })),
      ];
    }
    return [
      {
        value: null,
        label: "no data found",
        disaled: true,
      },
    ];
  };
  const fetchAllGroup = async (params) => {
    setLoading((prev) => ({ ...prev, groupLoading: true }));

    const actionType = "set";
    // const actionType = cameraPagination?.page === 0 ? "set" : "update";
    await request(
      groupApi.getAllGroups(actionType, {
        limit: params.limit,
        page: params.page,
        search: params.search,
      })
    );
    setLoading((prev) => ({ ...prev, groupLoading: false }));
  };

  const onSearchGroup = (text) => {
    delayedFetchGroup({
      ...initialParams,
      search: text,
    });
  };
  const delayedFetchGroup = useCallback(
    debounce((params) => {
      fetchAllGroup(params);
    }, 500),
    []
  );

  const onLinkGroup = async () => {
    try {
      if (groupState) await request(groupApi.linkGroupToQr(groupState));
      await request(QR.getDetailsByQRCodeId(id));
      await linkQRCode();
    } catch (error) {
    } finally {
      setIsModalOpen(false);
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      fetchAllGroup(initialParams);
    }
  }, [isModalOpen]);
  const getSelectedLanguageTitle = (details) => {
    if ((Array.isArray(details) && details.length === 0) || !details) {
      return "N/A";
    }

    const selectedLangTitle = details.find((x) => x?.lang === selectedlang);
    if (!!selectedLangTitle?.title) {
      return selectedLangTitle?.title;
    }
    return details[0].title;
  };
  function getCountriesFromApiData() {
    const languages = groupDataById?.publicView?.details?.map(
      (item) => item.lang
    );
    return COUNTRIES.filter((country) => languages?.includes(country.language));
  }

  const matchingCountries = getCountriesFromApiData();

  return (
    <>
      <CameraInfoStyled>
        <div className="camera-wrapper">
          <BaseTypography.Title level={4} style={{ marginBottom: "-10px" }}>
            {t("remain.group")}{" "}
            {isSelected ? <FaCheckCircle color="#167a8b" size={18} /> : null}
          </BaseTypography.Title>
          <div className="no-camera-wrapper">
            <BaseTypography.Text>
              {t("remain.add-group-associated-with-this-qr")}
            </BaseTypography.Text>
            <BaseButton
              onClick={() => {
                setIsModalOpen(true);
              }}
              style={{ marginRight: "25px" }}
            >
              {t("remain.link-group")}
            </BaseButton>
          </div>
          <BaseCardStyled isSelected={isSelected} isBoth={isBoth}>
            {groupId ? (
              <GroupPageCard isSelected={isSelected} isBoth={isBoth}>
                <InfoWrapperStyled>
                  <div className="label">{t("remain.group-name")}</div>
                  <div className="value">{groupDataById?.name}</div>
                </InfoWrapperStyled>
                <InfoWrapperStyled>
                  <div className="label">{t("remain.public-page")}</div>
                  <div className="value">
                    {getSelectedLanguageTitle(
                      groupDataById?.publicView?.details
                    )}
                  </div>
                </InfoWrapperStyled>
                <InfoWrapperStyled>
                  <div className="label">{t("remain.category")}</div>
                  <div className="value">{groupDataById?.category?.name}</div>
                </InfoWrapperStyled>
                <InfoWrapperStyled>
                  <div className="label">{t("remain.created")}</div>
                  <div className="value">
                    {convertTimestamp(groupDataById?.createdAt)}
                  </div>
                </InfoWrapperStyled>
                {!!groupDataById?.publicView?.customization && (
                  <InfoWrapperStyled>
                    <div className="label">{t("new.show-camera-image")}</div>
                    <div className="value">
                      {groupDataById?.publicView?.customization
                        ?.isCameraDetails ? (
                        <BaseTag color="success">Enabled</BaseTag>
                      ) : (
                        <BaseTag color="error">Disabled</BaseTag>
                      )}
                    </div>
                  </InfoWrapperStyled>
                )}

                <InfoWrapperStyled>
                  <div className="label">{t("dashboard.language")}</div>
                  <div className="value">
                    {matchingCountries?.length > 0 ? (
                      <LanguagesRow>
                        {matchingCountries.map((item) => {
                          return (
                            <BaseTag
                              key={item.languageFullName}
                              style={{
                                width: "fit-content",
                              }}
                            >
                              <LanguageContainer key={item.language}>
                                <Flag>{item.flag}</Flag>
                                <LanguageValue>{item.language}</LanguageValue>
                              </LanguageContainer>
                            </BaseTag>
                          );
                        })}
                      </LanguagesRow>
                    ) : (
                      <>N/A</>
                    )}
                  </div>
                </InfoWrapperStyled>

                <InfoWrapperStyled>
                  <div className="label">{t("dashboard.associate-logo")}</div>
                  <div className="value">
                    {groupDataById?.publicView?.footerLogo?.length > 0 ? (
                      <LogoGrid>
                        <Wrapper>
                          {groupDataById?.publicView?.footerLogo?.map(
                            (item) => (
                              <div style={{ position: "relative" }}>
                                <BaseToolTip
                                  key={item?._id}
                                  title={
                                    <a
                                      href={item.link}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {item?.link}
                                    </a>
                                  }
                                >
                                  <LogoWrapper>
                                    {item.URL ? (
                                      <LogoImage
                                        src={item.URL}
                                        alt={`Logo ${item._id}`}
                                      />
                                    ) : (
                                      <p> {t("dashboard.no-data")}</p>
                                    )}
                                  </LogoWrapper>
                                </BaseToolTip>
                              </div>
                            )
                          )}
                        </Wrapper>
                      </LogoGrid>
                    ) : (
                      "N/A"
                    )}
                  </div>
                </InfoWrapperStyled>

                <div className="view-group-wrapper">
                  <Button
                    style={{ marginTop: "15px" }}
                    onClick={() => navigate(`/dashboard/group/${groupId}`)}
                  >
                    {t("remain.view-group")}
                  </Button>
                </div>
              </GroupPageCard>
            ) : (
              <NoLinkFound>
                {t("remain.no-group-linked-to-this-qr")}
              </NoLinkFound>
            )}
          </BaseCardStyled>
        </div>
      </CameraInfoStyled>
      <BaseModal
        title={t("new.add-group")}
        open={isModalOpen}
        onCancel={closeModal}
        onOk={onLinkGroup}
        okText={t("remain.link-group")}
      >
        <AutoComplete
          options={groupOptions()}
          style={{ width: "100%" }}
          placeholder={t("remain.search-group")}
          onSearch={(text) => onSearchGroup(text)}
          onSelect={(value, key) => {
            console.log("the value and key", value, key);
            setGroupState((prev) => ({ ...prev, newGroupId: key.id }));
          }}
        />
      </BaseModal>
    </>
  );
};

export default GroupViewInfo;
const CameraInfoStyled = styled.div`
  background: #fff;
  padding: 10px;
  box-shadow: 0px 0px 1px #ccc;
  margin-bottom: 30px;

  &.no-camera {
    background: #fff1;
    padding: 0px;
  }

  & .no-camera-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px 0px 20px;
    flex-wrap: wrap;
  }

  & .camera-wrapper {
    padding: 20px;

    & .camera-info-row {
      display: flex;
      align-items: center;
      margin-bottom: 6px;
      &-title {
        width: 120px;
        font-weight: 600;
        color: #666;
      }
      &-value {
        position: relative;
      }
    }
  }

  & .view-group-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 10px;
  }
`;

const BaseCardStyled = styled(BaseCard)<{
  isSelected: boolean;
  isBoth: boolean;
}>`
  /* background-color: ${({ isSelected, isBoth }) =>
    !isSelected && isBoth ? "#EBEBE4" : `var(--white)`}; */
`;

const GroupPageCard = styled.div<{
  isSelected: boolean;
  isBoth: boolean;
}>`
  & .label {
    width: 110px;
    font-weight: 600;
    color: ${({ isSelected, isBoth }) =>
      !isSelected && isBoth ? "#a1a19b" : `var(--black)`};
  }
  & .value {
    color: ${({ isSelected, isBoth }) =>
      !isSelected && isBoth ? "#a1a19b" : `var(--black)`};
  }
`;

const NoLinkFound = styled.div`
  text-align: center;
  font-style: italic;
  color: var(--gray);
  font-size: ${FONT_SIZE.md};
`;

const InfoWrapperStyled = styled.div`
  display: flex;
  margin-bottom: 8px;
  flex-wrap: wrap;
  align-items: start;
  flex-direction: column;
  @media (${devices.mobileSM}) {
    flex-direction: row;
    align-items: center;
  }
`;

export const LanguageContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const LanguagesRow = styled.div`
  display: flex;
  flex-direction: row;
  grid-gap: 5px;
`;

export const Flag = styled.span`
  font-size: 8px;
  margin-right: 4px;
  font-family: "Twemoji Country Flags";
`;

export const LanguageValue = styled.span`
  font-size: ${FONT_SIZE.xxs};
  font-weight: ${FONT_WEIGHT.regular};
`;

const LogoGrid = styled.div`
  display: flex;
  max-width: 100%;
  flex-direction: column;
  margin: 0 auto;
`;
const Wrapper = styled.div`
  display: flex;
  gap: 28px;
  flex-wrap: wrap;
  margin: 8px;
  align-items: center;
`;

const LogoWrapper = styled.div``;
const LogoImage = styled.img`
  width: 30px;
  height: 30px;
`;
