// import React, { useState } from "react";
// import { GoogleApiWrapper, Map, Marker } from "google-maps-react";

// const Location = ({ google, lat: _lat, lang: _lang }: any) => {
//   const [isMapReady, setMapReady] = useState(false);
//   return (
//     <>
//       <Map
//         google={google}
//         // @ts-ignore
//         mapTypeControl={false}
//         zoomControl={false}
//         fullscreenControl={false}
//         streetViewControl={false}
//         draggable={false}
//         onReady={(props, map) => {
//           setMapReady(true);
//         }}
//         containerStyle={{
//           position: "relative",
//         }}
//         center={{
//           lat: _lat,
//           lng: _lang,
//         }}
//         style={{
//           height: 400,
//           position: "fixed",
//           top: 0,
//           left: 0,
//           right: 0,
//           marginTop: 20,
//         }}
//         zoom={10}
//       >
//         {isMapReady && (
//           <Marker
//             // @ts-ignore
//             name={"helsinki"}
//             position={{
//               lat: _lat,
//               lng: _lang,
//             }}
//             // draggable
//             onDragEnd={(coords: any) => {
//               //   setSelectedAddress({
//               //     address: selectedAddress.address,
//               //     lat: coords?.latLng?.lat?.(),
//               //     lng: coords?.latLng?.lng?.(),
//               //   });
//               //
//             }}
//           />
//         )}
//       </Map>
//     </>
//   );
// };

// export default GoogleApiWrapper({
//   apiKey: process.env.GOOGLE_MAPS_API || "",
// })(Location);

import React from "react";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import marker from "leaflet/dist/images/marker-icon.png";
import "leaflet/dist/leaflet.css";
import { Icon } from "leaflet";

const myIcon = new Icon({
  iconUrl: marker,
  iconSize: [30, 30],
});

const Leaflet = ({ lat, long }) => {
  return (
    <>
      {!!lat && !!long && (
        <MapContainer
          center={[27.7172, 85.324]}
          zoom={12}
          style={{ height: "400px", width: "100%" }}
        >
          {/* <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      /> */}
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />

          <Marker position={[lat, long]} icon={myIcon}></Marker>
        </MapContainer>
      )}
    </>
  );
};

export default Leaflet;
