import { Typography } from "antd";
import React from "react";
import { SectionContainer } from "../style";
import { blogData } from "../../../data/blogs";
//@ts-ignore
import { useTranslation } from "react-i18next";
import { kebabCase, snakeCase } from "lodash";
import styled from "styled-components";
import { Link, NavLink, useNavigate } from "react-router-dom";

const BlogContainer = styled.div`
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 10px;
  max-width: 400px;
  overflow: hidden;
  cursor: pointer;

  & .text {
    color: gray;
    font-size: 14px;
    margin-bottom: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  & .container-title {
    text-align: center;
  }

  & .image {
    width: 100%;
    height: 200px;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
  }
`;

const Content = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

const ActionButton = styled.div`
  text-align: center;
  padding: 4px;
  span {
    color: #157b8b;
  }
`;

const Blogs = React.forwardRef((props, ref) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  return (
    //@ts-ignore
    <SectionContainer ref={ref}>
      <Typography.Title level={4} className="title">
        News
      </Typography.Title>

      <div className="main">
        {blogData?.map((blog) => {
          const { img, title, description } = blog;
          return (
            <BlogContainer
              key={blog.id}
              onClick={() => navigate(`/news/${kebabCase(blog.title)}`)}
            >
              <img src={img} className="image" alt={title} />
              <Content>
                <Typography.Title level={5}>{title}</Typography.Title>
                <Typography.Text className="text">
                  {description}
                </Typography.Text>

                <Typography.Text
                  style={{ textAlign: "center", textDecoration: "underline" }}
                >
                  Read More
                </Typography.Text>
              </Content>
            </BlogContainer>
          );
        })}
      </div>
    </SectionContainer>
  );
});

export default Blogs;
