import { BaseCard } from "components/common/BaseCard";
import React, { useCallback, useEffect, useState } from "react";
import BaseButton from "components/common/BaseButton";
import { BaseTypography } from "components/common/BaseTypography";
import BaseModal from "components/common/BaseModal";
import publicViewApi from "shared/front/apis/publicView.api";
import request from "utils/request";
import { useDispatch, useSelector } from "react-redux";
import store from "store";
import { AutoComplete, Button, Spin } from "antd";
import { getI18n } from "react-i18next";
import { debounce } from "lodash";
import { Link, useNavigate, useParams } from "react-router-dom";
import QR from "shared/front/apis/qr.api";
import publicPage from "shared/front/apis/public-page.api";
import { styled } from "styled-components";
import useAPI from "hooks/useApi";
import { FONT_SIZE, FONT_WEIGHT } from "styles/themes/constants";
import { convertTimestamp } from "utils/utils";
import { BaseTag } from "components/common/BaseTag";
import { FaCheck, FaCheckCircle } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { devices } from "utils/theme";
import { RxCrossCircled } from "react-icons/rx";
import { COUNTRIES, LANGUAGES } from "constants/countries";
import { useLanguage } from "hooks/useLanguage";
import languageMapWithCountries from "utils/languagemaps";
import country from "shared/front/apis/country.api";
import BaseToolTip from "components/common/BaseTooltip";

const PublicViewInfo = ({
  id,
  publicPageId,
  isSelected,
  linkQRCode,
  isBoth,
}: any) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { language: selectedlang } = getI18n();
  const { language, setLanguage } = useLanguage();
  const languageOptions = LANGUAGES[language];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [
    makeRequest,
    { loading: publicViewloading, data: publicViewDataById },
  ] = useAPI();
  const navigate = useNavigate();
  const getPublicViewDataById = async (publicPageId) => {
    await makeRequest(publicPage.getPublicPageById(publicPageId));
  };

  useEffect(() => {
    if (publicPageId) {
      getPublicViewDataById(publicPageId);
    }
  }, [publicPageId]);
  const initialParams = {
    limit: 10,
    page: 0,
  };
  const [publicViewQrState, setPublicViewQrState] = useState({
    qrId: "",
    publicViewId: "",
  });
  const publicViews = useSelector((state: any) => state?.publicViews?.data);
  const [loading, setLoading] = useState({
    publicViewLoading: false,
  });

  const delayedFetchPublicView = useCallback(
    debounce((params) => {
      fetchAllPublicPages(params);
    }, 500),
    []
  );

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const publicViewsPagination = useSelector(
    (state: any) => state?.publicViews?.pagination
  );
  const fetchAllPublicPages = async (params) => {
    setLoading((prev) => ({ ...prev, publicViewLoading: true }));
    const actionType = publicViewsPagination?.page === 0 ? "set" : "update";
    await request(
      publicViewApi.getAllPublicViews(actionType, {
        limit: params.limit,
        page: params.page,
        title: params.title,
        lang: params.lang,
      })
    );

    setLoading((prev) => ({ ...prev, publicViewLoading: false }));
  };
  const getPublicViewData = async () => {
    if (isModalOpen) {
      dispatch(store.Actions.set("publicViews.pagination", initialParams));
      fetchAllPublicPages(initialParams);
    }
  };

  const getSelectedLanguageTitle = (details) => {
    if ((Array.isArray(details) && details.length === 0) || !details) {
      return "N/A";
    }

    const selectedLangTitle = details.find((x) => x?.lang === selectedlang);
    if (!!selectedLangTitle?.title) {
      return selectedLangTitle?.title;
    }
    return details[0].title;
  };

  const publicViewOptions = () => {
    const options = [];
    if (loading?.publicViewLoading) {
      options.push({
        label: <Spin size="small" />,
        value: null,
        disabled: true,
      });
    }

    if (Array.isArray(publicViews) && publicViews?.length > 0) {
      return [
        ...options,
        ...publicViews.map((val) => ({
          value: getSelectedLanguageTitle(val?.details),
          label: getSelectedLanguageTitle(val?.details),
          id: val._id,
        })),
      ];
    }
    return [
      {
        value: null,
        label: "no data found",
        disaled: true,
      },
    ];
  };

  const onSearchPublicView = (text) => {
    delayedFetchPublicView({
      ...initialParams,
      title: text,
      lang: selectedlang,
    });
  };
  const onLinkPublicPage = async () => {
    try {
      if (publicViewQrState)
        await request(publicViewApi.linkPublicViewToQr(publicViewQrState));
      await request(QR.getDetailsByQRCodeId(id));
      await linkQRCode();
    } catch (error) {
    } finally {
      setIsModalOpen(false);
    }
  };

  const getPublicViewPageDetailById = async (id) => {
    await request(publicPage.getPublicPageById(id));
  };

  useEffect(() => {
    if (publicPageId) {
      getPublicViewPageDetailById(publicPageId);
    }
  }, [publicPageId]);
  useEffect(() => {
    getPublicViewData();
  }, [isModalOpen]);

  useEffect(() => {
    setPublicViewQrState((prev) => ({ ...prev, qrId: id }));
  }, [id]);

  function getCountriesFromApiData() {
    const languages = publicViewDataById?.details?.map((item) => item.lang);
    return COUNTRIES.filter((country) => languages?.includes(country.language));
  }
  const matchingCountries = getCountriesFromApiData();

  return (
    <>
      <CameraInfoStyled>
        <div className="camera-wrapper">
          <BaseTypography.Title level={4} style={{ marginBottom: "-10px" }}>
            {t("remain.individual")} {t("remain.public-page")}{" "}
            {isSelected ? <FaCheckCircle color="#167a8b" size={18} /> : null}
          </BaseTypography.Title>
          <div className="no-camera-wrapper">
            <BaseTypography.Text>
              {t("remain.add-public-page-associated-with-this-qr")}
            </BaseTypography.Text>
            <BaseButton
              onClick={() => {
                setIsModalOpen(true);
              }}
              style={{ marginRight: "25px" }}
            >
              {t("remain.link-public-page")}
            </BaseButton>
          </div>

          <div>
            {publicPageId ? (
              <BaseCardStyled isSelected={isSelected} isBoth={isBoth}>
                <PublicPageCard isSelected={isSelected} isBoth={isBoth}>
                  <LeftPartDetails>
                    <InfoWrapperStyled>
                      <div className="label">{t("remain.title")}</div>
                      <div className="value">
                        {getSelectedLanguageTitle(publicViewDataById?.details)}
                      </div>
                    </InfoWrapperStyled>
                    <InfoWrapperStyled>
                      <div className="label">{t("remain.created")}</div>
                      <div className="value">
                        {convertTimestamp(publicViewDataById?.createdAt)}
                      </div>
                    </InfoWrapperStyled>
                    <InfoWrapperStyled>
                      <div className="label">{t("new.show-camera-image")}</div>
                      <div className="value">
                        {publicViewDataById?.customization?.isCameraDetails ? (
                          <BaseTag color="success">Enabled</BaseTag>
                        ) : (
                          <BaseTag color="error">Disabled</BaseTag>
                        )}
                      </div>
                    </InfoWrapperStyled>
                    <InfoWrapperStyled>
                      <div className="label">{t("dashboard.language")}</div>
                      <div className="value">
                        {matchingCountries?.length > 0 ? (
                          <LanguagesRow>
                            {matchingCountries.map((item) => {
                              return (
                                <BaseTag
                                  key={item.languageFullName}
                                  style={{
                                    width: "fit-content",
                                  }}
                                >
                                  <LanguageContainer key={item.language}>
                                    <Flag>{item.flag}</Flag>
                                    <LanguageValue>
                                      {item.language}
                                    </LanguageValue>
                                  </LanguageContainer>
                                </BaseTag>
                              );
                            })}
                          </LanguagesRow>
                        ) : (
                          <>N/A</>
                        )}
                      </div>
                    </InfoWrapperStyled>
                    <InfoWrapperStyled>
                      <div className="label">
                        {t("dashboard.associate-logo")}
                      </div>
                      <div className="value">
                        {publicViewDataById?.footerLogo?.length > 0 ? (
                          <LogoGrid>
                            <Wrapper>
                              {publicViewDataById?.footerLogo?.map((item) => (
                                <div style={{ position: "relative" }}>
                                  <BaseToolTip
                                    key={item?._id}
                                    title={
                                      <a
                                        href={item.link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {item?.link}
                                      </a>
                                    }
                                  >
                                    <LogoWrapper>
                                      {item.URL ? (
                                        <LogoImage
                                          src={item.URL}
                                          alt={`Logo ${item._id}`}
                                        />
                                      ) : (
                                        <p> {t("dashboard.no-data")}</p>
                                      )}
                                    </LogoWrapper>
                                  </BaseToolTip>
                                </div>
                              ))}
                            </Wrapper>
                          </LogoGrid>
                        ) : (
                          "N/A"
                        )}
                      </div>
                    </InfoWrapperStyled>
                  </LeftPartDetails>
                </PublicPageCard>

                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "20px",
                  }}
                >
                  <BaseButton
                    onClick={() =>
                      navigate(
                        `/dashboard/public-page/customize-page/${publicPageId}`
                      )
                    }
                  >
                    {t("remain.view-public-page")}
                  </BaseButton>
                </div>
              </BaseCardStyled>
            ) : (
              <BaseCard>
                <NoLinkFound>
                  {t("remain.no-public-page-linked-to-this-qr")}
                </NoLinkFound>
              </BaseCard>
            )}
          </div>
        </div>
      </CameraInfoStyled>

      <BaseModal
        title={t("remain.add-public-page")}
        open={isModalOpen}
        onCancel={closeModal}
        onOk={onLinkPublicPage}
        okText={t("remain.link-public-page")}
      >
        <AutoComplete
          options={publicViewOptions()}
          style={{ width: "100%" }}
          placeholder={t("remain.search-public-page")}
          onSearch={(text) => onSearchPublicView(text)}
          onSelect={(value, key) => {
            setPublicViewQrState((prev) => ({
              ...prev,
              publicViewId: key?.id,
            }));
          }}
        />
      </BaseModal>
    </>
  );
};

export default PublicViewInfo;

const BaseCardStyled = styled(BaseCard)<{
  isSelected: boolean;
  isBoth: boolean;
}>`
  /* background-color: ${({ isSelected, isBoth }) =>
    !isSelected && isBoth ? "#EBEBE4" : `var(--white)`}; */
`;
const CameraInfoStyled = styled.div`
  background: #fcfcfc;
  padding: 10px;
  box-shadow: 0px 0px 1px #ccc;
  margin-bottom: 30px;

  & .no-camera-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0px 20px;
    flex-wrap: wrap;
  }

  & .camera-wrapper {
    padding: 20px;

    & .camera-info-row {
      display: flex;
      align-items: center;
      margin-bottom: 6px;
      &-title {
        width: 120px;
        font-weight: 600;
        color: #666;
      }
      &-value {
        position: relative;
      }
    }
  }

  & .view-publicpage-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 10px;
  }
`;

const NoLinkFound = styled.div`
  text-align: center;
  font-style: italic;
  color: var(--gray);
  font-size: ${FONT_SIZE.md};
`;

const PublicPageCard = styled.div<{ isSelected: boolean; isBoth: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 12px;
  & .label {
    width: 110px;
    font-weight: 600;
    color: ${({ isSelected, isBoth }) =>
      !isSelected && isBoth ? "#a1a19b" : `var(--black)`};
  }
  & .value {
    color: ${({ isSelected, isBoth }) =>
      !isSelected && isBoth ? "#a1a19b" : `var(--black)`};
  }
`;

const LeftPartDetails = styled.div``;
const InfoWrapperStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  flex-direction: column;
  @media (${devices.mobileSM}) {
    flex-direction: row;
    align-items: center;
  }
  margin-bottom: 8px;
`;

export const LanguageContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Flag = styled.span`
  font-size: 8px;
  margin-right: 4px;
  font-family: "Twemoji Country Flags";
`;

export const LanguageValue = styled.span`
  font-size: ${FONT_SIZE.xxs};
  font-weight: ${FONT_WEIGHT.regular};
`;
export const LanguagesRow = styled.div`
  display: flex;
  flex-direction: row;
  grid-gap: 5px;
`;

const AssociateLogoTextStyle = styled(BaseTypography.Text)`
  font-size: ${FONT_SIZE.lg};
  font-weight: ${FONT_WEIGHT.semibold};
  margin: 20px;
`;

const LogoGrid = styled.div`
  display: flex;
  max-width: 100%;
  flex-direction: column;
  margin: 0 auto;
`;
const Wrapper = styled.div`
  display: flex;
  gap: 28px;
  flex-wrap: wrap;
  margin: 8px;
  align-items: center;
`;

const LogoWrapper = styled.div``;
const LogoImage = styled.img`
  width: 30px;
  height: 30px;
`;
