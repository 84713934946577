import { Divider, Tabs } from "antd";
import Editor from "components/Editor/Editor";
import { BaseTypography } from "components/common/BaseTypography";
import React, { useState } from "react";
import { styled } from "styled-components";
import { FONT_SIZE, media } from "styles/themes/constants";

const LanguageTabs = ({
  cState,
  cityDetailsArray,
  availableLanguages,
  onTabChange,
  selectedLang,
  selectedDetails,
}: any) => {
  return (
    <>
      <Tabs
        items={availableLanguages.map((val) => ({
          key: val?.langCode,
          label: (
            <LangText
              cState={cState}
              style={{ display: "flex", alignItems: "center" }}
            >
              {val?.flag} &ensp;
              {val?.language}
            </LangText>
          ),
        }))}
        onChange={onTabChange}
        activeKey={selectedLang}
      />
      {!!selectedDetails?.lang && (
        <div style={{ marginBottom: "50px" }}>
          <TitleDescriptions level={3} cState={cState}>
            {selectedDetails?.title}
          </TitleDescriptions>
          <Divider />
          <Editor isReadOnly editorState={selectedDetails?.desc} />
        </div>
      )}
    </>
  );
};

export default LanguageTabs;
const LangText = styled(BaseTypography.Text)<{
  cState: any;
}>`
  color: ${(props) => props?.cState?.fontColor} !important;
  font-size: ${FONT_SIZE.xxs} !important;
  @media only screen and ${media.md} {
    font-size: ${FONT_SIZE.xs} !important;
  }
`;

const TitleDescriptions = styled(BaseTypography.Title)<{
  cState: any;
}>`
  text-align: center;
  color: ${(props) => props?.cState?.fontColor} !important;
  font-size: ${FONT_SIZE.lg} !important;
  @media only screen and ${media.md} {
    font-size: ${FONT_SIZE.xxl} !important;
  }
`;
