import { toast } from "react-toastify";

const toastr = {
  success: (title) => {
    toast.success(`${title}`, {
      toastId: "success",
      position: "top-right",
      autoClose: 2500,
      hideProgressBar: false,
      closeOnClick: true,
      className: "toastr",
    });
  },

  error: (error) => {
    toast.error(`${error}`, {
      toastId: "error",
      className: "toastr",
    });
  },

  info: (info, autoClose = 2500) => {
    toast.info(`${info}`, {
      toastId: "info",
      autoClose,
      className: "toastr",
    });
  },
};

export default toastr;
