import React from "react";
import { useRoot } from "../../RootProvider";
import UserProfile from "./ProfileUser";
import * as S from "./index.styles";
import { useTranslation } from "react-i18next";

import { BaseTypography } from "components/common/BaseTypography";
import { useLocation } from "react-router-dom";
import { QR_HOME_PATH } from "constants/routes/route";
import LanguageSelection from "./LanguageSelection";
import { PopupNotifications } from "containers/Notifications";
const ProfileHeader = () => {
  const { auth } = useRoot();
  const location = useLocation();
  const { t } = useTranslation();
  const isHomePage = location.pathname === QR_HOME_PATH;

  return (
    <S.ProfileWrapper>
      {isHomePage && !!auth && (
        <BaseTypography>
          <S.AdminLink to={"/dashboard"}>
            {t("dashboard.dashboard")}
          </S.AdminLink>
        </BaseTypography>
      )}
      {/* <LanguageSelection /> */}
      <S.NotificationProfileWrapperStyled>
        {!!auth && <PopupNotifications />}
      </S.NotificationProfileWrapperStyled>
      <UserProfile auth={auth} />
    </S.ProfileWrapper>
  );
};

export default ProfileHeader;
