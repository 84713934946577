import { styled } from "styled-components";
import { FONT_SIZE, media } from "../../styles/themes/constants";
import { BaseTypography } from "../../components/common/BaseTypography";
import { Col } from "antd";

export const TextDescription = styled(BaseTypography.Text)`
  font-size: ${FONT_SIZE.xl};
`;
export const LeftSideCol = styled(Col)`
  padding: 0px 0.5rem 0.5rem;
  @media only screen and ${media.xl} {
    /* padding: 0rem 2.25rem 1.25rem; */
    overflow: auto;
  }
`;

export const RightSideCol = styled(Col)`
  padding: 1.25rem 2.25rem;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;
export const Space = styled.div`
  margin: 1rem 0;
`;

export const ScrollWrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 250px;

  .ant-card-body {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }
`;

export const BlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  gap: 15px;

  background: black;

  min-height: 300px;
  overflow-y: auto;
`;

export const Item = styled.div`
  background: red;
  height: 150px;
  flex-shrink: 0;
`;

export const LogoWrapper = styled.div`
  position: absolute;
  top: 120px;
  left: 22px;
  @media only screen and ${media.md} {
    top: 200px;
    left: 35px;
  }
  @media only screen and ${media.xxl} {
    top: 350px;
  }
`;

export const ScreeningCardWrapper = styled.div`
  @media only screen and ${media.lg} {
    // margin-top: 1.9rem;
  }
  height: 100%;

  & #blood-screening,
  & #operator-screening {
    height: 100%;

    @media only screen and ${media.lg} {
      height: 95%;
    }
  }
`;
