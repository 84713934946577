import { styled } from "styled-components";
import { FONT_SIZE, FONT_WEIGHT } from "styles/themes/constants";

export const LanguageContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Flag = styled.span`
  font-size: 8px;
  margin-right: 4px;
  font-family: "Twemoji Country Flags";
`;

export const LanguageValue = styled.span`
  font-size: ${FONT_SIZE.xxs};
  font-weight: ${FONT_WEIGHT.regular};
`;
export const LanguagesRow = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5px;
`;
