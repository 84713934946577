import { UploadOutlined } from "@ant-design/icons";
import { useResponsive } from "hooks/useResponsive";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { media } from "styles/themes/constants";

const JSONUpload = ({ setSelectedFile, allowed }: any) => {
  const { t } = useTranslation();
  const { isTablet } = useResponsive();

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const jsonData = JSON.parse(e.target.result);
        setSelectedFile(jsonData);
      };
      reader.readAsText(file);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        // alignItems: "center",
        marginBottom: "8px",
        flexDirection: isTablet ? "row" : "column",
      }}
    >
      <FileUploaderWrapper>
        <div className="drop-area">
          <input
            type="file"
            onChange={handleFileChange}
            accept={allowed}
            id="fileJOSNElem"
          />
        </div>
        <label className="upload-label" htmlFor="fileJOSNElem">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <UploadOutlined style={{ fontSize: "50px" }} />
            <div className="upload-text">
              {t("remain.click-to-upload")} JSON
            </div>
            <div className="upload-small-text">
              Click to import data from JSON.
              {/* {t("remain.upload-svg-file-format-for-sticker")} */}
            </div>
          </div>
        </label>
      </FileUploaderWrapper>
    </div>
  );
};

export default JSONUpload;

const FileUploaderWrapper = styled.div`
  width: 200px;
  height: 200px;
  margin: 20px auto;
  border: 3px dotted var(--border);
  @media ${media.md} {
    width: 300px;
    height: 300px;
  }
  #fileJOSNElem {
    display: none;
  }
  .upload-label {
    padding: 10px;
    cursor: pointer;
    border-radius: 6px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f6f6f6;
    transition: 0.3s;
  }
  .upload-label:hover {
    background-color: #e9e9e9;
  }
  .upload-label:active {
    background-color: #f6f6f6;
  }
  .upload-text {
    font-size: 16px;
    user-select: none;
  }
  .upload-small-text {
    font-size: 12px;
    color: #666;
  }
`;
