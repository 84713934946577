export default [
  {
    title: "1. Acceptans av villkor:",
    desc: [
      {
        title: "1.1",
        desc: "Genom att använda vårt projekt för kamerakompatibilitet erkänner du att du har läst, förstått och samtyckt till dessa villkor och bestämmelser.",
      },
    ],
  },
  {
    title: "2. Integritetspolicy:",
    desc: [
      {
        title: "2.1",
        desc: "Din användning av våra tjänster omfattas också av vår integritetspolicy. Genom att använda våra tjänster samtycker du till insamling, användning och delning av dina personuppgifter enligt beskrivningen i integritetspolicyn.",
      },
    ],
  },

  {
    title: "3. Användning av tjänster:",
    desc: [
      {
        title: "3.1",
        desc: "Din användning av våra tjänster omfattas också av vår integritetspolicy. Genom att använda våra tjänster samtycker du till insamling, användning och delning av dina personuppgifter enligt beskrivningen i integritetspolicyn.",
      },
      {
        title: "3.2",
        desc: "Du måste följa alla tillämpliga lagar och förordningar när du använder våra tjänster.",
      },
    ],
  },

  {
    title: "4. Immaterialrätt:",
    desc: [
      {
        title: "4.1",
        desc: "Allt innehåll, material och immateriell egendom som är associerat med vårt projekt för kamerakompatibilitet ägs av oss eller våra licensgivare. Du får inte reproducera, distribuera, modifiera eller skapa härledda verk baserat på vårt innehåll utan vårt uttryckliga tillstånd.",
      },
    ],
  },

  {
    title: "5. Användaruppförande:",
    desc: [
      {
        title: "5.1",
        desc: "Du är ansvarig för noggrannheten och lagligheten hos informationen du tillhandahåller när du använder våra tjänster.",
      },
      {
        title: "5.2",
        desc: "Du får inte engagera dig i något beteende som kan störa eller skada våra tjänster, andra användare eller vårt rykte.",
      },
    ],
  },

  {
    title: "6. Ansvarsbegränsning:",
    desc: [
      {
        title: "6.1",
        desc: "Vårt projekt för kamerakompatibilitet tillhandahålls 'i befintligt skick' och 'som det är tillgängligt'. Vi garanterar inte noggrannheten, tillförlitligheten eller tillgängligheten hos tjänsterna.",
      },
      {
        title: "6.2",
        desc: "Vi är inte ansvariga för skador, förluster eller skulder som uppkommer genom din användning av våra tjänster eller någon information som erhållits genom våra tjänster. Vi garanterar inte noggrannheten, tillförlitligheten eller tillgängligheten hos tjänsterna.",
      },
    ],
  },

  {
    title: "7. Indemnifiering:",
    desc: [
      {
        title: "7.1",
        desc: "Du samtycker till att friköpa och hålla oss skadeslösa från alla anspråk, förluster, skulder och kostnader (inklusive juridiska avgifter) som uppstår genom din användning av våra tjänster eller din överträdelse av dessa villkor och bestämmelser.",
      },
    ],
  },
  {
    title: "8. Ändringar och avslutning:",
    desc: [
      {
        title: "8.1",
        desc: "Vi förbehåller oss rätten att när som helst ändra, suspendiera eller avsluta vårt projekt för kamerakompatibilitet utan förvarning.",
      },
      {
        title: "8.2",
        desc: "Vi kan också ändra dessa villkor och bestämmelser från tid till annan. Din fortsatta användning av våra tjänster efter sådana ändringar innebär att du accepterar de uppdaterade villkoren.",
      },
    ],
  },
  {
    title: "9. Tillämplig lag:",
    desc: [
      {
        title: "9.1",
        desc: "Dessa villkor och bestämmelser regleras och tolkas i enlighet med lagarna i [Jurisdiktion]. Eventuella tvister som uppstår ur eller i samband med dessa villkor ska vara underkastade exklusiv jurisdiktion av domstolarna i [Jurisdiktion].",
      },
    ],
  },
  {
    title: "10. Kontakta oss:",
    desc: [
      {
        desc: "Om du har några frågor, bekymmer eller förfrågningar om dessa villkor och bestämmelser eller användningen av våra tjänster, vänligen kontakta oss på [kontakt e-post]. Genom att använda vårt projekt för kamerakompatibilitet samtycker du till att vara bunden av dessa villkor och bestämmelser. Om du inte håller med om någon del av dessa villkor, vänligen använd inte våra tjänster.",
      },
    ],
  },
];
