import * as Styles from "./index.styles";
import BaseButton from "components/common/BaseButton";
import { BaseTypography } from "components/common/BaseTypography";
import { FaEdit } from "react-icons/fa";

interface GroupsTableRowCardProps {
  name: string;
  publicView: string;
  category: string;
  createdDate: string;
  onEditClick: (e: React.MouseEvent<HTMLElement>) => void;
  onViewClick: (e) => void;
}

const GroupsTableRowCard = ({
  name,
  publicView,
  category,
  createdDate,
  onEditClick,
  onViewClick,
}: GroupsTableRowCardProps) => {
  return (
    <Styles.CardWrapper onClick={onViewClick}>
      <div style={{ fontSize: "16px", fontWeight: "600", marginBottom: "5px" }}>
        {name}
      </div>
      <div
        style={{
          fontSize: "14px",
          background: "#FAF9F6aa",
          padding: "5px 8px",
          borderRadius: "6px",
          marginBottom: "5px",
          width: "100%",
        }}
      >
        <BaseTypography.Text
          style={{
            width: "100px",
            fontWeight: "600",
            marginRight: "5px",
          }}
        >
          Public Page
        </BaseTypography.Text>
        <BaseTypography.Paragraph
          ellipsis={{ rows: 2 }}
          style={{ maxWidth: "100%", fontWeight: "300" }}
        >
          {publicView}
        </BaseTypography.Paragraph>
      </div>
      <div style={{ fontSize: "12px", fontWeight: "400" }}>
        Category: {category}
      </div>
      <div style={{ fontSize: "10px", fontWeight: "400", marginBottom: "6px" }}>
        Created on {createdDate}
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <BaseButton
          onClick={onEditClick}
          style={{ padding: "0px", height: "20px" }}
          type="link"
        >
          <FaEdit
            style={{
              height: "20px",
              width: "20px",
              color: "var(--skyblue)",
            }}
          />
        </BaseButton>
      </div>
    </Styles.CardWrapper>
  );
};

export default GroupsTableRowCard;
