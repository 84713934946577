import React from "react";
import { StatisticsInfo } from "../StatisticsInfo/StatisticsInfo";
import * as S from "./StatisticsCard.styles";
import { Col, Row } from "antd";

interface StatisticsCardProps {
  name: string;
  value: number;
  prevValue: number;
  color: any;
  unit: any;
  Icon: React.FC;
}

export const StatisticsCard: React.FC<StatisticsCardProps> = ({
  name,
  value,
  prevValue,
  color,
  unit,
  Icon,
}) => {
  return (
    <S.StatisticCard $color={color}>
      <Row wrap={false} gutter={[10, 0]} align="middle">
        <Col>
          <S.IconWrapper>
            <S.Icon component={Icon} />
          </S.IconWrapper>
        </Col>

        <Col flex={1}>
          <Row justify="space-between" align="middle" wrap={false}>
            <Col>
              <StatisticsInfo name={name} value={value} prevValue={prevValue} />
            </Col>

            <Col>
              <S.NumberText>{value}</S.NumberText>
            </Col>
          </Row>
        </Col>
      </Row>
    </S.StatisticCard>
  );
};
