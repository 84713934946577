import * as React from "react";
import styled, { keyframes } from "styled-components";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export function NotFoundPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      <Wrapper>
        <Title>
          4
          <span role="img" aria-label="Crying Face">
            😢
          </span>
          4
        </Title>
        <P>{t("dashboard.not-found-page")}</P>
        <Button onClick={() => navigate("/dashboard")}>
          {t("dashboard.back-home")}
        </Button>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  /* height: calc(100vh - 100px); */
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 320px;
  background-color: #f2f2f2;
  animation: ${fadeIn} 0.5s ease-in-out;
`;

const P = styled.div`
  line-height: 1.5;
  color: #4d4d4d;
  margin: 0.625rem 0 1.5rem 0;
  text-align: center;
`;

const Title = styled.div`
  margin-top: -8vh;
  font-weight: bold;
  color: #363636;
  font-size: 3.375rem;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);

  span {
    font-size: 3.125rem;
  }
`;

const Button = styled.button`
  margin-top: 1.5rem;
  background-color: #4d4d4d;
  color: white;
  padding: 0.75rem 2rem;
  border: none;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);

  &:hover {
    background-color: #333333;
  }
`;
