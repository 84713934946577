import dynamicQRImg from "../../Features/images/qr.png";
import stickerImg from "../../Features/images/sticker.png";
import publicPageImg from "../../Features/images/city-public-page.png";

export default [
  {
    img: dynamicQRImg,
    title: "QR Country and city",
    description:
      "Modern cities require modern solutions. With Camera Compliance, we bring GDPR compliance to public surveillance systems, safeguarding personal data and ensuring complete transparency in the process",
  },
  {
    img: stickerImg,
    title: "Sticker with field",
    description:
      "Stickers are great! Place your dull QR codes in colorful stickers to give them attractive looks.",
  },
  {
    img: publicPageImg,
    title: "Public Page for Cities",
    description:
      "Customize your public pages as per your needs...City admins can customize and can add different language according to city",
  },
];
