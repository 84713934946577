export default [
  {
    title: "Ograniczenie Odpowiedzialności",
    desc: [
      "Camera Compliance Ltd, jego afiliacje, partnerzy i pracownicy nie ponoszą odpowiedzialności za szkody bezpośrednie, pośrednie, przypadkowe, specjalne, następcze ani karne, w tym, ale nie ograniczając się do, strat finansowych, utraty danych lub innych szkód wynikających z korzystania z tej strony internetowej lub polegania na udostępnianych treściach informacyjnych.",
    ],
  },
  {
    title: "Brak Porad Profesjonalnych",
    desc: [
      "Informacje dostępne na tej stronie internetowej nie stanowią porad prawnych, technicznych ani innych porad profesjonalnych. Chociaż dokładamy starań, aby dostarczać dokładne i aktualne informacje, nie udzielamy żadnych gwarancji ani reprezentacji jakiegokolwiek rodzaju co do dokładności, aktualności ani kompletności strony internetowej.",

      "Aby uzyskać szczegółowe porady dostosowane do Twojej sytuacji, skonsultuj się z wykwalifikowanymi specjalistami, w tym prawnikami mającymi doświadczenie w zakresie ochrony danych i zgodności z RODO.",
    ],
  },
  {
    title: "Prawo Stosowane",
    desc: [
      "Niniejsze zrzeczenie będzie podlegać i będzie interpretowane zgodnie z prawem jurysdykcji, w której działa Camera Compliance Ltd. Korzystając z tej strony internetowej, wyrażasz zgodę na jurysdykcję takich sądów w przypadku wszelkich działań, spraw lub postępowań wynikających z tego zrzeczenia.",
    ],
  },
];
