import { Typography } from "antd";
import { BaseCard } from "components/common/BaseCard";
import { useTranslation } from "react-i18next";
import React from "react";
import { styled } from "styled-components";

type Props = {
  operatorDetails: any;
};

const Stats = ({ operatorDetails }: Props) => {
  const { t } = useTranslation();
  const infoToShow = [
    {
      label: t("dashboard.qr-update"),
      value: operatorDetails?.updatedQr?.length || 0,
      color: null,
    },
    {
      label: t("dashboard.qr-associated"),
      value: operatorDetails?.totalAssociatedQR || 0,
      color: null,
    },
    // {
    //   label: "Has Logged In",
    //   value: operatorDetails?.operatorDetails?.isFirstLogin ? "Yes" : "No",
    //   color: null,
    // },
  ];

  return (
    <BaseCard style={{ marginBottom: "30px" }}>
      <StatsStyled>
        <Typography.Title level={3}>{t("dashboard.qr-stats")}</Typography.Title>
        <StatCardWrapperStyled>
          {infoToShow &&
            infoToShow.map(({ color, label, value }) => (
              <StatCardStyled color={color}>
                <div className="label">{label}</div>
                <div className="value">{value}</div>
              </StatCardStyled>
            ))}
        </StatCardWrapperStyled>
      </StatsStyled>
    </BaseCard>
  );
};

export default Stats;

const StatsStyled = styled.div``;

const StatCardWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px;
`;
const StatCardStyled = styled.div<{ color?: string }>`
  margin: 0px 10px;
  padding: 20px;
  min-width: 150px;
  text-align: center;
  box-shadow: 0px 0px 2px 1px ${({ color }) => color || "#ccc"};
  background: #fcfcfc;
  border-radius: 20px;

  & .label {
    font-size: 1.2rem;
    color: #555;
  }
  & .value {
    font-size: 1.6rem;
    color: ${({ color }) => color || "#167a8b"};
  }
`;
