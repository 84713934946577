const authApi = {
  changePassword: (data: any): any => {
    return {
      url: "auth/changePassword",
      method: "post",
      data,
    };
  },
  forgotPassword: (data) => {
    return {
      url: "auth/forgotPassword",
      method: "post",
      data,
    };
  },
  resetPassword: (data) => {
    return {
      url: "auth/resetPassword",
      method: "post",
      data,
    };
  },
  getOnboardingDetails: () => {
    return {
      url: "auth/getOnboardingDetails",
      method: "get",
      config: {
        store: {
          action: "set",
          key: "onBoardingDetails",
        },
      },
    };
  },
  getOwnQRCodeActivity: (params) => {
    return {
      url: "auth/getOwnQRCodeActivity",
      method: "get",
      params,
      config:{
        store: {
          action: "set",
          key:"ownQRCodeActivity.data"
        }
      }
  
    };
  },
};

export default authApi;
