import styled from "styled-components";
import { BaseTable } from "components/common/BaseTable";
import { FONT_SIZE, FONT_WEIGHT } from "styles/themes/constants";
import { Link } from "react-router-dom";
import { FlexEndText } from "utils/styles";
import { devices } from "utils/theme";

interface TextProps {
  $isActive?: boolean;
}

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;

  @media (${devices.mobileSM}) {
    padding: 0;
  }
`;

export const Table = styled(BaseTable)`
  padding: 0rem 0.5rem;
  thead .ant-table-cell {
    border-top: none;
    border-bottom: 1px solid var(--border-color);
  }

  .ant-table-thead > tr > th {
    background: transparent;
    color: var(--text-main-color);
    font-size: ${FONT_SIZE.md};
    font-weight: ${FONT_WEIGHT.semibold};

    &::before {
      display: none;
    }
  }

  .ant-table-tbody > tr > td {
    border: 0;
    cursor: pointer;
  }
`;

export const Text = styled.span<TextProps>`
  color: var(--text-main-color);
  font-weight: ${(props) =>
    props.$isActive ? `${FONT_WEIGHT.semibold}` : `${FONT_WEIGHT.regular}`};
`;

export const Flag = styled(Text)<{ $isNorm: boolean }>`
  color: ${(props) =>
    props.$isNorm ? "var(--success-color)" : "var(--error-color)"};
`;

export const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
