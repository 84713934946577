import React, { useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import * as S from "./index.styles";
import * as Auth from "./AuthLayout.styles";
import AuthLogo from "components/logo/AuthLogo";
import { BaseCard } from "components/common/BaseCard";
import { Field, Form, Formik } from "formik";
import { Row } from "antd";
import BaseButton from "components/common/BaseButton";
import BaseInputs from "components/common/BaseInput/BaseInputs";
import useAPI from "hooks/useApi";
import { useRoot } from "RootProvider";
import { loginURL } from "constants/api/auth";
import { handleLogin } from "utils/request";
import { toast } from "react-toastify";
import { QR_ADMIN_CHANGE_PASSWORD_PATH } from "constants/routes/route";
import { SUPER_ADMIN } from "constants/roles/roles";
import Header from "containers/Home/Header";
import { BaseTypography } from "components/common/BaseTypography";
import { useTranslation } from "react-i18next";
import authApi from "shared/front/apis/auth.api";

export const ForgotPassword: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  // const [rememberMe, setRememberMe] = useState(false);
  const [handleSubmitRequest, { loading: loginLoading, error }] = useAPI();
  const { setAuth, role, setInitialPassword, auth, initialPassword } =
    useRoot();

  const handleSubmit = async (values: any) => {
    try {
      await handleSubmitRequest(authApi.forgotPassword(values));
      toast.success(
        t("remain.otp-toast")
      );
      navigate("/auth/otp-verify", {
        state: {
          email: values.email,
        },
      });
    } catch (error) {
      console.log("the error", error);
      toast.error(error);
    }
  };
  return (
    <>
      <Header />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1"
      />
      <Auth.Wrapper>
        <Auth.BackgroundWrapper>
          <Auth.LoginWrapper>
            <BaseCard>
              <Auth.FormTitle>
                <div
                  style={{
                    fontWeight: 700,
                    fontSize: "1.625rem",
                    textAlign: "center",
                  }}
                >
                  {t("remain.forget-password")}
                </div>
              </Auth.FormTitle>
              <S.LoginDescription>
                <BaseTypography.Text>
                  {t("remain.enter-your-email-message")}
                </BaseTypography.Text>
              </S.LoginDescription>
              <Formik
                initialValues={{ email: "" }}
                onSubmit={(values: ILogin, { setSubmitting }) => {
                  handleSubmit(values);
                  setSubmitting(false);
                }}
              >
                {({ errors, isSubmitting }: any) => {
                  return (
                    <Form>
                      <BaseInputs
                        label={t("dashboard.email")}
                        name={"email"}
                        type={"text"}
                      />

                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <BaseButton
                          htmlType="submit"
                          type={"primary"}
                          disabled={loginLoading || isSubmitting}
                          loading={loginLoading}
                          style={{
                            background: "var(--primary)",
                            color: "#fff",
                          }}
                        >
                         {t("remain.continue")}
                        </BaseButton>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </BaseCard>
          </Auth.LoginWrapper>
        </Auth.BackgroundWrapper>
      </Auth.Wrapper>
    </>
  );
};

interface ILogin {
  email: string;
}
