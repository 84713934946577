import { FooterStyled } from "./style";
import { Typography } from "antd";
import { BsTwitter } from "react-icons/bs";
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
//@ts-ignore
import { useTranslation } from "react-i18next";
import { BiLogoLinkedin } from "react-icons/bi";
type Props = {};

const Footer = (props: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();

  return (
    <FooterStyled>
      <div className="reserved-wrapper">
        <div className="footer-icons">
          <BiLogoLinkedin className="img" />
        </div>

        <Typography.Text className="reserved">
          <span
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/terms")}
          >
            {t("footer.footer-term")}
          </span>{" "}
          -{" "}
          <span
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/privacy")}
          >
            {t("footer.footer-privacy")}
          </span>{" "}
          -{" "}
          <span
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/disclaimer")}
          >
            {t("remain.disclaimer")}
          </span>
        </Typography.Text>
        <br />
        <Typography.Text className="reserved-copy">
          © 2023 Camera Compliance Limited | Registered in Ireland company
          number: 623650
        </Typography.Text>
      </div>
    </FooterStyled>
  );
};

export default Footer;
