export default [
  {
    title: "1. Accettazione delle Condizioni:",
    desc: [
      {
        title: "1.1",
        desc: "Utilizzando il nostro progetto di conformità delle telecamere, riconosci di aver letto, compreso e accettato questi Termini e Condizioni.",
      },
    ],
  },
  {
    title: "2. Politica sulla Privacy:",
    desc: [
      {
        title: "2.1",
        desc: "Anche l'utilizzo dei nostri servizi è disciplinato dalla nostra Politica sulla Privacy. Utilizzando i nostri servizi, acconsenti alla raccolta, all'uso e alla condivisione delle tue informazioni personali come descritto nella Politica sulla Privacy.",
      },
    ],
  },

  {
    title: "3. Uso dei Servizi:",
    desc: [
      {
        title: "3.1",
        desc: "Anche l'utilizzo dei nostri servizi è disciplinato dalla nostra Politica sulla Privacy. Utilizzando i nostri servizi, acconsenti alla raccolta, all'uso e alla condivisione delle tue informazioni personali come descritto nella Politica sulla Privacy.",
      },
      {
        title: "3.2",
        desc: "Devi rispettare tutte le leggi e i regolamenti applicabili durante l'utilizzo dei nostri servizi.",
      },
    ],
  },

  {
    title: "4. Proprietà Intellettuale:",
    desc: [
      {
        title: "4.1",
        desc: "Tutti i contenuti, i materiali e la proprietà intellettuale associati al nostro progetto di conformità delle telecamere sono di nostra proprietà o dei nostri licenzianti. Non è consentito riprodurre, distribuire, modificare o creare opere derivate basate sul nostro contenuto senza il nostro esplicito permesso.",
      },
    ],
  },

  {
    title: "5. Condotta dell'Utente:",
    desc: [
      {
        title: "5.1",
        desc: "Sei responsabile dell'accuratezza e della legalità delle informazioni che fornisci durante l'utilizzo dei nostri servizi.",
      },
      {
        title: "5.2",
        desc: "Non devi intraprendere alcuna condotta che possa interrompere o danneggiare i nostri servizi, gli altri utenti o la nostra reputazione.",
      },
    ],
  },

  {
    title: "6. Limitazione di Responsabilità:",
    desc: [
      {
        title: "6.1",
        desc: "Il nostro progetto di conformità delle telecamere è fornito 'così com'è' e 'come disponibile'. Non garantiamo l'accuratezza, l'affidabilità o la disponibilità dei servizi.",
      },
      {
        title: "6.2",
        desc: "Non siamo responsabili per danni, perdite o responsabilità derivanti dall'utilizzo dei nostri servizi o da qualsiasi informazione ottenuta tramite i nostri servizi. Non garantiamo l'accuratezza, l'affidabilità o la disponibilità dei servizi.",
      },
    ],
  },

  {
    title: "7. Indennizzo:",
    desc: [
      {
        title: "7.1",
        desc: "Accetti di indennizzarci e di tenerci indenni da qualsiasi reclamo, perdita, responsabilità e spesa (incluse spese legali) derivanti dall'utilizzo dei nostri servizi o dalla violazione di questi Termini e Condizioni da parte tua.",
      },
    ],
  },
  {
    title: "8. Modifiche e Risoluzione:",
    desc: [
      {
        title: "8.1",
        desc: "Ci riserviamo il diritto di modificare, sospendere o interrompere il nostro progetto di conformità delle telecamere in qualsiasi momento senza preavviso.",
      },
      {
        title: "8.2",
        desc: "Potremmo anche modificare questi Termini e Condizioni di volta in volta. Il tuo utilizzo continuato dei nostri servizi dopo tali modifiche costituisce l'accettazione dei termini aggiornati.",
      },
    ],
  },
  {
    title: "9. Legge Applicabile:",
    desc: [
      {
        title: "9.1",
        desc: "Questi Termini e Condizioni sono regolati e interpretati in conformità alle leggi di [Giurisdizione]. Eventuali controversie derivanti da o in relazione a questi termini saranno soggette alla giurisdizione esclusiva dei tribunali di [Giurisdizione].",
      },
    ],
  },
  {
    title: "10. Contattaci:",
    desc: [
      {
        desc: "Se hai domande, preoccupazioni o richieste riguardo a questi Termini e Condizioni o all'uso dei nostri servizi, contattaci all'indirizzo [indirizzo email di contatto]. Utilizzando il nostro progetto di conformità delle telecamere, accetti di essere vincolato da questi Termini e Condizioni. Se non sei d'accordo con una parte di questi termini, ti preghiamo di non utilizzare i nostri servizi.",
      },
    ],
  },
];
