import { Spin } from "antd";
import styled from "styled-components";
export const Spiner = styled(Spin)``;

export const SpinWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  .ant-spin {
    color: var(--border);
    .anticon-loading {
      font-size: 50px;
    }
  }
`;
