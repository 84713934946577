import { Modal, Spin } from "antd";
import { ErrorText } from "components/common/BaseError/index.styles";
import { BaseSelect } from "components/common/BaseSelect";
import { BaseTypography } from "components/common/BaseTypography";
import { OptionLabelStyled } from "containers/Groups/style";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import cameraApi from "shared/front/apis/camera";
import QR from "shared/front/apis/qr.api";
import store from "store";
import request from "utils/request";

const AddCameraModal = ({ isVisible, onClose, qrId, alreadyAddCamerasId }) => {
  const { t } = useTranslation();
  const [selectedCamera, setSelectedCamera] = useState(null);
  const [validationError, setValidationError] = useState(null);
  const [adddingACamera, setAddingACamera] = useState(false);
  const camera = useSelector((state: any) => state.camera.data);
  const pagination = useSelector((state: any) => state.camera.pagination);
  const [loading, setLoading] = useState({
    camera: false,
  });
  const [selectedCameras, setSelectedCameras] = useState([]);
  const dispatch = useDispatch();
  const initialParams = {
    limit: 10,
    page: 0,
  };
  const fetchAllCamera = async (params) => {
    setLoading((prev) => ({ ...prev, camera: true }));

    const actionType = "set";
    // const actionType = cameraPagination?.page === 0 ? "set" : "update";
    await request(
      cameraApi.getAllCamera(actionType, {
        limit: params.limit,
        page: params.page,
        search: params.search,
      })
    );
    setLoading((prev) => ({ ...prev, camera: false }));
  };

  useEffect(() => {
    if (Array.isArray(camera) && camera.length > 0) {
      setSelectedCamera(camera[0]._id);
    }
  }, [camera]);

  useEffect(() => {
    if (camera.length === 0) {
      dispatch(
        store.Actions["update"]("camera.pagination", {
          page: 0,
          limit: 100,
        })
      );
      getAllCamera({
        page: 0,
        limit: 100,
      });
    }
  }, []);

  console.log("the camera options", camera);
  const delayedFetchCamera = useCallback(
    debounce((params) => {
      fetchAllCamera(params);
    }, 500),
    []
  );

  const onSearchCamera = (text) => {
    delayedFetchCamera({
      ...initialParams,
      search: text,
    });
  };

  const cameraOptions = () => {
    const options = [];
    if (loading?.camera) {
      options.push({
        label: <Spin size="small" />,
        value: null,
        disaled: true,
      });
    }
    if (Array.isArray(camera) && camera?.length > 0) {
      return [
        ...options,
        ...camera.map((val) => ({
          label: (
            <OptionLabelStyled
              className={
                selectedCameras?.find((x) => x._id === val?._id)
                  ? "selected"
                  : ""
              }
            >
              <div>{val?.name}</div>
              <div>{val?.address}</div>
            </OptionLabelStyled>
          ),
          value: val?.address,
          id: val._id,
        })),
      ];
    }
    return [
      {
        value: null,
        label: t("group.no-camera-found"),
        disaled: true,
      },
    ];
  };

  const getAllCamera = (params) => {
    const actionType = params?.page === 0 ? "set" : "update";
    request(cameraApi.getAllCamera(actionType, params));
  };

  const handleSelectCamera = (value) => {
    setSelectedCamera(value);
  };

  const handleOnClose = () => {
    onClose();
    setValidationError(null);
  };

  const onLinkCamera = async () => {
    try {
      if (!selectedCamera) {
        setValidationError(t("new.select-camera"));
        return;
      }

      if (!qrId) {
        setValidationError(t("new.select-qr"));
        return;
      }
      setAddingACamera(true);
      setValidationError(null);

      await request(QR.linkCamera(qrId, selectedCamera));
      await request(QR.getDetailsByQRCodeId(qrId));
      handleOnClose();
    } catch (error) {
      console.log("Link Camera:", error);
      if (typeof error === "string") {
        setValidationError(error);
      }
    } finally {
      setAddingACamera(false);
    }
  };
  return (
    <Modal
      open={isVisible}
      onCancel={handleOnClose}
      title={t("new.link-camera")}
      onOk={onLinkCamera}
      confirmLoading={adddingACamera}
      okText={t("new.link-camera-button")}
    >
      <BaseTypography.Text>{t("new.select-camera-title")}</BaseTypography.Text>
      <br />
      <br />
      {/* <AutoComplete
        options={camera.map((cam) => ({
          value: cam?._id,
          label:
            cam?.address || `lng(${cam?.longitude}) - lat(${cam?.latitude})`,
        }))}
        style={{ width: "100%" }}
        onSearch={(text) => onSearchCamera(text)}
        onChange={handleSelectCamera}
      /> */}

      <BaseSelect
        label={t("new.camera")}
        style={{ width: "100%" }}
        value={selectedCamera}
        onChange={handleSelectCamera}
        options={camera
          .map((cam) => {
            if (
              Array.isArray(alreadyAddCamerasId) &&
              alreadyAddCamerasId?.includes(cam?._id)
            ) {
              return null;
            }
            return {
              value: cam?._id,
              label:
                cam?.address ||
                `lng(${cam?.longitude}) - lat(${cam?.latitude})`,
            };
          })
          .filter(Boolean)}
      />
      <ErrorText>{validationError}</ErrorText>
    </Modal>
  );
};

export default AddCameraModal;
