import { Col, Row } from "antd";
import BaseInputs from "components/common/BaseInput/BaseInputs";
import S3uploaderMessage from "components/common/S3presigned";
import { Field, Form, Formik } from "formik";
import * as S from "./index.styles";
import { styled } from "styled-components";
import BaseButton from "components/common/BaseButton";
import { ButtonFlexEnd, flexColumnJustifyAlignCenter } from "utils/styles";
import useAPI from "hooks/useApi";
import city from "shared/front/apis/city.api";
import BaseLoading from "components/common/BaseSpin";
import { useTranslation } from "react-i18next";
import { useState } from "react";

const EditProfile = ({
  cityInfo,
  assignCityLoading,
  onCancel,
  reFetch,
}: any) => {
  const id = cityInfo?._id;
  const [buttonLoading, setButtonLoading] = useState(false);
  const [makeRequest, { loading: updateCityInfoLoading }] = useAPI();
  const handleInfoValues = async (id, values: any) => {
    await makeRequest(city.updateCity(id, values));
  };

  const handleCancel = () => onCancel();
  const { t } = useTranslation();
  return (
    <>
      {!!assignCityLoading ? (
        <BaseLoading />
      ) : (
        <Row gutter={{ xs: 10, md: 15, xl: 30 }}>
          <Col span={24}>
            <Formik
              initialValues={{
                desc: cityInfo?.desc,
                logo: cityInfo?.logo,
                bannerImage: cityInfo?.bannerImage,
              }}
              onSubmit={async (values, { setSubmitting }) => {
                console.log("the is submitted", values);
                try {
                  await handleInfoValues(id, values);
                  reFetch();
                  handleCancel();
                } catch (error) {}
              }}
            >
              {({ values, setFieldValue, errors, isSubmitting }) => {
                return (
                  <Form>
                    <Row justify={"center"}>
                      <UploaderColumn
                        span={24}
                        style={{ marginBottom: "30px" }}
                      >
                        <Field
                          name="bannerImage"
                          component={S3uploaderMessage}
                          setButtonLoading={setButtonLoading}
                          isFullWidth
                          setFieldValue={setFieldValue}
                          fieldName={"bannerImage"}
                          value={values.bannerImage}
                          // error={errors.image}
                          label={t("city.city-banner")}
                        />
                      </UploaderColumn>
                      <UploaderColumn span={12}>
                        <Field
                          name="logo"
                          component={S3uploaderMessage}
                          setButtonLoading={setButtonLoading}
                          setFieldValue={setFieldValue}
                          fieldName={"logo"}
                          // error={errors.image}
                          value={values.logo}
                          label={t("city.city-logo")}
                        />
                      </UploaderColumn>

                      {/* <Col span={24}>
                        <TextArea
                          name={"desc"}
                          label="City Descriptions"
                          type={"textarea"}
                          showCount
                          maxLength={600}
                        />
                      </Col> */}
                    </Row>

                    <ButtonFlexEnd>
                      <BaseButton
                        style={{ backgroundColor: "#167a8b", color: "white" }}
                        htmlType={"submit"}
                        loading={updateCityInfoLoading || buttonLoading}
                        disabled={updateCityInfoLoading || buttonLoading}
                      >
                        {t("city.update")}
                      </BaseButton>
                    </ButtonFlexEnd>
                  </Form>
                );
              }}
            </Formik>
          </Col>
        </Row>
      )}
    </>
  );
};

export default EditProfile;
const TextArea = styled(BaseInputs)`
  height: 200px !important;
`;
const UploaderColumn = styled(Col)`
  ${flexColumnJustifyAlignCenter}
  border: "1px solid red",
`;
