const admin = {
  addAdmin: (data: any): any => {
    return {
      url: "auth/registerAdmin",
      method: "post",
      data,
    };
  },
  updateOwnProfile: ({ data, id }: any) => {
    return {
      url: `auth/updateProfile`,
      method: "patch",
      data,
    };
  },

  updateAdmin: ({ data, id }: any) => {
    return {
      url: `auth/updateAuth/${id}`,
      method: "patch",
      data,
    };
  },
  getAllAdmin: (action, params): any => {
    return {
      url: "auth/getAllAdmin",
      method: "get",
      params: params,
      config: {
        store: {
          action: action,
          key: "admin.data",
        },
      },
    };
  },
};

export default admin;
