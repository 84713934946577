import React from "react";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import ReactQuill from "react-quill";
import { styled } from "styled-components";

type Props = {
  onEditorChange?: (values: string) => void;
  placeholder?: string;
  isReadOnly?: boolean;
  editorState?: string;
};

const Editor = ({
  onEditorChange,
  placeholder,
  isReadOnly = false,
  editorState,
}: Props) => {
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      [
        "link",
        // "image"
      ],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    // "image",
    "color",
  ];

  return isReadOnly ? (
    <EditorWrapperStyled $isReadOnly={isReadOnly}>
      <ReactQuill theme="bubble" value={editorState} readOnly={isReadOnly} />
    </EditorWrapperStyled>
  ) : (
    <div style={{ height: "200px" }}>
      <EditorWrapperStyled>
        <ReactQuill
          theme="snow"
          modules={modules}
          formats={formats}
          onChange={onEditorChange}
          placeholder={placeholder}
          value={editorState}
          style={{ height: "60%" }}
        />
      </EditorWrapperStyled>
    </div>
  );
};

export default Editor;

const EditorWrapperStyled = styled.div<{ $isReadOnly?: boolean }>`
  height: ${({ $isReadOnly }) => ($isReadOnly ? "auto" : "100%")};
  & li {
    margin: 5px 0px;
  }
`;
