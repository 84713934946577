const groupApi = {
  getAllGroups: (action, params): any => {
    return {
      url: "group/getAllGroup",
      method: "get",
      params: params,
      config: {
        store: {
          action: action,
          key: "groups.data",
        },
      },
    };
  },

  createCategory: (data: {
    name: string;
    category: string;
    publicView: string;
    subCategory: Array<string>;
    QRCode: Array<string>;
    camera: Array<string>;
  }): any => {
    return {
      url: "group/createGroup",
      method: "post",
      data,
    };
  },
  updateGroup: (
    groupId,
    data: {
      name?: string;
      category?: string;
      publicView?: string;
      subCategory?: Array<string>;
      QRCode?: Array<string>;
      camera?: Array<string>;
    }
  ): any => {
    return {
      url: `group/updateGroupById/${groupId}`,
      method: "patch",
      data,
    };
  },
  getGroupDetail: (groupId): any => {
    return {
      url: `group/getGroupDetailsById/${groupId}`,
      method: "get",
      config: {
        store: {
          action: "set",
          key: "groupsDetails",
        },
      },
    };
  },
  linkGroupToQr: (data) => {
    return {
      url: `group/addQRToGroup`,
      method: "post",
      data,
      config: {
        store: {
          action: "set",
          key: "groupDataById",
        },
      },
    };
  },
};

export default groupApi;
