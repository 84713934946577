import React from "react";
import * as S from "./index.styles";
import { LoadingOutlined } from "@ant-design/icons";

const BaseLoading = ({ loadingSize }: any) => {
  return (
    <S.SpinWrapper>
      <S.Spiner
        indicator={
          <LoadingOutlined
            style={{ fontSize: `${loadingSize}px`, color: `var(--primary)` }}
          />
        }
      />
    </S.SpinWrapper>
  );
};

export default BaseLoading;
