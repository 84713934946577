import React, { useEffect } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import * as S from "./index.styles";
import { useRoot } from "RootProvider";
import { ADMIN, ALLOWED_ROLES } from "constants/roles/roles";

const AuthLayout: React.FC = () => {
  const { auth } = useRoot();
  const token = auth?.token;
  const role = auth?.userDetails?.role;
  const navigate = useNavigate();

  useEffect(() => {
    if (ALLOWED_ROLES.includes(role) && !!token) {
      return navigate("/dashboard");
    }
  }, [auth, token, role]);

  return (
    <S.Wrapper>
      <S.BackgroundWrapper>
        <S.LoginWrapper>
          <Outlet />
        </S.LoginWrapper>
      </S.BackgroundWrapper>
    </S.Wrapper>
  );
};

export default AuthLayout;
