import * as StylesShared from "../index.styles";
import Feature from "../Feature";
import OperatorProfileImage from "../images/operator-profile.png";
// @ts-ignore
import Fade from "react-reveal/Fade";
//@ts-ignore
import { useTranslation } from "react-i18next";

const OperatorFeatures = () => {
  const { t } = useTranslation();
  return (
    <StylesShared.Section>
      <StylesShared.Container>
        <Feature
          title={t("feature.operator-title")}
          description={t("feature.operator-description")}
          image={OperatorProfileImage}
          direction="row-reverse"
        />
      </StylesShared.Container>
    </StylesShared.Section>
  );
};

export default OperatorFeatures;
