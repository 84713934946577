import { Modal, ModalProps } from "antd";
import React, { useState } from "react";

const BaseModal = ({ title, children, ...props }: IModalProps) => {
  return (
    <Modal title={title} {...props}>
      {children}
    </Modal>
  );
};

export default BaseModal;

interface IModalProps extends ModalProps {
  title: string;
  children: React.ReactNode;
}
