const initialState = {
  loading: {},
  cities: {
    data: [],
    pagination: {
      search: "",
      limit: 10,
      page: 0,
      count: 0,
    },
  },
  countries: {
    data: [],
    pagination: {
      search: "",
      limit: 10,
      page: 0,
      count: 0,
    },
  },

  admin: {
    data: [],
    pagination: {
      search: "",
      limit: 10,
      page: 0,
      count: 0,
    },
  },
  sticker: {
    data: [],
    pagination: {
      search: "",
      limit: 10,
      page: 0,
      count: 0,
      status: "All",
    },
  },
  operator: {
    data: [],
    pagination: {
      search: "",
      limit: 10,
      page: 0,
      count: 0,
    },
  },
  qr: {
    data: [],
    pagination: {
      search: "",
      limit: 10,
      page: 0,
      count: 0,
      status: "All",
      batch: "",
      hasGroup: false,
    },
  },
  publicPage: {
    data: [],
    pagination: {
      search: "",
      limit: 10,
      page: 0,
      count: 0,
    },
  },
  qrDetails: {
    data: [],
    pagination: {},
  },
  camera: {
    data: [],
    pagination: {
      search: "",
      limit: 10,
      page: 0,
      count: 0,
    },
  },

  dashboard: {},
  getOnBoardingDetails: {},
  profile: {},
  notifications: [],
  pagination: {
    count: 0,
    lastPage: 0,
  },
  qrGeneratedNotifications: {},
  unreadNotificationsCount: { count: 0 },
  selectedStickerToGenerateQr: {},
  operatorActivity: {
    data: [],
    pagination: {
      search: "",
      limit: 10,
      page: 0,
      count: 0,
      status: "All",
    },
  },
  ownQRCodeActivity: {
    data: [],
    pagination: {
      search: "",
      limit: 10,
      page: 0,
      count: 0,
    },
  },

  categories: {
    data: [],
    pagination: {
      search: "",
      limit: 20,
      page: 0,
      count: 0,
    },
  },
  subCategories: {
    data: [],
    pagination: {
      search: "",
      limit: 20,
      page: 0,
      count: 0,
    },
  },
  groupsDetails: {},
  groups: {
    data: [],
    pagination: {
      search: "",
      limit: 10,
      page: 0,
      count: 0,
    },
  },
  publicViews: {
    data: [],
    pagination: {
      search: "",
      limit: 10,
      page: 0,
      count: 0,
    },
  },
  qrBatch: {
    data: [],
    pagination: {
      limit: 50,
      page: 0,
      count: 0,
    },
  },
  cameraDetails: {},
  publicViewDataById: {},
  groupDataById: {},
  stickerSelection: {
    status: "NotUsed", //Active for active action and NotUsed for inital state
    selectedFile: null,
    qrCodeSVG: null,
    previewURL: "",
    qrOptions: {
      color: {
        light: "#FFFFFF",
        dark: "#000000",
      },
      margin: 1,
      borderRadius: 0,
    },
    imageSize: {
      width: 400,
      height: 600,
      aspectRatio: 4 / 3,
    },
    imageRefSize: {
      width: 400,
      height: 600,
      aspectRatio: 4 / 3,
    },
    qrCodeSize: {
      width: 300,
      height: 300,
      x: 10,
      y: 10,
      aspectRatio: 1 / 1,
    },
  },
};

export default initialState;
