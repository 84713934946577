import { Divider, Typography } from "antd";

import { Link, useParams } from "react-router-dom";
import { blogData } from "data/blogs";
import { kebabCase, snakeCase } from "lodash";

import styled from "styled-components";

export const ContentStyled = styled.div`
  max-width: 1160px;
  margin: 0px auto 30px;
  margin-top: 20px;
  min-height: calc(100vh - 239px);
  align-items: flex-start;
  justify-content: space-between;
  padding: 80px 30px;
  flex-direction: column-reverse;

  p {
    font-weight: 400;
  }
`;
const BannerImage = styled.img`
  width: 100%;
  max-height: 400px;
  object-fit: cover;
  margin-bottom: 30px;
`;
const Footer = styled.div`
  margin-top: 40px;

  a {
    margin-left: 10px;
  }
`;

const Content = () => {
  let { id } = useParams();

  const blog = blogData.find((item) => kebabCase(item.title) === id);

  return (
    <ContentStyled>
      <Typography.Title
        level={4}
        style={{
          textAlign: "center",
        }}
      >
        {blog.title}
      </Typography.Title>
      <BannerImage src={blog.img} alt={blog.title} />

      <Typography.Text>
        <div dangerouslySetInnerHTML={{ __html: blog.body }} />
      </Typography.Text>

      <Footer>
        For more information
        <Link to="/contact">
          <Typography.Text>Contact Us</Typography.Text>
        </Link>
      </Footer>
    </ContentStyled>
  );
};

export default Content;
