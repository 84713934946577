import { BaseInputNumber } from "components/common/BaseInputNumber";
import { BaseTypography } from "components/common/BaseTypography";
import { useResponsive } from "hooks/useResponsive";
import { useTranslation } from "react-i18next";
import QROptions from "./QROptions";
import Card from "./Card";
type props = {
  imageSize: { height: number; width: number };
  setImageSize: (any) => void;
  rndState: { height: number; width: number; x: number; y: number };
  setRndState: (any) => void;
  qrOptions: {};
  setQROptions: (any) => void;
};

const CalculateSize = ({
  imageSize,
  setImageSize,
  rndState,
  setRndState,
  qrOptions,
  setQROptions,
}: props) => {
  const { t } = useTranslation();
  const { isTablet, useMediaQuery } = useResponsive();
  const handleStickerImageSizeChange = (type: string, value: number) => {
    if (type === "width") {
      setImageSize((prev) => ({
        ...prev,
        height: Math.round(value / prev.aspectRatio),
        width: Math.round(value),
      }));
    }
    if (type === "height") {
      setImageSize((prev) => ({
        ...prev,
        height: Math.round(value),
        width: Math.round(value * prev.aspectRatio),
      }));
    }
  };

  const handleQRImageSizeAndPositionChange = (type: string, value: number) => {
    if (type === "width") {
      setRndState((prev) => ({
        ...prev,
        height: Math.round(value / prev.aspectRatio),
        width: Math.round(value),
      }));
    }
    if (type === "height") {
      setRndState((prev) => ({
        ...prev,
        height: Math.round(value),
        width: Math.round(value * prev.aspectRatio),
      }));
    }
  };

  return (
    <div
      style={{
        display: "flex",
        marginRight: "10px",
        // alignItems: "flex-start",
        flexDirection: isTablet ? "column" : "row",
        width: isTablet ? "auto" : "100%",
        marginBottom: "20px",
        flexWrap: "wrap-reverse",
        gap: isTablet ? "5px" : "20px",
      }}
    >
      <Card
        noMargin
        style={{
          margin: isTablet ? "0px 0px 10px 0px" : "0px 10px 0px 0px",
          minWidth: useMediaQuery({
            query: `(max-width: ${410 - 0.02}px)`,
          })
            ? "auto"
            : "225px",
          flex: 1,
        }}
      >
        <BaseTypography.Text>
          {t("dashboard.sticker-dimension")}
        </BaseTypography.Text>
        <div
          style={{
            display: "flex",
            marginTop: "5px",
            flexDirection: isTablet ? "row" : "row",
            flexWrap: "wrap",
            gap: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              margin: isTablet ? "0px 0px 8px" : "0px 8px 0px 0px",
            }}
          >
            <BaseTypography.Text>
              {" "}
              {t("dashboard.width")} :{" "}
            </BaseTypography.Text>
            <BaseInputNumber
              value={imageSize.width}
              onChange={(value: any) => {
                handleStickerImageSizeChange("width", value ?? 0);
                // setImageSize({ ...imageSize, width: value ?? 0 })
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "8px",
            }}
          >
            <BaseTypography.Text>
              {t("dashboard.height")} :{" "}
            </BaseTypography.Text>
            <BaseInputNumber
              value={imageSize.height}
              onChange={(value: any) => {
                handleStickerImageSizeChange("height", value ?? 0);

                // setImageSize({ ...imageSize, height: value ?? 0 })
              }}
            />
          </div>
        </div>
      </Card>
      <Card
        noMargin
        style={{
          margin: isTablet ? "0px 0px 10px 0px" : "0px 10px 0px 0px",
          minWidth: useMediaQuery({
            query: `(max-width: ${410 - 0.02}px)`,
          })
            ? "auto"
            : "225px",
          flex: 1,
        }}
      >
        <BaseTypography.Text>
          {" "}
          {t("dashboard.qr-dimension")}{" "}
        </BaseTypography.Text>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "5px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: isTablet ? "row" : "row",
              flexWrap: "wrap",
              gap: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "8px",
                margin: isTablet ? "0px 0px 8px" : "0px 8px 0px 0px",
              }}
            >
              <BaseTypography.Text>
                {" "}
                {t("dashboard.width")} :{" "}
              </BaseTypography.Text>
              <BaseInputNumber
                value={rndState.width}
                onChange={(value) => {
                  handleQRImageSizeAndPositionChange("width", value ?? 0);
                  // setRndState({ ...rndState, width: value ?? 0 });
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "8px",
              }}
            >
              <BaseTypography.Text>
                {" "}
                {t("dashboard.height")} :{" "}
              </BaseTypography.Text>
              <BaseInputNumber
                value={rndState.height}
                onChange={(value) => {
                  handleQRImageSizeAndPositionChange("height", value ?? 0);

                  // setRndState({ ...rndState, height: value ?? 0 })
                }}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: isTablet ? "row" : "row",
              flexWrap: "wrap",
              gap: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: isTablet ? "0px 0px 8px" : "0px 8px 0px 0px",
              }}
            >
              <BaseTypography.Text>
                {" "}
                {t("dashboard.top")} :{" "}
              </BaseTypography.Text>
              <BaseInputNumber
                value={rndState.y}
                onChange={(value) =>
                  setRndState({ ...rndState, y: value ?? 0 })
                }
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "8px",
              }}
            >
              <BaseTypography.Text>
                {" "}
                {t("dashboard.left")} :{" "}
              </BaseTypography.Text>
              <BaseInputNumber
                value={rndState.x}
                onChange={(value) =>
                  setRndState({ ...rndState, x: value ?? 0 })
                }
              />
            </div>
          </div>
        </div>
      </Card>
      <QROptions qrOptions={qrOptions} setQROptions={setQROptions} />
    </div>
  );
};

export default CalculateSize;
