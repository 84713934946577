import React from "react";
import * as S from "./index.styles";
import NotAuthorized from "../../../assets/notauthorized.svg";

const BaseNotAuthorized = () => {
  return (
    <S.ImageWrapper>
      <S.Image src={NotAuthorized} alt="under-constraction" />
    </S.ImageWrapper>
  );
};

export default BaseNotAuthorized;
