import styled from "styled-components";

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 20px;
  background-color: #efefef;
  margin-bottom: 8px;
  border-radius: 8px;

  &:hover {
    background-color: #fff;
  }
`;
