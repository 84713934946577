const country = {
  createCountry: (data: ICountryCreateType): any => {
    return {
      url: "common/createCountry",
      method: "post",
      data,
    };
  },

  getCountry: (action, params): any => {
    return {
      url: "common/getAllCountry",
      method: "get",
      params: params,
      config: {
        store: {
          action: action,
          key: "countries.data",
        },
      },
    };
  },
};

interface ICountryCreateType {
  title: any;
  language: { value: string; label: string }[];
}

export default country;
