import DownloadOutlined from "@ant-design/icons/DownloadOutlined";
import EyeOutlined from "@ant-design/icons/EyeOutlined";
import { Divider, Image, Input, InputNumber, Modal, Radio } from "antd";
import ActionButton from "components/ActionWrapper/ActionButton";
import ActionWrapper from "components/ActionWrapper/ActionWrapper";
import ConfirmationMessagePopup from "components/ConfirmationMessagePopup/ConfirmationMessagePopup";
import ViewQrSticker from "components/StickerSelection/ViewQrSticker";
import BaseButton from "components/common/BaseButton";
import BaseError from "components/common/BaseError";
import BaseImageSelector from "components/common/BaseImageSelector";
import { BasePagination } from "components/common/BasePagination";
import BaseLoading from "components/common/BaseSpin";
import BaseTabs from "components/common/BaseTabs";
import { BaseTag } from "components/common/BaseTag";
import BaseToolTip from "components/common/BaseTooltip";
import { BaseTypography } from "components/common/BaseTypography";
import PublicViewInfo from "containers/Generated-Qr/PublicViewInfo";
import { Form, Formik } from "formik";
import { useResponsive } from "hooks/useResponsive";
import { CSSProperties, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaTrashAlt } from "react-icons/fa";
import { TbLock, TbLockOpen } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import type { Dispatch } from "redux";
import { downloadFile } from "services/filesave";
import MultiFileDownload from "services/multiplefilesave";
import publicPage from "shared/front/apis/public-page.api";
import QR from "shared/front/apis/qr.api";
import store from "store";
import { styled } from "styled-components";
import request from "utils/request";
import {
  FlexColumnAlignCenter,
  FlexEnd,
  GroupTextStyled,
  flexRow,
} from "utils/styles";
import { convertTimestamp, fullConvertTimestamp } from "utils/utils";
import * as Yup from "yup";
import { BaseTable } from "../../components/common/BaseTable";
import HeaderWrapper from "../../components/headerwrapper";
import { generateStickerWithQR } from "../../constants/api/qr";
import useAPI from "../../hooks/useApi";
import stickersAPI from "../../shared/front/apis/stickers.api";
import Store from "../../store";
import GroupViewInfo from "./GroupViewInfo";
import QRGeneratorTableRowCard from "./QRGeneratorTableRowCard";
import QrFilter from "./QrFilter";
import QrFilterStatus from "./QrFilter/QrFilterStatus";
import * as S from "./index.styles";
import { downloadMergedSVG } from "utils/svgHelpers";

const validationSchema = Yup.object().shape({
  imageSelect: Yup.string().required("Must Select one of the sticker"),
});

const MAX_QR_COUNT =
  parseInt(process.env.REACT_APP_MAX_QR_GENERATION_COUNT) || 10;

const GeneratedQR = () => {
  const { t } = useTranslation();
  const { isLargeMobile, isTablet } = useResponsive();
  const [publicViewId, setPublicViewId] = useState("");
  const [groupState, setGroupState] = useState("");
  const [search, setSearch] = useSearchParams();
  const [qrStickerData, setQrStickerData] = useState<any>();
  const [showQrInSticker, setShowQrInSticker] = useState(false);
  const [qrCountToGenerate, setQrCountToGenerate] = useState(1);
  const [validationErrors, setValidationErrors] = useState({
    linkQr: "",
    count: "",
    batchError: "",
  });
  const [successBatchName, setSuccessBatchName] = useState("");
  const [imageSize, setImageSize] = useState<any>({
    width: 400,
    height: 600,
    aspectRatio: 4 / 3,
  });
  const [imageRefSize, setImageRefSize] = useState({
    width: 400,
    height: 600,
    aspectRatio: 4 / 3,
  });
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [selectedQRSticker, setSelectedQRSticker] = useState<{
    _id?: string;
    URL?: string;
    status?: string;
    isArchive?: boolean;
    batch?: string;
    stickerURL?: string;
  }>({});
  const [buttonLoading, setButtonLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [radioValue, setRadioValue] = useState("");
  const [blockedDelete, setBlockedDelete] = useState("");
  const [publicPageViewId, setPublicPageViewId] = useState("");
  const [imagePreviewActive, setImagePreviewActive] = useState(false);
  const [batchName, setBatchName] = useState("");
  const dispatch: Dispatch = useDispatch();
  const qr = useSelector((state: any) => state.qr.data);
  const pagination = useSelector((state: any) => state.qr.pagination);
  const [getRequest, { loading: loader }] = useAPI();
  const batchCount = qr.length;
  let [searchParams, setSearchParams] = useSearchParams();

  const items = [
    {
      key: "All",
      label: t("dashboard.all"),
      archive: false,
    },
    {
      key: "New",
      label: t("dashboard.new"),
      archive: false,
    },
    {
      key: "Active",
      label: t("dashboard.active"),
      archive: false,
    },
    {
      key: "Closed",
      label: t("dashboard.closed"),
      archive: false,
    },
    {
      key: "Blocked",
      label: t("dashboard.blocked"),
      archive: true,
    },
    {
      key: "NotGrouped",
      label: t("remain.not-grouped"),
      hasGroup: false,
    },
  ];

  const getPublicViewDataById = async (id) => {
    await request(publicPage.getPublicPageById(id));
  };

  const handlePagination = (page, limit) => {
    const { search, status, batch } = pagination;
    let archive = false;
    let hasGroup = false;
    if (status === "Blocked") {
      archive = true;
    }

    if (status === "NotGrouped") {
      hasGroup = false;
    }
    dispatch(
      store.Actions["update"]("qr.pagination", {
        page,
        limit,
        batch,
      })
    );

    if (status === "NotGrouped") {
      getAllQr({
        page,
        limit,
        ...(search && { search }),
        archive,
        hasGroup: hasGroup,
        batch: batch,
      });
    } else {
      getAllQr({
        page,
        limit,
        status,
        ...(search && { search }),
        archive,
        batch: batch,
      });
    }
  };

  useEffect(() => {
    if (search.get("status")) {
      handleTabs(search.get("status"));
      search.delete("status");
      setSearch(search);
    }
  }, [search]);

  useEffect(() => {
    return () => {
      dispatch(
        store.Actions["update"]("qr.pagination", {
          page: 0,
          status: "All",
          batch: "",
          archive: false,
          limit: 10,
        })
      );
    };
  }, []);

  const handleTabs = (status) => {
    let archive = false;
    let hasGroup = false;
    if (status === "Blocked") {
      archive = true;
    }

    if (status === "NotGrouped") {
      hasGroup = false;
    }

    dispatch(
      store.Actions["update"]("qr.pagination", {
        page: 0,
        status,
        batch: "",
        archive,
        limit: 10,
        hasGroup,
      })
    );
    if (status === "Blocked") {
      status = "Active";
    }

    if (status === "NotGrouped") {
      getAllQr({
        page: 0,
        limit: pagination.limit,
        // status,
        archive,
        batch: null,
        hasGroup: hasGroup,
      });
    } else {
      getAllQr({
        page: 0,
        limit: pagination.limit,
        status,
        archive,
        batch: "",
      });
    }
  };

  const handleBatch = async (batch) => {
    let { status, archive } = pagination;

    await dispatch(
      store.Actions["update"]("qr.pagination", {
        page: 0,
        batch,
        status,
        archive,
        limit: 10,
      })
    );
    if (status === "Blocked") {
      status = "Active";
    }

    await getAllQr({
      page: 0,
      limit: 10,
      status,
      batch,
      archive,
    });
  };

  const handleClearFilter = () => {
    let { status, archive } = pagination;

    dispatch(
      store.Actions["update"]("qr.pagination", {
        page: 0,
        status,
        batch: null,
        limit: 10,
        archive,
      })
    );
    if (status === "Blocked") {
      status = "Active";
    }
    getAllQr({
      page: 0,
      limit: 10,
      status,
      batch: null,
      archive,
    });
  };

  const OnRadioChange = (e: any) => {
    setRadioValue(e.target.value);
  };

  const [
    getStickerRequest,
    { loading: stickerRequestLoading, data: stickers },
  ] = useAPI();
  const [params, setParams] = useState({
    search: "",
    limit: 10,
    page: 0,
    status: "New",
  });
  const bannerImageRef = useRef<HTMLImageElement>();

  const stickerData = stickers?.data;

  const [handleStickerGenerate, { loading: stickerGenerateLoading }] = useAPI();
  const qrGeneratedNotifications: any = useSelector(
    (state: any) => state.qrGeneratedNotifications
  );
  const [isPreviewVisible, setPreviewVisible] = useState(false);

  const [isModalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const columns = [
    {
      title: t("dashboard.s.n."),
      dataIndex: "rowNo",
      key: "rowNo",
    },
    {
      title: t("dashboard.qr"),
      dataIndex: "image",
      key: "image",
      render: (imageURL: string, record) => (
        <>
          <ViewQrSticker
            imgSize="center"
            style={{
              height: "50px",
              width: "100px",
              objectFit: "contain",
            }}
            frontEndURL={record?.frontEndURL}
            stickerDimensions={record?.sticker?.stickerDimension}
            stickerURL={record?.sticker?.URL}
            onClick={(e) => {
              e.stopPropagation();
              setImagePreviewActive(true);
            }}
          />
        </>
      ),
    },
    {
      title: t("dashboard.batch"),
      dataIndex: "batch",
      key: "batch",
      render: (batch: any) => {
        return batch || t("dashboard.not-available");
      },
    },
    // {
    //   title: t("dashboard.url"),
    //   dataIndex: "url",
    //   key: "url",
    //   render: (url: any) => {
    //     return (
    //       <UrlLink
    //         onClick={async () => {
    //           window.open(url);
    //         }}
    //       >
    //         {url}
    //       </UrlLink>
    //     );
    //   },
    // },
    {
      title: t("group.group"),
      dataIndex: "group",
      key: "group",
      render: (text: string, record: any) => (
        <BaseToolTip title={t("tooltip.view-group-details")}>
          <GroupTextStyled
            className={record?.groupId ? t("dashboard.active") : ""}
            ellipsis={{ rows: 1 }}
            style={{ maxWidth: "200px" }}
            onClick={(e) => {
              e.stopPropagation();
              if (record?.groupId)
                navigate(`/dashboard/group/${record?.groupId}`);
            }}
          >
            {text}
          </GroupTextStyled>
        </BaseToolTip>
      ),
    },
    {
      title: t("dashboard.created-date"),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: any, record: any, index: any) => {
        return (
          <BaseToolTip title={fullConvertTimestamp(text)}>
            <span>{convertTimestamp(text)}</span>
          </BaseToolTip>
        );
      },
    },
    {
      title: t("dashboard.status"),
      dataIndex: "status",
      key: "status",
      align: "center" as "center",
      render: (text, record: any) => {
        return (
          <BaseTag
            color={
              text === "Active"
                ? record?.isArchive
                  ? "warning"
                  : "success"
                : "cyan"
            }
          >
            {text === "Active"
              ? record?.isArchive
                ? t("dashboard.blocked")
                : t("dashboard.active")
              : text}
          </BaseTag>
        );
      },
    },
    {
      title: t("dashboard.action"),
      dataIndex: "action",
      key: "action",
      align: "right" as "right",
      render: (url: string, record: any) => {
        return (
          <>
            <>
              {record.status === "New" && (
                <BaseToolTip title={t("tooltip.download-qr")}>
                  <BaseButton
                    onClick={async (e) => {
                      e.stopPropagation();
                      downloadMergedSVG(record?.sticker, record?.frontEndURL);
                    }}
                    type="link"
                    style={{ marginRight: "5px" }}
                  >
                    <DownloadOutlined
                      style={{
                        fontSize: "24px",
                        color: "var(--primary)",
                      }}
                    />
                  </BaseButton>
                </BaseToolTip>
              )}
              {(record.isArchive || record.status === "New") && (
                <BaseToolTip title={t("tooltip.delete-qr")}>
                  <BaseButton
                    onClick={(e) => {
                      e.stopPropagation();
                      onSelectQRStickerToDeleteBlockAndUnblock(record?.id, qr);
                      setBlockedDelete("DeleteBlocked");
                    }}
                    type="link"
                  >
                    <FaTrashAlt
                      style={{
                        height: "24px",
                        width: "24px",
                        color: "var(--red)",
                      }}
                    />
                  </BaseButton>
                </BaseToolTip>
              )}
            </>

            {record.status !== "New" && (
              <>
                <BaseToolTip title={t("tooltip.view-qr-details")}>
                  <BaseButton
                    onClick={async (e) => {
                      e.stopPropagation();
                      navigate(record.id);
                    }}
                    type="link"
                  >
                    <EyeOutlined
                      style={{
                        fontSize: "24px",
                        color: "var(--primary)",
                      }}
                    />
                  </BaseButton>
                </BaseToolTip>

                <BaseButton
                  onClick={(e) => {
                    e.stopPropagation();
                    onSelectQRStickerToDeleteBlockAndUnblock(record?.id, qr);
                    record?.isArchive && setBlockedDelete("Unblock");
                  }}
                  type="link"
                >
                  {record?.isArchive ? (
                    <BaseToolTip title={t("tooltip.unblock-qr")}>
                      <TbLockOpen
                        style={{
                          height: "24px",
                          width: "24px",
                          color: "var(--tairo)",
                        }}
                      />
                    </BaseToolTip>
                  ) : (
                    <BaseToolTip title={t("tooltip.block-qr")}>
                      <TbLock
                        style={{
                          height: "24px",
                          width: "24px",
                          color: "var(--orange)",
                        }}
                      />
                    </BaseToolTip>
                  )}
                </BaseButton>
              </>
            )}
          </>
        );
      },
    },
  ];

  const dataSource = qr?.map((item: any, index: number) => ({
    rowNo: pagination.limit * pagination.page + index + 1,
    key: item._id,
    id: item._id,
    url: item.stickerURL,
    status: item?.status,
    isCompleted: item.isComplete,
    image: item.stickerURL,
    createdAt: item.createdAt,
    action: item.stickerURL,
    isRecent: Date.now() - Date.parse(item.createdAt) <= 60000,
    batch: item.batch,
    isArchive: item.isArchive,
    group: item?.group?.name ?? "N/A",
    groupId: item?.group?._id ?? null,
    frontEndURL: item?.frontendURL,
    sticker: item?.sticker,
  }));

  useEffect(() => {
    if (qrStickerData?._id) {
      setImageSize({
        width: qrStickerData?.stickerDimension?.stickerWidth,
        height: qrStickerData?.stickerDimension?.stickerHeight,
        aspectRatio:
          qrStickerData?.stickerDimension?.stickerWidth /
          qrStickerData?.stickerDimension?.stickerWidth,
      });
    }
  }, [qrStickerData?._id]);

  useEffect(() => {
    getImageRefSizes();
  }, [imageSize, qrStickerData?._id, bannerImageRef]);

  useEffect(() => {
    if (searchParams) {
      const queryStickerId = searchParams.get("sticker");
      const queryOpenQrModal = searchParams.get("createQrModal");

      if (queryStickerId) {
        setModalOpen(true);
        handleStickerByCity();
      }
      if (queryOpenQrModal) {
        setModalOpen(true);
        handleStickerByCity();
        searchParams.delete("createQrModal");
        setSearchParams(searchParams);
      }
    }
  }, [searchParams]);

  useEffect(() => {
    if (searchParams) {
      const queryStickerId = searchParams.get("sticker");
      if (queryStickerId) {
        getASingleSticker(queryStickerId).then((response) => {
          if (response) {
            setQrStickerData({ ...response });
            handleSelect();
          }
        });
      }
    }
  }, [searchParams, stickerData]);
  useEffect(() => {
    setValidationErrors((prev) => ({ ...prev, linkQr: "" }));
  }, [publicViewId, groupState]);

  const getASingleSticker = async (stickerId) => {
    try {
      const response = await request(stickersAPI.getASticker(stickerId));
      if (response) {
        return response;
      }
      return {};
    } catch (error) {
      toast.error(error);
    } finally {
    }
  };

  const onSelectQRStickerToDeleteBlockAndUnblock = (
    qrStickerId: string,
    qrStickers: any[]
  ) => {
    if (Array.isArray(qrStickers)) {
      setSelectedQRSticker(qrStickers.find((x) => x._id === qrStickerId));
      setIsDeleteModalVisible(true);
    }
  };

  const getImageRefSizes = () => {
    if (bannerImageRef?.current) {
      const ht = bannerImageRef?.current?.offsetHeight;
      const wt = bannerImageRef?.current?.offsetWidth;

      setImageRefSize({
        height: ht,
        width: wt,
        aspectRatio: wt / ht,
      });
    }
  };

  const handlegeneratedQR = async () => {
    setModalOpen(true);
    handleStickerByCity();
  };

  const handleStickerByCity = async () => {
    try {
      const res = await getStickerRequest({
        method: "get",
        url: `QR/getAllSticker`,
      });

      setQrStickerData(res);
    } catch (error) {}
  };

  const requestToGenerateQR = async () => {
    // if (!activeImage) {
    //   toast.error("please select the image");
    //   return;
    // }
    const count = qrCountToGenerate;
    if (count <= 0) {
      setValidationErrors({
        ...validationErrors,
        count: "*Request for generating at least one QR.",
      });
      // toast.error("Request for generating at least one QR.");
      return;
    }
    if (count > MAX_QR_COUNT) {
      setValidationErrors({
        ...validationErrors,
        count: `*You can process a maximum of ${MAX_QR_COUNT} QR sticker at any given time.`,
      });
      // toast.info(
      //   `Sorry, we are only able to process ${MAX_QR_COUNT} QR at one time. We will generate ${MAX_QR_COUNT} QR for the moments.`
      // );
      return;
    }
    setValidationErrors((prev) => ({
      ...prev,
      count: "",
    }));

    if (!batchName) {
      setValidationErrors((prev) => ({
        ...prev,
        batchError: "*Add the batch name to qr",
      }));
      return;
    }

    setValidationErrors((prev) => ({
      ...prev,
      batchError: "",
    }));

    if (publicViewId === "" && groupState === "") {
      setValidationErrors({
        ...validationErrors,
        linkQr: `*Please link this Qr either to public page or group`,
      });
      return;
    }

    setValidationErrors((prev) => ({
      ...prev,
      linkQr: "",
    }));

    try {
      await verifyBatch({ batch: batchName });
      // if (verifyResponse) {
      //   toast.error(verifyResponse);
      //   return;
      // }
      const qrResponse = await handleStickerGenerate({
        method: "post",
        url: generateStickerWithQR,
        data: {
          stickerId: qrStickerData._id,
          stickerImageUrl: qrStickerData.URL,
          count: count > MAX_QR_COUNT ? MAX_QR_COUNT : count,
          publicViewId: radioValue === "PublicPage" ? publicViewId : null,
          groupId: radioValue === "Group" ? groupState : null,
          batch: batchName ? batchName : null,
        },
      });
      toast.success(qrResponse?.message + ".");
      setShowQrInSticker(false);
      setQrStickerData({});
      setPublicViewId("");
      setGroupState("");
      setValidationErrors({
        count: "",
        linkQr: "",
        batchError: "",
      });
      setSuccessBatchName("");
      setQrCountToGenerate(1);
      getAllQr({ page: 0, limit: 10 });
    } catch (error) {
    } finally {
      setModalOpen(false);
      searchParams.delete("sticker");
      setSearchParams(searchParams);
    }
  };

  const getAllQr = (params) => {
    if (params?.status === "All") {
      getRequest(
        QR.getAllQRCodes("set", {
          ...params,
          status: null,
          archive: null,
          batch: params?.batch,
        })
      );
    } else {
      getRequest(QR.getAllQRCodes("set", params));
    }
  };

  const refetchQrSticker = () => {
    const { page, limit, search, status, batch } = pagination;
    if (qr?.length > 1) {
      let archive = false;
      let hasGroup = false;
      if (status === "Blocked") {
        archive = true;
      }

      if (status === "NotGrouped") {
        hasGroup = false;
      }
      if (status === "NotGrouped") {
        getAllQr({
          page,
          limit,
          ...(search && { search }),
          archive,
          hasGroup: hasGroup,
          batch: batch,
        });
      } else {
        getAllQr({
          page,
          limit,
          status,
          ...(search && { search }),
          archive,
          batch: batch,
        });
      }
    } else {
      if (page > 0) {
        handlePagination(page - 1, limit);
      }
    }
  };

  useEffect(() => {
    // if (qr.length === 0) {
    const { limit } = pagination;
    dispatch(
      store.Actions["update"]("qr.pagination", {
        page: 0,
        status: "All",
        batch: "",
        archive: false,
        limit,
      })
    );
    getAllQr({
      page: 0,
      limit,
      status: "All",
    });
    // }
  }, []);

  useEffect(() => {
    if (qrGeneratedNotifications?._id) {
      const { search, status } = pagination;
      dispatch(
        store.Actions["update"]("qr.pagination", {
          status: ["All", "New"].includes(pagination?.status)
            ? pagination?.status
            : "All",
        })
      );
      getAllQr({
        page: 0,
        limit: 10,
        status: ["All", "New"].includes(pagination?.status)
          ? pagination?.status
          : "All",
      });
      Store.store.dispatch(Store.Actions.set("qrGeneratedNotifications", {}));
    }
  }, [qrGeneratedNotifications]);

  const onSelectQrImage = (value) => {
    setQrStickerData({ ...value });
  };

  const handleSelect = () => {
    setModalOpen(false);
    setShowQrInSticker(true);
  };
  const onCloseShowQrInSticker = (closeAll = true) => {
    setShowQrInSticker(false);
    if (!closeAll) {
      setModalOpen(true);
    }
    setQrStickerData({});
    searchParams.delete("sticker");
    setSearchParams(searchParams);
    setPublicViewId("");
    setGroupState("");
    setValidationErrors({
      linkQr: "",
      count: "",
      batchError: "",
    });
    setSuccessBatchName("");
    setBatchName("");
    setQrCountToGenerate(1);
  };
  const closeDeleteModal = () => {
    setIsDeleteModalVisible(false);
    setSelectedQRSticker({});
  };

  const updateBlockAndUnblockStatus = (actionType, id) => {
    dispatch(store.Actions.remove("qr", id));
    refetchQrSticker();
    // if (
    //   ["blocked", "unblocked"].includes(actionType) &&
    //   pagination?.status === "All"
    // ) {
    //   const copy = JSON.parse(JSON.stringify(qr));
    //   const updatedData = copy.filter((x) => {
    //     if (x._id === id) {
    //       const update = {
    //         ...x,
    //         isArchive: !x.isArchive,
    //       };
    //       return {
    //         ...update,
    //       };
    //     }
    //     return x;
    //   });
    //   dispatch(store.Actions["set"]("qr.data", [...updatedData]));
    // } else {
    //   const updatedData = qr.filter((x) => x._id !== id);
    //   dispatch(store.Actions["set"]("qr.data", updatedData));
    // }
  };

  const handleDeleteQRSticker = async (qrStickerId) => {
    try {
      setIsLoading(true);
      const response = await request(QR.deleteQrSticker(qrStickerId));
      if (response) {
        const actionType =
          selectedQRSticker?.status === "New"
            ? t("remain.deleted")
            : selectedQRSticker?.isArchive && blockedDelete === "DeleteBlocked"
            ? t("remain.deleted")
            : selectedQRSticker?.isArchive && blockedDelete === "Unblock"
            ? t("remain.unblocked")
            : t("remain.blocked");
        updateBlockAndUnblockStatus(actionType, qrStickerId);
        const toastMessage = `${t("remain.qr-sticker")} ${
          selectedQRSticker?.status === "New"
            ? t("remain.deleted")
            : selectedQRSticker?.isArchive && blockedDelete === "DeleteBlocked"
            ? t("remain.deleted")
            : selectedQRSticker?.isArchive && blockedDelete === "Unblock"
            ? t("remain.unblocked")
            : t("remain.blocked")
        } ${t("remain.successfully")}`;
        toast.success(toastMessage);
        closeDeleteModal();
      }
    } catch (error) {
      console.log("Delete QrSticker:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUnblockQRSticker = async (qrStickerId) => {
    try {
      setIsLoading(true);
      const response = await request(QR.unblockQRSticker(qrStickerId));
      if (response) {
        const actionType = "unblocked";
        updateBlockAndUnblockStatus(actionType, qrStickerId);
        toast.success(t("remain.toast-unblock-qr-sticker"));
        closeDeleteModal();
      }
    } catch (error) {
      console.log("Unblock QrSticker:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const factor = useMemo(
    () => ({
      x: imageSize.width / imageRefSize.width,
      y: imageSize.height / imageRefSize.height,
    }),
    [imageRefSize, imageSize]
  );
  const stickerDimensions: {
    stickerWidth: number;
    stickerHeight: number;
    qrWidth: number;
    qrHeight: number;
    qrTop: number;
    qrLeft: number;
  } = qrStickerData?.stickerDimension || {};

  const verifyBatch = async (data) => {
    setButtonLoading(true);
    try {
      const res = await request(QR.verifyBatchName(data));
      if (res.success) {
        setSuccessBatchName("This batch name is availiable.");
      }
    } catch (error) {
      setValidationErrors((prev) => ({
        ...prev,
        batchError: "This batch is already taken.",
      }));
    }
    setButtonLoading(false);
  };

  return (
    <>
      {isTablet ? (
        <>
          <HeaderWrapper title={t("dashboard.qr-generator")} />
          <ActionWrapper>
            <BaseTabs
              items={items}
              handleTabs={handleTabs}
              statusValue={pagination.status}
            />
            <ActionWrapper>
              <QrFilter pagination={pagination} handleBatch={handleBatch} />
              <ActionButton
                actionTitle={t("dashboard.create-qr")}
                handleAction={handlegeneratedQR}
              />
            </ActionWrapper>
          </ActionWrapper>
        </>
      ) : (
        <>
          <ActionWrapper>
            <HeaderWrapper title={t("dashboard.qr-generator")} />
            <ActionWrapper>
              <QrFilter pagination={pagination} handleBatch={handleBatch} />
              <ActionButton
                actionTitle={t("dashboard.create-qr")}
                handleAction={handlegeneratedQR}
              />
            </ActionWrapper>
          </ActionWrapper>

          <BaseTabs
            items={items}
            handleTabs={handleTabs}
            statusValue={pagination.status}
          />
        </>
      )}

      <QrFilterStatus
        pagination={pagination}
        handleClearFilter={handleClearFilter}
      />
      {qr && pagination.batch !== "" && (
        <MultiFileDownload downloadData={qr} batchCount={batchCount} />
      )}

      {isModalOpen && (
        <Modal
          open={isModalOpen}
          title={t("dashboard.generate-qr")}
          onCancel={() => {
            setModalOpen(false);
            setSuccessBatchName("");
            setBatchName("");
          }}
          footer={[]}
        >
          <Formik
            initialValues={{ imageSelect: "", count: 1 }}
            onSubmit={() => {
              handleSelect();
              // requestToGenerateQR({ count });
            }}
            validationSchema={validationSchema}
            validateOnBlur={false}
          >
            {({ values, setFieldValue, errors }) => {
              return (
                <Form>
                  {stickerRequestLoading ? (
                    <S.LoaderWrapper>
                      <BaseLoading loadingsize={50} />
                    </S.LoaderWrapper>
                  ) : (
                    <BaseImageSelector
                      imageData={stickerData}
                      activeImage={qrStickerData}
                      setActiveImage={onSelectQrImage}
                      name={"imageSelect"}
                    />
                  )}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "20px",
                    }}
                  >
                    <BaseButton
                      style={{ marginRight: "12px" }}
                      onClick={() => setModalOpen(false)}
                    >
                      {t("dashboard.cancel")}
                    </BaseButton>
                    <BaseButton
                      htmlType="submit"
                      type="primary"
                      style={{ background: "var(--primary)", color: "#fff" }}
                    >
                      {t("dashboard.select")}
                    </BaseButton>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Modal>
      )}

      {showQrInSticker && !!qrStickerData._id && (
        <Modal
          open={showQrInSticker}
          onCancel={() => onCloseShowQrInSticker(true)}
          title={t("remain.selected-sticker")}
          footer={null}
          width={!isTablet ? "100vw" : "80vw"}
        >
          <S.QRPositionInStickerContainerStyled>
            <BaseTypography.Text>
              {t("new.represented-of-qr")}
            </BaseTypography.Text>
            <FlexEnd>
              <BaseButton
                onClick={() => {
                  navigate(`/dashboard/sticker?sticker=${qrStickerData?._id}`);
                }}
              >
                {t("new.edit-sticker")}
              </BaseButton>
            </FlexEnd>

            <ViewQrSticker
              frontEndURL={`https://cameracompliance.com/qr/this-is-test-qr.`}
              stickerURL={qrStickerData?.URL}
              stickerDimensions={qrStickerData?.stickerDimension}
            />
          </S.QRPositionInStickerContainerStyled>
          <BaseTypography style={{ marginBottom: 4 }}>
            {t("dashboard.number-generate")}
          </BaseTypography>
          <InputNumber
            name={"count"}
            value={qrCountToGenerate}
            type="number"
            min={1}
            defaultValue={qrCountToGenerate}
            // max={MAX_QR_COUNT}
            onChange={(val) => setQrCountToGenerate(val)}
          />

          <div style={{ marginBottom: "20px" }} />
          <BatchWrapper>
            <BaseTypography>
              {t("dashboard.batch")} {t("remain.name")}
            </BaseTypography>
            <Input
              placeholder={t("remain.unique-batch-name")}
              style={{ width: "50%" }}
              onChange={(e) => {
                setBatchName(e.target.value);
                setValidationErrors((prev) => ({
                  ...prev,
                  batchError: "",
                }));
                setSuccessBatchName("");
              }}
            />
            <BaseButton
              style={{
                marginLeft: "20px",
              }}
              onClick={() => verifyBatch({ batch: batchName })}
              loading={buttonLoading}
            >
              {t("remain.verify")}
            </BaseButton>
            {!!successBatchName && (
              <BaseError color="success">{successBatchName}</BaseError>
            )}
            <BaseError>{validationErrors["batchError"]}</BaseError>
          </BatchWrapper>

          <LinkOptions>
            <LinkMessage>{t("remain.select-one-of-the-option")}</LinkMessage>
            <BaseTypography.Text
              type="secondary"
              style={{ marginBottom: "8px", display: "block" }}
            >
              {t("dashboard.note-publicpage")}
            </BaseTypography.Text>
            <RadioWrapper>
              <Radio.Group onChange={OnRadioChange} value={radioValue}>
                <Radio value={"PublicPage"}>{t("remain.public-page")}</Radio>
                <Radio value={"Group"}>{t("remain.group")}</Radio>
              </Radio.Group>
            </RadioWrapper>
          </LinkOptions>
          {radioValue === "PublicPage" && (
            <PublicViewInfo
              publicViewId={publicViewId}
              setPublicViewId={setPublicViewId}
              radioValue={radioValue}
            />
          )}
          {radioValue === "Group" && (
            <GroupViewInfo
              groupState={groupState}
              setGroupState={setGroupState}
              radioValue={radioValue}
            />
          )}

          <Divider />
          <S.StickerInfoStyled>
            <BaseTypography.Text style={{ display: "block" }}>
              {t("new.verify")}
            </BaseTypography.Text>
            <BaseTypography.Text
              type="secondary"
              style={{ marginBottom: "8px", display: "block" }}
            >
              {t("new.note")}
            </BaseTypography.Text>
            <div className="info-row">
              <BaseTypography.Text className="title">
                {t("dashboard.created-date")}
              </BaseTypography.Text>
              <BaseTypography.Text className="value">
                {fullConvertTimestamp(qrStickerData?.createdAt)}
              </BaseTypography.Text>
            </div>
            <div className="info-row">
              <BaseTypography.Text className="title">
                {t("new.sticker-size")}
              </BaseTypography.Text>
              <BaseTypography.Text className="value">
                <span>{`${stickerDimensions?.stickerWidth}px `}</span>
                <span className="cross">X</span>
                <span>{` ${stickerDimensions?.stickerHeight}px`}</span>
              </BaseTypography.Text>
            </div>
            <div className="info-row">
              <BaseTypography.Text className="title">
                {t("new.qr-size")}
              </BaseTypography.Text>
              <BaseTypography.Text className="value">
                <span>{`${stickerDimensions?.qrWidth}px `}</span>
                <span className="cross">X</span>
                <span>{` ${stickerDimensions?.qrHeight}px`}</span>{" "}
              </BaseTypography.Text>
            </div>
            <div className="info-row">
              <BaseTypography.Text className="title">
                {t("new.qr-position")}
              </BaseTypography.Text>
              <BaseTypography.Text className="value">
                <span>{`x (${stickerDimensions?.qrLeft}px)`}</span>
                <span className="cross">,</span>
                <span>{`y (${stickerDimensions?.qrTop}px)`}</span>{" "}
              </BaseTypography.Text>
            </div>
            <div className="info-row">
              <BaseTypography.Text className="title">
                {t("new.no-of-qr-sticker")}
              </BaseTypography.Text>
              <BaseTypography.Text className="value">
                {qrCountToGenerate}
              </BaseTypography.Text>
            </div>
          </S.StickerInfoStyled>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <BaseButton
              style={{ marginRight: "12px" }}
              onClick={() => onCloseShowQrInSticker(false)}
            >
              {t("new.back")}
            </BaseButton>
            <BaseButton
              style={{ marginRight: "12px" }}
              onClick={() => onCloseShowQrInSticker(true)}
            >
              {t("new.close")}
            </BaseButton>
            <BaseButton
              type="primary"
              style={{ background: "var(--primary)", color: "#fff" }}
              loading={stickerGenerateLoading}
              onClick={requestToGenerateQR}
            >
              {t("new.generate-qr")}
            </BaseButton>
          </div>
          <BaseError>{validationErrors["count"]}</BaseError>
          <BaseError>{validationErrors["batchError"]}</BaseError>
          <BaseError>{validationErrors["linkQr"]}</BaseError>
        </Modal>
      )}
      <>
        {isLargeMobile ? (
          <BaseTable
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            loading={loader}
            onRow={(record) => ({
              onClick: async (e) => {
                if (!imagePreviewActive) {
                  // Only navigate if the image preview is not active
                  navigate(record.id);
                }
              },
              style: { cursor: "pointer" },
            })}
          />
        ) : (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {qr?.map((qr) => (
              <QRGeneratorTableRowCard
                url={qr.URL}
                stickerUrl={qr.stickerURL}
                createdDate={qr.createdAt}
                status={qr.status}
                onDownloadClick={async () => {
                  downloadFile(qr.URL);
                }}
                onViewClick={async () => {
                  navigate(qr._id);
                }}
              />
            ))}
          </div>
        )}
        <BasePagination
          pagination={pagination}
          handlePagination={handlePagination}
        />
      </>
      <ConfirmationMessagePopup
        isVisible={isDeleteModalVisible}
        onAction={() => {
          if (selectedQRSticker?.isArchive) {
            if (blockedDelete === "DeleteBlocked") {
              handleDeleteQRSticker(selectedQRSticker?._id);
            } else {
              handleUnblockQRSticker(selectedQRSticker?._id);
            }
          } else {
            handleDeleteQRSticker(selectedQRSticker?._id);
          }
        }}
        actionLabel={
          selectedQRSticker?.status === "New"
            ? t("modal.delete-QR-btn")
            : selectedQRSticker?.isArchive && blockedDelete === "DeleteBlocked"
            ? t("modal.delete-QR-btn")
            : selectedQRSticker?.isArchive && blockedDelete === "Unblock"
            ? t("modal.unblock-QR-btn")
            : t("modal.block-QR-btn")
        }
        onClose={closeDeleteModal}
        title={`${
          selectedQRSticker?.status === "New"
            ? "Delete"
            : selectedQRSticker?.isArchive && blockedDelete === "DeleteBlocked"
            ? "Delete"
            : selectedQRSticker?.isArchive && blockedDelete === "Unblock"
            ? "UnBlock"
            : "Block"
        } ${t("modal.qr-sticker")}`}
        actionIsDanger={
          selectedQRSticker?.status === "New" ||
          (selectedQRSticker?.isArchive && blockedDelete === "DeleteBlocked")
        }
        isLoading={isLoading}
      >
        <BaseTypography.Text style={{ marginBottom: "20px" }}>
          {selectedQRSticker?.status === "New"
            ? t("modal.delete-qr-message")
            : selectedQRSticker?.isArchive
            ? t("modal.unblock-qr-message")
            : t("modal.block-qr-message")}
        </BaseTypography.Text>
        <br />
        <br />
        <FlexColumnAlignCenter>
          <Image
            src={selectedQRSticker?.stickerURL}
            style={{ maxHeight: "150px", margin: "0 auto" }}
          />
        </FlexColumnAlignCenter>
      </ConfirmationMessagePopup>
    </>
  );
};

export default GeneratedQR;

const Column = styled.div`
  ${flexRow}
`;

const LinkOptions = styled.div`
  margin: 0px 0px 30px;
  background: #fff;
  margin: 20px 0px;
`;

const RadioWrapper = styled.div`
  padding: 10px 0px;
`;

const LinkMessage = styled.div``;

const BatchWrapper = styled.div``;

const DescriptionMessage = styled.div``;
