import React, { useEffect, useState } from "react";
import {
  HomeFilled,
  CloseCircleOutlined,
  CheckCircleOutlined,
  QrcodeOutlined,
  UserOutlined,
  SettingOutlined,
  EnvironmentOutlined,
  CameraOutlined,
} from "@ant-design/icons";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import MainHeader from "../MainHeader/MainHeader";
import Screen from "components/Layout/Screen";
import * as S from "./index.styles";
import { BaseLayout } from "../../../components/common/BaseLayout";
import { useResponsive } from "../../../hooks/useResponsive";
//@ts-ignore
import { ReactComponent as QR_LOGO_SVG } from "../../../assets/logo/qr-logo.svg";
import { useRoot } from "RootProvider";
import { Typography } from "antd";
import Sidebar from "./Sidebar";

const MainLayout = ({ QR_DASHBOARD_MAIN_PATH }: any) => {
  const { auth } = useRoot();
  const userRole = auth?.userDetails?.role;

  const { pathname } = useLocation();
  const [isChangePassword, setIsChangePassword] = useState(false);
  const [isPathChecked, setIsPathChecked] = useState(false);
  const [center, setCenter] = useState<boolean>();
  const [collapsed, setCollapsed] = useState<boolean>(true);

  const { Header, Content, Footer, Sider } = BaseLayout;
  const { isBigScreen } = useResponsive();

  const comparePathName = (pathname: string) => {
    if (pathname.startsWith("/city/")) {
      console.log("the state");
      setCenter(true);
    } else if (pathname.startsWith("/qr/")) {
      setCenter(true);
    } else {
      setCenter(false);
    }
  };

  useEffect(() => {
    setIsPathChecked(false);
    if (pathname.includes("change-password")) {
      setIsChangePassword(true);
    }
    setIsPathChecked(true);
  }, [pathname]);

  useEffect(() => {
    comparePathName(pathname);
  }, [pathname]);

  if (!isPathChecked) {
    return null;
  }

  if (isChangePassword) {
    return <Outlet />;
  }
  const handleToggleCollapsed = () => {
    setCollapsed((prev) => !prev);
  };

  return (
    <S.LayoutMaster>
      <MainHeader onToggleSideBar={handleToggleCollapsed} />
      <S.LayoutMain>
        <Sidebar
          QR_DASHBOARD_MAIN_PATH={QR_DASHBOARD_MAIN_PATH}
          userRole={userRole}
          onToggleSideBar={handleToggleCollapsed}
          isCollapsed={collapsed}
        />
        <S.OverlayStyled
          $iscollapsed={collapsed}
          onClick={handleToggleCollapsed}
        />

        <BaseLayout>
          <Screen>
            <Content>
              <Outlet />
            </Content>
          </Screen>
        </BaseLayout>
      </S.LayoutMain>
    </S.LayoutMaster>
  );
};

export default MainLayout;
