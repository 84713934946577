import React from "react";
import { ChooseCameraStyled } from "./style";
import Slider from "../../../assets/slider.svg";
import User from "../../../assets/user-expertise.svg";
import Lock from "../../../assets/lock.svg";
import chooseCamera from "../../../assets/choose-camera.png";
import { Button, Typography } from "antd";
import { useTranslation } from "react-i18next";
type Props = {};

const chooseCameraData = [
  {
    img: <img src={User} />,
    title: "Expertise in GDPR and Surveillance Technology",
    description:
      "Our team combines legal expertise in GDPR with technological acumen to provide cutting-edge solutions.",
  },
  {
    img: <img src={Slider} />,
    title: "Customizable Solutions",
    description:
      "We tailor our products and services to meet the unique needs of each city or municipality.",
  },
  {
    img: <img src={Lock} />,
    title: "Commitment to Privacy and Transparency:",
    description:
      "At our core, we are dedicated to upholding the rights of individuals while enhancing public safety and trust.",
  },
];

const ChooseCamera = (props: Props) => {
  const { t } = useTranslation();

  return (
    <ChooseCameraStyled>
      <div className="left-contents">
        <Typography.Title className="title">
          {/* {t("homePage.about-us-title")} */}
          Why Choose Camera Compliance?
        </Typography.Title>
        <Typography.Paragraph className="description">
          {/* {t("homePage.about-us-description")} */}
          In a world where surveillance is essential yet privacy is paramount,
          Camera Compliance Ltd stands as a beacon of balance, ensuring that our
          cities can be both watched and trusted.
        </Typography.Paragraph>

        <div className="data-container">
          {chooseCameraData?.map((item) => {
            return (
              <div className="data">
                <div className="title-container">
                  {item?.img}
                  <Typography className="data-title">{item?.title}</Typography>
                </div>
                <Typography className="data-desc">
                  {item?.description}
                </Typography>
              </div>
            );
          })}
          {/* <Button className="btn">Read More</Button> */}
        </div>
      </div>
      <div className="right-contents">
        <img src={chooseCamera} alt="hero" />
      </div>
    </ChooseCameraStyled>
  );
};

export default ChooseCamera;
