import { styled } from "styled-components";
import { flexRowAlignCenterJustifySpaceBetween } from "utils/styles";

export const ViewCameraStyled = styled.div`
  & .camera-row {
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    & .title {
      font-weight: 600;
      display: block;
      width: 120px;
      color: var(--gray);
    }
    & .value {
    }
  }

  & .add-camera-title-wrapper {
    ${flexRowAlignCenterJustifySpaceBetween}
  }

  & .camera-images-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    margin-bottom: 20px;

    & .image-wrapper {
      border: 1px solid #dbdbdb;
      border-radius: 6px;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      & .ant-image-img.camera-image {
        width: 100%;
        height: 200px;
        object-fit: cover;
      }
    }
  }
`;
