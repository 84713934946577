import React, { useState, useEffect, useRef } from "react";
import { CloseCircleOutlined } from "@ant-design/icons";
import BaseButton from "../BaseButton";
import useAPI from "hooks/useApi";
import { BaseInput } from "../BaseInput";
import { BaseTypography } from "../BaseTypography";
import AsyncSelect from "react-select/async";
import { BaseSelect } from "../BaseSelect";
import { allCitiesDropDown, allCity } from "constants/api/city";
import axios from "axios";
import { useField } from "formik";
import BaseError from "../BaseError";
import ImageUpload from "./imageUpload";
import { useTranslation } from "react-i18next";

const BaseUploader = ({ setCityId, name }: any) => {
  const [field, meta, helpers] = useField(name);
  const { touched, error } = meta;
  const { t } = useTranslation();
  const [options, setOptions] = useState([]);
  const [handlerequest, { loading: stickerAddRequest }] = useAPI();
  const [getRequest, { loading, data: allCityData }] = useAPI();

  const getAllCities = async () => {
    try {
      const res = await getRequest({
        method: "get",
        url: allCitiesDropDown,
      });
      setOptions(res);
    } catch (error) {}
  };

  useEffect(() => {
    getAllCities();
  }, []);

  return (
    <>
      <BaseTypography>{t("dashboard.select-city")} </BaseTypography>
      <BaseSelect
        style={{
          width: "100%",
        }}
        allowClear
        showSearch
        placeholder={t("dashboard.select-a-city")}
        options={options}
        onClear={() => helpers.setValue("")}
        onSelect={(value, option) => {
          setCityId && setCityId(option?.id);
          helpers.setValue(option?.id);
        }}
      />

      {error && <BaseError>{error}</BaseError>}
    </>
  );
};

export default BaseUploader;
