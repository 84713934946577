import { GoBack } from "components/GoBack";
import BaseLoading from "components/common/BaseSpin";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import QR from "shared/front/apis/qr.api";
import request from "utils/request";
import CameraInfo from "./CameraInfo";
import GroupViewInfo from "./GroupViewInfo";
import HeaderInfo from "./HeaderInfo";
import PublicViewInfo from "./PublicViewInfo";
import QRImages from "./QRImages";
import ScanInfo from "./ScanInfo";
import Stats from "./Stats";
import { GeneratedQrDetailsStyled } from "./styled";
import { useTranslation } from "react-i18next";

import { Radio, Spin } from "antd";
import { getI18n } from "react-i18next";

import { styled } from "styled-components";
import useAPI from "hooks/useApi";
import groupApi from "shared/front/apis/group.api";
import { media } from "styles/themes/constants";

type Props = {};

const GeneratedQRDetails = (props: Props) => {
  const { language: selectedlang } = getI18n();
  const activeQrData = useSelector((state: any) => state?.qrDetails?.data);
  const [activeQrDataloaing, setActiveQrDataLoading] = useState(false);
  const [error, setError] = useState("");
  const [option, setOption] = useState("");
  const [changingOption, setChangingOption] = useState(false);
  const { t } = useTranslation();
  const { id } = useParams();
  const [makeRequest, { loading: groupDataLoading, data: groupDataById }] =
    useAPI();

  const handleRequest = async () => {
    setActiveQrDataLoading(true);
    await request(QR.getDetailsByQRCodeId(id));
    setActiveQrDataLoading(false);
  };

  const linkQRCode = async (id, data) => {
    try {
      setChangingOption(true);
      await request(QR.markQrToShow(id, data));
      handleRequest();
    } catch (error) {
      console.log(error);
    } finally {
      setChangingOption(false);
    }
  };

  const getSelectedLanguageTitle = (details) => {
    if ((Array.isArray(details) && details.length === 0) || !details) {
      return "N/A";
    }

    const selectedLangTitle = details.find((x) => x?.lang === selectedlang);
    if (!!selectedLangTitle?.title) {
      return selectedLangTitle?.title;
    }
    return details[0].title;
  };

  useEffect(() => {
    if (activeQrData?.QRDetails?.group) {
      makeRequest(groupApi.getGroupDetail(activeQrData?.QRDetails?.group));
    }
  }, [activeQrData?.QRDetails?.group]);

  useEffect(() => {
    if (activeQrData?.QRDetails?.isShowFromGroup) {
      setOption("group");
    } else {
      setOption("publicView");
    }
  }, [activeQrData]);

  useEffect(() => {
    handleRequest();
  }, [id]);
  return (
    <>
      <GoBack />
      {activeQrDataloaing && !activeQrData ? (
        <BaseLoading />
      ) : (
        <GeneratedQrDetailsStyled>
          <HeaderInfo activeQrData={activeQrData} />
          {!!activeQrData?.QRDetails?.publicView &&
            !!activeQrData?.QRDetails?.group && (
              <LinkOptions>
                <LinkMessage>
                  {t("remain.select-one-of-the-option")}
                </LinkMessage>

                <Radio.Group
                  value={option}
                  onChange={(e: any) => {
                    if (
                      activeQrData?.QRDetails?.publicView ||
                      activeQrData?.QRDetails?.group
                    ) {
                      if (e.target.value === "group") {
                        linkQRCode(id, { isShowFromGroup: "true" });
                      } else {
                        linkQRCode(id, { isShowFromGroup: "false" });
                      }
                      setOption(e.target.value);
                    }
                  }}
                  style={{
                    margin: "20px 10px",
                  }}
                  disabled={changingOption}
                >
                  <OptionStyle>
                    {activeQrData?.QRDetails?.publicView && (
                      <RadioStyle value={"publicView"}>
                        {t("remain.individual")} {t("remain.public-page")}
                      </RadioStyle>
                    )}
                    {activeQrData?.QRDetails?.group && (
                      <RadioStyle value={"group"}>
                        {t("remain.group")} -{" "}
                        {getSelectedLanguageTitle(
                          groupDataById?.publicView?.details
                        )}
                      </RadioStyle>
                    )}
                  </OptionStyle>
                </Radio.Group>
                {changingOption && <Spin />}
              </LinkOptions>
            )}
          {!activeQrData?.QRDetails?.publicView &&
            !activeQrData?.QRDetails?.group && (
              <NoLinkGroup>{t("remain.link-to-public-page")}</NoLinkGroup>
            )}
          <PublicViewInfo
            id={id}
            publicPageId={activeQrData?.QRDetails?.publicView}
            isSelected={
              !!activeQrData?.QRDetails?.publicView &&
              !!activeQrData?.QRDetails?.group &&
              !activeQrData?.QRDetails?.isShowFromGroup
            }
            linkQRCode={() => linkQRCode(id, { isShowFromGroup: false })}
            isBoth={
              !!activeQrData?.QRDetails?.publicView &&
              !!activeQrData?.QRDetails?.group
            }
          />

          <GroupViewInfo
            id={id}
            groupId={activeQrData?.QRDetails?.group}
            isSelected={
              !!activeQrData?.QRDetails?.group &&
              !!activeQrData?.QRDetails?.publicView &&
              activeQrData?.QRDetails?.isShowFromGroup
            }
            linkQRCode={() => linkQRCode(id, { isShowFromGroup: true })}
            groupDataById={groupDataById}
            isBoth={
              !!activeQrData?.QRDetails?.publicView &&
              !!activeQrData?.QRDetails?.group
            }
          />

          <CameraInfo
            info={activeQrData?.QRDetails?.camera}
            qrId={activeQrData?.QRDetails?._id}
            qrDetails={activeQrData?.QRDetails}
            refetch={handleRequest}
            groupDataById={groupDataById}
            isPublicPage={
              !!activeQrData?.QRDetails?.publicView &&
              !activeQrData?.QRDetails?.isShowFromGroup
            }
          />
          <ScanInfo activeQrData={activeQrData} />
          <Stats activeQrData={activeQrData} />
          <QRImages images={activeQrData?.QRDetails?.images} />
        </GeneratedQrDetailsStyled>
      )}
    </>
  );
};

export default GeneratedQRDetails;

const LinkOptions = styled.div`
  margin: 0px 0px 30px;
  background: #fff;
  padding: 20px;
`;

const LinkMessage = styled.div``;

const NoLinkGroup = styled.div`
  font-style: italic;
  text-align: center;
  color: var(--gray);
  margin: 30px 0px;
`;

const Option = styled.div`
  /* background-color: white;
  padding: 10px; */
`;
const RadioStyle = styled(Radio)`
  label:not(:first-child) {
    margin-top: 20px;
  }
  .ant-radio + span {
    word-break: break-word;
  }

  @media only screen and ${media.md} {
    .ant-radio + span {
      word-break: normal;
      margin-top: 0px;
    }
  }
`;
const OptionStyle = styled(Option)`
  label:not(:first-child) {
    margin-top: 20px;
  }
  .ant-radio {
    align-self: flex-start;
  }
`;
