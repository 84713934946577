import { getAllSticker } from "constants/api/sticker";

const stickers = {
  getAllSticker: (action, params): any => {
    return {
      url: getAllSticker,
      method: "get",
      params: params,
      config: {
        store: {
          action: action,
          key: "sticker.data",
        },
      },
    };
  },
  getASticker: (stickerId: string): any => {
    return {
      url: `QR/getSticker/${stickerId}`,
      method: "get",
      config: {
        store: {
          action: "set",
          key: "selectedSticker",
        },
      },
    };
  },
  updateSticker: ({ data, stickerId }: IUpdateSticker) => {
    return {
      url: `QR/updateSticker/${stickerId}`,
      method: "patch",
      data,
    };
  },

  deleteSticker: (stickerId: string) => {
    return {
      url: `QR/deleteSticker/${stickerId}`,
      method: "delete",
    };
  },
  unblockSticker: (stickerId: string) => {
    return {
      url: `QR/unBlockStickerById/${stickerId}`,
      method: "post",
    };
  },
};

export default stickers;

interface IUpdateSticker {
  stickerId: string;
  data: {
    stickerDimension: {
      stickerWidth: number;
      stickerHeight: number;
      qrTop: number;
      qrLeft: number;
      qrWidth: number;
      qrHeight: number;
      qrOptions: {
        color: {
          dark: string;
          light: string;
        };
        margin: number;
        borderRadius: number;
      };
    };
  };
}
