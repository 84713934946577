export default [
  {
    title: "1. Informacije, ki jih zbiramo:",
    desc: [
      {
        title: "1.1",
        desc: "Osebne informacije: Ko interaktirate z našim projektom za skladnost s kamerami, lahko zbiramo osebne informacije, kot so vaše ime, elektronski naslov in kontaktni podatki.",
      },
      {
        title: "1.2",
        desc: "Informacije o napravi: Zbiramo lahko informacije o napravi, ki jo uporabljate za dostop do naših storitev, vključno z vrsto naprave, operacijskim sistemom, vrsto brskalnika in IP naslovom.",
      },
      {
        title: "1.3",
        desc: "Informacije o lokaciji: Z vašim izrecnim soglasjem lahko zbiramo informacije o lokaciji vaše naprave, da vam zagotovimo natančne informacije o skladnosti, povezane z mestom in območjem, kjer se nahajate.",
      },
      {
        title: "1.4",
        desc: "Informacije o skeniranju kamere: Ko skenirate QR kodo na drogu s kamero, lahko zbiramo podatke o lokaciji kamere, datumu in uri skeniranja ter pridobljenih informacijah o skladnosti.",
      },
    ],
  },
  {
    title: "2. Kako uporabljamo vaše informacije:",
    desc: [
      {
        title: "2.1",
        desc: "Zbrane informacije uporabljamo za zagotavljanje natančnih informacij o skladnosti, povezanih z območjem mesta, kjer ste skenirali QR kodo.",
      },
      {
        title: "2.2",
        desc: "Vaše osebne informacije lahko uporabimo, da vam pošljemo posodobitve, obvestila in pomembne informacije v zvezi s projektom skladnosti s kamerami.",
      },
      {
        title: "2.3",
        desc: "Analiziramo združene in anonimizirane podatke za statistične namene, kar nam lahko pomaga izboljšati naše storitve.",
      },
    ],
  },

  {
    title: "3. Delitev informacij:",
    desc: [
      {
        title: "3.1",
        desc: "Vaših osebnih informacij ne delimo s tretjimi osebami za namene trženja brez vašega izrecnega soglasja.",
      },
      {
        title: "3.2",
        desc: "Vaše informacije lahko delimo s ponudniki storitev, ki nam pomagajo pri zagotavljanju naših storitev. Ti ponudniki storitev so dolžni ohranjati zaupnost vaših informacij.",
      },
      {
        title: "3.3",
        desc: "Vaše informacije lahko razkrijemo, če je to zahtevano z zakonom ali za zaščito naših pravic, zasebnosti, varnosti ali lastnine.",
      },
    ],
  },

  {
    title: "4. Varnost podatkov:",
    desc: [
      {
        title: "4.1",
        desc: "Uvedemo varnostne ukrepe za zaščito vaših osebnih informacij pred nepooblaščenim dostopom, spremembami, razkritjem ali uničenjem.",
      },
      {
        title: "4.2",
        desc: "Kljub našim prizadevanjem ni noben prenos ali shranjevanje podatkov na spletu popolnoma varno. Zato ne moremo zagotoviti absolutne varnosti vaših informacij.",
      },
    ],
  },

  {
    title: "5. Vaše možnosti:",
    desc: [
      {
        title: "5.1",
        desc: "Odjavite se lahko od prejemanja marketinških komunikacij od nas tako, da sledite navodilom za odjavo, ki so navedena v komunikaciji.",
      },
      {
        title: "5.2",
        desc: "Spreminjanje zbiranja informacij o lokaciji lahko nadzorujete tako, da prilagodite nastavitve na svoji napravi.",
      },
    ],
  },

  {
    title: "6. Zasebnost otrok:",
    desc: [
      {
        title: "6.1",
        desc: "Naše storitve niso namenjene posameznikom, mlajšim od [age]. Zavedno ne zbiramo osebnih informacij otrok.",
      },
    ],
  },

  {
    title: "7. Spremembe te politike zasebnosti:",
    desc: [
      {
        title: "7.1",
        desc: "Periodično lahko posodobimo to politiko zasebnosti, da odraža spremembe v naših praksah ali iz drugih operativnih, pravnih ali regulatornih razlogov.",
      },
      {
        title: "7.2",
        desc: "V primeru pomembnih sprememb te politike zasebnosti bomo zagotovili opazno obvestilo.",
      },
    ],
  },
  {
    title: "8. Kontaktirajte nas:",
    desc: [
      {
        desc: "Če imate kakršna koli vprašanja, skrbi ali zahteve v zvezi s to politiko zasebnosti ali z uporabo vaših osebnih informacij, nas prosimo kontaktirajte na [contact email].",
      },
    ],
  },
];
