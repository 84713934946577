import { styled } from "styled-components";

export const LoaderWrapper = styled.div`
  margin-top: 20px;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const QRPositionInStickerContainerStyled = styled.div`
  & .image-wrapper {
    height: 500px;
    margin: 0 auto;
    display: flex;
    justify-content: center;

    & .img-container {
      position: relative;
      & img {
        max-width: 100%;
        height: 100%;
      }
      & .icons {
        position: absolute;
      }
    }
  }
`;

export const StickerInfoStyled = styled.div`
  & .info-row {
    display: flex;
    align-items: center;
    & .title {
      display: inline-block;
      width: 130px;
      font-weight: 600;
    }
    & .value {
      position: relative;
      &::before {
        position: absolute;
        left: 0px;
        content: ":";
      }
      padding-left: 8px;
      display: flex;
      align-items: center;
      font-size: 1.1em;
      & .cross {
        font-weight: 200;
        font-size: 1.2em;
        margin: 0px 5px;
      }
    }
  }
`;
