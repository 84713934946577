const categoryApi = {
  getAllCategories: (action, params): any => {
    return {
      url: "group/getAllCategory",
      method: "get",
      params: params,
      config: {
        store: {
          action: action,
          key: "categories.data",
        },
      },
    };
  },

  addCategory: (data: { name: string }): any => {
    return {
      url: "group/addCategory",
      method: "post",
      data,
    };
  },
  updateCategory: (categoryId, data: { name: string }): any => {
    return {
      url: `group/updateCategoryById/${categoryId}`,
      method: "patch",
      data,
    };
  },
  addSubCategory: (categoryId, data: string): any => {
    return {
      url: "group/addSubCategory",
      method: "post",
      data: {
        category: categoryId,
        name: [data],
      },
    };
  },
  updateSubCategory: (subCategoryId, data: string): any => {
    return {
      url: `group/updateSubCategoryById/${subCategoryId}`,
      method: "patch",
      data: {
        name: data,
      },
    };
  },
  getAllSubCategories: (categoryId, action, params): any => {
    return {
      url: `group/getAllSubCategoryByCategoryId/${categoryId}`,
      method: "get",
      params: params,
      config: {
        store: {
          action: action,
          key: "subCategories.data",
        },
      },
    };
  },
  deleteSubCategory: (subCategoryId): any => {
    return {
      url: `group/deleteSubCategoryById/${subCategoryId}`,
      method: "delete",
    };
  },
  deleteCategory: (categoryId): any => {
    return {
      url: `group/deleteCategoryById/${categoryId}`,
      method: "delete",
    };
  },
  unblockCategory: (categoryId): any => {
    return {
      url: `group/unBlockCategoryById/${categoryId}`,
      method: "post",
    };
  },
};

export default categoryApi;
