export default [
  {
    title: "1. Information som vi samlar in:",
    desc: [
      {
        title: "1.1",
        desc: "Personlig information: När du interagerar med vårt projekt för kameraefterlevnad kan vi samla in personlig information såsom ditt namn, e-postadress och kontaktinformation.",
      },
      {
        title: "1.2",
        desc: "Enhetsinformation: Vi kan samla in information om den enhet du använder för att komma åt våra tjänster, inklusive enhetstyp, operativsystem, webbläsartyp och IP-adress.",
      },
      {
        title: "1.3",
        desc: "Platsinformation: Med ditt uttryckliga samtycke kan vi samla in platsinformation från din enhet för att ge dig korrekt efterlevnadsinformation relaterad till staden och området du befinner dig i.",
      },
      {
        title: "1.4",
        desc: "Information om kameraavläsning: När du skannar en QR-kod på en stolpe med en kamera kan vi samla in data relaterad till kamerans plats, datum och tid för avläsningen samt den erhållna efterlevnadsinformationen.",
      },
    ],
  },
  {
    title: "2. Hur vi använder din information:",
    desc: [
      {
        title: "2.1",
        desc: "Vi använder den insamlade informationen för att ge dig korrekt efterlevnadsinformation relaterad till det stadsområde där du har skannat QR-koden.",
      },
      {
        title: "2.2",
        desc: "Vi kan använda din personliga information för att skicka dig uppdateringar, aviseringar och viktig information relaterad till projektet för kameraefterlevnad.",
      },
      {
        title: "2.3",
        desc: "Vi analyserar sammanslagna och anonymiserade data för statistiska ändamål, vilket kan hjälpa oss att förbättra våra tjänster.",
      },
    ],
  },

  {
    title: "3. Delning av information:",
    desc: [
      {
        title: "3.1",
        desc: "Vi delar inte din personliga information med tredje parter för marknadsföringsändamål utan ditt uttryckliga samtycke.",
      },
      {
        title: "3.2",
        desc: "Vi kan dela din information med våra tjänsteleverantörer som hjälper oss att leverera våra tjänster. Dessa tjänsteleverantörer är skyldiga att upprätthålla konfidentialiteten för din information.",
      },
      {
        title: "3.3",
        desc: "Vi kan lämna ut din information om det krävs enligt lag eller för att skydda våra rättigheter, integritet, säkerhet eller egendom.",
      },
    ],
  },

  {
    title: "4. Dataskydd:",
    desc: [
      {
        title: "4.1",
        desc: "Vi implementerar säkerhetsåtgärder för att skydda din personliga information mot obehörig åtkomst, ändring, avslöjande eller förstörelse.",
      },
      {
        title: "4.2",
        desc: "Trots våra ansträngningar är ingen online-överföring eller lagring helt säker. Därför kan vi inte garantera absolut säkerhet för din information.",
      },
    ],
  },

  {
    title: "5. Dina val:",
    desc: [
      {
        title: "5.1",
        desc: "Du kan välja att inte ta emot marknadsföringskommunikation från oss genom att följa avprenumerationsinstruktionerna som finns i kommunikationen.",
      },
      {
        title: "5.2",
        desc: "Du kan kontrollera insamlingen av platsinformation genom att justera inställningarna på din enhet.",
      },
    ],
  },

  {
    title: "6. Barns integritet:",
    desc: [
      {
        title: "6.1",
        desc: "Våra tjänster är inte avsedda för personer under [age]. Vi samlar medvetet inte in personlig information från barn.",
      },
    ],
  },

  {
    title: "7. Ändringar i denna integritetspolicy:",
    desc: [
      {
        title: "7.1",
        desc: "Vi kan uppdatera denna integritetspolicy från tid till annan för att återspegla förändringar i våra praxis eller av andra operationella, juridiska eller reglerande skäl.",
      },
      {
        title: "7.2",
        desc: "Vi kommer att ge tydlig information om eventuella väsentliga förändringar i denna integritetspolicy.",
      },
    ],
  },
  {
    title: "8. Kontakta oss:",
    desc: [
      {
        desc: "Om du har några frågor, bekymmer eller förfrågningar om denna integritetspolicy eller användningen av din personliga information, kontakta oss på [contact email].",
      },
    ],
  },
];
