import React from "react";
import NoUser from "../../../../assets/noImageUser.jpg";
import * as Styles from "./index.styles";

interface TopOperatorsTableRowCardProps {
  firstName: string;
  lastName: string;
  acceptedQr: string;
}

const TopOperatorsTableRowCard = ({
  firstName,
  lastName,
  acceptedQr,
}: TopOperatorsTableRowCardProps) => {
  return (
    <Styles.CardWrapper>
      <Styles.Logo src={NoUser} />
      <div>
        <div style={{ fontSize: "16px", fontWeight: "600" }}>
          {firstName} {lastName}
        </div>
        <div style={{ fontSize: "12px", fontWeight: "400" }}>
          Completed QR: {acceptedQr}
        </div>
      </div>
    </Styles.CardWrapper>
  );
};

export default TopOperatorsTableRowCard;
