import { BaseTypography } from "components/common/BaseTypography";
import { Link } from "react-router-dom";
import { css, styled } from "styled-components";
import { FONT_SIZE } from "styles/themes/constants";

export const CapitalText = styled.span`
  text-transform: capitalize;
`;
export const flexColumnJustifyAlignCenter = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const flexColumnAlignCenterJustifySpaceBetween = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;
export const flexColumn = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const flexRow = css`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const flexRowAlignJustifyCenter = css`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const flexRowAlignCenterJustifySpaceBetween = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
export const FlexAligncenter = styled.div`
  display: flex;
  align-items: center;
`;

export const flexAlignCenter = css`
  display: flex;
  align-items: center;
`;
export const FlexColumnAlignCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const ButtonFlexEnd = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
  & > *:first-child {
    margin-right: 1rem;
  }
`;
export const FlexEnd = styled.div`
  display: flex;

  justify-content: flex-end;
`;
export const FlexEndText = css`
  display: flex;
  /* justify-content: flex-end; */
  flex-direction: column;
  /* position: bottomCenter; */
`;
export const FlexEndLastRow = css`
  display: flex;
  justify-content: flex-end;

  /* flex-direction: column; */
  /* position: bottomCenter; */
`;

export const FilterWrapper = styled.div`
  margin: 0.6rem;
`;
export const UrlLink = styled.div`
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  color: #226aff;
  &:hover {
    text-decoration: underline;
  }
`;
export const GroupTextStyled = styled(BaseTypography.Paragraph)`
  max-width: 200px;
  cursor: pointer;

  &:hover {
    text-decoration: underline !important;
  }
`;
export const ItalicLink = styled(Link)`
  ${FlexEndLastRow}
  margin: 10px  16px 10px 0px;
  font-weight: 380;
  text-decoration: none;
  font-style: italic;
  font-size: 0.8rem;
  &:hover {
    color: var(--primary);
  }
`;

export const TableImageIcon = styled.img`
  height: 50px;
  width: 50px;
  object-fit: contain;
`;
