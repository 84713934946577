import { Typography } from "antd";
import React from "react";
import { SolutionContainer } from "./style";
import { cameraData } from "./cameraData";
import MainHeader from "layouts/Main/MainHeader/MainHeader";
import Footer from "../Footer";

type Props = {};

const CameraDetails = (props: Props) => {
  return (
    <>
      <MainHeader />

      <SolutionContainer>
        <Typography.Title level={4} className="title">
          {/* {t("new.unique-features")} */}
          Who Can Use the Camera Compliance System?
        </Typography.Title>

        <Typography style={{ marginBottom: "50px" }}>
          Our system is specifically designed for a diverse range of
          stakeholders who play a pivotal role in urban development, public
          safety, and data protection. Below are the key users who can
          significantly benefit from our GDPR-compliant surveillance solutions.
        </Typography>

        <div className="main">
          {cameraData?.map((section) => {
            const { title, description } = section;
            return (
              <div className="container" key={section.id}>
                <Typography.Title level={5} className="container-title">
                  {title}
                </Typography.Title>
                <ul className="description-list">
                  {description.map((desc, index) => (
                    <li key={index}>
                      <Typography className="text">{desc}</Typography>
                    </li>
                  ))}
                </ul>{" "}
              </div>
            );
          })}
        </div>
      </SolutionContainer>
      <Footer />
    </>
  );
};

export default CameraDetails;
