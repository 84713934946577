import {
  Avatar,
  Col,
  Divider,
  Dropdown,
  Menu,
  Row,
  Skeleton,
  Tabs,
} from "antd";
import { BaseCard } from "components/common/BaseCard";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { styled } from "styled-components";
import * as S from "./index.styles";
import OperatorStats from "./OperatorStats";
import ResetPassword from "./ResetPassword";
import useAPI from "hooks/useApi";
import operators from "shared/front/apis/operator.api";
import { useEffect, useState } from "react";
import { convertTimestamp } from "utils/utils";
import { BaseTypography } from "components/common/BaseTypography";
import BaseButton from "components/common/BaseButton";
import NoAvatar from "../../../assets/NOAVATAR.png";
import Stats from "./Stats";
import OperatorActivity from "./OperatorActivity";
import { toast } from "react-toastify";

// const menu = (
//   <Menu>
//     <Menu.Item key="action1" style={{ color: "rgb(123, 66, 245)" }}>
//       Edit information
//     </Menu.Item>

//     <Menu.Item key="action2" style={{ color: "red" }}>
//       Delete Account
//     </Menu.Item>
//   </Menu>
// );

const SingleOperator = () => {
  const { t } = useTranslation();
  const [data, setData]: any = useState("");
  const [operatorActivity, setOperatorActivity] = useState([]);
  const { operatorId }: any = useParams();

  const [
    makeRequest,
    { data: allOperatorData, loading: operatorsLoading, error: operatorsError },
  ] = useAPI();
  const [
    makeOperatorInfoRequest,
    {
      data: operatorInfoData,
      loading: operatorInfoDataLoading,
      error: operatorInfoDataError,
    },
  ] = useAPI();
  const getOperatorInfo = async (id: string) => {
    try {
      const data = await makeOperatorInfoRequest(operators.getOperatorInfo(id));
      if (data?.operatorDetails) {
        setData(data?.operatorDetails);
        setOperatorActivity(data?.activity);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    getOperatorInfo(operatorId);
  }, []);

  const operatorDetails = [
    {
      label: t("dashboard.name"),
      value: `${data?.firstName} ${data?.lastName}`,
      useLabel: true,
    },
    {
      label: t("dashboard.email"),
      value: data?.email,
      useLabel: true,
    },
    {
      label: t("dashboard.register_at"),
      value: convertTimestamp(data?.createdAt),
      useLabel: true,
    },
    {
      label: t("dashboard.blocked"),
      value: data?.isBlocked ? "Blocked" : "Active",
      useLabel: true,
    },
    {
      label: t("dashboard.logged_in"),
      value: data?.isFirstLogin ? "No" : "Yes",
      useLabel: true,
    },
    {
      label: t("dashboard.verified"),
      value: data?.isVerified ? "Yes" : "No",
      useLabel: true,
    },
  ];

  return operatorInfoDataLoading ? (
    <S.SkeletonWrapperStyled>
      <Skeleton.Avatar shape="square" size={300}></Skeleton.Avatar>
      <Skeleton></Skeleton>
    </S.SkeletonWrapperStyled>
  ) : (
    <>
      <BaseCard style={{ marginBottom: "30px" }}>
        <BaseTypography.Title level={2}>
          {" "}
          {t("dashboard.operator")}
        </BaseTypography.Title>
        <S.Wrapper>
          <S.ImgWrapper>
            <Avatar
              shape="square"
              src={NoAvatar}
              alt="Profile"
              style={{ width: "100%", height: "100%" }}
            />
          </S.ImgWrapper>
          <S.Title>{operatorDetails[0]?.value?.toUpperCase()}</S.Title>
          <S.Subtitle>{operatorDetails[1]?.value}</S.Subtitle>
          <Divider />
          <S.SubtitleWrapper>
            {operatorDetails.map(({ label, value, useLabel }) => {
              if (["Name", "Email"].includes(label)) return null;
              return (
                <S.Subtitle>
                  {useLabel && <div className="label">{label} </div>}: {value}
                </S.Subtitle>
              );
            })}
          </S.SubtitleWrapper>
        </S.Wrapper>
      </BaseCard>
      <Stats operatorDetails={data} />
      <OperatorActivity activity={operatorActivity} operatorId={operatorId} />
    </>
  );
};

export default SingleOperator;
