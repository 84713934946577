import React, { useState } from "react";
import { useResponsive } from "hooks/useResponsive";
import * as S from "./BloodScreeningTable.styles";

import { ColumnsType } from "antd/es/table";
import { BloodTestResult, flags, results } from "constants/operatorTestReuslts";
import NoUser from "../../../../assets/noImageUser.jpg";
import { ItalicLink } from "utils/styles";
import TopOperatorsTableRowCard from "../TopOperatorsTableRowCard";
import { useTranslation } from "react-i18next";

interface BloodScreeningTableProps {
  activeItem: BloodTestResult;
  isOperatorPage?: boolean;
  setActiveItem: (item: BloodTestResult) => void;
  topOperator: any;
}

export const BloodScreeningTable: React.FC<BloodScreeningTableProps> = ({
  activeItem,
  setActiveItem,
  topOperator,
}) => {
  const { isLargeMobile, isTablet, isDesktop } = useResponsive();
  const { t } = useTranslation();

  const dataSource = topOperator?.map((item: any) => {
    return {
      key: item._id,
      name: item?.firstName + " " + item.lastName,
      acceptedQR: item.acceptedQR,
    };
  });

  const columns: ColumnsType<BloodTestResult> = [
    {
      title: t("dashboard.operator"),
      dataIndex: "icon",
      width: 80,
      render: () => {
        return <img src={NoUser} alt="no-user" width={40} height={40} />;
      },
    },
    // {
    //   title: "Accuracy Rate",
    //   dataIndex: "test",
    //   render: (test: string, { key }) => (
    //     <S.Text $isActive={activeItem.key === key}>{test}</S.Text>
    //   ),
    // },
    {
      title: t("dashboard.name"),
      dataIndex: "name",
      width: 80,
      render: (text: string, { key }) => {
        return <S.Text $isActive={activeItem?.key === key}>{text}</S.Text>;
      },
    },
    {
      title: t("dashboard.completed-qr"),
      dataIndex: "acceptedQR",
      width: 80,
      align: "right" as "right",
      render: (result: number, { key }) => (
        <S.Text $isActive={activeItem?.key === key}>{result}</S.Text>
      ),
    },
    // {
    //   title: "medical-dashboard.bloodScreening.units",
    //   dataIndex: "units",
    //   render: (units, { key }) => (
    //     <S.Text $isActive={activeItem.key === key}>{units}</S.Text>
    //   ),
    // },
    // {
    //   title: "medical-dashboard.bloodScreening.flag",
    //   dataIndex: "flag",
    //   render: (flag, { key }) => (
    //     <S.Flag $isNorm={flag === 0} $isActive={activeItem.key === key}>
    //       {flags[flag]}
    //     </S.Flag>
    //   ),
    // },
  ];

  return (
    <S.Container>
      {isLargeMobile ? (
        <S.Table
          size={"small"}
          pagination={false}
          columns={columns}
          dataSource={dataSource}
          scroll={{ y: isDesktop ? 300 : isTablet ? 620 : 400 }}
          onRow={(record: any) => {
            return {
              onClick: () => setActiveItem(record),
            };
          }}
        />
      ) : (
        <S.CardsWrapper>
          {topOperator?.map((operator) => (
            <TopOperatorsTableRowCard
              key={operator?.id}
              firstName={operator.firstName}
              lastName={operator.lastName}
              acceptedQr={operator.acceptedQR}
            ></TopOperatorsTableRowCard>
          ))}
        </S.CardsWrapper>
      )}

      <ItalicLink to={`operator`}>{t("dashboard.view-more")}</ItalicLink>
    </S.Container>
  );
};
