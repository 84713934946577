import React from "react";
import * as Styles from "./index.styles";
import { convertTimestamp, fullConvertTimestamp } from "utils/utils";
import BaseToolTip from "components/common/BaseTooltip";
import { BaseTag } from "components/common/BaseTag";

interface AdminsTableRowCardProps {
  firstName: string;
  lastName: string;
  createdDate: string;
  email: string;
  isFirstLogin: boolean;
  initialPassword: string;
}
const AdminsTableRowCard = ({
  firstName,
  lastName,
  createdDate,
  email,
  isFirstLogin,
  initialPassword,
}: AdminsTableRowCardProps) => {
  return (
    <Styles.CardWrapper>
      <div style={{ fontSize: "16px", fontWeight: "600" }}>
        {firstName} {lastName}
      </div>
      <div style={{ fontSize: "12px", fontWeight: "400" }}>{email}</div>
      <div style={{ width: "fit-content" }}>
        <BaseToolTip title={fullConvertTimestamp(createdDate)}>
          <div style={{ fontSize: "10px", fontWeight: "400" }}>
            Created on {convertTimestamp(createdDate)}
          </div>
        </BaseToolTip>
      </div>
      {isFirstLogin ? (
        <div style={{ fontSize: "12px", fontWeight: "400" }}>
          Initial Password: {initialPassword}
        </div>
      ) : (
        <div style={{ width: "fit-content", marginTop: "4px" }}>
          <BaseTag color="success">Logged In</BaseTag>
        </div>
      )}
    </Styles.CardWrapper>
  );
};

export default AdminsTableRowCard;
