import { Col, Divider, Row, Tabs } from "antd";
import AlterActionWrapper from "components/ActionWrapper/AlterActionWrapper";
import Editor from "components/Editor/Editor";
import BaseButton from "components/common/BaseButton";
import { BaseCard } from "components/common/BaseCard";
import BaseModal from "components/common/BaseModal";
import { BaseTypography } from "components/common/BaseTypography";
import HeaderWrapper from "components/headerwrapper";
import useAPI from "hooks/useApi";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import publicPage from "shared/front/apis/public-page.api";
import languageMapWithCountries from "utils/languagemaps";
import Template from "./Templates/Template1";
import LeftPart from "./CustomizationModal/LeftPart";
import request from "utils/request";
import toastr from "utils/toastHandler";
import { styled } from "styled-components";
import { FONT_SIZE, FONT_WEIGHT } from "styles/themes/constants";
import BaseToolTip from "components/common/BaseTooltip";
import { QR_DASHBOARD_MAIN_PATH } from "constants/routes/route";
import { GoBack } from "components/GoBack";
import BannerImage from "containers/Dashboard/BannerImage";
import CustomizationTemplates from "./CustomizationModal";
import BaseLoading from "components/common/BaseSpin";
//fake data
const data = {
  URL: "https://rentisity-stage.s3.eu-west-1.amazonaws.com/328b79fa-69ab-4808-832d-1581ae8790e7.svg",
  address: "Kathmandu, Bagmati Province",
  admin: "64dde3b971a618f402078c15",
  batch: "AB-51",
  camera: [
    {
      location: {
        type: "Point",
        coordinates: [85.34917447275537, 27.671340942382812],
      },
      images: [],
      // ... other properties inside the "camera" array
    },
  ],
  city: {
    _id: "64dde39571a618f402078c09",
    superAdmin: "64d5fb525bcd4ead1ec63461",
    // ... other properties inside the "city" object
  },
  cityName: "Kathmandu",
  count: 0,
  createdAt: "2023-08-17T12:58:55.829Z",
  frontendURL: "https://cameracompliance.com/qr/64de198fb4a2fcc4c85009b4",
  images: [
    {
      _id: "64de19eeb4a2fcc4c8500a64",
      QRCode: "64de198fb4a2fcc4c85009b4",
      // ... other properties inside the "images" array
    },
  ],
  footerLogo: [],
  isArchive: false,
  isAssigned: false,
  isCompleted: false,
  isDownloaded: true,
  isVerified: false,
  latitude: 27.671340942382812,
  location: {
    type: "Point",
    coordinates: [85.34917447275537, 27.671340942382812],
  },
  longitude: 85.34917447275537,
  operator: null,
  status: "Active",
  sticker: {
    _id: "64dde55371a618f402078d14",
    admin: "64dde3b971a618f402078c15",
    city: "64dde39571a618f402078c09",
    // ... other properties inside the "sticker" object
  },
  stickerURL:
    "https://rentisity-stage.s3.eu-west-1.amazonaws.com/c1001748-9dd9-4854-ae1c-e95a47548014.svg",
  updatedAt: "2023-08-17T13:00:30.464Z",
  __v: 0,
  _id: "64de198fb4a2fcc4c85009b4",
};

const CustomizationPage = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [colorState, setColorState] = useState({
    backgroundColor: "rgb(22, 119, 255)",
    footerColor: "rgb(22, 119, 255)",
    fontColor: "rgb(0,0,0)",
  });
  let [searchParams, setSearchParams] = useSearchParams();
  const [selectedDetails, setSelectedDetails] = useState<{
    desc?: string;
    lang?: string;
    title?: string;
  }>({});
  const [selectedLang, setSelectedLang] = useState("");
  const [availableLanguages, setAvailableLanguages] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  //customization
  const [makeGetRequest, { loading: publicPageLoading, data: publicPageInfo }] =
    useAPI();
  const [
    makeCustomizeRequest,
    { loading: customizeLoading, data: customizeData },
  ] = useAPI();

  const getPublicPageByID = async (id) => {
    try {
      await makeGetRequest(publicPage.getPublicPageById(id));
    } catch (error) {
      toastr.error(error);
    }
  };

  useEffect(() => {
    getPublicPageByID(id);
  }, []);

  useEffect(() => {
    if (searchParams) {
      const queryModalOpen = searchParams.get("openModal");
      if (queryModalOpen) {
        setIsModalVisible(true);
        searchParams.delete("openModal");
        setSearchParams(searchParams);
      }
    }
  }, [searchParams]);

  const cityDetailsArray = publicPageInfo?.details || [];
  const footerLogo = publicPageInfo?.footerLogo || [];

  const handleDetailsSelection = (lang) => {
    if (Array.isArray(cityDetailsArray) && cityDetailsArray.length > 0) {
      const selected = cityDetailsArray.find((x) => x.lang === lang);
      setSelectedDetails(selected);
    }
  };

  useEffect(() => {
    const availLang = publicPageInfo?.details?.map((val) => {
      return {
        langCode: val.lang,
        language: languageMapWithCountries[val.lang]?.languageFullName,
        flag: (
          <div style={{ fontFamily: "Twemoji Country Flags" }}>
            {languageMapWithCountries[val.lang]?.flag}
          </div>
        ),
      };
    });
    if (availLang?.length > 0) {
      handleDetailsSelection(availLang[0]?.langCode);
      setSelectedLang(availLang[0]?.langCode);
      setAvailableLanguages(availLang);
    }
  }, [publicPageInfo]);

  const onTabChange = (val) => {
    handleDetailsSelection(val);
    setSelectedLang(val);
  };
  const closeModal = () => {
    setIsModalVisible(false);
  };
  if (publicPageLoading) {
    return <BaseLoading />;
  }

  return (
    <>
      <GoBack />
      <HeaderWrapper title={t("remain.customize-public-page")} />
      <AlterActionWrapper>
        <BaseButton
          onClick={() => {
            // navigate(`${QR_DASHBOARD_MAIN_PATH}/public-page/template/${id}`);
            setIsModalVisible(true);
          }}
        >
          {t("remain.customization")}
        </BaseButton>
      </AlterActionWrapper>
      <BaseModal
        title={t("remain.customize")}
        open={isModalVisible}
        onCancel={closeModal}
        footer={[]}
        width={"100%"}
        centered
      >
        <CustomizationTemplates
          customize={publicPageInfo?.customization}
          closeModal={closeModal}
        />
      </BaseModal>
      <BaseCard>
        <BaseTypography.Title level={4}>
          {publicPageInfo?.title}
        </BaseTypography.Title>
        {publicPageInfo?.banner && (
          <BannerImage bannerImg={publicPageInfo?.banner} />
        )}
        <Tabs
          items={availableLanguages.map((val) => ({
            key: val?.langCode,
            label: (
              <div style={{ display: "flex", alignItems: "center" }}>
                {val?.flag} &ensp;
                {val?.language}
              </div>
            ),
          }))}
          onChange={onTabChange}
          activeKey={selectedLang}
        />
        {!!selectedDetails?.lang && (
          <div style={{ marginBottom: "50px" }}>
            <BaseTypography.Title level={3}>
              {selectedDetails?.title}
            </BaseTypography.Title>
            <Divider />
            <Editor isReadOnly editorState={selectedDetails?.desc} />
          </div>
        )}

        <div style={{ marginTop: "16px" }}>
          <AssociateLogoTextStyle>
            {t("dashboard.associate-logo")}
          </AssociateLogoTextStyle>
          <LogoGrid>
            <Wrapper>
              {footerLogo?.map((item) => (
                <div style={{ position: "relative" }}>
                  <BaseToolTip
                    key={item?._id}
                    title={
                      <a
                        href={item.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item?.link}
                      </a>
                    }
                  >
                    <LogoWrapper>
                      {item.URL ? (
                        <LogoImage src={item.URL} alt={`Logo ${item._id}`} />
                      ) : (
                        <p> {t("dashboard.no-data")}</p>
                      )}
                    </LogoWrapper>
                  </BaseToolTip>
                </div>
              ))}
            </Wrapper>
          </LogoGrid>
        </div>
      </BaseCard>
    </>
  );
};

export default CustomizationPage;

const LogoGrid = styled.div`
  display: flex;
  max-width: 100%;
  flex-direction: column;
  margin: 0 auto;
  margin-bottom: 20px;
`;

const LogoImage = styled.img`
  width: 70px;
  height: 70px;
`;

const Wrapper = styled.div`
  display: flex;
  gap: 28px;
  flex-wrap: wrap;
  margin: 20px;
  align-items: center;
`;

const LogoWrapper = styled.div``;

const AssociateLogoTextStyle = styled(BaseTypography.Text)`
  font-size: ${FONT_SIZE.lg};
  font-weight: ${FONT_WEIGHT.semibold};
  margin: 20px;
`;
