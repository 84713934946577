import React, { useEffect, useState } from "react";
import { TopCitiesTable } from "../TopCitiesTable/TopCitiesTable";
import { useTranslation } from "react-i18next";
import * as S from "./TopCityCard.styles";
import { BloodTestResult } from "constants/bloodTestResults";
import { BaseTypography } from "components/common/BaseTypography";

export const TopCityCard = ({ dashboardData }: any) => {
  const { t } = useTranslation();
  const topCity = dashboardData?.city;
  const [activeItem, setActiveItem] = useState<BloodTestResult>();

  useEffect(() => {
    if (Array.isArray(dashboardData?.city)) {
      setActiveItem(dashboardData?.city[0]);
    }
  }, [dashboardData]);

  if (!activeItem) {
    return null;
  }

  return (
    <S.BloodScreeningCard
      title={
        <S.TitleWrapper>
          <BaseTypography.Text>{t("dashboard.top-cities")}</BaseTypography.Text>
          {!!activeItem?.test && (
            <S.ActiveItem>{activeItem?.test}</S.ActiveItem>
          )}
        </S.TitleWrapper>
      }
      padding={0}
    >
      <TopCitiesTable
        activeItem={activeItem}
        setActiveItem={setActiveItem}
        topCity={topCity}
      />
    </S.BloodScreeningCard>
  );
};
