import React, { useState, useEffect } from "react";
import HeaderWrapper from "components/headerwrapper";
import { useSelector } from "react-redux";
import useAPI from "hooks/useApi";
import country from "shared/front/apis/country.api";
import { COUNTRIES } from "./Flag/countries";
import { BaseTable } from "components/common/BaseTable";
import BaseLoading from "components/common/BaseSpin";
import BaseButton from "components/common/BaseButton";
import SAdminAddCountry from "./AddCountry/SAdminAddCountry";
import { useNavigate } from "react-router-dom";
import { convertTimestamp, fullConvertTimestamp } from "utils/utils";
import { CapitalText } from "utils/styles";
import { BaseTag } from "components/common/BaseTag";
import EyeOutlined from "@ant-design/icons/EyeOutlined";
import BaseToolTip from "components/common/BaseTooltip";
import { BasePagination } from "components/common/BasePagination";
import ActionWrapper from "components/ActionWrapper/ActionWrapper";
import BaseSearch from "components/common/BaseSearch";
import ActionButton from "components/ActionWrapper/ActionButton";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import type { Dispatch } from "redux";
import store from "store";

const Country = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch: Dispatch = useDispatch();
  const countries = useSelector((state: any) => state.countries.data);
  const countriesLoading = useSelector((state: any) => state.countries.loader);
  const pagination = useSelector((state: any) => state.countries.pagination);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [getRequest, { loading: getAllCountryLoading }] = useAPI();

  const addCountry = () => {
    setIsModalOpen(true);
  };
  const handlePagination = (page, limit) => {
    const { search } = pagination;
    dispatch(
      store.Actions["update"]("countries.pagination", {
        page,
        limit,
      })
    );
    getAllCountry({ page, limit, ...(search && { search }) });
  };
  const handleSearch = (search) => {
    dispatch(
      store.Actions["update"]("countries.pagination", { search, page: 0 })
    );
    getAllCountry({
      search,
      page: 0,
      limit: pagination.limit,
    });
  };

  const getAllCountry = async (params) => {
    await getRequest(country.getCountry("set", params));
  };

  useEffect(() => {
    if (countries.length == 0) {
      const { page, limit } = pagination;
      getAllCountry({
        page,
        limit,
      });
    }
  }, []);

  const columns = [
    {
      title: t("dashboard.s.n."),
      dataIndex: "rowNo",
      key: "rowNo",
    },

    {
      title: t("dashboard.country-name"),
      dataIndex: "title",
      key: "title",
      render: (text: any) => {
        return <CapitalText>{text}</CapitalText>;
      },
    },

    {
      title: t("dashboard.created-date"),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: any, record: any, index: any) => {
        return (
          <BaseToolTip title={fullConvertTimestamp(text)}>
            <span>{convertTimestamp(text)}</span>
          </BaseToolTip>
        );
      },
    },

    // {
    //   title: "Action",
    //   dataIndex: "action",
    //   key: "action",
    //   render: (text: any, record: any) => {
    //     return (
    //       <>
    //         <BaseButton
    //           className="view-city"
    //           onClick={() => {
    //             navigate(`${record?.id}`);
    //           }}
    //         >
    //           <EyeOutlined />
    //         </BaseButton>
    //       </>
    //     );
    //   },
    // },
  ];

  const dataSource = countries?.map((item: any, index: number) => ({
    rowNo: pagination.limit * pagination.page + index + 1,
    key: item._id,
    id: item._id,
    title: item.title,
    email: item.email,
    avatar: item.avatar,
    createdAt: item.createdAt,
    phone: item.phone,
    gender: item.gender,
    password: item.initialPassword,
  }));

  if (countriesLoading && countries.length === 0)
    <BaseLoading loadingsize={50}></BaseLoading>;
  return (
    <>
      <>
        <HeaderWrapper title={t("dashboard.country")} />
        <ActionWrapper>
          <BaseSearch
            handleSearch={handleSearch}
            searchValue={pagination.search}
          />
          <ActionButton
            actionTitle={t("dashboard.add-country")}
            handleAction={addCountry}
          />
        </ActionWrapper>
        <SAdminAddCountry
          // loader={submitLoader}
          onRefresh={getAllCountry}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />

        <BaseTable
          onRow={(record, rowIndex) => {
            return {
              onClick: () => {
                navigate(`${record?.id}`);
              },
            };
          }}
          dataSource={dataSource}
          columns={columns}
          pagination={false}
        />
        <BasePagination
          pagination={pagination}
          handlePagination={handlePagination}
        />
      </>
    </>
  );
};

export default Country;
