import { styled } from "styled-components";

export const GroupsStyled = styled.div``;
export const QRStickerImageWrapperStyled = styled.div`
  max-height: 120px;
  max-width: 200px;
  display: flex;
  justify-content: center;
  border: 1px solid #dbdbdb;

  overflow: hidden;
  cursor: pointer;

  &.selected {
    border: 5px solid var(--primary);
    box-shadow: 0px 0px 10px 3px #d0d0d0;
    border-radius: 6px;
  }
  & img {
    max-height: 120px;
    object-fit: contain;
  }
`;

export const QRStickerImageContainerStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
`;

export const PublicPageViewStyled = styled.div`
  margin-bottom: 30px;
  background: #fcfcfc;
  border: 1px solid #ececec;
  padding: 20px;
  border-radius: 10px;

  & .title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
    padding-bottom: 5px;
    // border-bottom: 1px solid #dbdbdb;
  }

  & .page-row {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    & .title {
      display: inline-block;
      width: 80px;
      font-weight: 600;
      color: var(--gray);
    }
    & .value {
    }
  }
`;

export const CategoryStyled = styled.div`
  background: #fcfcfc;
  border: 1px solid #ececec;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 30px;
  & .title-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    padding-bottom: 5px;
    // border-bottom: 1px solid #dbdbdb;
  }

  & .category-value {
    & .cat-name {
      margin-bottom: 10px;
    }

    & .cat-row {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      & .title {
        display: inline-block;
        width: 120px;
        font-weight: 600;
        color: var(--gray);
      }
      & .value {
      }
    }
  }
`;

export const CameraStyled = styled.div`
  background: #fcfcfc;
  border: 1px solid #ececec;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 30px;
  & .title-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    padding-bottom: 5px;
    // border-bottom: 1px solid #dbdbdb;
  }

  & .camera-row {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    & .title {
      display: inline-block;
      width: 100px;
      font-weight: 600;
      color: var(--gray);
    }
    & .value {
    }
  }
`;
export const CameraContainerStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 18px;
`;

export const SingleCameraInfoStyled = styled.div<{ isBlocked: boolean }>`
  border: ${({ isBlocked }) =>
    isBlocked ? "2px solid var(--red)" : "2px solid #808080"};
  padding: 15px;
  border-radius: 6px;
  background: ${({ isBlocked }) =>
    isBlocked ? "rgba(200,10,10,0.1)" : "var(--white)"};
  & .view-camera-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 10px;
  }
`;

export const SelectedCameraContainerStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 15px;
`;

export const SelectedCameraStyled = styled.div`
  border: 2px solid #808080;
  border-radius: 6px;
  padding: 10px 10px;
  margin: 5px 0px;

  & .camera-row {
    display: flex;
    align-items: center;
    margin-bottom: 0px;
    & .title {
      display: inline-block;
      width: 100px;
      font-weight: 600;
      color: var(--gray);
      font-size: 12px;
    }
    & .value {
      font-size: 12px;
    }
  }
`;

export const OptionLabelStyled = styled.div`
  padding: 5px;
  border-bottom: 1px solid #ececec;
  & div:first-child {
    font-size: 16px;
  }
  & div:nth-child(2) {
    font-size: 12px;
  }

  & .selected-text {
    display: none;
    font-size: 10px;
    vertical-align: middle;
  }

  &.selected {
    font-weight: 700;
    background: #a5d6a7aa;
    padding: 5px 5px;
    border-radius: 6px;
    color: var(--primary);
    & .selected-text {
      display: inline;
    }
  }
`;

export const QRStyled = styled.div`
  background: #fcfcfc;
  border: 1px solid #ececec;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 30px;
  & .title-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    padding-bottom: 5px;
    // border-bottom: 1px solid #dbdbdb;
  }

  & .qr-sticker-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, 120px);
    margin-bottom: 15px;
    gap: 10px;
    & .qr-wrapper {
      display: flex;
      justify-content: center;
      border: 1px solid #dbdbdb;
      border-radius: 10px;
      overflow: hidden;
      width: 100%;
      height: 80px;
      cursor: pointer;
      & img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
`;

export const ViewGroupsStyled = styled.div``;
