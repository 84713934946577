import { Typography } from "antd";
import { PopupNotifications } from "containers/Notifications";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import CameraCompliance from "../../../assets/logo/CameraCompliance-cc.png";
import CameraComplianceIcon from "../../../assets/logo/CameraComplianceIcon.png";

//@ts-ignore
import { ReactComponent as QR_LOGO_SVG } from "../../../assets/logo/qrLogo.svg";
import ProfileHeader from "../../ProfileHeader";
import * as S from "./index.style";
import { useTranslation } from "react-i18next";
import { useRoot } from "RootProvider";

import styled from "styled-components";
const Links = styled.div`
  display: flex;
  gap: 16px;
  align-self: flex-end;

  span {
    margin: 0;
    padding: 0;
    font-size: 16px;
    cursor: pointer;

    border-bottom: 2px solid transparent;
    &:hover {
      border-bottom: 2px solid black;
    }

    &.active {
      color: #157b8b;
      border-bottom: 2px solid #157b8b;
      &:hover {
        border-bottom: 2px solid #157b8b;
      }
    }
  }
`;

const RightContent = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;

const MainHeader = ({
  onToggleSideBar,
  onHomePress = () => {},
  onNewsPress = () => {},
  activeNav = "",
}: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { auth } = useRoot();

  const { pathname } = useLocation();
  console.log(pathname);
  return (
    <S.HeaderWrapper>
      <S.LogoWrapperStyled>
        {!!onToggleSideBar && (
          <span className="hamburger-icon" onClick={onToggleSideBar}>
            <GiHamburgerMenu color="var(--primary)" className="icon" />
          </span>
        )}
        <NavLink to="/">
          <img
            className="brand-logo"
            src={CameraCompliance}
            alt="Camera compliance logo"
            style={{ height: "30px" }}
          />
        </NavLink>
      </S.LogoWrapperStyled>
      <RightContent>
        {(pathname === "/" ||
          pathname === "/contact" ||
          pathname.includes("/news/")) && (
          <Links>
            <Typography.Text
              onClick={onHomePress}
              className={activeNav === "Home" ? "active" : ""}
            >
              Home
            </Typography.Text>
            {pathname === "/" && (
              <Typography.Text
                onClick={onNewsPress}
                className={activeNav === "News" ? "active" : ""}
              >
                News
              </Typography.Text>
            )}
            <Link to="/contact">
              <Typography.Text
                className={pathname === "/contact" ? "active" : ""}
              >
                Contact
              </Typography.Text>
            </Link>
          </Links>
        )}

        <ProfileHeader />
      </RightContent>
    </S.HeaderWrapper>
  );
};

export default MainHeader;
