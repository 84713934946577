import React from "react";
import { styled } from "styled-components";
import { flexRowAlignCenterJustifySpaceBetween } from "utils/styles";

const ActionWrapper = ({ children }: any) => {
  return <ActionWrapperStyled>{children}</ActionWrapperStyled>;
};

export default ActionWrapper;

const ActionWrapperStyled = styled.div`
  ${flexRowAlignCenterJustifySpaceBetween}
  margin: 12px 0px;
`;
