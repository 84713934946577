import * as StylesShared from "../index.styles";
import Feature from "../Feature";
import StickerImage from "../images/sticker.png";
import QRImage from "../images/qr.png";
import GeneratedQRImage from "../images/generated-qr.png";
// @ts-ignore
import Fade from "react-reveal/Fade";
//@ts-ignore
import { useTranslation } from "react-i18next";
import { SectionTwo } from "./index.styles";

const CityAdminFeatures = () => {
  const { t } = useTranslation();
  return (
    <StylesShared.Section>
      <StylesShared.Container>
        <Feature
          title={t("feature.feature-dynamic-qr-title")}
          description={t("feature.feature-dynamic-qr-description")}
          image={QRImage}
        />

        <Feature
          title={t("feature.feature-dynamic-sticker-title")}
          description={t("feature.feature-dynamic-sticker-description")}
          image={StickerImage}
          direction="row-reverse"
        />

        <Feature
          title={t("feature.feature-dynamic-exportqr-title")}
          description={t("feature.feature-dynamic-exportqr-description")}
          image={GeneratedQRImage}
        />
      </StylesShared.Container>

      {/* <div>
        <Feature
          title={t("feature.feature-dynamic-qr-title")}
          description={t("feature.feature-dynamic-qr-description")}
          image={QRImage}
        />
      </div>
      <div>
        <Feature
          title={t("feature.feature-dynamic-sticker-title")}
          description={t("feature.feature-dynamic-sticker-description")}
          image={StickerImage}
        />
      </div> */}
      {/* <div>
        <Feature
          title={t("feature.feature-dynamic-exportqr-title")}
          description={t("feature.feature-dynamic-exportqr-description")}
          image={GeneratedQRImage}
        />
      </div> */}
    </StylesShared.Section>
  );
};

export default CityAdminFeatures;
