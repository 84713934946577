import React, { useState, useEffect } from "react";
import HeaderWrapper from "components/headerwrapper";
import AddOperatorModal from "./AddOperatorModal";
import useAPI from "hooks/useApi";
import operators from "shared/front/apis/operator.api";
import { BaseTable } from "components/common/BaseTable";
import BaseLoading from "components/common/BaseSpin";
import BaseButton from "components/common/BaseButton";
import AssignOperatorModal from "./AssignOperatorModal";
import { useNavigate, useSearchParams } from "react-router-dom";
import { convertTimestamp, fullConvertTimestamp } from "utils/utils";
import { CapitalText, FlexColumnAlignCenter } from "utils/styles";
import { BaseTag } from "components/common/BaseTag";
import EyeOutlined from "@ant-design/icons/EyeOutlined";
import BaseToolTip from "components/common/BaseTooltip";
import { BasePagination } from "components/common/BasePagination";
import ActionWrapper from "components/ActionWrapper/ActionWrapper";
import BaseSearch from "components/common/BaseSearch";
import ActionButton from "components/ActionWrapper/ActionButton";
import { useSelector, useDispatch } from "react-redux";
import type { Dispatch } from "redux";
import store from "store";
import { useResponsive } from "hooks/useResponsive";

import OperatorsTableRowCard from "./OperatorsTableRowCard";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import ConfirmationMessagePopup from "components/ConfirmationMessagePopup/ConfirmationMessagePopup";
import { BaseTypography } from "components/common/BaseTypography";
import { Image } from "antd";
import { TbLock, TbLockOpen } from "react-icons/tb";
import request from "utils/request";
import BaseTabs from "components/common/BaseTabs";

//@ts-ignore
import { useTranslation } from "react-i18next";

import { toast } from "react-toastify";
import EditOperatorModal from "./EditOperatorModal";

const Operator = () => {
  const { isLargeMobile, isTablet } = useResponsive();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch: Dispatch = useDispatch();
  let [searchParams, setSearchParams] = useSearchParams();
  let operatorData = useSelector((state: any) => state.operator.data);
  const pagination = useSelector((state: any) => state.operator.pagination);
  const [isEditModal, setIsEditModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [selectedOperator, setSelectedOperator] = useState<{
    _id?: string;
    isFirstLogin?: boolean;
    isBlocked?: boolean;
    email?: string;
    fullName?: string;
  }>({});
  const [isLoading, setIsLoading] = useState(false);

  const [
    makeOperatorInfoRequest,
    {
      data: operatorInfoData,
      loading: operatorInfoDataLoading,
      error: operatorInfoDataError,
    },
  ] = useAPI();
  const addOperator = () => {
    setIsModalOpen(true);
  };

  const [getRequest, { loading: loader }] = useAPI();

  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (searchParams) {
      const queryOperatorModal = searchParams.get("operatorModal");
      if (queryOperatorModal) {
        addOperator();
        searchParams.delete("operatorModal");
        setSearchParams(searchParams);
      }
    }
  }, [searchParams]);

  const items = [
    {
      key: "All",
      label: t("dashboard.all"),
      archive: false,
    },
    {
      key: "New",
      label: t("dashboard.new"),
      archive: false,
    },
    {
      key: "Active",
      label: t("dashboard.active"),
      archive: false,
    },
    {
      key: "Blocked",
      label: t("dashboard.blocked"),
      archive: true,
    },
  ];

  const handleTabs = (status) => {
    const { search } = pagination;

    let blocked = false;
    let notLoggedIn = false;
    if (status === "Blocked") {
      blocked = true;
    }
    if (status === "New") {
      notLoggedIn = true;
    }

    dispatch(
      store.Actions["update"]("operator.pagination", {
        page: 0,
        blocked,
        notLoggedIn,
        limit: 10,
        status,
      })
    );

    const paramsToPass = {
      page: 0,
      limit: pagination?.limit,
      blocked,
      notLoggedIn,
      ...(search && { search }),
    };
    if (status === "All") {
      delete paramsToPass?.blocked;
      delete paramsToPass?.notLoggedIn;
    }

    getAllOperator({
      ...paramsToPass,
    });
  };
  const handlePagination = (page, limit) => {
    const { search, blocked, notLoggedIn, status } = pagination;
    dispatch(
      store.Actions["update"]("operator.pagination", {
        page,
        limit,
      })
    );
    const paramsToPass = {
      page,
      limit,
      blocked,
      notLoggedIn,
      ...(search && { search }),
    };
    if (status === "All") {
      delete paramsToPass?.blocked;
      delete paramsToPass?.notLoggedIn;
    }
    getAllOperator({
      ...paramsToPass,
    });
  };

  const refetchOperator = () => {
    let { search, blocked, notLoggedIn, status, page, limit } = pagination;
    if (operatorData?.length > 1) {
      const paramsToPass = {
        page,
        limit,
        blocked,
        notLoggedIn,
        ...(search && { search }),
      };
      if (status === "All") {
        delete paramsToPass?.blocked;
        delete paramsToPass?.notLoggedIn;
      }
      getAllOperator(paramsToPass);
    } else {
      if (page > 0) {
        handlePagination(page - 1, limit);
      }
    }
  };

  const handleSearch = (search) => {
    dispatch(
      store.Actions["update"]("operator.pagination", { search, page: 0 })
    );
    const { limit, blocked, notLoggedIn, status } = pagination;

    const paramsToPass = {
      search,
      page: 0,
      limit,
      blocked,
      notLoggedIn,
    };
    if (status === "All") {
      delete paramsToPass?.blocked;
      delete paramsToPass?.notLoggedIn;
    }
    getAllOperator({
      ...paramsToPass,
    });
  };

  const getAllOperator = (params) => {
    const { limit, blocked, notLoggedIn, status, search } = params;

    const paramsToPass = {
      search,
      page: 0,
      limit,
      blocked,
      notLoggedIn,
    };
    if (status === "All") {
      delete paramsToPass?.blocked;
      delete paramsToPass?.notLoggedIn;
    }
    getRequest(operators.getAllOperator("set", params));
  };

  useEffect(() => {
    // if (operatorData.length === 0) {
    const { limit } = pagination;

    getAllOperator({
      page: 0,
      limit,
    });
    dispatch(
      store.Actions["update"]("operator.pagination", {
        ...pagination,
        page: 0,
        status: "All",
      })
    );
    // }
  }, []);

  const onSelectToDelete = (operator: string, operators: any[]) => {
    if (Array.isArray(operators)) {
      setSelectedOperator(operators.find((x) => x._id === operator));
      setIsDeleteModalVisible(true);
    }
  };
  const closeDeleteModal = () => {
    setIsDeleteModalVisible(false);
    setSelectedOperator({});
  };

  const updateBlockAndUnblockStatus = (actionType, id) => {
    dispatch(store.Actions.remove("operator", id));
    refetchOperator();
    // if (
    //   ["blocked", "unblocked"].includes(actionType) &&
    //   pagination?.status === "All"
    // ) {
    //   const copy = JSON.parse(JSON.stringify(operatorData));
    //   const updatedData = copy.filter((x) => {
    //     if (x._id === id) {
    //       const update = {
    //         ...x,
    //         isBlocked: x.isBlocked,
    //       };

    //       return {
    //         ...update,
    //       };
    //     }
    //     return x;
    //   });

    //   dispatch(store.Actions["set"]("operator.data", [...updatedData]));
    // } else {
    //   const updatedData = operatorData.filter((x) => x._id !== id);
    //   dispatch(store.Actions["set"]("operator.data", updatedData));
    // }
  };

  const handleDeleteOperator = async (operatorId: string) => {
    try {
      setIsLoading(true);
      const response = await request(operators.blockOperator(operatorId));
      if (response) {
        const actionType = selectedOperator?.isFirstLogin
          ? t("remain.deleted")
          : t("remain.blocked");
        updateBlockAndUnblockStatus(actionType, operatorId);
        const toastMessage = `${t("remain.operator")}  ${actionType} ${t(
          "remain.successfully"
        )}`;
        toast.success(toastMessage);
        closeDeleteModal();
      }
    } catch (error) {
      console.log("Delete Operator:", error);
      if (typeof error === "string") {
        toast.success(error);
      }
    } finally {
      setIsLoading(false);
    }
  };
  const handleUnblockOperator = async (operatorId: string) => {
    try {
      setIsLoading(true);
      const response = await request(operators.unBlockOperator(operatorId));
      if (response) {
        updateBlockAndUnblockStatus("unblocked", operatorId);
        toast.success(t("remain.toast-unblock-operator"));
        closeDeleteModal();
      }
    } catch (error) {
      console.log("Delete Operator:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    {
      title: t("dashboard.s.n."),
      dataIndex: "rowNo",
      key: "rowNo",
    },
    {
      title: t("dashboard.full-name"),
      dataIndex: "fullName",
      key: "fullName",
      render: (text: any) => {
        return <CapitalText>{text}</CapitalText>;
      },
    },
    {
      title: t("dashboard.email"),
      dataIndex: "email",
      key: "email",
    },
    {
      title: t("dashboard.password"),
      dataIndex: "password",
      key: "password",
      render: (text: string) => {
        return <span>{text || t("dashboard.not-available")}</span>;
      },
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: t("dashboard.created-date"),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: any, record: any, index: any) => {
        return (
          <BaseToolTip title={fullConvertTimestamp(text)}>
            <span>{convertTimestamp(text)}</span>
          </BaseToolTip>
        );
      },
    },

    {
      title: t("dashboard.action"),
      dataIndex: "action",
      key: "action",
      align: "right" as "right",
      render: (title: string, record: any, index: any) => (
        <>
          <BaseToolTip title={t("tooltip.view-operator-details")}>
            <BaseButton
              onClick={(e: any) => {
                const id = record?.id;
                navigate(`${id}`);
              }}
              type="link"
            >
              <EyeOutlined
                style={{
                  fontSize: "24px",
                  color: "var(--primary)",
                }}
              />
            </BaseButton>
          </BaseToolTip>

          <BaseToolTip title={t("tooltip.edit-operator")}>
            <BaseButton
              type="link"
              onClick={(e) => {
                e.stopPropagation();

                setIsEditModal(record);
              }}
              style={{ marginLeft: "15px", padding: "0px" }}
            >
              <FaEdit
                style={{
                  height: "24px",
                  width: "24px",
                  color: "var(--skyblue)",
                }}
              />
            </BaseButton>
          </BaseToolTip>

          <BaseButton
            onClick={(e) => {
              e.stopPropagation();
              onSelectToDelete(record?.id, operatorData);
            }}
            type="link"
          >
            {record?.isFirstLogin ? (
              <BaseToolTip title={t("tooltip.delete-operator")}>
                <FaTrashAlt
                  style={{ height: "24px", width: "24px", color: "var(--red)" }}
                />
              </BaseToolTip>
            ) : record?.isBlocked ? (
              <>
                <BaseToolTip title={t("tooltip.unblock-operator")}>
                  <TbLockOpen
                    style={{
                      height: "24px",
                      width: "24px",
                      color: "var(--tairo)",
                    }}
                  />
                </BaseToolTip>
              </>
            ) : (
              <>
                <BaseToolTip title={t("tooltip.block-operator")}>
                  <TbLock
                    style={{
                      height: "24px",
                      width: "24px",
                      color: "var(--orange)",
                    }}
                  />
                </BaseToolTip>
              </>
            )}
          </BaseButton>
        </>
      ),
    },
  ];
  const dataSource = operatorData?.map((item: any, index: number) => ({
    rowNo: pagination.limit * pagination.page + index + 1,
    key: item?._id,
    id: item?._id,
    firstName: item?.firstName,
    lastName: item?.lastName,
    fullName: item?.firstName + " " + item?.lastName,
    email: item?.email,
    avatar: item?.avatar,
    createdAt: item?.createdAt,
    countryCode: item?.countryCode,
    phone: item?.phone ? `+${item?.countryCode} ${item?.phone}` : "N/A",
    gender: item?.gender,
    password: item?.initialPassword,
    isFirstLogin: item?.isFirstLogin,
    isBlocked: item?.isBlocked,
  }));

  return (
    <>
      {isTablet ? (
        <>
          <HeaderWrapper title={t("dashboard.operator")} />
          <ActionWrapper>
            <BaseTabs
              items={items}
              handleTabs={handleTabs}
              statusValue={pagination.status}
            />
            <ActionWrapper>
              <BaseSearch
                handleSearch={handleSearch}
                searchValue={pagination.search}
                style={{ marginRight: "12px" }}
              />
              <ActionButton
                actionTitle={t("dashboard.add-operator")}
                handleAction={addOperator}
              />
            </ActionWrapper>
          </ActionWrapper>
        </>
      ) : (
        <>
          <HeaderWrapper title={t("dashboard.operator")} />
          <ActionWrapper>
            <BaseSearch
              handleSearch={handleSearch}
              searchValue={pagination.search}
              style={{ marginRight: "12px" }}
            />
            <ActionButton
              actionTitle={t("dashboard.add-operator")}
              handleAction={addOperator}
            />
          </ActionWrapper>

          <BaseTabs
            items={items}
            handleTabs={handleTabs}
            statusValue={pagination.status}
          />
        </>
      )}

      <AddOperatorModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        onRefresh={() => getAllOperator(pagination)}
      />

      <EditOperatorModal
        values={isEditModal}
        isModalOpen={isEditModal}
        setIsModalOpen={(val) => {
          setIsEditModal(val);
        }}
        openModal={() => {
          setIsEditModal(true);
        }}
        closeModal={() => {
          setIsEditModal(false);
        }}
        onRefresh={() => getAllOperator(pagination)}
      />

      <AssignOperatorModal
        isModalOpen={isAssignModalOpen}
        setIsModalOpen={setIsAssignModalOpen}
        onRefresh={() => getAllOperator(pagination)}
        operatorInfo={operatorInfoData}
      />

      {isLargeMobile ? (
        <BaseTable
          onRow={(record, rowIndex) => {
            return {
              onClick: () => {
                navigate(`${record?.id}`);
              },
            };
          }}
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          loading={loader}
        />
      ) : (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {operatorData?.map((operator) => (
            <OperatorsTableRowCard
              key={operator?._id}
              firstName={operator?.firstName}
              lastName={operator?.lastName}
              email={operator?.email}
              createdDate={operator?.createdAt}
              onRowClick={(e) => {
                e.stopPropagation();
                navigate(`${operator?._id}`);
              }}
            />
          ))}
        </div>
      )}
      <BasePagination
        pagination={pagination}
        handlePagination={handlePagination}
      />
      <ConfirmationMessagePopup
        isVisible={isDeleteModalVisible}
        onAction={() => {
          if (selectedOperator?.isBlocked) {
            handleUnblockOperator(selectedOperator?._id);
          } else {
            handleDeleteOperator(selectedOperator?._id);
          }
        }}
        actionLabel={
          selectedOperator?.isFirstLogin
            ? t("modal.delete-btn")
            : selectedOperator?.isBlocked
            ? t("modal.unblock-btn")
            : t("modal.block-btn")
        }
        onClose={closeDeleteModal}
        title={`${
          selectedOperator?.isFirstLogin
            ? t("modal.delete-operator-title")
            : selectedOperator?.isBlocked
            ? t("modal.unblock-operator-title")
            : t("modal.block-operator-title")
        } ${t("modal.operator")} `}
        actionIsDanger={selectedOperator?.isFirstLogin}
        isLoading={isLoading}
      >
        <BaseTypography.Text style={{ marginBottom: "20px" }}>
          {selectedOperator?.isFirstLogin
            ? t("modal.delete-message")
            : selectedOperator?.isBlocked
            ? t("modal.unblock-message")
            : t("modal.block-message")}
        </BaseTypography.Text>
        {!selectedOperator?.isFirstLogin && !selectedOperator?.isBlocked && (
          <>
            <br />
            <BaseTypography.Text type="secondary">
              {t("modal.confirm-block-message")}
            </BaseTypography.Text>
          </>
        )}
        <br />
        <br />
        <BaseTypography.Text>
          {t("dashboard.name")} &ensp;: {selectedOperator?.fullName}
        </BaseTypography.Text>
        <br />
        <BaseTypography.Text>
          {t("remain.email")} &nbsp;&ensp;: {selectedOperator?.email}
        </BaseTypography.Text>
      </ConfirmationMessagePopup>
    </>
  );
};

export default Operator;
