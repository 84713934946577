import { Input, InputNumber, InputProps, Space } from "antd";
import BaseError from "components/common/BaseError";
import { BaseTypography } from "components/common/BaseTypography";
import { useField } from "formik";
import React from "react";

const inputComponents: any = {
  text: Input,
  password: Input.Password,
  textarea: Input.TextArea,
  search: Input.Search,
};

const BaseInputs = ({ type, label, name, ...props }: IBaseInputProps) => {
  const InputComponent = inputComponents[type] || Input;
  const [field, meta, helpers] = useField(name);
  const { touched, error } = meta;

  return (
    <div style={{ margin: "15px 0px" }}>
      <BaseTypography style={{ marginBottom: 4 }}>{label}</BaseTypography>

      <InputComponent {...props} {...field} />

      {touched && error && <BaseError>{error}</BaseError>}
    </div>
  );
};

export default BaseInputs;

interface IBaseInputProps extends InputProps {
  type: string;
  label: string;
  name: string;
}
