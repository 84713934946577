// import { Avatar, Col, Divider, Image, Row, Tabs, Typography } from "antd";
// import Editor from "components/Editor/Editor";
// import BaseLoading from "components/common/BaseSpin";
// import { BaseTypography } from "components/common/BaseTypography";
import useAPI from "hooks/useApi";
import { useEffect, useState } from "react";
// import { useTranslation, setI18n } from "react-i18next";
import { useParams } from "react-router-dom";
// import languageMapWithCountries from "utils/languagemaps";
// import * as S from "./index.styles";
// import Leaflet from "containers/City/SingleCity/Leaflet";
// import BannerImage from "./BannerImage";
// import LogoTitle from "./LogoTitle";
// import LanguageTabs from "./LanguageTabs";
// import InterSection from "./Intersection";
// import { styled } from "styled-components";
// import i18next from "i18next";
import QRPublicPage from "containers/QRPublicPage";

const QRDetailPublic = () => {
  const { id } = useParams();
  // const location = useLocation();
  // const { t } = useTranslation();

  // const [availableLanguages, setAvailableLanguages] = useState([]);
  // const [selectedDetails, setSelectedDetails] = useState<{
  //   desc?: string;
  //   lang?: string;
  //   title?: string;
  // }>({});
  const [selectedLang, setSelectedLang] = useState("");

  const [makeRequest, { data: QrDetailsData, loading: QrDetailsLoading }] =
    useAPI();
  let isUsingGroup = false;
  const publicViews = () => {
    if (!!QrDetailsData?.group && !!QrDetailsData?.publicView) {
      if (QrDetailsData?.isShowFromGroup === false) {
        isUsingGroup = false;
        return QrDetailsData.publicView;
      } else {
        isUsingGroup = true;
        return QrDetailsData?.group?.publicView;
      }
    }
    if (!!QrDetailsData?.group) {
      isUsingGroup = true;
      return QrDetailsData?.group?.publicView;
    }
    if (!!QrDetailsData?.publicView) {
      isUsingGroup = false;
      return QrDetailsData?.publicView;
    }
  };

  // const group = QrDetailsData?.group;
  // const cityData = QrDetailsData?.city;
  const publicView = publicViews();

  const camera = isUsingGroup
    ? QrDetailsData?.selectedGroupCamera
    : QrDetailsData?.selectedPublicCamera;
  // const cityDetailsArray = publicView?.details || [];
  // const footerLogo = publicView?.footerLogo;
  const colorState = publicView?.customization;

  console.log("the isusinggroup", camera);

  const getCityData = async (id: any) => {
    try {
      await makeRequest({
        method: "get",
        url: `QR/getPublicQRCodesDetailsById/${id}`,
      });
    } catch (error) {}
  };

  useEffect(() => {
    getCityData(id);
  }, []);

  // const changeLang = async (val) => {
  //   await i18next.changeLanguage(val);
  // };

  // useEffect(() => {
  //   if (Array.isArray(cityDetailsArray) && cityDetailsArray.length > 0) {
  //     const availLang = cityDetailsArray.map((val) => {
  //       return {
  //         langCode: val.lang,
  //         language: languageMapWithCountries[val.lang].languageFullName,
  //         flag: (
  //           <div style={{ fontFamily: "Twemoji Country Flags" }}>
  //             {languageMapWithCountries[val.lang].flag}
  //           </div>
  //         ),
  //       };
  //     });
  //     handleDetailsSelection(availLang[0].langCode);
  //     setSelectedLang(availLang[0].langCode);
  //     changeLang(availLang[0].langCode);

  //     setAvailableLanguages(availLang);
  //   }
  // }, [cityDetailsArray]);

  // const handleDetailsSelection = (lang) => {
  //   if (Array.isArray(cityDetailsArray) && cityDetailsArray.length > 0) {
  //     const selected = cityDetailsArray.find((x) => x.lang === lang);
  //     setSelectedDetails(selected);
  //   }
  // };

  // const onTabChange = async (val) => {
  //   handleDetailsSelection(val);
  //   setSelectedLang(val);
  //   changeLang(val);
  // };

  const singleMarkerDetails = [];

  if (
    QrDetailsData?.latitude !== undefined &&
    QrDetailsData?.longitude !== undefined
  ) {
    singleMarkerDetails.push({
      _id: QrDetailsData?._id || "null",
      latitude: QrDetailsData?.latitude,
      longitude: QrDetailsData?.longitude,
    });
  }

  return (
    <QRPublicPage
      isQRDetailsPage
      loading={QrDetailsLoading}
      publicView={publicView}
      qrDetails={QrDetailsData}
      colorState={colorState}
      cameras={camera}
    />
    // <>
    //   {QrDetailsLoading ? (
    //     <BaseLoading></BaseLoading>
    //   ) : (
    //     <>
    //       <S.QRRequestedStyled colorState={colorState}>
    //         {publicView?.banner && (
    //           <BannerImage src={publicView?.banner} colorState={colorState} />
    //         )}
    //         <S.ContentWrapperStyled>
    //           {publicView && publicView?._id && (
    //             <>
    //               <LogoTitle
    //                 logo={cityData?.logo}
    //                 title={publicView?.title}
    //                 CState={colorState}
    //               />
    //               <LanguageTabs
    //                 cState={colorState}
    //                 cityDetailsArray={cityDetailsArray}
    //                 availableLanguages={availableLanguages}
    //                 onTabChange={onTabChange}
    //                 selectedLang={selectedLang}
    //                 selectedDetails={selectedDetails}
    //               />
    //             </>
    //           )}

    //           {!QrDetailsData?._id && (
    //             <Text>
    //               <Typography.Text
    //                 style={{
    //                   textAlign: "center",
    //                   fontSize: "20px",
    //                   marginTop: "20px",
    //                 }}
    //               >
    //                 {" "}
    //                 {t("remain.not-contain-any-information")}
    //               </Typography.Text>
    //             </Text>
    //           )}

    // {/* <Divider /> */}
    //           {!!colorState?.isCameraDetails && (
    //             <InterSection
    //               QrDetailsData={QrDetailsData}
    //               cState={colorState}
    //             />
    //           )}
    //           <Row align="top" justify="space-between">
    //             <Col
    //               xs={{ span: 24 }}
    //               md={{ span: 12 }}
    //               style={{ paddingRight: "15px" }}
    //             >
    //               <S.InfoContainerStyled colorState={colorState}>
    //                 <S.InfoHeader>{t("remain.location")}</S.InfoHeader>
    //                 <Divider style={{ margin: "5px 0px 10px" }} />
    //                 <S.InfoWrapperStyled>
    //                   <div className="label">{t("dashboard.address")}</div>
    //                   <div className="value">: {QrDetailsData?.address}</div>
    //                 </S.InfoWrapperStyled>
    //                 <S.InfoWrapperStyled>
    //                   <div className="label">{t("dashboard.latitude")}</div>
    //                   <div className="value">: {QrDetailsData?.latitude}</div>
    //                 </S.InfoWrapperStyled>
    //                 <S.InfoWrapperStyled>
    //                   <div className="label">{t("dashboard.longitude")}</div>
    //                   <div className="value">: {QrDetailsData?.longitude}</div>
    //                 </S.InfoWrapperStyled>
    //               </S.InfoContainerStyled>
    //               <S.InfoContainerStyled colorState={colorState}>
    //                 <S.InfoHeader>{t("group.qr")}</S.InfoHeader>
    //                 <Divider style={{ margin: "5px 0px 10px" }} />
    //                 <S.InfoWrapperStyled>
    //                   <div className="label">{t("dashboard.id")}</div>
    //                   <div className="value">: {QrDetailsData?._id}</div>
    //                 </S.InfoWrapperStyled>
    //                 <S.InfoWrapperStyled>
    //                   <img
    //                     style={{ objectFit: "contain" }}
    //                     src={QrDetailsData?.URL}
    //                     alt={QrDetailsData?.URL}
    //                     height={"100px"}
    //                   />
    //                 </S.InfoWrapperStyled>
    //               </S.InfoContainerStyled>
    //             </Col>
    //             <Col xs={{ span: 24 }} md={{ span: 12 }}>
    //               {QrDetailsData && (
    //                 <Leaflet QRCodeDetails={singleMarkerDetails} />
    //               )}
    //             </Col>
    //           </Row>
    //         </S.ContentWrapperStyled>

    //         <S.FooterContainer colorState={colorState}>
    //           <S.FooterLogo>
    //             <S.FooterText>
    //               {cityData?.title} {t("dashboard.gdpr")}
    //             </S.FooterText>
    //           </S.FooterLogo>
    //           <S.AssociateLogo>
    //             <S.FooterStyled>
    //               <div className="image-wrapper">
    //                 {footerLogo?.length &&
    //                   footerLogo?.map((item) => {
    //                     return (
    //                       <>
    //                         <img
    //                           src={item?.URL}
    //                           alt={item?.link}
    //                           onClick={() => window.open(item?.link)}
    //                           style={{
    //                             cursor: "pointer",
    //                             objectFit: "contain",
    //                           }}
    //                         />
    //                       </>
    //                     );
    //                   })}
    //               </div>
    //             </S.FooterStyled>
    //             <S.FooterText>
    //               {t("contact.contact-us")}: cameracompliance@example.com
    //             </S.FooterText>
    //           </S.AssociateLogo>
    //         </S.FooterContainer>
    //       </S.QRRequestedStyled>
    //     </>
    //   )}
    // </>
  );
};

export default QRDetailPublic;

// const Text = styled.div`
//   display: flex;
//   align-items: center;
//   margin: 0 auto;
//   justify-content: center;
//   margin-bottom: 50px;
// `;

// const Container = styled.div`
//   /* width: 100%; */
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   margin: 0px auto;
// `;
