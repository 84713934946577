import FileSaver from "file-saver";
import { toast } from "react-toastify";

export const downloadFile = async (url: any) => {
  try {
    await FileSaver.saveAs(url, url);
  } catch (error) {
    toast.error("Error downloading the Qr");
  }
};

export const convertSvgToPng = async (svgUrl, fileName = "converted_image") => {
  const svgImage = new Image();
  svgImage.crossOrigin = "anonymous";

  svgImage.onload = async () => {
    const canvas = document.createElement("canvas");
    canvas.width = svgImage.width;
    canvas.height = svgImage.height;
    const ctx = canvas.getContext("2d");

    if (ctx) {
      ctx.drawImage(svgImage, 0, 0);
      const pngDataUrl = canvas.toDataURL("image/png");

      const downloadLink = document.createElement("a");
      downloadLink.href = pngDataUrl;
      downloadLink.download = fileName + ".png";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };
  svgImage.src = svgUrl;
};
