import * as StylesShared from "../index.styles";
import Feature from "../Feature";
import CityPublicPAgeImage from "../images/city-public-page.png";
// @ts-ignore
import Fade from "react-reveal/Fade";
//@ts-ignore
import { useTranslation } from "react-i18next";

const CustomPublicPage = () => {
  const { t } = useTranslation();
  return (
    <StylesShared.Section>
      <StylesShared.Container>
        <Feature
          title={t("feature.custom-title")}
          description={
            <>
              {t("feature.custom-description")}
              <>
                <ul>
                  <li>{t("feature.theme")}</li>
                  <li>{t("feature.styles")}</li>
                  <li>{t("feature.content")}</li>
                  <li>{t("feature.language")}</li>
                  <li>{t("feature....and-more")}</li>
                </ul>
              </>
              {t("feature.on-every-public-page")}
            </>
          }
          image={CityPublicPAgeImage}
        />
      </StylesShared.Container>
    </StylesShared.Section>
  );
};

export default CustomPublicPage;
