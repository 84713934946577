import { subscribers as allSubscribers } from "./functions";

import { io, Socket } from "socket.io-client";

import axios from "axios";
import rootData from "../utils/rootData";

let socket: undefined | Socket;

function connectSocket() {
  const SOCKET_URL = rootData.socketURL;

  const authToken = axios.defaults.headers.Authorization as string;
  const token = authToken.split(" ")[1];
  try {
    const opts = {
      transports: ["websocket"],
      reconnectionDelay: 5000,
      reconnection: true,
      reconnectionAttempts: Infinity,
      auth: {
        token,
      },
      cors: {
        origin: SOCKET_URL,
      },
    };

    socket = io(SOCKET_URL, opts);
    socket.on("connect", function () {
      console.log("socket connected");
      allSubscribers(socket);
    });
    socket.on("connect_error", function (err) {
      console.log("connect error", err);
    });
  } catch (err) {
    console.log("error", err);
  }

  return socket;
}

function disconnectSocket() {
  socket?.disconnect();
  socket?.close();
  socket = undefined;
}

export default {
  socket,
  connectSocket,
  disconnectSocket,
};
