import { Typography } from "antd";
import React from "react";
import { SectionContainer } from "../style";
import { sectionData } from "./sectiondata";
//@ts-ignore
import { useTranslation } from "react-i18next";

const UniqueFeatures = () => {
  const { t } = useTranslation();
  return (
    <SectionContainer>
      <Typography.Title level={4} className="title">
        {t("new.unique-features")}
      </Typography.Title>

      <div className="main">
        {sectionData?.map((section) => {
          const { img, title, description } = section;
          return (
            <div className="container" key={section.id}>
              <img src={img} className="image" />
              <Typography.Title level={5}>{title}</Typography.Title>
              <Typography.Text className="text">{description}</Typography.Text>
            </div>
          );
        })}
      </div>
    </SectionContainer>
  );
};

export default UniqueFeatures;
