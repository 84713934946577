import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./locales/en/translation.json";
import translationDE from "./locales/de/translation.json";
import translationFR from "./locales/french/translation.json";
import translationES from "./locales/spanish/translation.json";
import translationFI from "./locales/finnish/translation.json";
import translationIT from "./locales/italian/translation.json";
import translationPO from "./locales/polish/translation.json";
import translationRO from "./locales/romanian/translation.json";
import translationSI from "./locales/slovenian/translation.json";
import translationSE from "./locales/swedish/translation.json";

const resources = {
  en: {
    translation: translationEN,
  },
  de: {
    translation: translationDE,
  },
  fr: {
    translation: translationFR,
  },
  es: {
    translation: translationES,
  },
  fi: {
    translation: translationFI,
  },
  it: {
    translation: translationIT,
  },
  pl: {
    translation: translationPO,
  },
  ro: {
    translation: translationRO,
  },
  si: {
    translation: translationSI,
  },
  sv: {
    translation: translationSE,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
