import React from "react";
import { Select, SelectProps } from "antd";
import { useField } from "formik";
import { BaseTypography } from "../BaseTypography";
import * as S from "./index.styles";

export const BaseSelect = ({ name, label, children, ...props }: ISelectProps) => {
  return (
    <>
      <BaseTypography>{label}</BaseTypography>
      <Select {...props}>{children}</Select>
    </>
  );
};

interface ISelectProps extends SelectProps {
  name?: string;
  label?: string;
}
