import styled from "styled-components";
import { default as AntIcon } from "@ant-design/icons";
import { StatisticColor } from "constants/config/statistics";
import { Text } from "../StatisticsInfo/StatisticsInfo.styles";
import { Card, Row } from "antd";
import { BaseTypography } from "components/common/BaseTypography";
import { FONT_SIZE, FONT_WEIGHT, media } from "styles/themes/constants";

interface StatisticsProps {
  $color: StatisticColor;
}

export const IconWrapper = styled.div`
  margin-top: 0.25rem;
`;

export const Icon = styled(AntIcon)`
  font-size: 1.2rem;
  // color: var(--white);
  color: #000;
  @media only screen and ${media.md} {
    font-size: 1.5rem;
  }
`;

export const StatisticCard = styled(Card)<StatisticsProps>`
  line-height: 1;
  overflow: hidden;
  padding: 0.2rem;
  background-color: ${(props) => `var(--${props.$color})`};
  .ant-card-body {
    padding: 10px;
  }
  @media only screen and ${media.md} {
    .ant-card-body {
      padding: 24px;
    }
  }
`;

export const NumberText = styled(BaseTypography.Text)`
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.medium};
  // color: var(--white);
  color: #000;
`;
