import { Checkbox, Spin, Typography } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import BaseButton from "components/common/BaseButton";
import { BaseTypography } from "components/common/BaseTypography";
import useIntersectionObserver from "hooks/useIntersectionObserver";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import notifications from "shared/front/apis/notifications.api";
import request from "utils/request";
import { ButtonFlexEnd, FlexColumnAlignCenter } from "utils/styles";
import { fullConvertTimestamp } from "utils/utils";
import Store from "../../store";
import { FullPageNotificationsStyled } from "./styles";
import { useTranslation } from "react-i18next";

type Props = {};

const FullPageNotifications = (props: Props) => {
  const [filteredNotifications, setFilteredNotifications] = useState([]);
  const [selectedNotifications, setSelectedNotifications] = useState([]);
  const [readingAllNotifications, setReadingAllNotifications] = useState(false);
const {t} = useTranslation();
  const [params, setParams] = useState({
    limit: 10,
    page: 0,
  });
  const notificationLastRowRef = useRef();
  const entry = useIntersectionObserver(notificationLastRowRef, {});
  let isIntersecting = undefined;

  if (entry && entry[0]) {
    isIntersecting = entry[0].isIntersecting;
  }

  // SELECTOR
  // @ts-ignore
  const allNotifications: any[] = useSelector(Store.Select.notifications);
  const pagination: {
    count: number;
    // @ts-ignore
  } = useSelector(Store.Select.pagination);
  const unreadNotificationsCount: { count: number } = useSelector(
    // @ts-ignore
    Store.Select.unreadNotificationsCount
  );

  // @ts-ignore
  const isLoading: any = useSelector(Store.Select.loading);

  const navigate = useNavigate();

  useEffect(() => {
    if (isIntersecting && !isLoading.notifications) {
      setParams((p) => ({ ...p, page: p.page + 1 }));
    } else {
    }
  }, [isIntersecting]);

  useEffect(() => {
    if (
      allNotifications.length > 0 &&
      pagination?.count > allNotifications.length
    ) {
      fetchAllNotications(params);
    } else {
    }
  }, [params]);

  const fetchAllNotications = async (params) => {
    try {
      request(notifications.getAllNotifications({ params }));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (Array.isArray(allNotifications) && allNotifications.length > 0) {
      const copyNotifications = [...allNotifications];
      const uniqueNotifications = copyNotifications
        .reduce((acc, val) => {
          if (acc.findIndex((x) => x._id === val._id) > 0) {
            return acc;
          }
          acc.push(val);
          return acc;
        }, [])
        .sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt));
      setFilteredNotifications(uniqueNotifications);
    }
  }, [allNotifications]);

  const storeValueUpdateAsRead = (ids) => {
    const updatedNotifications = allNotifications.map((notif) => {
      if (ids.includes(notif._id)) {
        return {
          ...notif,
          isRead: true,
        };
      }
      return notif;
    });
    Store.store.dispatch(
      Store.Actions.set("notifications", updatedNotifications)
    );
    Store.store.dispatch(
      Store.Actions.set("unreadNotificationsCount", {
        count:
          unreadNotificationsCount?.count - 1 >= 0
            ? unreadNotificationsCount?.count - 1
            : 0,
      })
    );
  };

  const readNotification = async (notificationId) => {
    try {
      await request(notifications.readSingleNotification(notificationId));
    } catch (error) {
      console.log(error);
    }
  };
  const markAsReadBulkNotification = async (notificationIds: Array<string>) => {
    try {
      if (notificationIds.length <= 0) return;
      setReadingAllNotifications(true);
      await request(notifications.readBulkNotification(notificationIds));
      storeValueUpdateAsRead(notificationIds);
      setSelectedNotifications([]);
      const newCount =
        unreadNotificationsCount?.count - notificationIds?.length;
      Store.store.dispatch(
        Store.Actions.set("unreadNotificationsCount", {
          count: newCount >= 0 ? newCount : 0,
        })
      );
      setReadingAllNotifications(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleNotificationClick = async (event, notification) => {
    event.stopPropagation();
    if (!allNotifications || !Array.isArray(allNotifications)) return;
    const id = notification._id;
    storeValueUpdateAsRead([id]);
    readNotification(id);
    navigate(notification?.webRoute);
  };

  const handleSelectNotifications = (event: CheckboxChangeEvent) => {
    event.stopPropagation();
    const { value } = event.target;
    setSelectedNotifications((prev) =>
      prev.includes(value) ? prev.filter((x) => x !== value) : [...prev, value]
    );
  };

  const renderNotification = (notification) => {
    return (
      <div
        className="notification"
        onClick={(e) => handleNotificationClick(e, notification)}
      >
        <div className="checkbox-title-wrapper">
          <div onClick={(e) => e.stopPropagation()} className="checkbox">
            <Checkbox
              value={notification._id}
              onChange={handleSelectNotifications}
              checked={selectedNotifications.includes(notification._id)}
              disabled={notification.isRead}
            />
          </div>
          <div>
            <BaseTypography.Text className="message">
              {notification?.title}
            </BaseTypography.Text>
            <BaseTypography.Text type="secondary" className="date">
              {fullConvertTimestamp(notification?.createdAt)}
            </BaseTypography.Text>
          </div>
        </div>
        {!notification?.isRead && <div className="unread-dot" />}
      </div>
    );
  };
  return (
    <FullPageNotificationsStyled>
      <BaseTypography.Title level={3}>{t("notification.notification")}</BaseTypography.Title>
      <div className="notification-container">
        <ButtonFlexEnd style={{ margin: "0px 10px 20px" }}>
          <BaseButton
            onClick={() => markAsReadBulkNotification(selectedNotifications)}
            disabled={!selectedNotifications.length}
            className="mark-btn"
            loading={readingAllNotifications}
          >
           {t("notification.mark-as-read")}
          </BaseButton>
        </ButtonFlexEnd>
        <div className="notifications-wrapper">
          {Array.isArray(allNotifications) && allNotifications.length > 0 ? (
            filteredNotifications.map((notice, index) => {
              const isLastRow = index + 1 === filteredNotifications.length;
              if (isLastRow) {
                return (
                  <div ref={notificationLastRowRef}>
                    {renderNotification(notice)}
                  </div>
                );
              }
              return <div>{renderNotification(notice)}</div>;
            })
          ) : (
            <div>{t("notification.no-notification")}</div>
          )}
          <FlexColumnAlignCenter style={{ margin: "20px 0px" }}>
            {pagination?.count > allNotifications.length ? (
              isLoading ? (
                <Spin size="large" style={{ color: "var(--primary)" }} />
              ) : (
                <BaseButton
                  onClick={() => setParams((p) => ({ ...p, page: p.page + 1 }))}
                >
                  {t("notification.load-more")}
                </BaseButton>
              )
            ) : (
              <Typography.Text>-- {t("notification.end-of-notifications")} --</Typography.Text>
            )}
          </FlexColumnAlignCenter>
        </div>
      </div>
    </FullPageNotificationsStyled>
  );
};

export default FullPageNotifications;
