import en from "./en";
import finnish from "./finnish";
import french from "./french";
import german from "./german";
import italian from "./italian";
import polish from "./polish";
import romanian from "./romanian";
import slovenian from "./slovenian";
import spanish from "./spanish";
import swedish from "./swedish";

export default {
  en: en,
  fr: french,
  de: german,
  es: spanish,
  it: italian,
  pl: polish,
  ro: romanian,
  si: slovenian,
  sv: swedish,
  fi: finnish,
};
