import { styled } from "styled-components";
import { FONT_FAMILY } from "styles/themes/constants";
import { devices } from "utils/theme";

export const Container = styled.div`
  background-color: #efefef;
  /* background-color: #111222; */
`;
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 3rem;
  max-width: 1200px;
  margin: 0 auto;
  padding: 50px;
  min-height: 80vh;

  @media (${devices.tablet}) {
    flex-direction: row;
  }
`;

export const TextContainer = styled.div`
  flex: 2;

  & .features-hero-title {
    font-size: 3rem;
    color: black;
    /* color: white; */
  }

  & .features-hero-description {
    margin-top: 20px;
    font-size: 20px;
    /* color: white; */
    color: black;
  }
`;

export const Icons = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;

  > a {
    &:first-of-type {
      margin-right: 5px;
    }
  }
`;

export const ImageContainer = styled.div`
  flex: 3;
`;

export const HeroImage = styled.img`
  width: 100%;
`;

export const SectionOne = styled.div`
  position: relative;
  /* max-width: 1150px; */
  display: flex;
  justify-content: center;
  padding: 80px 100px;

  /* background: #111222; */
  /* color: #fff; */
  & .container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & .main-title {
      font-size: 3em;
      margin-bottom: 50px;
      /* color: white; */
      color: black;
    }

    & .content {
      position: relative;

      /* width: 1150px; */
      display: flex;
      justify-content: center;
      align-items: center;

      & .image {
        position: relative;
        width: 800px;
        height: 400px;
        overflow: hidden;

        & .img {
          z-index: 111;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 10px;
        }
      }

      & .text-box {
        z-index: 222;
        /* background: rgba(255, 255, 255, 0.1); */
        background-color: rgba(255, 0, 0, 0.3);
        backdrop-filter: blur(20px);
        padding: 40px;
        max-width: 400px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 10px;
        /* margin-top: 20px; */
        height: 400px;
        transform: translate(-60px, 35px);

        & .desc {
          color: white;
          align-items: center;
          justify-content: center;
        }
      }
    }
    & .media-icons {
      margin-top: 100px;

      & .a {
        color: #fff;
        font-size: 2em;
        margin: 60px;
      }
    }
  }
`;
