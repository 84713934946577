import {
  BrowserRouter,
  Routes,
  Route,
  NavigateFunction,
  useNavigate,
} from "react-router-dom";
import MainLayout from "../layouts/Main/MainLayout/MainLayout";
import Dashboard from "../containers/Dashboard";
import Operator from "../containers/Operator";
import GenerateQR from "../containers/Generate-Qr";
import GeneratedQR from "containers/Generated-Qr";
import RequireAuth from "./RequireAuth";
import AuthLayoutFallback from "../layouts/AuthLayout";
import { NotFoundPage } from "../containers/NotFoundPage";
import LogoutFallback from "./Logout";
import RootProvider from "../RootProvider";
import Sticker from "containers/Sticker";
import Contact from "containers/Contact";
import Country from "containers/Country";
import City from "containers/City";
import SingleCity from "containers/City/SingleCity";
import SingleOperator from "containers/Operator/SingleOperator";
import { LoginForm } from "containers/Login";
import QRDetails from "containers/QrDetails";
import PublicViewQrDetails from "containers/QrDetails";
import QR from "containers/UpdatedQr";
import Terms from "containers/Terms";
import Privacy from "containers/Privacy";
import Home from "containers/Home";
import TestSticker from "containers/TestSticker/TestSticker";
import {
  QR_ADMIN_CHANGE_PASSWORD_PATH,
  QR_DASHBOARD_AUTH_LOGOUT_PATH,
  QR_DASHBOARD_AUTH_PATH,
  QR_DASHBOARD_MAIN_PATH,
  QR_HOME_PATH,
} from "constants/routes/route";
import Rbac from "./Rbac";
import {
  ALLOWED_CITY_ROLE,
  ALLOWED_SUPER_ADMIN_ROLE,
} from "constants/roles/roles";
import Admin from "containers/Admin";
import ChangePassword from "containers/Login/ChangePassword";
import Profile from "containers/Profile";
import GeneratedQRDetails from "containers/GeneratedQRDetails";
import Camera from "containers/Camera";
import QRDetailPublic from "containers/QrDetailPublicView";
import { NotificationsPage } from "containers/Notifications";
import EditCity from "containers/City/EditCity";
import ChangePasswords from "containers/ChangePassword";
import { ForgotPassword } from "containers/Login/ForgetPassword";
import VerifyOTP from "containers/Login/VerifyOTP";
import ResetPassword from "containers/Login/ResetPassword";
import ProfileChangePassword from "containers/Login/ProfileChangePassword";
import Features from "containers/Features";
import PublicPage from "containers/PublicPage";
import { Categories } from "containers/Categories";
import { Groups, ViewGroup } from "containers/Groups";
import CustomizationPage from "containers/CustomizationPage";
import CustomizationTemplates from "containers/CustomizationPage/CustomizationModal";
import ViewCamera from "containers/Camera/ViewCamera";
import { useEffect } from "react";
import ScrollToTop from "components/ScrollToTop";
import Activities from "containers/Activities";
import Disclaimer from "containers/Disclaimer";
import CameraDetails from "containers/Home/Section/CameraDetails";
import Blog from "containers/Blog";

export let globalNavigate: NavigateFunction;

export const GlobalHistory = () => {
  globalNavigate = useNavigate();

  return null;
};

const AppRouter = () => {
  const protectedLayout = (
    <RequireAuth>
      <MainLayout QR_DASHBOARD_MAIN_PATH={QR_DASHBOARD_MAIN_PATH}></MainLayout>
    </RequireAuth>
  );
  return (
    <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
      <ScrollToTop />
      <GlobalHistory />
      <RootProvider>
        <Routes>
          <Route path="/test-sticker" element={<TestSticker />} />
          <Route path={QR_HOME_PATH} element={<Home />} />
          {/* <Route path="profile" element={<Profile />} /> */}
          <Route path="features" element={<Features />} />
          <Route path="terms" element={<Terms />} />
          <Route path="contact" element={<Contact />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="disclaimer" element={<Disclaimer />} />
          <Route path="camera-compliance-details" element={<CameraDetails />} />
          <Route path={QR_DASHBOARD_MAIN_PATH} element={protectedLayout}>
            <Route path="change-password" element={<ChangePassword />} />
            {/* <Route path="/dashboard/profile" element={<Profile />} /> */}
            <Route
              path="profilechangepassword"
              element={<ProfileChangePassword />}
            />
            <Route index element={<Dashboard />} />
            <Route element={<Rbac allowedRoute={ALLOWED_CITY_ROLE} />}>
              <Route path="camera">
                <Route index element={<Camera />} />
                <Route path=":cameraId" element={<ViewCamera />} />
              </Route>
              <Route path="activities" element={<Activities />} />
              <Route path="country">
                <Route index element={<Country />} />
              </Route>
              <Route path="operator">
                <Route index element={<Operator />} />
                <Route path=":operatorId" element={<SingleOperator />} />
              </Route>
              <Route path="profile" element={<Profile />} />

              <Route path="generated-qr">
                <Route index element={<GeneratedQR />} />
                <Route path=":id" element={<GeneratedQRDetails />} />
              </Route>
              <Route path="sticker" element={<Sticker />} />

              <Route path="updated-qr">
                <Route index element={<QR />} />
                <Route path=":id" element={<QRDetails />} />
              </Route>
              <Route path="public-page">
                <Route index element={<PublicPage />} />
                <Route path="edit">
                  <Route index element={<EditCity />} />
                  <Route path=":id" element={<EditCity />} />
                </Route>
              </Route>
              <Route
                path="public-page/customize-page/:id"
                element={<CustomizationPage />}
              />
              <Route
                path="public-page/template/:id"
                element={<CustomizationTemplates />}
              />
              <Route path="notifications">
                <Route index element={<NotificationsPage />} />
              </Route>
              {/* <Route path="change-passwords" element={<ChangePasswords />} /> */}

              {/* <Route path="change-passwords" element={<ChangePasswords />} /> */}
              <Route path="group">
                <Route index element={<Groups />} />
                <Route path=":groupId" element={<ViewGroup />} />
              </Route>
            </Route>
            <Route element={<Rbac allowedRoute={ALLOWED_SUPER_ADMIN_ROLE} />}>
              <Route path="city">
                <Route index element={<City />} />
                <Route path=":id" element={<SingleCity />} />
              </Route>
              <Route path="admin" element={<Admin />} />
              <Route path="category">
                <Route index element={<Categories />} />
              </Route>
            </Route>
          </Route>
          <Route path={QR_DASHBOARD_AUTH_PATH} element={<AuthLayoutFallback />}>
            <Route path="login" element={<LoginForm />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="otp-verify" element={<VerifyOTP />} />
            <Route path="reset-password" element={<ResetPassword />} />
          </Route>
          <Route path={"qr/:id"} element={<QRDetailPublic />} />
          <Route path={"news/:id"} element={<Blog />} />
          <Route
            path={QR_DASHBOARD_AUTH_LOGOUT_PATH}
            element={<LogoutFallback />}
          />

          <Route path="/*" element={<NotFoundPage />}></Route>
        </Routes>
      </RootProvider>
    </BrowserRouter>
  );
};

export default AppRouter;
//chnages
