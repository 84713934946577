const languageMapWithCountries = {
  en: {
    languageFullName: "English",
    countryName: "United Kingdom",
    countryCode: "GB",
    flag: "🇬🇧",
  },
  af: {
    languageFullName: "Afrikaans",
    countryName: "South Africa",
    countryCode: "ZA",
    flag: "🇿🇦",
  },
  sq: {
    languageFullName: "Albanian",
    countryName: "Albania",
    countryCode: "AL",
    flag: "🇦🇱",
  },
  am: {
    languageFullName: "Amharic",
    countryName: "Ethiopia",
    countryCode: "ET",
    flag: "🇪🇹",
  },
  ar: {
    languageFullName: "Arabic",
    countryName: "Egypt",
    countryCode: "EG",
    flag: "🇪🇬",
  },
  hy: {
    languageFullName: "Armenian",
    countryName: "Armenia",
    countryCode: "AM",
    flag: "🇦🇲",
  },
  az: {
    languageFullName: "Azerbaijani",
    countryName: "Azerbaijan",
    countryCode: "AZ",
    flag: "🇦🇿",
  },
  eu: {
    languageFullName: "Basque",
    countryName: "Spain",
    countryCode: "ES",
    flag: "🇪🇸",
  },
  be: {
    languageFullName: "Belarusian",
    countryName: "Belarus",
    countryCode: "BY",
    flag: "🇧🇾",
  },
  bn: {
    languageFullName: "Bengali",
    countryName: "Bangladesh",
    countryCode: "BD",
    flag: "🇧🇩",
  },
  bs: {
    languageFullName: "Bosnian",
    countryName: "Bosnia and Herzegovina",
    countryCode: "BA",
    flag: "🇧🇦",
  },
  bg: {
    languageFullName: "Bulgarian",
    countryName: "Bulgaria",
    countryCode: "BG",
    flag: "🇧🇬",
  },
  my: {
    languageFullName: "Burmese",
    countryName: "Myanmar",
    countryCode: "MM",
    flag: "🇲🇲",
  },
  ca: {
    languageFullName: "Catalan",
    countryName: "Spain",
    countryCode: "ES",
    flag: "🇪🇸",
  },
  ceb: {
    languageFullName: "Cebuano",
    countryName: "Philippines",
    countryCode: "PH",
    flag: "🇵🇭",
  },
  ny: {
    languageFullName: "Chichewa",
    countryName: "Malawi",
    countryCode: "MW",
    flag: "🇲🇼",
  },
  zh: {
    languageFullName: "Chinese",
    countryName: "China",
    countryCode: "CN",
    flag: "🇨🇳",
  },
  co: {
    languageFullName: "Corsican",
    countryName: "France",
    countryCode: "FR",
    flag: "🇫🇷",
  },
  hr: {
    languageFullName: "Croatian",
    countryName: "Croatia",
    countryCode: "HR",
    flag: "🇭🇷",
  },
  cs: {
    languageFullName: "Czech",
    countryName: "Czech Republic",
    countryCode: "CZ",
    flag: "🇨🇿",
  },
  da: {
    languageFullName: "Danish",
    countryName: "Denmark",
    countryCode: "DK",
    flag: "🇩🇰",
  },
  nl: {
    languageFullName: "Dutch",
    countryName: "Netherlands",
    countryCode: "NL",
    flag: "🇳🇱",
  },
  eo: {
    languageFullName: "Esperanto",
    countryName: "International",
    countryCode: "XX",
    flag: "🌍",
  },
  et: {
    languageFullName: "Estonian",
    countryName: "Estonia",
    countryCode: "EE",
    flag: "🇪🇪",
  },
  tl: {
    languageFullName: "Filipino",
    countryName: "Philippines",
    countryCode: "PH",
    flag: "🇵🇭",
  },
  fi: {
    languageFullName: "Finnish",
    countryName: "Finland",
    countryCode: "FI",
    flag: "🇫🇮",
  },
  fr: {
    languageFullName: "French",
    countryName: "France",
    countryCode: "FR",
    flag: "🇫🇷",
  },
  fy: {
    languageFullName: "Frisian",
    countryName: "Netherlands",
    countryCode: "NL",
    flag: "🇳🇱",
  },
  gl: {
    languageFullName: "Galician",
    countryName: "Spain",
    countryCode: "ES",
    flag: "🇪🇸",
  },
  ka: {
    languageFullName: "Georgian",
    countryName: "Georgia",
    countryCode: "GE",
    flag: "🇬🇪",
  },
  de: {
    languageFullName: "German",
    countryName: "Germany",
    countryCode: "DE",
    flag: "🇩🇪",
  },
  el: {
    languageFullName: "Greek",
    countryName: "Greece",
    countryCode: "GR",
    flag: "🇬🇷",
  },
  gu: {
    languageFullName: "Gujarati",
    countryName: "India",
    countryCode: "IN",
    flag: "🇮🇳",
  },
  ht: {
    languageFullName: "Haitian Creole",
    countryName: "Haiti",
    countryCode: "HT",
    flag: "🇭🇹",
  },
  ha: {
    languageFullName: "Hausa",
    countryName: "Nigeria",
    countryCode: "NG",
    flag: "🇳🇬",
  },
  haw: {
    languageFullName: "Hawaiian",
    countryName: "United States",
    countryCode: "US",
    flag: "🇺🇸",
  },
  iw: {
    languageFullName: "Hebrew",
    countryName: "Israel",
    countryCode: "IL",
    flag: "🇮🇱",
  },
  hi: {
    languageFullName: "Hindi",
    countryName: "India",
    countryCode: "IN",
    flag: "🇮🇳",
  },
  hmn: {
    languageFullName: "Hmong",
    countryName: "China",
    countryCode: "CN",
    flag: "🇨🇳",
  },
  hu: {
    languageFullName: "Hungarian",
    countryName: "Hungary",
    countryCode: "HU",
    flag: "🇭🇺",
  },
  is: {
    languageFullName: "Icelandic",
    countryName: "Iceland",
    countryCode: "IS",
    flag: "🇮🇸",
  },
  ig: {
    languageFullName: "Igbo",
    countryName: "Nigeria",
    countryCode: "NG",
    flag: "🇳🇬",
  },
  id: {
    languageFullName: "Indonesian",
    countryName: "Indonesia",
    countryCode: "ID",
    flag: "🇮🇩",
  },
  ga: {
    languageFullName: "Irish",
    countryName: "Ireland",
    countryCode: "IE",
    flag: "🇮🇪",
  },
  it: {
    languageFullName: "Italian",
    countryName: "Italy",
    countryCode: "IT",
    flag: "🇮🇹",
  },
  ja: {
    languageFullName: "Japanese",
    countryName: "Japan",
    countryCode: "JP",
    flag: "🇯🇵",
  },
  jw: {
    languageFullName: "Javanese",
    countryName: "Indonesia",
    countryCode: "ID",
    flag: "🇮🇩",
  },
  kn: {
    languageFullName: "Kannada",
    countryName: "India",
    countryCode: "IN",
    flag: "🇮🇳",
  },
  kk: {
    languageFullName: "Kazakh",
    countryName: "Kazakhstan",
    countryCode: "KZ",
    flag: "🇰🇿",
  },
  km: {
    languageFullName: "Khmer",
    countryName: "Cambodia",
    countryCode: "KH",
    flag: "🇰🇭",
  },
  rw: {
    languageFullName: "Kinyarwanda",
    countryName: "Rwanda",
    countryCode: "RW",
    flag: "🇷🇼",
  },
  ko: {
    languageFullName: "Korean",
    countryName: "South Korea",
    countryCode: "KR",
    flag: "🇰🇷",
  },
  ku: {
    languageFullName: "Kurdish",
    countryName: "Iraq",
    countryCode: "IQ",
    flag: "🇮🇶",
  },
  ky: {
    languageFullName: "Kyrgyz",
    countryName: "Kyrgyzstan",
    countryCode: "KG",
    flag: "🇰🇬",
  },
  lo: {
    languageFullName: "Lao",
    countryName: "Laos",
    countryCode: "LA",
    flag: "🇱🇦",
  },
  la: {
    languageFullName: "Latin",
    countryName: "Vatican City",
    countryCode: "VA",
    flag: "🇻🇦",
  },
  lv: {
    languageFullName: "Latvian",
    countryName: "Latvia",
    countryCode: "LV",
    flag: "🇱🇻",
  },
  lt: {
    languageFullName: "Lithuanian",
    countryName: "Lithuania",
    countryCode: "LT",
    flag: "🇱🇹",
  },
  lb: {
    languageFullName: "Luxembourgish",
    countryName: "Luxembourg",
    countryCode: "LU",
    flag: "🇱🇺",
  },
  mk: {
    languageFullName: "Macedonian",
    countryName: "North Macedonia",
    countryCode: "MK",
    flag: "🇲🇰",
  },
  mg: {
    languageFullName: "Malagasy",
    countryName: "Madagascar",
    countryCode: "MG",
    flag: "🇲🇬",
  },
  ms: {
    languageFullName: "Malay",
    countryName: "Malaysia",
    countryCode: "MY",
    flag: "🇲🇾",
  },
  ml: {
    languageFullName: "Malayalam",
    countryName: "India",
    countryCode: "IN",
    flag: "🇮🇳",
  },
  mt: {
    languageFullName: "Maltese",
    countryName: "Malta",
    countryCode: "MT",
    flag: "🇲🇹",
  },
  mi: {
    languageFullName: "Maori",
    countryName: "New Zealand",
    countryCode: "NZ",
    flag: "🇳🇿",
  },
  mr: {
    languageFullName: "Marathi",
    countryName: "India",
    countryCode: "IN",
    flag: "🇮🇳",
  },
  mn: {
    languageFullName: "Mongolian",
    countryName: "Mongolia",
    countryCode: "MN",
    flag: "🇲🇳",
  },
  ne: {
    languageFullName: "Nepali",
    countryName: "Nepal",
    countryCode: "NP",
    flag: "🇳🇵",
  },
  no: {
    languageFullName: "Norwegian",
    countryName: "Norway",
    countryCode: "NO",
    flag: "🇳🇴",
  },
  ps: {
    languageFullName: "Pashto",
    countryName: "Afghanistan",
    countryCode: "AF",
    flag: "🇦🇫",
  },
  fa: {
    languageFullName: "Persian",
    countryName: "Iran",
    countryCode: "IR",
    flag: "🇮🇷",
  },
  pl: {
    languageFullName: "Polish",
    countryName: "Poland",
    countryCode: "PL",
    flag: "🇵🇱",
  },
  pt: {
    languageFullName: "Portuguese",
    countryName: "Portugal",
    countryCode: "PT",
    flag: "🇵🇹",
  },
  pa: {
    languageFullName: "Punjabi",
    countryName: "India",
    countryCode: "IN",
    flag: "🇮🇳",
  },
  ro: {
    languageFullName: "Romanian",
    countryName: "Romania",
    countryCode: "RO",
    flag: "🇷🇴",
  },
  ru: {
    languageFullName: "Russian",
    countryName: "Russia",
    countryCode: "RU",
    flag: "🇷🇺",
  },
  sm: {
    languageFullName: "Samoan",
    countryName: "Samoa",
    countryCode: "WS",
    flag: "🇼🇸",
  },
  gd: {
    languageFullName: "Scots Gaelic",
    countryName: "United Kingdom",
    countryCode: "GB",
    flag: "🇬🇧",
  },
  sr: {
    languageFullName: "Serbian",
    countryName: "Serbia",
    countryCode: "RS",
    flag: "🇷🇸",
  },
  st: {
    languageFullName: "Sesotho",
    countryName: "Lesotho",
    countryCode: "LS",
    flag: "🇱🇸",
  },
  sn: {
    languageFullName: "Shona",
    countryName: "Zimbabwe",
    countryCode: "ZW",
    flag: "🇿🇼",
  },
  sd: {
    languageFullName: "Sindhi",
    countryName: "Pakistan",
    countryCode: "PK",
    flag: "🇵🇰",
  },
  // si: {
  //   languageFullName: "Sinhala",
  //   countryName: "Sri Lanka",
  //   countryCode: "LK",
  //   flag: "🇱🇰",
  // },
  sk: {
    languageFullName: "Slovak",
    countryName: "Slovakia",
    countryCode: "SK",
    flag: "🇸🇰",
  },
  si: {
    languageFullName: "Slovenian",
    countryName: "Slovenia",
    countryCode: "SI",
    flag: "🇸🇮",
  },
  so: {
    languageFullName: "Somali",
    countryName: "Somalia",
    countryCode: "SO",
    flag: "🇸🇴",
  },
  es: {
    languageFullName: "Spanish",
    countryName: "Spain",
    countryCode: "ES",
    flag: "🇪🇸",
  },
  su: {
    languageFullName: "Sundanese",
    countryName: "Indonesia",
    countryCode: "ID",
    flag: "🇮🇩",
  },
  sw: {
    languageFullName: "Swahili",
    countryName: "Tanzania",
    countryCode: "TZ",
    flag: "🇹🇿",
  },
  sv: {
    languageFullName: "Swedish",
    countryName: "Sweden",
    countryCode: "SE",
    flag: "🇸🇪",
  },
  tg: {
    languageFullName: "Tajik",
    countryName: "Tajikistan",
    countryCode: "TJ",
    flag: "🇹🇯",
  },
  ta: {
    languageFullName: "Tamil",
    countryName: "India",
    countryCode: "IN",
    flag: "🇮🇳",
  },
  te: {
    languageFullName: "Telugu",
    countryName: "India",
    countryCode: "IN",
    flag: "🇮🇳",
  },
  th: {
    languageFullName: "Thai",
    countryName: "Thailand",
    countryCode: "TH",
    flag: "🇹🇭",
  },
  tr: {
    languageFullName: "Turkish",
    countryName: "Turkey",
    countryCode: "TR",
    flag: "🇹🇷",
  },
  tk: {
    languageFullName: "Turkmen",
    countryName: "Turkmenistan",
    countryCode: "TM",
    flag: "🇹🇲",
  },
  uk: {
    languageFullName: "Ukrainian",
    countryName: "Ukraine",
    countryCode: "UA",
    flag: "🇺🇦",
  },
  ur: {
    languageFullName: "Urdu",
    countryName: "Pakistan",
    countryCode: "PK",
    flag: "🇵🇰",
  },
  ug: {
    languageFullName: "Uyghur",
    countryName: "China",
    countryCode: "CN",
    flag: "🇨🇳",
  },
  uz: {
    languageFullName: "Uzbek",
    countryName: "Uzbekistan",
    countryCode: "UZ",
    flag: "🇺🇿",
  },
  vi: {
    languageFullName: "Vietnamese",
    countryName: "Vietnam",
    countryCode: "VN",
    flag: "🇻🇳",
  },
  cy: {
    languageFullName: "Welsh",
    countryName: "United Kingdom",
    countryCode: "GB",
    flag: "🇬🇧",
  },
  xh: {
    languageFullName: "Xhosa",
    countryName: "South Africa",
    countryCode: "ZA",
    flag: "🇿🇦",
  },
  yi: {
    languageFullName: "Yiddish",
    countryName: "International",
    countryCode: "XX",
    flag: "🌍",
  },
  yo: {
    languageFullName: "Yoruba",
    countryName: "Nigeria",
    countryCode: "NG",
    flag: "🇳🇬",
  },
  zu: {
    languageFullName: "Zulu",
    countryName: "South Africa",
    countryCode: "ZA",
    flag: "🇿🇦",
  },
};

export default languageMapWithCountries;
