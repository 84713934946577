export default [
  {
    title: "1. Acceptation des Conditions :",
    desc: [
      {
        title: "1.1",
        desc: "En utilisant notre projet de conformité des caméras, vous reconnaissez avoir lu, compris et accepté ces Conditions Générales.",
      },
    ],
  },
  {
    title: "2. Politique de Confidentialité :",
    desc: [
      {
        title: "2.1",
        desc: "Votre utilisation de nos services est également régie par notre Politique de Confidentialité. En utilisant nos services, vous consentez à la collecte, à l'utilisation et au partage de vos informations personnelles conformément à la Politique de Confidentialité.",
      },
    ],
  },

  {
    title: "3. Utilisation des Services :",
    desc: [
      {
        title: "3.1",
        desc: "Votre utilisation de nos services est également régie par notre Politique de Confidentialité. En utilisant nos services, vous consentez à la collecte, à l'utilisation et au partage de vos informations personnelles conformément à la Politique de Confidentialité.",
      },
      {
        title: "3.2",
        desc: "Vous devez respecter toutes les lois et réglementations applicables lors de l'utilisation de nos services.",
      },
    ],
  },

  {
    title: "4. Propriété Intellectuelle :",
    desc: [
      {
        title: "4.1",
        desc: "Tous les contenus, matériaux et droits de propriété intellectuelle associés à notre projet de conformité des caméras nous appartiennent ou appartiennent à nos concédants de licence. Vous ne pouvez pas reproduire, distribuer, modifier ou créer des œuvres dérivées basées sur notre contenu sans notre permission explicite.",
      },
    ],
  },

  {
    title: "5. Comportement de l'Utilisateur :",
    desc: [
      {
        title: "5.1",
        desc: "Vous êtes responsable de l'exactitude et de la légalité des informations que vous fournissez lors de l'utilisation de nos services.",
      },
      {
        title: "5.2",
        desc: "Vous ne devez pas vous engager dans un comportement qui pourrait perturber ou nuire à nos services, aux autres utilisateurs ou à notre réputation.",
      },
    ],
  },

  {
    title: "6. Limitation de Responsabilité :",
    desc: [
      {
        title: "6.1",
        desc: "Notre projet de conformité des caméras est fourni 'tel quel' et 'tel que disponible'. Nous ne garantissons pas l'exactitude, la fiabilité ou la disponibilité des services.",
      },
      {
        title: "6.2",
        desc: "Nous ne sommes pas responsables des dommages, pertes ou responsabilités découlant de votre utilisation de nos services ou de toute information obtenue par le biais de nos services. Nous ne garantissons pas l'exactitude, la fiabilité ou la disponibilité des services.",
      },
    ],
  },

  {
    title: "7. Indemnisation :",
    desc: [
      {
        title: "7.1",
        desc: "Vous acceptez d'indemniser et de nous protéger de toute réclamation, perte, responsabilité et dépense (y compris les frais juridiques) découlant de votre utilisation de nos services ou de votre violation de ces Conditions Générales.",
      },
    ],
  },
  {
    title: "8. Modifications et Résiliation :",
    desc: [
      {
        title: "8.1",
        desc: "Nous nous réservons le droit de modifier, suspendre ou résilier notre projet de conformité des caméras à tout moment sans préavis.",
      },
      {
        title: "8.2",
        desc: "Nous pouvons également modifier ces Conditions Générales de temps à autre. Votre utilisation continue de nos services après de telles modifications constitue votre acceptation des termes mis à jour.",
      },
    ],
  },
  {
    title: "9. Loi Applicable :",
    desc: [
      {
        title: "9.1",
        desc: "Ces Conditions Générales sont régies et interprétées conformément aux lois de [Juridiction]. Tout litige découlant de ou en relation avec ces termes sera soumis à la juridiction exclusive des tribunaux de [Juridiction].",
      },
    ],
  },
  {
    title: "10. Contactez-nous :",
    desc: [
      {
        desc: "Si vous avez des questions, des préoccupations ou des demandes concernant ces Conditions Générales ou l'utilisation de nos services, veuillez nous contacter à [adresse e-mail de contact]. En utilisant notre projet de conformité des caméras, vous acceptez d'être lié par ces Conditions Générales. Si vous n'êtes pas d'accord avec une partie de ces termes, veuillez ne pas utiliser nos services.",
      },
    ],
  },
];
