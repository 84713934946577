import { Image, Spin } from "antd";
import type { ColumnsType } from "antd/es/table";
import ActionButton from "components/ActionWrapper/ActionButton";
import ActionWrapper from "components/ActionWrapper/ActionWrapper";
import ConfirmationMessagePopup from "components/ConfirmationMessagePopup";
import BaseButton from "components/common/BaseButton";
import BaseModal from "components/common/BaseModal";
import { BasePagination } from "components/common/BasePagination";
import { BaseTable } from "components/common/BaseTable";
import BaseTabs from "components/common/BaseTabs";
import { BaseTag } from "components/common/BaseTag";
import BaseToolTip from "components/common/BaseTooltip";
import { BaseTypography } from "components/common/BaseTypography";
import HeaderWrapper from "components/headerwrapper";
import Setting from "containers/Setting";
import useAPI from "hooks/useApi";
import { useResponsive } from "hooks/useResponsive";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { TbLock, TbLockOpen } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import type { Dispatch } from "redux";
import stickers from "shared/front/apis/stickers.api";
import store from "store";
import request from "utils/request";
import { FlexColumnAlignCenter, UrlLink } from "utils/styles";
import { convertTimestamp } from "utils/utils";
import StickersTableRowCard from "./StickersTableRowCard";

const Sticker = () => {
  const { isLargeMobile } = useResponsive();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isTablet } = useResponsive();
  const dispatch: Dispatch = useDispatch();
  const sticker = useSelector((state: any) => state.sticker.data);
  const pagination = useSelector((state: any) => state.sticker.pagination);
  let [searchParams, setSearchParams] = useSearchParams();
  const [isLoadingASingleSticker, setIsLoadingASingleSticker] = useState(false);

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [selectedSticker, setSelectedSticker] = useState<{
    _id?: string;
    URL?: string;
    status?: string;
    isArchive?: boolean;
  }>({});

  const [isLoading, setIsLoading] = useState(false);

  const items = [
    {
      key: "All",
      label: t("dashboard.all"),
      archive: false,
    },
    {
      key: "New",
      label: t("dashboard.new"),
      archive: false,
    },
    {
      key: "Active",
      label: t("dashboard.active"),
      archive: false,
    },
    {
      key: "Blocked",
      label: t("dashboard.blocked"),
      archive: true,
    },
  ];

  const handleTabs = (status) => {
    let archive = false;
    if (status === "Blocked") {
      archive = true;
    }

    dispatch(
      store.Actions["update"]("sticker.pagination", {
        page: 0,
        status,
        batch: "",
        archive,
        limit: pagination.limit,
      })
    );
    if (status === "Blocked") {
      status = "Active";
    }
    getAllSticker({ page: 0, limit: pagination?.limit, status, archive });
  };

  const handlePagination = (page, limit) => {
    let { search, status } = pagination;
    let archive = false;
    if (status === "Blocked") {
      archive = true;
      status = "Active";
    }
    dispatch(
      store.Actions["update"]("sticker.pagination", {
        page,
        limit,
      })
    );
    getAllSticker({ page, limit, status, archive, ...(search && { search }) });
  };

  const refetchSticker = () => {
    let { page, limit, status } = pagination;
    if (sticker?.length > 1) {
      let archive = false;
      if (status === "Blocked") {
        archive = true;
        status = "Active";
      }
      getAllSticker({ page, limit, status, archive });
    } else {
      if (page > 0) {
        handlePagination(page - 1, limit);
      }
    }
  };

  useEffect(() => {
    if (searchParams) {
      const queryStickerId = searchParams.get("sticker");
      const queryModalOpen = searchParams.get("addSticker");
      if (queryModalOpen) {
        openModal();
        searchParams.delete("addSticker");
        setSearchParams(searchParams);
      }
      if (queryStickerId) {
        selectStickerToEdit(queryStickerId);
      }
    }
  }, [searchParams, sticker]);

  const [getRequest, { loading: loader }] = useAPI();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const openModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    dispatch(store?.Actions?.reset("stickerSelection"));
    setIsModalVisible(false);
    setSelectedSticker({});
    searchParams.delete("sticker");
    setSearchParams(searchParams);
  };
  const closeDeleteModal = () => {
    setIsDeleteModalVisible(false);
    setSelectedSticker({});
  };

  const getAllSticker = (params) => {
    if (params?.status === "All") {
      getRequest(stickers.getAllSticker("set", { ...params, status: null }));
    } else {
      getRequest(stickers.getAllSticker("set", params));
    }
  };

  const getASingleSticker = async (stickerId) => {
    try {
      setIsLoadingASingleSticker(true);
      const response = await request(stickers.getASticker(stickerId));
      if (response) {
        return response;
      }
      return {};
    } catch (error) {
      console.log("Error Single Sticker: ", error);
    } finally {
      setIsLoadingASingleSticker(false);
    }
  };

  const selectStickerToEdit = async (stickerId) => {
    if (Array.isArray(sticker)) {
      const stickerToEdit = sticker.find((x) => x._id === stickerId);
      setIsModalVisible(true);

      if (!!stickerToEdit) {
        setSelectedSticker({ ...stickerToEdit });
      } else {
        const response = await getASingleSticker(stickerId);
        if (response) {
          setSelectedSticker({ ...response });
        }
      }
    }
  };

  const columns: ColumnsType = [
    {
      title: t("dashboard.s.n."),
      dataIndex: "rowNo",
      key: "rowNo",
      rowScope: "row",
    },
    {
      title: t("dashboard.sticker"),
      dataIndex: "image",
      key: "image",
      render: (imageURL: string) => (
        <Image
          alt={imageURL}
          src={imageURL}
          height={"50px"}
          style={{ width: "100px", objectFit: "cover" }}
          preview={{
            src: imageURL,
          }}
        />
      ),
    },
    {
      title: t("dashboard.url"),
      dataIndex: "url",
      key: "url",
      render: (text: any) => {
        return <UrlLink onClick={() => window.open(text)}>{text}</UrlLink>;
      },
    },

    {
      title: t("dashboard.created-date"),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: any, record: any, index: any) => {
        return <span>{convertTimestamp(text)}</span>;
      },
    },
    {
      title: t("dashboard.status"),
      dataIndex: "status",
      key: "status",
      align: "center" as "center",
      render: (text, record: any) => (
        <BaseTag
          color={
            text === "Active"
              ? record?.isArchive
                ? "warning"
                : "success"
              : "cyan"
          }
        >
          {text === "Active"
            ? record?.isArchive
              ? t("dashboard.blocked")
              : t("dashboard.active")
            : text}
        </BaseTag>
      ),
    },
    {
      title: t("dashboard.action"),
      dataIndex: "action",
      key: "action",
      align: "right" as "right",
      render: (text: any, record: any, index: any) => (
        <>
          <BaseToolTip title={t("new.edit-sticker")}>
            <BaseButton
              onClick={() => {
                selectStickerToEdit(record?.id);
              }}
              type="link"
            >
              <FaEdit
                style={{
                  height: "24px",
                  width: "24px",
                  color: "var(--skyblue)",
                }}
              />
            </BaseButton>
          </BaseToolTip>

          <BaseButton
            onClick={() => {
              setSelectedSticker(sticker.find((x) => x._id === record?.id));
              setIsDeleteModalVisible(true);
            }}
            type="link"
          >
            {record?.status === "New" ? (
              <BaseToolTip title={t("tooltip.delete-sticker")}>
                <FaTrashAlt
                  style={{ height: "24px", width: "24px", color: "var(--red)" }}
                />
              </BaseToolTip>
            ) : record?.isArchive ? (
              <BaseToolTip title={t("tooltip.unblock-sticker")}>
                <TbLockOpen
                  style={{
                    height: "24px",
                    width: "24px",
                    color: "var(--tairo)",
                  }}
                />
              </BaseToolTip>
            ) : (
              <BaseToolTip title={t("tooltip.block-sticker")}>
                <TbLock
                  style={{
                    height: "24px",
                    width: "24px",
                    color: "var(--orange)",
                  }}
                />
              </BaseToolTip>
            )}
          </BaseButton>
        </>
      ),
    },
  ];
  const dataSource = sticker?.map((item: any, index: any) => ({
    rowNo: pagination.limit * pagination.page + index + 1,
    key: item._id,
    id: item._id,
    url: item.URL,
    city: item?.city?.title,
    // isCompleted: "false",
    image: item.URL,
    createdAt: item.createdAt,
    status: item.status,
    isArchive: item.isArchive,
  }));

  useEffect(() => {
    // if (sticker.length === 0) {
    dispatch(
      store.Actions["update"]("sticker.pagination", {
        page: 0,
        status: "All",
        batch: "",
        archive: false,
        limit: pagination.limit,
      })
    );
    const { limit } = pagination;
    getAllSticker({
      page: 0,
      limit,
      status: "All",
      archive: false,
    });
    // }
  }, []);

  const handleRefectchAllSticker = async (status = "New") => {
    let archive = false;
    if (status === "Blocked") {
      archive = true;
      status = "Active";
    }
    handleTabs(status);

    getAllSticker({
      page: 0,
      limit: 10,
      status,
      archive,
    });
  };

  const updateBlockAndUnblockStatus = (actionType, id) => {
    dispatch(store.Actions.remove("sticker", id));
    refetchSticker();
    // if (
    //   ["blocked", "unblocked"].includes(actionType) &&
    //   pagination?.status === "All"
    // ) {
    //   const copy = JSON.parse(JSON.stringify(sticker));
    //   const updatedData = copy.filter((x) => {
    //     if (x._id === id) {
    //       const update = {
    //         ...x,
    //         isArchive: !x.isArchive,
    //       };
    //       return {
    //         ...update,
    //       };
    //     }
    //     return x;
    //   });
    //   dispatch(store.Actions["set"]("sticker.data", [...updatedData]));
    // } else {
    //   const updatedData = sticker.filter((x) => x._id !== id);
    //   dispatch(store.Actions["set"]("sticker.data", updatedData));
    // }
  };

  const handleDeleteSticker = async (stickerId) => {
    try {
      setIsLoading(true);

      const response = await request(stickers.deleteSticker(stickerId));

      if (response) {
        //for hooks use
        // handleRefectchAllSticker(selectedSticker?.status);
        // for redux use
        const actionType =
          selectedSticker?.status === "New"
            ? t("remain.deleted")
            : t("remain.blocked");
        updateBlockAndUnblockStatus(actionType, stickerId);
        const toastMessage = `${t("remain.sticker")}  ${
          selectedSticker?.status === "New"
            ? t("remain.deleted")
            : t("remain.blocked")
        }`;
        toast.success(toastMessage);
        closeDeleteModal();
      }
    } catch (error) {
      console.log("Delete Sticker:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleUnblockSticker = async (stickerId) => {
    try {
      setIsLoading(true);

      const response = await request(stickers.unblockSticker(stickerId));

      if (response) {
        // handleRefectchAllSticker("Blocked");
        // for redux use
        const actionType = "unblocked";
        updateBlockAndUnblockStatus(actionType, stickerId);
        toast.success(t("remain.toast-unblock-sticker"));

        closeDeleteModal();
      }
    } catch (error) {
      console.log("Unblock Sticker:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <HeaderWrapper title={t("dashboard.sticker")} />
      <ActionWrapper>
        <BaseTabs
          items={items}
          handleTabs={handleTabs}
          statusValue={pagination.status}
          // statusValue={activeTab}
        />
        <ActionButton
          handleAction={openModal}
          actionTitle={t("dashboard.add-sticker")}
        />
      </ActionWrapper>

      {isModalVisible && (
        <BaseModal
          title={
            selectedSticker?._id
              ? t("dashboard.edit-sticker")
              : t("dashboard.add-the-sticker")
          }
          open={isModalVisible}
          onCancel={closeModal}
          width={!isTablet ? "100vw" : "80vw"}
          footer={[]}
          bodyStyle={{ padding: "10px" }}
          zIndex={99999}
          centered
        >
          {isLoadingASingleSticker ? (
            <div
              style={{
                minHeight: "50vh",
              }}
            >
              <BaseTypography.Text type="warning">
                {t("remain.fetching-sticker-edit")}
              </BaseTypography.Text>
              <FlexColumnAlignCenter
                style={{
                  minHeight: "50vh",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Spin size="large" tip="Loading Sticker">
                  <div className="content" style={{ width: "150px" }} />
                </Spin>
              </FlexColumnAlignCenter>
            </div>
          ) : (
            <Setting
              closeModal={closeModal}
              refreshData={handleRefectchAllSticker}
              stickerEditData={selectedSticker}
            />
          )}
        </BaseModal>
      )}

      {isLargeMobile ? (
        <BaseTable
          dataSource={dataSource}
          columns={columns}
          pagination={false}
        />
      ) : (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {sticker?.map((sticker) => (
            <StickersTableRowCard
              key={sticker._id}
              url={sticker.URL}
              createdDate={sticker.createdAt}
              status={sticker.status}
            />
          ))}
        </div>
      )}
      <BasePagination
        pagination={pagination}
        handlePagination={handlePagination}
      />

      <ConfirmationMessagePopup
        isVisible={isDeleteModalVisible}
        onAction={() => {
          if (selectedSticker?.isArchive) {
            handleUnblockSticker(selectedSticker?._id);
          } else {
            handleDeleteSticker(selectedSticker?._id);
          }
        }}
        actionLabel={
          selectedSticker?.status === "New"
            ? t("modal.delete-sticker-title")
            : selectedSticker?.isArchive
            ? t("modal.unblock-sticker-title")
            : t("modal.block-sticker-title")
        }
        onClose={closeDeleteModal}
        title={`${
          selectedSticker?.status === "New"
            ? t("modal.delete-sticker-title")
            : selectedSticker?.isArchive
            ? t("modal.unblock-sticker-title")
            : t("modal.block-sticker-title")
        } ${t("modal.sticker")}`}
        actionIsDanger={selectedSticker?.status === "New"}
        isLoading={isLoading}
      >
        <BaseTypography.Text style={{ marginBottom: "20px" }}>
          {selectedSticker?.status === "New"
            ? t("modal.delete-sticker")
            : selectedSticker?.isArchive
            ? t("modal.unblock-sticker")
            : t("modal.block-sticker")}
        </BaseTypography.Text>
        <br />
        {selectedSticker?.status !== "New" && !selectedSticker?.isArchive && (
          <BaseTypography.Text type="secondary">
            {t("modal.confirm-block-sticker-message")}
          </BaseTypography.Text>
        )}
        <br />
        <br />
        <FlexColumnAlignCenter>
          <Image
            src={selectedSticker?.URL}
            style={{ maxHeight: "150px", margin: "0 auto" }}
          />
        </FlexColumnAlignCenter>
      </ConfirmationMessagePopup>
    </>
  );
};

export default Sticker;
