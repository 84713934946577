import { Badge } from "antd";
import BaseButton from "components/common/BaseButton";
import { BaseTypography } from "components/common/BaseTypography";
import useClickOutside from "hooks/useClickOutside";
import { useEffect, useState } from "react";
import { GoBell } from "react-icons/go";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import notifications from "shared/front/apis/notifications.api";
import { FlexColumnAlignCenter } from "utils/styles";
import { fullConvertTimestamp } from "utils/utils";
import Store from "../../store";
import request from "../../utils/request";
import * as S from "./styles";
//@ts-ignore
import { useTranslation } from "react-i18next";

type Props = {};

const PopupNotifications = (props: Props) => {
  const { t } = useTranslation();
  const [showNotifications, setShowNotifications] = useState(false);
  const [filteredNotifications, setFilteredNotifications] = useState([]);
  const [params, setParams] = useState({
    limit: 10,
    page: 0,
  });

  // SELECTOR
  // @ts-ignore
  const allNotifications: any[] = useSelector(Store.Select.notifications);
  const unreadNotificationsCount: { count: number } = useSelector(
    // @ts-ignore
    Store.Select.unreadNotificationsCount
  );

  const navigate = useNavigate();
  const toggleShowNotifications = () => {
    setShowNotifications((prev) => !prev);
  };
  const ref = useClickOutside(() => setShowNotifications(false));

  useEffect(() => {
    fetchAllNotications(params);
  }, [params]);

  const fetchAllNotications = async (params) => {
    try {
      request(notifications.getAllNotifications({ params }));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (Array.isArray(allNotifications) && allNotifications.length > 0) {
      const uniqueNotifications = allNotifications
        .reduce((acc, val) => {
          if (acc.findIndex((x) => x._id === val._id) > 0) {
            return acc;
          }
          acc.push(val);
          return acc;
        }, [])
        .sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt));
      setFilteredNotifications(uniqueNotifications);
    }
  }, [allNotifications]);

  const readNotification = async (notificationId) => {
    try {
      await request(notifications.readSingleNotification(notificationId));
    } catch (error) {
      console.log(error);
    }
  };

  const handleNotificationClick = async (event, notification) => {
    event.stopPropagation();
    if (!allNotifications || !Array.isArray(allNotifications)) return;
    const id = notification._id;
    const updatedNotifications = allNotifications.map((notif) => {
      if (id === notif._id) {
        return {
          ...notif,
          isRead: true,
        };
      }
      return notif;
    });

    Store.store.dispatch(
      Store.Actions.set("notifications", updatedNotifications)
    );
    Store.store.dispatch(
      Store.Actions.set("unreadNotificationsCount", {
        count:
          unreadNotificationsCount?.count - 1 >= 0
            ? unreadNotificationsCount?.count - 1
            : 0,
      })
    );

    readNotification(id);
    toggleShowNotifications();
    navigate(notification?.webRoute);
  };

  const handleNavigateViewAll = () => {
    toggleShowNotifications();
    navigate("/dashboard/notifications");
  };

  const renderNotification = (notification) => {
    return (
      <div
        className="notification"
        onClick={(e) => handleNotificationClick(e, notification)}
      >
        <div>
          <BaseTypography.Text className="message">
            {notification?.title}
          </BaseTypography.Text>
          <BaseTypography.Text type="secondary" className="date">
            {fullConvertTimestamp(notification?.createdAt)}
          </BaseTypography.Text>
        </div>
        {!notification?.isRead && <div className="unread-dot" />}
      </div>
    );
  };

  return (
    <div ref={ref}>
      <S.PopupNotificaionsStyled>
        <span onClick={toggleShowNotifications}>
          <Badge count={unreadNotificationsCount?.count} offset={[-4, 2]}>
            <GoBell className="notify-icon" size={25} />
          </Badge>
        </span>
        {showNotifications && (
          <div className="notification-container">
            <div className="notifications-wrapper">
              {Array.isArray(filteredNotifications) &&
              filteredNotifications.length > 0 ? (
                filteredNotifications.map((notice) =>
                  renderNotification(notice)
                )
              ) : (
                <div>{t("notification.no-notification")}</div>
              )}
            </div>
            {Array.isArray(allNotifications) &&
              allNotifications?.length > 4 && (
                <FlexColumnAlignCenter
                  style={{
                    margin: "0px 0px 0px",
                    borderTop: "1px solid #dbdbdb",
                  }}
                >
                  <BaseButton
                    style={{ margin: "10px 0px " }}
                    onClick={handleNavigateViewAll}
                  >
                    {t("notification.view-all")}
                  </BaseButton>
                </FlexColumnAlignCenter>
              )}
          </div>
        )}
      </S.PopupNotificaionsStyled>
    </div>
  );
};

export default PopupNotifications;
