import { Typography } from "antd";
import * as Styles from "./index.styles";
import { FeatureWrapper } from "./index.styles";
import { ReactNode } from "react";
// import ScrollReveal from "scrollreveal";
// @ts-ignore
import Jump from "react-reveal/Jump";

interface FeatureProps {
  direction?: "row" | "row-reverse";
  title: string;
  description: ReactNode;
  image: string;
}

const Feature = ({
  direction = "row",
  title,
  description,
  image,
}: FeatureProps) => {
  // ScrollReveal({

  //   distance: "60px",
  //   duration: 500,
  //   delay: 300,
  // });

  // ScrollReveal().reveal(".information", { delay: 500, origin: "bottom" });
  // ScrollReveal().reveal(".section2 .image", { delay: 500, origin: "top" });
  return (
    <Styles.FeatureWrapper flexDirection={direction}>
      <Styles.Container flexDirection={direction}>
        <Styles.Content>
          {/* <Jump> */}
          <Typography.Title level={3}>{title}</Typography.Title>
          {/* </Jump> */}
          <Typography.Text>{description}</Typography.Text>
        </Styles.Content>
        <Styles.Image src={image} alt="" />
      </Styles.Container>
    </Styles.FeatureWrapper>

    // <FeatureWrapper className="section2">
    //   <div className="container">
    //     <Typography.Title level={3} className="main-title">
    //       {title}
    //     </Typography.Title>
    //     <div className="content">
    //       <div className="image">
    //         <img src={image} alt="" className="img" />
    //       </div>
    //       <div className="information">
    //         <Typography.Text className="info">{description}</Typography.Text>
    //       </div>
    //     </div>
    //   </div>
    // </FeatureWrapper>
  );
};

export default Feature;
