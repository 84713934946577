export default [
  {
    title: "1. Informationen, die wir sammeln:",
    desc: [
      {
        title: "1.1",
        desc: "Persönliche Informationen: Wenn Sie mit unserem Kamera-Compliance-Projekt interagieren, können wir persönliche Informationen wie Ihren Namen, Ihre E-Mail-Adresse und Ihre Kontaktinformationen sammeln.",
      },
      {
        title: "1.2",
        desc: "Geräteinformationen: Wir können Informationen über das Gerät sammeln, das Sie verwenden, um auf unsere Dienste zuzugreifen, einschließlich Gerätetyp, Betriebssystem, Browsertyp und IP-Adresse.",
      },
      {
        title: "1.3",
        desc: "Standortinformationen: Mit Ihrer ausdrücklichen Zustimmung können wir Standortinformationen von Ihrem Gerät erfassen, um Ihnen genaue Compliance-Informationen in Bezug auf die Stadt und die Region, in der Sie sich befinden, bereitzustellen.",
      },
      {
        title: "1.4",
        desc: "Informationen zur Kamerascannung: Wenn Sie einen QR-Code an einem Pfosten mit einer Kamera scannen, können wir Daten im Zusammenhang mit dem Standort der Kamera, Datum und Uhrzeit des Scans sowie die abgerufenen Compliance-Informationen erfassen.",
      },
    ],
  },
  {
    title: "2. Wie wir Ihre Informationen verwenden:",
    desc: [
      {
        title: "2.1",
        desc: "Wir verwenden die gesammelten Informationen, um Ihnen genaue Compliance-Informationen in Bezug auf den Stadtteil zu bieten, in dem Sie den QR-Code gescannt haben.",
      },
      {
        title: "2.2",
        desc: "Wir können Ihre persönlichen Informationen verwenden, um Ihnen Updates, Benachrichtigungen und wichtige Informationen im Zusammenhang mit dem Kamera-Compliance-Projekt zu senden.",
      },
      {
        title: "2.3",
        desc: "Wir analysieren aggregierte und anonymisierte Daten zu statistischen Zwecken, die uns bei der Verbesserung unserer Dienste helfen können.",
      },
    ],
  },

  {
    title: "3. Informationen teilen:",
    desc: [
      {
        title: "3.1",
        desc: "Wir geben Ihre persönlichen Informationen ohne Ihre ausdrückliche Zustimmung nicht an Dritte zu Marketingzwecken weiter.",
      },
      {
        title: "3.2",
        desc: "Wir können Ihre Informationen mit unseren Dienstleistern teilen, die uns bei der Bereitstellung unserer Dienste unterstützen. Diese Dienstleister sind verpflichtet, die Vertraulichkeit Ihrer Informationen zu wahren.",
      },
      {
        title: "3.3",
        desc: "Wir können Ihre Informationen offenlegen, wenn dies gesetzlich vorgeschrieben ist oder um unsere Rechte, Privatsphäre, Sicherheit oder Eigentum zu schützen.",
      },
    ],
  },

  {
    title: "4. Datensicherheit:",
    desc: [
      {
        title: "4.1",
        desc: "Wir ergreifen Sicherheitsmaßnahmen zum Schutz Ihrer persönlichen Informationen vor unbefugtem Zugriff, Änderung, Offenlegung oder Zerstörung.",
      },
      {
        title: "4.2",
        desc: "Trotz unserer Bemühungen ist keine Online-Übertragung oder -Speicherung vollständig sicher. Daher können wir die absolute Sicherheit Ihrer Informationen nicht garantieren.",
      },
    ],
  },

  {
    title: "5. Ihre Auswahlmöglichkeiten:",
    desc: [
      {
        title: "5.1",
        desc: "Sie können sich vom Erhalt von Marketing-Kommunikationen von uns abmelden, indem Sie den Anweisungen zum Abbestellen folgen, die in der Kommunikation bereitgestellt werden.",
      },
      {
        title: "5.2",
        desc: "Sie können die Erfassung von Standortinformationen steuern, indem Sie die Einstellungen auf Ihrem Gerät anpassen.",
      },
    ],
  },

  {
    title: "6. Datenschutz für Kinder:",
    desc: [
      {
        title: "6.1",
        desc: "Unsere Dienste richten sich nicht an Personen unter [Alter]. Wir erfassen nicht wissentlich persönliche Informationen von Kindern.",
      },
    ],
  },

  {
    title: "7. Änderungen dieser Datenschutzrichtlinie:",
    desc: [
      {
        title: "7.1",
        desc: "Wir können diese Datenschutzrichtlinie von Zeit zu Zeit aktualisieren, um Änderungen in unseren Praktiken oder aus anderen betrieblichen, rechtlichen oder regulatorischen Gründen widerzuspiegeln.",
      },
      {
        title: "7.2",
        desc: "Wir werden auf bedeutende Änderungen dieser Datenschutzrichtlinie deutlich hinweisen.",
      },
    ],
  },
  {
    title: "8. Kontaktieren Sie uns:",
    desc: [
      {
        desc: "Wenn Sie Fragen, Bedenken oder Anfragen bezüglich dieser Datenschutzrichtlinie oder der Verwendung Ihrer persönlichen Informationen haben, kontaktieren Sie uns bitte unter [Kontakt-E-Mail].",
      },
    ],
  },
];
