import React from "react";

import { HomeStyled } from "containers/Terms/style";
import Content from "./Content";
import Footer from "containers/Home/Footer";
import MainHeader from "layouts/Main/MainHeader/MainHeader";
// import Footer from "containers/Terms/Footer";

const Blog = () => {
  return (
    <HomeStyled>
      <MainHeader />
      <Content />
      <Footer />
    </HomeStyled>
  );
};

export default Blog;
