import { AutoComplete, Checkbox, Image, Modal, Spin } from "antd";
import BaseButton from "components/common/BaseButton";
import BaseError from "components/common/BaseError";
import BaseInputs from "components/common/BaseInput/BaseInputs";
import { BaseSelect } from "components/common/BaseSelect";
import { BaseTypography } from "components/common/BaseTypography";
import { Form, Formik, FormikProps, FormikValues } from "formik";
import { useResponsive } from "hooks/useResponsive";
import { debounce } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { getI18n, useTranslation } from "react-i18next";
import { AiOutlineFilter } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import cameraApi from "shared/front/apis/camera";
import categoryApi from "shared/front/apis/category.api";
import groupApi from "shared/front/apis/group.api";
import publicViewApi from "shared/front/apis/publicView.api";
import QR from "shared/front/apis/qr.api";
import store from "store";
import request from "utils/request";
import { FlexEnd } from "utils/styles";
import { fullConvertTimestamp } from "utils/utils";
import * as yup from "yup";
import {
  OptionLabelStyled,
  QRStickerImageContainerStyled,
  QRStickerImageWrapperStyled,
  SelectedCameraContainerStyled,
  SelectedCameraStyled,
} from "./style";
import { FaCheck } from "react-icons/fa";
import ViewQrSticker from "components/StickerSelection/ViewQrSticker";

type Props = {
  isVisible: boolean;
  onClose: () => void;
  dataToEdit?: any;
};

const ADD_CAMERA = "add-camera";
const DONOT_ADD_CAMERA = "donot-add-camera";

const CreateGroup = ({ isVisible, onClose, dataToEdit }: Props) => {
  const { isTablet } = useResponsive();
  const { t } = useTranslation();
  const { language: selectedlang } = getI18n();
  const formikRef = useRef<FormikProps<FormikValues>>();
  // STATES
  const [loading, setLoading] = useState({
    category: false,
    subCategory: false,
    publicViews: false,
    qrCodes: false,
    camera: false,
    creatingGroup: false,
  });
  const [addCameraStatus, setAddCameraStatus] = useState(null);
  const [initialFormValues, setInitialFormValues] = useState({
    name: "",
    category: "",
    publicView: "",
    subCategory: [],
    QRCode: [],
    camera: [],
  });
  const [selectedCameras, setSelectedCameras] = useState([]);

  const validationSchema = yup.object().shape({
    name: yup.string().required(t("group.group-name-required")),
    category: yup.string().required(t("group.category-required")),
    publicView: yup.string().required(t("group.public-page-required")),
    subCategory: yup.array().of(yup.string()).min(0),
    QRCode: yup.array().of(yup.string()).min(0, t("group.qr-min-required")),
    camera: yup.array().of(yup.string()).min(0),
  });

  // SELECTORS
  const groupsData = useSelector((state: any) => state?.groups?.data);
  const groupsPagination = useSelector(
    (state: any) => state?.groups?.pagination
  );

  const categories = useSelector((state: any) => state?.categories?.data);
  const categoriesPagination = useSelector(
    (state: any) => state?.categories?.pagination
  );
  const subCategories = useSelector((state: any) => state?.subCategories?.data);
  const subCategoriesPagination = useSelector(
    (state: any) => state?.subCategories?.pagination
  );
  const publicViews = useSelector((state: any) => state?.publicViews?.data);
  const publicViewsPagination = useSelector(
    (state: any) => state?.publicViews?.pagination
  );
  const qrCodes = useSelector((state: any) => state?.qr?.data);
  const qrCodesPagination = useSelector((state: any) => state?.qr?.pagination);

  const qrBatch = useSelector((state: any) => state?.qrBatch?.data);
  const qrBatchPagination = useSelector(
    (state: any) => state?.qrBatch?.pagination
  );

  const camera = useSelector((state: any) => state?.camera?.data);
  const cameraPagination = useSelector(
    (state: any) => state?.camera?.pagination
  );
  const dispatch = useDispatch();

  const delayedFetchPublicView = useCallback(
    debounce((params) => {
      fetchAllPublicPages(params);
    }, 500),
    []
  );

  const delayedFetchCategory = useCallback(
    debounce((params) => {
      fetchAllCategory(params);
    }, 500),
    []
  );

  const delayedFetchCamera = useCallback(
    debounce((params) => {
      fetchAllCamera(params);
    }, 500),
    []
  );

  const initialParams = {
    limit: 100,
    page: 0,
  };

  useEffect(() => {
    setInitialFormValues({
      name: "",
      category: "",
      publicView: "",
      subCategory: [],
      QRCode: [],
      camera: [],
    });
  }, [dataToEdit]);

  useEffect(() => {
    // FOR EDIT FUNCTIONALITY
    if (dataToEdit?._id) {
      populateInitialValues({
        label: "name",
        value: dataToEdit?.name,
      });

      if (Array.isArray(publicViews) && publicViews.length > 0) {
        const value = publicViews.find(
          (x) => x._id === dataToEdit?.publicView?._id
        );

        if (!!value) {
          populateInitialValues({ label: "publicView", value: value._id });
          populateInitialValues({
            label: "publicViewValue",
            value: getSelectedLanguageTitle(dataToEdit?.publicView?.details),
          });
        } else {
          onSearchPublicView(
            getSelectedLanguageTitle(dataToEdit?.publicView?.details)
          );
        }
      }
    }
  }, [dataToEdit, publicViews]);

  useEffect(() => {
    // FOR EDIT FUNCTIONALITY
    if (dataToEdit?._id) {
      if (Array.isArray(categories) && categories.length > 0) {
        const value = categories.find(
          (x) => x._id === dataToEdit?.category._id
        );
        if (!!value) {
          fetchSubCategory(value._id, { page: 0, limit: 50 });
          populateInitialValues({
            label: "category",
            value: value._id,
          });

          populateInitialValues({
            label: "categoryValue",
            value: value.name,
          });
        } else {
          onSearchCategory(dataToEdit?.category?.name);
        }
      }
      if (Array.isArray(subCategories) && subCategories.length > 0) {
        const value = subCategories.find((x) => {
          if (dataToEdit?.subCategory && dataToEdit?.subCategory[0]) {
            return x._id === dataToEdit?.subCategory[0]?._id;
          }
          return false;
        });
        if (!!value) {
          populateInitialValues({
            label: "subCategory",
            value: [value._id],
          });
        }
      }
    }
  }, [dataToEdit, categories]);

  useEffect(() => {
    // FOR EDIT FUNCTIONALITY
    if (dataToEdit?._id) {
      if (Array.isArray(subCategories) && subCategories.length > 0) {
        const value = subCategories.find((x) => {
          if (dataToEdit?.subCategory && dataToEdit?.subCategory[0]) {
            return x._id === dataToEdit?.subCategory[0]._id;
          }
          return false;
        });
        if (!!value) {
          populateInitialValues({
            label: "subCategory",
            value: [value._id],
          });
        }
      }
    }
  }, [dataToEdit, subCategories]);

  useEffect(() => {
    // FOR EDIT FUNCTIONALITY
    if (dataToEdit?._id && formikRef?.current) {
      if (Array.isArray(camera) && camera.length > 0) {
        handleCameraSelection(
          dataToEdit?.camera
            .map((cam) => ({ ...cam, id: cam?._id }))
            .filter((x) => !x.isArchive),
          formikRef?.current?.setFieldValue,
          []
        );
      }
    }
  }, [dataToEdit, camera, formikRef]);

  useEffect(() => {
    // FOR EDIT FUNCTIONALITY
    if (dataToEdit?._id) {
      if (Array.isArray(qrBatch) && qrBatch?.length > 0) {
        handleFetchQrOnValueChange(dataToEdit?.QRCode[0]?.batch, "batch");
      }
      const selectedQRs = dataToEdit?.QRCode?.map((val) => val?._id);
      populateInitialValues({
        label: "QRCode",
        value: selectedQRs,
      });
    }

    // FOR ADD FUNCTIONALITY
    if (Array.isArray(qrBatch) && qrBatch?.length > 0 && !dataToEdit?._id) {
      handleFetchQrOnValueChange(qrBatch[0], "batch");
    }
  }, [dataToEdit, qrBatch]);

  useEffect(() => {
    if (isVisible) {
      dispatch(store.Actions.set("publicViews.pagination", initialParams));
      fetchAllPublicPages(initialParams);

      dispatch(store.Actions.set("categories.pagination", initialParams));
      fetchAllCategory(initialParams);

      dispatch(store.Actions.set("camera.pagination", initialParams));
      fetchAllCamera(initialParams);
      dispatch(
        store.Actions.update("qrBatch.pagination", {
          limit: 50,
          page: 0,
        })
      );
      fetchAllBatch({ limit: 50, page: 0 });
      dispatch(store.Actions.set("qr.pagination", initialParams));
      fetchAllQRCode(initialParams);
    }
  }, [isVisible]);

  const populateInitialValues = async ({ label, value }) => {
    setInitialFormValues((prev) => ({ ...prev, [label]: value }));
  };

  const fetchAllPublicPages = async (params) => {
    setLoading((prev) => ({ ...prev, publicViews: true }));
    const actionType = publicViewsPagination?.page === 0 ? "set" : "update";
    await request(
      publicViewApi.getAllPublicViews(actionType, {
        limit: params.limit,
        page: params.page,
        title: params.title,
        lang: params.lang,
      })
    );

    setLoading((prev) => ({ ...prev, publicViews: false }));
  };

  const fetchAllCategory = async (params) => {
    setLoading((prev) => ({ ...prev, category: true }));
    const actionType = categoriesPagination?.page === 0 ? "set" : "update";
    await request(
      categoryApi.getAllCategories(actionType, {
        limit: params.limit,
        page: params.page,
        search: params.search,
      })
    );
    setLoading((prev) => ({ ...prev, category: false }));
  };

  const fetchSubCategory = async (categoryId, params) => {
    if (!categoryId) {
      console.log("Category is not selected.");
      return;
    }
    setLoading((prev) => ({ ...prev, subCategory: true }));

    const actionType = subCategoriesPagination?.page === 0 ? "set" : "update";
    await request(
      categoryApi.getAllSubCategories(categoryId, actionType, {
        limit: params.limit,
        page: params.page,
      })
    );
    setLoading((prev) => ({ ...prev, subCategory: false }));
  };
  const fetchAllQRCode = async (params) => {
    setLoading((prev) => ({ ...prev, qrCodes: true }));

    const actionType = qrCodesPagination?.page === 0 ? "set" : "update";
    await request(
      QR.getAllQRCodes(actionType, {
        limit: params.limit,
        page: params.page,
        batch: params?.batch ?? null,
        cameraId: params?.cameraId ?? null,
      })
    );
    setLoading((prev) => ({ ...prev, qrCodes: false }));
  };

  const fetchAllBatch = async (params) => {
    setLoading((prev) => ({ ...prev, batch: true }));

    await request(QR.getBatch({ limit: params?.limit, page: params?.page }));

    setLoading((prev) => ({ ...prev, batch: false }));
  };
  const fetchAllCamera = async (params) => {
    setLoading((prev) => ({ ...prev, camera: true }));

    const actionType = "set";
    // const actionType = cameraPagination?.page === 0 ? "set" : "update";
    await request(
      cameraApi.getAllCamera(actionType, {
        limit: params.limit,
        page: params.page,
        search: params.search,
      })
    );
    setLoading((prev) => ({ ...prev, camera: false }));
  };

  const clearFormFields = () => {
    formikRef.current.handleReset();
  };

  const handleCancel = () => {
    onClose();
    clearFormFields();
    setAddCameraStatus(null);
    setInitialFormValues({
      name: "",
      category: "",
      publicView: "",
      subCategory: [],
      QRCode: [],
      camera: [],
    });
    setSelectedCameras([]);
    dispatch(
      store.Actions.update("qr.pagination", {
        ...qrCodesPagination,
        batch: null,
        cameraId: null,
      })
    );
  };

  const onCreateGroup = async (values) => {
    try {
      setLoading((prev) => ({ ...prev, creatingGroup: true }));
      let response = null;
      if (dataToEdit?._id) {
        response = await request(groupApi.updateGroup(dataToEdit?._id, values));
      } else {
        response = await request(groupApi.createCategory(values));
      }
      if (response) {
        if (dataToEdit?._id) {
          dispatch(
            store.Actions.set("groups.data", [
              response,
              ...groupsData.filter((x) => x._id !== dataToEdit?._id),
            ])
          );
        } else {
          // dispatch(store.Actions.update("groups.data", [response]));
          dispatch(store.Actions.set("groups.data", [response, ...groupsData]));
          dispatch(
            store.Actions.update("groups.pagination", {
              count: groupsPagination?.count + 1,
            })
          );
        }
        handleCancel();
        if (dataToEdit?._id) {
          toast.success(
            `${values?.name} group is ${
              dataToEdit?._id ? "updated" : "created"
            } successfully.`
          );
        } else {
          toast.success(
            `${values?.name} ${t("group.group-create-successfully")}`
          );
        }
      }
    } catch (error) {
      console.log("Creating Group :", error);
      if (typeof error === "string") {
        toast.error(error);
      }
    } finally {
      setLoading((prev) => ({ ...prev, creatingGroup: false }));
    }
  };

  const getSelectedLanguageTitle = (details) => {
    if ((Array.isArray(details) && details.length === 0) || !details) {
      return "N/A";
    }

    const selectedLangTitle = details.find((x) => x?.lang === selectedlang);
    if (!!selectedLangTitle?.title) {
      return selectedLangTitle?.title;
    }
    return details[0].title;
  };

  const handleAddCameraStatusChange = (value) => {
    setAddCameraStatus(value);
    dispatch(store.Actions.set("qr.data", []));
    if (value === ADD_CAMERA) {
      dispatch(store.Actions.set("camera.pagination", initialParams));
      fetchAllCamera(initialParams);
    } else {
    }
  };

  const handleFetchQrOnValueChange = (
    value: string,
    type: "batch" | "camera"
  ): any => {
    let paramsToFetchQR: any = {
      page: 0,
      limit: 50,
    };
    if (type === "batch") {
      paramsToFetchQR = { ...paramsToFetchQR, batch: value };
    }
    if (type === "camera") {
      paramsToFetchQR = { ...paramsToFetchQR, cameraId: value };
    }
    dispatch(
      store.Actions.update("qr.pagination", {
        ...qrCodesPagination,
        ...paramsToFetchQR,
      })
    );
    fetchAllQRCode({
      ...paramsToFetchQR,
    });
  };

  const handleQrImagesSelection = (
    values,
    setFieldValues,
    selectedValues = []
  ) => {
    if (Array.isArray(values)) {
      if (values.length === selectedValues.length) {
        setFieldValues("QRCode", []);
        return;
      }
      setFieldValues(
        "QRCode",
        values.reduce((acc, val) => {
          acc.push(val?._id);
          return acc;
        }, [])
      );
      return;
    }
    const updatedList = selectedValues.includes(values?._id)
      ? selectedValues.filter((x) => x !== values?._id)
      : [...selectedValues, values?._id];
    setFieldValues("QRCode", updatedList);
  };

  const getCameraDetailsFromId = (id) => {
    return camera.find((x) => x._id === id);
  };

  const handleCameraSelection = (
    values,
    setFieldValues,
    selectedValues = []
  ) => {
    if (Array.isArray(values)) {
      setSelectedCameras([]);
      if (values.length === selectedValues.length) {
        setFieldValues("camera", []);
        return;
      }
      setFieldValues(
        "camera",
        values.reduce((acc, val) => {
          setSelectedCameras((prev) => [
            ...prev,
            getCameraDetailsFromId(val.id),
          ]);
          acc.push(val?.id);
          return acc;
        }, [])
      );
      return;
    }
    const itExists = selectedValues.includes(values?.id);
    setSelectedCameras((prev) =>
      itExists
        ? prev.filter((x) => x?._id !== values?.id)
        : [...prev, getCameraDetailsFromId(values?.id)]
    );
    const updatedList = itExists
      ? selectedValues.filter((x) => x !== values?.id)
      : [...selectedValues, values?.id];
    setFieldValues("camera", updatedList);
  };

  const onSearchPublicView = (text) => {
    delayedFetchPublicView({
      ...initialParams,
      title: text,
      lang: selectedlang,
    });
  };

  const onSearchCategory = (text) => {
    delayedFetchCategory({
      ...initialParams,
      search: text,
    });
  };
  const onSearchCamera = (text) => {
    delayedFetchCamera({
      ...initialParams,
      search: text,
    });
  };

  const publicViewOptions = () => {
    const options = [];
    if (loading?.publicViews) {
      options.push({
        label: <Spin size="small" />,
        value: null,
        disabled: true,
      });
    }

    if (Array.isArray(publicViews) && publicViews?.length > 0) {
      return [
        ...options,
        ...publicViews.map((val) => ({
          value: getSelectedLanguageTitle(val?.details),
          label: getSelectedLanguageTitle(val?.details),
          id: val._id,
        })),
      ];
    }
    return [
      {
        value: null,
        label: t("group.no-public-page-found"),
        disabled: true,
      },
    ];
  };
  const categoryOptions = () => {
    const options = [];
    if (loading?.category) {
      options.push({
        label: <Spin size="small" />,
        value: null,
        disabled: true,
      });
    }

    if (Array.isArray(categories) && categories?.length > 0) {
      return [
        ...options,
        ...categories.map((val) => ({
          value: val?.name,
          label: val?.name,
          id: val._id,
        })),
      ];
    }
    return [
      {
        value: null,
        label: t("group.no-category-found"),
        disabled: true,
      },
    ];
  };

  const cameraOptions = () => {
    const options = [];
    if (loading?.camera) {
      options.push({
        label: <Spin size="small" />,
        value: null,
        disabled: true,
      });
    }

    if (Array.isArray(camera) && camera?.length > 0) {
      return [
        ...options,
        ...camera.map((val) => ({
          label: (
            <OptionLabelStyled
              className={
                selectedCameras?.find((x) => x?._id === val?._id)
                  ? "selected"
                  : ""
              }
            >
              <div>
                {val?.name}
                <span className="selected-text">
                  &ensp;
                  <FaCheck />
                </span>
              </div>
              <div>{val?.address || `${t("dashboard.address")}: N/A`}</div>
            </OptionLabelStyled>
          ),
          value: val?.address || val?.name,
          id: val._id,
        })),
      ];
    }
    return [
      {
        value: null,
        label: t("group.no-camera-found"),
        disabled: true,
      },
    ];
  };

  return (
    <Modal
      open={isVisible}
      onCancel={handleCancel}
      title={
        dataToEdit?._id ? t("group.update-group") : t("group.create-group")
      }
      width={!isTablet ? "100vw" : "80vw"}
      footer={null}
      centered
    >
      <Formik
        innerRef={formikRef}
        validateOnMount
        enableReinitialize
        initialValues={initialFormValues}
        validationSchema={validationSchema}
        onSubmit={async (values, {}) => {
          delete values.publicViewValue;
          delete values.categoryValue;
          await onCreateGroup(values);
        }}
        validateOnBlur={false}
      >
        {({ errors, values, setFieldValue, isValid, touched }: any) => {
          return (
            <Form>
              <BaseInputs
                label={t("group.name")}
                name={"name"}
                type={"text"}
                value={values["name"]}
                placeholder={t("group.name")}
              />
              <>
                <BaseTypography>{t("group.public-page")}</BaseTypography>
                <AutoComplete
                  value={values["publicViewValue"]}
                  options={publicViewOptions()}
                  style={{ width: "100%" }}
                  onSelect={(value, key) => {
                    setFieldValue("publicView", key?.id);
                    setFieldValue("publicViewValue", value);
                  }}
                  onSearch={(text) => onSearchPublicView(text)}
                  onChange={(value, key) => {
                    setFieldValue("publicView", key?.id);
                    setFieldValue("publicViewValue", value);
                  }}
                  placeholder={t("remain.search")}
                />
                {touched["publicView"] && errors["publicView"] && (
                  <BaseError>{errors["publicView"]}</BaseError>
                )}
              </>

              <br />
              <br />

              <>
                <BaseTypography> {t("category.category")}</BaseTypography>
                <AutoComplete
                  value={values["categoryValue"]}
                  style={{ width: "100%" }}
                  options={categoryOptions()}
                  onSelect={(value, key) => {
                    fetchSubCategory(key?.id, { page: 0, limit: 50 });
                    setFieldValue("category", key?.id);
                    setFieldValue("categoryValue", value);
                  }}
                  onSearch={(text) => onSearchCategory(text)}
                  onChange={(value, key) => {
                    fetchSubCategory(key?.id, { page: 0, limit: 50 });
                    setFieldValue("category", key?.id);
                    setFieldValue("categoryValue", value);
                  }}
                  placeholder={t("remain.search")}
                />
                {touched["category"] && errors["category"] && (
                  <BaseError>{errors["category"]}</BaseError>
                )}
              </>
              <br />
              <br />
              {!loading.subCategory && values["category"] ? (
                Array.isArray(subCategories) && subCategories?.length > 0 ? (
                  <>
                    <BaseSelect
                      label={t("category.sub-category")}
                      name={"subCategory"}
                      value={values["subCategory"]}
                      style={{ width: "100%" }}
                      options={subCategories.map((val) => ({
                        value: val._id,
                        label: val?.name,
                      }))}
                      onChange={(value) =>
                        setFieldValue("subCategory", [value])
                      }
                      placeholder={t("group.select")}
                    />
                    {touched["subCategory"] && errors["subCategory"] && (
                      <BaseError>{errors["subCategory"]}</BaseError>
                    )}
                    <br />
                    <br />
                  </>
                ) : (
                  <>
                    <BaseTypography.Text>
                      {t("group.no-sub-category")}
                    </BaseTypography.Text>
                    <br />
                    <br />
                  </>
                )
              ) : (
                loading.subCategory && (
                  <>
                    <BaseTypography.Text>
                      {t("group.getting-sub-category")} &emsp;{" "}
                      <Spin size="small" />
                    </BaseTypography.Text>
                    <br />
                    <br />
                  </>
                )
              )}

              <>
                <BaseTypography> {t("group.camera")}</BaseTypography>
                <AutoComplete
                  value={values["cameraValue"]}
                  style={{ width: "100%" }}
                  options={cameraOptions()}
                  onSelect={(value, key) => {
                    handleCameraSelection(key, setFieldValue, values["camera"]);
                    setFieldValue("cameraValue", "");
                  }}
                  onSearch={(text) => onSearchCamera(text)}
                  onChange={(value, key) => {
                    // handleCameraSelection(key, setFieldValue, values["camera"]);
                    setFieldValue("cameraValue", value);
                  }}
                  placeholder={t("remain.search")}
                />
                {touched["camera"] && errors["camera"] && (
                  <BaseError>{errors["camera"]}</BaseError>
                )}

                {Array.isArray(selectedCameras) &&
                  selectedCameras.length > 0 && (
                    <div style={{ marginTop: "10px" }}>
                      <BaseTypography.Text>
                        {t("remain.selected-cameras")}
                      </BaseTypography.Text>
                      <SelectedCameraContainerStyled>
                        {selectedCameras.map((cam) => (
                          <SelectedCameraStyled>
                            <div className="camera-row">
                              <BaseTypography.Text className="title">
                                {t("dashboard.name")}
                              </BaseTypography.Text>
                              <BaseTypography.Text className="value">
                                {cam?.name ?? "N/A"}
                              </BaseTypography.Text>
                            </div>
                            <div className="camera-row">
                              <BaseTypography.Text className="title">
                                {t("dashboard.address")}
                              </BaseTypography.Text>
                              <BaseTypography.Text className="value">
                                {cam?.address}
                              </BaseTypography.Text>
                            </div>
                            <div className="camera-row">
                              <BaseTypography.Text className="title">
                                {t("dashboard.longitude")}
                              </BaseTypography.Text>
                              <BaseTypography.Text className="value">
                                {cam?.longitude}
                              </BaseTypography.Text>
                            </div>
                            <div className="camera-row">
                              <BaseTypography.Text className="title">
                                {t("dashboard.latitude")}
                              </BaseTypography.Text>
                              <BaseTypography.Text className="value">
                                {cam?.latitude}
                              </BaseTypography.Text>
                            </div>
                            <div className="camera-row">
                              <BaseTypography.Text className="title">
                                {t("remain.created-by")}
                              </BaseTypography.Text>
                              <BaseTypography.Text className="value">
                                {cam?.isCreateAutomatic ? "System" : "Admin"}
                              </BaseTypography.Text>
                            </div>
                            <div className="camera-row">
                              <BaseTypography.Text className="title">
                                {t("dashboard.created-date")}
                              </BaseTypography.Text>
                              <BaseTypography.Text className="value">
                                {fullConvertTimestamp(cam?.createdAt)}
                              </BaseTypography.Text>
                            </div>
                            {cam?._id && (
                              <FlexEnd>
                                <BaseButton
                                  style={{
                                    padding: "0px 5px",
                                    fontSize: "12px",
                                  }}
                                  danger
                                  onClick={() =>
                                    handleCameraSelection(
                                      { ...cam, id: cam?._id },
                                      setFieldValue,
                                      values["camera"]
                                    )
                                  }
                                >
                                  {t("remain.remove")}
                                </BaseButton>
                              </FlexEnd>
                            )}
                          </SelectedCameraStyled>
                        ))}
                      </SelectedCameraContainerStyled>
                    </div>
                  )}
                <br />
                <br />
              </>
              <div
                style={{
                  margin: "0px 0px 10px 0px",
                }}
              >
                <div
                  style={{
                    border: "1px solid #dbdbdb",
                    width: "fit-content",
                    padding: "0px 10px",
                    borderRadius: "6px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <label htmlFor="filterQrBatch">
                    <div
                      style={{
                        borderRight: "1px solid #dbdbdb",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <BaseTypography>
                        {t("remain.filter-batch")}
                      </BaseTypography>
                      <AiOutlineFilter
                        style={{
                          fontSize: "18px",
                          margin: "0px 10px 0px 3px",
                        }}
                      />
                    </div>
                  </label>

                  <BaseSelect
                    id="filterQrBatch"
                    placeholder="Filter Batch"
                    showSearch
                    options={qrBatch.map((val) => ({
                      value: val,
                      label: val,
                    }))}
                    value={qrCodesPagination?.batch}
                    onChange={(val) => {
                      setFieldValue("QRCode", []);
                      handleFetchQrOnValueChange(val, "batch");
                    }}
                    bordered={false}
                  />
                </div>
              </div>

              {Array.isArray(qrCodes) &&
                qrCodes?.length > 0 &&
                (loading?.qrCodes ? (
                  <BaseTypography.Text>
                    {t("group.getting-qr-codes")} &emsp; <Spin size="small" />
                  </BaseTypography.Text>
                ) : (
                  <>
                    <BaseTypography.Text
                      style={{ marginBottom: "5px", display: "block" }}
                    >
                      {t("group.click-on-image")}
                    </BaseTypography.Text>
                    <Checkbox
                      checked={values["QRCode"].length === qrCodes.length}
                      onChange={() => {
                        handleQrImagesSelection(
                          qrCodes,
                          setFieldValue,
                          values["QRCode"]
                        );
                      }}
                      style={{ marginBottom: "6px" }}
                    >
                      {t("group.select-all")}
                    </Checkbox>

                    <QRStickerImageContainerStyled>
                      {qrCodes.map((qr, index) => {
                        if (!!qr?.group && !dataToEdit._id) {
                          return null;
                        }
                        if (
                          dataToEdit._id &&
                          qr?.group &&
                          qr?.group?._id !== dataToEdit._id
                        ) {
                          return null;
                        }
                        return (
                          <QRStickerImageWrapperStyled
                            key={qr?._id}
                            className={`${
                              values["QRCode"].includes(qr?._id)
                                ? t("group.selected")
                                : ""
                            }`}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleQrImagesSelection(
                                qr,
                                setFieldValue,
                                values["QRCode"]
                              );
                            }}
                          >
                            <ViewQrSticker
                              disablePreview
                              frontEndURL={qr?.frontendURL}
                              stickerDimensions={qr?.sticker?.stickerDimension}
                              stickerURL={qr?.sticker?.URL}
                              imgSize="center"
                              style={{
                                height: "100%",
                                width: "100%",
                                objectFit: "contain",
                              }}
                            />
                          </QRStickerImageWrapperStyled>
                        );
                      })}
                    </QRStickerImageContainerStyled>
                    {qrCodes?.filter((qr) => {
                      if (!!qr?.group && !dataToEdit._id) {
                        return false;
                      }
                      if (
                        dataToEdit._id &&
                        qr?.group &&
                        qr?.group?._id !== dataToEdit._id
                      ) {
                        return false;
                      }
                      return true;
                    })?.length === 0 && (
                      <BaseTypography>
                        No qr on this batch without group.
                      </BaseTypography>
                    )}

                    {errors["QRCode"] && (
                      <BaseError>{errors["QRCode"]}</BaseError>
                    )}
                    <br />
                    <br />
                  </>
                ))}
              <FlexEnd>
                <BaseButton onClick={handleCancel}>
                  {t("group.cancel")}
                </BaseButton>
                <BaseButton
                  htmlType="submit"
                  type="primary"
                  style={{ background: "var(--primary)", marginLeft: "10px" }}
                  loading={loading?.creatingGroup}
                >
                  {dataToEdit?._id
                    ? t("group.update-group")
                    : t("group.create-group")}
                </BaseButton>
              </FlexEnd>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default CreateGroup;
