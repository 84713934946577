import React, { useEffect } from "react";
import axios from "axios";
import { deleteToken, deleteUser } from "../services/localStorage";
import { Navigate } from "react-router";
import { useRoot } from "../RootProvider";
import store from "store";

const Logout = () => {
  const { setAuth } = useRoot();
  useEffect(() => {
    deleteUser();
    deleteToken();
    setAuth(undefined);
    delete axios.defaults.headers.common["Authorization"];
    delete axios.defaults.headers.Authorization;
    store.store.dispatch(store.Actions.set("loginDetails", {}));
    store.store.dispatch(store.Actions.resetRedux());
  });
  return <Navigate to="/auth/login" replace />;
};

export default Logout;
