import React from "react";
import { ScanInfoStyled } from "./styled";
import { convertTimestamp } from "utils/utils";
import { useTranslation } from "react-i18next";

type Props = {};

const ScanInfo = ({ activeQrData }) => {
  const { t } = useTranslation();
  const qrDetails = activeQrData?.QRDetails;
  return (
    <ScanInfoStyled>
      <div className="info-wrapper">
        <div className="label">{t("dashboard.total-scans")}</div>
        <div className="value">
          {activeQrData?.totalScan}
          <span className="sub-value">/ {t("new.city")}</span>
        </div>
      </div>
      {/* <div className="info-wrapper">
        <div className="label">Downloads</div>
        <div className="value">2</div>
      </div> */}
      <div className="info-wrapper">
        <div className="label">{t("dashboard.created-date")}</div>
        <div className="value">{convertTimestamp(qrDetails?.createdAt)}</div>
      </div>
      <div className="info-wrapper">
        <div className="label">{t("dashboard.updated-date")}</div>
        <div className="value">{convertTimestamp(qrDetails?.updatedAt)}</div>
      </div>
    </ScanInfoStyled>
  );
};

export default ScanInfo;
