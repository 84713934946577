import { Steps } from "antd";
import useAPI from "hooks/useApi";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import authApi from "shared/front/apis/auth.api";
import Swipers from "./Swiper";
import { BaseCard } from "components/common/BaseCard";
import BaseLoading from "components/common/BaseSpin";
import { BaseTypography } from "components/common/BaseTypography";
import styled from "styled-components";
import { StepProps } from "antd/lib";
//@ts-ignore
import { useTranslation } from "react-i18next";
const OnBoarding = () => {
  const [getRequest, { loading: loader }] = useAPI();
  const onBoardingData = useSelector((state: any) => state.onBoardingDetails);
  const { t } = useTranslation();
  const [current, setCurrent] = useState(0);
  const [allTrueState, setAllTrueState] = useState<Boolean>(true);

  const getOnBoardingDetails = async () => {
    await getRequest(authApi.getOnboardingDetails());
  };

  console.log("the on boarding steps", onBoardingData);

  const steps = [
    {
      title: t("dashboard.sticker"),
      id: 0,
      buttontext: t("dashboard.add-sticker"),
      iscompleted: onBoardingData?.sticker,
      description: t("dashboard.add-sticker-must-be-on-svg-format"),
      disabled: false,
    },
    {
      title: t("dashboard.operator"),
      id: 5,
      buttontext: t("dashboard.add-operator"),
      iscompleted: onBoardingData?.operator,
      description: t("dashboard.add-operator-with-their-necessary-information"),
      disabled: false,
    },
    {
      title: t("dashboard.public-page"),
      id: 1,
      buttontext: t("dashboard.add-public-page"),
      iscompleted: onBoardingData?.publicPage,
      description: t("dashboard.make-public-page-as-your-requirement"),
      disabled: false,
    },
    {
      title: t("dashboard.camera"),
      id: 2,
      buttontext: t("dashboard.add-camera"),
      iscompleted: onBoardingData?.camera,
      description: t(
        "dashboard.add-camera-location-and-its-all-required-information"
      ),
      disabled: false,
    },
    {
      title: t("dashboard.group"),
      id: 3,
      buttontext: t("dashboard.add-group-page"),
      iscompleted: onBoardingData?.group,
      description: t(
        "dashboard.make-group-the-link-all-the-previous-inforamtion"
      ),
      disabled: onBoardingData?.group,
    },

    {
      title: t("dashboard.qr"),
      id: 4,
      buttontext: t("dashboard.generate-qr"),
      iscompleted: onBoardingData?.QRCode,
      description: t("dashboard.generate-qr-using-the-sticker-of-any-number"),
      disabled:
        onBoardingData?.sticker &&
        (onBoardingData?.publicPage || onBoardingData?.group),
    },
  ];

  useEffect(() => {
    getOnBoardingDetails();
  }, []);

  useEffect(() => {
    setCurrent(0);
    if (onBoardingData) {
      const allTrue = Object.values(onBoardingData).every(
        (value) => value === true
      );
      if (allTrue) {
        setAllTrueState(true);
      } else {
        setAllTrueState(false);
      }
    }
  }, [onBoardingData]);

  const sortedSteps = steps.sort((a, b) => {
    // First, compare based on item.iscompleted
    if (a.iscompleted && !b.iscompleted) {
      return -1; // a comes first
    } else if (!a.iscompleted && b.iscompleted) {
      return 1; // b comes first
    }

    // If item.iscompleted is the same, compare based on !item.iscompleted
    if (!a.iscompleted && !b.iscompleted) {
      return 0; // No change in order
    }

    // Finally, compare based on item.disabled
    if (a.disabled && !b.disabled) {
      return -1; // a comes first
    } else if (!a.disabled && b.disabled) {
      return 1; // b comes first
    }

    return 0; // No change in order if all properties are equal
  });

  return (
    <>
      {loader && !onBoardingData ? (
        <BaseLoading />
      ) : (
        !allTrueState && (
          <BaseCard style={{ marginBottom: "30px" }}>
            <BaseTypography.Title level={5}>
              {t("dashboard.set-up-your-onboarding-steps")}
            </BaseTypography.Title>
            <BaseTypography.Text>
              {t("dashboard.complete-these-steps-to-get established")}
            </BaseTypography.Text>
            <StepStyled
              current={sortedSteps.filter((item) => item.iscompleted).length}
              items={sortedSteps.map(({ description, ...rest }) => rest)}
            />
            <Swipers stepsCard={steps} />
          </BaseCard>
        )
      )}
    </>
  );
};

export default OnBoarding;

const StepStyled = styled(Steps)<{ current: any; items: any }>`
  margin-top: 10px;
  margin-bottom: 15px;
  .ant-steps-item-active {
    .ant-steps-item-icon {
      background-color: var(--primary);
      border-color: var(--primary);
      .ant-steps-icon {
        color: var(--white);
      }
    }
  }
  .ant-steps-item-process {
    .ant-steps-item-icon {
      background-color: var(--primary);
      border-color: var(--primary);
      .ant-steps-icon {
        color: var(--white);
      }
    }
  }

  .ant-steps-item-title {
    font-size: 12px;
  }
`;
