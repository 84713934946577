import React from "react";
import Header from "containers/Home/Header";
import { HomeStyled } from "containers/Terms/style";
import Content from "./Content";
import Footer from "containers/Terms/Footer";

const Disclaimer = () => {
  return (
    <HomeStyled>
      <Header />
      <Content />
      <Footer />
    </HomeStyled>
  );
};

export default Disclaimer;
