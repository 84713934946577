import { Tabs, TabsProps } from "antd";
import React from "react";

const BaseTabs = ({ items, handleTabs, statusValue }: any) => {
  const onChange = (key: string) => {
    handleTabs(key);
  };
  return (
    <div>
      <Tabs
        activeKey={statusValue}
        items={items}
        onChange={onChange}
        // value={status}
      />
    </div>
  );
};

export default BaseTabs;
