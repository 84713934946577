import {
  CameraOutlined,
  EnvironmentOutlined,
  HomeFilled,
  QrcodeOutlined,
  SettingOutlined,
  UserOutlined,
  GlobalOutlined,
} from "@ant-design/icons";
import CameraCompliance from "../../../assets/logo/CameraCompliance-cc.png";

import { BaseLayout } from "components/common/BaseLayout";
import { useLocation, useNavigate } from "react-router-dom";
import * as S from "./index.styles";
import { useState } from "react";
import { MdClose } from "react-icons/md";
import { useResponsive } from "hooks/useResponsive";
import { useTranslation } from "react-i18next";

import { Typography } from "antd";
//@ts-ignore
import { ReactComponent as QR_LOGO_SVG } from "../../../../src/assets/logo/qrLogo.svg";
import { BiCategory } from "react-icons/bi";
import { FaLayerGroup } from "react-icons/fa";
type Props = {
  QR_DASHBOARD_MAIN_PATH: string;
  userRole: string;
  onToggleSideBar: () => void;
  isCollapsed: boolean;
};

const ADMIN = "Admin";
const SUPER_ADMIN = "Super Admin";

const Sidebar = ({
  QR_DASHBOARD_MAIN_PATH,
  userRole,
  onToggleSideBar,
  isCollapsed,
}: Props) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isTablet, useMediaQuery } = useResponsive();
  const { Sider } = BaseLayout;
  const is992px = useMediaQuery({ query: "(min-width: 992px)" });

  const menu: any = [
    {
      key: "dashboard",
      label: t("dashboard.dashboard"),
      roles: [ADMIN, SUPER_ADMIN],
      url: `${QR_DASHBOARD_MAIN_PATH}`,
      icon: <HomeFilled />,
    },
    // {
    //   key: "country",
    //   label: t("dashboard.country"),
    //   roles: [SUPER_ADMIN],
    //   url: `${QR_DASHBOARD_MAIN_PATH}/country`,
    //   icon: <EnvironmentOutlined />,
    // },
    {
      key: "city",
      label: t("dashboard.city"),
      roles: [SUPER_ADMIN],
      url: `${QR_DASHBOARD_MAIN_PATH}/city`,
      icon: <EnvironmentOutlined />,
    },
    {
      key: "admin",
      label: t("dashboard.admin"),
      roles: [SUPER_ADMIN],
      url: `${QR_DASHBOARD_MAIN_PATH}/admin`,
      icon: <UserOutlined />,
    },

    {
      key: "sticker",
      label: t("dashboard.sticker"),
      roles: [ADMIN],
      url: `${QR_DASHBOARD_MAIN_PATH}/sticker`,
      icon: <SettingOutlined />,
    },
    {
      key: "generated-qr",
      label: t("dashboard.qr"),
      roles: [ADMIN],
      url: `${QR_DASHBOARD_MAIN_PATH}/generated-qr`,
      icon: <QrcodeOutlined />,
    },
    {
      key: "operator",
      label: t("dashboard.operator"),
      roles: [ADMIN],
      url: `${QR_DASHBOARD_MAIN_PATH}/operator`,
      icon: <UserOutlined />,
    },

    {
      key: "camera",
      label: t("dashboard.camera"),
      roles: [ADMIN],
      url: `${QR_DASHBOARD_MAIN_PATH}/camera`,
      icon: <CameraOutlined />,
    },
    {
      key: "category",
      label: t("category.category"),
      roles: [SUPER_ADMIN],
      url: `${QR_DASHBOARD_MAIN_PATH}/category`,
      icon: <BiCategory />,
    },
    {
      key: "public-page",
      label: t("dashboard.public-page"),
      roles: [ADMIN],
      url: `${QR_DASHBOARD_MAIN_PATH}/public-page`,
      icon: <GlobalOutlined />,
      // children: [
      //   {
      //     key: "page",
      //     label: t("dashboard.pages"),
      //     roles: [ADMIN],
      //     url: `${QR_DASHBOARD_MAIN_PATH}/public-page`,
      //   },
      //   {
      //     key: "city",
      //     label: t("dashboard.city"),
      //     roles: [ADMIN],
      //     url: `${QR_DASHBOARD_MAIN_PATH}/city/edit`,
      //   },
      // ],
    },
    {
      key: "group",
      label: t("group.group"),
      roles: [ADMIN],
      url: `${QR_DASHBOARD_MAIN_PATH}/group`,
      icon: <FaLayerGroup />,
    },
    {
      key: "profile",
      label: t("dashboard.profile"),
      url: `${QR_DASHBOARD_MAIN_PATH}/profile`,
      icon: <SettingOutlined />,
      roles: [ADMIN],
      // children: [
      //   {
      //     key: "",
      //     label: t("dashboard.template"),
      //     roles: [SUPER_ADMIN],
      //     url: `${QR_DASHBOARD_MAIN_PATH}/admin/subitem1`,
      //   },
      //   {
      //     key: "template",
      //     label: t("dashboard.template"),
      //     roles: [SUPER_ADMIN],
      //     url: `${QR_DASHBOARD_MAIN_PATH}/admin/subitem1`,
      //   },
      //   // Add more submenu items here...
      // ],
    },

    {
      key: "activity",
      label: t("remain.sidebar-activities"),
      roles: [ADMIN],
      url: `${QR_DASHBOARD_MAIN_PATH}/activities`,
      icon: <UserOutlined />,
    },
  ];
  // Filter the menu items based on user role
  const filteredMenu = menu.filter((item: any) =>
    item.roles.includes(userRole)
  );
  const sidebarNavFlat = menu.reduce(
    (result: any, current) =>
      result.concat(
        current.children && current.children.length > 0
          ? current.children
          : current
      ),
    []
  );
  const currentMenuItem = sidebarNavFlat.find((item: any) => {
    const splittedURL = item?.url.split("/")[2];
    const splittedPathname = pathname.split("/")[2];

    if (splittedURL === splittedPathname) {
      return true;
    }

    return false;
  });

  const defaultSelectedKeys = currentMenuItem ? [currentMenuItem.key] : [];
  const openedSubmenu = menu.find(({ children }) =>
    children?.some(({ url }) => url === pathname)
  );
  const defaultOpenKeys = openedSubmenu ? [openedSubmenu.key] : [];

  const renderSidebar = () => {
    return (
      <S.SiderWrapperStyled>
        <Sider style={{ background: "white" }}>
          <S.Menu
            mode="inline"
            selectedKeys={defaultSelectedKeys}
            defaultOpenKeys={defaultOpenKeys}
            style={{
              height: "100%",
              borderRight: 0,
              padding: "10px",
            }}
            width={is992px ? 200 : 250}
            items={filteredMenu}
            onClick={({ item }: any) => {
              const { props } = item;
              onToggleSideBar();
              navigate(props.url);
            }}
          />
        </Sider>
      </S.SiderWrapperStyled>
    );
  };
  return (
    <S.SidebarWrapperStyled $isCollapsed={isCollapsed}>
      <div className="large-screen-sidebar">{renderSidebar()}</div>
      <div className="small-screen-sidebar">
        <S.LogoWrapperStyled>
          <img
            src={CameraCompliance}
            style={{ height: "25px" }}
            alt="Camera compliance logo"
            onClick={() => navigate("/")}
            role="link"
          />
          <div className="icon-wrapper">
            <span className="hamburger-icon" onClick={onToggleSideBar}>
              <MdClose color="black" className="icon" />
            </span>
          </div>
        </S.LogoWrapperStyled>

        {renderSidebar()}
      </div>
    </S.SidebarWrapperStyled>
  );
};

export default Sidebar;
