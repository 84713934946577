import { Avatar } from "antd";
import React, { useState } from "react";
import { UserOutlined } from "@ant-design/icons";
import { BasePopover } from "components/common/BasePopOver";
import ProfileOverlay from "../ProfieOverlay";
import { QR_DASHBOARD_AUTH_PATH } from "constants/routes/route";
import BaseButton from "components/common/BaseButton";
import { useLocation, useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import { useTranslation } from "react-i18next";

const UserProfile = ({ auth }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const isLoginPage = () =>
    location.pathname === `${QR_DASHBOARD_AUTH_PATH}/login`;
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const handlePopoverVisibleChange = () => {
    setIsPopoverOpen(!isPopoverOpen);
  };
  return (
    <>
      {!!auth && !isLoginPage() ? (
        <BasePopover
          placement="bottomRight"
          content={
            <ProfileOverlay
              auth={auth}
              onLinkClick={handlePopoverVisibleChange}
            />
          }
          trigger="click"
          open={isPopoverOpen}
          onOpenChange={handlePopoverVisibleChange}
        >
          <Avatar
            size={{ sm: 40, xs: 30 }}
            icon={<UserOutlined />}
            className="profile-avatar"
          />
        </BasePopover>
      ) : (
        <LoginButtonStyled className="button-wrapper">
          <BaseButton
            onClick={() => {
              navigate(`${QR_DASHBOARD_AUTH_PATH}/login`);
            }}
          >
            {t("dashboard.log-in")}
          </BaseButton>
        </LoginButtonStyled>
      )}
    </>
  );
};

export default UserProfile;
const LoginButtonStyled = styled.div`
  & button {
    display: flex;
    align-items: center;
    color: #fff;
    background: #167a8b;
    font-weight: 500;
    border: 1px solid #fff;
    transition: 0.5s;
    outline: none;
    &:hover {
      background: #ececec;
      color: #167a8b;
    }
  }
`;
