import styled from "styled-components";
import { Button as AntButton, ButtonProps } from "antd";

export const Button = styled(AntButton)<ButtonProps>`
  /* border-radius: 0px; */
  &:disabled {
    color: var(--black);
    opacity: 0.8;
  }
`;
