import HeaderWrapper from "components/headerwrapper";
import React, { useState, useEffect } from "react";
import AddAdminModal from "./AddAdminModal";
import useAPI from "hooks/useApi";
import EditAdminModal from "./EditAdminModal";
import admin from "shared/front/apis/admin.api";
import { BaseTable } from "components/common/BaseTable";
import { useNavigate } from "react-router-dom";
import { convertTimestamp, fullConvertTimestamp } from "utils/utils";
import { CapitalText } from "utils/styles";
import BaseLoading from "components/common/BaseSpin";
import { BaseTag } from "components/common/BaseTag";
import BaseToolTip from "components/common/BaseTooltip";
import BaseSearch from "components/common/BaseSearch";
import { ActionsWrapper } from "containers/Login/AuthLayout.styles";
import ActionButton from "components/ActionWrapper/ActionButton";
import ActionWrapper from "components/ActionWrapper/ActionWrapper";
import { BasePagination } from "components/common/BasePagination";
import { useDispatch, useSelector } from "react-redux";
import type { Dispatch } from "redux";
import store from "store";
import { useResponsive } from "hooks/useResponsive";
import * as Styles from "./index.styles";
import { useTranslation } from "react-i18next";
import AdminsTableRowCard from "./AdminsTableRowCard";
import AlterActionWrapper from "components/ActionWrapper/AlterActionWrapper";
import BaseButton from "components/common/BaseButton";
import { FaEdit, FaTrash, FaTrashAlt } from "react-icons/fa";

const Admin = () => {
  const { isLargeMobile } = useResponsive();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [makeRequest, { loading: loader }] = useAPI();
  const dispatch: Dispatch = useDispatch();
  const admins = useSelector((state: any) => state.admin.data);
  const pagination = useSelector((state: any) => state.admin.pagination);
  const [isEditModal, setIsEditModal] = useState(false);
  // const allAdminData = allAdmin?.data;
  // const adminPagination = allAdmin?.pagination;
  const openModal = () => {
    setIsModalVisible(true);
  };
  const closeModal = () => {
    setIsModalVisible(false);
  };

  const handlePagination = (page, limit) => {
    const { search } = pagination;
    dispatch(
      store.Actions["update"]("admin.pagination", {
        page,
        limit,
      })
    );
    getAllAdmin({ page, limit, ...(search && { search }) });
  };

  const handleSearch = (search) => {
    dispatch(store.Actions["update"]("admin.pagination", { search, page: 0 }));
    getAllAdmin({
      search,
      page: 0,
      limit: pagination.limit,
    });
  };

  const getAllAdmin = (params: any) => {
    makeRequest(admin.getAllAdmin("set", params));
  };

  useEffect(() => {
    if (admins.length === 0) {
      const { page, limit } = pagination;
      getAllAdmin({
        page,
        limit,
      });
    }
  }, []);

  const columns = [
    {
      title: t("dashboard.s.n."),
      dataIndex: "rowNo",
      key: "rowNo",
    },
    {
      title: t("dashboard.name"),
      dataIndex: "name",
      key: "name",
      render: (text: any) => {
        return <CapitalText>{text}</CapitalText>;
      },
    },
    {
      title: t("dashboard.created-date"),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: any, record: any, index: any) => {
        return (
          <BaseToolTip title={fullConvertTimestamp(text)}>
            <span>{convertTimestamp(text)}</span>
          </BaseToolTip>
        );
      },
    },
    {
      title: t("dashboard.email"),
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: t("dashboard.initial-password"),
      dataIndex: "initialPassword",
      key: "initialPassword",
      render: (text: any, record: any) => {
        return (
          <>
            {record?.isFirstLogin ? (
              <span>{text}</span>
            ) : (
              <BaseTag color="success">{t("status.logged-in")}</BaseTag>
            )}
          </>
        );
      },
    },
    {
      title: t("dashboard.action"),
      dataIndex: "action",
      key: "action",
      align: "right" as "right",
      render: (text: any, record: any, index: any) => {
        return (
          <>
            <BaseButton
              type="link"
              onClick={(e) => {
                e.stopPropagation();
                console.log(
                  "test",
                  record,
                  admins?.find((item) => item.admin._id === record.id)?.admin
                );

                setIsEditModal(
                  admins?.find((item) => item.admin._id === record.id)?.admin
                );
              }}
              style={{ marginLeft: "15px", padding: "0px" }}
            >
              <FaEdit
                style={{
                  height: "24px",
                  width: "24px",
                  color: "var(--skyblue)",
                }}
              />
            </BaseButton>
          </>
        );
      },
    },
  ];

  const dataSource = admins?.map((item: any, index: number) => ({
    rowNo: pagination.limit * pagination.page + index + 1,
    key: item.admin._id,
    id: item.admin._id,
    email: item.admin.email,
    createdAt: item.admin.createdAt,
    role: item.admin.role,
    phone: item?.admin?.phone
      ? `+${item?.admin?.countryCode}   ${item?.admin?.phone}`
      : "N/A",
    name: item.admin.firstName + " " + item.admin.lastName,
    initialPassword: item.admin.initialPassword,
    isFirstLogin: item.admin.isFirstLogin,
  }));

  return (
    <>
      <HeaderWrapper title={t("dashboard.admin")} />
      <AlterActionWrapper>
        <BaseSearch
          searchValue={pagination.search}
          handleSearch={handleSearch}
        />
        <ActionButton
          actionTitle={t("dashboard.add-admin")}
          handleAction={openModal}
        />
      </AlterActionWrapper>

      {isModalVisible && (
        <AddAdminModal
          onRefresh={getAllAdmin}
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          openModal={openModal}
          closeModal={closeModal}
        />
      )}
      {isEditModal && (
        <EditAdminModal
          values={isEditModal}
          onRefresh={getAllAdmin}
          isModalVisible={isEditModal}
          setIsModalVisible={(val) => {
            setIsEditModal(val);
          }}
          openModal={() => {
            setIsEditModal(true);
          }}
          closeModal={() => {
            setIsEditModal(false);
          }}
        />
      )}

      {isLargeMobile ? (
        <BaseTable
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          loading={loader}
        />
      ) : (
        <Styles.CardsWrapper>
          {admins?.map((admin) => (
            <AdminsTableRowCard
              key={admin._id}
              firstName={admin.admin.firstName}
              lastName={admin.admin.lastName}
              email={admin.admin.email}
              createdDate={admin.admin.createdAt}
              isFirstLogin={admin.admin.isFirstLogin}
              initialPassword={admin.admin.initialPassword}
            ></AdminsTableRowCard>
          ))}
        </Styles.CardsWrapper>
      )}
      <BasePagination
        pagination={pagination}
        handlePagination={handlePagination}
      />
    </>
  );
};

export default Admin;
