// ****************** generateQR **********************
export const generateQRCode = `QR/generateQRCode?number=${1}`;

// ****************** GETALLOPERATOR **********************
export const getAllOperator = `QR/getAllOperators`;

// ****************** GETALLGENERATEQR **********************
export const getAllGeneratedQr = `QR/getAllQRCodes`;

// ****************** GENERATEWITHSTICKERQR **********************
export const generateStickerWithQR = `QR/generateStickerWithQR`;

// ****************** UPDATEDQRCODES **********************
export const updated = `QR/generateStickerWithQR`;

// ****************** ACCEPTQR **********************
export const acceptQr = (id: string) => {
  return `QR/acceptQRCodeRequest/${id}`;
};

export const rejectQr = (id: string) => {
  return `QR/rejectQRCodeRequest/${id}`;
};
