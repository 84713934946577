import { Input, Tooltip } from "antd";
import BaseButton from "components/common/BaseButton";
import BaseModal from "components/common/BaseModal";
import { BaseTypography } from "components/common/BaseTypography";
import S3uploaderMessage from "components/common/S3presigned";

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { styled } from "styled-components";
import { ButtonFlexEnd, FlexEnd, flexAlignCenter } from "utils/styles";
import { PlusOutlined } from "@ant-design/icons";
import BaseToolTip from "components/common/BaseTooltip";
import { Link } from "react-router-dom";
import { BaseInput } from "components/common/BaseInput";
import useAPI from "hooks/useApi";
import city from "shared/front/apis/city.api";
import { FONT_SIZE } from "styles/themes/constants";

const CityFooterLogo = ({
  fieldName,
  setFieldValue,
  setFooterlogo,
  footerLogoState,
}: any) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [singleFooterLogo, setSingleFooterLogo] = useState({
    URL: "",
    link: "",
  });

  const { t } = useTranslation();
  const [makeRequest, { data }] = useAPI();
  const closeModal = () => {
    setIsModalVisible(false);
    setSingleFooterLogo({
      URL: "",
      link: "",
    });
  };

  const handleRemoveLogo = async (id, url) => {
    if (!!id) {
      await makeRequest(city.deleteFooterLogoById(id));
    }

    const filterFooterLogo = footerLogoState?.filter(
      (logo) => logo.URL !== url
    );
    setFooterlogo(filterFooterLogo);
  };

  return (
    <div>
      <LogoHeaderWrapper>
        <BaseTypography> {t("dashboard.associate-logo")}</BaseTypography>
        <BaseButton
          htmltype="button"
          onClick={() => setIsModalVisible(true)}
          icon={<PlusOutlined />}
          style={{ marginLeft: "20px" }}
        ></BaseButton>
      </LogoHeaderWrapper>

      <LogoGrid>
        <Wrapper>
          {footerLogoState?.map((item) => (
            <div style={{ position: "relative" }}>
              <BaseToolTip
                key={item?._id}
                title={
                  <a href={item.link} target="_blank" rel="noopener noreferrer">
                    {item?.link}
                  </a>
                }
              >
                <LogoWrapper>
                  <LogoImage src={item.URL} alt={`Logo ${item?._id}`} />
                </LogoWrapper>
              </BaseToolTip>
              <CloseIcon
                onClick={(e) => {
                  handleRemoveLogo(item?._id, item?.URL);
                }}
              >
                X
              </CloseIcon>
            </div>
          ))}
        </Wrapper>
      </LogoGrid>
      {isModalVisible && (
        <BaseModal
          title={t("dashboard.associate-logo")}
          open={isModalVisible}
          onCancel={closeModal}
          footer={[]}
          width={500}
        >
          <S3uploaderMessage
            label={t("remain.logo")}
            footerLogoState={singleFooterLogo}
            setFooterlogo={setSingleFooterLogo}
            isFormik={false}
            setFieldValue={undefined}
            fieldName={undefined}
            error={undefined}
            value={undefined}
            isFullWidth={undefined}
            setButtonLoading={undefined}
          />
          <div style={{ margin: "10px 0px" }}>
            <BaseTypography>{t("dashboard.website-link")}</BaseTypography>

            <Input
              type="url"
              placeholder="https://example.com"
              value={singleFooterLogo.link}
              onChange={(e) => {
                setSingleFooterLogo((prev) => ({
                  ...prev,
                  link: e.target.value,
                }));
              }}
            />
            <DirectionMessage>
              {t("remain.public-page-logo-message")}
            </DirectionMessage>
          </div>

          <ButtonFlexEnd>
            <BaseButton
              onClick={() => {
                if (!singleFooterLogo.URL || !singleFooterLogo.link) {
                  toast.error(
                    t(
                      "remain.please-add-associate-Logo-and-url-of-asscocite-site"
                    )
                  );
                  return;
                }

                setFooterlogo((prev) => [...prev, singleFooterLogo]);
                closeModal();
              }}
              style={{
                background: "var(--primary)",
                color: "#fff",
              }}
              disabled={!singleFooterLogo.URL || !singleFooterLogo.link}
            >
              {t("remain.add-btn")}
            </BaseButton>
          </ButtonFlexEnd>
        </BaseModal>
      )}
    </div>
  );
};

export default CityFooterLogo;
const LogoHeaderWrapper = styled.div`
  ${flexAlignCenter}
`;

const LogoGrid = styled.div`
  display: flex;
  max-width: 100%;
  flex-direction: column;
  margin: 0 auto;
  margin-bottom: 20px;
`;

const LogoImage = styled.img`
  width: 70px;
  height: 70px;
`;
const Wrapper = styled.div`
  display: flex;
  gap: 28px;
  flex-wrap: wrap;
  margin: 20px;
  align-items: center;
`;

const FooterText = styled.p`
  margin: 0;
  text-align: center;
`;

const CloseIcon = styled.div`
  background-color: red;
  border-radius: 20px;
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 10px;
  cursor: pointer;
  border: 1px solid red;
  width: 16px;
  height: 16px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LogoWrapper = styled.div``;

const DirectionMessage = styled.span`
  font-size: ${FONT_SIZE.xxs};
  font-style: italic;
`;
