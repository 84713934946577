import { useRoot } from "RootProvider";
import BaseNotAuthorized from "components/common/BaseNotAuthorized";
import { Outlet } from "react-router-dom";

const Rbac = ({ allowedRoute }: any) => {
  const { auth } = useRoot();

  const isAuthorized = allowedRoute?.[0] === auth?.userDetails?.role;

  return isAuthorized ? <Outlet /> : <BaseNotAuthorized />;
};

export default Rbac;
