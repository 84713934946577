export default [
  {
    title: "1. Sprejetje pogojev:",
    desc: [
      {
        title: "1.1",
        desc: "Z uporabo našega projekta skladnosti s kamerami priznavate, da ste prebrali, razumeli in se strinjate s temi Pogoji poslovanja.",
      },
    ],
  },
  {
    title: "2. Pravilnik o zasebnosti:",
    desc: [
      {
        title: "2.1",
        desc: "Vaša uporaba naših storitev je prav tako urejena z našim Pravilnikom o zasebnosti. Z uporabo naših storitev soglašate s pridobivanjem, uporabo in deljenjem vaših osebnih podatkov, kot je opisano v Pravilniku o zasebnosti.",
      },
    ],
  },

  {
    title: "3. Uporaba storitev:",
    desc: [
      {
        title: "3.1",
        desc: "Vaša uporaba naših storitev je prav tako urejena z našim Pravilnikom o zasebnosti. Z uporabo naših storitev soglašate s pridobivanjem, uporabo in deljenjem vaših osebnih podatkov, kot je opisano v Pravilniku o zasebnosti.",
      },
      {
        title: "3.2",
        desc: "Med uporabo naših storitev morate upoštevati vse veljavne zakone in predpise.",
      },
    ],
  },

  {
    title: "4. Intelektualna lastnina:",
    desc: [
      {
        title: "4.1",
        desc: "Vsi vsebine, materiali in intelektualna lastnina, povezana s projektom skladnosti s kamerami, so last nas ali naših licencorjev. Brez našega izrecnega dovoljenja ne smete razmnoževati, distribuirati, spreminjati ali ustvarjati izpeljanih del na podlagi naših vsebin.",
      },
    ],
  },

  {
    title: "5. Ravnanje uporabnika:",
    desc: [
      {
        title: "5.1",
        desc: "Vi ste odgovorni za točnost in zakonitost informacij, ki jih posredujete med uporabo naših storitev.",
      },
      {
        title: "5.2",
        desc: "Ne smete se vključiti v nobeno ravnanje, ki bi lahko motilo ali škodovalo našim storitvam, drugim uporabnikom ali naši ugledu.",
      },
    ],
  },

  {
    title: "6. Omejitev odgovornosti:",
    desc: [
      {
        title: "6.1",
        desc: "Naš projekt skladnosti s kamerami se zagotavlja »kot je« in »kot je na voljo«. Ne zagotavljamo natančnosti, zanesljivosti ali razpoložljivosti storitev.",
      },
      {
        title: "6.2",
        desc: "Ne prevzemamo odgovornosti za morebitno škodo, izgube ali obveznosti, ki izhajajo iz vaše uporabe naših storitev ali kakršnih koli informacij, pridobljenih prek naših storitev. Ne zagotavljamo natančnosti, zanesljivosti ali razpoložljivosti storitev.",
      },
    ],
  },

  {
    title: "7. Zavarovanje:",
    desc: [
      {
        title: "7.1",
        desc: "Soglašate, da nas boste odškodovali in nas varovali pred vsemi zahtevki, izgubami, obveznostmi in stroški (vključno z zakonitimi stroški), ki izhajajo iz vaše uporabe naših storitev ali kršitve teh Pogojev poslovanja.",
      },
    ],
  },
  {
    title: "8. Spremembe in prenehanje:",
    desc: [
      {
        title: "8.1",
        desc: "Pridržujemo si pravico, da kadar koli brez predhodnega obvestila spremenimo, suspendiramo ali prekinemo naš projekt skladnosti s kamerami.",
      },
      {
        title: "8.2",
        desc: "Prav tako lahko kadarkoli spremenimo te Pogoje poslovanja. Vaša nadaljnja uporaba naših storitev po takšnih spremembah pomeni vaše sprejemanje posodobljenih pogojev.",
      },
    ],
  },
  {
    title: "9. Veljavno pravo:",
    desc: [
      {
        title: "9.1",
        desc: "Ti Pogoji poslovanja se urejajo in razlagajo v skladu z zakoni [Jurisdikcija]. Morebitni spori, ki izhajajo iz ali v zvezi s temi pogoji, so podvrženi izključni pristojnosti sodišč v [Jurisdikcija].",
      },
    ],
  },
  {
    title: "10. Kontaktirajte nas:",
    desc: [
      {
        desc: "Če imate kakršna koli vprašanja, pomisleke ali povpraševanja glede teh Pogojev poslovanja ali uporabe naših storitev, nas prosimo kontaktirajte na [kontaktni e-poštni naslov]. Z uporabo našega projekta skladnosti s kamerami se strinjate, da ste zavezani s temi Pogoji poslovanja. Če se ne strinjate z nobenim delom teh pogojev, naših storitev prosimo ne uporabljajte.",
      },
    ],
  },
];
