import dynamicQRImg from "../../Features/images/qr.png";
import stickerImg from "../../Features/images/sticker.png";
import publicPageImg from "../../Features/images/city-public-page.png";

export default [
  {
    img: dynamicQRImg,
    title: "QR država in mesto",
    description:
      "Sodobna mesta zahtevajo sodobne rešitve. S sistemom Skladnost s kamero zagotavljamo skladnost z GDPR v javnih nadzornih sistemih, varovanje osebnih podatkov in zagotavljanje popolne preglednosti postopka.",
  },
  {
    img: stickerImg,
    title: "Nalepka s poljem",
    description:
      "Nalepke so odlične! Svoje dolgočasne QR kode postavite v pisane nalepke, da bodo privlačnega videza.",
  },
  {
    img: publicPageImg,
    title: "Javna stran za mesta",
    description:
      "Prilagodite javne strani glede na svoje potrebe... Mestni administratorji lahko prilagodijo in dodajo različne jezike glede na mesto.",
  },
];
