import BaseButton from "components/common/BaseButton";
import MenuLogo from "components/logo/MenuLogo";
//@ts-ignore
import { ReactComponent as QR_LOGO_SVG } from "../../assets/logo/qrLogo.svg";
import { QR_DASHBOARD_AUTH_PATH } from "constants/routes/route";
import React from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import { Typography } from "antd";
import ProfileHeader from "layouts/ProfileHeader";
import { useRoot } from "RootProvider";
import { useResponsive } from "hooks/useResponsive";

import CameraCompliance from "../../assets/logo/CameraCompliance-cc.png";

import { useTranslation } from "react-i18next";

type Props = {};

const Header = (props: Props) => {
  const navigate = useNavigate();
  const { isLargeScreen } = useResponsive();
  const { auth } = useRoot();
  const { t } = useTranslation();

  const location = useLocation();

  const isLoginPage = () =>
    location.pathname === `${QR_DASHBOARD_AUTH_PATH}/login`;
  return (
    <HeaderWrapperStyleds style={{ position: "fixed", top: 0, width: "100%" }}>
      <NavLink to="/">
        <LogoWrapperStyled>
          <img
            src={CameraCompliance}
            style={{ height: "30px" }}
            alt="Camera Compliance Logo"
          />
        </LogoWrapperStyled>
      </NavLink>

      {!!auth ? (
        <ProfileHeader />
      ) : (
        !isLoginPage() && (
          <div className="button-wrapper">
            <BaseButton
              onClick={() => {
                navigate(`${QR_DASHBOARD_AUTH_PATH}/login`);
              }}
            >
              {t("dashboard.log-in")}
            </BaseButton>
          </div>
        )
      )}
    </HeaderWrapperStyleds>
  );
};

export default Header;

const HeaderWrapperStyleds = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;

  padding: 14px 40px;
  position: fixed;
  z-index: var(--header);
  top: 0px;
  width: 100vw;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  z-index: 9999;
  @media (max-width: 500px) {
    padding: 14px 20px;
  }

  & .button-wrapper {
    & button {
      display: flex;
      align-items: center;
      color: #fff;
      background: #167a8b;
      font-weight: 500;
      border: 1px solid #fff;
      transition: 0.5s;
      outline: none;
      &:hover {
        background: #ececec;
        color: #167a8b;
      }
    }
  }
`;

const LogoWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  & .logoText {
    color: black;
    font-size: 1rem;
    margin: 0px;
    line-height: 16px;

    @media (max-width: 500px) {
      width: 110px;
    }
  }
  & svg {
    height: 32px;
    width: 32px;
    margin-right: 11px;
  }
`;
