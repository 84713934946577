import React from "react";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import * as S from "./StatisticsInfo.styles";
import { Space } from "antd";

export const getDifference = (
  value: number,
  prevValue: number
): string | null =>
  prevValue !== 0
    ? `${((Math.abs(value - prevValue) / prevValue) * 100).toFixed(0)}%`
    : "100%";
interface StatisticsInfoProps {
  name: string;
  value: number;
  prevValue: number;
}

export const StatisticsInfo: React.FC<StatisticsInfoProps> = ({
  name,
  value,
  prevValue,
}) => {
  return (
    <Space direction="vertical" size={6}>
      <S.Title>{name}</S.Title>

      {prevValue && (
        <S.Text>
          <S.IconWrapper></S.IconWrapper>
        </S.Text>
      )}
    </Space>
  );
};
