import { BaseTypography } from "components/common/BaseTypography";
import styled from "styled-components";
import { FONT_SIZE, FONT_WEIGHT, media } from "styles/themes/constants";

export const Title = styled(BaseTypography.Text)`
  font-size: ${FONT_SIZE.xxs};
  font-weight: ${FONT_WEIGHT.bold};
  // color: var(--white);
  color: #000;
  @media only screen and ${media.md} {
    font-size: ${FONT_SIZE.xs};
  }

  @media only screen and ${media.xl} {
    font-size: ${FONT_SIZE.xs};
  }
`;

export const Text = styled(BaseTypography.Text)`
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.medium};
`;

export const IconWrapper = styled.span`
  font-size: 0.625rem;
  color: var(--white);
`;
