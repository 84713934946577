import { BaseTypography } from "components/common/BaseTypography";
import styled from "styled-components";
import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
  media,
} from "styles/themes/constants";

export const Header = styled.div`
  padding: 1.25rem 0rem;
  display: flex;
  align-items: center;
`;

export const AuthorWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 21.25rem;
  position: relative;
  max-width: 42.5rem;
  box-shadow: var(--box-shadow);
  border-radius: ${BORDER_RADIUS};
  transition: 0.3s;

  [data-theme="dark"] & {
    background: var(--secondary-background-color);
  }

  &:hover {
    box-shadow: var(--box-shadow-hover);
  }
`;

export const Author = styled.div`
  font-size: ${FONT_SIZE.lg};
  font-weight: ${FONT_WEIGHT.bold};
  color: var(--text-main-color);
  line-height: 1.5625rem;
  text-transform: capitalize;
`;

export const InfoWrapper = styled.div`
  padding: 1.25rem;

  @media only screen and ${media.xl} {
    padding: 1rem;
  }

  @media only screen and ${media.xxl} {
    padding: 1.85rem;
  }
`;

export const InfoHeader = styled.div`
  display: flex;
  font-size: 1rem;
  margin-bottom: 1rem;

  @media only screen and ${media.md} {
    margin-bottom: 0.625rem;
  }

  @media only screen and ${media.xxl} {
    margin-bottom: 1.25rem;
  }
`;

export const Title = styled.div`
  font-size: ${FONT_SIZE.xl};
  font-weight: ${FONT_WEIGHT.semibold};
  width: 80%;
  line-height: 1.375rem;

  color: var(--text-main-color);

  @media only screen and ${media.md} {
    font-size: ${FONT_SIZE.xxl};
  }
`;

export const DateTime = styled(BaseTypography.Text)`
  font-size: ${FONT_SIZE.xs};
  color: var(--text-main-color);
  line-height: 1.25rem;
`;

export const Description = styled.div`
  font-size: ${FONT_SIZE.xs};
  color: rgb(102, 102, 102);

  @media only screen and ${media.xxl} {
    font-size: 1rem;
  }
`;

export const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.625rem;
  padding: 0 1.25rem 1.25rem;
`;

///new

export const CityWrapper = styled.div`
  max-width: 1200px;
  width: 100%;
  padding: 20px;
  margin: 0px auto;
  background: var(--whiteBackground);
  padding-bottom: 50px;
`;

export const CityImage = styled.img`
  width: 100%;
  height: 260px;
  object-fit: cover;
  @media only screen and ${media.xxl} {
    height: 400px;
  }
  border: 1px solid #dbdbdb;
`;

export const CityLogoImage = styled.img`
  width: 180px;
  height: 180px;
  object-fit: cover;
  border-radius: 50%;
  position: absolute;
  bottom: 0px;
  left: 0px;
  transform: translate(10px, 50%);
  border: 1px solid #dbdbdb;
  background: #fff;
`;
export const InfoContainerStyled = styled.div`
  margin-bottom: 30px;
`;
export const InfoContainerSStyled = styled.div`
  margin-bottom: 20px;
`;
export const HeaderWrapper = styled.div`
  margin-bottom: 2rem;
`;
