import styled from "styled-components";

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px 20px;
  background-color: #fff;
  margin-bottom: 8px;
  border-radius: 8px;
`;
