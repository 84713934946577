import React, { useEffect } from "react";
import styled from "styled-components";
import authApi from "shared/front/apis/auth.api";
import useAPI from "hooks/useApi";
import { Typography, Image, Tag, Space } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { BasePagination } from "components/common/BasePagination";
import store from "store";

const Activities = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [getRequest, { loading: loader }] = useAPI();
  const operatorActivities = useSelector(
    (state: any) => state.ownQRCodeActivity
  );
  const navigate = useNavigate();

  const getOwnQRCodeActivity = async ({ page, limit }) => {
    await getRequest(authApi.getOwnQRCodeActivity({ page, limit }));
  };

  useEffect(() => {
    getOwnQRCodeActivity({ page: 0, limit: 10 });
    dispatch(
      store.Actions["update"]("ownQRCodeActivity.pagination", {
        page: 0,
        limit: 10,
      })
    );
  }, []);

  const handlePagination = (page, limit) => {
    dispatch(
      store.Actions["update"]("ownQRCodeActivity.pagination", {
        page,
        limit,
      })
    );
    getOwnQRCodeActivity({ page, limit });
  };

  const makeMessage = (type, qr) => {
    if (type === "QR-Update") {
      return (
        <MessageStyled>
          <Link to={`/dashboard/generated-qr/${qr?._id}`}>
            {t("remain.sticker-qr")}
          </Link>{" "}
          {t("remain.of-batch")} {qr?.batch} {t("remain.located-at")}{" "}
          {qr?.address} ({qr?.latitude}, {qr?.longitude}) {t("remain.updated")}
        </MessageStyled>
      );
    }
    return type;
  };

  return (
    <>
      <Typography.Title level={4}>
        {t("remain.sidebar-activities")}
      </Typography.Title>
      {operatorActivities?.data?.length === 0 && (
        <div>
          <Typography.Text style={{ margin: "0 auto", fontSize: "16px" }}>
            {t("remain.currently-no-activities")}
          </Typography.Text>
        </div>
      )}
      {operatorActivities?.data?.length > 0 &&
        operatorActivities?.data?.map((activity) => {
          return (
            <ActivityWrapper>
              <Name>
                {activity?.operator ? (
                  <StyledText
                    onClick={() =>
                      navigate(`/dashboard/operator/${activity?.operator?._id}`)
                    }
                  >
                    {activity?.operator?.firstName}{" "}
                    {activity?.operator?.lastName}
                  </StyledText>
                ) : (
                  <StyledText>
                    {" "}
                    {activity?.admin?.firstName} {activity?.admin?.lastName}{" "}
                  </StyledText>
                )}
              </Name>
              <TopPartWrapper>
                <CountAnswerWrapper>
                  {makeMessage(activity?.type, activity?.QRCode)}
                </CountAnswerWrapper>
              </TopPartWrapper>
              <BottomPartWrapper>
                <DetailedWrapper>
                  <QuestionWrapper className="que-ellipsis"></QuestionWrapper>
                  <OptionWrapper>
                    <Typography.Text style={{ color: "gray", width: "80px" }}>
                      {t("dashboard.address")}
                    </Typography.Text>{" "}
                    {activity?.QRCode?.address}
                  </OptionWrapper>
                  <OptionWrapper>
                    <Typography.Text style={{ color: "gray", width: "90px" }}>
                      {t("dashboard.longitude")}
                    </Typography.Text>{" "}
                    {activity?.QRCode?.longitude}
                  </OptionWrapper>
                  <OptionWrapper>
                    <Typography.Text
                      style={{
                        color: "gray",
                        width: "90px",
                        marginBottom: "10px",
                      }}
                    >
                      {t("dashboard.latitude")}
                    </Typography.Text>
                    {activity?.QRCode?.latitude}
                  </OptionWrapper>
                  <Space size={[0, 8]} wrap>
                    <Tag color="green" style={{ width: "auto" }}>
                      {activity?.type}
                    </Tag>
                  </Space>
                </DetailedWrapper>

                <RightPartWrapper>
                  {activity?.QRCode?.images?.length > 1 && (
                    <CountImage>
                      +{activity?.QRCode?.images?.length - 1}
                    </CountImage>
                  )}

                  {Array.isArray(activity?.QRCode?.images) &&
                    activity?.QRCode?.images?.length > 0 && (
                      <Image.PreviewGroup
                        items={activity?.QRCode?.images.map((image) => {
                          return image?.URL;
                        })}
                      >
                        <Image
                          className="img"
                          style={{
                            borderRadius: "10px",
                            height: "100px",
                            width: "150px",
                            objectFit: "cover",
                            border: "1px solid #dbdbdb",
                          }}
                          src={activity?.QRCode?.images[0].URL}
                        />
                      </Image.PreviewGroup>
                    )}
                </RightPartWrapper>
              </BottomPartWrapper>
            </ActivityWrapper>
          );
        })}
      {operatorActivities?.data?.length > 0 && (
        <BasePagination
          pagination={operatorActivities.pagination}
          handlePagination={handlePagination}
        />
      )}
    </>
  );
};

export default Activities;

const ActivityWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 20px;
  box-shadow: 0px 0px 12px 2px rgba(27, 63, 84, 0.1);
  border-radius: 6px;
  background: #fff;
  border: 1px solid #eee;
  margin: 0px 5px 20px 0px;
  /* cursor: pointer; */
`;
const TopPartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: space-between; */
`;
const BottomPartWrapper = styled.div`
  margin: 0px 20px 10px 0px;
  display: flex;
  justify-content: space-between;
`;

const TopSideWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
const RightPartWrapper = styled.div`
  position: relative;
`;
const CountAnswerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: baseline;

  & > :nth-child(2) {
    margin-left: 2px;
  }
`;
const QuestionWrapper = styled.div`
  text-align: initial;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
  &.que-ellipsis {
    display: -webkit-box;
    max-height: 32px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;
const DetailedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const OptionWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  /* gap: 20px; */
`;

const ImageWrapper = styled.img`
  width: 85px;
  height: 90px;
  border-radius: 8px;
  margin-left: 15px;
`;
const MessageStyled = styled.div`
  // font-size: 12px;
  /* margin-bottom: 5px; */
  & a {
    text-decoration: underline;
  }
`;

const Name = styled.div`
  margin-bottom: 10px;

  & :hover {
    color: #167a8b;
    text-decoration: underline;
    cursor: pointer;
  }
`;
const StyledText = styled(Typography.Text)`
  font-size: 16px;
  font-weight: bold;
`;

const CountImage = styled.div`
  height: 25px;
  width: 25px;

  position: absolute;
  background-color: grey;
  padding: 5px;
  right: 0;
  top: 0;
  border-radius: 50%;
  translate: 30% -30%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  text-align: center;
  align-items: center;
  /* font-weight: bold; */
  color: white;
`;
