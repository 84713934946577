import dynamicQRImg from "../Features/images/newqr.png";
import stickerImg from "../Features/images/newsticker.png";
import publicPageImg from "../Features/images/ssprofile.png";

export const featuresData = [
  {
    img: dynamicQRImg,
    title: "QR Country and city",
    description:
      "With Camera Compliance, we bring GDPR compliance to public surveillance systems, safeguarding personal data and ensuring complete transparency in the process",
  },
  {
    img: stickerImg,
    title: "Sticker with field",
    description:
      "Stickers are great! Place your dull QR codes in colorful stickers to give them attractive looks.",
  },
  {
    img: publicPageImg,
    title: "Public Page for Cities",
    description:
      "Customize your public pages as per your needs...City admins can customize and can add different language according to city",
  },
];
