import styled, { keyframes } from "styled-components";

const lineAnim = keyframes`
    0% {
        left: -40%;
    }
    50% {
        left: 20%;
        width: 80%;
    }
    100% {
        left: 100%;
        width: 100%;
    }
`;

export const StraightLoader = styled.div`
  width: 100%;
  height: 3px;
  position: relative;
  overflow: hidden;
  background-color: #ddd;
  margin: 20px auto;
  border-radius: 20px;

  &:before {
    content: "";
    position: absolute;
    left: -50%;
    height: 3px;
    width: 40%;
    background-color: var(--primary);
    animation: ${lineAnim} 6s linear infinite;
    border-radius: 20px;
  }
`;
export const StraightUnLoad = styled.div`
  width: 100%;
  height: 3px;
  position: relative;
  overflow: hidden;
  background-color: #ddd;
  border-radius: 20px;
  margin: 20px auto;
`;
