import { ToastContainer } from "react-toastify";
import AppRouter from "./router/AppRouter";
import "react-toastify/dist/ReactToastify.css";
import deDe from "antd/lib/locale/de_DE";
import enUS from "antd/lib/locale/en_US";
import { ConfigProvider } from "antd";
import { useLanguage } from "hooks/useLanguage";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
function App() {
  const { language } = useLanguage();

  return (
    <>
      <ConfigProvider locale={language === "en" ? enUS : deDe}>
        <AppRouter />
        <ToastContainer style={{ zIndex: 999999 }} />
      </ConfigProvider>
    </>
  );
}

export default App;
