import axios from "axios";
import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { BaseTypography } from "../BaseTypography";
import { useTranslation } from "react-i18next";

import { useField } from "formik";
import BaseError from "../BaseError";
import { convertTimestamp, fullConvertTimestamp } from "utils/utils";
import InfiniteScroll from "react-infinite-scroll-component";
const SelectorWrapper = styled.div`
  margin: 20px 0px;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Image = styled.img`
  width: 120px;
  height: 120px;
  // margin: 10px;
  border: 1px solid var(--border);
  cursor: pointer;
  &.active {
    border: 5px solid var(--primary);
  }
`;
const ImageErrorWrapper = styled.div``;
const ImagesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px 20px;
  justify-content: space-between;
`;

const NoImageFoundMessage = styled.p`
  color: #888;
  font-style: italic;
  /* Add any other styles you want for the "no image found" message */
`;

// Wrap the "no image found" message with the styled component

const BaseImageSelector = ({
  imageData,
  activeImage,
  setActiveImage,
  name,
}: any) => {
  const handleImageClick = (item: any, helpers: any) => {
    setActiveImage(item);
    helpers.setValue(item?.URL);
  };
  const [field, meta, helpers] = useField(name);
  const { t } = useTranslation();
  const { touched, error } = meta;
  const ImageNotFound = () => (
    <NoImageFoundMessage> {t("dashboard.no-image-found")}</NoImageFoundMessage>
  );
  return (
    <SelectorWrapper>
      <BaseTypography>{t("dashboard.select-QR")}</BaseTypography>
      <ImageContainer>
        {imageData?.length > 0 ? (
          <ImageErrorWrapper>
            <ImagesWrapper>
              {imageData?.map((item: any) => {
                return (
                  <div>
                    <Image
                      key={item.URL}
                      src={item.URL}
                      alt={item.URL}
                      className={item.URL === activeImage?.URL ? "active" : ""}
                      onClick={() => handleImageClick(item, helpers)}
                      style={{ objectFit: "cover" }}
                    />
                    <BaseTypography.Text
                      style={{
                        fontSize: "12px",
                        color: "#666",
                        textAlign: "center",
                        display: "block",
                        marginTop: "3px",
                      }}
                    >
                      {convertTimestamp(item.createdAt)}
                    </BaseTypography.Text>
                  </div>
                );
              })}
            </ImagesWrapper>

            {error && <BaseError>{error}</BaseError>}
          </ImageErrorWrapper>
        ) : (
          <ImageNotFound />
        )}
      </ImageContainer>
    </SelectorWrapper>
  );
};

export default BaseImageSelector;
