import React, { useEffect, useMemo } from "react";
import { MapContainer, TileLayer, Marker, useMap, Popup } from "react-leaflet";

import "leaflet/dist/leaflet.css";

import { Link } from "react-router-dom";
import L from "leaflet";
import { styled } from "styled-components";
const myIcon = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-icon.png",
  shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png",
});
const Leaflet = ({ QRCodeDetails }: any) => {
  const group = L.featureGroup();
  // const QRCodeDetailss: any = [
  //   {
  //     latitude: 60.2055,
  //     longitude: 24.6559,
  //     address: "Espoo",
  //     images: [
  //       "https://rentisity-stage.s3.eu-west-1.amazonaws.com/47160dcc-be00-4cf5-a3a1-a7256a14ea64",
  //       "https://rentisity-stage.s3.eu-west-1.amazonaws.com/47160dcc-be00-4cf5-a3a1-a7256a14ea64",
  //       "https://rentisity-stage.s3.eu-west-1.amazonaws.com/47160dcc-be00-4cf5-a3a1-a7256a14ea64",
  //     ],
  //   },
  //   {
  //     latitude: 61.4981,
  //     longitude: 23.7609,
  //     address: "Tampere",
  //     images: [
  //       "https://rentisity-stage.s3.eu-west-1.amazonaws.com/47160dcc-be00-4cf5-a3a1-a7256a14ea64",
  //       "https://rentisity-stage.s3.eu-west-1.amazonaws.com/47160dcc-be00-4cf5-a3a1-a7256a14ea64",
  //       "https://rentisity-stage.s3.eu-west-1.amazonaws.com/47160dcc-be00-4cf5-a3a1-a7256a14ea64",
  //     ],
  //   },
  //   {
  //     latitude: 60.2941,
  //     longitude: 25.0408,
  //     address: "Vantaa",
  //     images: [
  //       "https://rentisity-stage.s3.eu-west-1.amazonaws.com/47160dcc-be00-4cf5-a3a1-a7256a14ea64",
  //       "https://rentisity-stage.s3.eu-west-1.amazonaws.com/47160dcc-be00-4cf5-a3a1-a7256a14ea64",
  //       "https://rentisity-stage.s3.eu-west-1.amazonaws.com/47160dcc-be00-4cf5-a3a1-a7256a14ea64",
  //     ],
  //   },
  //   {
  //     latitude: 60.4518,
  //     longitude: 22.2666,
  //     address: "Turku",
  //     images: [
  //       "https://rentisity-stage.s3.eu-west-1.amazonaws.com/47160dcc-be00-4cf5-a3a1-a7256a14ea64",
  //       "https://rentisity-stage.s3.eu-west-1.amazonaws.com/47160dcc-be00-4cf5-a3a1-a7256a14ea64",
  //       "https://rentisity-stage.s3.eu-west-1.amazonaws.com/47160dcc-be00-4cf5-a3a1-a7256a14ea64",
  //     ],
  //   },
  // ];
  function Bounds({ coords }) {
    const map = useMap();
    const formattedData = coords?.map((item) => [
      item?.latitude,
      item?.longitude,
    ]);

    useEffect(() => {
      if (!map || !group || formattedData?.length === 0) {
        return;
      }

      group.clearLayers();

      if (formattedData?.length > 0) {
        formattedData?.forEach((marker) => group.addLayer(L.marker(marker)));
        map.fitBounds(group.getBounds());
      }
    }, [map, formattedData]);
    return null;
  }

  return (
    <MapContainer
      attributionControl={false}
      center={[61.4981, 25.0408]}
      zoom={5}
      style={{ height: "400px", width: "100%" }}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      {QRCodeDetails?.length > 0 &&
        QRCodeDetails?.map((item: any) => {
          return (
            <Marker
              key={item._id}
              position={[item?.latitude, item?.longitude]}
              icon={myIcon}
            >
              {item?.address && item?.images && (
                <PopupStyle>
                  <span>{item?.address}</span>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      flexDirection: "row",
                    }}
                    key={item}
                  >
                    <img
                      src={item?.images[0]?.URL}
                      alt={item?._id}
                      width={"100px"}
                      height={"100px"}
                      style={{ objectFit: "cover", marginTop: "20px" }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "10px",
                    }}
                  >
                    <Link to={`/qr/${item?._id}`}>View more</Link>
                  </div>
                </PopupStyle>
              )}
            </Marker>
          );
        })}
      {QRCodeDetails?.length > 0 && <Bounds coords={QRCodeDetails} />}
    </MapContainer>
  );
};

export default Leaflet;

const PopupStyle = styled(Popup)`
  .leaflet-popup-content {
    width: 100px;
  }
`;
