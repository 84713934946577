import { Col, Select, message } from "antd";
import { BaseTypography } from "components/common/BaseTypography";
import React, { useEffect, useState } from "react";
import BaseModal from "components/common/BaseModal";
import BaseInputs from "components/common/BaseInput/BaseInputs";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import BaseButton from "components/common/BaseButton";
import useAPI from "hooks/useApi";
import country from "shared/front/apis/country.api";
import BaseError from "components/common/BaseError";
import { toast } from "react-toastify";
import { styled } from "styled-components";
import { COUNTRIES } from "../Flag/countries";
import { SelectMenuOption } from "../Flag/types";
import { useTranslation } from "react-i18next";
import CountrySelector from "../Flag/countryList";
const validationSchema = Yup.object().shape({
  title: Yup.string()
    .required("Country Name is required")
    .min(2, "Country Name should have a minimum of 2 characters")
    .max(30, "Country Name should have a maximum of 30 characters"),
});

const SAdminAddCountry = ({ onRefresh, isModalOpen, setIsModalOpen }: any) => {
  const [messageApi, contextHolder] = message.useMessage();
  //For Flag
  const myRef = React.createRef<HTMLDivElement>();
  const [isOpen, setIsOpen] = useState(false);
  const [nation, setNation] = useState("AF");
  const { t } = useTranslation();

  const handleChange = (value: string | string[]) => {
    console.log(`selected ${value}`);
  };
  const [modalWidth, setModalWidth] = useState<string | number>("500px");
  const [makeRequest, { loading: addCountryLoading, error: addCountryError }] =
    useAPI();
  const closeModal = () => {
    setIsModalOpen(false);
  };
  // const handleFormSubmit = async (values: any) => {
  //   try {
  //     alert("test");
  //     const { title, language } = values;

  //     const formattedLanguages = language.map((lang: string) => ({
  //       value: lang,
  //       label: lang,
  //     }));
  //     const languageValues = language.map((lang) => lang.value);
  //     await makeRequest(
  //       country.createCountry({
  //         title,
  //         language: languageValues.filter(Boolean),
  //       })
  //     );
  //     onRefresh();
  //   } catch (error: any) {
  //     toast.error(error);
  //   }
  // };

  const handleNationChange = (val) => {
    console.log(val, "testValues");
    setNation(val);
  };

  const handleFormSubmit = async (values: any) => {
    try {
      const { title, language, test } = values;
      // const languageValues = language.map((lang) => lang);
      await makeRequest(country.createCountry(values));
      onRefresh();
    } catch (error: any) {
      toast.error(error);
    }
  };

  // For width get of window
  const handleResize = () => {
    if (window.innerWidth > 500) {
      setModalWidth("30vw");
    } else {
      setModalWidth("30vw");
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      {contextHolder}
      {isModalOpen && (
        <BaseModal
          title={t("dashboard.add-country")}
          open={isModalOpen}
          onCancel={closeModal}
          footer={[]}
          width={500}
        >
          <Formik
            initialValues={{
              title: "",
              language: [],
              nation: nation,
            }}
            onSubmit={async (values, { setSubmitting }) => {
              await handleFormSubmit(values);
              await setSubmitting(false);
              setIsModalOpen(false);
            }}
            validationSchema={validationSchema}
            validateOnBlur={false}
          >
            {({
              values,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              errors,
            }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Col span={24}>
                    <BaseInputs
                      label={t("dashboard.country-name")}
                      name={"title"}
                      type={"text"}
                    />
                  </Col>
                  <CountrySelector
                    id={"countries"}
                    open={isOpen}
                    onToggle={() => setIsOpen(!isOpen)}
                    onChange={handleNationChange}
                    selectedValue={
                      COUNTRIES.find(
                        (option) => option.value === nation
                      ) as SelectMenuOption
                    }
                  />

                  <Col span={24}>
                    <BaseTypography style={{ marginBottom: 4 }}>
                      {t("dashboard.language")}
                    </BaseTypography>

                    <Select
                      mode="multiple"
                      placeholder="Please select"
                      value={values.language}
                      onChange={(selectedLanguages) =>
                        setFieldValue("language", selectedLanguages)
                      }
                      style={{ width: "100%" }}
                      options={[
                        { value: "en", label: "English" },
                        { value: "gn", label: "German" },
                        { value: "fn", label: "French" },
                        { value: "sn", label: "Spanish" },
                        { value: "rn", label: "Russian" },
                      ]}
                    />
                  </Col>
                  <BaseError>{addCountryError}</BaseError>
                  <BaseButton
                    htmlType="submit"
                    disabled={isSubmitting || addCountryLoading}
                    loading={addCountryLoading}
                    type={"primary"}
                    style={{
                      background: "var(--primary)",
                      color: "#fff",
                      marginTop: "10px",
                    }}
                  >
                    {t("dashboard.submit")}
                  </BaseButton>
                </Form>
              );
            }}
          </Formik>
        </BaseModal>
      )}
    </>
  );
};

export default SAdminAddCountry;
