import React from "react";
import { styled } from "styled-components";
import { devices } from "utils/theme";
import { object } from "yup";

const BannerImage = ({ src, style, colorState }: any) => {
  return (
    <ImageWrapper src={src} alt={src} style={style} colorState={colorState} />
  );
};

export default BannerImage;

const ImageWrapper = styled.img<{ colorState: any }>`
  object-fit: ${(props) =>
    props?.colorState?.objectFit ? props?.colorState?.objectFit : "contain"};
  width: 100%;
  height: 200px;
  width: 100%;
  background-color: ${(props) =>
    props?.colorState?.bannerBackgroundColor || "var(--white)"};
  @media ${devices.mobileSM} {
    height: 300px;
  }
  @media ${devices.mobileSM} {
    height: 350px;
  }
  @media ${devices.tablet} {
    height: 400px;
  }
  @media ${devices.laptop} {
    height: 450px;
  }
  @media ${devices.laptopXL} {
    height: 500px;
  }
`;
