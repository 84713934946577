import { styled } from "styled-components";

export const PopupNotificaionsStyled = styled.div`
  position: relative;
  & .notify-icon {
    color: var(--black);
    font-size: 24px;
    cursor: pointer;
  }

  & .notification-container {
    position: absolute;
    width: 350px;
    left: 0px;
    top: 40px;
    transform: translateX(calc(-100% + 50px));
    background: #fcfcfc;
    box-shadow: 0px 0px 5px 0px #ccc;
    border-radius: 10px;
    z-index: 100;
    border-top: 1px solid #ccc;
    padding-top: 20px;

    &::before {
      content: "";
      position: absolute;
      top: 0px;
      right: 0px;
      transform-origin: 50% 50%;
      transform: translate(-20px, -11px) rotate(45deg);
      z-index: 200;
      height: 20px;
      width: 20px;

      background: #fcfcfc;
      border-top: 1px solid #ccc;
      border-left: 1px solid #ccc;
      box-shadow: 0 -6px 5px -5px #ccc, -6px 0 5px -5px #ccc;
    }

    & .notifications-wrapper {
      max-height: 400px;
      overflow-y: auto;
      padding: 0px 10px 20px 20px;
    }

    & .notification {
      display: flex;
      width: 100%;
      align-items: flex-start;
      margin: 0px 0px 10px;
      padding: 10px 10px;
      background: #f0f0f0;
      border-radius: 10px;
      cursor: pointer;
      position: relative;
      &:hover {
        background-color: #e0e0e0;
      }
      & .message {
        display: block;
      }
      & .date {
        display: block;
        font-size: 12px;
      }

      & .unread-dot {
        height: 8px;
        width: 8px;
        content: "";
        position: absolute;
        right: 5px;
        top: 5px;
        background: var(--red);
        border-radius: 50%;
      }
    }
  }
`;

export const FullPageNotificationsStyled = styled.div`
  & .notification-container {
    background: #fcfcfc;
    box-shadow: 0px 0px 5px 0px #ccc;
    border-radius: 10px;
    border-top: 1px solid #ccc;
    padding-top: 20px;

    & .mark-btn {
      color: #fff;
      background: var(--primary);

      &:disabled {
        opacity: 0.5;
      }
      &:hover {
        color: #fcfcfc;
      }
    }

    & .notifications-wrapper {
      padding: 0px 10px 20px 20px;
      min-height: calc(100vh - 250px);
      max-height: calc(100vh - 250px);
      overflow: hidden;
      overflow-y: auto;
      padding-bottom: 100px;
    }

    & .notification {
      display: flex;
      width: 100%;
      align-items: flex-start;
      justify-content: space-between;
      margin: 0px 0px 10px;
      padding: 20px 20px;
      background: #f0f0f0;
      border-radius: 10px;
      cursor: pointer;
      &:hover {
        background-color: #e0e0e0;
      }

      & .checkbox-title-wrapper {
        display: flex;

        & .checkbox {
          margin-right: 12px;
        }
      }

      & .message {
        display: block;
        flex: 1;
      }
      & .date {
        display: block;
        font-size: 12px;
      }

      & .unread-dot {
        height: 10px;
        width: 10px;
        background: var(--red);
        border-radius: 50%;
      }
    }
  }
`;
