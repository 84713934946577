import { Col, Row } from "antd";
import { useRef, useState } from "react";
import { styled } from "styled-components";

import BaseButton from "components/common/BaseButton";
import { useResponsive } from "hooks/useResponsive";
import { useTranslation } from "react-i18next";
import { devices } from "utils/theme";

import SelectSticker from "components/StickerSelection/SelectSticker";
import {
  downloadMergedPNG,
  downloadMergedSVG,
  mergeSVG,
} from "utils/svgHelpers";
import SvgToPngExporter from "./svgToPng";
import { JSON_KEY } from "containers/Setting";
import ViewQrSticker from "components/StickerSelection/ViewQrSticker";
import { BaseTypography } from "components/common/BaseTypography";
import { useNavigate } from "react-router-dom";
import store from "store";

const Setting = () => {
  const { t } = useTranslation();
  const [showPreview, setShowPreview] = useState(false);

  const svgContainerRef = useRef(null);

  const [dataFromChild, setDataFromChild] = useState<any>(undefined);

  const { isTablet } = useResponsive();
  const naviagte = useNavigate();
  const getFinalSVGString = () => {
    return mergeSVG({
      stickerImage: atob(
        dataFromChild?.selectedFilePreviewURL?.replace(
          /^data:image\/svg\+xml;base64,/,
          ""
        )
      ),
      stickerWidth: dataFromChild?.imageSize?.width,
      stickerHeight: dataFromChild?.imageSize?.height,
      qrWidth: dataFromChild?.qrCodeSize.width,
      qrHeight: dataFromChild?.qrCodeSize.height,
      qrTopPos: dataFromChild?.qrCodeSize.y,
      qrLeftPos: dataFromChild?.qrCodeSize.x,
      qrCode: dataFromChild?.qrCodeSVG,
    });
  };

  const downloadFinalSVG = () => {
    if (svgContainerRef.current) {
      const actualContent = svgContainerRef.current.innerHTML;

      const blob = new Blob([actualContent], { type: "image/svg+xml" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "merged.svg"; // Specify the filename here
      a.click();
      URL.revokeObjectURL(url);
    }
  };

  const exportJSON = () => {
    const data = {
      version: "0.0.1",
      key: JSON_KEY,
      stickerImage: atob(
        dataFromChild?.selectedFilePreviewURL?.replace(
          /^data:image\/svg\+xml;base64,/,
          ""
        )
      ),
      stickerWidth: dataFromChild?.imageSize.width,
      stickerHeight: dataFromChild?.imageSize.height,
      qrWidth: dataFromChild?.qrCodeSize.width,
      qrHeight: dataFromChild?.qrCodeSize.height,
      qrTopPos: dataFromChild?.qrCodeSize.y,
      qrLeftPos: dataFromChild?.qrCodeSize.x,
      qrCode: dataFromChild?.qrCodeSVG,
      qrOptions: dataFromChild?.qrOptions,
    };

    const jsonData = JSON.stringify(data, null, 2); // Use null and 2 for pretty formatting
    const blob = new Blob([jsonData], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "data.json"; // Specify the filename here
    a.click();
    URL.revokeObjectURL(url);
  };
  const gotoDashboard = () => {
    store.store.dispatch(store.Actions.reset("stickerSelection"));
    naviagte("/dashboard");
  };

  return (
    <>
      <Row style={{ padding: "20px" }}>
        <Col span={24}>
          {showPreview ? (
            <div>
              {/* <div
                ref={svgContainerRef}
                dangerouslySetInnerHTML={{
                  __html: getFinalSVGString(),
                }}
              /> */}
              <BaseTypography.Text
                style={{
                  display: "block",
                  marginBottom: "10px",
                  fontSize: "1rem",
                  padding: "4px 10px",
                  borderRadius: "6px",
                }}
              >
                Check uploaded sticker by downloading in all format. If
                everything works as expected, you can export JSON and upload at
                Add Sticker.
              </BaseTypography.Text>

              <ViewQrSticker
                frontEndURL={`https://cameracompliance.com/qr/this-is-test-qr.`}
                stickerURL={dataFromChild?.selectedFilePreviewURL}
                stickerDimensions={{
                  stickerWidth: dataFromChild?.imageSize?.width,
                  stickerHeight: dataFromChild?.imageSize?.height,
                  qrWidth: dataFromChild?.qrCodeSize?.width,
                  qrHeight: dataFromChild?.qrCodeSize?.height,
                  qrTop: dataFromChild?.qrCodeSize?.y,
                  qrLeft: dataFromChild?.qrCodeSize?.x,
                  qrOptions: dataFromChild?.qrOptions,
                }}
                style={{
                  height: "calc(100vh - 200px)",
                }}
              />
            </div>
          ) : (
            <SelectSticker
              callbackForParent={(cData) => {
                if (cData) setDataFromChild(cData);
              }}
            />
          )}

          {dataFromChild?.selectedFilePreviewURL && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "30px",
              }}
            >
              <BaseButton
                onClick={gotoDashboard}
                htmlType="button"
                type="primary"
                style={{ background: "var(--primary)", marginRight: 10 }}
              >
                Goto Dashboard
              </BaseButton>
              {showPreview && (
                <>
                  <BaseButton
                    onClick={() => {
                      // downloadFinalSVG();
                      const sticker = {
                        URL: dataFromChild?.selectedFilePreviewURL,
                        stickerDimension: {
                          stickerWidth: dataFromChild?.imageSize?.width,
                          stickerHeight: dataFromChild?.imageSize?.height,
                          qrWidth: dataFromChild?.qrCodeSize?.width,
                          qrHeight: dataFromChild?.qrCodeSize?.height,
                          qrTop: dataFromChild?.qrCodeSize?.y,
                          qrLeft: dataFromChild?.qrCodeSize?.x,
                          qrOptions: dataFromChild?.qrOptions,
                        },
                      };
                      const frontendURL =
                        "https://cameracompliance.com/qr/this-is-a-test-qr";

                      downloadMergedSVG(sticker, frontendURL);
                    }}
                    htmlType="button"
                    type="primary"
                    // disabled={generatedQRLoading}

                    style={{
                      background: "var(--primary)",
                      marginRight: 10,
                    }}
                  >
                    Export SVG
                  </BaseButton>
                  <BaseButton
                    onClick={() => {
                      // const actualContent = svgContainerRef?.current?.innerHTML;
                      // if (actualContent) {
                      //   SvgToPngExporter(
                      //     actualContent,
                      //     dataFromChild?.imageSize?.width,
                      //     dataFromChild?.imageSize?.height
                      //   );
                      // }
                      if (dataFromChild) {
                        const sticker = {
                          URL: dataFromChild?.selectedFilePreviewURL,
                          stickerDimension: {
                            stickerWidth: dataFromChild?.imageSize?.width,
                            stickerHeight: dataFromChild?.imageSize?.height,
                            qrWidth: dataFromChild?.qrCodeSize?.width,
                            qrHeight: dataFromChild?.qrCodeSize?.height,
                            qrTop: dataFromChild?.qrCodeSize?.y,
                            qrLeft: dataFromChild?.qrCodeSize?.x,
                            qrOptions: dataFromChild?.qrOptions,
                          },
                        };
                        const frontendURL =
                          "https://cameracompliance.com/qr/this-is-a-test-qr";

                        downloadMergedPNG(sticker, frontendURL);
                      }
                    }}
                    htmlType="button"
                    type="primary"
                    // disabled={generatedQRLoading}

                    style={{
                      background: "var(--primary)",
                      marginRight: 10,
                    }}
                  >
                    Export PNG
                  </BaseButton>
                  <BaseButton
                    onClick={() => {
                      exportJSON();
                    }}
                    htmlType="button"
                    type="primary"
                    // disabled={generatedQRLoading}
                    style={{
                      background: "var(--primary)",
                      marginRight: 10,
                    }}
                  >
                    Export JSON
                  </BaseButton>
                </>
              )}
              <BaseButton
                onClick={() => {
                  setShowPreview((prev) => !prev);
                }}
                htmlType="button"
                type="primary"
                // disabled={generatedQRLoading}
                style={{ background: "var(--primary)" }}
              >
                {showPreview ? "Hide" : "Show"} Preview
              </BaseButton>
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};

const ImageStyled = styled.img<any>`
  width: 100%;
  height: 100%;
  max-height: 400px;
  @media ${devices.tablet} {
    max-height: 500px;
  }
  @media ${devices.laptopXL} {
    max-height: 600px;
  }
`;

const ImageContainer = styled.div<ImageContainerProps>`
  height: fit-content;
  max-height: 400px;

  @media ${devices.tablet} {
    max-height: 500px;
  }

  @media ${devices.laptopXL} {
    max-height: 600px;
  }

  position: relative;
  border: 1px solid #dbdbdb;
`;

const DimmedBackground = styled.div`
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface IimageSize {
  width: number;
  height: number;
}
interface ImageContainerProps {
  imageSize: IimageSize;
  isWidthGreaterThanHeight?: Boolean;
}

export default Setting;
