import ConfirmationMessagePopup from "components/ConfirmationMessagePopup/ConfirmationMessagePopup";
import BaseButton from "components/common/BaseButton";
import { BasePagination } from "components/common/BasePagination";
import { BaseTable } from "components/common/BaseTable";
import { BaseTypography } from "components/common/BaseTypography";
import HeaderWrapper from "components/headerwrapper";
import useAPI from "hooks/useApi";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { TbLock, TbLockOpen } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import type { Dispatch } from "redux";
import cameraApi from "shared/front/apis/camera";
import store from "store";
import request from "utils/request";
import { capitalize, convertTimestamp } from "utils/utils";
import AddCameraModal from "./AddCameraModal";
// import { BaseTag } from "components/common/BaseTag";
import EyeOutlined from "@ant-design/icons/EyeOutlined";
import ActionWrapper from "components/ActionWrapper/ActionWrapper";
import BaseTabs from "components/common/BaseTabs";
import { useResponsive } from "hooks/useResponsive";
import { toast } from "react-toastify";
import CameraTableRowCard from "./CameraTableRowCard";
import BaseSearch from "components/common/BaseSearch";
import BaseToolTip from "components/common/BaseTooltip";

const Camera = () => {
  const [search, setSearch] = useSearchParams();
  const navigate = useNavigate();
  const dispatch: Dispatch = useDispatch();
  const { isLargeMobile } = useResponsive();
  const { t } = useTranslation();
  const camera = useSelector((state: any) => state.camera.data);
  const pagination = useSelector((state: any) => state.camera.pagination);

  const [isModalOpen, setIsModalOpen] = useState(false);
  //For custom delete modal
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [getRequest, { loading: loader }] = useAPI();
  let [searchParams, setSearchParams] = useSearchParams();
  const [selectedCamera, setSelectedCamera] = useState<{
    _id?: string;
    address?: string;
    serialNumber?: string;
    longitude?: string;
    latitude?: string;
    isCreateAutomatic?: boolean;
    isArchive?: boolean;
    isUsed?: boolean;
  }>({});
  const [isLoading, setIsLoading] = useState(false);

  const addCameraLocation = () => {
    setIsModalOpen(true);
    setSelectedCamera({});
  };
  const getAllCamera = (params) => {
    getRequest(cameraApi.getAllCamera("set", params));
  };

  const refetchCamera = () => {
    let { status, page, limit } = pagination;

    let archive = false;
    let hasGrouped = false;
    if (status === "Blocked") {
      archive = true;
    } else if (status === "NotGrouped") {
      hasGrouped = false;
    }

    if (camera?.length > 1) {
      if (status === "NotGrouped") {
        getAllCamera({
          page,
          limit,
          hasGroup: hasGrouped,
        });
      } else {
        getAllCamera({
          page,
          limit,
          archive,
        });
      }
    } else {
      if (page > 0) {
        handlePagination(page - 1, limit);
      }
    }
  };

  useEffect(() => {
    if (search.get("status")) {
      handleTabs(search.get("status"));
      search.delete("status");
      setSearch(search);
    }
  }, [search]);

  useEffect(() => {
    if (searchParams) {
      const queryCameraId = searchParams.get("camera");

      if (camera && camera?.length > 0 && queryCameraId) {
        selectStickerToEdit(queryCameraId);
      }
    }
  }, [searchParams, camera]);

  const selectStickerToEdit = (cameraId) => {
    if (Array.isArray(camera)) {
      const cameraToEdit = camera.find((x) => x._id === cameraId);

      if (!!cameraToEdit) {
        setSelectedCamera({ ...cameraToEdit });
        setIsModalOpen(true);
      }
    }
  };

  const handlePagination = (page, limit) => {
    const { status } = pagination;
    let archive = false;
    let hasGrouped = false;
    if (status === "Blocked") {
      archive = true;
    } else if (status === "NotGrouped") {
      hasGrouped = false;
    }

    dispatch(
      store.Actions["update"]("camera.pagination", {
        page,
        limit,
      })
    );

    if (status === "NotGrouped") {
      getAllCamera({
        page,
        limit,
        hasGroup: hasGrouped,
      });
    } else {
      getAllCamera({
        page,
        limit,
        archive,
      });
    }
  };

  useEffect(() => {
    // if (camera.length === 0) {
    dispatch(
      store.Actions["update"]("camera.pagination", {
        page: 0,
        limit: 10,
      })
    );
    getAllCamera({
      page: 0,
      limit: 10,
    });
    // }
  }, []);

  const handleSearch = (search) => {
    dispatch(store.Actions["update"]("camera.pagination", { search, page: 0 }));
    const { limit, blocked, notLoggedIn, status } = pagination;

    const paramsToPass = {
      search,
      page: 0,
      limit,
      blocked,
      notLoggedIn,
    };

    getAllCamera({
      ...paramsToPass,
    });
  };

  const dataSource = camera?.map((item: any, index: number) => {
    return {
      rowNo: pagination.limit * pagination.page + index + 1,
      key: item._id || t("dashboard.not-available"),
      id: item._id || t("dashboard.not-available"),
      name: item.name || t("dashboard.not-available"),
      address: item.address || t("dashboard.not-available"),
      serialNumber: item.serialNumber || t("dashboard.not-available"),
      createdAt: item.createdAt || t("dashboard.not-available"),
      isCreateAutomatic: item.isCreateAutomatic,
      status: item.isCreateAutomatic || t("dashboard.not-available"),
      isUsed: item?.isUsed,
      isArchive: item?.isArchive,
    };
  });

  //For Delete
  useEffect(() => {
    if (searchParams) {
      const queryCameraId = searchParams.get("camera");
      if (camera && camera?.length > 0 && queryCameraId) {
        onSelectToDelete(queryCameraId);
      }
    }
  }, [searchParams, camera]);

  const onSelectToDelete = (cameraId: any) => {
    if (Array.isArray(camera)) {
      const cameraToDelete = camera.find((x) => x._id === cameraId);

      if (!!cameraToDelete) {
        setSelectedCamera({ ...cameraToDelete });
        setIsDeleteModalVisible(true);
      }
    }
  };

  const closeDeleteModal = () => {
    setIsDeleteModalVisible(false);
    setSelectedCamera({});
  };

  const handleDeleteCamera = async (cameraId: string) => {
    try {
      setIsLoading(true);
      const response = await request(cameraApi.deleteCamera(cameraId));
      if (response) {
        dispatch(store.Actions.remove("camera", cameraId));
        closeDeleteModal();
        toast.success(
          `${t("remain.camera-of-location")} ${selectedCamera?.address} ${
            selectedCamera?.isUsed ? t("remain.blocked") : t("remain.deleted")
          }  ${t("successfully")}`
        );
        refetchCamera();
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleUnblockCamera = async (cameraId: string) => {
    try {
      setIsLoading(true);
      const response = await request(cameraApi.unblockCamera(cameraId));
      if (response) {
        const updatedData = camera.filter((x) => x._id !== cameraId);
        dispatch(store.Actions.set("camera.data", updatedData));
        closeDeleteModal();
        toast.success(
          `${t("remain.camera-of-location")} ${selectedCamera?.address} ${t(
            "remain.unblock"
          )}
          ${t("successfully")}`
        );
        refetchCamera();
      }
    } catch (error) {
      // console.log("Delete Camera:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onViewRow = async (record) => {
    navigate(record.id);
  };

  const columns = [
    {
      title: t("dashboard.s.n."),
      dataIndex: "rowNo",
      key: "rowNo",
    },
    {
      title: t("dashboard.name"),
      dataIndex: "name",
      key: "name",
      render: (text: any, record: any, index: any) => {
        return (
          <span>
            {(text && capitalize(text)) || t("dashboard.not-available")}
          </span>
        );
      },
    },
    {
      title: t("dashboard.address"),
      dataIndex: "address",
      key: "address",
      render: (text: any, record: any, index: any) => {
        return (
          <span>
            {(text && capitalize(text)) || t("dashboard.not-available")}
          </span>
        );
      },
    },
    {
      title: t("dashboard.serialnumber"),
      dataIndex: "serialNumber",
      key: "serialNumber",
    },

    {
      title: t("dashboard.created-date"),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: any, record: any, index: any) => {
        return <span>{convertTimestamp(text)}</span>;
      },
    },
    {
      title: t("dashboard.action"),
      dataIndex: "action",
      key: "action",
      align: "right" as "right",
      render: (text: any, record: any, index: any) => {
        return (
          <>
            <BaseToolTip title={t("tooltip.view-camera")}>
              <BaseButton
                onClick={(e) => {
                  e.stopPropagation();
                  onViewRow(record);
                }}
                style={{ marginLeft: "15px", padding: "0px" }}
                type="link"
              >
                <EyeOutlined
                  style={{
                    fontSize: "24px",
                    color: "var(--primary)",
                  }}
                />
              </BaseButton>
            </BaseToolTip>

            <BaseToolTip title={t("tooltip.edit-camera")}>
              <BaseButton
                type="link"
                onClick={(e) => {
                  e.stopPropagation();
                  selectStickerToEdit(record?.id);
                }}
                style={{ marginLeft: "15px", padding: "0px" }}
              >
                <FaEdit
                  style={{
                    height: "24px",
                    width: "24px",
                    color: "var(--skyblue)",
                  }}
                />
              </BaseButton>
            </BaseToolTip>

            {record?.isUsed ? (
              record?.isArchive ? (
                <BaseButton
                  onClick={(e) => {
                    e.stopPropagation();
                    onSelectToDelete(record?.id);
                  }}
                  style={{ marginLeft: "15px", padding: "0px" }}
                  type="link"
                >
                  <BaseToolTip title={t("tooltip.block-camera")}>
                    <TbLockOpen
                      style={{
                        height: "24px",
                        width: "24px",
                        color: "var(--tairo)",
                      }}
                    />
                  </BaseToolTip>
                </BaseButton>
              ) : (
                <BaseButton
                  onClick={(e) => {
                    e.stopPropagation();
                    onSelectToDelete(record?.id);
                  }}
                  style={{ marginLeft: " 10px", padding: "0px" }}
                  type="link"
                >
                  <BaseToolTip title={t("tooltip.unblock-camera")}>
                    <TbLock
                      style={{
                        height: "24px",
                        width: "24px",
                        color: "var(--orange)",
                      }}
                    />
                  </BaseToolTip>
                </BaseButton>
              )
            ) : (
              <BaseButton
                onClick={(e) => {
                  e.stopPropagation();
                  onSelectToDelete(record?.id);
                }}
                style={{ marginLeft: " 10px", padding: "0px" }}
                type="link"
              >
                <BaseToolTip title={t("tooltip.delete-camera")}>
                  <FaTrashAlt
                    style={{
                      height: "24px",
                      width: "24px",
                      color: "var(--red)",
                    }}
                  />
                </BaseToolTip>
              </BaseButton>
            )}
          </>
        );
      },
    },
    // {
    //   title: t("dashboard.status"),
    //   dataIndex: "status",
    //   key: "status",
    //   render: (text) => {
    //     return <BaseTag></BaseTag>;
    //   },
    // },
  ];

  const items = [
    {
      key: "Active",
      label: t("dashboard.active"),
      archive: false,
    },
    {
      key: "Blocked",
      label: t("dashboard.blocked"),
      archive: true,
    },
    {
      key: "NotGrouped",
      label: t("remain.not-grouped"),
      hasGrouped: false,
    },
  ];

  useEffect(() => {
    return () => {
      dispatch(
        store.Actions["update"]("camera.pagination", {
          page: 0,
          status: "Active",
          archive: false,
          limit: 10,
        })
      );
    };
  }, []);

  const handleTabs = (status) => {
    let archive = false;
    let hasGrouped = false;
    if (status === "Blocked") {
      archive = true;
    } else if (status === "NotGrouped") {
      hasGrouped = false;
    }

    dispatch(
      store.Actions["update"]("camera.pagination", {
        page: 0,
        status,
        archive,
        limit: 10,
        hasGrouped,
      })
    );
    if (status === "NotGrouped") {
      getAllCamera({
        page: 0,
        limit: pagination.limit,
        hasGroup: hasGrouped,
      });
    } else {
      getAllCamera({
        page: 0,
        limit: pagination.limit,
        archive,
      });
    }
  };

  return (
    <>
      <HeaderWrapper title={t("dashboard.camera")} />
      <ActionWrapper>
        {" "}
        <BaseTabs
          items={items}
          handleTabs={handleTabs}
          statusValue={pagination.status}
        />
        <ActionWrapper>
          <BaseSearch
            handleSearch={handleSearch}
            searchValue={pagination.search}
            style={{ marginRight: "12px" }}
          />
          <HeaderWrapper
            title=""
            actionTitle={t("dashboard.add-camera")}
            handleAction={addCameraLocation}
          ></HeaderWrapper>
        </ActionWrapper>
      </ActionWrapper>

      <AddCameraModal
        cameraID={selectedCamera?._id}
        cameraEditData={selectedCamera}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        onRefresh={() => getAllCamera({ page: 0, limit: 10 })}
      />

      {isLargeMobile ? (
        <BaseTable
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          loading={loader}
          onRow={(record, rowIndex) => {
            return {
              onClick: () => {
                onViewRow(record);
              },
            };
          }}
        ></BaseTable>
      ) : (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {dataSource?.map((camera) => (
            <CameraTableRowCard
              key={camera.key}
              address={camera.address}
              serialNumber={camera.serialNumber}
              createdDate={camera.createdAt}
              isCreateAutomatic={camera.isCreateAutomatic}
              isArchive={camera?.isArchive}
              isUsed={camera?.isUsed}
              onViewClick={() => {
                const id = camera?.id;
                navigate(`${id}`);
              }}
              onRowClick={(e) => {
                e.stopPropagation();
                onViewRow(camera);
              }}
              onEditClick={(e) => {
                e.stopPropagation();
                selectStickerToEdit(camera?.id);
              }}
              onDeleteClick={(e) => {
                e.stopPropagation();
                onSelectToDelete(camera?.id);
              }}
              onUnblockClick={(e) => {
                e.stopPropagation();
                onSelectToDelete(camera?.id);
              }}
            />
          ))}
        </div>
      )}

      <BasePagination
        pagination={pagination}
        handlePagination={handlePagination}
      />
      <ConfirmationMessagePopup
        isVisible={isDeleteModalVisible}
        onAction={() => {
          selectedCamera?.isArchive
            ? handleUnblockCamera(selectedCamera?._id)
            : handleDeleteCamera(selectedCamera?._id);
        }}
        isLoading={isLoading}
        onClose={closeDeleteModal}
        actionIsDanger={true}
        actionLabel={
          selectedCamera?.isUsed
            ? selectedCamera?.isArchive
              ? t("remain.unblock")
              : t("remain.block")
            : t("remain.delete")
        }
        title={
          selectedCamera?.isUsed
            ? selectedCamera?.isArchive
              ? t("remain.unblock-camera-title")
              : t("remain.block-camera-title")
            : t("remain.delete-camera-title")
        }
      >
        <BaseTypography.Text style={{ marginBottom: "20px" }}>
          {selectedCamera?.isUsed
            ? selectedCamera?.isArchive
              ? t("remain.unblock-camera")
              : t("remain.block-camera")
            : t("remain.delete-camera")}
        </BaseTypography.Text>
      </ConfirmationMessagePopup>
    </>
  );
};

export default Camera;
