import * as StylesShared from "../index.styles";
import * as Styles from "./index.styles";
import { SectionOne } from "./index.styles";
import { AiFillApple } from "react-icons/ai";
import { FaGooglePlay } from "react-icons/fa";
import HeroImage from "../images/city-public-page.png";
// @ts-ignore
import { ReactComponent as AppStoreIcon } from "../images/app-store-icon.svg";
// @ts-ignore
import { ReactComponent as PlayStoreIcon } from "../images/play-store-icon.svg";
import { Link } from "react-router-dom";
//@ts-ignore
import { useTranslation } from "react-i18next";
import { Typography } from "antd";
// import ScrollReveal from "scrollreveal";
import { useEffect } from "react";
const FeaturesHero = () => {
  const { t } = useTranslation();

  // ScrollReveal({

  //   distance: "60px",
  //   duration: 1000,
  //   delay: 400,
  // });

  // ScrollReveal().reveal(".main-title", { delay: 500, origin: "left" });
  // ScrollReveal().reveal(".section1 .image", { delay: 600, origin: "bottom" });
  // ScrollReveal().reveal(".text-box", { delay: 700, origin: "right" });
  // ScrollReveal().reveal(".media-icons ", {
  //   delay: 500,
  //   origin: "bottom",
  //   interval: 200,
  // });

  return (
    <StylesShared.Section>
      <Styles.Container>
        <Styles.Wrapper>
          <Styles.TextContainer>
            <Typography.Title level={1} className="features-hero-title">
              {t("feature.feature-title")}
            </Typography.Title>
            <Typography.Text className="features-hero-description">
              {t("feature.feature-description")}
            </Typography.Text>
            <Styles.Icons>
              <Typography.Text
                style={{ marginRight: "10px", fontSize: "20px" }}
              >
                {t("feature.find")}
              </Typography.Text>
              <Link to="https://www.apple.com/app-store/">
                <AppStoreIcon style={{ width: "40px", height: "40px" }} />
              </Link>
              <Link to="https://play.google.com/store/games?hl=en&gl=US">
                <PlayStoreIcon style={{ width: "40px", height: "40px" }} />
              </Link>
            </Styles.Icons>
          </Styles.TextContainer>
          <Styles.ImageContainer>
            <Styles.HeroImage src={HeroImage} alt="Hero Image" />
          </Styles.ImageContainer>
        </Styles.Wrapper>
      </Styles.Container>
      {/* <SectionOne className="section1">
        <div className="container">
          <Typography.Title level={1} className="main-title">
            {t("feature.feature-title")}
          </Typography.Title>
          <div className="content">
            <div className="image">
              <img className="img" src={HeroImage} alt="Hero Image" />
            </div>
            <div className="text-box">
              <Typography.Text className="desc">
                {t("feature.feature-description")}
              </Typography.Text>
            </div>
          </div>
          <div className="media-icons">
            <Link to="">
              <AiFillApple className="a" style={{ fontSize: "2.5em" }} />
            </Link>
            <Link to="">
              <FaGooglePlay className="a" />
            </Link>
          </div>
        </div>
      </SectionOne> */}
    </StylesShared.Section>
  );
};

export default FeaturesHero;
