export default [
  {
    title: "Omejitev Odgovornosti",
    desc: [
      "Camera Compliance Ltd, njegove povezane družbe, partnerji in zaposleni niso odgovorni za neposredno, posredno, naključno, posebno, posledično ali kazensko škodo, vključno, vendar ne omejujoč se na finančno škodo, izgubo podatkov ali druge škode, ki izhaja iz vaše uporabe te spletne strani ali zaupanja v informativne vsebine, ki so na voljo.",
    ],
  },
  {
    title: "Brez Strokovnih Nasvetov",
    desc: [
      "Informacije, ki jih najdete na tej spletni strani, ne predstavljajo pravnih, tehničnih ali drugih strokovnih nasvetov. Čeprav se trudimo zagotavljati natančne in ažurirane informacije, ne dajemo nobenih jamstev ali zastopstev katere koli vrste glede natančnosti, valutnosti ali popolnosti te spletne strani.",

      "Za podrobne nasvete, prilagojene vaši situaciji, se posvetujte s kvalificiranimi strokovnjaki, vključno, vendar ne omejeno na, pravniki z izkušnjami na področju varstva podatkov in skladnosti s pravili GDPR.",
    ],
  },
  {
    title: "Veljavno Pravo",
    desc: [
      "Ta odstop velja in se razlaga v skladu z zakoni jurisdikcije, v kateri deluje Camera Compliance Ltd. Z uporabo te spletne strani soglašate z jurisdikcijo takšnih sodišč za vse tožbe, postopke ali postopke, ki izhajajo iz tega odstopa.",
    ],
  },
];
