import { Select } from "antd";
import styled from "styled-components";
import { flexRow } from "utils/styles";

export const SelectStyle = styled(Select)`
  margin: 1rem 0rem;
`;
export const SelectWrapper = styled.div`
  ${flexRow}
`;
export const TypoWrapper = styled.div`
  margin-right: 20px;
`;
